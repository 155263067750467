/**
 * @generated SignedSource<<2712d319b7cda0f164e33dcd4c6f421c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets$data = {
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_fieldSets" | "row_nativeIssueTable_RowWithDragAndDrop_fieldSets">;
  readonly " $fragmentType": "row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets";
};
export type row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets$key = {
  readonly " $data"?: row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isDensityFull",
    "variableName": "isDensityFull"
  },
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowInner_fieldSets"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithDragAndDrop_fieldSets"
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};
})();

(node as any).hash = "2df10ad608b4032fdba30e652192059f";

export default node;
