/**
 * @generated SignedSource<<ea56737eacf62204e5cc47ea8a38de4e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell$data = {
  readonly statusCategory: {
    readonly name: string | null | undefined;
    readonly statusCategoryId: string;
  };
  readonly " $fragmentType": "issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell";
};
export type issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell$key = {
  readonly " $data"?: issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell",
  "selections": [
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "statusCategoryId"
        }
      ]
    }
  ],
  "type": "JiraStatusCategoryField"
};

(node as any).hash = "01c5fef6394b4cc6d50a5e2f02db1620";

export default node;
