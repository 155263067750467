import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	tooltip: ReactNode;
};

const WarningIndicator = ({ tooltip }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip content={tooltip}>
			<WarningIconWrapper>
				<WarningIcon
					label={formatMessage(messages.warningLabel)}
					color={token('color.icon.warning')}
					LEGACY_size="small"
				/>
			</WarningIconWrapper>
		</Tooltip>
	);
};

export default WarningIndicator;

const HEADER_CELL_HEIGHT = 3.5 * gridSize;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningIconWrapper = styled.span({
	marginRight: `${token('space.025')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_CELL_HEIGHT}px`,
	display: 'flex',
	alignItems: 'center',
});
