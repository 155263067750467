import { ConnectionInterface, type RecordProxy } from 'relay-runtime';

const findEdgeIndex = (edges: RecordProxy[], id?: string): number => {
	if (id == null) {
		return -1;
	}
	const { NODE } = ConnectionInterface.get();

	return edges.findIndex((edge) => edge.getLinkedRecord(NODE)?.getValue('id') === id);
};

/**
 * Modification of [Relay's ConnectionHandler.insertEdgeBefore](https://relay.dev/docs/api-reference/store/#insertedgebeforeconnection-recordproxy-newedge-recordproxy-cursor-string)
 * to lookup the edge by node ID rather than cursor. This is required as issues created via inline issue create will not
 * have a cursor, but we still need them to be identifiable for relative insertion.
 *
 * @param record The connection record the edge should be inserted into.
 * @param newEdge The edge to insert
 * @param id ID of the node the issue should be inserted relative to.
 */
export const insertEdgeBefore = (record: RecordProxy, newEdge: RecordProxy, id?: string) => {
	const { EDGES } = ConnectionInterface.get();
	const edges = record.getLinkedRecords(EDGES) ?? [];

	const index = findEdgeIndex(edges, id);
	let nextEdges;
	if (index === -1) {
		nextEdges = [newEdge].concat(edges);
	} else {
		nextEdges = edges.slice(0, index).concat(newEdge).concat(edges.slice(index));
	}
	record.setLinkedRecords(nextEdges, EDGES);
};

/**
 * Modification of [Relay's ConnectionHandler.insertEdgeAfter](https://relay.dev/docs/api-reference/store/#insertedgeafterconnection-recordproxy-newedge-recordproxy-cursor-string)
 * to lookup the edge by node ID rather than cursor. This is required as issues created via inline issue create will not
 * have a cursor, but we still need them to be identifiable for relative insertion.
 *
 * @param record The connection record the edge should be inserted into.
 * @param newEdge The edge to insert
 * @param id ID of the node the issue should be inserted relative to.
 */
export const insertEdgeAfter = (record: RecordProxy, newEdge: RecordProxy, id?: string) => {
	const { EDGES } = ConnectionInterface.get();
	const edges = record.getLinkedRecords(EDGES) ?? [];

	const index = findEdgeIndex(edges, id);
	let nextEdges;
	if (index === -1) {
		nextEdges = edges.concat(newEdge);
	} else {
		nextEdges = edges
			.slice(0, index + 1)
			.concat(newEdge)
			.concat(edges.slice(index + 1));
	}
	record.setLinkedRecords(nextEdges, EDGES);
};

export const hasEdge = (record: RecordProxy, edge: RecordProxy) => {
	const { NODE, EDGES } = ConnectionInterface.get();
	const edges = record.getLinkedRecords(EDGES) ?? [];
	const edgeIndex = edges.findIndex(
		(e) => e.getLinkedRecord(NODE)?.getValue('id') === edge.getLinkedRecord(NODE)?.getValue('id'),
	);
	return edgeIndex > -1;
};
