/**
 * @generated SignedSource<<50d1a3186385343e93273e55cabee208>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_RowInner_issueEdge$data = {
  readonly node: {
    readonly id: string;
    readonly isHighlightedIssueRow: boolean | null | undefined;
    readonly issueId: string;
    readonly " $fragmentSpreads": FragmentRefs<"beforeFirstCell_nativeIssueTable">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge">;
  readonly " $fragmentType": "row_nativeIssueTable_RowInner_issueEdge";
};
export type row_nativeIssueTable_RowInner_issueEdge$key = {
  readonly " $data"?: row_nativeIssueTable_RowInner_issueEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_issueEdge">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_RowInner_issueEdge",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        },
        {
          "kind": "ScalarField",
          "name": "issueId"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "isReparentingEnabled",
              "variableName": "isReparentingEnabled"
            }
          ],
          "kind": "FragmentSpread",
          "name": "beforeFirstCell_nativeIssueTable"
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "isHighlightedIssueRow"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueEdge"
};

(node as any).hash = "4f79d837150c5a57231d1f7399de095b";

export default node;
