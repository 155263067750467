/**
 * @generated SignedSource<<3ae778017890687e6f948da7bd27c83f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type childRowsPagination_issueTableHierarchy_query$data = {
  readonly jira: {
    readonly issueSearch: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "JiraIssueEdge";
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"childRowsPagination_issueTableHierarchy_queryData">;
    };
  };
  readonly " $fragmentType": "childRowsPagination_issueTableHierarchy_query";
};
export type childRowsPagination_issueTableHierarchy_query$key = {
  readonly " $data"?: childRowsPagination_issueTableHierarchy_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"childRowsPagination_issueTableHierarchy_query">;
};

import tableBody_issueTableHierarchy_childIssuesPaginationQuery_graphql from './tableBody_issueTableHierarchy_childIssuesPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "issueSearch"
],
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsContext"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "options"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    },
    {
      "kind": "RootArgument",
      "name": "viewConfigInput"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": tableBody_issueTableHierarchy_childIssuesPaginationQuery_graphql
    }
  },
  "name": "childRowsPagination_issueTableHierarchy_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": "issueSearch",
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                },
                {
                  "kind": "Variable",
                  "name": "fieldSetsInput",
                  "variableName": "fieldSetsInput"
                },
                {
                  "kind": "Variable",
                  "name": "issueSearchInput",
                  "variableName": "issueSearchInput"
                },
                {
                  "kind": "Variable",
                  "name": "options",
                  "variableName": "options"
                },
                {
                  "kind": "Variable",
                  "name": "viewConfigInput",
                  "variableName": "viewConfigInput"
                }
              ],
              "concreteType": "JiraIssueConnection",
              "kind": "LinkedField",
              "name": "__hierarchy_nativeIssueTable__issueSearch_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    },
                    {
                      "concreteType": "JiraIssue",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "isDensityFull",
                      "variableName": "isDensityFull"
                    },
                    {
                      "kind": "Variable",
                      "name": "isInlineEditingEnabled",
                      "variableName": "isInlineEditingEnabled"
                    },
                    {
                      "kind": "Variable",
                      "name": "isReparentingEnabled",
                      "variableName": "isReparentingEnabled"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectKey",
                      "variableName": "projectKey"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectKeys",
                      "variableName": "projectKeys"
                    },
                    {
                      "kind": "Variable",
                      "name": "shouldQueryHasChildren",
                      "variableName": "shouldQueryHasChildren"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "childRowsPagination_issueTableHierarchy_queryData"
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasPreviousPage"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "startCursor"
                    }
                  ]
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__id"
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "jira.issueSearch"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "99eb249d45b9191b7ffc164979f8522f";

export default node;
