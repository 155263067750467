import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updateIssueParentErrorTitle: {
		id: 'native-issue-table.services.reparent-mutation.update-issue-parent-error-title',
		defaultMessage: "Something's gone wrong",
		description: 'Error message informing the user that update an item’s parent has failed.',
	},
	updateIssueParentGenericErrorDescription: {
		id: 'native-issue-table.services.reparent-mutation.update-issue-parent-generic-error-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Flag description. Informs a user that update an item’s parent has failed.',
	},
	updateIssueParent400ErrorDescription: {
		id: 'native-issue-table.services.reparent-mutation.update-issue-parent-400-error-description',
		defaultMessage: 'You don’t have permission to update this issue. Contact a project admin.',
		description:
			'Flag description. Informs a user that they don’t have permission to edit this issue.',
	},
	updateIssueParent404ErrorDescription: {
		id: 'native-issue-table.services.reparent-mutation.update-issue-parent-404-error-description',
		defaultMessage: 'We’re having trouble updating this issue. To continue, refresh the page.',
		description: 'Flag description. Informs a user that issue no longer found.',
	},
	updateIssueParent400ErrorDescriptionIssueTermRefresh: {
		id: 'native-issue-table.services.reparent-mutation.update-issue-parent-400-error-description-issue-term-refresh',
		defaultMessage: 'You don’t have permission to update this work item. Contact a project admin.',
		description:
			'Flag description. Informs a user that they don’t have permission to edit this issue.',
	},
	updateIssueParent404ErrorDescriptionIssueTermRefresh: {
		id: 'native-issue-table.services.reparent-mutation.update-issue-parent-404-error-description-issue-term-refresh',
		defaultMessage: 'We’re having trouble updating this work item. To continue, refresh the page.',
		description: 'Flag description. Informs a user that issue no longer found.',
	},
});
