import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import type { issueStatus_nativeIssueTable_IssueStatusCell$data } from '@atlassian/jira-relay/src/__generated__/issueStatus_nativeIssueTable_IssueStatusCell.graphql';

export type JiraStatusField = issueStatus_nativeIssueTable_IssueStatusCell$data['status'];

export const toJiraStatusField = (statusValue: StatusValue): JiraStatusField => ({
	statusId: statusValue.id,
	name: statusValue.name,
	statusCategory: {
		statusCategoryId: `${statusValue.statusCategory.id}`,
	},
});

export const toStatusValue = (jiraStatusField: JiraStatusField): StatusValue => ({
	id: jiraStatusField.statusId,
	name: jiraStatusField.name ?? '',
	statusCategory: {
		id: parseInt(jiraStatusField.statusCategory?.statusCategoryId ?? '', 10),
	},
});
