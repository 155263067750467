/** @jsx jsx */
import React, { forwardRef, type RefCallback } from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export type Props = {
	/**
	 * Tooltip shown when the user hovers over the show more button, which appears as a "+X" button
	 * when more than 1 item is present.
	 */
	showMoreTooltip: string;
	/**
	 * The number of items displayed in the popup. This is what the user will see before opening the popup. If this is <1, the popup will not be displayed.
	 */
	totalItems: number;
	/**
	 * Component that will be displayed inside the popup.
	 */
	PopupComponent: React.JSX.Element;

	/**
	 * The open state of the Show more popup
	 * Default value is false
	 */
	isOpen: boolean;
	onSetIsOpen: (isOpen: boolean) => void;
	triggerRefCallback?: RefCallback<HTMLElement>;
	/**
	 * The source of the show more popup, used for analytics.
	 * Will be preprended to "ItemWithPopup" in analytics events, i.e. "issueLinksItemWithPopup"
	 */
	actionSubjectIdPrefix: string;
};

const ShowMorePopup = ({
	showMoreTooltip,
	totalItems,
	PopupComponent,
	isOpen,
	onSetIsOpen,
	triggerRefCallback,
	actionSubjectIdPrefix,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (totalItems === 0) {
		return null;
	}

	const onClickTrigger = () => {
		onSetIsOpen(!isOpen);
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			`${actionSubjectIdPrefix}ItemWithPopup`,
		);
	};

	const content = () => (
		<>
			<Box xcss={ContainerDialogStyles}>{PopupComponent}</Box>
			<ShortcutScope />
		</>
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => onSetIsOpen(false)}
			placement="bottom"
			content={content}
			testId="item-with-popup.ui.item-with-popup.show-more-popup.show-more-popup"
			messageId="item-with-popup.ui.item-with-popup.show-more-popup.popup"
			messageType="transactional"
			trigger={(triggerProps) => (
				<Tooltip key="triggerLinksDialog" content={showMoreTooltip} tag={TooltipContainer}>
					<Button
						{...triggerProps}
						aria-label={`${showMoreTooltip} +${totalItems}`}
						isSelected={isOpen}
						onClick={onClickTrigger}
						spacing="none"
						ref={(element) => {
							triggerRefCallback && triggerRefCallback(element);
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(triggerProps.ref as RefCallback<HTMLElement>)(element);
						}}
					>
						<Box as="span" xcss={itemsCountStyles} paddingInline="space.050">
							+{totalItems}
						</Box>
					</Button>
				</Tooltip>
			)}
		/>
	);
};

const TooltipContainer: TooltipProps['tag'] = forwardRef((props, ref) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box {...props} ref={ref} as="span" xcss={tooltipContainerStyles} />
));

const ContainerDialogStyles = xcss({
	width: '240px',
	maxHeight: '220px',
	overflow: 'auto',
	padding: 'space.100',
	border: `2px solid ${token('color.border.focused')}`,
});

const tooltipContainerStyles = xcss({
	maxWidth: '100%',
});

const itemsCountStyles = xcss({
	fontWeight: token('font.weight.regular'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.250'),
});

export default ShowMorePopup;
