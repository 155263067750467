import React from 'react';
import { WrappedStatusLozenge } from '../../common/ui/status-lozenge/index.tsx';
import type { Props } from './types.tsx';

export const StatusLozenge = (props: Props) => {
	const status = props.value || props.initialValue;
	if (!status) {
		return null;
	}

	return (
		<WrappedStatusLozenge
			status={status}
			isEditable={false}
			hasHoverState={false}
			focused={false}
			contentRef={props.contentRef}
		/>
	);
};

export const StatusLozengeWithDropdownIndicator = (props: Props) => {
	const status = props.value || props.initialValue;
	if (!status) {
		return null;
	}

	return (
		<WrappedStatusLozenge
			status={status}
			isEditable={false}
			hasHoverState={false}
			focused={false}
			shouldShowDropdownIndication
		/>
	);
};
