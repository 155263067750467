/**
 * @generated SignedSource<<73f64d6bbc7e03fab4a20dcf3e112eff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_issue$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_issue" | "row_nativeIssueTable_RowInner_issue" | "row_nativeIssueTable_RowWithDragAndDrop_issue" | "row_nativeIssueTable_RowWithSiblingCreate_issue">;
  readonly " $fragmentType": "row_nativeIssueTable_issue";
};
export type row_nativeIssueTable_issue$key = {
  readonly " $data"?: row_nativeIssueTable_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_issue">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isDensityFull",
    "variableName": "isDensityFull"
  },
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_issue",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowInner_issue"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithSiblingCreate_issue"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issue"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithDragAndDrop_issue"
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "4859b1b0140c4d135bcce90e54a15277";

export default node;
