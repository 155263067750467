import React, { type ErrorInfo, memo, useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, TEAM_NAME } from '../../constants.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../controllers/features/selectors.tsx';
import { HoverPopover } from '../hover-popover/index.tsx';
import messages from './messages.tsx';

type Props = {
	cellType: string;
	reason: string | Error;
	errorInfo?: ErrorInfo;
};

const ErrorCell = ({ cellType, reason, errorInfo }: Props) => {
	const { formatMessage } = useIntl();
	const [isHovered, setIsHovered] = useState(false);

	const onMouseEnter = useCallback(() => setIsHovered(true), []);
	const onMouseLeave = useCallback(() => setIsHovered(false), []);

	const iconColor = isHovered ? token('color.icon') : token('color.icon.subtle');

	const message = formatMessage(messages.tooltip);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	useEffect(() => {
		const sendAnalytics = () => {
			const error = new Error(
				`Failed to render cell data for the ${cellType} cell type. Reason: ${reason}`,
			);
			fireErrorAnalytics({
				meta: {
					id: 'errorCell',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				errorInfo,
				event: createAnalyticsEvent({}),
				attributes: {
					cellType,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		};
		const sendAnalyticsDebounced = debounce(sendAnalytics);
		sendAnalyticsDebounced();
		// Cancel any debounced events on unmount. This prevents sending an error event when the cell was mounted
		// due to an intermediary render. We've observed this when new columns are committed to the Relay store
		// in a separate render before issue field sets are aligned.
		return () => {
			sendAnalyticsDebounced.cancel();
		};
		// Only fire on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const cellContent = (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="native-issue-table.common.error-cell.tooltip-target"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<WarningIcon color={iconColor} label={message} spacing="spacious" />
		</div>
	);

	if (isSingleLineRowHeightEnabled) {
		return (
			<HoverPopover content={message} minimumWidth="narrow">
				{cellContent}
			</HoverPopover>
		);
	}

	return (
		<Tooltip content={message} position="bottom-start">
			{cellContent}
		</Tooltip>
	);
};

export default memo<Props>(ErrorCell);
