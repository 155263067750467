/**
 * @generated SignedSource<<64112bc4a30b3469275bdfcb6eadd44c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedVersionsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly label: string | null | undefined;
        readonly value: string;
        readonly versionId: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"multiVersionPicker_issueFieldMultiVersionPickerReadviewFull_MultiVersionPickerReadView">;
  readonly " $fragmentType": "multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef";
};
export type multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "multiVersionPicker_issueFieldMultiVersionPickerReadviewFull_MultiVersionPickerReadView"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "value",
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "alias": "label",
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "versionId"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedVersionsConnection(first:1000)"
    }
  ],
  "type": "JiraMultipleVersionPickerField"
};

(node as any).hash = "f3af5be7640f993ae73c44b61cc0fcb5";

export default node;
