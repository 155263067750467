/**
 * @generated SignedSource<<ab4cedce41cbb321f6ef8976f1af3dc3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueKey_nativeIssueTable_IssueKeyCell_fieldRef$data = {
  readonly text: string | null | undefined;
  readonly " $fragmentType": "issueKey_nativeIssueTable_IssueKeyCell_fieldRef";
};
export type issueKey_nativeIssueTable_IssueKeyCell_fieldRef$key = {
  readonly " $data"?: issueKey_nativeIssueTable_IssueKeyCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueKey_nativeIssueTable_IssueKeyCell_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueKey_nativeIssueTable_IssueKeyCell_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "79f6c1453d4dd585ed1c4eab8fbd7fc1";

export default node;
