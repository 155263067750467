import React, { memo, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { COMPOUND_OPERATOR_AND, OPERATOR_EQUALS, creators, print } from '@atlaskit/jql-ast';
import { useIntl } from '@atlassian/jira-intl';
import ListCellWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import type { versions_nativeIssueTable_VersionsCell$key } from '@atlassian/jira-relay/src/__generated__/versions_nativeIssueTable_VersionsCell.graphql';
import { escapeBackslashes, isNonNullish } from '../../../utils/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: versions_nativeIssueTable_VersionsCell$key;
	issueSearchBaseUrl: string;
	projectKey: string;
	jqlTerm: string;
};

/**
 * Expected JQL Output: `project = ${projectKey} AND ${jqlTerm} = ${name}`
 */
const getLinkJql = (projectKey: string, jqlTerm: string, name: string) => {
	const jast = creators.jast(
		creators.query(
			creators.compoundClause(creators.compoundOperator(COMPOUND_OPERATOR_AND), [
				creators.terminalClause(
					creators.field('project'),
					creators.operator(OPERATOR_EQUALS),
					creators.valueOperand(projectKey),
				),
				creators.terminalClause(
					creators.field(escapeBackslashes(jqlTerm)),
					creators.operator(OPERATOR_EQUALS),
					creators.valueOperand(escapeBackslashes(name)),
				),
			]),
		),
	);

	return print(jast, {
		operatorCase: 'upper',
	});
};

export const VersionsCell = memo<Props>(({ fieldRef, issueSearchBaseUrl, projectKey, jqlTerm }) => {
	const { formatMessage } = useIntl();
	const { name: fieldName, selectedVersionsConnection } =
		useFragment<versions_nativeIssueTable_VersionsCell$key>(
			graphql`
				fragment versions_nativeIssueTable_VersionsCell on JiraMultipleVersionPickerField {
					name
					selectedVersionsConnection(first: 1000) {
						... on JiraVersionConnection {
							edges {
								node {
									name
								}
							}
						}
					}
				}
			`,
			fieldRef,
		);

	const versions = selectedVersionsConnection?.edges;

	const filteredVersions = useMemo(
		() =>
			versions
				?.map((edge) =>
					edge?.node?.name != null
						? {
								key: edge.node.name,
								name: edge.node.name,
								href: `${issueSearchBaseUrl}?jql=${encodeURIComponent(
									getLinkJql(projectKey, jqlTerm, edge.node.name),
								)}`,
							}
						: null,
				)
				.filter(isNonNullish) ?? [],
		[versions, issueSearchBaseUrl, projectKey, jqlTerm],
	);

	if (!versions || versions.length !== filteredVersions.length || !projectKey) {
		return <ErrorCell cellType="versions" reason="Missing required fields" />;
	}

	return (
		<ListCellWithPopup
			items={filteredVersions}
			ItemComponent={TagItem}
			PopupContentWrapper={TagItemPopupContentWrapper}
			showMoreTooltip={formatMessage(messages.triggerButtonGeneric, { fieldName })}
		/>
	);
});
