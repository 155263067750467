/**
 * @generated SignedSource<<7b67d3ba091d283a05c57cfce22eb7bf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueTable_nativeIssueTable_groupResults$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly firstGroup?: {
    readonly id: string;
    readonly issues: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly issueId: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"groupTableBody_nativeIssueTable_groupResults">;
  readonly " $fragmentType": "issueTable_nativeIssueTable_groupResults";
};
export type issueTable_nativeIssueTable_groupResults$key = {
  readonly " $data"?: issueTable_nativeIssueTable_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueTable_nativeIssueTable_groupResults">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isPaginating"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "issueTable_nativeIssueTable_groupResults",
  "selections": [
    {
      "condition": "isPaginating",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "concreteType": "JiraSpreadsheetGroup",
          "kind": "LinkedField",
          "name": "firstGroup",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "fieldSetsInput",
                  "variableName": "fieldSetsInput"
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 50
                }
              ],
              "concreteType": "JiraIssueConnection",
              "kind": "LinkedField",
              "name": "issues",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraIssue",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "issueId"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraSpreadsheetGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSpreadsheetGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isPaginating",
          "variableName": "isPaginating"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "groupTableBody_nativeIssueTable_groupResults"
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};
})();

(node as any).hash = "ae30dfef3630eafb8bc50e6e7501ea3c";

export default node;
