import React, { useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import type {
	firstGroupIssues_childIssuesQuery,
	firstGroupIssues_childIssuesQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/firstGroupIssues_childIssuesQuery.graphql';
import { ChildRowsPagination } from '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows-pagination/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ChildRowsPagination as ChildRowsPaginationOld } from '../../../hierarchy/index.tsx';
import { CreateIssueController } from '../../../../services/issue-create-mutation/index.tsx';
import { useScrollStateSelector } from '../../../../controllers/scroll-state/index.tsx';
import { useIsIssueRankDescending } from '../../../../controllers/features/selectors.tsx';
import {
	CHILD_QUERY_PAGE_SIZE,
	ANALYTICS_SOURCE,
	GROUPED_ITEMS_EXPERIENCE,
	GROUPED_LOAD_MORE_EXPERIENCE,
} from '../../../../common/constants.tsx';
import { Rows } from '../../../rows/index.tsx';

export type Props = {
	variables: firstGroupIssues_childIssuesQuery$variables;
	groupId: string;
	groupConnectionId: string;
	groupFieldValue: string | null;
};

export const FirstGroupIssues = ({
	variables,
	groupId,
	groupConnectionId,
	groupFieldValue,
}: Props) => {
	const data = useLazyLoadQuery<firstGroupIssues_childIssuesQuery>(
		graphql`
			query firstGroupIssues_childIssuesQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$first: Int
				$last: Int
				$before: String
				$after: String
				$namespace: String
				$viewId: String
				$options: JiraIssueSearchOptions
				$filterId: String
				$amountOfColumns: Int!
				$fieldSetIds: [String!]!
				$fieldSetsInput: JiraIssueSearchFieldSetsInput!
				$shouldQueryFieldSetsById: Boolean!
				$fieldSetsContext: JiraIssueSearchViewFieldSetsContext
				$viewConfigInput: JiraIssueSearchViewConfigInput
				$shouldQueryHasChildren: Boolean!
			) {
				...childRowsPagination_issueTableHierarchy_query
					@arguments(shouldQueryHasChildren: $shouldQueryHasChildren)
				...hierarchy_nativeIssueTable_ChildRowsPagination_query
					@arguments(shouldQueryHasChildren: $shouldQueryHasChildren)
				jira {
					issueSearch(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
						fieldSetsInput: $fieldSetsInput
						viewConfigInput: $viewConfigInput
					)
						@connection(key: "hierarchy_nativeIssueTable__issueSearch")
						@optIn(to: "JiraSpreadsheetComponent-M1") {
						edges {
							__typename
						}
					}
				}
			}
		`,
		variables,
		{ fetchPolicy: 'store-only' },
	);

	const connectionMetadata = useMemo(
		() =>
			variables.issueSearchInput.jql
				? {
						type: 'GROUP_CHILDREN' as const,
						fieldValue: groupFieldValue,
						groupId,
						jql: variables.issueSearchInput.jql,
					}
				: undefined,
		[groupFieldValue, groupId, variables.issueSearchInput.jql],
	);

	let width = 0;
	let isIssueRankDescending = false;
	if (fg('jira_list_hierarchy_extraction')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		width = useScrollStateSelector((scrollState) => scrollState.width);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueRankDescending = useIsIssueRankDescending();
	}

	// Ensure relay cache is in correct state
	if (!data?.jira?.issueSearch?.edges) {
		return null;
	}

	if (fg('jira_list_hierarchy_extraction')) {
		return (
			<ChildRowsPagination
				query={data}
				depth={0}
				parentItemId={groupId}
				groupId={groupId}
				itemsConnectionId={groupConnectionId}
				connectionMetadata={connectionMetadata}
				width={width}
				isIssueRankDescending={isIssueRankDescending}
				pageSize={CHILD_QUERY_PAGE_SIZE}
				childItemsExperience={GROUPED_ITEMS_EXPERIENCE}
				childItemsLoadMoreExperience={GROUPED_LOAD_MORE_EXPERIENCE}
				analyticsSource={ANALYTICS_SOURCE}
				renderRows={(rowProps) => <Rows {...rowProps} />}
				renderCreateIssueController={(args) => <CreateIssueController {...args} />}
			/>
		);
	}

	return (
		<ChildRowsPaginationOld
			query={data}
			depth={0}
			parentItemId={groupId}
			itemsConnectionId={groupConnectionId}
			connectionMetadata={connectionMetadata}
		/>
	);
};
