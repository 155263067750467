/**
 * @generated SignedSource<<ab89800218576513b704c68f8ce1ac28>>
 * @relayHash b4af6905e862873a8846fa4a71f56a57
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 12ff733a258befd165b334ced2a9a4ad512afe20c3502276964dc077438a292d

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateMultipleSelectFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraMultipleSelectFieldOperationInput>;
};
export type JiraMultipleSelectFieldOperationInput = {
  ids: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation$variables = {
  input: JiraUpdateMultipleSelectFieldInput;
};
export type multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation$data = {
  readonly jira: {
    readonly updateMultipleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateMultipleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly selectedOptions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation = {
  rawResponse: multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation$rawResponse;
  response: multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation$data;
  variables: multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraMultipleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateMultipleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraMultipleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraMultipleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateMultipleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraMultipleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraOptionConnection",
                    "kind": "LinkedField",
                    "name": "selectedOptions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraOptionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraOption",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "12ff733a258befd165b334ced2a9a4ad512afe20c3502276964dc077438a292d",
    "metadata": {},
    "name": "multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "cb08d7ecaba6a03f3f00edae8eb1b5a3";

export default node;
