import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { SingleSelectInlineEditViewIsEditable } from '@atlassian/jira-issue-field-single-select-inline-edit-full/src/ui/single-select/lite/index.tsx';
import type { singleSelect_nativeIssueTable_SingleSelectCell$key } from '@atlassian/jira-relay/src/__generated__/singleSelect_nativeIssueTable_SingleSelectCell.graphql';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: singleSelect_nativeIssueTable_SingleSelectCell$key;
};

export const SingleSelectCell = ({ fieldRef }: Props) => {
	const data = useFragment<singleSelect_nativeIssueTable_SingleSelectCell$key>(
		graphql`
			fragment singleSelect_nativeIssueTable_SingleSelectCell on JiraSingleSelectField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<SingleSelectInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
		/>
	);
};
