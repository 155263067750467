import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import InlineCreateFormEntrypoint from './issue-type-dropdown/entrypoint.tsx';

export const useInlineIssueCreateEntryPoint = (
	projectKey: string | null,
	minHierarchyLevel?: number,
	maxHierarchyLevel?: number,
) => {
	const cloudId = useCloudId();
	return useEntryPoint(InlineCreateFormEntrypoint, {
		cloudId,
		projectKey,
		minHierarchyLevel: minHierarchyLevel ?? undefined,
		maxHierarchyLevel: maxHierarchyLevel ?? undefined,
	});
};
