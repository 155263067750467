import React, { forwardRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { CascadingSelectInlineEditViewIsEditable } from '@atlassian/jira-issue-field-cascading-select-inline-edit-full/src/ui/cascading-select/index.tsx';
import type { cascadingSelect_nativeIssueTable_CascadingSelectCell$key } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_nativeIssueTable_CascadingSelectCell.graphql';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';

export type Props = {
	fieldRef: cascadingSelect_nativeIssueTable_CascadingSelectCell$key;
};

export const CascadingSelectCell = ({ fieldRef }: Props) => {
	const data = useFragment<cascadingSelect_nativeIssueTable_CascadingSelectCell$key>(
		graphql`
			fragment cascadingSelect_nativeIssueTable_CascadingSelectCell on JiraCascadingSelectField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<CascadingSelectInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="start"
			editViewPopupTarget={CustomTarget}
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
			menuPosition="fixed"
			isTruncated
		/>
	);
};

const CustomTarget = forwardRef<HTMLDivElement, {}>((_, ref) => {
	return <Box xcss={customTargetStyles} ref={ref} />;
});

const customTargetStyles = xcss({
	width: '0',
	position: 'absolute',
	top: 'space.0',
});
