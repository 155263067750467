import React, { useState } from 'react';
import { Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import ItemWithPopup from '../item-with-popup/index.tsx';
import { ErrorFallback } from './error-fallback/index.tsx';
import type { Props } from './types.tsx';

export const ItemWithEntryPointPopup = <TEntryPoint extends AnyEntryPoint>({
	entryPoint,
	entryPointParams,
	entryPointRuntimeProps,
	...props
}: Props<TEntryPoint>) => {
	const [isOpen, setIsOpen] = useState(false);
	// eslint-disable-next-line @atlassian/react-entrypoint/prefer-entrypoint-file-import
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		entryPoint,
		entryPointParams,
	);

	const [retryKey, setRetryKey] = useState(0);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	return (
		<ItemWithPopup
			isOpen={isOpen}
			onSetIsOpen={setIsOpen}
			triggerRefCallback={triggerRef}
			PopupComponent={
				<JiraEntryPointContainer
					key={retryKey}
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="popup-content"
					packageName="jiraItemWithPopup"
					errorFallback={() => <ErrorFallback onClick={() => setRetryKey((prev) => prev + 1)} />}
					fallback={
						<Box padding="space.050">
							<Spinner size="medium" />
						</Box>
					}
					runtimeProps={entryPointRuntimeProps}
				/>
			}
			{...props}
		/>
	);
};
