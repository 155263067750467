import React, { type ComponentType, type FC, useState } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ErrorState } from '../common/ui/error-state/index.tsx';
import type { BaseProps as Props } from './index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const MeatballMenu = lazyAfterPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-meatball-menu" */ './index'),
	{ ssr: false },
);

const Fallback = ({ onRetry }: { onRetry: () => void }) => (
	<DropdownMenu
		trigger={({ triggerRef, isSelected, ...props }) => {
			const iconColor = isSelected ? token('color.icon.selected') : token('color.icon.subtle');

			return (
				<Button
					{...props}
					appearance="subtle"
					iconBefore={
						<MoreIcon
							label=""
							LEGACY_size="medium"
							color={iconColor}
							LEGACY_primaryColor={iconColor}
							spacing="spacious"
						/>
					}
					ref={triggerRef}
					isSelected={isSelected}
				/>
			);
		}}
		placement="bottom-end"
	>
		<ErrorState onRetry={onRetry} />
	</DropdownMenu>
);

const AsyncMeatballMenu: FC<Props> = (props: Props) => {
	const [resetCaughtError, setResetCaughtError] = useState<Error>();
	const { formatMessage } = useIntl();

	return (
		<JSErrorBoundary
			packageName="jiraIssueNavigatorIssueOperations"
			id="async-meatball-menu"
			sendToPrivacyUnsafeSplunk
			// Attempt to re-render children when retry is pressed in the fallback error state
			fallback={({ error }) => <Fallback onRetry={() => setResetCaughtError(error)} />}
			onError={() => setResetCaughtError(undefined)}
			resetCaughtError={resetCaughtError}
		>
			<Placeholder
				name="async-meatball-menu"
				fallback={
					<Button
						id={`async-meatball-menu-button-fallback-${props.issueId}`}
						aria-label={formatMessage(messages.disabledMenuButtonLabel, {
							issueKey: props.issueKey,
						})}
						appearance="subtle"
						iconBefore={
							<MoreIcon label="" LEGACY_size="medium" color="currentColor" spacing="spacious" />
						}
						isDisabled
					/>
				}
			>
				<MeatballMenu {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};
export default AsyncMeatballMenu;
