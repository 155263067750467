import React from 'react';
import type { Props as InlineCreateFormProps } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/inline-create-form/index.tsx';
import { IssueCreateCell } from './issue-create-cell/index.tsx';

export type Props = Omit<InlineCreateFormProps, 'onCreateIssue' | 'contextualFields'> & {
	isFormVisible: boolean;
	issuesConnectionId: string;
	siblingIssueId: string;
	parentIssueAri?: string;
};

export const IssueCreateRow = ({ isFormVisible, ...props }: Props) => {
	return (
		isFormVisible && (
			<tr>
				<IssueCreateCell {...props} />
			</tr>
		)
	);
};
