import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	type MouseEventHandler,
	type UIEventHandler,
} from 'react';
import throttle from 'lodash/throttle';
import type { TriggerPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { useIsSiblingIssueCreateEnabled } from '../features/selectors.tsx';
import { useSiblingCreateActiveIndexActions } from '../sibling-create-active-index/index.tsx';
import { ISSUE } from '../sibling-create-active-index/types.tsx';

const MOUSE_MOVE_THROTTLE = 16;
const SIBLING_CREATE_HOVER_AREA = 12;

type EventHandlers = {
	onMouseMove?: MouseEventHandler;
	onMouseLeave?: MouseEventHandler;
};

type Context = {
	isFormVisible?: boolean;
	triggerProps?: TriggerPropsType;
};

export const SiblingIssueCreateRowContext = createContext<Context>({});

export const useSiblingIssueCreateRow = (): Context => {
	const context = useContext(SiblingIssueCreateRowContext);
	const isSiblingIssueCreateEnabled = useIsSiblingIssueCreateEnabled();

	return isSiblingIssueCreateEnabled ? context : {};
};

/**
 * Returns mouse events to apply to a table row to enable hiding/showing of the sibling issue create button when
 * hovering the row element. If sibling issue creation is disabled then these mouse events will be `undefined`.
 *
 * @param connectionId Relay ID of the issue connection record.
 * @param edgeIndex Index of the issue within a connection.
 */
export const useSiblingIssueCreateHandler = (
	connectionId: string,
	edgeIndex: number,
): EventHandlers => {
	const isSiblingIssueCreateEnabled = useIsSiblingIssueCreateEnabled();
	const { setIndex, clearIndex, clearIndexAfterDelay } = useSiblingCreateActiveIndexActions();

	const calculateRowIndex = useMemo(
		() =>
			throttle((event) => {
				const currentRow = event.currentTarget;
				if (!currentRow) {
					return;
				}

				// Calculate the y position of the mouse relative to the top of the current row.
				const currentRowRect = currentRow.getBoundingClientRect();
				// This calculation ranges from -1 to 39, instead of 0 to 40, potentially due to the row's border.
				// For this reason, we add 1 to ensure the value is correct.
				const currentRowMouseVerticalOffset = event.clientY - currentRowRect.top + 1;

				if (
					currentRowMouseVerticalOffset >= 0 &&
					currentRowMouseVerticalOffset <= SIBLING_CREATE_HOVER_AREA
				) {
					// When hovering the top part of a row, the trigger should be rendered at the top of the current row.
					setIndex(ISSUE, [connectionId, edgeIndex]);
				} else if (
					currentRowMouseVerticalOffset >
					currentRowRect.height - SIBLING_CREATE_HOVER_AREA
				) {
					// When hovering the bottom part of a row, the trigger should be rendered at the top of the next row.
					setIndex(ISSUE, [connectionId, edgeIndex + 1]);
				} else {
					// When hovering the middle part of a row, the trigger should not be rendered.
					clearIndex(ISSUE);
				}
			}, MOUSE_MOVE_THROTTLE),
		[connectionId, edgeIndex, clearIndex, setIndex],
	);

	const onMouseLeave: MouseEventHandler = useCallback(() => {
		clearIndexAfterDelay(ISSUE);
	}, [clearIndexAfterDelay]);

	const onMouseMove: MouseEventHandler = useCallback(
		(event) => {
			calculateRowIndex(event);
		},
		[calculateRowIndex],
	);

	return isSiblingIssueCreateEnabled ? { onMouseLeave, onMouseMove } : {};
};

/**
 * Returns an `onScroll` event to apply to the table scrollable container which will hide any active sibling issue
 * create buttons while scrolling. If sibling issue creation is disabled then this event will be `undefined`.
 */
export const useSiblingIssueCreateOnScroll = (): UIEventHandler | undefined => {
	const isSiblingIssueCreateEnabled = useIsSiblingIssueCreateEnabled();
	const { clearIndex } = useSiblingCreateActiveIndexActions();
	return useMemo(
		() =>
			isSiblingIssueCreateEnabled
				? throttle(() => {
						clearIndex(ISSUE);
					}, MOUSE_MOVE_THROTTLE)
				: undefined,
		[clearIndex, isSiblingIssueCreateEnabled],
	);
};
