/**
 * @generated SignedSource<<5cea65a179854d7e08a64f57841499df>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type parent_nativeIssueTable_ParentCell_fieldRef$data = {
  readonly id: string;
  readonly parentIssue: {
    readonly key: string;
    readonly summaryField: {
      readonly text: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentReadviewFull_ParentReadView" | "parent_nativeIssueTable_ParentCell_data">;
  readonly " $fragmentType": "parent_nativeIssueTable_ParentCell_fieldRef";
};
export type parent_nativeIssueTable_ParentCell_fieldRef$key = {
  readonly " $data"?: parent_nativeIssueTable_ParentCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"parent_nativeIssueTable_ParentCell_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "parent_nativeIssueTable_ParentCell_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "text"
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "parent_issueFieldParentReadviewFull_ParentReadView"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "isInlineEditingEnabled",
              "variableName": "isInlineEditingEnabled"
            }
          ],
          "kind": "FragmentSpread",
          "name": "parent_nativeIssueTable_ParentCell_data"
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
};

(node as any).hash = "2a93235cfca7356f6c6155a5a26e263c";

export default node;
