import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { xcss, Box } from '@atlaskit/primitives';
import { SimpleTag } from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import type { checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView$key as CheckboxSelectFragment } from '@atlassian/jira-relay/src/__generated__/checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView.graphql';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import ListWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import messages from './messages.tsx';
import type { CheckboxSelectReadViewProps } from './types.tsx';

/**
 * The CheckboxSelectReadView will show a read only view of CheckboxSelect field.
 * @param props [CheckboxSelectReadViewProps](./types.tsx)
 */
export const CheckboxSelectReadView = ({
	fragmentRef,
	isTruncated,
}: CheckboxSelectReadViewProps) => {
	const data = useFragment<CheckboxSelectFragment>(
		graphql`
			fragment checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView on JiraCheckboxesField {
				selectedOptions {
					edges {
						node {
							id
							value
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const { formatMessage } = useIntl();

	const selectedOptions =
		data?.selectedOptions?.edges
			?.filter((edge) => Boolean(edge?.node?.value))
			.map((edge) => ({ name: edge?.node?.value || '', key: edge?.node?.id || '' })) || [];

	if (!selectedOptions?.length) {
		return <EmptyFieldText />;
	}
	if (isTruncated) {
		return (
			<Box paddingBlock="space.050">
				<ListWithPopup
					items={selectedOptions}
					ItemComponent={TagItem}
					PopupContentWrapper={TagItemPopupContentWrapper}
					showMoreTooltip={formatMessage(messages.showMoreTooltip)}
				/>
			</Box>
		);
	}

	return (
		<Box xcss={containerWithNegativeMarginOffset}>
			{selectedOptions.map((value) => (
				<SimpleTag key={value.key} text={value.name} />
			))}
		</Box>
	);
};

const containerWithNegativeMarginOffset = xcss({
	display: 'flex',
	width: '100%',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	// Tag has a built-in margin of 4px, so we use a negative side margin to allow tags to align with their parent.
	marginTop: 'space.0',
	marginRight: 'space.negative.050',
	marginBottom: 'space.0',
	marginLeft: 'space.negative.050',
});
