import parameters from '@atlassian/jira-relay/src/__generated__/issueTypeDropdownIssueType_projectDataQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	projectKey: string | null;
	minHierarchyLevel?: number;
	maxHierarchyLevel?: number;
};

const IssueTypeDropdownEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-issue-table-inline-create-issue-type" */ './index').then(
			(module) => module.IssueTypeDropdownEntryPoint,
		),
	),
	getPreloadProps: ({
		cloudId,
		projectKey,
		minHierarchyLevel,
		maxHierarchyLevel,
	}: EntryPointParams) =>
		({
			queries: {
				projectData: {
					options: {
						fetchPolicy: 'store-or-network',
					},
					parameters,
					variables: {
						cloudId,
						projectKey: projectKey ?? '',
						hasProjectKey: !!projectKey,
						...(minHierarchyLevel != null
							? {
									filter: {
										minHierarchyLevel,
										maxHierarchyLevel,
									},
								}
							: {}),
					},
				},
			},
		}) as const,
});

export default IssueTypeDropdownEntryPoint;
