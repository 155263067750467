import React, {
	useCallback,
	useRef,
	type ChangeEvent,
	type KeyboardEvent,
	type MouseEvent,
} from 'react';
import Checkbox from '@atlaskit/checkbox';
import { Inline } from '@atlaskit/primitives';
import { abortAll } from '@atlaskit/react-ufo/interaction-metrics';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	type UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useFlatListIssueIds,
	useIssueIds,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import {
	useIsIssueSelected,
	useIssueTableSelectedIssuesActions,
	useIssueTableSelectedIssues,
	useAreAllIssuesSelected,
} from '../../../../controllers/selected-issues/index.tsx';
import { useSelectedRowActions } from '../../../../controllers/selected-row-state/index.tsx';
import { messages } from './messages.tsx';
import type { CheckboxCellProps, HeaderCheckboxCellProps } from './types.tsx';

const EMPTY_ARRAY: string[] = [];

export const CheckboxCell = ({ issueId, rowIndex, ...checkboxProps }: CheckboxCellProps) => {
	const { formatMessage } = useIntl();
	const isIssueSelected = useIsIssueSelected(issueId);
	// TODO Remove useSelectedRowActions when cleaning up jsc_m2_hierarchy_fe_changes
	const { checkRow: checkRowWithoutHierarchy } = useSelectedRowActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	let flatListIssueIds = EMPTY_ARRAY;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		flatListIssueIds = fg('jira_list_grouping_bulk_ops') ? useIssueIds() : useFlatListIssueIds();
	}

	const { selectedOnlyIssueId, checkIssueRow } = useIssueTableSelectedIssuesActions();
	const isShiftPressed = useRef(false);

	const onCheckboxChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
			const toggleCheckboxChecked = e.target.checked;
			fireUIAnalytics(analyticsEvent, 'newIssueNavigatorTableRow', {
				isCheckboxChecked: toggleCheckboxChecked,
				selectionType: isShiftPressed.current ? 'shift' : 'default',
			});
			if (fg('jsc_m2_hierarchy_fe_changes')) {
				checkIssueRow(issueId, toggleCheckboxChecked, isShiftPressed.current, flatListIssueIds);
			} else {
				checkRowWithoutHierarchy(rowIndex, toggleCheckboxChecked, isShiftPressed.current);
			}
		},
		[checkIssueRow, issueId, flatListIssueIds, checkRowWithoutHierarchy, rowIndex],
	);

	const handleSelectedOnlyIssueId = useCallback(
		(ev: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
			ev.preventDefault();
			const analyticsEvent = createAnalyticsEvent({
				actionSubject: 'checkbox',
				action: 'changed',
			});
			fireUIAnalytics(analyticsEvent, 'newIssueNavigatorTableRow', {
				isCheckboxChecked: true,
				selectionType: 'ctrl',
			});
			selectedOnlyIssueId(issueId);
		},
		[createAnalyticsEvent, selectedOnlyIssueId, issueId],
	);

	return (
		<Inline alignInline="center">
			<Checkbox
				{...checkboxProps}
				aria-label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.checkboxAriaLabelIssueTermRefresh
						: messages.checkboxAriaLabel,
				)}
				isChecked={isIssueSelected}
				onChange={onCheckboxChange}
				onClick={(ev) => {
					isShiftPressed.current = ev.shiftKey;
					abortAll('new_interaction');
				}}
				onKeyDown={(ev) => {
					if (!isIssueSelected && ev.ctrlKey && ev.code === 'Space') {
						handleSelectedOnlyIssueId(ev);
					}
				}}
				onContextMenu={(ev) => {
					if (!isIssueSelected && ev.ctrlKey) {
						handleSelectedOnlyIssueId(ev);
					}
				}}
			/>
		</Inline>
	);
};

export const HeaderCheckboxCell = ({
	selectableIssueIds,
	groupByFieldId,
	...checkboxProps
}: HeaderCheckboxCellProps) => {
	const { formatMessage } = useIntl();
	const selectedIssueIds = useIssueTableSelectedIssues();
	const { insertSelectedIssueIds, reset } = useIssueTableSelectedIssuesActions();

	const prevGroupByFieldId = useRef(groupByFieldId);

	// eslint-disable-next-line jira/ff/no-preconditioning
	if (fg('jira_list_grouping_bulk_ops') && prevGroupByFieldId.current !== groupByFieldId) {
		prevGroupByFieldId.current = groupByFieldId;
		reset();
	}

	let issueIds;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issueIds = fg('jira_list_grouping_bulk_ops') ? useIssueIds() : useFlatListIssueIds();
	} else {
		issueIds = selectableIssueIds;
	}

	const isChecked = useAreAllIssuesSelected(issueIds);

	const isIndeterminate = !isChecked && selectedIssueIds.length > 0;

	const onCheckboxChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
			const toggleCheckboxChecked = e.target.checked;
			fireUIAnalytics(analyticsEvent, 'bulkSelectTableRows', {
				isCheckboxChecked: toggleCheckboxChecked,
			});
			if (isIndeterminate || !isChecked) {
				insertSelectedIssueIds(issueIds);
			} else if (isChecked) {
				reset();
			}
		},
		[insertSelectedIssueIds, isChecked, isIndeterminate, reset, issueIds],
	);

	return (
		<Inline alignInline="center">
			<Checkbox
				{...checkboxProps}
				aria-label={formatMessage(messages.headerCheckboxAriaLabel)}
				isChecked={isChecked}
				isIndeterminate={isIndeterminate}
				onChange={onCheckboxChange}
				onClick={() => {
					abortAll('new_interaction');
				}}
			/>
		</Inline>
	);
};
