/**
 * @generated SignedSource<<4b695107a083baa5c6fa0c94bb815ce8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type storyPointsGroupHeader_nativeIssueTable$data = {
  readonly number: number | null | undefined;
  readonly " $fragmentType": "storyPointsGroupHeader_nativeIssueTable";
};
export type storyPointsGroupHeader_nativeIssueTable$key = {
  readonly " $data"?: storyPointsGroupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointsGroupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "storyPointsGroupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "number"
    }
  ],
  "type": "JiraJqlNumberFieldValue"
};

(node as any).hash = "7fa044b8c88133632268742d2cff7bf2";

export default node;
