import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
	graphql,
	useFragment,
	useSubscription,
	fetchQuery,
	commitLocalUpdate,
	useRelayEnvironment,
} from 'react-relay';
import type { GraphQLSubscriptionConfig } from 'relay-runtime';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { realtimeUpdaterOld_issueCreated_Subscription as IssueCreateSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_issueCreated_Subscription.graphql.ts';
import type { realtimeUpdaterOld_issueDeleted_Subscription as IssueDeleteSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_issueDeleted_Subscription.graphql.ts';
import type { realtimeUpdaterOld_issueDetails_Query as IssueDetailsQuery } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_issueDetails_Query.graphql.ts';
import type { realtimeUpdaterOld_groupsForIssue_Query } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_groupsForIssue_Query.graphql.ts';
import type {
	realtimeUpdaterOld_issueUpdated_Subscription as IssueUpdateSubscription,
	JiraIssueExpandedGroup,
	JiraIssueExpandedParent,
} from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_issueUpdated_Subscription.graphql.ts';
import type { realtimeUpdaterOld_nativeIssueTable_groups$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_nativeIssueTable_groups.graphql.ts';
import type { realtimeUpdaterOld_nativeIssueTable_issues$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_nativeIssueTable_issues.graphql.ts';
import type { realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useConnectionsList,
	type ConnectionDetails,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	useIsDensityFull,
	useIsInlineEditingEnabled,
} from '../../controllers/features/selectors.tsx';
import { useIssueFetchQueryVariables } from '../../controllers/issue-fetch-query-variables/index.tsx';
import {
	useIssueRealtimeUpdater,
	type GroupsForIssue,
} from '../../controllers/issue-realtime-updater/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';

const REAL_TIME_EVENT_TYPE_KEY = 'realTimeEventType';
const CREATE_ERROR_ID = 'realTimeIssueCreate';
const UPDATE_ERROR_ID = 'realTimeIssueUpdate';
const DELETE_ERROR_ID = 'realTimeIssueDelete';

const VARIABLE_DEBOUNCE_TIME = 1000;

const throttledFireTrackAnalytics = throttle(fireTrackAnalytics, 1000);

type Props = {
	project: realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project$key;
	issues?: realtimeUpdaterOld_nativeIssueTable_issues$key | null;
	groups?: realtimeUpdaterOld_nativeIssueTable_groups$key | null;
	isHierarchyEnabled?: boolean;
};

type SearchViewContextInputType =
	| IssueCreateSubscription['variables']['searchViewContextInput']
	| IssueUpdateSubscription['variables']['searchViewContextInput'];

const fetchIssueFieldSetConnection = async ({
	id,
	fieldSetIds,
	isInlineEditingEnabled,
	isDensityFull,
	isHierarchyEnabled,
	projectKey,
}: {
	id: string;
	fieldSetIds: string[];
	isInlineEditingEnabled: boolean;
	isDensityFull: boolean;
	isHierarchyEnabled: boolean | undefined;
	projectKey: string;
}): Promise<string | undefined> => {
	const environment = getRelayEnvironment();

	try {
		// if the issue is in a loaded context, the issue is visible so we need to fetch the fields for display
		const response = await fetchQuery<IssueDetailsQuery>(
			environment,
			graphql`
				query realtimeUpdaterOld_issueDetails_Query(
					$id: ID!
					$fieldSetIds: [String!]!
					$isInlineEditingEnabled: Boolean!
					$isDensityFull: Boolean!
					$isHierarchyEnabled: Boolean!
					$projectKey: String!
				) {
					jira {
						# This is a workaround for the subscription not currently being ready for use. In the future this query will be copied
						# as is there, so all the extra fields being requested here are used in the controller but not necessarily this file
						# eslint-disable-next-line @atlassian/relay/unused-fields
						issueById(id: $id) {
							issueId
							key
							issueTypeField @include(if: $isHierarchyEnabled) {
								id
								issueType {
									hierarchy {
										level
									}
								}
							}
							canHaveChildIssues(projectKey: $projectKey) @include(if: $isHierarchyEnabled)
							fieldSetsById(fieldSetIds: $fieldSetIds, first: 500) {
								__id
								# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
								...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
									@arguments(
										isInlineEditingEnabled: $isInlineEditingEnabled
										isDensityFull: $isDensityFull
									)
							}
						}
					}
				}
			`,
			{
				id,
				fieldSetIds,
				isInlineEditingEnabled,
				isDensityFull,
				isHierarchyEnabled: isHierarchyEnabled ?? false,
				projectKey,
			},
			{ fetchPolicy: 'network-only' },
		).toPromise();

		return response?.jira?.issueById?.fieldSetsById?.__id;
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'realTimeFetchIssueFieldSetConnection',
				packageName: PACKAGE_NAME,
				teamName: TEAM_NAME,
			},
			error: error instanceof Error ? error : undefined,
			sendToPrivacyUnsafeSplunk: true,
		});

		return undefined;
	}
};

const getGroupsForIssue = async ({
	issueId,
	fieldId,
	jql,
	numGroupsLoaded,
}: {
	issueId: string;
	fieldId: string;
	jql: string;
	numGroupsLoaded: number;
}): Promise<GroupsForIssue> => {
	const environment = getRelayEnvironment();

	try {
		const data = await fetchQuery<realtimeUpdaterOld_groupsForIssue_Query>(
			environment,
			graphql`
				query realtimeUpdaterOld_groupsForIssue_Query(
					$issueSearchInput: JiraIssueSearchInput!
					$fieldId: String!
					$issueId: ID!
					$firstNGroupsToSearch: Int!
				) {
					jira {
						issueById(id: $issueId) {
							groupsByFieldId(
								fieldId: $fieldId
								issueSearchInput: $issueSearchInput
								firstNGroupsToSearch: $firstNGroupsToSearch
								first: 50
							) @optIn(to: "JiraIssueGroupsByFieldId") {
								edges {
									node {
										__id
										id
										afterGroupId
										# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
										...groupRow_nativeIssueTable_groupRow
									}
								}
							}
						}
					}
				}
			`,
			{
				issueSearchInput: { jql },
				fieldId,
				issueId,
				// Needs to be connection length + 1 to ensure a potentially new group at the end of the
				// currently loaded connection is included
				firstNGroupsToSearch: numGroupsLoaded + 1,
			},
			{ fetchPolicy: 'network-only' },
		).toPromise();

		return (
			data?.jira?.issueById?.groupsByFieldId?.edges
				?.map((edge) =>
					edge?.node
						? {
								__id: edge.node.__id,
								id: edge.node.id,
								afterGroupId: edge.node.afterGroupId,
							}
						: undefined,
				)
				.filter(Boolean) || []
		);
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'realTimeFetchGroupsForIssue',
				packageName: PACKAGE_NAME,
				teamName: TEAM_NAME,
			},
			error: error instanceof Error ? error : undefined,
			sendToPrivacyUnsafeSplunk: true,
		});

		return [];
	}
};

const issueCreatedSubscription = graphql`
	subscription realtimeUpdaterOld_issueCreated_Subscription(
		$cloudId: ID!
		$projectId: String!
		$isHierarchyEnabled: Boolean
		$isGroupingEnabled: Boolean
		$issueSearchInput: JiraIssueSearchInput!
		$searchViewContextInput: JiraIssueSearchViewContextInput!
	) {
		jira {
			onIssueCreatedByProject(cloudId: $cloudId, projectId: $projectId) {
				__id
				id
				searchViewContext(
					isHierarchyEnabled: $isHierarchyEnabled
					isGroupingEnabled: $isGroupingEnabled
					issueSearchInput: $issueSearchInput
					searchViewContextInput: $searchViewContextInput
				) @optIn(to: "JiraListComponent-M1.2") {
					contexts {
						afterIssueId
						beforeIssueId
						position
						... on JiraIssueSearchViewContextMappingByParent {
							parentIssueId
						}
						... on JiraIssueSearchViewContextMappingByGroup {
							jql
						}
					}
				}
				parentIssueField {
					parentIssue {
						__id
					}
				}
			}
		}
	}
`;

const issueUpdatedSubscription = graphql`
	subscription realtimeUpdaterOld_issueUpdated_Subscription(
		$cloudId: ID!
		$projectId: String!
		$isHierarchyEnabled: Boolean
		$isGroupingEnabled: Boolean
		$issueSearchInput: JiraIssueSearchInput!
		$searchViewContextInput: JiraIssueSearchViewContextInput!
	) {
		jira {
			onIssueUpdatedByProject(cloudId: $cloudId, projectId: $projectId) {
				__id
				id
				searchViewContext(
					isHierarchyEnabled: $isHierarchyEnabled
					isGroupingEnabled: $isGroupingEnabled
					issueSearchInput: $issueSearchInput
					searchViewContextInput: $searchViewContextInput
				) @optIn(to: "JiraListComponent-M1.2") {
					contexts {
						afterIssueId
						beforeIssueId
						position
						... on JiraIssueSearchViewContextMappingByParent {
							parentIssueId
						}
						... on JiraIssueSearchViewContextMappingByGroup {
							jql
						}
					}
				}
				parentIssueField {
					parentIssue {
						__id
					}
				}
			}
		}
	}
`;

const issueDeletedSubscription = graphql`
	subscription realtimeUpdaterOld_issueDeleted_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueDeletedByProject(cloudId: $cloudId, projectId: $projectId) {
				resource
			}
		}
	}
`;

const generateSearchViewContextInput = ({
	connections,
	isGroupingEnabled,
	groupedByFieldId,
}: {
	connections: Record<string, ConnectionDetails>;
	isGroupingEnabled: boolean;
	groupedByFieldId?: string | null;
}): SearchViewContextInputType => {
	const expandedParents: JiraIssueExpandedParent[] = [];
	const groups: JiraIssueExpandedGroup[] = [];

	Object.values(connections).forEach((connection) => {
		if (connection.type === 'PARENT_CHILDREN') {
			expandedParents.push({ parentIssueId: connection.parentId, first: connection.first });
		} else if (connection.type === 'GROUP_CHILDREN') {
			groups.push({
				jql: connection.jql,
				fieldValue: connection.fieldValue,
				first: connection.first,
			});
		}
	});

	if (isGroupingEnabled && groupedByFieldId) {
		return { expandedGroups: { groupedByFieldId, groups } };
	}

	return { expandedParents };
};

export const RealtimeUpdater = ({ project, issues, groups, isHierarchyEnabled }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const { connections } = useConnectionsList();

	const issueConnection = useFragment(
		graphql`
			fragment realtimeUpdaterOld_nativeIssueTable_issues on JiraIssueConnection {
				__id
				jql
				edges {
					node {
						issueId
					}
					__typename
				}
			}
		`,
		issues ?? null,
	);

	const groupConnection = useFragment(
		graphql`
			fragment realtimeUpdaterOld_nativeIssueTable_groups on JiraSpreadsheetGroupConnection {
				__id
				jql
				groupByField
				edges {
					__typename
				}
			}
		`,
		groups ?? null,
	);
	const isGroupingEnabled = !!groups;
	const groupedByFieldId = groupConnection?.groupByField;

	const { projectId, key: projectKey } = useFragment(
		graphql`
			fragment realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project on JiraProject {
				projectId @required(action: THROW)
				key
			}
		`,
		project,
	);

	const { issuePositionUpdater, issueDeleteUpdater, groupsForIssueUpdater } =
		useIssueRealtimeUpdater();

	// We want to minimize the amount of times the subscriptions are recreated, but each time these functions update they cause them to reset
	// Wrapping them in a ref so function updates are reflected in the callback but do not cause the subscription to close and reopen
	const issuePositionUpdaterRef = useRef(issuePositionUpdater);
	const issueDeleteUpdaterRef = useRef(issueDeleteUpdater);

	useEffect(() => {
		issuePositionUpdaterRef.current = issuePositionUpdater;
	}, [issuePositionUpdater]);

	useEffect(() => {
		issueDeleteUpdaterRef.current = issueDeleteUpdater;
	}, [issueDeleteUpdater]);

	const { fieldSetIds } = useIssueFetchQueryVariables();
	const isDensityFull = useIsDensityFull();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();

	const baseVariables = useMemo(
		() => ({
			cloudId,
			projectId,
		}),
		[cloudId, projectId],
	);

	const [searchViewContextInput, setSearchViewContextInput] = useState<SearchViewContextInputType>(
		generateSearchViewContextInput({ connections, isGroupingEnabled, groupedByFieldId }),
	);

	const setSearchViewContextInputDebounced = useMemo(() => {
		return debounce((input: SearchViewContextInputType) => {
			setSearchViewContextInput(input);
		}, VARIABLE_DEBOUNCE_TIME);
	}, []);

	useEffect(() => {
		setSearchViewContextInputDebounced(
			generateSearchViewContextInput({ connections, isGroupingEnabled, groupedByFieldId }),
		);
		return () => setSearchViewContextInputDebounced.cancel();
	}, [
		connections,
		groupedByFieldId,
		groups,
		isGroupingEnabled,
		setSearchViewContextInputDebounced,
	]);

	const variables = useMemo(() => {
		return {
			...baseVariables,
			isHierarchyEnabled: isHierarchyEnabled ?? false,
			isGroupingEnabled,
			issueSearchInput: {
				jql: issueConnection?.jql || groupConnection?.jql || '',
			},
			searchViewContextInput,
		};
	}, [
		baseVariables,
		isHierarchyEnabled,
		isGroupingEnabled,
		issueConnection?.jql,
		groupConnection?.jql,
		searchViewContextInput,
	]);

	const environment = useRelayEnvironment();

	const createConfig = useMemo<GraphQLSubscriptionConfig<IssueCreateSubscription>>(
		() => ({
			variables,
			subscription: issueCreatedSubscription,
			onNext: async (response) => {
				try {
					if (response?.jira?.onIssueCreatedByProject) {
						const searchViewContext = response.jira.onIssueCreatedByProject.searchViewContext;
						const id = response.jira.onIssueCreatedByProject.id;
						const issueRecordId = response.jira.onIssueCreatedByProject.__id;
						const parentIssueNodeRecordId =
							response.jira.onIssueCreatedByProject.parentIssueField?.parentIssue?.__id;

						let fieldSetConnectionId: string | undefined;
						let groupsForIssue: GroupsForIssue | undefined;

						if (searchViewContext?.contexts && searchViewContext.contexts.length > 0) {
							fieldSetConnectionId = await fetchIssueFieldSetConnection({
								id,
								fieldSetIds,
								isInlineEditingEnabled,
								isDensityFull,
								isHierarchyEnabled,
								projectKey,
							});
						} else if (isGroupingEnabled && groupedByFieldId) {
							groupsForIssue = await getGroupsForIssue({
								issueId: id,
								fieldId: groupedByFieldId,
								jql: groupConnection?.jql || '',
								numGroupsLoaded: groupConnection?.edges?.length ?? 0,
							});
						}

						commitLocalUpdate(environment, (store) => {
							const wasPositionUpdated = issuePositionUpdaterRef.current({
								store,
								searchViewContext,
								fieldSetConnectionId,
								issueId: id,
								issueRecordId,
								parentIssueNodeRecordId,
								groupConnectionId: groupConnection?.__id,
								projectKey,
							});

							let wereGroupsUpdated = false;
							if (groupsForIssue && groupsForIssue?.length > 0) {
								wereGroupsUpdated = groupsForIssueUpdater({
									store,
									groupConnectionId: groupConnection?.__id,
									groupsForIssue,
								});
							}

							// only fire event when a visible change happens to prevent spamming analytics with all issue updates
							(wasPositionUpdated || wereGroupsUpdated) &&
								throttledFireTrackAnalytics(
									createAnalyticsEvent({}),
									'issueTableRealTimeEvent processed',
									{ [REAL_TIME_EVENT_TYPE_KEY]: 'create' },
								);
						});
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: CREATE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'onNext' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: CREATE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		}),
		[
			variables,
			isGroupingEnabled,
			groupedByFieldId,
			environment,
			fieldSetIds,
			isInlineEditingEnabled,
			isDensityFull,
			isHierarchyEnabled,
			groupConnection?.jql,
			groupConnection?.edges?.length,
			groupConnection?.__id,
			projectKey,
			groupsForIssueUpdater,
			createAnalyticsEvent,
		],
	);

	const updateConfig = useMemo<GraphQLSubscriptionConfig<IssueUpdateSubscription>>(
		() => ({
			variables,
			subscription: issueUpdatedSubscription,
			onNext: async (response) => {
				try {
					if (response?.jira?.onIssueUpdatedByProject) {
						const searchViewContext = response.jira.onIssueUpdatedByProject.searchViewContext;
						const id = response.jira.onIssueUpdatedByProject.id;
						const issueRecordId = response.jira.onIssueUpdatedByProject.__id;
						const parentIssueNodeRecordId =
							response.jira.onIssueUpdatedByProject.parentIssueField?.parentIssue?.__id;

						let fieldSetConnectionId: string | undefined;
						let groupsForIssue: GroupsForIssue | undefined;
						if (searchViewContext?.contexts && searchViewContext.contexts.length > 0) {
							fieldSetConnectionId = await fetchIssueFieldSetConnection({
								id,
								fieldSetIds,
								isInlineEditingEnabled,
								isDensityFull,
								isHierarchyEnabled,
								projectKey,
							});
						} else if (isGroupingEnabled && groupedByFieldId) {
							groupsForIssue = await getGroupsForIssue({
								issueId: id,
								fieldId: groupedByFieldId,
								jql: groupConnection?.jql || '',
								numGroupsLoaded: groupConnection?.edges?.length ?? 0,
							});
						}

						commitLocalUpdate(environment, (store) => {
							const wasPositionUpdated = issuePositionUpdaterRef.current({
								store,
								searchViewContext,
								fieldSetConnectionId,
								issueId: id,
								issueRecordId,
								parentIssueNodeRecordId,
								groupConnectionId: groupConnection?.__id,
								projectKey,
							});

							let wereGroupsUpdated = false;
							if (groupsForIssue && groupsForIssue?.length > 0) {
								wereGroupsUpdated = groupsForIssueUpdater({
									store,
									groupConnectionId: groupConnection?.__id,
									groupsForIssue,
								});
							}

							// only fire event when a visible change happens to prevent spamming analytics with all issue updates
							(searchViewContext?.contexts?.length || wasPositionUpdated || wereGroupsUpdated) &&
								throttledFireTrackAnalytics(
									createAnalyticsEvent({}),
									'issueTableRealTimeEvent processed',
									{ [REAL_TIME_EVENT_TYPE_KEY]: 'update' },
								);
						});
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: UPDATE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'onNext' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: UPDATE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		}),
		[
			createAnalyticsEvent,
			environment,
			fieldSetIds,
			groupConnection?.__id,
			groupConnection?.edges?.length,
			groupConnection?.jql,
			groupedByFieldId,
			groupsForIssueUpdater,
			isDensityFull,
			isGroupingEnabled,
			isHierarchyEnabled,
			isInlineEditingEnabled,
			projectKey,
			variables,
		],
	);

	const deleteConfig = useMemo<GraphQLSubscriptionConfig<IssueDeleteSubscription>>(() => {
		return {
			variables: baseVariables,
			subscription: issueDeletedSubscription,
			updater: (store, response) => {
				try {
					if (response?.jira?.onIssueDeletedByProject?.resource) {
						const wasDeleted = issueDeleteUpdaterRef.current({
							store,
							issueResourceId: response.jira.onIssueDeletedByProject.resource,
							groupConnectionId: groupConnection?.__id,
							projectKey,
						});

						wasDeleted &&
							throttledFireTrackAnalytics(
								createAnalyticsEvent({}),
								'issueTableRealTimeEvent processed',
								{ [REAL_TIME_EVENT_TYPE_KEY]: 'delete' },
							);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: DELETE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'updater' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: DELETE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		};
	}, [baseVariables, groupConnection?.__id, projectKey, createAnalyticsEvent]);

	useSubscription(createConfig);
	useSubscription(updateConfig);
	useSubscription(deleteConfig);

	return null;
};
