/**
 * @generated SignedSource<<90c0cf09bddbdffbd9a31701394dc6bd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type groupRow_nativeIssueTable_groupRow$data = {
  readonly fieldValue: {
    readonly __typename: "JiraJqlGoalsFieldValue";
    readonly goal: {
      readonly id: string;
    };
  } | {
    readonly __typename: "JiraJqlNumberFieldValue";
    readonly number: number | null | undefined;
  } | {
    readonly __typename: "JiraJqlOptionFieldValue";
    readonly option: {
      readonly optionId: string;
    } | null | undefined;
  } | {
    readonly __typename: "JiraJqlPriorityFieldValue";
    readonly priority: {
      readonly priorityId: string;
    };
  } | {
    readonly __typename: "JiraJqlSprintFieldValue";
    readonly sprint: {
      readonly sprintId: string;
    };
  } | {
    readonly __typename: "JiraJqlStatusFieldValue";
    readonly status: {
      readonly id: string;
    } | null | undefined;
  } | {
    readonly __typename: "JiraJqlUserFieldValue";
    readonly user: {
      readonly accountId: string;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly id: string;
  readonly jql: string;
  readonly " $fragmentSpreads": FragmentRefs<"groupHeader_nativeIssueTable">;
  readonly " $fragmentType": "groupRow_nativeIssueTable_groupRow";
};
export type groupRow_nativeIssueTable_groupRow$key = {
  readonly " $data"?: groupRow_nativeIssueTable_groupRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupRow_nativeIssueTable_groupRow">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "groupRow_nativeIssueTable_groupRow",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "jql"
      },
      "action": "THROW",
      "path": "jql"
    },
    {
      "kind": "LinkedField",
      "name": "fieldValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraPriority",
              "kind": "LinkedField",
              "name": "priority",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "priorityId"
                }
              ]
            }
          ],
          "type": "JiraJqlPriorityFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                }
              ]
            }
          ],
          "type": "JiraJqlUserFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraStatus",
              "kind": "LinkedField",
              "name": "status",
              "plural": false,
              "selections": (v1/*: any*/)
            }
          ],
          "type": "JiraJqlStatusFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "number"
            }
          ],
          "type": "JiraJqlNumberFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "option",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "optionId"
                }
              ]
            }
          ],
          "type": "JiraJqlOptionFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "sprint",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                }
              ]
            }
          ],
          "type": "JiraJqlSprintFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraGoal",
              "kind": "LinkedField",
              "name": "goal",
              "plural": false,
              "selections": (v1/*: any*/)
            }
          ],
          "type": "JiraJqlGoalsFieldValue"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "groupHeader_nativeIssueTable"
    }
  ],
  "type": "JiraSpreadsheetGroup"
};
})();

(node as any).hash = "d9bc5bf18bc53720bc1ec566728d2ea5";

export default node;
