import { commitLocalUpdate, type useRelayEnvironment } from 'react-relay/hooks';
import type { RecordSourceSelectorProxy } from 'relay-runtime';

export const TEMP_CREATE_FIELDSET_ID = 'temp_new_fieldset_id';

export const updateTempOptimisticFieldSetId = ({
	environment,
	fieldConfigId,
	fieldSetId,
}: {
	fieldConfigId: string | undefined;
	environment: ReturnType<typeof useRelayEnvironment>;
	fieldSetId: string;
}) => {
	commitLocalUpdate(environment, (store: RecordSourceSelectorProxy) => {
		if (fieldConfigId) {
			const fields = store.get(fieldConfigId)?.getLinkedRecords('edges');

			const currentField = fields?.find(
				(edge) => edge.getLinkedRecord('node')?.getValue('fieldSetId') === TEMP_CREATE_FIELDSET_ID,
			);

			if (currentField) {
				currentField.getLinkedRecord('node')?.setValue(fieldSetId, 'fieldSetId');
			}
		}
	});
};
