import React from 'react';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useHasExpandedItems } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import messages from './messages.tsx';

const ExpandHeaderCell = () => {
	const { formatMessage } = useIntl();
	const hasExpandedItems = useHasExpandedItems();

	const getScreenReaderMessage = () => {
		if (hasExpandedItems) {
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.columnHeaderCollapseButtonIssueTermRefresh
				: messages.columnHeaderCollapseButton;
		}
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.columnHeaderExpandButtonIssueTermRefresh
			: messages.columnHeaderExpandButton;
	};

	return (
		<Flex alignItems="center" xcss={hierarchyHeaderStyles}>
			<Flex alignItems="center" justifyContent="center" xcss={headerIconStyles}>
				{hasExpandedItems ? (
					<ChevronDownIcon
						label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.columnHeaderCollapseButtonIssueTermRefresh
								: messages.columnHeaderCollapseButton,
						)}
						color="currentColor"
					/>
				) : (
					<ChevronRightIcon
						label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.columnHeaderExpandButtonIssueTermRefresh
								: messages.columnHeaderExpandButton,
						)}
						color="currentColor"
					/>
				)}
				<ScreenReaderText>{formatMessage(getScreenReaderMessage())}</ScreenReaderText>
			</Flex>
		</Flex>
	);
};

/* TODO: remove after cleaning jira_spreadsheet_component_m1 - style added for the onboarding spotlight  */
const hierarchyHeaderStyles = xcss({
	height: 'size.300',
});

const headerIconStyles = xcss({
	width: token('space.300'),
	height: token('space.300'),
});

export default ExpandHeaderCell;
