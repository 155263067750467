/**
 * @generated SignedSource<<8a83f7b6a3bf3a91c61f7514ba09b83a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiVersionPicker_nativeIssueTable_MultiVersionPickerCell$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "multiVersionPicker_nativeIssueTable_MultiVersionPickerCell";
};
export type multiVersionPicker_nativeIssueTable_MultiVersionPickerCell$key = {
  readonly " $data"?: multiVersionPicker_nativeIssueTable_MultiVersionPickerCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiVersionPicker_nativeIssueTable_MultiVersionPickerCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "multiVersionPicker_nativeIssueTable_MultiVersionPickerCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraMultipleVersionPickerField"
};

(node as any).hash = "bac54c5febc57f9040c4badd289dba78";

export default node;
