import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueCountProps } from '../../../../common/types.tsx';
import messages from './messages.tsx';

export const IssueCount = ({
	startIssueRange,
	endIssueRange,
	loadedIssuesCount,
	total,
	countExceedsMaxResults,
	isNewFooterOrderEnabled,
	isInfiniteScrollEnabled,
}: IssueCountProps) => {
	const { formatMessage } = useIntl();
	const totalNumberIssues = countExceedsMaxResults ? `${total}+` : total;
	const isTotalUnknown = total <= 0;

	if (isInfiniteScrollEnabled) {
		return (
			<IssueCountContainer
				data-testid="native-issue-table.ui.footer-renderer.issue-count-renderer.issue-count.issue-count"
				isFlexEnd
			>
				{isTotalUnknown
					? String(loadedIssuesCount)
					: formatMessage(messages.loadedIssuesTotalKnown, {
							loadedIssuesCount,
							totalNumberIssues,
						})}
			</IssueCountContainer>
		);
	}

	return (
		<IssueCountContainer
			data-testid="native-issue-table.ui.footer-renderer.issue-count-renderer.issue-count.issue-count"
			isFlexEnd={isNewFooterOrderEnabled}
		>
			{isTotalUnknown
				? formatMessage(messages.issueRangeUnknown, {
						startIssueRange,
						endIssueRange,
					})
				: formatMessage(messages.issueRangeKnown, {
						startIssueRange,
						endIssueRange,
						totalNumberIssues,
					})}
		</IssueCountContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div<{ isFlexEnd: boolean }>({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	// cleanup isFlexEnd with visual-refresh FG
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	justifyContent: ({ isFlexEnd }) => (isFlexEnd ? 'flex-end' : 'center'),
	fontWeight: token('font.weight.semibold'),
});
