import { useCallback } from 'react';
import { fetchQuery, graphql } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { groupsForIssues_realtimeUpdater_Query } from '@atlassian/jira-relay/src/__generated__/groupsForIssues_realtimeUpdater_Query.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type { GroupDetails } from '../../controllers/issue-realtime-updater/index.tsx';

export const useGetGroupsForIssues = () => {
	const cloudId = useCloudId();

	return useCallback(
		async ({
			issueKeys,
			fieldId,
			jql,
			numGroupsLoaded,
		}: {
			issueKeys: string[];
			fieldId: string;
			jql: string;
			numGroupsLoaded: number;
		}): Promise<GroupDetails[]> => {
			const environment = getRelayEnvironment();

			try {
				const data = await fetchQuery<groupsForIssues_realtimeUpdater_Query>(
					environment,
					graphql`
						query groupsForIssues_realtimeUpdater_Query(
							$cloudId: ID!
							$issueSearchInput: JiraIssueSearchInput!
							$fieldId: String!
							$issueKeys: [String!]!
							$firstNGroupsToSearch: Int!
						) {
							jira {
								issuesByKey(cloudId: $cloudId, keys: $issueKeys) {
									groupsByFieldId(
										fieldId: $fieldId
										issueSearchInput: $issueSearchInput
										firstNGroupsToSearch: $firstNGroupsToSearch
										first: 50
									) @optIn(to: "JiraIssueGroupsByFieldId") {
										edges {
											node {
												__id
												id
												afterGroupId
												# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
												...groupRow_nativeIssueTable_groupRow
											}
										}
									}
								}
							}
						}
					`,
					{
						issueSearchInput: { jql },
						fieldId,
						cloudId,
						issueKeys,
						// Needs to be connection length + 1 to ensure a potentially new group at the end of the
						// currently loaded connection is included
						firstNGroupsToSearch: numGroupsLoaded + 1,
					},
					{ fetchPolicy: 'network-only' },
				).toPromise();

				const uniqueGroups: Record<string, GroupDetails> = {};
				data?.jira?.issuesByKey?.forEach((issue) => {
					issue?.groupsByFieldId?.edges?.forEach((edge) => {
						if (edge?.node) {
							uniqueGroups[edge.node.id] = {
								__id: edge.node.__id,
								id: edge.node.id,
								afterGroupId: edge.node.afterGroupId,
							};
						}
					});
				});

				return Object.values(uniqueGroups);
			} catch (error) {
				fireErrorAnalytics({
					meta: {
						id: 'realTimeFetchGroupsForIssue',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error: error instanceof Error ? error : undefined,
					sendToPrivacyUnsafeSplunk: true,
				});

				return [];
			}
		},
		[cloudId],
	);
};
