import React, { type Ref, useCallback, useState } from 'react';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import {
	MINIMUM_COLUMN_WIDTH,
	SORT_ASC,
	SORT_DESC,
	SORT_NONE,
} from '../../../../../common/constants.tsx';
import type { Column, TargetColumnPosition } from '../../../../../common/types.tsx';
import { useIsFixedTableLayoutEnabled } from '../../../../../controllers/features/selectors.tsx';
import { MoreActions } from './more-actions/index.tsx';
import { SortButton } from './sort-button/index.tsx';

export type Props = {
	column: Column;
	currentColumnWidth: number;
	hasColumnConfiguration: boolean;
	isColumnResizingEnabled: boolean;
	isCompactColumnEnabled?: boolean;
	isHeaderCellHovered: boolean;
	isFirst: boolean;
	isLast: boolean;
	isOpen: boolean;
	triggerRef?: Ref<HTMLButtonElement>;
	onMoveColumn: (position: TargetColumnPosition) => void;
	onOpenChange: (isOpen: boolean) => void;
	onRemoveColumn: () => void;
	onResizeColumn: () => void;
	onResetColumnWidth: () => void;
};

export const HeaderCellActions = ({
	column,
	currentColumnWidth,
	hasColumnConfiguration,
	isColumnResizingEnabled,
	isCompactColumnEnabled = false,
	isHeaderCellHovered,
	isFirst,
	isLast,
	isOpen,
	triggerRef = null,
	onMoveColumn,
	onOpenChange,
	onRemoveColumn,
	onResizeColumn,
	onResetColumnWidth,
}: Props) => {
	const [isFocused, setIsFocused] = useState(false);

	const onFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const onBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	const isSortable = column.onSortOrderChanged !== undefined && column.isSortable;

	const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();
	// Header actions width must be static when fixed table layout is disabled to prevent column
	// widths shifting as the user hovers the table header
	const isStaticWidth = !isFixedTableLayoutEnabled;

	if (!isSortable && !hasColumnConfiguration && !isColumnResizingEnabled) {
		return null;
	}

	const isMoreActionsVisible = isHeaderCellHovered || isOpen || isFocused;

	const isSorted = column.currentSorting === SORT_ASC || column.currentSorting === SORT_DESC;
	const isSortVisible = isMoreActionsVisible || isSorted;

	const isSortOnlyAction = isSortable && !hasColumnConfiguration && !isColumnResizingEnabled;

	const sortButton = (
		<Box xcss={[baseStyles, !isSortVisible && hiddenStyles]}>
			<SortButton column={column} onFocus={onFocus} onBlur={onBlur} />
		</Box>
	);

	const moreActionsButton = (
		<Box xcss={[baseStyles, !isMoreActionsVisible && hiddenStyles]}>
			<MoreActions
				column={column}
				currentColumnWidth={currentColumnWidth}
				hasColumnConfiguration={hasColumnConfiguration}
				isColumnResizingEnabled={isColumnResizingEnabled}
				isFirst={isFirst}
				isLast={isLast}
				isOpen={isOpen}
				triggerRef={triggerRef}
				onMoveColumn={onMoveColumn}
				onOpenChange={onOpenChange}
				onRemoveColumn={onRemoveColumn}
				onResizeColumn={onResizeColumn}
				onResetColumnWidth={onResetColumnWidth}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
		</Box>
	);

	if (isSortOnlyAction) {
		return (
			<Inline xcss={[containerStyles, isStaticWidth && singleButtonWidth]}>{sortButton}</Inline>
		);
	}

	if (!isSortable) {
		return (
			<Inline xcss={[containerStyles, isStaticWidth && singleButtonWidth]}>
				{moreActionsButton}
			</Inline>
		);
	}

	if (isCompactColumnEnabled && currentColumnWidth < MINIMUM_COLUMN_WIDTH) {
		if (column.currentSorting !== SORT_NONE && !isHeaderCellHovered) {
			return (
				<Inline xcss={[containerStyles, isStaticWidth && singleButtonWidth]}>{sortButton}</Inline>
			);
		}
		return (
			<Inline xcss={[containerStyles, isStaticWidth && singleButtonWidth]}>
				{moreActionsButton}
			</Inline>
		);
	}

	return (
		<Inline
			alignInline={isStaticWidth ? 'end' : undefined}
			xcss={[
				containerStyles,
				// Animate between single and all button width when sorted (only when in a dynamic layout)
				!isStaticWidth && isSorted && singleButtonWidth,
				(isStaticWidth || isMoreActionsVisible) && allButtonsWidth,
			]}
		>
			{sortButton}
			{moreActionsButton}
		</Inline>
	);
};

const containerStyles = xcss({
	gap: 'space.050',
	transition: 'width .25s',
});

const singleButtonWidth = xcss({
	width: '24px',
});

const allButtonsWidth = xcss({
	width: '52px', // 2 x 24px icon + 4px gap
});

const baseStyles = xcss({
	transition: 'opacity .25s',
});

const hiddenStyles = xcss({
	clip: 'rect(1px, 1px, 1px, 1px)',
	clipPath: 'inset(50%)',
	height: '1px',
	width: '1px',
	margin: 'space.negative.025',
	overflow: 'hidden',
	padding: 'space.0',
	opacity: 0,
	position: 'absolute',
	pointerEvents: 'none', // ensure hidden controls cannot be the target of any mouse events, e.g. hovering
});
