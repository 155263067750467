/* eslint-disable @atlassian/relay/unused-fields, @atlassian/relay/must-colocate-fragment-spreads */
import { useCallback } from 'react';
import {
	commitLocalUpdate,
	graphql,
	useRelayEnvironment,
	fetchQuery,
	type Environment,
} from 'react-relay';
import type {
	issueFetchAndAppendQuery,
	issueFetchAndAppendQuery$data,
} from '@atlassian/jira-relay/src/__generated__/issueFetchAndAppendQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useIsDensityFull,
	useIsInlineEditingEnabled,
} from '../../controllers/features/selectors.tsx';
import {
	issueConnectionUpdater,
	type IssueInsertionArgs,
	removeHighlightAfterDelay,
} from '../../controllers/issue-connection-updater/index.tsx';
import { useIssueFetchQueryVariables } from '../../controllers/issue-fetch-query-variables/index.tsx';

const issueConnectionQueryUpdater = (
	environment: Environment,
	response: issueFetchAndAppendQuery$data,
	issueConnectionId: string,
	insertionArgs?: IssueInsertionArgs,
) => {
	const issue = response?.jira?.issueByKey;
	if (!issue) {
		return;
	}

	commitLocalUpdate(environment, (store) => {
		issueConnectionUpdater(store, {
			...insertionArgs,
			issue,
			issueConnectionId,
			issueRecordId: issue.__id,
			fieldSetsConnectionId: issue.fieldSetsById.__id,
		});
	});
	removeHighlightAfterDelay(environment, issue);
};

/**
 * Fetch required data for an issue to be displayed within the issue table and insert the record to the provided issue
 * connection.
 *
 * @param issueKey Key of the issue to fetch.
 * @param insertionArgs Arguments to determine where the fetched issue should be inserted in the connection.
 */
type OnIssueFetchAndAppend = (issueKey: string, insertionArgs?: IssueInsertionArgs) => void;

/**
 * Returns a callback that will fetch required data for an issue (by issue key) to be displayed within the issue table
 * and append the record to the provided issue connection.
 *
 * @param issueConnectionId ID of the Relay connection where the new issue should be appended.
 */
export const useIssueFetchAndAppend = (issueConnectionId: string | null): OnIssueFetchAndAppend => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const isDensityFull = useIsDensityFull();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	const { fieldSetIds } = useIssueFetchQueryVariables();
	return useCallback(
		(issueKey: string, insertionArgs?: IssueInsertionArgs) => {
			if (!issueConnectionId) {
				return null;
			}
			fetchQuery<issueFetchAndAppendQuery>(
				environment,
				graphql`
					query issueFetchAndAppendQuery(
						$cloudId: ID!
						$issueKey: String!
						$fieldSetIds: [String!]!
						$isInlineEditingEnabled: Boolean!
						$isDensityFull: Boolean!
					) {
						jira {
							issueByKey(cloudId: $cloudId, key: $issueKey) {
								...highlightIssueRow_update_isHighlightedIssueRow
								__id
								key
								issueId
								fieldSetsById(fieldSetIds: $fieldSetIds, first: 500) @required(action: THROW) {
									__id
									...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
										@arguments(
											isInlineEditingEnabled: $isInlineEditingEnabled
											isDensityFull: $isDensityFull
										)
								}
							}
						}
					}
				`,
				{
					cloudId,
					issueKey,
					fieldSetIds,
					isInlineEditingEnabled,
					isDensityFull,
				},
			).subscribe({
				next: (data) => {
					issueConnectionQueryUpdater(environment, data, issueConnectionId, insertionArgs);
				},
			});
		},
		[cloudId, environment, fieldSetIds, isDensityFull, isInlineEditingEnabled, issueConnectionId],
	);
};
