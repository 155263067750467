/**
 * @generated SignedSource<<3d4db416a19f6fb4f58d2279bfea0b91>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_fieldSets$data = {
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets" | "row_nativeIssueTable_RowInner_fieldSets" | "row_nativeIssueTable_RowWithDragAndDrop_fieldSets" | "row_nativeIssueTable_RowWithSiblingCreate_fieldSets">;
  readonly " $fragmentType": "row_nativeIssueTable_fieldSets";
};
export type row_nativeIssueTable_fieldSets$key = {
  readonly " $data"?: row_nativeIssueTable_fieldSets$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_fieldSets">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isDensityFull",
    "variableName": "isDensityFull"
  },
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_fieldSets",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowInner_fieldSets"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithSiblingCreate_fieldSets"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_fieldSets"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithDragAndDrop_fieldSets"
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};
})();

(node as any).hash = "aeef3b235285f8ee145e31705e01ca9f";

export default node;
