import { useCallback } from 'react';
import { ConnectionHandler, type RecordProxy, type RecordSourceProxy } from 'relay-runtime';
import RelayDataID from '@atlassian/relay-data-id';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useConnectionsListActions,
	type ConnectionDetails,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';

const JIRA_SPREADSHEET_GROUP_TYPENAME = 'JiraSpreadsheetGroup';

type IssueCountOperation = 'add' | 'remove';

const removeGroupFromConnection = ({
	store,
	groupId,
	connectionId,
	groupConnectionId,
}: {
	store: RecordSourceProxy;
	groupId: string;
	connectionId?: string;
	groupConnectionId?: string;
}) => {
	if (!groupConnectionId) {
		return;
	}

	if (connectionId) {
		// invalidate connection for next incoming updates.
		const connectionRecord = store.get(connectionId);
		connectionRecord?.invalidateRecord();
	}

	const relayId = RelayDataID({ id: groupId }, JIRA_SPREADSHEET_GROUP_TYPENAME);
	const groupConnectionRecord = store.get(groupConnectionId);

	if (!groupConnectionRecord || !relayId) {
		return;
	}

	ConnectionHandler.deleteNode(groupConnectionRecord, relayId);

	// Remove the firstGroup reference if it's the same as the group being removed
	if (groupConnectionRecord.getLinkedRecord('firstGroup')?.getDataID() === relayId) {
		groupConnectionRecord.setValue(null, 'firstGroup');
	}
};

const updateGroupIssueCount = ({
	store,
	groupId,
	operation,
	groupConnectionId,
	connectionId,
}: {
	store: RecordSourceProxy;
	groupId: string;
	operation: IssueCountOperation;
	groupConnectionId?: string;
	connectionId?: string;
}) => {
	const relayId = RelayDataID({ id: groupId }, JIRA_SPREADSHEET_GROUP_TYPENAME);

	if (!relayId) {
		return;
	}

	const groupRecord = store.get(relayId);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const issueCount = (groupRecord?.getValue('issueCount') as number) || 0;
	const newIssueCount = issueCount + (operation === 'add' ? 1 : -1);

	if (newIssueCount <= 0) {
		removeGroupFromConnection({ store, groupId, connectionId, groupConnectionId });
	} else {
		groupRecord?.setValue(newIssueCount, 'issueCount');
	}
};

export const updateParentHasChildren = ({
	store,
	parentIssueRecordId,
	connectionId,
	hasChildren,
	projectKey,
}: {
	store: RecordSourceProxy;
	parentIssueRecordId: string;
	connectionId: string | undefined;
	hasChildren: boolean;
	projectKey?: string;
}): boolean => {
	// returns whether an update was performed
	const parentIssueRecord = store.get(parentIssueRecordId);
	const updateArgs = projectKey ? { filterByProjectKeys: [projectKey] } : undefined;
	if (parentIssueRecord && parentIssueRecord.getValue('hasChildren', updateArgs) !== hasChildren) {
		parentIssueRecord?.setValue(hasChildren, 'hasChildren', updateArgs);
		if (hasChildren === false && connectionId && fg('jsc_list_reparenting')) {
			const connectionRecord = store.get(connectionId);
			connectionRecord?.invalidateRecord();
		}
		return true;
	}
	return false;
};

export const useUpdateConnectionDetails = () => {
	const { removeConnection: removeConnectionFromList } = useConnectionsListActions();

	return useCallback(
		({
			store,
			connection,
			operation,
			remainingIssueCount,
			groupConnectionId,
			projectKey,
		}: {
			store: RecordSourceProxy;
			connection: ConnectionDetails;
			operation: IssueCountOperation;
			remainingIssueCount?: number;
			groupConnectionId?: string;
			projectKey?: string;
		}) => {
			if (remainingIssueCount === undefined) {
				return;
			}

			switch (connection.type) {
				case 'GROUP_CHILDREN':
					updateGroupIssueCount({
						store,
						groupId: connection.groupId,
						operation,
						groupConnectionId,
						connectionId: connection.connectionId,
					});
					break;
				case 'PARENT_CHILDREN':
					updateParentHasChildren({
						store,
						parentIssueRecordId: connection.parentRelayId,
						connectionId: connection.connectionId,
						hasChildren: remainingIssueCount > 0,
						projectKey,
					});
					break;
				case 'ROOT_ISSUES': {
					const connectionRecord = store.get(connection.connectionId);
					if (!connectionRecord) {
						break;
					}
					const totalIssues = connectionRecord.getValue('totalIssueSearchResultCount');
					if (totalIssues != null && typeof totalIssues === 'number') {
						connectionRecord.setValue(
							totalIssues + (operation === 'add' ? 1 : -1),
							'totalIssueSearchResultCount',
						);
					}
					break;
				}
				default:
			}

			if (remainingIssueCount === 0) {
				removeConnectionFromList(connection);
			}
		},
		[removeConnectionFromList],
	);
};

export type UseUpdateConnectionDetailsReturnType = ReturnType<typeof useUpdateConnectionDetails>;

function insertEdgeAtPosition<T = {}>(
	existingEdges: RecordProxy<T>[],
	newEdge: RecordProxy<T>,
	index: number,
): RecordProxy<T>[] {
	return [...existingEdges.slice(0, index), newEdge, ...existingEdges.slice(index)];
}

export const insertNewGroupInGroupsConnection = ({
	store,
	groupsConnection,
	newGroup,
	groupEdges,
	afterGroupId,
}: {
	store: RecordSourceProxy;
	groupsConnection: RecordProxy<{}>;
	newGroup: RecordProxy<{}>;
	groupEdges: RecordProxy<{}>[];
	afterGroupId?: string | null;
}) => {
	const newGroupEdge = ConnectionHandler.createEdge(
		store,
		groupsConnection,
		newGroup,
		'JiraSpreadsheetGroupEdge',
	);

	if (afterGroupId === null) {
		return insertEdgeAtPosition(groupEdges, newGroupEdge, 0);
	}

	const afterGroupIndex = groupEdges.findIndex(
		(edge) => edge.getLinkedRecord('node')?.getValue('id') === afterGroupId,
	);

	if (afterGroupIndex !== -1) {
		return insertEdgeAtPosition(groupEdges, newGroupEdge, afterGroupIndex + 1);
	}

	return groupEdges;
};
