import {
	PRIORITY_TYPE,
	ASSIGNEE_TYPE,
	STATUS_TYPE,
	SPRINT_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	CATEGORY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const isCreateIssueSupportedForFieldType = (fieldType?: string | null) => {
	return (
		fieldType === PRIORITY_TYPE ||
		fieldType === ASSIGNEE_TYPE ||
		fieldType === STATUS_TYPE ||
		fieldType === SPRINT_TYPE ||
		fieldType === NUMBER_CF_TYPE ||
		fieldType === STORY_POINT_ESTIMATE_CF_TYPE ||
		fieldType === CATEGORY_TYPE
	);
};
