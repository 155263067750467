import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issues: {
		id: 'native-issue-table.row.group-row.group-header.issues',
		defaultMessage: '{count, plural, one {# issue} other {# issues}}',
		description: 'Text for the issue count for each group in the issue table',
	},
	issuesIssueTermRefresh: {
		id: 'native-issue-table.row.group-row.group-header.issues-issue-term-refresh',
		defaultMessage: '{count, plural, one {# work item} other {# work items}}',
		description: 'Text for the issue count for each group in the issue table',
	},
});
