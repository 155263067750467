import type React from 'react';
import type { ReactNode, ComponentType, ReactElement, Ref, ElementType } from 'react';
import type { Operator, OperatorValue } from '@atlaskit/jql-ast';
import type { InputActionMeta, ValueType, ActionMeta, StylesConfig } from '@atlaskit/select';
import type { AdditionalEventAttributes } from '@atlassian/jira-common-experience-tracking-analytics/src/index.tsx';

export type SecondaryLabelComponent = {
	label: string;
	element: ReactElement;
};

export type KeyboardEventType = KeyboardEvent | React.KeyboardEvent<HTMLDivElement>;
export type EventType = Event | React.MouseEvent<HTMLElement> | KeyboardEventType;

export type Option = {
	optionType: 'option';
	value: string;
	label: string;
	secondaryLabel?: string | SecondaryLabelComponent;
	cursor?: string;
	aboveTheFold?: boolean;
	data?: Option;
	invalid?: boolean;
	icon?: string;
	fullList?: boolean;
	itemType?: string;
	isDisabled?: boolean;
	isOnlyUnselectable?: boolean;
	disabledMessage?: string | ReactNode;
};

export type SearchTemplate = string;
export type FieldType = string;

/**
 * This data structure contains all of the data about a field in order to render
 * the options in the "More" fields dropdown of the basic builder.
 *
 * We also use this data to generate the Picker for each field when it is
 * selected, so it must contain all the data necessary to generate a
 * FieldToRender object.
 */
export type FieldOption = Omit<Option, 'optionType'> & {
	/**
	 * Determines the type of item to render in the picker.
	 */
	optionType: 'field';
	/**
	 * The search template configured on the field. This is used to determine
	 * which picker type to use.
	 */
	searchTemplate: SearchTemplate;
	/**
	 * The underlying field type that is being searched on, which can be used
	 * to determine if any customisations need to be made on the picker.
	 */
	fieldType: FieldType;
	/**
	 * In basic mode, custom field pickers render with a tooltip containing a
	 * description of the field. This type represents that description data.
	 *
	 * This value can be an empty string in sume cases, or `null` if the field
	 * does not support a description (ex. system fields).
	 */
	fieldDescription: string | null;
	/**
	 * Operators supported by the jql field
	 */
	operators?: OperatorValue[];
	/**
	 * Allowed operators for basic mode picker, this array is used for query too complex check
	 */
	allowedOperators?: Operator[];
	/**
	 * Field type name displayed in UI.
	 */
	fieldTypeDisplayName: string | null;
};

export type AvatarOption = Omit<Option, 'optionType'> & {
	optionType: 'avatar';
	avatar?: string | null;
	square?: boolean;
	hideAvatar?: boolean;
	isGroup?: boolean;
};

export type LozengeOption = Omit<Option, 'optionType'> & {
	optionType: 'lozenge';
	appearance?: 'default' | 'inprogress' | 'moved' | 'new' | 'removed' | 'success';
	isBold?: boolean;
	maxWidth?: number | string;
};

export type OptionGroup = {
	optionType: 'group';
	label: string;
	options: SelectValue;
};

export type ErrorOption = {
	optionType: 'error';
	error: Error;
	onRetry: () => void;
};

export type SelectOption = SingleValueOption | ErrorOption | OptionGroup;

export type SingleValueOption = Option | AvatarOption | LozengeOption | FieldOption;

export type SelectValue = ReadonlyArray<SelectOption>;

/**
 * complies to the types of @atlaskit/select 16+ for onChange event
 *  */
export type SelectedValue = readonly SelectOption[] | SelectOption | null;

export type OptionResponse = {
	options: ReadonlyArray<SelectOption>;
	totalCount: number | null;
	endCursor?: string;
};

export type PaginatedOption = Option | AvatarOption | LozengeOption | FieldOption;

export type SearchValue = string;

export type FilterValue = {
	label: string;
	value: string;
}[];

export type Value = SelectValue;

export type Values = {
	[key: string]: Value;
};

export type EndCursor = string | undefined;

export type AsyncLoadOptions = (
	search: string,
	enableFullList: boolean,
	endCursor: EndCursor,
	cb: (data: OptionResponse) => void,
) => void;

type Data = {
	action: string;
	key: string;
};

export const MetaActions = {
	setValue: 'set-value',
	inputChange: 'input-change',
	inputBlur: 'input-blur',
	menuClose: 'menu-close',
	selectOption: 'select-option',
	deselectOption: 'deselect-option',
	removeValue: 'remove-value',
	popValue: 'pop-value',
	clear: 'clear',
	createOption: 'create-option',
	clearOptions: 'clear-options',
	add: 'add',
	remove: 'remove',
	update: 'update',
} as const;

export type Meta = {
	action: (typeof MetaActions)[keyof typeof MetaActions];
	key: string;
	data?: Data[];
};

export type OptionMeta = Meta & {
	option: PaginatedOption;
};

export type FilterOption = {
	label: string;
	value: string;
	rawInput: string;
	data: Option;
};

export type SelectComponentsConfig = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: ComponentType<any> | null;
};

export type ReplaceableComponentProps<T> = {
	children: ReactNode;
	innerRef: Ref<ElementType>;
	innerProps: unknown;
} & T;

type ComponentPropsMenu<T> = {
	children: ReactNode;
	innerRef: (arg1: HTMLElement | null) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	innerProps: { [key: string]: any };
} & T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReplaceableComponent = ComponentType<ReplaceableComponentProps<Record<any, any>>>;

/**
 * Subset of props we require in our implementation. See full type definition https://react-select.com/props#components
 */
export type PickerMenuProps = ComponentPropsMenu<{
	selectProps: {
		inputValue: string;
	};
}>;

export type PickerComponents = {
	Menu: ComponentType<PickerMenuProps>;
};

export type FormatOptionLabel = (option: SelectOption) => ReactElement | null;

export type OnChangeWithData = (
	options: SelectedValue,
	option: ActionMeta<SelectOption>,
	selectData?: {
		selectedIndex: number;
	},
	onSuccessCallback?: () => void,
) => void;

export type OnChange = (options: SelectedValue, option: ActionMeta<SelectOption>) => void;

export type MenuListPropsType = {
	error: {
		isError: boolean;
		error: Error | null;
		onRetry: (() => void) | null;
	};
	handleShowMore: () => void;
	isLoading: boolean;
	isPaginationLoading: boolean;
	showShowMoreButton: boolean;
};

export type BaseSelectProps = {
	isLoading?: boolean;
	inputValue?: string;
	value: SelectValue;
	closeMenuOnSelect?: boolean;
	options?: SelectValue;
	components?: SelectComponentsConfig;
	placeholder?: ReactNode;
	// eslint-disable-next-line jira/react/handler-naming
	NoOptionsMessage?: (data: { inputValue: string }) => ReactNode;
	id?: string;
	invalidLabel?: string | null;
	menuListProps: MenuListPropsType;
	formatOptionLabel?: FormatOptionLabel;
	onReload?: (arg1: string) => void;
	onInputChange?: (arg1: string, arg2: InputActionMeta) => void;
	onChange?: OnChange;
	onClear?: () => void;
	onMenuScrollToBottom?: (arg1: WheelEvent | TouchEvent) => void;
	onMenuScrollToTop?: (arg1: WheelEvent | TouchEvent) => void;
	isMulti?: boolean;
	/**
	 * Escape hatch to set the initial focus for a specific element when the popup is opened.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setInitialFocusRef?: (elem: any) => void;
	/**
	 * Additional attributes to be sent only in experience analytics events
	 * Consumers should provide a stable object reference as a new experience event will be re-emitted if the reference changes
	 */
	experienceAnalyticsAttributes?: AdditionalEventAttributes;
	styles?: StylesConfig<SelectOption, boolean>;
	isInBetweenColumnPickerEnabled?: boolean;
};

// Operator select types
export type OperatorOption = Operator & {
	label: string;
	shortLabel: string;
	valueLabel: OperatorValue;
	ariaLabel: string;
};
export type AllowedOperators = OperatorOption[];
export type OnChangeOperator = (value: ValueType<OperatorOption>) => void;
export type OperatorsSelectProps = {
	onChange: OnChangeOperator;
	allowedOperators: AllowedOperators;
	selectedOperator?: OperatorOption;
};

export type OnRestoreDefaults = boolean | (() => SelectValue);
