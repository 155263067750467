import type { Team } from '@atlassian/jira-issue-shared-types/src/common/types/team-type.tsx';
import type {
	team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef$key as TeamFragment,
	team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef$data as TeamEditViewWithSuggestionsData,
} from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragment_fieldOptionsFragmentRef.graphql';
import type {
	team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef$key as TeamFragmentNew,
	team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef$data as TeamEditViewWithSuggestionsDataNew,
} from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamEditviewFull_TeamEditViewWithFieldOptionsFragmentNew_fieldOptionsFragmentRef.graphql';
import type {
	EditViewAppearanceProps,
	EditViewSelectProps,
} from '@atlassian/jira-issue-field-edit-view-common/src/common/types.tsx';

export type RecentlyCreatedTeam = Team & { isRecentlyCreated: true; teamAri: string };
export const isRecentlyCreatedTeam = (team: NullableTeamOption): team is RecentlyCreatedTeam => {
	return team != null && Object.hasOwn(team, 'isRecentlyCreated');
};
export type NullableTeamOption = Team | RecentlyCreatedTeam | null;

/** Props for \<[TeamEditView](./index.tsx) /> */
export interface TeamEditViewProps extends EditViewAppearanceProps, EditViewSelectProps {
	/** Whether the field should automatically receive focus on render or not */
	autoFocus?: boolean;
	/** Whether suggestions should be fetched as the component mounts */
	fieldId: string;
	/** This is the name (a.k.a. label) of the field */
	fieldName?: string;
	/** current value of the field */
	value?: NullableTeamOption;
	/** handler called when the value is changed */
	onChange?: (value: NullableTeamOption) => void;
	/** boolean to denote the disabled state of a field */
	isDisabled?: boolean;
	/** used for linking field to label */
	inputId?: string;
	/** used for getting information about suggestions from the relay */
	sessionId: string;
	/**
	 * Used for style input format
	 *
	 * @deprecated This prop is deprecated and is replaced by `spacing`
	 */
	appearance?: 'normal' | 'compact';
	/**
	 * Control the overflow of the dropdownmenu
	 *
	 * @deprecated This prop is deprecated and is replaced by `menuPosition`
	 */
	isDropdownMenuFixedAndLayered?: boolean;
	ariaLabel?: string;
	ariaLabelledBy?: string;
}

/** Props for \<[TeamEditViewWithFieldOptionsFragment](./index.tsx) /> */
export interface TeamEditViewWithFieldOptionsFragmentProps extends TeamEditViewProps {
	fieldOptionsFragmentRef: TeamFragment;
	organisationId: string;
	siteId: string;
}

export interface TeamEditViewWithFieldOptionsFragmentNewProps extends TeamEditViewProps {
	fieldOptionsFragmentRef: TeamFragmentNew;
	organisationId: string;
	siteId: string;
}

export type AggJiraTeamConnection = NonNullable<TeamEditViewWithSuggestionsData['node']>['teams'];
export type AggJiraTeamConnectionNew = NonNullable<
	TeamEditViewWithSuggestionsDataNew['node']
>['teams'];

export type UseTeamOptions = {
	fieldOptionsData: AggJiraTeamConnection;
	value?: NullableTeamOption;
	searchByString: string;
	formattedNoValueText: string;
	lastFetchError: Error | null;
};

export type UseTeamOptionsNew = {
	fieldOptionsData: AggJiraTeamConnectionNew;
	value?: NullableTeamOption;
	searchByString: string;
	formattedNoValueText: string;
	lastFetchError: Error | null;
};

export type AggTeamView = {
	jiraSuppliedId: string;
	jiraSuppliedName: string;
	jiraSuppliedVisibility?: boolean | null;
	jiraSuppliedTeamId?: string | null;
	jiraIncludesYou?: boolean | null;
	jiraMemberCount?: number | null;
	jiraSuppliedIsVerified?: boolean | null;
};
