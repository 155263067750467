import React, { memo, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import type { issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell$key } from '@atlassian/jira-relay/src/__generated__/issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell.graphql';
import ErrorCell from '../../error-cell/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../../controllers/features/selectors.tsx';
import { HoverPopover } from '../../hover-popover/index.tsx';

export type Props = {
	fieldRef: issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell$key;
};

export const IssueStatusCategoryCell = memo<Props>(({ fieldRef }: Props) => {
	const { statusCategory } =
		useFragment<issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell$key>(
			graphql`
				fragment issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell on JiraStatusCategoryField {
					statusCategory {
						name
						statusCategoryId
					}
				}
			`,
			fieldRef,
		);
	const { name } = statusCategory;
	const statusCategoryId = parseInt(statusCategory.statusCategoryId, 10);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const contentRef = useRef(null);

	if (Number.isNaN(statusCategoryId) || statusCategoryId === -1 || !name?.length) {
		return <ErrorCell cellType="statusCategory" reason="Missing required fields" />;
	}

	if (!isSingleLineRowHeightEnabled)
		return (
			<Box xcss={alignmentFixStyles}>
				{/* IssueStatusFieldReadonly handles rendering the statusCategory in a manner consistent to status - rendering the correct color for a given status category.
                Status id is intentially left blank
             */}
				<IssueStatusFieldReadonly
					value={{ id: '', name, statusCategory: { id: statusCategoryId } }}
				/>
			</Box>
		);

	return (
		<HoverPopover content={statusCategory.name} contentRef={contentRef}>
			<Box xcss={alignmentFixStyles}>
				{/* IssueStatusFieldReadonly handles rendering the statusCategory in a manner consistent to status - rendering the correct color for a given status category.
                Status id is intentially left blank */}
				<IssueStatusFieldReadonly
					value={{ id: '', name, statusCategory: { id: statusCategoryId } }}
					contentRef={contentRef}
				/>
			</Box>
		</HoverPopover>
	);
});

// Status lozenges have a 2px invisible border around them to keep them in the same place if they enter an "error" state (where a border colour is added).
// Refer to: src/packages/issue/fields/status/src/common/ui/status-lozenge/main.tsx

const alignmentFixStyles = xcss({
	marginLeft: 'space.negative.025',
});
