import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unassignedDisplayName: {
		id: 'native-issue-table.row.group-row.group-header.assignee-group-header.unassigned-display-name',
		defaultMessage: 'Unassigned',
		description:
			'Text used as a group name for unassigned issues when grouping items by assignee is enabled.',
	},
});
