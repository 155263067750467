import React, { useCallback, type ReactNode } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import { Box, Inline, Text } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import AddIcon from '@atlaskit/icon/core/migration/add';
import InformationIcon from '@atlaskit/icon/core/migration/information--info';
import Anchor from '@atlaskit/primitives/anchor';
import { useIntl } from '@atlassian/jira-intl';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-providers-project-context/src/index.tsx';
import {
	createOptimisticFieldConfig,
	deleteOptimisticFieldConfig,
	getUpdatedOptions,
} from '@atlassian/jira-issue-table-column-picker/src/ui/utils.tsx';
import {
	MetaActions,
	type SingleValueOption,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import type { FooterType } from '@atlassian/jira-issue-table-column-picker/src/common/types.tsx';
import { useIFCConfigContext } from '../context/index.tsx';
import messages from './messages.tsx';
import { TEMP_CREATE_FIELDSET_ID, updateTempOptimisticFieldSetId } from './utils.tsx';

const styles = cssMap({
	ifcFooter: {
		paddingTop: token('space.200'),
		paddingRight: token('space.200'),
		paddingBottom: token('space.200'),
		paddingLeft: token('space.200'),
		display: 'flex',
		flexDirection: 'row',
		borderColor: token('color.border'),
		borderTopStyle: 'solid',
		borderTopWidth: token('border.width'),
		justifyContent: 'center',
	},
});

export const IFCFooter = ({
	onPickerClose,
	columnsDataId,
	selectedColumns,
	onChange,
	columnInsertIndex,
}: Parameters<FooterType>[0]) => {
	const ifcConfig = useIFCConfigContext();
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const isTMP = useIsSimplifiedProject();
	const isCMPAdmin = isAdmin && !isTMP;
	const isTMPAdmin = isAdmin && isTMP;

	const environment = useRelayEnvironment();

	const onStartFieldCreate = useCallback(
		({ name, type }: { name: string; type: string }) => {
			const field = { fieldSetId: TEMP_CREATE_FIELDSET_ID, name, type };
			createOptimisticFieldConfig({
				environment,
				fieldConfigId: columnsDataId,
				fields: [field],
				createIndex: columnInsertIndex,
			});
		},
		[columnInsertIndex, columnsDataId, environment],
	);

	const onFieldCreated = useCallback(
		(fieldId: string, fieldSetId?: string) => {
			if (fieldSetId) {
				const label = fieldSetId.substring(0, fieldSetId.indexOf('['));
				const field: SingleValueOption = {
					optionType: 'option',
					value: fieldSetId,
					label,
				};

				if (selectedColumns) {
					updateTempOptimisticFieldSetId({
						environment,
						fieldConfigId: columnsDataId,
						fieldSetId,
					});

					const selectedOptions = getUpdatedOptions(selectedColumns, [field], columnInsertIndex);

					onChange?.(selectedOptions, {
						action: MetaActions.selectOption,
						option: undefined,
					});
				}
			}
		},
		[columnInsertIndex, columnsDataId, environment, onChange, selectedColumns],
	);

	const onFieldCreateError = useCallback(() => {
		deleteOptimisticFieldConfig({
			environment,
			fieldConfigId: columnsDataId,
			fields: [
				{
					fieldSetId: TEMP_CREATE_FIELDSET_ID,
					name: '',
				},
			],
		});
	}, [columnsDataId, environment]);

	const clickHandler = useCallback(
		(event: React.MouseEvent) => {
			onPickerClose?.(event);
			ifcConfig?.onCreate({ onFieldCreated, onStartFieldCreate, onFieldCreateError });
		},
		[ifcConfig, onFieldCreated, onFieldCreateError, onPickerClose, onStartFieldCreate],
	);

	if (!isAdmin) {
		return (
			<Box xcss={styles.ifcFooter}>
				<Inline space="space.100" alignBlock="center" alignInline="center">
					<InformationIcon label="" color={token('color.icon.accent.blue')} />
					<Text>
						{formatMessage(messages.contactAdmin, {
							link: (chunks: ReactNode) => (
								<Anchor href="/secure/ContactAdministrators!default.jspa" target="_blank">
									{chunks}
								</Anchor>
							),
						})}
					</Text>
				</Inline>
			</Box>
		);
	}

	if (isTMPAdmin && ifcConfig?.IFCTriggerRef) {
		return (
			<Box xcss={styles.ifcFooter}>
				<ifcConfig.IFCTriggerRef>
					{({ triggerRef }) => (
						<Button ref={triggerRef} onClick={clickHandler} shouldFitContainer>
							<Inline space="space.100" alignBlock="center" alignInline="center">
								<AddIcon label="" />
								<Text>{formatMessage(messages.createAnotherButton)}</Text>
							</Inline>
						</Button>
					)}
				</ifcConfig.IFCTriggerRef>
			</Box>
		);
	}

	if (isCMPAdmin) {
		return (
			<Box xcss={styles.ifcFooter}>
				<Inline space="space.100" alignBlock="center" alignInline="center">
					<InformationIcon label="" color={token('color.icon.accent.blue')} />
					<Text>
						{formatMessage(messages.makeChangesInConfig, {
							link: (chunks: ReactNode) => (
								<Anchor href="/secure/admin/ViewCustomFields.jspa" target="_blank">
									{chunks}
								</Anchor>
							),
						})}
					</Text>
				</Inline>
			</Box>
		);
	}
};
