import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { xcss, Box } from '@atlaskit/primitives';
import Tag from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import type { components_issueFieldComponentsReadviewFull_ComponentsReadView$key as ComponentsFragment } from '@atlassian/jira-relay/src/__generated__/components_issueFieldComponentsReadviewFull_ComponentsReadView.graphql';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import ListWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';

import { toQuotedString } from '@atlassian/jira-common-jql/src/helpers.tsx';
import messages from './messages.tsx';
import type { ComponentsReadViewProps } from './types.tsx';

export const generateComponentsHref = (value: string, baseSearchUrl = '/issues/'): string => {
	const jqlEncodedValue = encodeURIComponent(`components = ${toQuotedString(value)}`);

	return `${baseSearchUrl}?jql=${jqlEncodedValue}`;
};

/**
 * The ComponentsReadView will show a read only view of components field.
 * @param props [ComponentsReadViewProps](./types.tsx)
 */
export const ComponentsReadView = ({
	baseSearchUrl,
	fragmentRef,
	isTruncated,
}: ComponentsReadViewProps) => {
	const data = useFragment<ComponentsFragment>(
		graphql`
			fragment components_issueFieldComponentsReadviewFull_ComponentsReadView on JiraComponentsField {
				selectedComponentsConnection(first: 1000) @required(action: THROW) {
					edges {
						node {
							value: id
							label: name
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const { formatMessage } = useIntl();

	const selectedComponents =
		data?.selectedComponentsConnection?.edges
			?.filter((edge) => Boolean(edge?.node?.label))
			.map((item) => ({
				name: item?.node?.label || '',
				key: item?.node?.value || '',
				href: item?.node?.label
					? generateComponentsHref(item?.node?.label, baseSearchUrl)
					: undefined,
			})) || [];

	if (!selectedComponents?.length) {
		return <EmptyFieldText />;
	}

	if (isTruncated) {
		return (
			<Box paddingBlock="space.050">
				<ListWithPopup
					items={selectedComponents}
					ItemComponent={TagItem}
					PopupContentWrapper={TagItemPopupContentWrapper}
					showMoreTooltip={formatMessage(messages.showMoreTooltip)}
				/>
			</Box>
		);
	}

	return (
		<Box xcss={containerWithNegativeMarginOffset}>
			{selectedComponents.map((item) => (
				<Tag key={item.key} isRemovable={false} text={item.name} href={item.href} />
			))}
		</Box>
	);
};

const containerWithNegativeMarginOffset = xcss({
	display: 'flex',
	width: '100%',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	// Tag has a built-in margin of 4px, so we use a negative side margin to allow tags to align with their parent.
	marginTop: 'space.0',
	marginRight: 'space.negative.050',
	marginBottom: 'space.0',
	marginLeft: 'space.negative.050',
});
