import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useElementSizeSelector } from '../../../controllers/element-size/index.tsx';
import {
	useScrollElement,
	useScrollStateSelector,
} from '../../../controllers/scroll-state/index.tsx';
import { LoadingIndicator } from './loading-indicator/index.tsx';
import messages from './messages.tsx';

type Props = {
	isLoadingPrevious?: boolean;
	isLoadingNext?: boolean;
	isGrouping?: boolean;
	onLoadingThresholdCheck?: (scrollElement: {
		clientHeight: number;
		scrollHeight: number;
		scrollTop: number;
	}) => void;
};

const getLoadingA11YMessage = ({
	isLoadingNext,
	isLoadingPrevious,
	hasFinishedLoadingNext,
	hasFinishedLoadingPrevious,
	isGrouping,
}: {
	isLoadingNext?: boolean;
	isLoadingPrevious?: boolean;
	hasFinishedLoadingNext?: boolean;
	hasFinishedLoadingPrevious?: boolean;
	isGrouping?: boolean;
}) => {
	if (isGrouping) {
		if (isLoadingPrevious) {
			return messages.loadStartA11YMessageTopWithGrouping;
		}
		if (hasFinishedLoadingPrevious) {
			return messages.loadEndA11YMessageTopWithGrouping;
		}
		if (isLoadingNext) {
			return messages.loadStartA11YMessageBottomWithGrouping;
		}
		if (hasFinishedLoadingNext) {
			return messages.loadEndA11YMessageBottomWithGrouping;
		}
	}

	if (isLoadingPrevious) {
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.loadStartA11YMessageTopIssueTermRefresh
			: messages.loadStartA11YMessageTop;
	}
	if (hasFinishedLoadingPrevious) {
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.loadEndA11YMessageTopIssueTermRefresh
			: messages.loadEndA11YMessageTop;
	}
	if (isLoadingNext) {
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.loadStartA11YMessageBottomIssueTermRefresh
			: messages.loadStartA11YMessageBottom;
	}
	if (hasFinishedLoadingNext) {
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.loadEndA11YMessageBottomIssueTermRefresh
			: messages.loadEndA11YMessageBottom;
	}

	return undefined;
};

export const LoadingManager = ({
	isLoadingPrevious,
	isLoadingNext,
	isGrouping,
	onLoadingThresholdCheck,
}: Props) => {
	const { formatMessage } = useIntl();
	// Table has height 0 during first render due to virtualization. Some checks need to wait for the table to have a height.
	const hasTableHeight = useElementSizeSelector((tableState) => Boolean(tableState.height));
	const scrollElement = useScrollElement();
	const height = useScrollStateSelector((scrollState) => scrollState.height);

	// #region After-load Handler
	/**
	 * After initial render and any subsequent loading events, make sure that we continue loading in more issues if
	 * necessary because a single load may not have enough issues to fill the scroll container.
	 */
	useEffect(() => {
		if (
			scrollElement &&
			onLoadingThresholdCheck &&
			hasTableHeight &&
			!isLoadingPrevious &&
			!isLoadingNext &&
			!isRefactorNinToViewSchemaEnabled()
		) {
			onLoadingThresholdCheck(scrollElement);
		}
	}, [hasTableHeight, isLoadingPrevious, isLoadingNext, onLoadingThresholdCheck, scrollElement]);

	useEffect(() => {
		if (
			scrollElement &&
			onLoadingThresholdCheck &&
			hasTableHeight &&
			!isLoadingPrevious &&
			!isLoadingNext &&
			height &&
			isRefactorNinToViewSchemaEnabled()
		) {
			onLoadingThresholdCheck(scrollElement);
		}
	}, [
		hasTableHeight,
		isLoadingPrevious,
		isLoadingNext,
		onLoadingThresholdCheck,
		scrollElement,
		height,
	]);
	// #endregion

	// #endregion

	// #region Resize Handler
	/**
	 * Check the loading boundaries when resizing the scroll container, as the new size may require loading more issues.
	 */
	const prevHeight = usePreviousWithInitial(height);
	useEffect(() => {
		if (
			scrollElement &&
			onLoadingThresholdCheck &&
			hasTableHeight &&
			height > prevHeight &&
			!isRefactorNinToViewSchemaEnabled()
		) {
			onLoadingThresholdCheck(scrollElement);
		}
	}, [hasTableHeight, height, prevHeight, onLoadingThresholdCheck, scrollElement]);

	// #region Top Scroll Handler
	/**
	 * A component that ensures that the scroll position is kept stable when loading previous issues.
	 * This is necessary because sometimes the browser does scroll to keep existing rows in view when new rows are prepended.
	 *
	 * Without this, we may see issues like continuously loading all previous issues until there are none left.
	 */
	const prevIsLoadingPrevious = usePreviousWithInitial(isLoadingPrevious);
	const hasStartedLoadingPrevious = !prevIsLoadingPrevious && isLoadingPrevious;
	const hasFinishedLoadingPrevious = prevIsLoadingPrevious && !isLoadingPrevious;

	// Hook's scrollHeight doesn't update the height information when the scroll height changes due to DOM mutations
	// Need to track this separately when loading starts
	const scrollHeightRef = useRef(0);

	useEffect(() => {
		if (scrollElement && hasStartedLoadingPrevious) {
			scrollHeightRef.current = scrollElement.scrollHeight;
		}
	}, [hasStartedLoadingPrevious, scrollElement]);

	// Ensure that the scroll is kept in place after loading previous issues - browser doesn't always handle this properly
	// Using a layout effect to make sure this happens as quickly as possible to reduce visible movement
	useLayoutEffect(() => {
		if (scrollElement && hasFinishedLoadingPrevious) {
			const newScrollHeight = scrollElement.scrollHeight;

			if (newScrollHeight > scrollHeightRef.current) {
				const heightIncrease = newScrollHeight - scrollHeightRef.current;
				scrollElement.scrollBy(0, heightIncrease);
			}

			scrollHeightRef.current = newScrollHeight;
		}
	}, [hasFinishedLoadingPrevious, scrollElement]);
	// #endregion

	// #region Loading A11y
	const prevIsLoadingNext = usePreviousWithInitial(isLoadingNext);
	const hasFinishedLoadingNext = prevIsLoadingNext && !isLoadingNext;
	const loadingCompleteLiveMessage = useRef<MessageDescriptor | undefined>(undefined);

	loadingCompleteLiveMessage.current = getLoadingA11YMessage({
		isLoadingNext,
		isLoadingPrevious,
		hasFinishedLoadingNext,
		hasFinishedLoadingPrevious,
		isGrouping,
	});

	// #endregion

	return (
		<>
			{isLoadingPrevious && <LoadingIndicator position="top" isGrouping={isGrouping} />}
			{isLoadingNext && <LoadingIndicator position="bottom" isGrouping={isGrouping} />}
			{loadingCompleteLiveMessage.current && (
				<ScreenReaderText role="alert" aria-live="polite">
					{formatMessage(loadingCompleteLiveMessage.current)}
				</ScreenReaderText>
			)}
		</>
	);
};
