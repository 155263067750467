import React, {
	useCallback,
	useEffect,
	useRef,
	type PropsWithChildren,
	type ReactNode,
	type UIEventHandler,
} from 'react';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { Box, xcss } from '@atlaskit/primitives';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { COLUMN_OPERATION_RESIZE } from '../../common/constants.tsx';
import type { HeaderHeight } from '../../common/types.tsx';
import { useIsInfiniteScrollEnabled } from '../../controllers/features/selectors.tsx';
import {
	useInfiniteScrollHandler,
	type Props as InfiniteScrollProps,
} from '../../controllers/infinite-scroll-handler/index.tsx';
import { ScrollStateProvider } from '../../controllers/scroll-state/index.tsx';
import { useSiblingIssueCreateOnScroll } from '../../controllers/sibling-issue-create-handler/index.tsx';
import { useIntersectionObserver } from '../../controllers/table-items-intersection-observer/index.tsx';
import { LoadingManager } from './loading-manager/index.tsx';

export type Props = PropsWithChildren<
	{
		loading?: boolean;
		isGrouping?: boolean;
		footer: ReactNode;
	} & InfiniteScrollProps
>;

export const useScrollToTopWhenLoadingNewIssues = ({
	loading,
	resetScroll,
}: {
	loading?: boolean;
	resetScroll: () => void;
}) => {
	const prevLoading = usePrevious(loading);

	// Reseting the scroll when the connection id change will cover any variable change
	useEffect(() => {
		if (prevLoading !== loading && prevLoading === true) {
			resetScroll();
		}
	});
};

export const ScrollContainer = ({
	children,
	footer,
	loading,
	isGrouping,
	...infiniteScrollProps
}: Props) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const siblingIssueCreateOnScroll = useSiblingIssueCreateOnScroll();
	const isInfiniteScrollEnabled = useIsInfiniteScrollEnabled();

	const resetScroll = useCallback(() => {
		if (scrollContainerRef.current != null && isInfiniteScrollEnabled) {
			scrollContainerRef.current.scrollTop = 0;
		}
	}, [isInfiniteScrollEnabled]);

	useScrollToTopWhenLoadingNewIssues({ loading, resetScroll });

	const { onLoadingThresholdCheck, onScroll: infiniteScrollOnScroll } =
		useInfiniteScrollHandler(infiniteScrollProps);

	const onScroll = useCallback<UIEventHandler>(
		(event) => {
			siblingIssueCreateOnScroll?.(event);
			if (isInfiniteScrollEnabled) {
				infiniteScrollOnScroll(event);
			}
		},
		[siblingIssueCreateOnScroll, isInfiniteScrollEnabled, infiniteScrollOnScroll],
	);

	useEffect(() => {
		// Handle auto-scrolling for pragmatic dnd operations like column resizing
		const scrollableElement = scrollContainerRef.current;

		if (scrollableElement) {
			return autoScrollForElements({
				element: scrollableElement,
				canScroll: ({ source }) => source.data.operation !== COLUMN_OPERATION_RESIZE,
			});
		}
	}, []);

	const intersectionObsRef = useIntersectionObserver();
	const ref = useMergeRefs(intersectionObsRef, scrollContainerRef);

	return (
		<ScrollStateProvider scrollRef={scrollContainerRef}>
			<Box xcss={loadingIndicatorContainerStyles}>
				<Box
					xcss={scrollContainerStyles}
					ref={ref}
					onScroll={onScroll}
					testId="native-issue-table.ui.scroll-container.scroll-container"
				>
					{children}
				</Box>
				{isInfiniteScrollEnabled && (
					<LoadingManager
						isLoadingPrevious={infiniteScrollProps.isLoadingPrevious}
						isLoadingNext={infiniteScrollProps.isLoadingNext}
						onLoadingThresholdCheck={onLoadingThresholdCheck}
						isGrouping={isGrouping}
					/>
				)}
			</Box>
			{footer}
		</ScrollStateProvider>
	);
};

const HEADER_HEIGHT: HeaderHeight = 40;
const scrollContainerStyles = xcss({
	display: 'flex',
	position: 'relative',
	flexFlow: 'column nowrap',
	overflow: 'auto',
	// Since header is sticky, we need to specify a scroll padding that matches its height so that auto-scrolling
	// functionality accounts for this element in its calculations, otherwise header can overlap the selected row.
	scrollPaddingTop: `${HEADER_HEIGHT}px`,
});

const loadingIndicatorContainerStyles = xcss({
	display: 'flex',
	flexFlow: 'column nowrap',
	position: 'relative',
	overflow: 'hidden',
});
