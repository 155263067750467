/**
 * @generated SignedSource<<b486690fc24de66310921e30e199c6eb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type parent_nativeIssueTable_ParentCell_data$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef">;
  readonly " $fragmentType": "parent_nativeIssueTable_ParentCell_data";
};
export type parent_nativeIssueTable_ParentCell_data$key = {
  readonly " $data"?: parent_nativeIssueTable_ParentCell_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"parent_nativeIssueTable_ParentCell_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "parent_nativeIssueTable_ParentCell_data",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef"
    }
  ],
  "type": "JiraParentIssueField"
};

(node as any).hash = "5bd5d278efda13fd8ec2a79f00dfd945";

export default node;
