/**
 * @generated SignedSource<<4e85c1b6294972f8a4727536d560cd70>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly value: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView">;
  readonly " $fragmentType": "checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef";
};
export type checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView"
    },
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "value"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraCheckboxesField"
};
})();

(node as any).hash = "949a32b9c93f1898a846b4634eca9e93";

export default node;
