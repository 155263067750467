import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createButton: {
		id: 'issue-table-inline-issue-create.inline-create-from.create-button',
		defaultMessage: 'Create',
		description: 'Text on the create button.',
	},
	placeholderText: {
		id: 'issue-table-inline-issue-create.inline-create-from.placeholder-text',
		defaultMessage: 'What needs to be done?',
		description: 'Placeholder text in the summary field to prompt the user to enter a summary.',
	},
});
