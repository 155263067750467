import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyIssues: {
		id: 'native-issue-table.issue-table.empty-issues.empty-issues',
		defaultMessage: 'There are no issues',
		description: 'Message shown when no issues are available.',
	},
	emptyIssuesIssueTermRefresh: {
		id: 'native-issue-table.issue-table.no-issues-renderer.no-issues.empty-issues-issue-term-refresh',
		defaultMessage: 'There are no work items',
		description: 'Message shown when no issues are available.',
	},
});
