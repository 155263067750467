import React, { useCallback, useMemo, useState } from 'react';

import { useFragment, graphql, useMutation } from 'react-relay';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';

import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';

import type {
	ComponentsEditViewProps,
	CreatableOption,
} from '@atlassian/jira-issue-field-components-editview-full/src/ui/components/types.tsx';
import type { Option } from '@atlassian/jira-issue-field-components-editview-full/src/common/types.tsx';
import ComponentsEditViewEntryPoint from '@atlassian/jira-issue-field-components-editview-full/src/entrypoint.tsx';
import { ComponentsReadView } from '@atlassian/jira-issue-field-components-readview-full/src/ui/components/index.tsx';
import type { components_issueFieldComponents_ComponentsField_Mutation as ComponentsMutation } from '@atlassian/jira-relay/src/__generated__/components_issueFieldComponents_ComponentsField_Mutation.graphql';
import type { components_issueFieldComponentsInlineEditFull_ComponentsInlineEditView_fragmentRef$key as ComponentsFragment } from '@atlassian/jira-relay/src/__generated__/components_issueFieldComponentsInlineEditFull_ComponentsInlineEditView_fragmentRef.graphql';
import type { components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef$key as ComponentsWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef.graphql';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import type {
	ComponentsInlineEditViewProps,
	ComponentsInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: Option[] | null, b: Option[] | null) => {
	if (a === null || b === null) return a === b;
	if (a.length !== b.length) return false;

	const aIdSet = new Set(a.map((option) => option.value));
	return b.every((option) => aIdSet.has(option.value));
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `ComponentsInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [ComponentsInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const ComponentsInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	isTruncated,
	baseSearchUrl,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
}: ComponentsInlineEditViewWithIsEditableProps) => {
	const data = useFragment<ComponentsWithIsEditableFragment>(
		graphql`
			fragment components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef on JiraComponentsField {
				id
				name
				fieldId
				type
				...components_issueFieldComponentsReadviewFull_ComponentsReadView
				selectedComponentsConnection(first: 1000) @required(action: THROW) {
					edges {
						node {
							value: id
							label: name
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<ComponentsMutation>(graphql`
		mutation components_issueFieldComponents_ComponentsField_Mutation(
			$input: JiraUpdateComponentsFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateComponentsField(input: $input) {
					success
					errors {
						message
					}
					field {
						...components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const {
		id: uniqueFieldId,
		name,
		selectedComponentsConnection: selectedOptions,
		fieldId,
		type: fieldType,
	} = data;

	const initialValue: Option[] | null = useMemo(() => {
		if (!selectedOptions?.edges) {
			return null;
		}

		return selectedOptions.edges.map((edge) => ({
			value: edge?.node?.value ?? '',
			label: edge?.node?.label ?? '',
		}));
	}, [selectedOptions?.edges]);

	const [updatedValue, setUpdatedValue] = useState<Option[] | null>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);

	const handleSubmit = useCallback(
		(values: Option[] | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(values);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								operation: 'SET',
								ids: values?.map((selection) => selection.value) ?? [],
							},
						],
					},
				},
				onCompleted(response) {
					if (response.jira?.updateComponentsField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateComponentsField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedComponentsConnection: {
									edges: values?.map((edge) => ({
										node: {
											id: edge.value ?? '',
											label: edge.label ?? '',
											value: edge.value ?? '',
										},
									})),
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	const renderReadView = useCallback(
		() => (
			<ComponentsReadView
				fragmentRef={data}
				isTruncated={isTruncated}
				baseSearchUrl={baseSearchUrl}
			/>
		),
		[data, baseSearchUrl, isTruncated],
	);

	const handleOnChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(values: ReadonlyArray<Option | CreatableOption> | null, actionMeta: ActionMeta<Option>) => {
			const mutableOptions =
				values?.map((i): Option => ({ label: i.label, value: i.value || '' })) || null;
			handleChange(mutableOptions);
		},
		[handleChange],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): ComponentsEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleOnChange,
		fetchSuggestionsOnMount: true,
		spacing,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={ComponentsEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [ComponentsInlineEditViewProps](./types.tsx)
 */
export const ComponentsInlineEditView = ({
	fragmentRef,
	...props
}: ComponentsInlineEditViewProps) => {
	/**
	 * This fragment should spread the inner InlineEditViewWithIsEditable fragment and read the isEditable flag from the
	 * fieldConfig.
	 */
	const data = useFragment<ComponentsFragment>(
		graphql`
			fragment components_issueFieldComponentsInlineEditFull_ComponentsInlineEditView_fragmentRef on JiraComponentsField {
				...components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<ComponentsInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
