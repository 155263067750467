import React from 'react';
import { styled } from '@compiled/react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { token } from '@atlaskit/tokens';

type Props = {
	// Avoid dynamically generating `interactionName`.
	// Each consumer should use a consistent `interactionName` for a specific overlay.
	interactionName: string;
};

export const Overlay = ({ interactionName }: Props) => {
	return (
		// We are intentionally ignoring jira/ufo/valid-labels because we have a valid use case
		// for an encapsulated generic component, similar to generic AK components.
		// eslint-disable-next-line jira/ufo/valid-labels
		<UFOLoadHold name={interactionName}>
			<StyledOverlay />
		</UFOLoadHold>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledOverlay = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,

	backgroundColor: token('color.background.neutral.subtle.hovered'),
	opacity: 0.8,
	content: "''",
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OverlayOld = StyledOverlay;
