import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createStateHook,
	type Action,
} from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State, Actions, Props } from './types.tsx';

/**
 * Store to manage GraphQL variables required for fetching fragment data within the issue table as runtime props. This
 * allows us to avoid excessive prop drilling for operations that trigger a refetch of data within the table. Any
 * rendering logic dependent on GraphQL data should leverage Relay fragments, not this store..
 */
export const IssueFetchQueryVariablesContainer = createContainer<Props>();

export const initialState: State = {
	fieldSetIds: [],
};

const updateStore =
	(): Action<State, Props> =>
	({ setState, getState }, { fieldSetIds }) => {
		if (fg('empanada_nin_concurrent_mode_fixes')) {
			const state = getState();
			if (!isEqual(fieldSetIds, state.fieldSetIds)) {
				setState({ fieldSetIds });
			}
		} else {
			setState({ fieldSetIds });
		}
	};

const Store = createStore<State, Actions, Props>({
	containedBy: IssueFetchQueryVariablesContainer,
	initialState,
	actions: {},
	handlers: {
		onInit: updateStore,
		onContainerUpdate: updateStore,
	},
});

export const useIssueFetchQueryVariables = createStateHook(Store);
