/**
 * @generated SignedSource<<f3eac2c78547fb53e97538d0f152a09b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type priorityGroupHeader_nativeIssueTable$data = {
  readonly displayName: string;
  readonly priority: {
    readonly iconUrl: AGG$URL | null | undefined;
  };
  readonly " $fragmentType": "priorityGroupHeader_nativeIssueTable";
};
export type priorityGroupHeader_nativeIssueTable$key = {
  readonly " $data"?: priorityGroupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"priorityGroupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "priorityGroupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "displayName"
    },
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        }
      ]
    }
  ],
  "type": "JiraJqlPriorityFieldValue"
};

(node as any).hash = "2bbedaec577f256ea116a98cbba06b13";

export default node;
