import React, { type PropsWithChildren, useEffect, useState } from 'react';
import DataLoader from 'dataloader';
import { useRelayEnvironment } from 'react-relay';
import type { ViewContext } from '@atlassian/jira-issue-media-provider/src/common/types.tsx';
import { viewContextTransformer } from '@atlassian/jira-issue-media-provider/src/controllers/create-media-provider/utils.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer, createActionsHook } from '@atlassian/react-sweet-state';
import { createDataLoaderScheduler } from '../../common/utils/data-loader-scheduler/index.tsx';
import { actions, batchMediaViewResponseFactory } from './actions.tsx';
import { MAX_BATCH_SIZE } from './constants.tsx';
import type { Actions, Props, State } from './types.tsx';

const initialState: State = {
	loader: null,
	scheduler: null,
};

const Container = createContainer<Props>();

const Store = createStore<State, Actions, Props>({
	containedBy: Container,
	initialState,
	actions,
	handlers: {
		onInit:
			() =>
			({ setState }, { environment, cloudId }) => {
				const scheduler = createDataLoaderScheduler(100);
				const loader = new DataLoader(batchMediaViewResponseFactory(environment, cloudId), {
					maxBatchSize: MAX_BATCH_SIZE,
					batchScheduleFn: scheduler.schedule,
				});
				setState({
					loader,
					scheduler,
				});
			},
	},
});

export const LazyMediaViewContextLoaderContainer = ({
	children,
	scope,
}: PropsWithChildren<{ scope?: string }>) => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	return (
		<Container scope={scope} environment={environment} cloudId={cloudId}>
			{children}
		</Container>
	);
};

export const useLazyMediaViewContextLoaderActions = createActionsHook(Store);

export const useLazyMediaViewContext = (issueKey: string): ViewContext | null => {
	const { load } = useLazyMediaViewContextLoaderActions();
	const [viewContext, setViewContext] = useState<ViewContext | null>();

	useEffect(() => {
		const executeLoad = async () => {
			// We only want to fetch when viewContext's value hasn't been initialised yet
			if (viewContext === undefined) {
				const viewResponse = await load(issueKey);

				if (viewResponse) {
					setViewContext(viewContextTransformer(viewResponse));
				} else {
					setViewContext(null);
				}
			}
		};
		executeLoad();
	}, [viewContext, load, issueKey]);

	return viewContext ?? null;
};
