/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss, type XCSS } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useHierarchyActions } from '../../controllers/hierarchy/index.tsx';

export type RenderTableCell = (args: {
	key: string;
	colSpan: number;
	border: Array<XCSS | false | undefined>;
	children: JSX.Element;
}) => JSX.Element;

type Props = {
	itemId: string;
	groupId?: string;
	isFixedTableLayoutEnabled: boolean;
	columnsCount: number;
	renderTableCell: RenderTableCell;
};

const LoadingIssueRow = ({
	itemId,
	groupId,
	columnsCount,
	isFixedTableLayoutEnabled,
	renderTableCell,
}: Props) => {
	const { setItemIsLoading } = useHierarchyActions();

	useEffect(() => {
		setItemIsLoading({
			itemId,
			groupId,
			isLoading: true,
		});

		return () => {
			setItemIsLoading({
				itemId,
				groupId,
				isLoading: false,
			});
		};
	}, [itemId, groupId, setItemIsLoading]);

	const columns = Array.from({ length: columnsCount });

	const colSpans = columns.map((_, index) => {
		const isLastColumn = index === columns.length - 1;
		return 1 + (isLastColumn && isFixedTableLayoutEnabled ? 1 : 0);
	});

	return (
		<Box as="tr" xcss={wrapperStyles}>
			{columns.map((_, i) =>
				renderTableCell({
					key: `${itemId}-${i}`,
					colSpan: colSpans[i],
					border: [cellBorderStyles, i === columnsCount - 1 && lastCellBorderStyles],
					children: <Box xcss={loadingCellStyles} />,
				}),
			)}
		</Box>
	);
};

export default LoadingIssueRow;

const wrapperStyles = xcss({ height: '40px' });

const cellBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: 'border.width',
	borderBottomWidth: '0',
	borderLeftWidth: '0',
	borderRightWidth: 'border.width',
});

const lastCellBorderStyles = xcss({
	borderRightWidth: '0',
});

const loadingCellStyles = xcss({
	backgroundColor: 'color.background.neutral',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	padding: 'space.150',
	width: '100%',
});
