import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sortedTooltip: {
		id: 'native-issue-table.table-header-row.sort-headers.sorted-tooltip',
		defaultMessage: 'Sorted {sortDescription}',
		description: 'Tooltip for sorting, sortDescription is like "A -> Z"',
	},
	sortPromptTooltip: {
		id: 'native-issue-table.table-header-row.sort-headers.sort-prompt-tooltip',
		defaultMessage: 'Sort {sortDescription}',
		description:
			'Tooltip for sorting, when it has not been sorted, to prompt users to hit sort. sortDescription is like "A -> Z"',
	},
	sortPromptDefaultTooltip: {
		id: 'native-issue-table.table-header-row.sort-headers.sort-prompt-default-tooltip',
		defaultMessage: 'Sort in ascending order',
		description:
			'If no sort description is provided, this prompts the user to sort in ascending order',
	},
	sortedDefaultAscendingTooltip: {
		id: 'native-issue-table.table-header-row.sort-headers.sorted-default-ascending-tooltip',
		defaultMessage: 'Sorted in ascending order',
		description:
			'If no sort description is provided, this informs the user that it is sorted in ascending order',
	},
	sortedDefaultDescendingTooltip: {
		id: 'native-issue-table.table-header-row.sort-headers.sorted-default-descending-tooltip',
		defaultMessage: 'Sorted in descending order',
		description:
			'If no sort description is provided, this informs the user that it is sorted in descending order',
	},
});
