import React, { useEffect, useCallback, useMemo, useState, forwardRef, memo } from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { JSC_ONBOARDING_INLINE_CREATE_MESSAGE_ID, STARGATE_URL } from '../../common/constants.tsx';
import messages from './messages.tsx';
import InlineCreateOnboardingSpotlight from './onboarding/index.tsx';

export type Props = {
	onClick?: () => void;
};

const InlineCreateTrigger = forwardRef<HTMLButtonElement, Props>(({ onClick }: Props, ref) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();
	const [isStoplightVisible, setIsSpotlightVisible] = useState(false);

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, orgId),
		[cloudId, orgId],
	);

	const startTour = useCallback(async () => {
		const started = await coordinationClient?.start(JSC_ONBOARDING_INLINE_CREATE_MESSAGE_ID);
		if (started) {
			setIsSpotlightVisible(true);
		}
	}, [coordinationClient]);

	const endTour = useCallback(() => {
		setIsSpotlightVisible(false);
		coordinationClient?.stop?.(JSC_ONBOARDING_INLINE_CREATE_MESSAGE_ID);
	}, [coordinationClient]);

	useEffect(() => {
		startTour();
	}, [startTour]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickCallback = useCallback(() => {
		onClick?.();
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineIssueCreateButton');
	}, [createAnalyticsEvent, onClick]);

	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button viewed', 'inlineIssueCreateButton');
	}, [createAnalyticsEvent]);

	return (
		<SpotlightManager>
			<SpotlightTarget name="inline-create-trigger">
				<Button appearance="subtle" onClick={onClickCallback} ref={ref} iconBefore={AddIcon}>
					{formatMessage(messages.createLabel)}
				</Button>
			</SpotlightTarget>

			<InlineCreateOnboardingSpotlight showOnboarding={isStoplightVisible} onDismiss={endTour} />
		</SpotlightManager>
	);
});

export default memo(InlineCreateTrigger);
