import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const CheckboxSelectEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-checkbox-select-edit-view" */ './ui/checkbox-select'),
	),
	getPreloadProps: () => ({}),
});

export default CheckboxSelectEditViewEntryPoint;
