import { useCallback, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';
import { graphql, useFragment, useSubscription } from 'react-relay';
import type { GraphQLSubscriptionConfig } from 'relay-runtime';
import type { realtimeUpdater_issueCreated_Subscription as IssueCreateSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_issueCreated_Subscription.graphql';
import type { realtimeUpdater_issueDeleted_Subscription as IssueDeleteSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_issueDeleted_Subscription.graphql';
import type { realtimeUpdater_issueUpdated_Subscription as IssueUpdateSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_issueUpdated_Subscription.graphql';
import type { realtimeUpdater_nativeIssueTable_groups$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_nativeIssueTable_groups.graphql';
import type { realtimeUpdater_nativeIssueTable_issues$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_nativeIssueTable_issues.graphql.ts';
import type { realtimeUpdater_nativeIssueTable_project$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_nativeIssueTable_project.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import { useIssueRealtimeUpdater } from '../../controllers/issue-realtime-updater/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { useRealtimeUpdatesForIssues } from './utils.tsx';

const REAL_TIME_EVENT_TYPE_KEY = 'realTimeEventType';
const CREATE_ERROR_ID = 'realTimeIssueCreate';
const UPDATE_ERROR_ID = 'realTimeIssueUpdate';
const DELETE_ERROR_ID = 'realTimeIssueDelete';

const MAX_ALLOWED_QUEUE_SIZE = 10;
const QUERY_ISSUE_DETAILS_DEBOUNCE_TIME = 3000;

const throttledFireTrackAnalytics = throttle(fireTrackAnalytics, 1000);

type Props = {
	project: realtimeUpdater_nativeIssueTable_project$key;
	issues?: realtimeUpdater_nativeIssueTable_issues$key | null;
	groups?: realtimeUpdater_nativeIssueTable_groups$key | null;
	isHierarchyEnabled?: boolean;
};

const issueCreatedSubscription = graphql`
	subscription realtimeUpdater_issueCreated_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueCreatedByProject(cloudId: $cloudId, projectId: $projectId) {
				key
			}
		}
	}
`;

const issueUpdatedSubscription = graphql`
	subscription realtimeUpdater_issueUpdated_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueUpdatedByProject(cloudId: $cloudId, projectId: $projectId) {
				key
			}
		}
	}
`;

const issueDeletedSubscription = graphql`
	subscription realtimeUpdater_issueDeleted_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueDeletedByProject(cloudId: $cloudId, projectId: $projectId) {
				resource
			}
		}
	}
`;

export const RealtimeUpdater = ({ project, issues, groups, isHierarchyEnabled }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();

	const issueConnection = useFragment(
		graphql`
			fragment realtimeUpdater_nativeIssueTable_issues on JiraIssueConnection {
				__id
				jql
				edges {
					__typename
				}
			}
		`,
		issues ?? null,
	);

	const groupConnection = useFragment(
		graphql`
			fragment realtimeUpdater_nativeIssueTable_groups on JiraSpreadsheetGroupConnection {
				__id
				jql
				groupByField
				edges {
					__typename
				}
			}
		`,
		groups ?? null,
	);

	const { projectId, key: projectKey } = useFragment(
		graphql`
			fragment realtimeUpdater_nativeIssueTable_project on JiraProject {
				projectId @required(action: THROW)
				key
			}
		`,
		project,
	);

	const { issueDeleteUpdater } = useIssueRealtimeUpdater();

	const variables = useMemo(
		() => ({
			cloudId,
			projectId,
		}),
		[cloudId, projectId],
	);

	const queueRef = useRef<string[]>([]);

	const realtimeUpdatesForIssues = useRealtimeUpdatesForIssues();
	const [queryIssueDetailsFromQueue] = useDebouncedCallback(async () => {
		const keys = queueRef.current.slice(0, MAX_ALLOWED_QUEUE_SIZE);
		queueRef.current = queueRef.current.slice(MAX_ALLOWED_QUEUE_SIZE);

		realtimeUpdatesForIssues({
			keys,
			isHierarchyEnabled: isHierarchyEnabled ?? false,
			jql: issueConnection?.jql || groupConnection?.jql || '',
			groupByFieldId: groupConnection?.groupByField || undefined,
			groupConnectionId: groupConnection?.__id,
			numGroupsLoaded: groupConnection?.edges?.length ?? 0,
			projectKey,
		});
	}, QUERY_ISSUE_DETAILS_DEBOUNCE_TIME);

	const handleCreateOrUpdateEvent = useCallback(
		(key: string) => {
			queueRef.current.push(key);

			queryIssueDetailsFromQueue();
		},
		[queryIssueDetailsFromQueue],
	);

	const createConfig = useMemo<GraphQLSubscriptionConfig<IssueCreateSubscription>>(
		() => ({
			variables,
			subscription: issueCreatedSubscription,
			onNext: async (response) => {
				try {
					if (response?.jira?.onIssueCreatedByProject) {
						handleCreateOrUpdateEvent(response.jira.onIssueCreatedByProject.key);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: CREATE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'onNext' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: CREATE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		}),
		[handleCreateOrUpdateEvent, variables],
	);

	const updateConfig = useMemo<GraphQLSubscriptionConfig<IssueUpdateSubscription>>(
		() => ({
			variables,
			subscription: issueUpdatedSubscription,
			onNext: async (response) => {
				try {
					if (response?.jira?.onIssueUpdatedByProject) {
						handleCreateOrUpdateEvent(response.jira.onIssueUpdatedByProject.key);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: UPDATE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'onNext' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: UPDATE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		}),
		[handleCreateOrUpdateEvent, variables],
	);

	const deleteConfig = useMemo<GraphQLSubscriptionConfig<IssueDeleteSubscription>>(() => {
		return {
			variables,
			subscription: issueDeletedSubscription,
			updater: (store, response) => {
				try {
					if (response?.jira?.onIssueDeletedByProject?.resource) {
						const wasDeleted = issueDeleteUpdater({
							store,
							issueResourceId: response.jira.onIssueDeletedByProject.resource,
							groupConnectionId: groupConnection?.__id,
							projectKey,
						});

						wasDeleted &&
							throttledFireTrackAnalytics(
								createAnalyticsEvent({}),
								'issueTableRealTimeEvent processed',
								{ [REAL_TIME_EVENT_TYPE_KEY]: 'delete' },
							);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: DELETE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'updater' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: DELETE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		};
	}, [variables, issueDeleteUpdater, groupConnection?.__id, projectKey, createAnalyticsEvent]);

	useSubscription(createConfig);
	useSubscription(updateConfig);
	useSubscription(deleteConfig);

	return null;
};
