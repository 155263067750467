import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createSelector } from '@atlassian/react-sweet-state';
import { IICConfigStore } from './index.tsx';
import type { State, ProjectConfig } from './types.tsx';

const defaultProjectConfigProjectConfig: ProjectConfig = {
	defaultIssueType: null,
};

const selectProjectConfig = createSelector(
	(_, projectKey: ProjectKey | null | undefined) => projectKey,
	({ projectConfigs }: State) => projectConfigs,
	(projectKey, projectConfigs) => {
		if (projectKey == null) {
			return defaultProjectConfigProjectConfig;
		}

		const config = projectConfigs[projectKey];
		if (config == null) {
			return defaultProjectConfigProjectConfig;
		}
		return config;
	},
);

const selectIssueType = createSelector(selectProjectConfig, (config) => config.defaultIssueType);

export const useDefaultIssueType = createHook(IICConfigStore, {
	selector: selectIssueType,
});
