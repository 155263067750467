/**
 * @generated SignedSource<<6dcc61bfdd5c42f253dda5a7711e84ee>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_RowWithSiblingCreate_issue$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_issue">;
  readonly " $fragmentType": "row_nativeIssueTable_RowWithSiblingCreate_issue";
};
export type row_nativeIssueTable_RowWithSiblingCreate_issue$key = {
  readonly " $data"?: row_nativeIssueTable_RowWithSiblingCreate_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowWithSiblingCreate_issue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_RowWithSiblingCreate_issue",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issue"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "ba623de7122344bfc84f16b75828ccda";

export default node;
