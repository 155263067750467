import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expandIssue: {
		id: 'native-issue-table.expand-button.expand-issue-hierarchy',
		defaultMessage: 'Expand hierarchy',
		description: 'Tooltip text to expand items',
	},
	collapseIssue: {
		id: 'native-issue-table.expand-button.collapse',
		defaultMessage: 'Collapse hierarchy',
		description: 'Tooltip text to collapse items',
	},
	expandGroup: {
		id: 'native-issue-table.expand-button.expand-group-issue-hierarchy',
		defaultMessage: 'Expand group',
		description: 'Label text to expand group',
	},
	collapseGroup: {
		id: 'native-issue-table.expand-button.collapse-group',
		defaultMessage: 'Collapse group',
		description: 'Label to collapse group',
	},
});
