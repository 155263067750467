/**
 * @generated SignedSource<<283366ea8bf96885436438c2020b1c67>>
 * @relayHash 1329ef06d6eadca254eb7d70473b060f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b0bd695ba8fc39f33b7415f4f133767d60ee6f29526e9acac6dc24b33d675de3

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type realtimeUpdaterOld_issueDeleted_Subscription$variables = {
  cloudId: string;
  projectId: string;
};
export type realtimeUpdaterOld_issueDeleted_Subscription$data = {
  readonly jira: {
    readonly onIssueDeletedByProject: {
      readonly resource: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type realtimeUpdaterOld_issueDeleted_Subscription = {
  response: realtimeUpdaterOld_issueDeleted_Subscription$data;
  variables: realtimeUpdaterOld_issueDeleted_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "concreteType": "JiraSubscription",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "projectId",
            "variableName": "projectId"
          }
        ],
        "concreteType": "JiraIssueDeletedStreamHubPayload",
        "kind": "LinkedField",
        "name": "onIssueDeletedByProject",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "resource"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "realtimeUpdaterOld_issueDeleted_Subscription",
    "selections": (v1/*: any*/),
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "realtimeUpdaterOld_issueDeleted_Subscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b0bd695ba8fc39f33b7415f4f133767d60ee6f29526e9acac6dc24b33d675de3",
    "metadata": {},
    "name": "realtimeUpdaterOld_issueDeleted_Subscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "06cdd48b917944337297865bde9babd8";

export default node;
