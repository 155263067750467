import React, { memo, useState, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import { SingleLineTextInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-single-line-text-inline-edit-full/src/ui/single-line-text/index.tsx';
import type { plainText_nativeIssueTable_PlainTextCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/plainText_nativeIssueTable_PlainTextCell_fieldRef.graphql';
import type { plainText_nativeIssueTable_PlainTextCellOld_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/plainText_nativeIssueTable_PlainTextCellOld_fieldRef.graphql';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { TruncatedTextWithPopup } from '../../truncated-text-cell-with-popup/index.tsx';
import {
	useIsDensityFull,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { MAX_LINE_WRAP_NEW } from '../../../constants.tsx';

// Attributes for inline editing track events
const attributes = {
	// If the update was made inline (and not via the issue view)
	isInlineEditing: true,
};

export type Props = {
	fieldRef: plainText_nativeIssueTable_PlainTextCell_fieldRef$key;
};

export const PlainTextCell = memo<Props>(({ fieldRef }: Props) => {
	const data = useFragment<plainText_nativeIssueTable_PlainTextCell_fieldRef$key>(
		graphql`
			fragment plainText_nativeIssueTable_PlainTextCell_fieldRef on JiraSingleLineTextField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
	useLazyLoadEditability(data.id, isEditable);

	const isDensityCompact = !useIsDensityFull();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const maxLines = (() => {
		if (isSingleLineRowHeightEnabled) {
			return 1;
		}
		return isDensityCompact ? 2 : undefined;
	})();

	const renderCustomReadView = useCallback(
		(text: string | null) => (text ? <Text maxLines={maxLines}>{text}</Text> : <EmptyFieldText />),
		[maxLines],
	);
	return (
		<SingleLineTextInlineEditViewWithIsEditable
			attributes={attributes}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			maxLength={255}
			readViewFitContainerHeight
			renderCustomReadView={renderCustomReadView}
			hideEditButton
		/>
	);
});

export type PlainTextCellOldProps = {
	fieldRef: plainText_nativeIssueTable_PlainTextCellOld_fieldRef$key;
	/**
	 * Should only be needed For VR testing, to render a storybook example with
	 * the popup content immediately visible
	 */
	shouldOpenOnFirstRender?: boolean;
};

export const PlainTextCellOld = memo<PlainTextCellOldProps>(
	({ fieldRef, shouldOpenOnFirstRender = false }: PlainTextCellOldProps) => {
		const { text } = useFragment<plainText_nativeIssueTable_PlainTextCellOld_fieldRef$key>(
			graphql`
				fragment plainText_nativeIssueTable_PlainTextCellOld_fieldRef on JiraSingleLineTextField {
					text
				}
			`,
			fieldRef,
		);
		const [isOpen, setIsOpen] = useState(shouldOpenOnFirstRender);
		const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
		return (
			<TruncatedTextWithPopup
				isOpen={isOpen}
				onSetIsOpen={setIsOpen}
				// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
				content={<p>{text}</p>}
				triggerText={text ?? null}
				{...(isSingleLineRowHeightEnabled && { maxLength: MAX_LINE_WRAP_NEW })}
			/>
		);
	},
);
