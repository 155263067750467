import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerButton: {
		id: 'native-issue-table.common.issue-cells.issue-links.trigger-button',
		defaultMessage: 'See more',
		description: 'Label for trigger button to open a dialog with a list of more items',
	},
	showMoreLink: {
		id: 'native-issue-table.common.issue-cells.issue-links.show-more-link',
		defaultMessage: 'Go to all linked issues',
		description: 'Label for link that redirects users to a JQL search for all linked issues',
	},
	showMoreLinkIssueTermRefresh: {
		id: 'native-issue-table.common.issue-cells.issue-links.show-more-link-issue-term-refresh',
		defaultMessage: 'Go to all linked work items',
		description: 'Label for link that redirects users to a JQL search for all linked issues',
	},
	retryMessage: {
		id: 'native-issue-table.common.issue-cells.issue-links.retry-message',
		defaultMessage: 'Click to retry',
		description: 'Label for a link button that refreshes when issue links fail to load',
	},
	errorMessage: {
		id: 'native-issue-table.common.issue-cells.issue-links.error-message',
		defaultMessage: 'Failed to load issue links',
		description: 'Message displayed when issue links fail to load',
	},
	errorMessageIssueTermRefresh: {
		id: 'native-issue-table.common.issue-cells.issue-links.error-message-issue-term-refresh',
		defaultMessage: 'Failed to load linked work items',
		description: 'Message displayed when issue links fail to load',
	},
});
