import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/core/migration/error';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { StyledText } from './styled.tsx';

type Props = { onRetry: () => void };
export const ErrorState = ({ onRetry }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<DropdownItemsWrapper>
			<StyledBox height={gridSize * 9} iconSize={gridSize * 9}>
				<ErrorIcon
					spacing="spacious"
					testId="issue-navigator-issue-operations.common.ui.error-state.error-icon"
					color={token('color.icon')}
					label=""
				/>
			</StyledBox>
			<StyledText>{formatMessage(messages.errorMessage)}</StyledText>
			<Button onClick={onRetry} appearance="link">
				{formatMessage(messages.retryMessage)}
			</Button>
		</DropdownItemsWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownItemsWrapper = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: gridSize * 9,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: gridSize * 30,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: gridSize * 30,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: gridSize * 39,
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBox = styled.div<{ height: number; iconSize?: number }>(
	{
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) => `${props.height}px`,
		justifyContent: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ iconSize }) =>
		iconSize
			? `svg {
                   width: ${iconSize}px;
                   height: ${iconSize}px;
               }`
			: '',
);
