/**
 * @generated SignedSource<<28fd7493a16534a6145674865ce2d549>>
 * @relayHash 74077174b6ef18173d4aeb4345058e0e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 33e4e26e706816fd2c3713c8b93b36723f8e3bf439c1a8131e241be986006ef6

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type JiraBulkEditMultiSelectFieldOptions = "ADD" | "REMOVE" | "REMOVE_ALL" | "REPLACE" | "%future added value";
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
export type JiraIssueCreateInput = {
  fields: JiraIssueFieldsInput;
  issueTypeId: string;
  projectId: string;
  rank?: JiraIssueCreateRankInput | null | undefined;
};
export type JiraIssueFieldsInput = {
  affectedServicesField?: JiraAffectedServicesFieldInput | null | undefined;
  assetsField?: JiraAssetFieldInput | null | undefined;
  atlassianTeamFields?: ReadonlyArray<JiraAtlassianTeamFieldInput> | null | undefined;
  cascadingSelectFields?: ReadonlyArray<JiraCascadingSelectFieldInput> | null | undefined;
  clearableNumberFields?: ReadonlyArray<JiraClearableNumberFieldInput> | null | undefined;
  colorFields?: ReadonlyArray<JiraColorFieldInput> | null | undefined;
  datePickerFields?: ReadonlyArray<JiraDateFieldInput> | null | undefined;
  dateTimePickerFields?: ReadonlyArray<JiraDateTimeFieldInput> | null | undefined;
  entitlementField?: JiraEntitlementFieldInput | null | undefined;
  epicLinkField?: JiraEpicLinkFieldInput | null | undefined;
  issueType?: JiraIssueTypeInput | null | undefined;
  labelsFields?: ReadonlyArray<JiraLabelsFieldInput> | null | undefined;
  multipleGroupPickerFields?: ReadonlyArray<JiraMultipleGroupPickerFieldInput> | null | undefined;
  multipleSelectClearableUserPickerFields?: ReadonlyArray<JiraMultipleSelectClearableUserPickerFieldInput> | null | undefined;
  multipleSelectFields?: ReadonlyArray<JiraMultipleSelectFieldInput> | null | undefined;
  multipleSelectUserPickerFields?: ReadonlyArray<JiraMultipleSelectUserPickerFieldInput> | null | undefined;
  multipleVersionPickerFields?: ReadonlyArray<JiraMultipleVersionPickerFieldInput> | null | undefined;
  multiselectComponents?: JiraMultiSelectComponentFieldInput | null | undefined;
  numberFields?: ReadonlyArray<JiraNumberFieldInput> | null | undefined;
  organizationField?: JiraOrganizationFieldInput | null | undefined;
  originalEstimateField?: JiraDurationFieldInput | null | undefined;
  parentField?: JiraParentFieldInput | null | undefined;
  peopleFields?: ReadonlyArray<JiraPeopleFieldInput> | null | undefined;
  priority?: JiraPriorityInput | null | undefined;
  projectFields?: ReadonlyArray<JiraProjectFieldInput> | null | undefined;
  resolution?: JiraResolutionInput | null | undefined;
  richTextFields?: ReadonlyArray<JiraRichTextFieldInput> | null | undefined;
  security?: JiraSecurityLevelInput | null | undefined;
  singleGroupPickerFields?: ReadonlyArray<JiraSingleGroupPickerFieldInput> | null | undefined;
  singleLineTextFields?: ReadonlyArray<JiraSingleLineTextFieldInput> | null | undefined;
  singleOrganizationField?: JiraSingleOrganizationFieldInput | null | undefined;
  singleSelectClearableUserPickerFields?: ReadonlyArray<JiraUserFieldInput> | null | undefined;
  singleSelectFields?: ReadonlyArray<JiraSingleSelectFieldInput> | null | undefined;
  singleSelectUserPickerFields?: ReadonlyArray<JiraSingleSelectUserPickerFieldInput> | null | undefined;
  singleVersionPickerFields?: ReadonlyArray<JiraSingleVersionPickerFieldInput> | null | undefined;
  sprintsField?: JiraSprintFieldInput | null | undefined;
  teamFields?: ReadonlyArray<JiraTeamFieldInput> | null | undefined;
  timeTrackingField?: JiraTimeTrackingFieldInput | null | undefined;
  urlFields?: ReadonlyArray<JiraUrlFieldInput> | null | undefined;
};
export type JiraAffectedServicesFieldInput = {
  affectedServices: ReadonlyArray<JiraAffectedServicesInput>;
  fieldId: string;
};
export type JiraAffectedServicesInput = {
  serviceId: string;
};
export type JiraAssetFieldInput = {
  assets: ReadonlyArray<JiraAssetInput>;
  fieldId: string;
};
export type JiraAssetInput = {
  appKey: string;
  originId: string;
  serializedOrigin: string;
  value: string;
};
export type JiraAtlassianTeamFieldInput = {
  fieldId: string;
  team: JiraAtlassianTeamInput;
};
export type JiraAtlassianTeamInput = {
  teamId: string;
};
export type JiraCascadingSelectFieldInput = {
  childOptionValue?: JiraSelectedOptionInput | null | undefined;
  fieldId: string;
  parentOptionValue: JiraSelectedOptionInput;
};
export type JiraSelectedOptionInput = {
  id?: string | null | undefined;
  optionId?: string | null | undefined;
};
export type JiraClearableNumberFieldInput = {
  fieldId: string;
  value?: number | null | undefined;
};
export type JiraColorFieldInput = {
  color: JiraColorInput;
  fieldId: string;
};
export type JiraColorInput = {
  name: string;
};
export type JiraDateFieldInput = {
  date: JiraDateInput;
  fieldId: string;
};
export type JiraDateInput = {
  formattedDate: string;
};
export type JiraDateTimeFieldInput = {
  dateTime: JiraDateTimeInput;
  fieldId: string;
};
export type JiraDateTimeInput = {
  formattedDateTime: string;
};
export type JiraEntitlementFieldInput = {
  entitlement: JiraEntitlementInput;
  fieldId: string;
};
export type JiraEntitlementInput = {
  entitlementId: string;
};
export type JiraEpicLinkFieldInput = {
  epicLink: JiraEpicLinkInput;
  fieldId: string;
};
export type JiraEpicLinkInput = {
  issueKey: string;
};
export type JiraIssueTypeInput = {
  id?: string | null | undefined;
  issueTypeId: string;
};
export type JiraLabelsFieldInput = {
  bulkEditMultiSelectFieldOption?: JiraBulkEditMultiSelectFieldOptions | null | undefined;
  fieldId: string;
  labels?: ReadonlyArray<JiraLabelsInput> | null | undefined;
};
export type JiraLabelsInput = {
  name?: string | null | undefined;
};
export type JiraMultipleGroupPickerFieldInput = {
  fieldId: string;
  groups: ReadonlyArray<JiraGroupInput>;
};
export type JiraGroupInput = {
  groupName: string;
};
export type JiraMultipleSelectClearableUserPickerFieldInput = {
  fieldId: string;
  users?: ReadonlyArray<JiraUserInput> | null | undefined;
};
export type JiraUserInput = {
  accountId: string;
};
export type JiraMultipleSelectFieldInput = {
  fieldId: string;
  options: ReadonlyArray<JiraSelectedOptionInput>;
};
export type JiraMultipleSelectUserPickerFieldInput = {
  fieldId: string;
  users: ReadonlyArray<JiraUserInput>;
};
export type JiraMultipleVersionPickerFieldInput = {
  bulkEditMultiSelectFieldOption?: JiraBulkEditMultiSelectFieldOptions | null | undefined;
  fieldId: string;
  versions: ReadonlyArray<JiraVersionInput>;
};
export type JiraVersionInput = {
  versionId?: string | null | undefined;
};
export type JiraMultiSelectComponentFieldInput = {
  bulkEditMultiSelectFieldOption?: JiraBulkEditMultiSelectFieldOptions | null | undefined;
  components?: ReadonlyArray<JiraComponentInput> | null | undefined;
  fieldId: string;
};
export type JiraComponentInput = {
  componentId: string;
};
export type JiraNumberFieldInput = {
  fieldId: string;
  value: number;
};
export type JiraOrganizationFieldInput = {
  fieldId: string;
  organizations: ReadonlyArray<JiraOrganizationsInput>;
};
export type JiraOrganizationsInput = {
  organizationId: string;
};
export type JiraDurationFieldInput = {
  originalEstimateField?: string | null | undefined;
};
export type JiraParentFieldInput = {
  issueId: string;
};
export type JiraPeopleFieldInput = {
  fieldId: string;
  users: ReadonlyArray<JiraUserInput>;
};
export type JiraPriorityInput = {
  priorityId: string;
};
export type JiraProjectFieldInput = {
  fieldId: string;
  project: JiraProjectInput;
};
export type JiraProjectInput = {
  id?: string | null | undefined;
  projectId: string;
};
export type JiraResolutionInput = {
  resolutionId: string;
};
export type JiraRichTextFieldInput = {
  fieldId: string;
  richText: JiraRichTextInput;
};
export type JiraRichTextInput = {
  adfValue?: AGG$JSON | null | undefined;
  wikiText?: string | null | undefined;
};
export type JiraSecurityLevelInput = {
  securityLevelId: string;
};
export type JiraSingleGroupPickerFieldInput = {
  fieldId: string;
  group: JiraGroupInput;
};
export type JiraSingleLineTextFieldInput = {
  fieldId: string;
  text?: string | null | undefined;
};
export type JiraSingleOrganizationFieldInput = {
  fieldId: string;
  organization: JiraOrganizationsInput;
};
export type JiraUserFieldInput = {
  fieldId: string;
  user?: JiraUserInput | null | undefined;
};
export type JiraSingleSelectFieldInput = {
  fieldId: string;
  option: JiraSelectedOptionInput;
};
export type JiraSingleSelectUserPickerFieldInput = {
  fieldId: string;
  user: JiraUserInput;
};
export type JiraSingleVersionPickerFieldInput = {
  fieldId: string;
  version: JiraVersionInput;
};
export type JiraSprintFieldInput = {
  fieldId: string;
  sprints: ReadonlyArray<JiraSprintInput>;
};
export type JiraSprintInput = {
  sprintId: string;
};
export type JiraTeamFieldInput = {
  fieldId: string;
  team: JiraTeamInput;
};
export type JiraTeamInput = {
  teamId: string;
};
export type JiraTimeTrackingFieldInput = {
  originalEstimate?: string | null | undefined;
  timeRemaining?: string | null | undefined;
};
export type JiraUrlFieldInput = {
  fieldId: string;
  url: string;
};
export type JiraIssueCreateRankInput = {
  afterIssueId?: string | null | undefined;
  beforeIssueId?: string | null | undefined;
};
export type issueCreateMutation_useIssueCreateMutation$variables = {
  fieldSetIds: ReadonlyArray<string>;
  isDensityFull: boolean;
  isInlineEditingEnabled: boolean;
  isIssueHierarchyEnabled: boolean;
  issueCreateInput: JiraIssueCreateInput;
  projectKey?: string | null | undefined;
};
export type issueCreateMutation_useIssueCreateMutation$data = {
  readonly jira: {
    readonly createIssue: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
      }> | null | undefined;
      readonly issue: {
        readonly $updatableFragmentSpreads: FragmentRefs<"highlightIssueRow_update_isHighlightedIssueRow">;
        readonly __id: string;
        readonly canHaveChildIssues?: boolean | null | undefined;
        readonly fieldSetsById: {
          readonly __id: string;
          readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
        } | null | undefined;
        readonly id: string;
        readonly issueId: string;
        readonly issueTypeField?: {
          readonly id: string;
          readonly issueType: {
            readonly hierarchy: {
              readonly level: number | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly key: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type issueCreateMutation_useIssueCreateMutation$rawResponse = {
  readonly jira: {
    readonly createIssue: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly __typename: "BulkMutationErrorExtension";
          readonly id: string;
          readonly statusCode: number | null | undefined;
        } | {
          readonly __typename: string;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
      }> | null | undefined;
      readonly issue: {
        readonly __typename: "JiraIssue";
        readonly __id?: string;
        readonly canHaveChildIssues: boolean | null | undefined;
        readonly fieldSetsById: {
          readonly __id?: string;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fieldSetId: string | null | undefined;
              readonly fields: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraAffectedServicesField";
                    readonly __isJiraIssueField: "JiraAffectedServicesField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly selectedAffectedServicesConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly name: string | null | undefined;
                          readonly serviceId: string;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraCMDBField";
                    readonly __isJiraIssueField: "JiraCMDBField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly selectedCmdbObjectsConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly avatar: {
                            readonly mediaClientConfig: {
                              readonly clientId: string | null | undefined;
                              readonly fileId: string | null | undefined;
                              readonly mediaBaseUrl: AGG$URL | null | undefined;
                              readonly mediaJwtToken: string | null | undefined;
                            } | null | undefined;
                            readonly url48: string | null | undefined;
                          } | null | undefined;
                          readonly label: string | null | undefined;
                          readonly objectId: string | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                      readonly errors: ReadonlyArray<{
                        readonly message: string | null | undefined;
                      }> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraCascadingSelectField";
                    readonly __isJiraIssueField: "JiraCascadingSelectField";
                    readonly cascadingOption: {
                      readonly childOptionValue: {
                        readonly id: string;
                        readonly isDisabled: boolean | null | undefined;
                        readonly value: string | null | undefined;
                      } | null | undefined;
                      readonly parentOptionValue: {
                        readonly id: string;
                        readonly isDisabled: boolean | null | undefined;
                        readonly value: string | null | undefined;
                      } | null | undefined;
                    } | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraCheckboxesField";
                    readonly __isJiraIssueField: "JiraCheckboxesField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedOptions: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly value: string | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraCommentSummaryField";
                    readonly __isJiraIssueField: "JiraCommentSummaryField";
                    readonly commentSummary: {
                      readonly canAddComment: boolean | null | undefined;
                      readonly totalCount: number | null | undefined;
                    } | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraComponentsField";
                    readonly __isJiraIssueField: "JiraComponentsField";
                    readonly componentsOld: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly name: string | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedComponentsConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly label: string | null | undefined;
                          readonly value: string;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraDatePickerField";
                    readonly __isJiraIssueField: "JiraDatePickerField";
                    readonly date: AGG$Date | null | undefined;
                    readonly fieldConfig: {
                      readonly isRequired: boolean | null | undefined;
                    } | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraDateTimePickerField";
                    readonly __isJiraIssueField: "JiraDateTimePickerField";
                    readonly dateTime: AGG$DateTime | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraEpicLinkField";
                    readonly __isJiraIssueField: "JiraEpicLinkField";
                    readonly epic: {
                      readonly color: string | null | undefined;
                      readonly id: string;
                      readonly key: string | null | undefined;
                      readonly summary: string | null | undefined;
                    } | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraFallbackField";
                    readonly __isJiraIssueField: "JiraFallbackField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly renderedFieldHtml: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraIssueLinkField";
                    readonly __isJiraIssueField: "JiraIssueLinkField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly issueLinkConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string | null | undefined;
                          readonly issue: {
                            readonly fieldsById: {
                              readonly edges: ReadonlyArray<{
                                readonly node: {
                                  readonly __typename: "JiraStatusCategoryField";
                                  readonly id: string;
                                  readonly statusCategory: {
                                    readonly id: string;
                                    readonly statusCategoryId: string;
                                  };
                                } | {
                                  readonly __typename: string;
                                  readonly id: string;
                                } | null | undefined;
                              } | null | undefined> | null | undefined;
                            } | null | undefined;
                            readonly id: string;
                            readonly key: string;
                            readonly webUrl: AGG$URL | null | undefined;
                          } | null | undefined;
                          readonly relationName: string | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                      readonly totalCount: number | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraIssueTypeField";
                    readonly __isJiraIssueField: "JiraIssueTypeField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly small: string | null | undefined;
                      } | null | undefined;
                      readonly id: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraLabelsField";
                    readonly __isJiraIssueField: "JiraLabelsField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedLabelsConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly name: string | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                      readonly totalCount: number | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraMultipleSelectField";
                    readonly __isJiraIssueField: "JiraMultipleSelectField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedOptions: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly optionId: string;
                          readonly value: string | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraMultipleSelectUserPickerField";
                    readonly __isJiraIssueField: "JiraMultipleSelectUserPickerField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedUsersConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly __typename: "AtlassianAccountUser";
                          readonly accountId: string;
                          readonly accountStatus: AccountStatus;
                          readonly email: string | null | undefined;
                          readonly id: string;
                          readonly name: string;
                          readonly picture: AGG$URL;
                        } | {
                          readonly __typename: string;
                          readonly accountId: string;
                          readonly accountStatus: AccountStatus;
                          readonly id: string;
                          readonly name: string;
                          readonly picture: AGG$URL;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraMultipleVersionPickerField";
                    readonly __isJiraIssueField: "JiraMultipleVersionPickerField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedVersionsConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly label: string | null | undefined;
                          readonly name: string | null | undefined;
                          readonly value: string;
                          readonly versionId: string;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraNumberField";
                    readonly __isJiraIssueField: "JiraNumberField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly isStoryPointField: boolean | null | undefined;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly number: number | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraOriginalTimeEstimateField";
                    readonly __isJiraIssueField: "JiraOriginalTimeEstimateField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly issue: {
                      readonly id: string;
                      readonly timeTrackingField: {
                        readonly fieldId: string;
                        readonly id: string;
                        readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                        readonly name: string;
                        readonly originalEstimate: {
                          readonly timeInSeconds: AGG$Long | null | undefined;
                        } | null | undefined;
                        readonly timeTrackingSettings: {
                          readonly defaultFormat: JiraTimeFormat | null | undefined;
                          readonly defaultUnit: JiraTimeUnit | null | undefined;
                          readonly workingDaysPerWeek: number | null | undefined;
                          readonly workingHoursPerDay: number | null | undefined;
                        } | null | undefined;
                        readonly type: string;
                      } | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraParentIssueField";
                    readonly __isJiraIssueField: "JiraParentIssueField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly parentIssue: {
                      readonly fieldsById?: {
                        readonly edges: ReadonlyArray<{
                          readonly node: {
                            readonly __typename: "JiraIssueTypeField";
                            readonly fieldId: string;
                            readonly id: string;
                            readonly issueType: {
                              readonly avatar: {
                                readonly xsmall: string | null | undefined;
                              } | null | undefined;
                              readonly hierarchy: {
                                readonly level: number | null | undefined;
                              } | null | undefined;
                              readonly id: string;
                              readonly issueTypeId: string | null | undefined;
                              readonly name: string;
                            } | null | undefined;
                          } | {
                            readonly __typename: "JiraSingleLineTextField";
                            readonly fieldId: string;
                            readonly id: string;
                            readonly text: string | null | undefined;
                          } | {
                            readonly __typename: "JiraStatusField";
                            readonly fieldId: string;
                            readonly id: string;
                            readonly status: {
                              readonly id: string;
                              readonly statusCategory: {
                                readonly id: string;
                                readonly statusCategoryId: string;
                              } | null | undefined;
                            };
                          } | {
                            readonly __typename: string;
                            readonly id: string;
                          } | null | undefined;
                        } | null | undefined> | null | undefined;
                      } | null | undefined;
                      readonly id: string;
                      readonly issueColorField: {
                        readonly color: {
                          readonly colorKey: string | null | undefined;
                          readonly id: string | null | undefined;
                        } | null | undefined;
                        readonly id: string;
                      } | null | undefined;
                      readonly issueId?: string;
                      readonly issueTypeField: {
                        readonly id: string;
                        readonly issueType: {
                          readonly avatar: {
                            readonly xsmall: string | null | undefined;
                          } | null | undefined;
                          readonly id: string;
                          readonly name: string;
                        } | null | undefined;
                      } | null | undefined;
                      readonly key: string;
                      readonly summaryField: {
                        readonly id: string;
                        readonly text: string | null | undefined;
                      } | null | undefined;
                      readonly webUrl?: AGG$URL | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraPriorityField";
                    readonly __isJiraIssueField: "JiraPriorityField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly priority: {
                      readonly iconUrl: AGG$URL | null | undefined;
                      readonly id: string;
                      readonly name: string | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraProjectField";
                    readonly __isJiraIssueField: "JiraProjectField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly project: {
                      readonly avatar: {
                        readonly large: string | null | undefined;
                      } | null | undefined;
                      readonly id: string;
                      readonly key: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraRadioSelectField";
                    readonly __isJiraIssueField: "JiraRadioSelectField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedOption: {
                      readonly id: string;
                      readonly label: string | null | undefined;
                      readonly value: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraResolutionField";
                    readonly __isJiraIssueField: "JiraResolutionField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly resolution: {
                      readonly id: string;
                      readonly name: string | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraRichTextField";
                    readonly __isJiraIssueField: "JiraRichTextField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly richText: {
                      readonly adfValue: {
                        readonly convertedPlainText: {
                          readonly plainText: string | null | undefined;
                        } | null | undefined;
                        readonly json: AGG$JSON | null | undefined;
                      } | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraServiceManagementRequestTypeField";
                    readonly __isJiraIssueField: "JiraServiceManagementRequestTypeField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly requestType: {
                      readonly avatar: {
                        readonly xsmall: string | null | undefined;
                      } | null | undefined;
                      readonly id: string;
                      readonly name: string | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly __isJiraIssueField: "JiraSingleLineTextField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleSelectField";
                    readonly __isJiraIssueField: "JiraSingleSelectField";
                    readonly fieldId: string;
                    readonly fieldOption: {
                      readonly color: {
                        readonly colorKey: string | null | undefined;
                        readonly id: string | null | undefined;
                      } | null | undefined;
                      readonly id: string;
                      readonly optionId: string;
                      readonly value: string | null | undefined;
                    } | null | undefined;
                    readonly fieldOptions: {
                      readonly totalCount: number | null | undefined;
                    } | null | undefined;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleSelectUserPickerField";
                    readonly __isJiraIssueField: "JiraSingleSelectUserPickerField";
                    readonly fieldConfig: {
                      readonly isRequired: boolean | null | undefined;
                    } | null | undefined;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly type: string;
                    readonly user: {
                      readonly __typename: string;
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly id: string;
                      readonly name: string;
                      readonly picture: AGG$URL;
                    } | null | undefined;
                  } | {
                    readonly __typename: "JiraSprintField";
                    readonly __isJiraIssueField: "JiraSprintField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedSprintsConnection: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly endDate: AGG$DateTime | null | undefined;
                          readonly id: string;
                          readonly name: string | null | undefined;
                          readonly sprintId: string;
                          readonly state: JiraSprintState | null | undefined;
                        } | null | undefined;
                      } | null | undefined> | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraStatusCategoryField";
                    readonly __isJiraIssueField: "JiraStatusCategoryField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly statusCategory: {
                      readonly id: string;
                      readonly name: string | null | undefined;
                      readonly statusCategoryId: string;
                    };
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraStatusField";
                    readonly __isJiraIssueField: "JiraStatusField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly status: {
                      readonly id: string;
                      readonly name: string | null | undefined;
                      readonly statusCategory: {
                        readonly id: string;
                        readonly statusCategoryId: string;
                      } | null | undefined;
                      readonly statusId: string;
                    };
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraTeamViewField";
                    readonly __isJiraIssueField: "JiraTeamViewField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly selectedTeam: {
                      readonly jiraSuppliedId: string;
                      readonly jiraSuppliedName: string | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraUrlField";
                    readonly __isJiraIssueField: "JiraUrlField";
                    readonly fieldId: string;
                    readonly id: string;
                    readonly lazyIsEditableInIssueView?: boolean | null | undefined;
                    readonly name: string;
                    readonly type: string;
                    readonly uri: string | null | undefined;
                  } | {
                    readonly __typename: string;
                    readonly __isJiraIssueField: string;
                    readonly fieldId: string;
                    readonly id: string;
                    readonly type: string;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly type: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly issueId: string;
        readonly issueTypeField: {
          readonly id: string;
          readonly issueType: {
            readonly hierarchy: {
              readonly level: number | null | undefined;
            } | null | undefined;
            readonly id: string;
          } | null | undefined;
        } | null | undefined;
        readonly key: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type issueCreateMutation_useIssueCreateMutation = {
  rawResponse: issueCreateMutation_useIssueCreateMutation$rawResponse;
  response: issueCreateMutation_useIssueCreateMutation$data;
  variables: issueCreateMutation_useIssueCreateMutation$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDensityFull"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isInlineEditingEnabled"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isIssueHierarchyEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueCreateInput"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v6 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "issueCreateInput"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "key"
},
v10 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v11 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v12 = {
  "args": [
    {
      "kind": "Variable",
      "name": "projectKey",
      "variableName": "projectKey"
    }
  ],
  "kind": "ScalarField",
  "name": "canHaveChildIssues"
},
v13 = [
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v14 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v16 = {
  "kind": "ScalarField",
  "name": "type"
},
v17 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v18 = {
  "kind": "ScalarField",
  "name": "name"
},
v19 = {
  "kind": "ScalarField",
  "name": "value"
},
v20 = [
  (v19/*: any*/),
  (v8/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v21 = {
  "condition": "isInlineEditingEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "lazyIsEditableInIssueView"
        }
      ]
    }
  ]
},
v22 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v23 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v24 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v25 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v26 = {
  "kind": "ScalarField",
  "name": "text"
},
v27 = {
  "kind": "InlineFragment",
  "selections": [
    (v26/*: any*/),
    (v18/*: any*/),
    (v21/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v28 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v29 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v8/*: any*/)
  ]
},
v30 = [
  (v29/*: any*/)
],
v31 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v32 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v33 = {
  "kind": "ScalarField",
  "name": "picture"
},
v34 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v35 = [
  (v18/*: any*/),
  (v8/*: any*/)
],
v36 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v37 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v38 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v37/*: any*/),
    (v8/*: any*/)
  ]
},
v39 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v26/*: any*/),
    (v8/*: any*/)
  ]
},
v40 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v29/*: any*/),
    (v8/*: any*/)
  ]
},
v41 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v42 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v41/*: any*/),
        (v18/*: any*/),
        (v8/*: any*/)
      ]
    },
    (v8/*: any*/)
  ]
},
v43 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v44 = {
  "kind": "ScalarField",
  "name": "number"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueCreateMutation_useIssueCreateMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssueCreatePayload",
            "kind": "LinkedField",
            "name": "createIssue",
            "plural": false,
            "selections": [
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "highlightIssueRow_update_isHighlightedIssueRow"
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "condition": "isIssueHierarchyEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueTypeField",
                        "kind": "LinkedField",
                        "name": "issueTypeField",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  {
                    "args": (v13/*: any*/),
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsById",
                    "plural": false,
                    "selections": [
                      {
                        "args": [
                          {
                            "kind": "Variable",
                            "name": "isDensityFull",
                            "variableName": "isDensityFull"
                          },
                          {
                            "kind": "Variable",
                            "name": "isInlineEditingEnabled",
                            "variableName": "isInlineEditingEnabled"
                          }
                        ],
                        "kind": "FragmentSpread",
                        "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets"
                      },
                      (v14/*: any*/)
                    ]
                  },
                  (v14/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "issueCreateMutation_useIssueCreateMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssueCreatePayload",
            "kind": "LinkedField",
            "name": "createIssue",
            "plural": false,
            "selections": [
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "args": (v13/*: any*/),
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsById",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldSetEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "fieldSetId"
                              },
                              {
                                "concreteType": "JiraIssueFieldConnection",
                                "kind": "LinkedField",
                                "name": "fields",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v15/*: any*/),
                                          (v17/*: any*/),
                                          (v16/*: any*/),
                                          {
                                            "kind": "TypeDiscriminator",
                                            "abstractKey": "__isJiraIssueField"
                                          },
                                          (v8/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraAffectedServiceConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedAffectedServicesConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraAffectedServiceEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAffectedService",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "serviceId"
                                                          },
                                                          (v18/*: any*/),
                                                          (v8/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraAffectedServicesField"
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraCascadingOption",
                                                    "kind": "LinkedField",
                                                    "name": "cascadingOption",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOption",
                                                        "kind": "LinkedField",
                                                        "name": "parentOptionValue",
                                                        "plural": false,
                                                        "selections": (v20/*: any*/)
                                                      },
                                                      {
                                                        "concreteType": "JiraOption",
                                                        "kind": "LinkedField",
                                                        "name": "childOptionValue",
                                                        "plural": false,
                                                        "selections": (v20/*: any*/)
                                                      }
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraCascadingSelectField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "args": (v22/*: any*/),
                                                    "concreteType": "JiraComponentConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedComponentsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponentEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraComponent",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v23/*: any*/),
                                                              (v24/*: any*/),
                                                              (v8/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    "storageKey": "selectedComponentsConnection(first:1000)"
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraComponentsField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v18/*: any*/),
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraDateTimePickerField"
                                              },
                                              (v27/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCommentSummary",
                                                    "kind": "LinkedField",
                                                    "name": "commentSummary",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "canAddComment"
                                                      },
                                                      (v28/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraCommentSummaryField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "uri"
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraUrlField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraOptionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldOptions",
                                                    "plural": false,
                                                    "selections": [
                                                      (v28/*: any*/)
                                                    ]
                                                  },
                                                  {
                                                    "concreteType": "JiraOption",
                                                    "kind": "LinkedField",
                                                    "name": "fieldOption",
                                                    "plural": false,
                                                    "selections": [
                                                      (v19/*: any*/),
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": (v30/*: any*/)
                                                      },
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": (v30/*: any*/)
                                                      },
                                                      (v8/*: any*/),
                                                      (v31/*: any*/)
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraSingleSelectField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraOptionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedOptions",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOptionEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraOption",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v8/*: any*/),
                                                              (v19/*: any*/),
                                                              (v31/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraMultipleSelectField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraOptionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedOptions",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOptionEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraOption",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v8/*: any*/),
                                                              (v19/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraCheckboxesField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraUserConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedUsersConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraUserEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v15/*: any*/),
                                                              (v32/*: any*/),
                                                              (v18/*: any*/),
                                                              (v33/*: any*/),
                                                              (v8/*: any*/),
                                                              (v34/*: any*/),
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "email"
                                                                  }
                                                                ],
                                                                "type": "AtlassianAccountUser"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraMultipleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraTeamView",
                                                    "kind": "LinkedField",
                                                    "name": "selectedTeam",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "jiraSuppliedId"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "jiraSuppliedName"
                                                      }
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraTeamViewField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssue",
                                                    "kind": "LinkedField",
                                                    "name": "issue",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraTimeTrackingField",
                                                        "kind": "LinkedField",
                                                        "name": "timeTrackingField",
                                                        "plural": false,
                                                        "selections": [
                                                          (v8/*: any*/),
                                                          (v21/*: any*/),
                                                          {
                                                            "concreteType": "JiraEstimate",
                                                            "kind": "LinkedField",
                                                            "name": "originalEstimate",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "timeInSeconds"
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            "concreteType": "JiraTimeTrackingSettings",
                                                            "kind": "LinkedField",
                                                            "name": "timeTrackingSettings",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "workingHoursPerDay"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "workingDaysPerWeek"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "defaultFormat"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "defaultUnit"
                                                              }
                                                            ]
                                                          },
                                                          (v17/*: any*/),
                                                          (v18/*: any*/),
                                                          (v16/*: any*/)
                                                        ]
                                                      },
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraOriginalTimeEstimateField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "concreteType": "JiraOption",
                                                    "kind": "LinkedField",
                                                    "name": "selectedOption",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "alias": "label",
                                                        "kind": "ScalarField",
                                                        "name": "value"
                                                      },
                                                      (v8/*: any*/),
                                                      (v23/*: any*/)
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraRadioSelectField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "args": (v22/*: any*/),
                                                    "concreteType": "JiraVersionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedVersionsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraVersionEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraVersion",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v8/*: any*/),
                                                              (v18/*: any*/),
                                                              (v23/*: any*/),
                                                              (v24/*: any*/),
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "versionId"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    "storageKey": "selectedVersionsConnection(first:1000)"
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraMultipleVersionPickerField"
                                              }
                                            ]
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbObjectConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedCmdbObjectsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbObjectEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraCmdbObject",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "objectId"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "label"
                                                          },
                                                          {
                                                            "concreteType": "JiraCmdbAvatar",
                                                            "kind": "LinkedField",
                                                            "name": "avatar",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraCmdbMediaClientConfig",
                                                                "kind": "LinkedField",
                                                                "name": "mediaClientConfig",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "clientId"
                                                                  },
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "fileId"
                                                                  },
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaBaseUrl"
                                                                  },
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "mediaJwtToken"
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "url48"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  {
                                                    "concreteType": "QueryError",
                                                    "kind": "LinkedField",
                                                    "name": "errors",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "message"
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraCMDBField"
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": false,
                                            "selections": [
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "alias": "componentsOld",
                                                    "concreteType": "JiraComponentConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedComponentsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponentEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraComponent",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": (v35/*: any*/)
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraComponentsField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v26/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              }
                                            ]
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "date"
                                              },
                                              (v18/*: any*/),
                                              (v36/*: any*/),
                                              (v21/*: any*/)
                                            ],
                                            "type": "JiraDatePickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v25/*: any*/)
                                            ],
                                            "type": "JiraDateTimePickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraEpic",
                                                "kind": "LinkedField",
                                                "name": "epic",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "summary"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "color"
                                                  },
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraEpicLinkField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "renderedFieldHtml"
                                              }
                                            ],
                                            "type": "JiraFallbackField"
                                          },
                                          (v27/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraStatusCategory",
                                                "kind": "LinkedField",
                                                "name": "statusCategory",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  (v37/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraStatusCategoryField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraStatus",
                                                "kind": "LinkedField",
                                                "name": "status",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "statusId"
                                                  },
                                                  (v38/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraStatusField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "args": (v22/*: any*/),
                                                "concreteType": "JiraLabelConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedLabelsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraLabelEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraLabel",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v18/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v28/*: any*/)
                                                ],
                                                "storageKey": "selectedLabelsConnection(first:1000)"
                                              },
                                              (v18/*: any*/),
                                              (v21/*: any*/)
                                            ],
                                            "type": "JiraLabelsField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueType",
                                                "kind": "LinkedField",
                                                "name": "issueType",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraAvatar",
                                                    "kind": "LinkedField",
                                                    "name": "avatar",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "small"
                                                      }
                                                    ]
                                                  },
                                                  (v18/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraIssueTypeField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "parentIssue",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  (v39/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssue",
                                                    "kind": "LinkedField",
                                                    "name": "parentIssue",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      (v40/*: any*/),
                                                      (v42/*: any*/),
                                                      (v39/*: any*/),
                                                      (v8/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssue",
                                                    "kind": "LinkedField",
                                                    "name": "parentIssue",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      (v40/*: any*/),
                                                      (v42/*: any*/),
                                                      (v39/*: any*/),
                                                      (v8/*: any*/),
                                                      (v10/*: any*/),
                                                      (v43/*: any*/),
                                                      {
                                                        "args": [
                                                          {
                                                            "kind": "Literal",
                                                            "name": "ids",
                                                            "value": [
                                                              "summary",
                                                              "issuetype",
                                                              "status"
                                                            ]
                                                          }
                                                        ],
                                                        "concreteType": "JiraIssueFieldConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldsById",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraIssueFieldEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                              {
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v15/*: any*/),
                                                                  {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                      (v17/*: any*/),
                                                                      (v26/*: any*/)
                                                                    ],
                                                                    "type": "JiraSingleLineTextField"
                                                                  },
                                                                  {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                      (v17/*: any*/),
                                                                      {
                                                                        "concreteType": "JiraIssueType",
                                                                        "kind": "LinkedField",
                                                                        "name": "issueType",
                                                                        "plural": false,
                                                                        "selections": [
                                                                          {
                                                                            "kind": "ScalarField",
                                                                            "name": "issueTypeId"
                                                                          },
                                                                          (v18/*: any*/),
                                                                          (v41/*: any*/),
                                                                          (v11/*: any*/),
                                                                          (v8/*: any*/)
                                                                        ]
                                                                      }
                                                                    ],
                                                                    "type": "JiraIssueTypeField"
                                                                  },
                                                                  {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                      (v17/*: any*/),
                                                                      {
                                                                        "concreteType": "JiraStatus",
                                                                        "kind": "LinkedField",
                                                                        "name": "status",
                                                                        "plural": false,
                                                                        "selections": [
                                                                          (v38/*: any*/),
                                                                          (v8/*: any*/)
                                                                        ]
                                                                      }
                                                                    ],
                                                                    "type": "JiraStatusField"
                                                                  },
                                                                  (v8/*: any*/)
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ],
                                                        "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                                                      }
                                                    ]
                                                  },
                                                  (v18/*: any*/),
                                                  (v21/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraParentIssueField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraPriority",
                                                "kind": "LinkedField",
                                                "name": "priority",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "iconUrl"
                                                  },
                                                  (v8/*: any*/)
                                                ]
                                              },
                                              (v18/*: any*/),
                                              (v21/*: any*/)
                                            ],
                                            "type": "JiraPriorityField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraProject",
                                                "kind": "LinkedField",
                                                "name": "project",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  (v9/*: any*/),
                                                  {
                                                    "concreteType": "JiraAvatar",
                                                    "kind": "LinkedField",
                                                    "name": "avatar",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "large"
                                                      }
                                                    ]
                                                  },
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraProjectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraResolution",
                                                "kind": "LinkedField",
                                                "name": "resolution",
                                                "plural": false,
                                                "selections": (v35/*: any*/)
                                              }
                                            ],
                                            "type": "JiraResolutionField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraSprintConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedSprintsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraSprintEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraSprint",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v8/*: any*/),
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "sprintId"
                                                          },
                                                          (v18/*: any*/),
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "state"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "endDate"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v18/*: any*/),
                                              (v21/*: any*/)
                                            ],
                                            "type": "JiraSprintField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v15/*: any*/),
                                                  (v32/*: any*/),
                                                  (v33/*: any*/),
                                                  (v18/*: any*/),
                                                  (v8/*: any*/),
                                                  (v34/*: any*/)
                                                ]
                                              },
                                              (v18/*: any*/),
                                              (v36/*: any*/),
                                              (v21/*: any*/)
                                            ],
                                            "type": "JiraSingleSelectUserPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v18/*: any*/),
                                              {
                                                "args": (v22/*: any*/),
                                                "concreteType": "JiraVersionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedVersionsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraVersionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraVersion",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": (v35/*: any*/)
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "selectedVersionsConnection(first:1000)"
                                              }
                                            ],
                                            "type": "JiraMultipleVersionPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraRichText",
                                                "kind": "LinkedField",
                                                "name": "richText",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraADF",
                                                    "kind": "LinkedField",
                                                    "name": "adfValue",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "condition": "isDensityFull",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraAdfToConvertedPlainText",
                                                            "kind": "LinkedField",
                                                            "name": "convertedPlainText",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "plainText"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "condition": "isDensityFull",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "json"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraRichTextField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraServiceManagementRequestType",
                                                "kind": "LinkedField",
                                                "name": "requestType",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  (v41/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraServiceManagementRequestTypeField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "args": [
                                                  {
                                                    "kind": "Literal",
                                                    "name": "first",
                                                    "value": 1
                                                  }
                                                ],
                                                "concreteType": "JiraIssueLinkConnection",
                                                "kind": "LinkedField",
                                                "name": "issueLinkConnection",
                                                "plural": false,
                                                "selections": [
                                                  (v28/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueLinkEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssueLink",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraIssue",
                                                            "kind": "LinkedField",
                                                            "name": "issue",
                                                            "plural": false,
                                                            "selections": [
                                                              (v43/*: any*/),
                                                              (v9/*: any*/),
                                                              {
                                                                "args": [
                                                                  {
                                                                    "kind": "Literal",
                                                                    "name": "ids",
                                                                    "value": [
                                                                      "statusCategory"
                                                                    ]
                                                                  }
                                                                ],
                                                                "concreteType": "JiraIssueFieldConnection",
                                                                "kind": "LinkedField",
                                                                "name": "fieldsById",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "concreteType": "JiraIssueFieldEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                      {
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                          (v15/*: any*/),
                                                                          {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                              (v38/*: any*/)
                                                                            ],
                                                                            "type": "JiraStatusCategoryField"
                                                                          },
                                                                          (v8/*: any*/)
                                                                        ]
                                                                      }
                                                                    ]
                                                                  }
                                                                ],
                                                                "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                              },
                                                              (v8/*: any*/)
                                                            ]
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "relationName"
                                                          },
                                                          (v8/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "issueLinkConnection(first:1)"
                                              }
                                            ],
                                            "type": "JiraIssueLinkField"
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "isStoryPointField"
                                                  },
                                                  (v44/*: any*/),
                                                  (v18/*: any*/),
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": false,
                                                    "selections": [
                                                      (v44/*: any*/)
                                                    ]
                                                  },
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                      (v44/*: any*/),
                                                      (v18/*: any*/),
                                                      (v21/*: any*/)
                                                    ]
                                                  },
                                                  (v21/*: any*/)
                                                ],
                                                "type": "JiraNumberField"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v14/*: any*/)
                    ]
                  },
                  {
                    "condition": "isIssueHierarchyEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueTypeField",
                        "kind": "LinkedField",
                        "name": "issueTypeField",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v14/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "33e4e26e706816fd2c3713c8b93b36723f8e3bf439c1a8131e241be986006ef6",
    "metadata": {},
    "name": "issueCreateMutation_useIssueCreateMutation",
    "operationKind": "mutation",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "71dfbf1afff2cf915246d3fc638183ba";

export default node;
