/**
 * @generated SignedSource<<8218f47ddc91c3359aa0c4b7cefdd7c9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueSummary_nativeIssueTable_IssueSummaryCell_issueRef$data = {
  readonly __id: string;
  readonly key: string;
  readonly " $fragmentType": "issueSummary_nativeIssueTable_IssueSummaryCell_issueRef";
};
export type issueSummary_nativeIssueTable_IssueSummaryCell_issueRef$key = {
  readonly " $data"?: issueSummary_nativeIssueTable_IssueSummaryCell_issueRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueSummary_nativeIssueTable_IssueSummaryCell_issueRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueSummary_nativeIssueTable_IssueSummaryCell_issueRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "dcb0d0c1f8d780927dbe796b45e5676f";

export default node;
