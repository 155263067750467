import { useEffect, useRef } from 'react';
import { commitLocalUpdate, useRelayEnvironment } from 'react-relay';
import RelayDataID from '@atlassian/relay-data-id';
import type { ConnectionMetadata } from '../../../controllers/connections-list/index.tsx';

export const useUpdateHierarchyHasChildrenOnExpand = ({
	connectionMetadata,
	projectKey,
	childCount,
}: {
	connectionMetadata: ConnectionMetadata | undefined;
	childCount: number;
	projectKey?: string;
}) => {
	const environment = useRelayEnvironment();

	useEffect(() => {
		if (connectionMetadata?.type === 'PARENT_CHILDREN' && childCount === 0) {
			commitLocalUpdate(environment, (store) => {
				const parentIssueRecord = store.get(connectionMetadata.parentRelayId);
				const updateArgs = projectKey ? { filterByProjectKeys: [projectKey] } : undefined;
				if (parentIssueRecord && parentIssueRecord.getValue('hasChildren', updateArgs) === true) {
					parentIssueRecord.setValue(false, 'hasChildren', updateArgs);
				}
			});
		}
	}, [childCount, connectionMetadata, environment, projectKey]);
};

export const useUpdateGroupIssueCountOnExpand = ({
	groupId,
	isGroupChildren,
	totalIssueSearchResultCount,
	isCappingIssueSearchResult,
}: {
	groupId: string | undefined;
	isGroupChildren: boolean;
	totalIssueSearchResultCount: number;
	isCappingIssueSearchResult: boolean;
}) => {
	const environment = useRelayEnvironment();
	// we need to update the group only once when the component mounts which happens on first expand
	// Other changes to issue count will be handled by realtime connection updates
	// also, we don't need to update the "firstGroup" field in the group record as it's expanded on default and realtime event always updates it
	const hasIssueCountUpdated = useRef(false);

	useEffect(() => {
		if (!hasIssueCountUpdated.current && groupId && isGroupChildren) {
			commitLocalUpdate(environment, (store) => {
				const relayId = RelayDataID({ id: groupId }, 'JiraSpreadsheetGroup');

				if (!relayId) {
					return;
				}

				const groupRecord = store.get(relayId);
				if (
					groupRecord?.getValue('issueCount') !== totalIssueSearchResultCount &&
					!isCappingIssueSearchResult
				) {
					groupRecord?.setValue(totalIssueSearchResultCount, 'issueCount');
				}
			});
		}
		hasIssueCountUpdated.current = true;
	}, [
		environment,
		groupId,
		isCappingIssueSearchResult,
		isGroupChildren,
		totalIssueSearchResultCount,
	]);
};
