import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	comments: {
		id: 'business-list.list-view.comments-cell.comments',
		defaultMessage: 'Comments',
		description: 'Text to show inside a list cell of type "Comment"',
	},
	commentsCount: {
		id: 'business-list.list-view.comments-cell.comments-count',
		defaultMessage: '{commentCount, plural, one {# comment} other {# comments}}',
		description: 'Comment count to show inside a list cell of type "Comment"',
	},
	moreComments: {
		id: 'business-list.list-view.comments-cell.more-comments',
		defaultMessage: '99+ comments',
		description: 'Shown when comment count exceeds 99',
	},
	addComment: {
		id: 'business-list.list-view.comments-cell.add-comment',
		defaultMessage: 'Add comment',
		description: 'Text to show inside a list cell of type "Comment" if the issue has no comments',
	},
});
