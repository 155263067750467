import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	epicTitle: {
		id: 'native-issue-table.common.issue-cells.epic-link.epic-title',
		defaultMessage: 'Click to visit',
		description: 'Epic url title - Click to visit [epic]',
	},
	replacedByParentField: {
		id: 'issue-create-commons.common.fields.epic-link-parent-link-disabled.replaced-by-parent-field',
		defaultMessage: 'Replaced by <strong>Parent</strong> field',
		description:
			'Text to display on the "Epic Link" field indicating that the "Epic Link" field is no longer used and the user should use the "Parent" field instead',
	},
});
