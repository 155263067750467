import React, { useCallback, useMemo, type Ref } from 'react';
import memoizeOne from 'memoize-one';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	type CustomTriggerProps,
	DropdownItemGroup,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import MoreVerticalIcon from '@atlaskit/icon/core/migration/show-more-vertical--more-vertical';
import ShowMoreVerticalIcon from '@atlaskit/icon/utility/show-more-vertical';
import ShowMoreHorizontalIcon from '@atlaskit/icon/utility/show-more-horizontal';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	MOVE_COLUMN_FIRST,
	MOVE_COLUMN_LAST,
	MOVE_COLUMN_LEFT,
	MOVE_COLUMN_RIGHT,
	SORT_ASC,
	SORT_DESC,
} from '../../../../../../common/constants.tsx';
import type { Column, TargetColumnPosition } from '../../../../../../common/types.tsx';
import { ActionItem } from './action-item/index.tsx';
import messages from './messages.tsx';

export type Props = {
	column: Column;
	currentColumnWidth: number;
	hasColumnConfiguration: boolean;
	isColumnResizingEnabled: boolean;
	isFirst: boolean;
	isLast: boolean;
	isOpen: boolean;
	triggerRef?: Ref<HTMLButtonElement>;
	onMoveColumn: (position: TargetColumnPosition) => void;
	onOpenChange: (isOpen: boolean) => void;
	onRemoveColumn: () => void;
	onResizeColumn: () => void;
	onResetColumnWidth: () => void;
	onFocus: () => void;
	onBlur: () => void;
};

export const MoreActions = ({
	column,
	currentColumnWidth,
	hasColumnConfiguration,
	isColumnResizingEnabled,
	isFirst,
	isLast,
	isOpen,
	triggerRef = null,
	onMoveColumn,
	onOpenChange,
	onRemoveColumn,
	onResizeColumn,
	onResetColumnWidth,
	onFocus,
	onBlur,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { formatMessage } = useIntl();

	const sortAsc = useCallback(() => {
		column.onSortOrderChanged?.(column.jqlTerm, SORT_ASC);
	}, [column]);

	const sortDesc = useCallback(() => {
		column.onSortOrderChanged?.(column.jqlTerm, SORT_DESC);
	}, [column]);

	const handleOnOpenChange = useCallback(
		({ isOpen: newIsOpen }: OnOpenChangeArgs) => {
			if (newIsOpen) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'opened',
						actionSubject: 'dropdown',
					}),
					'issueTableHeaderActions',
				);
			}

			onOpenChange(newIsOpen);
		},
		[createAnalyticsEvent, onOpenChange],
	);

	const isSortable = column.onSortOrderChanged !== undefined && column.isSortable;

	const triggerLabel = formatMessage(messages.columnActions, { column: column.title });

	// Ensure a stable ref function is passed through to the trigger button
	const memoizedMergeRefs = useMemo(() => memoizeOne(mergeRefs), []);

	return (
		<DropdownMenu
			isOpen={isOpen}
			onOpenChange={handleOnOpenChange}
			placement="bottom-start"
			trigger={({
				triggerRef: dropdownTriggerRef,
				...triggerProps
			}: CustomTriggerProps<HTMLButtonElement>) =>
				isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
					<IconButton
						{...triggerProps}
						ref={memoizedMergeRefs(dropdownTriggerRef, triggerRef)}
						icon={fg('visual-refresh_drop_6') ? ShowMoreHorizontalIcon : ShowMoreVerticalIcon}
						label={triggerLabel}
						onFocus={onFocus}
						onBlur={onBlur}
						isTooltipDisabled={false}
						spacing="compact"
						appearance="subtle"
						testId="native-issue-table.ui.issue-table.header.header-cell.header-cell-actions.more-actions.icon-button"
					/>
				) : (
					<Tooltip content={triggerLabel} delay={0} ignoreTooltipPointerEvents>
						<Box xcss={triggerStyles}>
							<Button
								{...triggerProps}
								ref={memoizedMergeRefs(dropdownTriggerRef, triggerRef)}
								appearance="subtle"
								spacing="compact"
								iconBefore={<MoreVerticalIcon label={triggerLabel} LEGACY_size="small" />}
								onFocus={onFocus}
								onBlur={onBlur}
							/>
						</Box>
					</Tooltip>
				)
			}
		>
			{isSortable && (
				<DropdownItemGroup>
					{column.currentSorting !== SORT_ASC && (
						<ActionItem actionType="sortAsc" onClick={sortAsc}>
							{column.ascText ?? false
								? formatMessage(messages.sortCustom, {
										sortDescription: column.ascText,
									})
								: formatMessage(messages.sortAsc)}
						</ActionItem>
					)}
					{column.currentSorting !== SORT_DESC && (
						<ActionItem actionType="sortDesc" onClick={sortDesc}>
							{column.descText ?? false
								? formatMessage(messages.sortCustom, {
										sortDescription: column.descText,
									})
								: formatMessage(messages.sortDesc)}
						</ActionItem>
					)}
				</DropdownItemGroup>
			)}
			{hasColumnConfiguration && (
				<DropdownItemGroup hasSeparator={isSortable}>
					{!isFirst && (
						<>
							<ActionItem
								actionType="moveColumnFirst"
								onClick={() => onMoveColumn(MOVE_COLUMN_FIRST)}
							>
								{formatMessage(messages.moveFirst)}
							</ActionItem>
							<ActionItem
								actionType="moveColumnLeft"
								onClick={() => onMoveColumn(MOVE_COLUMN_LEFT)}
							>
								{formatMessage(messages.moveLeft)}
							</ActionItem>
						</>
					)}
					{!isLast && (
						<>
							<ActionItem
								actionType="moveColumnRight"
								onClick={() => onMoveColumn(MOVE_COLUMN_RIGHT)}
							>
								{formatMessage(messages.moveRight)}
							</ActionItem>
							<ActionItem
								actionType="moveColumnLast"
								onClick={() => onMoveColumn(MOVE_COLUMN_LAST)}
							>
								{formatMessage(messages.moveLast)}
							</ActionItem>
						</>
					)}
					<ActionItem actionType="removeColumn" onClick={onRemoveColumn}>
						{formatMessage(messages.removeColumn)}
					</ActionItem>
				</DropdownItemGroup>
			)}
			{isColumnResizingEnabled && (
				<DropdownItemGroup hasSeparator={isSortable || hasColumnConfiguration}>
					<ActionItem actionType="resizeColumn" onClick={onResizeColumn}>
						{formatMessage(messages.resizeColumn)}
					</ActionItem>
					{currentColumnWidth != null && currentColumnWidth !== column.defaultWidth && (
						<ActionItem actionType="resetColumnWidth" onClick={onResetColumnWidth}>
							{formatMessage(messages.resetColumnWidth)}
						</ActionItem>
					)}
				</DropdownItemGroup>
			)}
			<ShortcutScope />
		</DropdownMenu>
	);
};

const triggerStyles = xcss({
	display: 'flex',
	height: '24px',
	width: '24px',
});
