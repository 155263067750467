import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	retryMessage: {
		id: 'item-with-popup.ui.item-with-entrypoint-popup.error-fallback.retry-message',
		defaultMessage: 'Click to retry',
		description: 'Label for a retry button displayed when popup content failed to load.',
	},
	errorMessage: {
		id: 'item-with-popup.ui.item-with-entrypoint-popup.error-fallback.error-message',
		defaultMessage: 'Failed to load content',
		description: 'Message displayed when popup content failed to load.',
	},
});
