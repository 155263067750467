import React, {
	type ForwardedRef,
	forwardRef,
	type SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Pagination from '@atlaskit/pagination';
import Tooltip from '@atlaskit/tooltip';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	type Attributes,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	paginationControls_nativeIssueTable$key as PaginationControlsKey,
	paginationControls_nativeIssueTable$data as PaginationControlsData,
} from '@atlassian/jira-relay/src/__generated__/paginationControls_nativeIssueTable.graphql';
import messages from './messages.tsx';

const getFirstPage = (data: PaginationControlsData | null): number | null | undefined => {
	if (data?.pageCursors?.first) {
		return data?.pageCursors.first.pageNumber;
	}

	if (data?.pageCursors?.around?.length) {
		return data?.pageCursors.around[0]?.pageNumber;
	}

	return undefined;
};

const getLastPage = (data: PaginationControlsData | null): number | null | undefined => {
	if (data?.pageCursors?.last) {
		return data?.pageCursors.last.pageNumber;
	}

	if (data?.pageCursors?.around?.length) {
		return data?.pageCursors.around[data?.pageCursors.around.length - 1]?.pageNumber;
	}

	return undefined;
};

const getPageCursor = (
	data: PaginationControlsData | null,
	page: number,
): string | null | undefined => {
	const { first, last, around } = data?.pageCursors ?? {};
	if (page === first?.pageNumber) return first?.cursor ?? undefined;
	if (page === last?.pageNumber) return last?.cursor ?? undefined;
	return (
		around?.find(
			(
				pageCursor:
					| {
							readonly cursor: string | null | undefined;
							readonly isCurrent: boolean | null | undefined;
							readonly pageNumber: number | null | undefined;
					  }
					| null
					| undefined,
			) => page === pageCursor?.pageNumber,
		)?.cursor ?? undefined
	);
};

export type Props = {
	/**
	 * Relay fragment key for a connection of issues.
	 */
	issues: PaginationControlsKey;
	/**
	 * Event emitted when the page changes.
	 *
	 * @param after Cursor of the newly selected page.
	 */
	onPageChange: (after: string) => void;
	/**
	 * `true` if the new footer order is enabled, which moves the pagination controls to the center of the footer.
	 */
	isNewFooterOrderEnabled: boolean;
};

export const PaginationControls = ({ issues, onPageChange, isNewFooterOrderEnabled }: Props) => {
	const { formatMessage } = useIntl();
	const data = useFragment(
		graphql`
			fragment paginationControls_nativeIssueTable on JiraIssueConnection
			@argumentDefinitions(pageSize: { type: "Int" }) {
				isCappingIssueSearchResult
				pageCursors(maxCursors: 7, pageSize: $pageSize) {
					first {
						cursor
						pageNumber
						isCurrent
					}
					around {
						cursor
						pageNumber
						isCurrent
					}
					last {
						cursor
						pageNumber
						isCurrent
					}
				}
			}
		`,
		issues,
	);

	const [optimisticCurrentPage, setOptimisticCurrentPage] = useState<number | null>(null);

	useEffect(() => {
		// Clear our optimistically set page number whenever fragment data changes.
		setOptimisticCurrentPage(null);
	}, [data]);

	const pages = useMemo<number[]>(() => {
		const firstPage = getFirstPage(data) ?? 1;
		const lastPage = getLastPage(data) ?? 1;
		return Array(lastPage - firstPage + 1)
			.fill(undefined)
			.map((_, i) => firstPage + i);
	}, [data]);

	const currentPage =
		optimisticCurrentPage ??
		data?.pageCursors?.around?.find(
			(
				pageCursor:
					| {
							readonly cursor: string | null | undefined;
							readonly isCurrent: boolean | null | undefined;
							readonly pageNumber: number | null | undefined;
					  }
					| null
					| undefined,
			) => pageCursor?.isCurrent,
		)?.pageNumber ??
		1;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onChange = useCallback(
		(_: SyntheticEvent, page: number) => {
			if (page === currentPage || optimisticCurrentPage !== null) {
				return;
			}

			const pageCursorToFetch = getPageCursor(data, page);
			if (pageCursorToFetch != null) {
				setOptimisticCurrentPage(page);
				onPageChange(pageCursorToFetch);

				const attributes: Attributes = {
					currentPage,
					nextPage: page,
					randomAccess: Math.abs(page - currentPage) > 1,
					isLastPage: page === pages.length,
				};

				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'changed',
						actionSubject: 'pageNumber',
					}),
					'issueTablePagination',
					attributes,
				);
			}
		},
		[createAnalyticsEvent, currentPage, data, onPageChange, optimisticCurrentPage, pages.length],
	);

	// Disable the pagination buttons while the selected page is optimistically rendered as we may not have page cursors
	// readily available for pages rendered on the UI.
	const OptimisticDisabledButtonRenderer = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		({ 'aria-label': ariaLabel, ...props }: any, ref: ForwardedRef<HTMLElement>) => (
			<button
				ref={ref}
				{...props}
				aria-label={
					!ariaLabel && props.page
						? formatMessage(messages.pageButtonPrefix, { page: props.page })
						: ariaLabel
				}
				disabled={props.disabled || optimisticCurrentPage !== null}
			/>
		),
		[formatMessage, optimisticCurrentPage],
	);

	const OptimisticDisabledButtonRendererWithTooltip = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		({ 'aria-label': ariaLabel, ...props }: any, ref: ForwardedRef<HTMLElement>) =>
			props.disabled && optimisticCurrentPage == null && data?.isCappingIssueSearchResult ? (
				<Tooltip
					content={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.disabledButtonTooltipIssueTermRefresh
							: messages.disabledButtonTooltip,
					)}
					position="top-end"
				>
					<FullHeightDisabledButton
						tabIndex={0}
						isDisabled
						aria-label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.disabledButtonTooltipIssueTermRefresh
								: messages.disabledButtonTooltip,
						)}
						data-testid="native-issue-table.ui.footer-renderer.pagination-controls.full-height-disabled-button"
					>
						<button ref={ref} {...props} aria-label={ariaLabel} />
					</FullHeightDisabledButton>
				</Tooltip>
			) : (
				<button
					ref={ref}
					{...props}
					aria-label={ariaLabel}
					disabled={props.disabled || optimisticCurrentPage !== null}
				/>
			),
		[optimisticCurrentPage, data?.isCappingIssueSearchResult, formatMessage],
	);

	const components = useMemo(() => {
		const OptimisticDisabledButton = forwardRef(OptimisticDisabledButtonRenderer);
		const OptimisticDisabledButtonWithTooltip = forwardRef(
			OptimisticDisabledButtonRendererWithTooltip,
		);

		return {
			Page: OptimisticDisabledButton,
			Previous: OptimisticDisabledButton,
			Next: OptimisticDisabledButtonWithTooltip,
		};
	}, [OptimisticDisabledButtonRenderer, OptimisticDisabledButtonRendererWithTooltip]);

	return (
		<PaginationContainer isFlexCenter={isNewFooterOrderEnabled}>
			<Pagination
				testId="native-issue-table.ui.footer-renderer.pagination-controls.pagination-controls"
				components={components}
				pages={pages}
				onChange={onChange}
				selectedIndex={currentPage - 1}
				max={7}
				nextLabel={formatMessage(messages.nextButton)}
				previousLabel={formatMessage(messages.prevButton)}
			/>
		</PaginationContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullHeightDisabledButton = styled(DisabledButtonWithTooltip)({
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationContainer = styled.div<{ isFlexCenter: boolean }>({
	display: 'flex',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	justifyContent: ({ isFlexCenter }) => (isFlexCenter ? 'center' : 'flex-end'),
	alignItems: 'center',
});
