import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitle: {
		id: 'native-issue-table.rows.child-rows.error-flag-title',
		defaultMessage: "We couldn't expand hierarchy",
		description: 'Error flag title when hierarchy cannot be expanded',
	},
	errorFlagDescription: {
		id: 'native-issue-table.rows.child-rows.error-flag-description',
		defaultMessage:
			'Someone may have deleted a task or changed access to the parent. Try again or contact an admin.',
		description: 'Error flag description when hierarchy cannot be expanded.',
	},
	errorFlagGroupingTitle: {
		id: 'native-issue-table.rows.child-rows.error-flag-grouping-title',
		defaultMessage: "We couldn't expand the group",
		description: 'Error flag title when group cannot be expanded',
	},
	errorFlagGroupingDescription: {
		id: 'native-issue-table.rows.child-rows.error-flag-grouping-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Error flag description when group cannot be expanded.',
	},
});
