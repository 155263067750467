import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'issue-navigator-issue-operations.dropdown-menu-group.error-message',
		defaultMessage: "Couldn't retrieve data",
		description: 'The text for when an error occurs when loading options',
	},
	retryMessage: {
		id: 'issue-navigator-issue-operations.dropdown-menu-group.retry-message',
		defaultMessage: 'Click to retry',
		description: 'The text for button to reload options',
	},
});
