/**
 * @generated SignedSource<<2f20c59dab77492604598897b506d3fd>>
 * @relayHash ead4aeaad9bf490eb7d3435ec219e3a0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3c49ad7e0999f23413665b12454770bad160b1b9af9354368998b9abe8369bcc

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateTeamFieldInput = {
  id: string;
  operation: JiraTeamFieldOperationInput;
};
export type JiraTeamFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type team_issueFieldTeam_TeamField_Mutation$variables = {
  input: JiraUpdateTeamFieldInput;
};
export type team_issueFieldTeam_TeamField_Mutation$data = {
  readonly jira: {
    readonly updateTeamField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type team_issueFieldTeam_TeamField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateTeamField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly selectedTeam: {
          readonly jiraSuppliedId: string;
          readonly jiraSuppliedName: string | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type team_issueFieldTeam_TeamField_Mutation = {
  rawResponse: team_issueFieldTeam_TeamField_Mutation$rawResponse;
  response: team_issueFieldTeam_TeamField_Mutation$data;
  variables: team_issueFieldTeam_TeamField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "team_issueFieldTeam_TeamField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraTeamFieldPayload",
            "kind": "LinkedField",
            "name": "updateTeamField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraTeamViewField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "team_issueFieldTeam_TeamField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraTeamFieldPayload",
            "kind": "LinkedField",
            "name": "updateTeamField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraTeamViewField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraTeamView",
                    "kind": "LinkedField",
                    "name": "selectedTeam",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "jiraSuppliedId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "jiraSuppliedName"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3c49ad7e0999f23413665b12454770bad160b1b9af9354368998b9abe8369bcc",
    "metadata": {},
    "name": "team_issueFieldTeam_TeamField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f3f61da99e07b74cee3d1344c1a154d1";

export default node;
