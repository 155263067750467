import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issuetype: {
		id: 'issue-table-column-picker.issuetype',
		defaultMessage: 'Issue Type',
		description: 'Label to show for the Issue Type column',
	},
	issuekey: {
		id: 'issue-table-column-picker.issuekey',
		defaultMessage: 'Key',
		description: 'Label to show for the Key column',
	},
	summary: {
		id: 'issue-table-column-picker.summary',
		defaultMessage: 'Summary',
		description: 'Label to show for the Summary column',
	},
	assignee: {
		id: 'issue-table-column-picker.assignee',
		defaultMessage: 'Assignee',
		description: 'Label to show for the Assignee column',
	},
	reporter: {
		id: 'issue-table-column-picker.reporter',
		defaultMessage: 'Reporter',
		description: 'Label to show for the Reporter column',
	},
	priority: {
		id: 'issue-table-column-picker.priority',
		defaultMessage: 'Priority',
		description: 'Label to show for the Priority column',
	},
	status: {
		id: 'issue-table-column-picker.status',
		defaultMessage: 'Status',
		description: 'Label to show for the Status column',
	},
	resolution: {
		id: 'issue-table-column-picker.resolution',
		defaultMessage: 'Resolution',
		description: 'Label to show for the Resolution column',
	},
	created: {
		id: 'issue-table-column-picker.created',
		defaultMessage: 'Created',
		description: 'Label to show for the Created column',
	},
	updated: {
		id: 'issue-table-column-picker.updated',
		defaultMessage: 'Updated',
		description: 'Label to show for the Updated column',
	},
	duedate: {
		id: 'issue-table-column-picker.duedate',
		defaultMessage: 'Due date',
		description: 'Label to show for the Due date column',
	},
	deprecatedParentLinkMessage: {
		id: 'issue-table-column-picker.deprecated-parent-link-message',
		defaultMessage:
			'<strong>Parent Link</strong> is replaced by <strong>Parent</strong>. Use the <strong>Parent</strong> field instead.',
		description: 'Warning message for Parent Link deprecation',
	},
	deprecatedEpicMessage: {
		id: 'issue-table-column-picker.deprecated-epic-message',
		defaultMessage:
			'<strong>Epic Link</strong> is replaced by <strong>Parent</strong>. Use the <strong>Parent</strong> field instead.',
		description: 'Warning message for Epic Link deprecation',
	},
});
