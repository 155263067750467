/**
 * @generated SignedSource<<58136de7036b95adc6365cb31a2bfcea>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_nativeIssueTable_IssueCountRenderer$data = {
  readonly edges: ReadonlyArray<{
    readonly __typename: "JiraIssueEdge";
  } | null | undefined> | null | undefined;
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly issueNavigatorPageInfo: {
    readonly firstIssuePosition: number | null | undefined;
    readonly lastIssuePosition: number | null | undefined;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentType": "main_nativeIssueTable_IssueCountRenderer";
};
export type main_nativeIssueTable_IssueCountRenderer$key = {
  readonly " $data"?: main_nativeIssueTable_IssueCountRenderer$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_nativeIssueTable_IssueCountRenderer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_nativeIssueTable_IssueCountRenderer",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    },
    {
      "concreteType": "JiraIssueNavigatorPageInfo",
      "kind": "LinkedField",
      "name": "issueNavigatorPageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "firstIssuePosition"
        },
        {
          "kind": "ScalarField",
          "name": "lastIssuePosition"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "26c8c81744afeed511fc1eca25e62e4d";

export default node;
