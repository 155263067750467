import { graphql, fetchQuery, type Environment } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { ViewResponse } from '@atlassian/jira-issue-media-provider/src/common/types.tsx';
import type { actions_LazyLoadMediaReadTokenQuery } from '@atlassian/jira-relay/src/__generated__/actions_LazyLoadMediaReadTokenQuery.graphql';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { MAX_TOKEN_LENGTH } from './constants.tsx';
import type { Actions } from './types.tsx';

export const batchMediaViewResponseFactory =
	(environment: Environment, cloudId: string) =>
	async (issueKeys: ReadonlyArray<string>): Promise<ReadonlyArray<ViewResponse | null>> => {
		const issueKeysMap = new Map<string, ViewResponse | null>();
		const uniqueIssueKeys = [...new Set(issueKeys)];

		const promise = fetchQuery<actions_LazyLoadMediaReadTokenQuery>(
			environment,
			graphql`
				query actions_LazyLoadMediaReadTokenQuery(
					$maxTokenLength: Int!
					$issueKeys: [String!]!
					$cloudId: ID!
				) {
					jira {
						issuesByKey(keys: $issueKeys, cloudId: $cloudId) {
							key
							mediaReadToken(maxTokenLength: $maxTokenLength)
								@optIn(to: "JiraMediaReadTokenInIssue") {
								clientId
								endpointUrl
								tokenLifespanInSeconds
								tokensWithFiles {
									edges {
										node {
											token
										}
									}
								}
							}
						}
					}
				}
			`,
			{ issueKeys: uniqueIssueKeys, cloudId, maxTokenLength: MAX_TOKEN_LENGTH },
		).toPromise();

		try {
			const result = await promise;
			const issues = result?.jira?.issuesByKey;
			issues?.forEach((issue) => {
				if (issue?.mediaReadToken) {
					const { clientId, endpointUrl, tokenLifespanInSeconds, tokensWithFiles } =
						issue?.mediaReadToken;
					const tokensWithFilesFlattened = tokensWithFiles?.edges
						?.map((edge) => (edge?.node?.token ? { token: edge.node.token } : null))
						.filter(Boolean);

					if (clientId && endpointUrl && tokenLifespanInSeconds && tokensWithFilesFlattened) {
						const viewResponse: ViewResponse = {
							clientId,
							endpointUrl,
							tokenLifespanInSeconds,
							tokensWithFiles: tokensWithFilesFlattened,
						};

						issueKeysMap.set(issue.key, viewResponse);
					}
				}
			});
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: 'lazyMediaViewResponseFactory',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error: error instanceof Error ? error : undefined,
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		// Ensure input argument order is preserved when returning batch results as required by the dataloader
		return issueKeys.map((issueKey) => issueKeysMap.get(issueKey) ?? null);
	};

export const actions: Actions = {
	load:
		(issueKey: string) =>
		({ getState }): Promise<ViewResponse | null> => {
			const { loader, scheduler } = getState();
			const loading = loader ? loader.load(issueKey) : Promise.resolve(null);
			scheduler?.dispatch();
			return loading;
		},
};
