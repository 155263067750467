import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const ICON_WIDTH = 24;
const INDENTED_PADDING = 8;

const IndentedIcon = ({ depth }: { depth: number }) => {
	if (depth <= 0) return null;
	const left = depth > 1 ? (depth - 1) * ICON_WIDTH + INDENTED_PADDING : INDENTED_PADDING;

	return (
		<Box
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ left: `${left}px` }}
			xcss={imgContainerStyles}
		>
			<Box
				xcss={imgStyles}
				testId="native-issue-table.ui.expand-button.indented-icon.indented-icon"
			>
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1 0V9.5C1 10.3284 1.67157 11 2.5 11H12V12H2.5C1.11929 12 0 10.8807 0 9.5V0H1Z"
						fill={token('color.border')}
					/>
				</svg>
			</Box>
		</Box>
	);
};

const imgStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: token('space.300'),
	height: token('space.300'),
});

const imgContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	height: '100%',
});

export default IndentedIcon;
