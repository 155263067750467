/* eslint-disable @atlassian/relay/graphql-naming */
import { graphql } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import type { highlightIssueRow_update_isHighlightedIssueRow$key as IssueRef } from '@atlassian/jira-relay/src/__generated__/highlightIssueRow_update_isHighlightedIssueRow.graphql';

/**
 * Update a `JiraIssue` to set the `isHighlightedIssueRow` client-only field to the provided value. The updatable
 * fragment must be spread on a `JiraIssue` which is provided via the `issueRef` argument. This will re-render any
 * components subscribed to the field. See https://relay.dev/docs/guided-tour/updating-data/imperatively-modifying-store-data/.
 *
 * @param store Relay store proxy, sourced from either a mutation updater function or `commitLocalUpdate`.
 * @param issueRef Fragment reference for the `JiraIssue` to update.
 * @param isHighlighted Value to set for the `isHighlightedIssueRow` client-only field.
 */
export const highlightIssueRow = (
	store: RecordSourceSelectorProxy,
	issueRef: IssueRef,
	isHighlighted: boolean,
) => {
	const { updatableData } = store.readUpdatableFragment(
		graphql`
			fragment highlightIssueRow_update_isHighlightedIssueRow on JiraIssue @updatable {
				isHighlightedIssueRow
			}
		`,
		issueRef,
	);

	updatableData.isHighlightedIssueRow = isHighlighted;
};
