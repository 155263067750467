import React, { memo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { PriorityInlineEditViewIsEditable } from '@atlassian/jira-issue-field-priority-inline-edit-full/src/ui/priority/index.tsx';
import type { priority_nativeIssueTable_PriorityCell$key } from '@atlassian/jira-relay/src/__generated__/priority_nativeIssueTable_PriorityCell.graphql';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { type Props as HoverPopoverProps, HoverPopover } from '../../hover-popover/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../../controllers/features/selectors.tsx';

type Props = {
	fieldRef: priority_nativeIssueTable_PriorityCell$key;
};

export const PriorityCell = memo(({ fieldRef }: Props) => {
	const data = useFragment<priority_nativeIssueTable_PriorityCell$key>(
		graphql`
			fragment priority_nativeIssueTable_PriorityCell on JiraPriorityField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
	useLazyLoadEditability(data.id, isEditable);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	const hoverPopoverRenderer = (props: HoverPopoverProps) => (
		<HoverPopover {...props}>{props.children}</HoverPopover>
	);

	return (
		<PriorityInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
			hoverPopoverRenderer={isSingleLineRowHeightEnabled ? hoverPopoverRenderer : undefined}
		/>
	);
});
