import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { beforeFirstCell_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/beforeFirstCell_nativeIssueTable.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIsReparentingEnabled,
	useIsDragAndDropEnabled,
} from '../../../controllers/features/selectors.tsx';
import { useSiblingIssueCreateRow } from '../../../controllers/sibling-issue-create-handler/index.tsx';
import { DragActionsButton } from './drag-actions-button/index.tsx';
import { SiblingIssueCreateButton } from './sibling-issue-create-button/index.tsx';

export interface Props {
	/** Index of the issue within a connection. */
	edgeIndex: number;
	/** ARI of the issue */
	issueId: string;
	/** Relay ID of the issue connection record. */
	itemsConnectionId: string;
	/** Issue level of the issue to know if is possible to reparent  */
	hierarchyLevel?: number | null;
	/** ARI of issue's parent */
	parentIssueAri?: string;
	/** The relay id of the JiraIssueConnection of the parent issue */
	parentIssueConnectionId?: string;
	/** Issue key of the issue to reparent  */
	issueKey?: string | undefined | null;
	/** Key of the current project */
	projectKey?: string | undefined;
	/** Boolean to know if the row is hovered and then show the drag handler  */
	isRowHovered?: boolean;
	/** Query to get the data to build the drag preview */
	issueRowData: beforeFirstCell_nativeIssueTable$key | null;
	/** Setter of row currently being dragging if any */
	setCurrentDraggingRow?: (itemKey: string | null) => void;
}

/**
 * Element to render within the first `td` of the issue row. This is to enable rendering of absolute/fixed position
 * elements relative to the start of the table row while retaining semantically valid HTML.
 */
export const BeforeFirstCell = ({
	edgeIndex,
	issueId,
	itemsConnectionId,
	hierarchyLevel,
	parentIssueAri,
	parentIssueConnectionId,
	issueKey,
	projectKey,
	isRowHovered,
	issueRowData,
	setCurrentDraggingRow,
}: Props) => {
	const { isFormVisible } = useSiblingIssueCreateRow();

	const firstCellData = useFragment<beforeFirstCell_nativeIssueTable$key>(
		graphql`
			fragment beforeFirstCell_nativeIssueTable on JiraIssue
			@argumentDefinitions(isReparentingEnabled: { type: "Boolean!" }) {
				...dragActionsButton_nativeIssueTable
					@arguments(isReparentingEnabled: $isReparentingEnabled)
			}
		`,
		issueRowData,
	);

	const isDragAndDropEnabled = useIsDragAndDropEnabled();

	let isReparentingEnabled = false;
	if (fg('jsc_list_reparenting')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isReparentingEnabled = useIsReparentingEnabled();
	}

	return (
		<>
			{
				// Check value instead of truthiness because it can be `undefined` if sibling issue create is disabled
				isFormVisible === false && (
					<SiblingIssueCreateButton edgeIndex={edgeIndex} issuesConnectionId={itemsConnectionId} />
				)
			}
			{isDragAndDropEnabled && (
				<DragActionsButton
					edgeIndex={edgeIndex}
					issueId={issueId}
					isRowHovered={isRowHovered}
					parentIssueConnectionId={parentIssueConnectionId}
					{...(isReparentingEnabled
						? {
								hierarchyLevel,
								parentIssueAri,
								issueKey,
								projectKey,
								firstCellData,
								itemsConnectionId,
								setCurrentDraggingRow,
							}
						: { firstCellData: null })}
				/>
			)}
		</>
	);
};
