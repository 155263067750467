/**
 * @generated SignedSource<<81652b567bdc92b05001baef9bc6e51d>>
 * @relayHash 02c885dbb8c08ad0f87a165a37575688
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9550e91efd1bd8e37c18308d513a0005542005d52520a500f674a261bddce50e

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type realtimeUpdater_issueCreated_Subscription$variables = {
  cloudId: string;
  projectId: string;
};
export type realtimeUpdater_issueCreated_Subscription$data = {
  readonly jira: {
    readonly onIssueCreatedByProject: {
      readonly key: string;
    } | null | undefined;
  } | null | undefined;
};
export type realtimeUpdater_issueCreated_Subscription = {
  response: realtimeUpdater_issueCreated_Subscription$data;
  variables: realtimeUpdater_issueCreated_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "projectId",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "realtimeUpdater_issueCreated_Subscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "onIssueCreatedByProject",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "realtimeUpdater_issueCreated_Subscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "onIssueCreatedByProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9550e91efd1bd8e37c18308d513a0005542005d52520a500f674a261bddce50e",
    "metadata": {},
    "name": "realtimeUpdater_issueCreated_Subscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "6f755119342f9769d9f83ee0dd05b105";

export default node;
