// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	type Action,
	createContainer,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import type { State, Key, Index } from './types.tsx';

const CLEAR_DELAY = 300;

const initialState: State = {
	issue: {
		index: undefined,
		isIndexLocked: false,
		timeoutId: undefined,
	},
	field: {
		index: undefined,
		isIndexLocked: false,
		timeoutId: undefined,
	},
};

const actions = {
	setIndex:
		(key: Key, index: Index): Action<State> =>
		({ setState, getState, dispatch }) => {
			setState({
				[key]: {
					...getState()[key],
					index,
				},
			});
			dispatch(actions.lockIndex(key));
		},
	clearIndex:
		(key: Key): Action<State> =>
		({ setState, getState, dispatch }) => {
			setState({
				[key]: {
					...getState()[key],
					index: undefined,
				},
			});
			dispatch(actions.unlockIndex(key));
		},
	clearIndexAfterDelay:
		(key: Key): Action<State> =>
		({ setState, getState, dispatch }) => {
			const timeoutId = setTimeout(() => {
				if (!getState()[key].isIndexLocked) {
					dispatch(actions.clearIndex(key));
					setState({
						[key]: {
							...getState()[key],
							timeoutId: undefined,
						},
					});
				}
			}, CLEAR_DELAY);
			setState({
				[key]: {
					...getState()[key],
					isIndexLocked: false,
					timeoutId,
				},
			});
		},
	lockIndex:
		(key: Key): Action<State> =>
		({ setState, getState }) => {
			const { timeoutId } = getState()[key];
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			setState({
				[key]: {
					...getState()[key],
					isIndexLocked: true,
					timeoutId: undefined,
				},
			});
		},
	unlockIndex:
		(key: Key): Action<State> =>
		({ setState, getState }) => {
			setState({
				[key]: {
					...getState()[key],
					isIndexLocked: false,
				},
			});
		},
};

export const SiblingCreateActiveIndexContainer = createContainer();

const Store = createStore({
	initialState,
	actions,
	containedBy: SiblingCreateActiveIndexContainer,
});

const isIndexEqual = (a: Index | undefined, b: Index) =>
	a !== undefined && a[0] === b[0] && a[1] === b[1];

/**
 * Return `true` if the sibling issue create action should be displayed for a given issue index.
 */
export const useIsSiblingIssueCreateActive = createHook(Store, {
	selector: (state: State, index: Index) => isIndexEqual(state.issue.index, index),
});

export const useSiblingCreateActiveIndexActions = createActionsHook(Store);

// Exported for testing
export const useSiblingCreateActiveIndex = createHook(Store);
