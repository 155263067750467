import React from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { StyledText } from './styled.tsx';

export const ErrorState = () => {
	const { formatMessage } = useIntl();
	return (
		<StyledBox>
			<ErrorIcon spacing="spacious" color={token('color.icon')} label="" />
			<StyledText>{formatMessage(messages.errorMessage)}</StyledText>
		</StyledBox>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBox = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	minHeight: '144px',
	justifyContent: 'center',
	marginTop: token('space.150'),
	marginRight: token('space.300'),
	marginBottom: token('space.200'),
	marginLeft: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '72px',
		height: '72px',
	},
});
