/**
 * @generated SignedSource<<c03f711f2100ccb4fcb240fc4071d6d1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld$data = {
  readonly fieldSetsById?: {
    readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
  };
  readonly fieldSetsForIssueSearchView?: {
    readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
  };
  readonly " $fragmentType": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld";
};
export type issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld$key = {
  readonly " $data"?: issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "isDensityFull",
        "variableName": "isDensityFull"
      },
      {
        "kind": "Variable",
        "name": "isInlineEditingEnabled",
        "variableName": "isInlineEditingEnabled"
      }
    ],
    "kind": "FragmentSpread",
    "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsContext"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld",
  "selections": [
    {
      "condition": "shouldQueryFieldSetsById",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "args": [
              {
                "kind": "Variable",
                "name": "fieldSetIds",
                "variableName": "fieldSetIds"
              },
              (v0/*: any*/)
            ],
            "concreteType": "JiraIssueFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSetsById",
            "plural": false,
            "selections": (v1/*: any*/)
          },
          "action": "THROW",
          "path": "fieldSetsById"
        }
      ]
    },
    {
      "condition": "shouldQueryFieldSetsById",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "args": [
              {
                "kind": "Variable",
                "name": "context",
                "variableName": "fieldSetsContext"
              },
              {
                "kind": "Variable",
                "name": "filterId",
                "variableName": "filterId"
              },
              (v0/*: any*/),
              {
                "kind": "Variable",
                "name": "namespace",
                "variableName": "namespace"
              },
              {
                "kind": "Variable",
                "name": "viewId",
                "variableName": "viewId"
              }
            ],
            "concreteType": "JiraIssueFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSetsForIssueSearchView",
            "plural": false,
            "selections": (v1/*: any*/)
          },
          "action": "THROW",
          "path": "fieldSetsForIssueSearchView"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "04b58e7d74acd8f7f93585698eb3d968";

export default node;
