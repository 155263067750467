/**
 * @generated SignedSource<<3281df4ab3692851ab8562e9a5d372a1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type user_nativeIssueTable_UserCell_fieldRef$data = {
  readonly fieldId: string;
  readonly type: string;
  readonly user: {
    readonly accountId: string;
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"user_nativeIssueTable_UserCellInternal_fieldRef">;
  readonly " $fragmentType": "user_nativeIssueTable_UserCell_fieldRef";
};
export type user_nativeIssueTable_UserCell_fieldRef$key = {
  readonly " $data"?: user_nativeIssueTable_UserCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"user_nativeIssueTable_UserCell_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "user_nativeIssueTable_UserCell_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "accountId"
        },
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "user_nativeIssueTable_UserCellInternal_fieldRef"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "a362c09fb0469678ce4210679cf0a5fa";

export default node;
