/**
 * @generated SignedSource<<4f7957eb15e09b3ca778fbe5b6cecfb8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type date_nativeIssueTable_DateCell$data = {
  readonly __typename: string;
  readonly date?: AGG$Date | null | undefined;
  readonly dateTime?: AGG$DateTime | null | undefined;
  readonly fieldId: string;
  readonly " $fragmentSpreads": FragmentRefs<"date_nativeIssueTable_DateInlineEditCell_fieldRef">;
  readonly " $fragmentType": "date_nativeIssueTable_DateCell";
};
export type date_nativeIssueTable_DateCell$key = {
  readonly " $data"?: date_nativeIssueTable_DateCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"date_nativeIssueTable_DateCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "date_nativeIssueTable_DateCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "date"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "isInlineEditingEnabled",
              "variableName": "isInlineEditingEnabled"
            }
          ],
          "kind": "FragmentSpread",
          "name": "date_nativeIssueTable_DateInlineEditCell_fieldRef"
        }
      ],
      "type": "JiraDatePickerField"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "dateTime"
        }
      ],
      "type": "JiraDateTimePickerField"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "7b89423f51f8c1066484f850cdda0694";

export default node;
