import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.label',
		defaultMessage: 'Actions',
		description: 'Label for the drag actions button',
	},
	addParent: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.add-parent',
		defaultMessage: 'Add parent',
		description: 'Label for the button that allows the users to add a parent to the item',
	},
	changeParent: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.change-parent',
		defaultMessage: 'Change parent',
		description: 'Label for the button that allows the users to change the parent of the item',
	},
	moveUp: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.move-up',
		defaultMessage: 'Move up',
		description:
			'Label for the dropdown item that allows the users to move the row up the table by one.',
	},
	moveDown: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.move-down',
		defaultMessage: 'Move down',
		description:
			'Label for the dropdown item that allows the users to move the row down the table by one.',
	},
});
