import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnWidthLabel: {
		id: 'native-issue-table.issue-table.header.header-cell.resize-popup.column-width-label',
		defaultMessage: 'Column width - {columnTitle}',
		description:
			'Label for the column width slider. The columnTitle is the name of the column being resized',
	},
	cancel: {
		id: 'native-issue-table.issue-table.header.header-cell.resize-popup.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the button that cancels the "resize" interaction and closes the popup',
	},
	save: {
		id: 'native-issue-table.issue-table.header.header-cell.resize-popup.save',
		defaultMessage: 'Save',
		description: 'Label for the button that saves the new column width',
	},
});
