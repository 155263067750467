import React, { useMemo, type PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { issueFetchQueryVariables_nativeIssueTable$key as ColumnsFragment } from '@atlassian/jira-relay/src/__generated__/issueFetchQueryVariables_nativeIssueTable.graphql';
import { IssueFetchQueryVariablesContainer } from '../../controllers/issue-fetch-query-variables/index.tsx';

type Props = {
	columns: ColumnsFragment;
};

/**
 * Set the field set IDs of the currently displayed table columns into `IssueCreateContextualFieldsData`.
 */
export const IssueFetchQueryVariables = ({ children, columns }: PropsWithChildren<Props>) => {
	const columnsData = useFragment<ColumnsFragment>(
		graphql`
			fragment issueFetchQueryVariables_nativeIssueTable on JiraIssueSearchFieldSetConnection {
				edges {
					node {
						fieldSetId
					}
				}
			}
		`,
		columns,
	);
	const fieldSetIds = useMemo(
		() => columnsData.edges?.map((edge) => edge?.node?.fieldSetId).filter(Boolean) ?? [],
		[columnsData],
	);

	return (
		<IssueFetchQueryVariablesContainer fieldSetIds={fieldSetIds}>
			{children}
		</IssueFetchQueryVariablesContainer>
	);
};
