/**
 * @generated SignedSource<<4ab7f0858672cb82645a3396e11f8fe0>>
 * @relayHash 0d9abbece612cf0b39d1b506df6ac887
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 88cc5b35279fc932bb73f89b79d29c75908b5950ef5010942a12dc207bfe4268

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraIssueTypeFilterInput = {
  maxHierarchyLevel?: number | null | undefined;
  minHierarchyLevel?: number | null | undefined;
};
export type issueTypeDropdownIssueType_projectDataQuery$variables = {
  cloudId: string;
  filter?: JiraIssueTypeFilterInput | null | undefined;
  hasProjectKey: boolean;
  projectKey: string;
};
export type issueTypeDropdownIssueType_projectDataQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey?: {
      readonly " $fragmentSpreads": FragmentRefs<"issueTypeDropdown_issueTableInlineIssueCreate">;
    };
  };
};
export type issueTypeDropdownIssueType_projectDataQuery = {
  response: issueTypeDropdownIssueType_projectDataQuery$data;
  variables: issueTypeDropdownIssueType_projectDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasProjectKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v5 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "name"
},
v8 = [
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueTypeDropdownIssueType_projectDataQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "hasProjectKey",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v4/*: any*/),
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "jiraProjectByKey",
                    "plural": false,
                    "selections": [
                      {
                        "args": [
                          (v5/*: any*/)
                        ],
                        "kind": "FragmentSpread",
                        "name": "issueTypeDropdown_issueTableInlineIssueCreate"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.jiraProjectByKey"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueTypeDropdownIssueType_projectDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "hasProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "projectId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  (v7/*: any*/),
                  {
                    "concreteType": "JiraAvatar",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "large"
                      }
                    ]
                  },
                  {
                    "args": (v8/*: any*/),
                    "concreteType": "JiraIssueTypeConnection",
                    "kind": "LinkedField",
                    "name": "issueTypes",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "issueTypeId"
                              },
                              (v7/*: any*/),
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "small"
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "level"
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              {
                                "alias": "value",
                                "kind": "ScalarField",
                                "name": "id"
                              },
                              {
                                "alias": "label",
                                "kind": "ScalarField",
                                "name": "name"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "args": (v8/*: any*/),
                    "filters": [
                      "filter"
                    ],
                    "handle": "connection",
                    "key": "inlineIssueCreate_IssueTable_issueTypes",
                    "kind": "LinkedHandle",
                    "name": "issueTypes"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "88cc5b35279fc932bb73f89b79d29c75908b5950ef5010942a12dc207bfe4268",
    "metadata": {},
    "name": "issueTypeDropdownIssueType_projectDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "30a657670a87487d2fd2c30abf17ebc7";

export default node;
