/**
 * @generated SignedSource<<533736002a141e9403872d1ccdba6e32>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueCreateFooter_nativeIssueTable$data = {
  readonly __id: string;
  readonly " $fragmentType": "issueCreateFooter_nativeIssueTable";
};
export type issueCreateFooter_nativeIssueTable$key = {
  readonly " $data"?: issueCreateFooter_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueCreateFooter_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueCreateFooter_nativeIssueTable",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "21882e8690a78f8a13d547c13b7e7a79";

export default node;
