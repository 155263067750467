// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer, type Action } from '@atlassian/react-sweet-state';
import { DENSITY_COMPACT, SORT_DESC } from '../../common/constants.tsx';
import type { State, Props } from './types.tsx';

const initialState: State = {
	density: DENSITY_COMPACT,
	isColumnResizingEnabled: true,
	isFixedTableLayoutEnabled: true,
	isInlineEditingEnabled: true,
	isInlineEditingExtendedFieldSupportEnabled: false,
	isIssueCreateEnabled: false,
	projectContext: null,
	isInfiniteScrollEnabled: false,
	isIssueRankingEnabled: false,
	isIssueRankDescending: false,
	isIssueHierarchyEnabled: false,
	isIssueOperationMeatballMenuEnabled: true,
	isRowReorderingEnabled: false,
	isEntireRowDraggable: false,
	isReparentingEnabled: false,
	isColumnBordersEnabled: true,
	isInlineFieldConfigEnabled: false,
	isInBetweenColumnPickerEnabled: false,
	isSingleLineRowHeightEnabled: false,
	isHideDoneItemsEnabled: false,
};

/**
 * Container to store the configuration state of various table features.
 * NOTE: Consider using `FeaturesContainer` from `./container.tsx` instead.
 * */
export const FeaturesContainerInternal = createContainer<Props>();

const updateStore: Action<State, Props> = (
	{ setState },
	{
		density,
		isColumnResizingEnabled,
		isInlineEditingEnabled,
		isIssueCreateEnabled,
		isInlineEditingExtendedFieldSupportEnabled,
		projectContext,
		isInfiniteScrollEnabled,
		isOrderedByRank,
		sortDirection,
		sortField,
		isIssueHierarchyEnabled,
		isIssueOperationMeatballMenuEnabled,
		isRowReorderingEnabled,
		isEntireRowDraggable,
		isReparentingEnabled,
		jql,
		isColumnBordersEnabled,
		isInlineFieldConfigEnabled,
		isInBetweenColumnPickerEnabled,
		isSingleLineRowHeightEnabled,
		isHideDoneItemsEnabled,
	},
) => {
	setState({
		density,
		isColumnResizingEnabled,
		isFixedTableLayoutEnabled: isColumnResizingEnabled,
		isInlineEditingEnabled,
		isInlineEditingExtendedFieldSupportEnabled,
		isIssueCreateEnabled: isIssueCreateEnabled && Boolean(projectContext),
		projectContext: projectContext ?? null,
		isInfiniteScrollEnabled,
		isIssueHierarchyEnabled: isIssueHierarchyEnabled && Boolean(projectContext),
		isIssueRankingEnabled: isOrderedByRank,
		isIssueRankDescending: sortDirection === SORT_DESC,
		isIssueOperationMeatballMenuEnabled,
		isRowReorderingEnabled,
		isEntireRowDraggable: isRowReorderingEnabled && Boolean(isEntireRowDraggable),
		isReparentingEnabled,
		sortDirection,
		sortField,
		jql,
		isColumnBordersEnabled,
		isInlineFieldConfigEnabled,
		isInBetweenColumnPickerEnabled,
		isSingleLineRowHeightEnabled,
		isHideDoneItemsEnabled,
	});
};

export const FeaturesStore = createStore({
	initialState,
	actions: {},
	containedBy: FeaturesContainerInternal,
	handlers: {
		onInit: () => updateStore,
		onContainerUpdate: () => updateStore,
	},
});
