import debounce from 'lodash/debounce';

export type ScheduleFn = () => void;
export type Scheduler = {
	schedule: (cb: ScheduleFn) => void;
	dispatch: () => void;
};

/**
 * Allow us to delay the DataLoader calls to not dispatch many during scrolling.
 * @param delay - Time to delay the call of the data loader
 * @returns {object} scheduler
 * @param {scheduler.schedule} - Callback to be pass to DataLoader.batchScheduleFn
 * @param {scheduler.dispatch} - Method to be called whenever we expect to dispatch the delayed data;
 */
export function createDataLoaderScheduler(delay: number): Scheduler {
	let cb: ScheduleFn[] = [];

	return {
		schedule(callback) {
			cb.push(callback);
		},
		dispatch: debounce(() => {
			cb.forEach((callback) => callback());
			cb = [];
		}, delay),
	};
}
