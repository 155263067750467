/**
 * @generated SignedSource<<3b6024a55d0f932f442c9b98e73b03a5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type rootConnectionRegister_nativeIssueTable_issues$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly __typename: "JiraIssueEdge";
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "rootConnectionRegister_nativeIssueTable_issues";
};
export type rootConnectionRegister_nativeIssueTable_issues$key = {
  readonly " $data"?: rootConnectionRegister_nativeIssueTable_issues$data;
  readonly " $fragmentSpreads": FragmentRefs<"rootConnectionRegister_nativeIssueTable_issues">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "rootConnectionRegister_nativeIssueTable_issues",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "5283567b68955e1a7394bdb870c16539";

export default node;
