export const ISSUE = 'issue';
export const FIELD = 'field';

export type Key = typeof ISSUE | typeof FIELD;

// We require namespacing so we can use edge indexes relative to their connection, e.g. when rendering grouped issue rows
export type Index = [namespace: string, edgeIndex: number];

export type State = {
	[ISSUE]: {
		index?: Index;
		isIndexLocked: boolean;
		timeoutId?: NodeJS.Timeout;
	};
	[FIELD]: {
		index?: number;
		isIndexLocked: boolean;
		timeoutId?: NodeJS.Timeout;
	};
};
