import React from 'react';
import { Stack, Text } from '@atlaskit/primitives';
import ErrorIcon from '@atlaskit/icon/core/error';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const ErrorFallback = ({ onClick }: { onClick: () => void }) => {
	const { formatMessage } = useIntl();

	return (
		<Stack alignInline="center" space="space.050">
			<ErrorIcon label="" />
			<Text as="strong">{formatMessage(messages.errorMessage)}</Text>
			<Button onClick={onClick} spacing="compact">
				{formatMessage(messages.retryMessage)}
			</Button>
		</Stack>
	);
};
