/**
 * @generated SignedSource<<cbfd986a9695ce8be5f34b186061834a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { UpdatableFragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type highlightIssueRow_update_isHighlightedIssueRow$data = {
  isHighlightedIssueRow: boolean | null | undefined;
  readonly " $fragmentType": "highlightIssueRow_update_isHighlightedIssueRow";
};
export type highlightIssueRow_update_isHighlightedIssueRow$key = {
  readonly " $data"?: highlightIssueRow_update_isHighlightedIssueRow$data;
  readonly $updatableFragmentSpreads: FragmentRefs<"highlightIssueRow_update_isHighlightedIssueRow">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "highlightIssueRow_update_isHighlightedIssueRow",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isHighlightedIssueRow"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "5d94f587c290893134bcec9a782eee62";

export default node;
