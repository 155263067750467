/**
 * @generated SignedSource<<3c561df0a0b0dd07c41d7c5cdcc83456>>
 * @relayHash 2159280563a44698c1c2db49b8035f42
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0147f25de377b28d9b8ae6b7a8787ea59b00b0b29846c2021e1d682441e67d18

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type realtimeUpdater_issueUpdated_Subscription$variables = {
  cloudId: string;
  projectId: string;
};
export type realtimeUpdater_issueUpdated_Subscription$data = {
  readonly jira: {
    readonly onIssueUpdatedByProject: {
      readonly key: string;
    } | null | undefined;
  } | null | undefined;
};
export type realtimeUpdater_issueUpdated_Subscription = {
  response: realtimeUpdater_issueUpdated_Subscription$data;
  variables: realtimeUpdater_issueUpdated_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "projectId",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "realtimeUpdater_issueUpdated_Subscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "onIssueUpdatedByProject",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "realtimeUpdater_issueUpdated_Subscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "onIssueUpdatedByProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0147f25de377b28d9b8ae6b7a8787ea59b00b0b29846c2021e1d682441e67d18",
    "metadata": {},
    "name": "realtimeUpdater_issueUpdated_Subscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "b09f570ca68eb9ae283a1b8c70f74655";

export default node;
