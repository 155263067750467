/**
 * @generated SignedSource<<7015fe40c096d2611995826fe9007308>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type richText_nativeIssueTable_RichTextCell_issueRef$data = {
  readonly canBeExported?: boolean | null | undefined;
  readonly " $fragmentType": "richText_nativeIssueTable_RichTextCell_issueRef";
};
export type richText_nativeIssueTable_RichTextCell_issueRef$key = {
  readonly " $data"?: richText_nativeIssueTable_RichTextCell_issueRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"richText_nativeIssueTable_RichTextCell_issueRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    }
  ],
  "kind": "Fragment",
  "name": "richText_nativeIssueTable_RichTextCell_issueRef",
  "selections": [
    {
      "condition": "isDensityFull",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canBeExported"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "6b89e8c15c1698f5f6af6c467470897f";

export default node;
