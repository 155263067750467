import React, { createContext, type ReactNode, useState, useMemo, useContext, useRef } from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { Text } from '@atlaskit/primitives';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { token, useThemeObserver } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useInlineCreateActiveIndexActions } from '../../controllers/inline-create-active-index/index.tsx';
import { FIELD } from '../../controllers/inline-create-active-index/types.tsx';
import configureFieldsLight from './assets/configure-fields-light.png';
import configureFieldsDark from './assets/configure-fields-dark.png';
import inlineFieldCreateOnboardingLight from './assets/inline-column-create-light.png';
import inlineFieldCreateOnboardingDark from './assets/inline-column-create-dark.png';
import messages from './messages.tsx';

export const INLINE_FIELD_CREATE_ONBOARDING_ENGAGEMENT_ID = 'issue-navigator-inline-field-create';
const STARGATE_URL = '/gateway/api';

type InlineFieldCreateOnboardingContextType = {
	showInlineFieldCreateOnboarding: boolean;
	setShowInlineFieldCreateOnboarding: (isMenuOpen: boolean) => void;
};

const InlineFieldCreateOnboardingContext =
	createContext<InlineFieldCreateOnboardingContextType | null>(null);

export const InlineFieldCreateOnboardingProvider = ({ children }: { children: ReactNode }) => {
	const [showInlineFieldCreateOnboarding, setShowInlineFieldCreateOnboarding] = useState(false);

	const value = useMemo(
		() => ({
			showInlineFieldCreateOnboarding,
			setShowInlineFieldCreateOnboarding,
		}),
		[showInlineFieldCreateOnboarding],
	);

	return (
		<InlineFieldCreateOnboardingContext.Provider value={value}>
			{children}
		</InlineFieldCreateOnboardingContext.Provider>
	);
};

export const useInlineFieldCreateOnboardingContext = () => {
	const inlineFieldCreateOnboardingContext = useContext(InlineFieldCreateOnboardingContext);

	if (!inlineFieldCreateOnboardingContext) {
		throw new Error(
			'useInlineFieldCreateOnboardingContext must be used within a InlineFieldCreateOnboardingProvider',
		);
	}

	return inlineFieldCreateOnboardingContext;
};

const ConfigureFieldsOnboardingComponent = ({
	setActiveSpotlight,
	isTMPAdmin,
}: {
	setActiveSpotlight: (index: number | null) => void;
	isTMPAdmin?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const { clearIndex } = useInlineCreateActiveIndexActions();
	const { colorMode } = useThemeObserver();
	const { setShowInlineFieldCreateOnboarding } = useInlineFieldCreateOnboardingContext();

	const actions = isTMPAdmin
		? [
				{
					text: formatMessage(messages.nextButtonText),
					onClick: () => {
						setActiveSpotlight(1);
						clearIndex(FIELD);
					},
				},
				{
					text: formatMessage(messages.createFieldDismiss),
					onClick: () => {
						setActiveSpotlight(null);
						setShowInlineFieldCreateOnboarding(false);
						clearIndex(FIELD);
					},
				},
			]
		: [
				{
					text: formatMessage(messages.createFieldDismiss),
					onClick: () => {
						setActiveSpotlight(null);
						setShowInlineFieldCreateOnboarding(false);
						clearIndex(FIELD);
					},
				},
			];

	return (
		<JiraSpotlight
			pulse
			messageId="native-issue-table.ui.inline-field-create-onboarding.jira-spotlight.inline-field-create-onboarding-column-picker-button.in-between"
			messageType="engagement"
			actions={actions}
			dialogWidth={320}
			target="inline-field-create-onboarding-column-picker-button.in-between"
			targetRadius={3}
			targetBgColor={token('elevation.surface')}
			image={
				colorMode === 'dark' ? inlineFieldCreateOnboardingDark : inlineFieldCreateOnboardingLight
			}
			actionsBeforeElement={isTMPAdmin ? '1/2' : undefined}
		>
			<Text>
				{formatMessage(messages.inlineColumnCreateBody, {
					b: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
				})}
			</Text>
		</JiraSpotlight>
	);
};

const InlineFieldCreateOnboardingComponent = ({
	setActiveSpotlight,
}: {
	setActiveSpotlight: (index: number | null) => void;
}) => {
	const { formatMessage } = useIntl();
	const { clearIndex } = useInlineCreateActiveIndexActions();
	const { colorMode } = useThemeObserver();
	const { setShowInlineFieldCreateOnboarding } = useInlineFieldCreateOnboardingContext();

	if (!colorMode) return null;

	return (
		<JiraSpotlight
			pulse
			messageId="native-issue-table.ui.inline-field-create-onboarding.jira-spotlight.inline-field-create-onboarding-column-picker-button.default"
			messageType="engagement"
			actions={[
				{
					text: formatMessage(messages.createFieldDismiss),
					onClick: () => {
						setActiveSpotlight(null);
						setShowInlineFieldCreateOnboarding(false);
						clearIndex(FIELD);
					},
				},
			]}
			dialogPlacement="bottom right"
			dialogWidth={320}
			target="inline-field-create-onboarding-column-picker-button.default"
			targetRadius={3}
			targetBgColor={token('elevation.surface')}
			image={colorMode === 'dark' ? configureFieldsDark : configureFieldsLight}
			actionsBeforeElement="2/2"
		>
			{formatMessage(messages.createFieldBody)}
		</JiraSpotlight>
	);
};

export const InlineFieldCreateOnboarding = () => {
	const { setShowInlineFieldCreateOnboarding } = useInlineFieldCreateOnboardingContext();
	const cloudId = useCloudId();
	const [activeSpotlight, setActiveSpotlight] = useState<null | number>(null);
	const isAdmin = useIsAdmin();
	const isTMP = useIsSimplifiedProject();
	const isTMPAdmin = isAdmin && isTMP;

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, '', 'engagement'),
		[cloudId],
	);

	const [isEnrolled, stopEnrolment] = useCoordination(
		coordinationClient,
		INLINE_FIELD_CREATE_ONBOARDING_ENGAGEMENT_ID,
	);

	const hasShownOnboarding = useRef(false);

	if (isEnrolled && !hasShownOnboarding.current) {
		setShowInlineFieldCreateOnboarding(true);
		stopEnrolment(false);
		setActiveSpotlight(0);
		hasShownOnboarding.current = true;
	}

	const renderActiveSpotlight = () => {
		const spotlights = isTMPAdmin
			? [
					<ConfigureFieldsOnboardingComponent
						key="configure-fields"
						setActiveSpotlight={setActiveSpotlight}
						isTMPAdmin
					/>,
					<InlineFieldCreateOnboardingComponent
						key="inline-field-create"
						setActiveSpotlight={setActiveSpotlight}
					/>,
				]
			: [
					<ConfigureFieldsOnboardingComponent
						key="configure-fields"
						setActiveSpotlight={setActiveSpotlight}
					/>,
				];
		if (activeSpotlight === null) {
			return null;
		}

		return spotlights[activeSpotlight];
	};

	return (
		<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
			<SpotlightTransition>
				{isEnrolled && activeSpotlight !== null && renderActiveSpotlight()}
			</SpotlightTransition>
		</ChoreographerContextProvider>
	);
};
