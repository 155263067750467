import type React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { useCellNavigationActions, useIsKeyBoardNavOn } from './store/index.tsx';

type useCellNavigationProps = {
	rowCount: number;
	colCount: number;
};

const TabbableSelector = `a[href],
	button,
	input:not([type="hidden"]),
	[tabindex]:not([tabindex="-1"])`;

const TableSiblingSelector = `a[href]:not(table a[href]), 
	button:not(table button), 
	input:not([type="hidden"]):not(table input:not([type="hidden"])), 
	[tabindex]:not([tabindex="-1"]):not(table [tabindex]:not([tabindex="-1"])), 
	table`;

// This implements keyboard navigation as described in the APG grid pattern https://www.w3.org/WAI/ARIA/apg/patterns/grid/
export const useTableGridNavigation = ({ rowCount, colCount }: useCellNavigationProps) => {
	const isKeyboardNavOn = useIsKeyBoardNavOn();
	const { setIsKeyboardNavOn, moveColumnRight, moveRowUp, moveColumnLeft, moveRowDown } =
		useCellNavigationActions();

	const cellNavigationRef = useRef<HTMLTableElement>(null);

	const disableTabbing = useCallback(() => {
		// disable clickable elements in the table when it gets focus
		const elements = cellNavigationRef.current?.querySelectorAll(TabbableSelector);
		if (elements) {
			elements.forEach((element) => {
				element.setAttribute('tabindex', '-1');
			});
		}
	}, []);

	const onKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			if (isKeyboardNavOn) {
				switch (e.key) {
					case 'Tab':
						// if keyboard nav is on and user tabs into an element within the table,
						// hijack the tab event and send focus to header/footer element.
						// also disables all elements in the table
						if (e.target instanceof HTMLElement && cellNavigationRef.current?.contains(e.target)) {
							e.preventDefault();
							disableTabbing();

							// we want to get the element before and after the table
							// so we can redirect any Tabs pressed by the user when in table
							const tableSiblingElements =
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								document.querySelectorAll(TableSiblingSelector);
							const tableIndex = Array.from(tableSiblingElements).findIndex(
								(el) => cellNavigationRef.current === el,
							);

							if (tableIndex > -1) {
								if (e.shiftKey) {
									const beforeElement = tableSiblingElements[tableIndex - 1];
									beforeElement instanceof HTMLElement && beforeElement.focus();
								} else {
									const afterElement = tableSiblingElements[tableIndex + 1];
									afterElement instanceof HTMLElement && afterElement.focus();
								}
							}
						}
						break;
					case 'ArrowRight':
						moveColumnRight(1, colCount - 1);
						break;
					case 'ArrowLeft':
						moveColumnLeft(1, 0);
						break;
					case 'ArrowDown':
						moveRowDown(1, rowCount - 1);
						break;
					case 'ArrowUp':
						moveRowUp(1, 0);
						break;
					default:
					// do nothing
				}
			}
		},
		[
			isKeyboardNavOn,
			moveColumnRight,
			colCount,
			moveColumnLeft,
			moveRowDown,
			rowCount,
			moveRowUp,
			disableTabbing,
		],
	);

	const onFocus = useCallback(
		(e: React.FocusEvent) => {
			// we are checking this to make sure that the focus event is triggered in the navigation wrapper and is not a focus that bubbled up
			// this means that the user has tabbed into the table so we should turn the grid nav on
			if (e.target === e.currentTarget) {
				if (cellNavigationRef.current != null) {
					disableTabbing();
				}

				setIsKeyboardNavOn(true);
			}
		},
		[disableTabbing, setIsKeyboardNavOn],
	);

	const onBlur = useCallback(
		(e: React.FocusEvent) => {
			if (
				e.relatedTarget instanceof Element &&
				!cellNavigationRef.current?.contains(e.relatedTarget)
			) {
				// if user has tabbed out of the table, turn keyboard nav off
				setIsKeyboardNavOn(false);
			}
		},
		[setIsKeyboardNavOn],
	);

	const tabIndex = useMemo(() => (isKeyboardNavOn ? -1 : 0), [isKeyboardNavOn]);

	return {
		cellNavigationRef,
		onFocus,
		onBlur,
		tabIndex,
		onKeyDown,
	};
};
