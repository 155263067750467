import React, { useMemo, useCallback, type PropsWithChildren, useRef } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { selectedRowState_nativeIssueTable_SelectedRowContainer$key as IssuesKey } from '@atlassian/jira-relay/src/__generated__/selectedRowState_nativeIssueTable_SelectedRowContainer.graphql';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { useIssueTableSelectedIssuesActions } from '../selected-issues/index.tsx';
import actions from './actions.tsx';
import type { Actions, Props, State } from './types.tsx';

const initialState: State = {
	selectedRowIndex: null,
	amountOfRows: 0,
	focusedRowIndex: null,
};

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'selectedRowState',
});

// Exported for testing
export const Container = createContainer<State, Actions, Props>(Store, {
	onInit:
		() =>
		({ setState }, { selectedRowIndex = null, amountOfRows = 0 }) => {
			setState({ selectedRowIndex, amountOfRows });
		},
	onUpdate:
		() =>
		({ setState, getState }, { selectedRowIndex: newRowIndex, amountOfRows: newAmountOfRows }) => {
			const { selectedRowIndex, amountOfRows } = getState();

			selectedRowIndex !== newRowIndex && setState({ selectedRowIndex: newRowIndex ?? null });
			amountOfRows !== newAmountOfRows && setState({ amountOfRows: newAmountOfRows ?? 0 });
		},
});

export type SelectedRowContainerProps = PropsWithChildren<{
	/**
	 * Index of the row to select or `null` if no row should be selected.
	 */
	selectedRowIndex?: number | null;
	/**
	 * Total number of rows in the current page of issues.
	 */
	amountOfRows?: number;
	/**
	 * Callback invoked when the selected row has changed.
	 */
	onRowChange?: (rowIndex: number) => void;
	/**
	 * Relay fragment key for a connection of issues.
	 */
	issues: IssuesKey | null;
	/**
	 * Callback invoked when a user action results in a need to navigate to a different page of issues. This callback
	 * will be invoked with the cursor to paginate to, and whether last issue in the result set should be selected (for
	 * example, if the user is trying to navigate from the first issue in a page to the last issue in the previous page).
	 */
	onPageChange?: (cursor: string, shouldSelectLastIssue: boolean) => void;
}>;

// TODO: HRZ-1652 Handle Selected rows with grouping
export const SelectedRowContainer = ({
	issues,
	onPageChange,
	...props
}: SelectedRowContainerProps) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<IssuesKey>(
		graphql`
			fragment selectedRowState_nativeIssueTable_SelectedRowContainer on JiraIssueConnection
			@argumentDefinitions(pageSize: { type: "Int" }) {
				pageCursors(maxCursors: 7, pageSize: $pageSize) {
					around {
						cursor
						isCurrent
					}
				}
				edges {
					node {
						issueId
					}
				}
			}
		`,
		issues,
	);

	const { insertSelectedIssueIds, removeSelectedIssueIds } = expVal(
		'bulk_operations_in_nin_experiment',
		'isEnabled',
		false,
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssueTableSelectedIssuesActions()
		: { insertSelectedIssueIds: noop, removeSelectedIssueIds: noop };

	const lastSelectedRow = useRef<number | null>(null);

	const cursors = data?.pageCursors?.around;
	const currentPageIndex = useMemo(
		() => cursors?.findIndex((cursor) => cursor?.isCurrent) ?? -1,
		[cursors],
	);

	const onNextPage = useCallback(() => {
		if (onPageChange && currentPageIndex > -1) {
			const nextPage = cursors?.[currentPageIndex + 1];
			if (nextPage && nextPage.cursor != null) {
				onPageChange(nextPage.cursor, false);
			}
		}
	}, [currentPageIndex, cursors, onPageChange]);

	const onPreviousPage = useCallback(() => {
		if (onPageChange && currentPageIndex > 0) {
			const previousPage = cursors?.[currentPageIndex - 1];
			if (previousPage && previousPage.cursor != null) {
				onPageChange(previousPage.cursor, true);
			}
		}
	}, [currentPageIndex, cursors, onPageChange]);

	const onCheckRow = expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback<Exclude<Props['onCheckRow'], undefined>>(
				(rowIndex, isChecked, isShiftSelected) => {
					const initialIssueId = data?.edges?.[rowIndex]?.node?.issueId;
					if (!initialIssueId) return;

					const selectedIssueIds = [initialIssueId];
					if (isShiftSelected && lastSelectedRow.current !== null) {
						const start = Math.min(lastSelectedRow.current, rowIndex);
						const end = Math.max(lastSelectedRow.current, rowIndex);
						for (let i = start; i <= end; i++) {
							const currentIssueId = data?.edges?.[i]?.node?.issueId;
							if (i !== rowIndex && currentIssueId) {
								selectedIssueIds.push(currentIssueId);
							}
						}
					}

					lastSelectedRow.current = rowIndex;
					if (isChecked) {
						insertSelectedIssueIds(selectedIssueIds);
					} else {
						removeSelectedIssueIds(selectedIssueIds);
					}
				},
				[data?.edges, insertSelectedIssueIds, removeSelectedIssueIds],
			)
		: undefined;

	return (
		<Container
			{...props}
			onNextPage={onNextPage}
			onPreviousPage={onPreviousPage}
			{...(expVal('bulk_operations_in_nin_experiment', 'isEnabled', false) && { onCheckRow })}
		/>
	);
};

export const useSelectedRow = createHook(Store, {
	selector: (state) => state.selectedRowIndex,
});

export const useIsSelected = createHook<State, Actions, boolean, { selectedRowIndex: number }>(
	Store,
	{
		selector: (state, { selectedRowIndex }) => state.selectedRowIndex === selectedRowIndex,
	},
);

export const useIsFocused = createHook<State, Actions, boolean, { selectedRowIndex: number }>(
	Store,
	{
		selector: (state, { selectedRowIndex }) => state.focusedRowIndex === selectedRowIndex,
	},
);

// TODO Delete when cleaning bulk_operations_in_nin_experiment and jsc_m2_hierarchy_fe_changes
export const useSelectedRowActions = createActionsHook(Store);

// Only for testing used
export const useAmountOfRowsActions = createHook(Store, {
	selector: (state) => state.amountOfRows,
});
