import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type { IssueCreateErrorHandler, MutationError } from '../../common/types.tsx';
import { useFallbackToGIC } from '../use-fallback-to-gic/index.tsx';
import messages from './messages.tsx';

const isMutationValidationError = (errors: ReadonlyArray<MutationError>) =>
	errors.some((error) => error.extensions?.statusCode === 400);

const toError = (errors: ReadonlyArray<MutationError>) => {
	const statusCodes = new Set<string>();
	errors.forEach((err) => {
		const sc = err.extensions?.statusCode;
		if (sc) {
			statusCodes.add(String(sc));
		}
	});
	return new Error(
		`Fail to fallback to GIC extension status codes: ${statusCodes.size > 0 ? Array.from(statusCodes.values()).join(',') : 'No status'}`,
	);
};

/**
 * Callback to handle network or GraphQL errors that occur during an issue create mutation. If a validation error has
 * occurred, i.e. 400, then this will trigger the global issue create modal as a fallback to allow the users to recover.
 * This returns a promise that will resolve with the issue key of the newly created issue, or `null` if no issue was
 * created.
 */
export const useIssueCreateOnError = (): IssueCreateErrorHandler => {
	const onFallbackToGIC = useFallbackToGIC();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();

	return useCallback<IssueCreateErrorHandler>(
		({ issueToCreate, contextualFields, error, onFail }): Promise<string | null> => {
			let shouldFallbackToGIC;
			if (error instanceof Error) {
				shouldFallbackToGIC = error instanceof ValidationError;
			} else {
				shouldFallbackToGIC = isMutationValidationError(error);
			}

			if (shouldFallbackToGIC) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'globalIssueCreate fallback',
					'inlineIssueCreate',
				);
				return onFallbackToGIC({ issueToCreate, contextualFields });
			}

			// Fire error analytics event and display flag if we were unable to fallback to GIC
			fireErrorAnalytics({
				event: createAnalyticsEvent({}),
				meta: {
					id: 'inlineIssueCreate',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error: error instanceof Error ? error : undefined,
				sendToPrivacyUnsafeSplunk: true,
			});

			showFlag({
				type: 'error',
				title: formatMessage(messages.errorFlagTitleNonFinal),
				description: formatMessage(messages.errorFlagDescriptionNonFinal),
				messageId:
					'issue-table-inline-issue-create.controllers.use-issue-create-on-error.show-flag.error',
				messageType: 'transactional',
			});
			onFail?.(error instanceof Error ? error : toError(error));

			return Promise.resolve(null);
		},
		[createAnalyticsEvent, formatMessage, onFallbackToGIC, showFlag],
	);
};
