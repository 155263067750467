/**
 * @generated SignedSource<<e66798db69b7174dc3e67181cb94bce4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView$data = {
  readonly selectedOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly value: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView";
};
export type checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView$key = {
  readonly " $data"?: checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView",
  "selections": [
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "value"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraCheckboxesField"
};

(node as any).hash = "d4b0ebe1c72d70b5bd1191947a37e196";

export default node;
