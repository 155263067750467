import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { FormPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { useIsItemExpanded, useHierarchyActions } from '../../controllers/hierarchy/index.tsx';
import type { ConnectionMetadata } from '../../controllers/connections-list/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import messages from './messages.tsx';

const PACKAGE_LOCATION = 'jira-issue-table-hierarchy-child-rows';
export const FLAG_ID = 'issue-table-child-rows-entrypoint-container';

type BaseProps = {
	parentItemId: string;
	itemsConnectionId: string;
	depth: number;
	parentIssueAri?: string;
	parentItemConnectionId?: string;
	isFormVisible?: boolean;
	formProps?: FormPropsType;
	projectKey?: string;
	isGroupingSupported?: boolean;
};

type AdditionalProps = {
	groupId?: string;
	childItemsExperience: string;
	childItemsLoadMoreExperience: string;
	analyticsSource: string;
	renderChildRowsLoader: RenderChildRowsLoader;
};

interface ChildRowProps extends BaseProps {
	type: 'PARENT_CHILDREN';
	issueKey: string;
}

interface GroupRowProps extends BaseProps {
	type: 'GROUP_CHILDREN';
	groupFieldValue: string | null;
	groupJql: string;
}

type Props = BaseProps & AdditionalProps & (ChildRowProps | GroupRowProps);

type RenderChildRowsLoader = (
	props: Props & { connectionMetadata?: ConnectionMetadata },
) => JSX.Element;

export const ChildRows = memo((props: Props) => {
	const [isExpanded] = useIsItemExpanded({
		itemId: props.parentItemId,
		itemsConnectionId: props.itemsConnectionId,
		groupId: props.groupId,
	});

	if (!isExpanded) {
		return null;
	}

	return <ChildRowsInner {...props} />;
});

const ChildRowsInner = (props: Props) => {
	const {
		parentItemId,
		itemsConnectionId,
		type,
		parentItemConnectionId,
		depth,
		groupId,
		renderChildRowsLoader,
	} = props;

	const onExperienceStart = useExperienceStart({
		experience: props.childItemsExperience,
		analyticsSource: props.analyticsSource,
	});

	const onExperienceFail = useExperienceFail({
		experience: props.childItemsExperience,
		attributes: {},
	});

	useEffect(() => {
		onExperienceStart();
	}, [onExperienceStart]);

	const { setIsItemExpanded } = useHierarchyActions();
	const { showFlag, hasFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const handleError = useCallback(
		(error: Error) => {
			setIsItemExpanded(
				{
					itemId: parentItemId,
					itemsConnectionId,
					groupId,
				},
				false,
			);
			if (!hasFlag?.(FLAG_ID)) {
				const isGrouping = groupId && depth === 0;

				showFlag({
					id: FLAG_ID,
					title: formatMessage(
						isGrouping ? messages.errorFlagGroupingTitle : messages.errorFlagTitle,
					),
					description: formatMessage(
						isGrouping ? messages.errorFlagGroupingDescription : messages.errorFlagDescription,
					),
					type: 'error',
					messageId: `issue-table-hierarchy.ui.child-rows.showFlag.${FLAG_ID}`,
					messageType: 'transactional',
				});
			}

			fireErrorAnalytics({
				meta: {
					id: 'issue-table-child-rows-container',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			onExperienceFail(PACKAGE_LOCATION, error);
			return null;
		},
		[
			formatMessage,
			hasFlag,
			itemsConnectionId,
			onExperienceFail,
			parentItemId,
			setIsItemExpanded,
			showFlag,
			depth,
			groupId,
		],
	);

	const connectionMetadata: ConnectionMetadata | undefined = useMemo(() => {
		if (type === 'PARENT_CHILDREN' && parentItemConnectionId) {
			return {
				type,
				parentRelayId: parentItemConnectionId,
				parentId: parentItemId,
			};
		}
		if (type === 'GROUP_CHILDREN' && groupId) {
			return {
				type,
				fieldValue: props.groupFieldValue,
				groupId,
				jql: props.groupJql,
			};
		}
		return undefined;
	}, [type, parentItemConnectionId, groupId, props, parentItemId]);

	return (
		<JSErrorBoundary
			id={PACKAGE_LOCATION}
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			onError={(_, error) => handleError(error)}
		>
			{renderChildRowsLoader({ ...props, connectionMetadata })}
		</JSErrorBoundary>
	);
};
