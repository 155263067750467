import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import {
	COLUMN_TYPE_DATE_PICKER,
	COLUMN_TYPE_SATISFACTION_FEEDBACK,
	COLUMN_TYPE_SENTIMENT,
	KNOWN_COLUMN_TYPES,
	MAXIMUM_COLUMN_WIDTH,
	MINIMUM_COLUMN_WIDTH,
	MINIMUM_COLUMN_WIDTH_COMPACT,
	MINIMUM_DATEPICKER_COLUMN_WIDTH,
	MINIMUM_DATETIME_COLUMN_WIDTH,
	MINIMUM_MULTI_USER_SELECT_COLUMN_WIDTH,
	MINIMUM_SATISFACTION_COLUMN_WIDTH,
	MINIMUM_SENTIMENT_COLUMN_WIDTH,
} from '../../../../common/constants.tsx';

const CSS_RESIZING_WIDTH_PROPERTY = '--local-resizing-width';

export const setCssResizingWidth = (container: HTMLElement | null, width: number) => {
	container?.style.setProperty(CSS_RESIZING_WIDTH_PROPERTY, `${width}px`);
};
export const clearCssResizingWidth = (container: HTMLElement | null) => {
	container?.style.removeProperty(CSS_RESIZING_WIDTH_PROPERTY);
};

export const disableCssPointerEvents = (container: HTMLElement | null | undefined) => {
	container?.style.setProperty('pointer-events', 'none');
};
export const enableCssPointerEvents = (container: HTMLElement | null | undefined) => {
	container?.style.removeProperty('pointer-events');
};

export const ensureWidthWithinLimits = (width: number, isCompactColumnEnabled?: boolean) => {
	return Math.min(
		Math.max(isCompactColumnEnabled ? MINIMUM_COLUMN_WIDTH_COMPACT : MINIMUM_COLUMN_WIDTH, width),
		MAXIMUM_COLUMN_WIDTH,
	);
};

export const getProposedWidth = ({
	initialWidth,
	isCompactColumnEnabled,
	location,
}: {
	initialWidth: number;
	isCompactColumnEnabled?: boolean;
	location: DragLocationHistory;
}): number => {
	const diffX = location.current.input.clientX - location.initial.input.clientX;
	const proposedWidth = initialWidth + diffX;

	// ensure we don't go below the min allowed width
	return ensureWidthWithinLimits(proposedWidth, isCompactColumnEnabled);
};

// Check if the column needs minimum width
export const isMinimumWidthColumn = (columnType: string) => {
	const columnsWithMinWidth = new Set([
		KNOWN_COLUMN_TYPES.DATE_TIME,
		KNOWN_COLUMN_TYPES.MULTI_USER_PICKER,
		KNOWN_COLUMN_TYPES.DUE_DATE,
		KNOWN_COLUMN_TYPES.CREATED,
		KNOWN_COLUMN_TYPES.UPDATED,
		COLUMN_TYPE_DATE_PICKER,
		COLUMN_TYPE_SATISFACTION_FEEDBACK,
		COLUMN_TYPE_SENTIMENT,
	]);

	return columnsWithMinWidth.has(columnType);
};

// Get the minimum width of the column
export const getMinimumColumnWidth = (columnType: string) => {
	switch (columnType) {
		case KNOWN_COLUMN_TYPES.DATE_TIME:
		case KNOWN_COLUMN_TYPES.CREATED:
		case KNOWN_COLUMN_TYPES.UPDATED:
			return MINIMUM_DATETIME_COLUMN_WIDTH;

		case KNOWN_COLUMN_TYPES.MULTI_USER_PICKER:
			return MINIMUM_MULTI_USER_SELECT_COLUMN_WIDTH;

		case COLUMN_TYPE_DATE_PICKER:
		case KNOWN_COLUMN_TYPES.DUE_DATE:
			return MINIMUM_DATEPICKER_COLUMN_WIDTH;

		case COLUMN_TYPE_SATISFACTION_FEEDBACK:
			return MINIMUM_SATISFACTION_COLUMN_WIDTH;

		case COLUMN_TYPE_SENTIMENT:
			return MINIMUM_SENTIMENT_COLUMN_WIDTH;

		default:
			// Return the smallest possible minimum width
			return MINIMUM_COLUMN_WIDTH_COMPACT;
	}
};

// Get the minimum width of the column given a proposed width
export const getMinimumColumnProposedWidth = (columnType: string, propsedWidth: number) => {
	if (!isMinimumWidthColumn(columnType)) {
		return propsedWidth;
	}
	return Math.max(propsedWidth, getMinimumColumnWidth(columnType));
};

// Check if minimum width has been reached
export const isMinimumWidth = (columnType: string, width: number) => {
	return isMinimumWidthColumn(columnType) && width < getMinimumColumnWidth(columnType);
};
