import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import type { PopupComponentProps } from '@atlaskit/popup';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

// Workaround for https://github.com/atlassian-labs/compiled/issues/1200
const layerValue = layers.layer;

/**
 * Styling the container itself removes the need for an additional nested `<div>`,
 * containing our custom styles, in the Popup content. Having an extra `<div>`
 * introduces problems with autofocus when popup is opened.
 *
 * Unfortunately the base PopupContainer styles are not exported from atlaskit,
 * so they had to be copy + pasted and extended with our custom styles.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPopupContainer = styled.div<PopupComponentProps>({
	/*
	 * Default styles based on:
	 * `atlassian-frontend/packages/design-system/popup/src/popper-wrapper.tsx`
	 */
	display: 'block',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layerValue(),
	flex: '1 1 auto',
	backgroundColor: token('elevation.surface.overlay'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	boxShadow: token('elevation.shadow.overlay'),
	overflow: 'auto',
	'&:focus': {
		outline: 'none',
	},
	/*
	 * custom styles
	 */
	width: '500px',
	maxHeight: '400px',
	minHeight: '50px', // prevent flashing scroll bar when spinner is visible
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.focused')}`,
});

export const PopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>((props, ref) => (
	<StyledPopupContainer {...props} ref={ref} />
));
