import {
	useEffect,
	useMemo,
	createContext,
	useContext,
	useState,
	useRef,
	useCallback,
} from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { Virtualizer } from '@tanstack/virtual-core';
import { fg } from '@atlassian/jira-feature-gating';

type Virtualization = Virtualizer<HTMLElement, Element>;

/**
 * Subset of virtualizer props, for easy simulate for SSR
 */
export type BaseServerVirtualizer = Pick<Virtualization, 'getTotalSize' | 'getVirtualItems'>;

export type ServerVirtualizer = Pick<
	Virtualization,
	'getTotalSize' | 'measureElement' | 'getVirtualItems'
> & {
	options: Pick<Virtualization['options'], 'scrollMargin'>;
	isScrolling: boolean;
};

// TODO: Cleanup with jsc_virtualizer_rss_store
const VirtualizationContext = createContext<ServerVirtualizer | null>(null);

VirtualizationContext.displayName = 'VirtualizationContext';

/**
 * Returns virtualizer object used to virtualize rows in the issue table.
 * TODO: Cleanup with jsc_virtualizer_rss_store
 *
 * @returns can be `null` if infinite scroll is not enabled
 */
function useVirtualization(): ServerVirtualizer | null {
	const virtualizer = useContext(VirtualizationContext);

	return virtualizer;
}

// TODO: Cleanup with jsc_virtualizer_rss_store
const { Provider } = VirtualizationContext;

// TODO: Cleanup with jsc_virtualizer_rss_store
/**
 * @deprecated
 */
export { Provider as VirtualizationProvider, useVirtualization };

type UseVirtualizerArgs = Parameters<typeof useVirtualizer<HTMLElement, Element>>[0];

/**
 * Create a virtualizer with an specific number of items to be returned when SSR
 * @param options
 * @returns
 */
export const createServerVirtualizer = (
	options: Pick<UseVirtualizerArgs, 'count' | 'estimateSize' | 'getItemKey' | 'scrollMargin'>,
): BaseServerVirtualizer => {
	const { count, estimateSize, getItemKey } = options;
	const totalSize = count * estimateSize(0);
	let start = 0;
	const items = Array.from({ length: count }).map((_, i) => {
		const end = start + estimateSize(i);
		const item = {
			index: i,
			key: getItemKey?.(i) ?? i,
			start,
			end,
			lane: 0,
			size: estimateSize(i),
		};
		start = end;
		return item;
	});

	return {
		getTotalSize() {
			return totalSize;
		},
		getVirtualItems() {
			return items;
		},
	};
};

const dummyVirtualizer: BaseServerVirtualizer = {
	getTotalSize() {
		return 0;
	},
	getVirtualItems() {
		return [];
	},
};

const useSSRSafeVirtualizerOld = (options: UseVirtualizerArgs): ServerVirtualizer => {
	const { getScrollElement, count, estimateSize, getItemKey } = options;
	const hasScrolled = useRef(false);

	const shouldUseSSRVirtualizer = useCallback(() => {
		return __SERVER__ || !hasScrolled.current;
	}, []);

	const serverVirtualizer = useMemo(() => {
		if (shouldUseSSRVirtualizer()) {
			// only calculated when is going to be used
			return createServerVirtualizer({ count, estimateSize, getItemKey });
		}
		return dummyVirtualizer;
	}, [count, estimateSize, getItemKey, shouldUseSSRVirtualizer]);
	const originalVirtualizer = useVirtualizer(options);

	const isScrolling = fg('jsc_m2_hierarchy_fe_changes') ? originalVirtualizer.isScrolling : false;
	const isScrollingRef = useRef(isScrolling);

	/**
	 * We swap to real virtualization when we detect that the user has initiated a scroll
	 */
	useEffect(() => {
		if (hasScrolled.current) {
			return;
		}

		const element = getScrollElement();

		const onScroll = () => {
			hasScrolled.current = true;
			if (fg('jsc_list_reparenting')) {
				isScrollingRef.current = true;
			}
			element?.removeEventListener('scroll', onScroll);
		};
		const onScrollEnd = () => {
			isScrollingRef.current = false;
		};

		element?.addEventListener('scroll', onScroll);
		if (fg('jsc_list_reparenting')) {
			element?.addEventListener('scrollend', onScrollEnd);
		}
		return () => {
			element?.removeEventListener('scroll', onScroll);
			if (fg('jsc_list_reparenting')) {
				element?.removeEventListener('scrollend', onScrollEnd);
			}
		};
	}, [getScrollElement]);

	/**
	 * We overwrite the methods to render the items and calculate size
	 * to assure that SSR renders the same as the frontend on the initial
	 * render
	 */
	return useMemo<ServerVirtualizer>(() => {
		return {
			...originalVirtualizer,
			isScrolling: fg('jsc_list_reparenting') ? isScrollingRef.current : isScrolling,
			getVirtualItems: () => {
				if (shouldUseSSRVirtualizer()) {
					return serverVirtualizer.getVirtualItems();
				}
				return originalVirtualizer.getVirtualItems();
			},
			getTotalSize: () => {
				if (shouldUseSSRVirtualizer()) {
					return serverVirtualizer.getTotalSize();
				}
				return originalVirtualizer.getTotalSize();
			},
		};
	}, [
		isScrolling,
		originalVirtualizer,
		serverVirtualizer,
		shouldUseSSRVirtualizer,
		isScrollingRef,
	]);
};

const useSSRSafeVirtualizerNew = (options: UseVirtualizerArgs): ServerVirtualizer => {
	const { getScrollElement, count, estimateSize, getItemKey } = options;
	const [shouldUseSSRVirtualizer, setShouldUseSSRVirtualizer] = useState(true);

	const serverVirtualizer = useMemo(() => {
		if (shouldUseSSRVirtualizer) {
			// only calculated when is going to be used
			return createServerVirtualizer({ count, estimateSize, getItemKey });
		}
		return dummyVirtualizer;
	}, [count, estimateSize, getItemKey, shouldUseSSRVirtualizer]);

	const originalVirtualizer: Virtualization = useVirtualizer<HTMLElement, Element>(options);

	/**
	 * We swap to real virtualization when we detect that the user has initiated a scroll
	 */
	useEffect(() => {
		if (!shouldUseSSRVirtualizer) {
			return;
		}

		const element = getScrollElement();

		const onScroll = () => {
			if (!__SERVER__) {
				setShouldUseSSRVirtualizer(false);
				element?.removeEventListener('scroll', onScroll);
			}
		};
		element?.addEventListener('scroll', onScroll);
		return () => {
			element?.removeEventListener('scroll', onScroll);
		};
	}, [shouldUseSSRVirtualizer, getScrollElement]);

	/**
	 * We overwrite the methods to render the items and calculate size
	 * to assure that SSR renders the same as the frontend on the initial
	 * render
	 */
	return useMemo<ServerVirtualizer>(() => {
		if (shouldUseSSRVirtualizer) {
			return {
				...originalVirtualizer,
				...serverVirtualizer,
			};
		}

		// Ensure we return a stable reference between renders when we stop using the SSR virtualizer
		return originalVirtualizer;
	}, [originalVirtualizer, serverVirtualizer, shouldUseSSRVirtualizer]);
};

export const useSSRSafeVirtualizer = (options: UseVirtualizerArgs) =>
	fg('empanada_nin_concurrent_mode_fixes')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSSRSafeVirtualizerNew(options)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useSSRSafeVirtualizerOld(options);
