import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Lozenge from '@atlaskit/lozenge';
import {
	DONE,
	IN_PROGRESS,
	statusCategoryForId,
	TODO,
	UNDEFINED,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { statusGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/statusGroupHeader_nativeIssueTable.graphql';

export type Props = {
	statusGroupHeader: statusGroupHeader_nativeIssueTable$key | null;
};

const appearanceMap = {
	[UNDEFINED]: 'default',
	[TODO]: 'default',
	[DONE]: 'success',
	[IN_PROGRESS]: 'inprogress',
} as const;

export const StatusGroupHeader = ({ statusGroupHeader }: Props) => {
	const data = useFragment<statusGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment statusGroupHeader_nativeIssueTable on JiraJqlStatusFieldValue {
				displayName
				statusCategory {
					statusCategoryId
				}
			}
		`,
		statusGroupHeader,
	);

	const appearance =
		data?.statusCategory.statusCategoryId != null
			? appearanceMap[statusCategoryForId(data?.statusCategory.statusCategoryId)]
			: appearanceMap[UNDEFINED];

	return <Lozenge appearance={appearance}>{data?.displayName}</Lozenge>;
};
