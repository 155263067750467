/**
 * @generated SignedSource<<c059ca3e1a8016cb39a15ecf80404d3e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow$data = {
  readonly fieldValue: {
    readonly __typename: "JiraJqlGoalsFieldValue";
    readonly goal: {
      readonly id: string;
    };
  } | {
    readonly __typename: "JiraJqlNumberFieldValue";
    readonly number: number | null | undefined;
  } | {
    readonly __typename: "JiraJqlOptionFieldValue";
    readonly option: {
      readonly optionId: string;
    } | null | undefined;
  } | {
    readonly __typename: "JiraJqlPriorityFieldValue";
    readonly priority: {
      readonly priorityId: string;
    };
  } | {
    readonly __typename: "JiraJqlSprintFieldValue";
    readonly sprint: {
      readonly sprintId: string;
    };
  } | {
    readonly __typename: "JiraJqlStatusFieldValue";
    readonly status: {
      readonly id: string;
    } | null | undefined;
  } | {
    readonly __typename: "JiraJqlUserFieldValue";
    readonly user: {
      readonly accountId: string;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly id: string;
  readonly issues: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly __typename: "JiraIssueEdge";
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"hierarchy_nativeIssueTable_ChildRowsPagination_queryData">;
  } | null | undefined;
  readonly jql: string;
  readonly " $fragmentSpreads": FragmentRefs<"groupHeader_nativeIssueTable">;
  readonly " $fragmentType": "groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow";
};
export type groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow$key = {
  readonly " $data"?: groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "issues"
        ]
      }
    ]
  },
  "name": "groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "jql"
      },
      "action": "THROW",
      "path": "jql"
    },
    {
      "kind": "LinkedField",
      "name": "fieldValue",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraPriority",
              "kind": "LinkedField",
              "name": "priority",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "priorityId"
                }
              ]
            }
          ],
          "type": "JiraJqlPriorityFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                }
              ]
            }
          ],
          "type": "JiraJqlUserFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraStatus",
              "kind": "LinkedField",
              "name": "status",
              "plural": false,
              "selections": (v2/*: any*/)
            }
          ],
          "type": "JiraJqlStatusFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "number"
            }
          ],
          "type": "JiraJqlNumberFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "option",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "optionId"
                }
              ]
            }
          ],
          "type": "JiraJqlOptionFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "sprint",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                }
              ]
            }
          ],
          "type": "JiraJqlSprintFieldValue"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraGoal",
              "kind": "LinkedField",
              "name": "goal",
              "plural": false,
              "selections": (v2/*: any*/)
            }
          ],
          "type": "JiraJqlGoalsFieldValue"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "groupHeader_nativeIssueTable"
    },
    {
      "alias": "issues",
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetsInput",
          "variableName": "fieldSetsInput"
        }
      ],
      "concreteType": "JiraIssueConnection",
      "kind": "LinkedField",
      "name": "__firstGroupSection_nativeIssueTable__issues_connection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "name": "cursor"
            },
            {
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            }
          ]
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "isDensityFull",
              "variableName": "isDensityFull"
            },
            {
              "kind": "Variable",
              "name": "isInlineEditingEnabled",
              "variableName": "isInlineEditingEnabled"
            },
            {
              "kind": "Variable",
              "name": "isReparentingEnabled",
              "variableName": "isReparentingEnabled"
            },
            {
              "kind": "Variable",
              "name": "projectKey",
              "variableName": "projectKey"
            },
            {
              "kind": "Variable",
              "name": "projectKeys",
              "variableName": "projectKeys"
            },
            {
              "kind": "Literal",
              "name": "shouldQueryHasChildren",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "hierarchy_nativeIssueTable_ChildRowsPagination_queryData"
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            }
          ]
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__id"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetGroup"
};
})();

(node as any).hash = "f5a4a7f135e4d6efd63e938da1df2c08";

export default node;
