// Adapted from '@atlassian/jira-issue-table/src/common/fields/issue-key';
import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import ListCellWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import type { affectedServices_nativeIssueTable_AffectedServicesCell$key } from '@atlassian/jira-relay/src/__generated__/affectedServices_nativeIssueTable_AffectedServicesCell.graphql';
import { isNonNullish } from '../../../utils/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: affectedServices_nativeIssueTable_AffectedServicesCell$key;
};
export const AffectedServicesCell = memo<Props>(({ fieldRef }: Props) => {
	const { formatMessage } = useIntl();
	const data = useFragment<affectedServices_nativeIssueTable_AffectedServicesCell$key>(
		graphql`
			fragment affectedServices_nativeIssueTable_AffectedServicesCell on JiraAffectedServicesField {
				selectedAffectedServicesConnection {
					edges {
						node {
							serviceId
							name
						}
					}
				}
			}
		`,
		fieldRef,
	);

	const affectedServices = data.selectedAffectedServicesConnection?.edges;
	const filteredAffectedServices =
		affectedServices
			?.map((edge) =>
				edge?.node?.name != null
					? {
							key: edge.node.serviceId,
							name: edge.node.name,
						}
					: null,
			)
			.filter(isNonNullish) ?? [];

	if (!affectedServices || affectedServices.length !== filteredAffectedServices.length) {
		return <ErrorCell cellType="affectedServices" reason="Missing required fields" />;
	}

	return (
		<ListCellWithPopup
			items={filteredAffectedServices}
			ItemComponent={TagItem}
			PopupContentWrapper={TagItemPopupContentWrapper}
			showMoreTooltip={formatMessage(messages.triggerButton)}
		/>
	);
});
