/**
 * @generated SignedSource<<6f6ee0189b5f6db6af7efd8ebc406adf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type versions_nativeIssueTable_VersionsCell$data = {
  readonly name: string;
  readonly selectedVersionsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "versions_nativeIssueTable_VersionsCell";
};
export type versions_nativeIssueTable_VersionsCell$key = {
  readonly " $data"?: versions_nativeIssueTable_VersionsCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"versions_nativeIssueTable_VersionsCell">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "versions_nativeIssueTable_VersionsCell",
  "selections": [
    (v0/*: any*/),
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedVersionsConnection(first:1000)"
    }
  ],
  "type": "JiraMultipleVersionPickerField"
};
})();

(node as any).hash = "d633c7e45438df5a321f477c661de24c";

export default node;
