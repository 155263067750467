import React, { type ReactNode, type ReactElement } from 'react';

type ConditionalWrapperProps = {
	children: ReactNode;
	condition: boolean;
	renderWrapper: (arg1: ReactNode) => ReactElement;
};

export const ConditionalWrapper = ({
	children,
	condition,
	renderWrapper,
}: ConditionalWrapperProps) => (condition ? renderWrapper(children) : <>{children}</>);
