/**
 * @generated SignedSource<<3ffc8c4257de96b52bfeb3d2dc90fc9b>>
 * @relayHash 7eefb6eeec213554db3d9a71ab4a3bb5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4208f182e24c74b313733c6747a0ff72e0249a271aadc573f28bb0de60cf2b96

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraIssueTypeFilterInput = {
  maxHierarchyLevel?: number | null | undefined;
  minHierarchyLevel?: number | null | undefined;
};
export type issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query$variables = {
  cursor?: string | null | undefined;
  filter?: JiraIssueTypeFilterInput | null | undefined;
  first?: number | null | undefined;
  id: string;
};
export type issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"issueTypeDropdown_issueTableInlineIssueCreate">;
  } | null | undefined;
};
export type issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query = {
  response: issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query$data;
  variables: issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "issueTypeDropdown_issueTableInlineIssueCreate"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "kind": "ScalarField",
                "name": "key"
              },
              (v6/*: any*/),
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "large"
                  }
                ]
              },
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "issueTypeId"
                          },
                          (v6/*: any*/),
                          {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "small"
                              }
                            ]
                          },
                          {
                            "concreteType": "JiraIssueTypeHierarchyLevel",
                            "kind": "LinkedField",
                            "name": "hierarchy",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "level"
                              }
                            ]
                          },
                          (v4/*: any*/),
                          {
                            "alias": "value",
                            "kind": "ScalarField",
                            "name": "id"
                          },
                          {
                            "alias": "label",
                            "kind": "ScalarField",
                            "name": "name"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v7/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "inlineIssueCreate_IssueTable_issueTypes",
                "kind": "LinkedHandle",
                "name": "issueTypes"
              }
            ],
            "type": "JiraProject"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4208f182e24c74b313733c6747a0ff72e0249a271aadc573f28bb0de60cf2b96",
    "metadata": {},
    "name": "issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "404aea827078dfb362c35ecb15dd8d3b";

export default node;
