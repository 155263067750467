/**
 * @generated SignedSource<<70e48106f05bb968bcc0404240ec4f6b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type storyPointEstimate_nativeIssueTable_StoryPointEstimateCell$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef">;
  readonly " $fragmentType": "storyPointEstimate_nativeIssueTable_StoryPointEstimateCell";
};
export type storyPointEstimate_nativeIssueTable_StoryPointEstimateCell$key = {
  readonly " $data"?: storyPointEstimate_nativeIssueTable_StoryPointEstimateCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointEstimate_nativeIssueTable_StoryPointEstimateCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "storyPointEstimate_nativeIssueTable_StoryPointEstimateCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "cdb9181ba214ad4249622bb22a6ba8ed";

export default node;
