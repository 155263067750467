/**
 * @generated SignedSource<<abebb290c9b5d6f7ed92b448a2731bda>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_nativeIssueTable_NativeIssueTable_project$data = {
  readonly editIssues?: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueTable_nativeIssueTable_project" | "realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project" | "realtimeUpdater_nativeIssueTable_project">;
  readonly " $fragmentType": "ui_nativeIssueTable_NativeIssueTable_project";
};
export type ui_nativeIssueTable_NativeIssueTable_project$key = {
  readonly " $data"?: ui_nativeIssueTable_NativeIssueTable_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_nativeIssueTable_NativeIssueTable_project",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "editIssues",
          "args": [
            {
              "kind": "Literal",
              "name": "type",
              "value": "EDIT_ISSUES"
            }
          ],
          "concreteType": "JiraProjectAction",
          "kind": "LinkedField",
          "name": "action",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canPerform"
            }
          ],
          "storageKey": "action(type:\"EDIT_ISSUES\")"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "realtimeUpdater_nativeIssueTable_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "issueTable_nativeIssueTable_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "61211e0ebbe9177f8fff6cd746405af0";

export default node;
