/**
 * @generated SignedSource<<a14b547dc80db979d18a2f6b7caad927>>
 * @relayHash 912b3665801859ed64b357f816488c18
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5822dc5c39bb3263eb89e5efb2750a9fb42f5c4f74af60f422e877ecfaac2766

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateParentFieldInput = {
  id: string;
  operation: JiraParentFieldOperationInput;
};
export type JiraParentFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type reparentMutation$variables = {
  input: JiraUpdateParentFieldInput;
};
export type reparentMutation$data = {
  readonly jira: {
    readonly updateParentField: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type reparentMutation = {
  response: reparentMutation$data;
  variables: reparentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "concreteType": "JiraParentIssueField",
  "kind": "LinkedField",
  "name": "field",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "reparentMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraParentFieldPayload",
            "kind": "LinkedField",
            "name": "updateParentField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reparentMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraParentFieldPayload",
            "kind": "LinkedField",
            "name": "updateParentField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5822dc5c39bb3263eb89e5efb2750a9fb42f5c4f74af60f422e877ecfaac2766",
    "metadata": {},
    "name": "reparentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8010fe7d0e37915f076661fa947a0546";

export default node;
