import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	blankRow: {
		id: 'native-issue-table.issue-row.blank-row.blank-row',
		defaultMessage: "We can't display this issue",
		description: 'Text that shows when an issue can no longer be opened from the issue list',
	},
	blankRowText: {
		id: 'native-issue-table.row.blank-row.blank-row-text',
		defaultMessage:
			'This issue cannot be opened because it may have been deleted or you do not have access to it',
		description: 'Text that shows when an issue can no longer be opened from the issue list',
	},
	blankRowTextWithArchiveIssue: {
		id: 'native-issue-table.row.blank-row.blank-row-text-with-archive-issue',
		defaultMessage:
			'This issue can’t be opened because it may have been archived or deleted, or you don’t have access to it.',
		description:
			'Text that shows when an issue can no longer be opened from the issue navigator, since it has been delted or archived, or user don’t have access',
	},
	blankRowTextWithArchiveIssueIssueTermRefresh: {
		id: 'native-issue-table.row.blank-row.blank-row-text-with-archive-issue-issue-term-refresh',
		defaultMessage:
			'This work item can’t be opened because it may have been archived or deleted, or you don’t have access to it.',
		description:
			'Text that shows when an issue can no longer be opened from the issue navigator, since it has been delted or archived, or user don’t have access',
	},
	tooltip: {
		id: 'native-issue-table.issue-row.blank-row.tooltip',
		defaultMessage: 'Either this issue has been deleted, or you don’t have access to it.',
		description: 'Tooltip text for blank row information icon',
	},
	blankRowIssueTermRefresh: {
		id: 'native-issue-table.row.blank-row.blank-row-issue-term-refresh',
		defaultMessage: "We can't display this work item",
		description: 'Text that shows when an issue can no longer be opened from the issue list',
	},
	blankRowTextIssueTermRefresh: {
		id: 'native-issue-table.row.blank-row.blank-row-text-issue-term-refresh',
		defaultMessage:
			'This work item cannot be opened because it may have been deleted or you do not have access to it.',
		description: 'Text that shows when an issue can no longer be opened from the issue list',
	},
	tooltipIssueTermRefresh: {
		id: 'native-issue-table.row.blank-row.tooltip-issue-term-refresh',
		defaultMessage: 'Either this work item has been deleted, or you don’t have access to it.',
		description: 'Tooltip text for blank row information icon',
	},
});
