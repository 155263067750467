/**
 * @generated SignedSource<<c59f4f09fee7d5632991da07e1679f74>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_nativeIssueTable_NativeIssueTable_columns$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly displayName: string;
      readonly fieldSetId: string;
      readonly fieldSetPreferences: {
        readonly width: number | null | undefined;
      } | null | undefined;
      readonly isPlaceHolderField: boolean | null | undefined;
      readonly isSortable: boolean | null | undefined;
      readonly jqlTerm: string;
      readonly type: string;
    };
  } | null | undefined>;
  readonly totalCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"issueFetchQueryVariables_nativeIssueTable" | "main_issueTableColumnPicker_ColumnConfig">;
  readonly " $fragmentType": "ui_nativeIssueTable_NativeIssueTable_columns";
};
export type ui_nativeIssueTable_NativeIssueTable_columns$key = {
  readonly " $data"?: ui_nativeIssueTable_NativeIssueTable_columns$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_columns">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_nativeIssueTable_NativeIssueTable_columns",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "issueFetchQueryVariables_nativeIssueTable"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "totalCount"
      },
      "action": "THROW",
      "path": "totalCount"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssueSearchFieldSetEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraIssueSearchFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "fieldSetId"
                  },
                  "action": "THROW",
                  "path": "edges.node.fieldSetId"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  "action": "THROW",
                  "path": "edges.node.type"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "displayName"
                  },
                  "action": "THROW",
                  "path": "edges.node.displayName"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "jqlTerm"
                  },
                  "action": "THROW",
                  "path": "edges.node.jqlTerm"
                },
                {
                  "kind": "ScalarField",
                  "name": "isSortable"
                },
                {
                  "concreteType": "JiraFieldSetPreferences",
                  "kind": "LinkedField",
                  "name": "fieldSetPreferences",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "width"
                    }
                  ]
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "isPlaceHolderField"
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "edges.node"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueTableColumnPicker_ColumnConfig"
    }
  ],
  "type": "JiraIssueSearchFieldSetConnection"
};

(node as any).hash = "cbbcdc26b9ef19c9713fbbf148654525";

export default node;
