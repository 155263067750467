/**
 * @generated SignedSource<<35bf3f97067093f7601e1f59825819c7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiSelect_nativeIssueTable_MultiSelectCell$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "multiSelect_nativeIssueTable_MultiSelectCell";
};
export type multiSelect_nativeIssueTable_MultiSelectCell$key = {
  readonly " $data"?: multiSelect_nativeIssueTable_MultiSelectCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiSelect_nativeIssueTable_MultiSelectCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "multiSelect_nativeIssueTable_MultiSelectCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraMultipleSelectField"
};

(node as any).hash = "b76efba9f73e137476cc5523a3ecf23a";

export default node;
