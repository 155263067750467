/** @jsx jsx */
import React, { memo } from 'react';
import { styled, jsx, css } from '@compiled/react';
import Tooltip from '@atlaskit/tooltip';
import ErrorCell from '../error-cell/index.tsx';
import { HoverPopover } from '../hover-popover/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../controllers/features/selectors.tsx';

export type Props = {
	name?: string | null;
	iconUrl?: string | null;
	showErrorIconWhenEmpty?: boolean;
	columnId?: string;
};

export const IconCell = memo(({ name, iconUrl, showErrorIconWhenEmpty }: Props) => {
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	if (!name?.length || !iconUrl?.length) {
		return showErrorIconWhenEmpty ? (
			<ErrorCell cellType="icon" reason="Missing required fields" />
		) : null; // aka an empty cell
	}

	const imgComponent = (
		<img
			height="16"
			width="16"
			src={iconUrl}
			alt={name}
			data-vc="native-issue-table-ui-icon-cell-img"
			css={isSingleLineRowHeightEnabled && imgStyles}
		/>
	);

	if (isSingleLineRowHeightEnabled) {
		return <HoverPopover content={name}>{imgComponent}</HoverPopover>;
	}

	return (
		// @ts-expect-error Incompatible ref type in tooltip (AK needs "HTMLElement", Compiled provides "HTMLDivElement" somehow these are not compatible in Typescript)
		<Tooltip content={name} tag={AlignmentFix}>
			{imgComponent}
		</Tooltip>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignmentFix = styled.div({
	display: 'flex',
});

const imgStyles = css({
	display: 'block',
});
