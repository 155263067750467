/**
 * @generated SignedSource<<5f931ef21191ed67113fe4de202f1f6a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView$data = {
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView";
};
export type multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView$key = {
  readonly " $data"?: multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView",
  "selections": [
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "picture"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleSelectUserPickerField"
};

(node as any).hash = "9a92e933723ed56c13d8c6b22c70828b";

export default node;
