/**
 * @generated SignedSource<<92d1e3360b2d69683e70b781460fce34>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef$data = {
  readonly node: {
    readonly issueTypes?: {
      readonly edges: ReadonlyArray<{
        readonly __typename: "JiraIssueTypeEdge";
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef";
};
export type issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef$key = {
  readonly " $data"?: issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef">;
};

import issueType_SearchRefetchQuery_graphql from './issueType_SearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "issueTypes"
],
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterById"
    },
    {
      "defaultValue": 1000,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": issueType_SearchRefetchQuery_graphql
    }
  },
  "name": "issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "issueTypes",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterById",
                  "variableName": "filterById"
                },
                {
                  "kind": "Variable",
                  "name": "searchBy",
                  "variableName": "searchBy"
                }
              ],
              "concreteType": "JiraIssueTypeConnection",
              "kind": "LinkedField",
              "name": "__issueType_issueFieldIssueTypeEditviewFull_issueTypes_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    },
                    {
                      "concreteType": "JiraIssueType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef"
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraIssueTypeField"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "54c04d4f507b644a10ef1cc8b3c0fc09";

export default node;
