/**
 * @generated SignedSource<<24f8ea29f5b0a9e715aebdeec184bd0b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView$data = {
  readonly selectedOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly value: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView";
};
export type multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView$key = {
  readonly " $data"?: multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView",
  "selections": [
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "value"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleSelectField"
};

(node as any).hash = "30f1fb233423b8843508f947cb7c3c09";

export default node;
