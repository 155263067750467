import React from 'react';
import Button from '@atlaskit/button/new';
import { xcss, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { RowHeight } from '../../types.tsx';
import { TableCell } from '../table-cell/index.tsx';
import messages from './messages.tsx';

const ROW_HEIGHT: RowHeight = 40;

export const LoadMoreItemsRow = ({
	loadNext,
	isLoadingNext = false,
	columnsCount,
}: {
	loadNext: () => void;
	isLoadingNext?: boolean;
	columnsCount: number;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Box as="tr" xcss={rowStyles}>
			<TableCell border={cellBorderStyles} />
			<TableCell border={cellBorderStyles} colSpan={columnsCount}>
				<Button appearance="subtle" spacing="compact" isDisabled={isLoadingNext} onClick={loadNext}>
					{isLoadingNext
						? formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.loadingMoreItemsIssueTermRefresh
									: messages.loadingMoreItems,
							)
						: formatMessage(messages.showMore)}
				</Button>
			</TableCell>
		</Box>
	);
};

const rowStyles = xcss({
	height: `${ROW_HEIGHT}px`,
	backgroundColor: 'elevation.surface',
});

const cellBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: 'border.width',
	borderBottomWidth: '0',
	borderLeftWidth: '0',
	borderRightWidth: '0',
});
