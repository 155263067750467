/**
 * @generated SignedSource<<d8228d06ebe8452bee095ff8f5594dc9>>
 * @relayHash e0b6440748f83639ae3e7c7c2d3fe93e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 24fe88e198b2feee72f5134490dbe0d574f365abdcf6a8e568c8988963268c89

import type { ConcreteRequest, Query } from 'relay-runtime';
export type actions_LazyLoadEditabilityQuery$variables = {
  first?: number | null | undefined;
  ids: ReadonlyArray<string>;
};
export type actions_LazyLoadEditabilityQuery$data = {
  readonly jira: {
    readonly issueFieldsByIds: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __id: string;
          readonly id: string;
          readonly isEditableInIssueView: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type actions_LazyLoadEditabilityQuery = {
  response: actions_LazyLoadEditabilityQuery$data;
  variables: actions_LazyLoadEditabilityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "isEditableInIssueView"
},
v5 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "actions_LazyLoadEditabilityQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "issueFieldsByIds",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "actions_LazyLoadEditabilityQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "issueFieldsByIds",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "24fe88e198b2feee72f5134490dbe0d574f365abdcf6a8e568c8988963268c89",
    "metadata": {},
    "name": "actions_LazyLoadEditabilityQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "271711cb2a483cec7c4f662a4b4db511";

export default node;
