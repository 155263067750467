/**
 * @generated SignedSource<<10e81eed51f30b85a0177f0bea5a09b1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_MaybeRowWithDragAndDrop_issue$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_issue" | "row_nativeIssueTable_RowWithDragAndDrop_issue">;
  readonly " $fragmentType": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issue";
};
export type row_nativeIssueTable_MaybeRowWithDragAndDrop_issue$key = {
  readonly " $data"?: row_nativeIssueTable_MaybeRowWithDragAndDrop_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_issue">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isDensityFull",
    "variableName": "isDensityFull"
  },
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issue",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowInner_issue"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithDragAndDrop_issue"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "f61ddf6ed119305422968d6c9b8a484c";

export default node;
