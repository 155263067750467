/**
 * @generated SignedSource<<9abde640c438bf7b632fc8e21ea6b27b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type beforeFirstCell_nativeIssueTable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"dragActionsButton_nativeIssueTable">;
  readonly " $fragmentType": "beforeFirstCell_nativeIssueTable";
};
export type beforeFirstCell_nativeIssueTable$key = {
  readonly " $data"?: beforeFirstCell_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"beforeFirstCell_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "beforeFirstCell_nativeIssueTable",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isReparentingEnabled",
          "variableName": "isReparentingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "dragActionsButton_nativeIssueTable"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "780f04e74d4c4e81616ebb05591db223";

export default node;
