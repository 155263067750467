import React, { useState, useCallback, useMemo, useEffect, type RefCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FormPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import CreateChildButton from '../create-child-button/index.tsx';
import { useIsItemExpanded } from '../../controllers/hierarchy/index.tsx';
import messages from './messages.tsx';
import IndentedIcon from './indented-icon/index.tsx';

type BaseProps = {
	itemId: string;
	itemsConnectionId: string;
	depth: number;
	groupId?: string;
	isGroupButton?: boolean;
};

type CreateChildButtonProps = {
	createChildTriggerProps?: {
		onClick: () => void;
		ref: RefCallback<HTMLElement>;
	};
	hasChildren?: boolean;
	isRowHoveredOrSelected?: boolean;
	showCreateChildButton?: boolean;
	isCreateChildFormVisible?: boolean;
	isFormVisible?: boolean;
	formProps?: FormPropsType;
};

export type Props = CreateChildButtonProps & BaseProps;

const ICON_WIDTH = 24;
const EXTRA_PADDING_ROW_WITHOUT_EXPAND = 8;

export const ExpandButton = ({
	itemId,
	itemsConnectionId,
	depth,
	isGroupButton,
	groupId,
}: BaseProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isExpanded, { toggleIsItemExpanded }] = useIsItemExpanded({
		itemId,
		groupId,
		itemsConnectionId,
	});

	const onExpandClick = useCallback(() => {
		const actionSubjectId = isGroupButton ? 'groupExpandButton' : 'hierarchyExpandButton';
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', actionSubjectId);
		toggleIsItemExpanded({ itemId, itemsConnectionId, groupId }, depth);
	}, [
		createAnalyticsEvent,
		depth,
		itemId,
		itemsConnectionId,
		toggleIsItemExpanded,
		isGroupButton,
		groupId,
	]);

	const label = useMemo(() => {
		if (isGroupButton) {
			return formatMessage(isExpanded ? messages.collapseGroup : messages.expandGroup);
		}
		return formatMessage(isExpanded ? messages.collapseIssue : messages.expandIssue);
	}, [formatMessage, isExpanded, isGroupButton]);

	return (
		<IconButton
			spacing="compact"
			appearance="subtle"
			label={label}
			aria-expanded={isExpanded}
			testId="issue-table-hierarchy.ui.expand-button.expand-button"
			icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
			onClick={onExpandClick}
			isTooltipDisabled={false}
		/>
	);
};

export const ExpandButtonWithCreateChildButton = ({
	itemId,
	itemsConnectionId,
	depth,
	hasChildren = true,
	isRowHoveredOrSelected,
	showCreateChildButton,
	createChildTriggerProps,
	isCreateChildFormVisible,
	isGroupButton,
	groupId,
}: BaseProps & CreateChildButtonProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isInlineCreateChildOpen, setIsInlineCreateChildOpen] = useState(false);
	const [isExpanded, { toggleIsItemExpanded }] = useIsItemExpanded({
		itemId,
		groupId,
		itemsConnectionId,
	});

	useEffect(() => {
		if (!isCreateChildFormVisible) {
			setIsInlineCreateChildOpen(false);
		}
	}, [isCreateChildFormVisible]);

	const onCreateChildClick = useCallback(() => {
		const actionSubjectId = isGroupButton ? 'groupCreateChildButton' : 'hierarchyCreateChildButton';
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', actionSubjectId);
		if (!isExpanded) {
			toggleIsItemExpanded({ itemId, itemsConnectionId }, depth);
		}
		if (!isCreateChildFormVisible) {
			createChildTriggerProps?.onClick();
		}
		setIsInlineCreateChildOpen(true);
	}, [
		createAnalyticsEvent,
		createChildTriggerProps,
		depth,
		isCreateChildFormVisible,
		isExpanded,
		itemId,
		itemsConnectionId,
		toggleIsItemExpanded,
		isGroupButton,
	]);

	if (hasChildren) {
		return (
			<Box
				tabIndex={isRowHoveredOrSelected ? 0 : -1}
				xcss={[cellStyle, !isGroupButton && cellPaddingStyle]}
			>
				<IndentedIcon depth={depth} />
				<Box
					xcss={cellWrapperStyles}
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{
						paddingLeft: `${ICON_WIDTH * depth}px`,
					}}
				>
					<ExpandButton
						itemId={itemId}
						itemsConnectionId={itemsConnectionId}
						depth={depth}
						isGroupButton={isGroupButton}
						groupId={groupId}
					/>
				</Box>
				{showCreateChildButton && !isInlineCreateChildOpen && (
					<CreateChildButton onClick={onCreateChildClick} ref={createChildTriggerProps?.ref} />
				)}
			</Box>
		);
	}
	return (
		<Box tabIndex={0} xcss={cellSizeStyle}>
			<IndentedIcon depth={depth} />
			<Box
				xcss={cellPaddingStyle} // eslint-disable-next-line jira/react/no-style-attribute
				style={{
					paddingLeft: `${ICON_WIDTH * depth + ICON_WIDTH + EXTRA_PADDING_ROW_WITHOUT_EXPAND}px`,
				}}
			>
				{showCreateChildButton && !isInlineCreateChildOpen && (
					<CreateChildButton onClick={onCreateChildClick} ref={createChildTriggerProps?.ref} />
				)}
			</Box>
		</Box>
	);
};

const cellWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const cellSizeStyle = xcss({
	height: '100%',
});

const cellStyle = xcss({
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
});

const cellPaddingStyle = xcss({
	padding: 'space.100',
});
