import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const MultiUserPickerEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-multi-user-picker-edit-view" */ './ui/multi-user-picker'),
	),
	getPreloadProps: () => ({}),
});

export default MultiUserPickerEditViewEntryPoint;
