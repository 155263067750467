/**
 * @generated SignedSource<<07b9d59f4fa70596158e014bd7a707ac>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type childRowsPagination_issueTableHierarchy_queryData$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"rows_nativeIssueTable">;
  readonly " $fragmentType": "childRowsPagination_issueTableHierarchy_queryData";
};
export type childRowsPagination_issueTableHierarchy_queryData$key = {
  readonly " $data"?: childRowsPagination_issueTableHierarchy_queryData$data;
  readonly " $fragmentSpreads": FragmentRefs<"childRowsPagination_issueTableHierarchy_queryData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "childRowsPagination_issueTableHierarchy_queryData",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    },
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            }
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isReparentingEnabled",
          "variableName": "isReparentingEnabled"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "rows_nativeIssueTable"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "f6a38e91e4ca98e1668749ed74ebe701";

export default node;
