import React, { useMemo, useState, useEffect } from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { Box } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import messages from './messages.tsx';
import onboardingGIFLight from './assets/reparent-onboarding-light.gif';
import onboardingGIFDark from './assets/reparent-onboarding-dark.gif';
/* TODO: remove after cleaning jira_list_reparenting  */
export const REPARENTING_ONBOARDING_ENGAGEMENT_ID_STG = 'jira-list-nin-reparent-onboarding-stg';
export const REPARENTING_ONBOARDING_ENGAGEMENT_ID_PROD = 'jira-list-nin-reparent-onboarding';

const ENV_STAGING: Environment = 'staging';
const STARGATE_URL = '/gateway/api';

export const ReparentingOnboarding = ({
	buttonElement,
	setIsOnboardingVisible,
}: {
	buttonElement: HTMLElement;
	setIsOnboardingVisible: (isVisible: boolean) => void;
}) => {
	const ONBOARDING_ENGAGEMENT_ID =
		useEnvironment() === ENV_STAGING
			? REPARENTING_ONBOARDING_ENGAGEMENT_ID_STG
			: REPARENTING_ONBOARDING_ENGAGEMENT_ID_PROD;

	const cloudId = useCloudId();
	const { formatMessage } = useIntl();

	const { colorMode } = useThemeObserver();
	const spotlight = colorMode === 'dark' ? onboardingGIFDark : onboardingGIFLight;

	const [isSpotlightActive, setIsSpotlightActive] = useState(false);

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, '', 'engagement'),
		[cloudId],
	);

	const [isEnrolled, stopEnrolment] = useCoordination(coordinationClient, ONBOARDING_ENGAGEMENT_ID);

	useEffect(() => {
		if (isEnrolled) {
			setIsOnboardingVisible(true);
			stopEnrolment(false);
			setTimeout(() => {
				setIsSpotlightActive(true);
			}, 50);
		}
	}, [isEnrolled, setIsOnboardingVisible, stopEnrolment]);

	return (
		<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
			<SpotlightTransition>
				{isSpotlightActive && buttonElement && (
					<Box
						onMouseOver={(event: React.MouseEvent) => {
							event.stopPropagation();
						}}
						onMouseLeave={(event: React.MouseEvent) => {
							event.stopPropagation();
						}}
					>
						<JiraSpotlight
							image={spotlight}
							messageId="native-issue-table.ui.row.before-first-cell.drag-actions-button.reparenting-onboarding.jira-spotlight.expand-column-header"
							messageType="engagement"
							actions={[
								{
									onClick: () => {
										setIsSpotlightActive(false);
										setIsOnboardingVisible(false);
									},
									text: formatMessage(messages.dismiss),
								},
							]}
							dialogWidth={270}
							heading={formatMessage(messages.onboardingHeading)}
							targetNode={buttonElement}
							targetRadius={3}
							key="reparenting-onboarding"
						>
							{formatMessage(messages.onboardingDescription)}
						</JiraSpotlight>
					</Box>
				)}
			</SpotlightTransition>
		</ChoreographerContextProvider>
	);
};
