import React from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import messages from './messages.tsx';

type Props = {
	showOnboarding: boolean;
	onDismiss?: () => void;
};

const InlineCreateOnboardingSpotlight = ({ showOnboarding, onDismiss }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SpotlightTransition>
			{showOnboarding && (
				<JiraSpotlight
					actions={[
						{
							onClick: onDismiss,
							text: formatMessage(messages.onboardingDismiss),
						},
					]}
					heading={formatMessage(messages.onboardingHeader)}
					target="inline-create-trigger"
					key="inline-create-trigger"
					messageType="engagement"
					dialogPlacement="top right"
					messageId={messages.onboardingDescription.id}
					dialogWidth={275}
				>
					{formatMessage(messages.onboardingDescription)}
				</JiraSpotlight>
			)}
		</SpotlightTransition>
	);
};

export default InlineCreateOnboardingSpotlight;
