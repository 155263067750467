import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createAnotherButton: {
		id: 'native-issue-table.inline-field-config-footer.create-another-button',
		defaultMessage: 'Create another',
		description: 'Label for button in the column picker footer that creates a new field',
	},
	contactAdmin: {
		id: 'native-issue-table.inline-field-config-footer.contact-admin-text',
		defaultMessage: '<link>Contact your project admin</link> to make changes',
		description:
			'Text on footer in the column picker that tells a non-admin user to contact admin to make changes to the column list',
	},
	makeChangesInConfig: {
		id: 'native-issue-table.inline-field-config-footer.make-config-changes-text',
		defaultMessage: 'Make changes in <link>configurations</link>',
		description:
			'Text on footer in the column picker that tells a CMP admin user to make changes in Configurations',
	},
});
