/**
 * @generated SignedSource<<357beb53165a8a4df485e730345c9792>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly optionId: string;
        readonly value: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView">;
  readonly " $fragmentType": "multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef";
};
export type multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView"
    },
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "value"
                },
                {
                  "kind": "ScalarField",
                  "name": "optionId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleSelectField"
};
})();

(node as any).hash = "51442265271466c01819bfe490c82976";

export default node;
