import { useCallback } from 'react';
import { ConnectionHandler, type RecordSourceProxy, ConnectionInterface } from 'relay-runtime';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { FlagConfiguration } from '@atlassian/jira-flags';
import RelayDataID from '@atlassian/relay-data-id';
import { useConnectionsList } from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	updateParentHasChildren,
	useUpdateConnectionDetails,
} from '../../controllers/issue-realtime-updater/utils.tsx';

import {
	UPDATE_ITEM_PARENT_FAIL_400_FLAG,
	UPDATE_ITEM_PARENT_FAIL_400_FLAG_WITH_ISSUE_TERM,
	UPDATE_ITEM_PARENT_FAIL_404_FLAG,
	UPDATE_ITEM_PARENT_FAIL_404_FLAG_WITH_ISSUE_TERM,
} from './constants.tsx';

export const getFlagDetails = (statusCode: number | null): FlagConfiguration | null => {
	switch (statusCode) {
		case 400: {
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? UPDATE_ITEM_PARENT_FAIL_400_FLAG_WITH_ISSUE_TERM
				: UPDATE_ITEM_PARENT_FAIL_400_FLAG;
		}
		case 404:
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? UPDATE_ITEM_PARENT_FAIL_404_FLAG_WITH_ISSUE_TERM
				: UPDATE_ITEM_PARENT_FAIL_404_FLAG;
		default: {
			return null;
		}
	}
};

export const JIRA_ISSUE_TYPENAME = 'JiraIssue';

export const useGetReparentUpdater = () => {
	const { connections } = useConnectionsList();
	const updateConnectionDetails = useUpdateConnectionDetails();

	const getConection = useCallback(
		({ issueId }: { issueId?: string | null }) =>
			Object.values(connections).find((connection) => {
				if (issueId) {
					return connection.type === 'PARENT_CHILDREN' && connection.parentId === issueId;
				}
				return connection.type === 'ROOT_ISSUES';
			}),
		[connections],
	);

	const updater = useCallback(
		({
			store,
			issueId,
			oldParentId,
			newParentId,
			newParentEdgeId,
			index = 0,
			projectKey,
		}: {
			store: RecordSourceProxy;
			issueId: string;
			oldParentId?: string | null;
			newParentId?: string | null;
			newParentEdgeId?: string;
			index?: number;
			projectKey?: string;
		}) => {
			const relayId = RelayDataID({ id: issueId }, JIRA_ISSUE_TYPENAME);
			if (!relayId || !oldParentId) {
				return;
			}

			const oldConnection = getConection({ issueId: oldParentId });
			const newConnection = getConection({ issueId: newParentId });

			if (!oldConnection) {
				// Since the issue has to be visible to be repartented, this case should not occur
				return;
			}

			const oldConnectionRecord = store.get(oldConnection.connectionId);
			if (!oldConnectionRecord) {
				return;
			}

			if (newParentEdgeId) {
				updateParentHasChildren({
					store,
					parentIssueRecordId: newParentEdgeId,
					connectionId: newConnection?.connectionId,
					hasChildren: true,
					projectKey,
				});
			}

			if (newConnection) {
				const newConnectionRecord = store.get(newConnection.connectionId);
				if (!newConnectionRecord) {
					return;
				}
				const existingEdge = oldConnectionRecord
					.getLinkedRecords('edges')
					?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === relayId);

				// no before or after implies an empty conneciton, verify then create the edges list
				const { EDGES } = ConnectionInterface.get();
				const edges = newConnectionRecord.getLinkedRecords(EDGES);
				if (!edges || !existingEdge) {
					return;
				}
				const nextEdges = edges.slice(0, index).concat(existingEdge).concat(edges.slice(index));

				newConnectionRecord.setLinkedRecords(nextEdges, EDGES);

				updateConnectionDetails({
					store,
					connection: newConnection,
					operation: 'add',
					remainingIssueCount: newConnectionRecord.getLinkedRecords('edges')?.length,
					projectKey,
				});
			}

			if (oldConnection) {
				// remove the issue from the old connection
				if (
					oldConnectionRecord
						.getLinkedRecords('edges')
						?.some((edge) => edge?.getLinkedRecord('node')?.getDataID() === relayId)
				) {
					ConnectionHandler.deleteNode(oldConnectionRecord, relayId);

					updateConnectionDetails({
						store,
						connection: oldConnection,
						operation: 'remove',
						remainingIssueCount: oldConnectionRecord.getLinkedRecords('edges')?.length,
						projectKey,
					});
				}
			}
		},
		[getConection, updateConnectionDetails],
	);

	return updater;
};
