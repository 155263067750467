import { fetchQuery, graphql, commitLocalUpdate, type Environment } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { actions_LazyLoadEditabilityQuery } from '@atlassian/jira-relay/src/__generated__/actions_LazyLoadEditabilityQuery.graphql';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { FIELD_IDS_BATCH_SIZE } from './constants.tsx';
import type { Actions } from './types.tsx';

export const batchIsEditableInIssueViewFactory =
	(environment: Environment) =>
	async (fieldIds: ReadonlyArray<string>): Promise<ReadonlyArray<boolean | null>> => {
		const isEditableMap = new Map<string, boolean | null>();

		const promise = fetchQuery<actions_LazyLoadEditabilityQuery>(
			environment,
			graphql`
				query actions_LazyLoadEditabilityQuery($ids: [ID!]!, $first: Int) {
					jira {
						issueFieldsByIds(ids: $ids, first: $first) @optIn(to: "JiraIssueFieldsByIds") {
							edges {
								node {
									__id
									id
									isEditableInIssueView
								}
							}
						}
					}
				}
			`,
			{ ids: fieldIds, first: FIELD_IDS_BATCH_SIZE },
		).toPromise();

		try {
			const data = await promise;
			commitLocalUpdate(environment, (store) => {
				data?.jira?.issueFieldsByIds?.edges
					?.map((edge) => edge?.node)
					.filter(Boolean)
					.forEach(({ __id, id, isEditableInIssueView }) => {
						isEditableMap.set(id, isEditableInIssueView ?? null);

						// Set whether issue fields are editable as a new client-only field on the matching node in the Relay
						// store
						store.get(__id)?.setValue(isEditableInIssueView ?? null, 'lazyIsEditableInIssueView');
					});
			});
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: 'lazyEditabilityLoader',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error: error instanceof Error ? error : undefined,
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		// Ensure input argument order is preserved when returning batch results as required by the dataloader
		return fieldIds.map((id) => isEditableMap.get(id) ?? null);
	};

export const actions: Actions = {
	load:
		(fieldId: string) =>
		({ getState }): Promise<boolean | null> => {
			const { loader, scheduler } = getState();
			const loading = loader ? loader.load(fieldId) : Promise.resolve(null);
			scheduler?.dispatch();

			return loading;
		},
};
