import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { FormPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import {
	useIsItemExpanded,
	useHierarchyActions,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import type { ConnectionMetadata } from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	PACKAGE_NAME,
	TEAM_NAME,
	CHILD_ITEMS_EXPERIENCE,
	ANALYTICS_SOURCE,
	GROUPED_ITEMS_EXPERIENCE,
} from '../../../common/constants.tsx';
import { ChildRowsLoader } from '../../hierarchy/index.tsx';
import { useRowContext } from '../row-context/index.tsx';
import messages from './messages.tsx';

const PACKAGE_LOCATION = 'jira-native-issue-table-child-rows';
export const FLAG_ID = 'issue-table-child-rows-entrypoint-container';

type BaseProps = {
	parentItemId: string;
	itemsConnectionId: string;
	depth: number;
	parentIssueAri?: string;
	parentItemConnectionId?: string;
	parentIssueRelayConnectionId?: string;
	isFormVisible?: boolean;
	formProps?: FormPropsType;
	projectKey?: string;
	isGroupingSupported?: boolean;
	hasChildren?: boolean;
};

interface ChildRowProps extends BaseProps {
	type: 'PARENT_CHILDREN';
	issueKey: string;
}

interface GroupRowProps extends BaseProps {
	type: 'GROUP_CHILDREN';
	groupFieldValue: string | null;
	groupJql: string;
}

type Props = BaseProps & (ChildRowProps | GroupRowProps);

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const ChildRows = memo((props: Props) => {
	const { groupId } = useRowContext();

	const [isExpanded] = useIsItemExpanded({
		itemId: props.parentItemId,
		itemsConnectionId: props.itemsConnectionId,
		groupId,
	});

	if (!isExpanded) {
		return null;
	}

	return <ChildRowsInner {...props} />;
});

const ChildRowsInner = (props: Props) => {
	const { parentItemId, itemsConnectionId, type, parentItemConnectionId, depth } = props;
	const experience =
		props.type === 'GROUP_CHILDREN' ? GROUPED_ITEMS_EXPERIENCE : CHILD_ITEMS_EXPERIENCE;
	const onExperienceStart = useExperienceStart({
		experience,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceFail = useExperienceFail({
		experience,
		attributes: {},
	});

	useEffect(() => {
		onExperienceStart();
	}, [onExperienceStart]);

	const { setIsItemExpanded } = useHierarchyActions();
	const { showFlag, hasFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const { groupId } = useRowContext();

	const handleError = useCallback(
		(error: Error) => {
			setIsItemExpanded(
				{
					itemId: parentItemId,
					itemsConnectionId,
					groupId,
				},
				false,
			);
			if (!hasFlag?.(FLAG_ID)) {
				const isGrouping = groupId && depth === 0;

				showFlag({
					id: FLAG_ID,
					title: formatMessage(
						isGrouping ? messages.errorFlagGroupingTitle : messages.errorFlagTitle,
					),
					description: formatMessage(
						isGrouping ? messages.errorFlagGroupingDescription : messages.errorFlagDescription,
					),
					type: 'error',
					messageId: `native-issue-table.ui.rows.child-rows.showFlag.${FLAG_ID}`,
					messageType: 'transactional',
				});
			}

			fireErrorAnalytics({
				meta: {
					id: 'issue-table-child-rows-container',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			onExperienceFail(PACKAGE_LOCATION, error);
			return null;
		},
		[
			formatMessage,
			hasFlag,
			itemsConnectionId,
			onExperienceFail,
			parentItemId,
			setIsItemExpanded,
			showFlag,
			depth,
			groupId,
		],
	);

	const connectionMetadata: ConnectionMetadata | undefined = useMemo(() => {
		if (type === 'PARENT_CHILDREN' && parentItemConnectionId) {
			return {
				type,
				parentRelayId: parentItemConnectionId,
				parentId: parentItemId,
			};
		}
		if (type === 'GROUP_CHILDREN' && groupId) {
			return {
				type,
				fieldValue: props.groupFieldValue,
				groupId,
				jql: props.groupJql,
			};
		}
		return undefined;
	}, [type, parentItemConnectionId, groupId, props, parentItemId]);

	return (
		<JSErrorBoundary
			id={PACKAGE_LOCATION}
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			onError={(_, error) => handleError(error)}
		>
			<ChildRowsLoader {...props} connectionMetadata={connectionMetadata} />
		</JSErrorBoundary>
	);
};
