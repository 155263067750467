/**
 * @generated SignedSource<<f98fe0da0290d93372f146e646472b13>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge$data = {
  readonly node: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_issueEdge" | "row_nativeIssueTable_RowWithDragAndDrop_issueEdge">;
  readonly " $fragmentType": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge";
};
export type row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge$key = {
  readonly " $data"?: row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isDensityFull",
    "variableName": "isDensityFull"
  },
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  },
  {
    "kind": "Variable",
    "name": "isReparentingEnabled",
    "variableName": "isReparentingEnabled"
  },
  {
    "kind": "Variable",
    "name": "projectKey",
    "variableName": "projectKey"
  },
  {
    "kind": "Variable",
    "name": "projectKeys",
    "variableName": "projectKeys"
  },
  {
    "kind": "Variable",
    "name": "shouldQueryHasChildren",
    "variableName": "shouldQueryHasChildren"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowInner_issueEdge"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowWithDragAndDrop_issueEdge"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        }
      ]
    }
  ],
  "type": "JiraIssueEdge"
};
})();

(node as any).hash = "b7bc74fbfe4cf047632031716ff96362";

export default node;
