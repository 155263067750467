import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './main.tsx';

const LazyRichTextRenderer = lazyForPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-rich-text-renderer" */ './main'),
);

export const AsyncRichTextRenderer = (props: Props) => (
	<Placeholder name="async-rich-text-renderer">
		<LazyRichTextRenderer {...props} />
	</Placeholder>
);
