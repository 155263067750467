import type {
	IssueCreateIssueType,
	IssueCreateProject,
	IssueToCreate,
} from '@atlassian/jira-issue-table-inline-issue-create/src/common/types.tsx';
import type { issueCreateMutation_useIssueCreateMutation$rawResponse as OptimisticResponse } from '@atlassian/jira-relay/src/__generated__/issueCreateMutation_useIssueCreateMutation.graphql';
import type { OptimisticFieldSet } from './types.tsx';

export const toOptimisticResponse = (
	{ project, issueType, summary }: IssueToCreate,
	optimisticId: string,
	isIssueHierarchyEnabled: boolean,
): OptimisticResponse => {
	const issueId = optimisticId;
	const summaryEdge = summary ? toOptimisticSummary(issueId, summary) : null;
	const projectEdge = project ? toOptimisticProject(issueId, project) : null;
	const issueTypeEdge = issueType ? toOptimisticIssueType(issueId, issueType) : null;

	const edges = [summaryEdge, projectEdge, issueTypeEdge];

	return {
		jira: {
			createIssue: {
				errors: null,
				issue: {
					__typename: 'JiraIssue',
					id: issueId,
					issueId,
					key: '',
					fieldSetsById: {
						edges,
					},
					...(isIssueHierarchyEnabled
						? {
								issueTypeField: {
									issueType: {
										hierarchy: {
											level: issueType?.hierarchy?.level,
										},
										id: issueType?.id,
									},
									id: '',
								},
								canHaveChildIssues: false,
							}
						: {
								issueTypeField: undefined,
								canHaveChildIssues: undefined,
							}),
				},
			},
		},
	};
};

const toOptimisticSummary = (issueId: string, summary: string): OptimisticFieldSet => ({
	node: {
		fieldSetId: 'summary',
		type: 'summary',
		fields: {
			edges: [
				{
					node: {
						__typename: 'JiraSingleLineTextField',
						__isJiraIssueField: 'JiraSingleLineTextField',
						fieldId: 'summary',
						id: `${issueId}/summary`,
						name: 'Summary', // TODO Do we need to translate?
						text: summary,
						type: 'summary',
						lazyIsEditableInIssueView: undefined,
					},
				},
			],
		},
	},
});

const toOptimisticIssueType = (
	issueId: string,
	issueType: IssueCreateIssueType,
): OptimisticFieldSet => ({
	node: {
		fieldSetId: 'issuetype',
		type: 'issuetype',
		fields: {
			edges: [
				{
					node: {
						__typename: 'JiraIssueTypeField',
						__isJiraIssueField: 'JiraIssueTypeField',
						fieldId: 'issuetype',
						id: `${issueId}/issuetype`,
						type: 'issuetype',
						issueType: {
							id: issueType.id,
							name: issueType.name,
							avatar: issueType.avatar,
						},
					},
				},
			],
		},
	},
});

const toOptimisticProject = (issueId: string, project: IssueCreateProject): OptimisticFieldSet => ({
	node: {
		fieldSetId: 'project',
		type: 'project',
		fields: {
			edges: [
				{
					node: {
						__typename: 'JiraProjectField',
						__isJiraIssueField: 'JiraProjectField',
						fieldId: 'project',
						id: `${issueId}/project`,
						type: 'project',
						project: {
							id: project.id,
							key: project.key,
							name: project.name,
							avatar: project.avatar,
						},
					},
				},
			],
		},
	},
});
