import { useCallback, useEffect, useRef } from 'react';
import { useCellNavigationActions, useIsSelectedCell } from '../store/index.tsx';

type UseCellWrapperProps = {
	row: number;
	col: number;
};

const TabbableSelector = `a[href],
	button,
	input:not([type="hidden"]),
	[tabindex]:not([tabindex="-1"])`;

/**
 *
 * This hook toggles grid nav/cell nav based on user keydown events. It does the following:
 * 1. When user pressed Enter or clicked cell, it goes from grid nav to cell nav
 * 2. When cell only has 1 tabbable element, the key pressed is propagated to that element
 * 3. While in cell nav, tab is trapped in the cell
 * 4. When user presses Escape, it goes back to grid nav and cell is focused
 *
 */

export const useCellWrapper = ({ row, col }: UseCellWrapperProps) => {
	const cellRef = useRef<HTMLTableCellElement>(null);
	const isCellNavEnabled = useRef<boolean>(false);

	const [isSelectedCell] = useIsSelectedCell([row, col]);
	const { setIsKeyboardNavOn, setSelectedCell } = useCellNavigationActions();

	const forEachTabbableElement = useCallback((fn: (element: Element, index?: number) => void) => {
		const elements = cellRef.current?.querySelectorAll(TabbableSelector);
		if (elements) {
			elements.forEach((element, index) => {
				fn(element, index);
			});
		}
	}, []);

	const enableTabbing = useCallback(
		() =>
			forEachTabbableElement((element, index) => {
				element.setAttribute('tabindex', '0');
				if (element instanceof HTMLElement && index === 0) {
					element.focus();
				}
			}),
		[forEachTabbableElement],
	);

	const disableTabbing = useCallback(
		() =>
			forEachTabbableElement((element) => {
				element.setAttribute('tabindex', '-1');
			}),
		[forEachTabbableElement],
	);

	const toggleCellNavigation = useCallback(
		(override?: boolean) => {
			if (typeof override === 'boolean') {
				isCellNavEnabled.current = override;
				setIsKeyboardNavOn(!override);
				override ? enableTabbing() : disableTabbing();
			} else if (isCellNavEnabled.current) {
				setIsKeyboardNavOn(true);
				isCellNavEnabled.current = false;
				disableTabbing();
			} else {
				setIsKeyboardNavOn(false);
				isCellNavEnabled.current = true;
				enableTabbing();
			}
		},
		[disableTabbing, enableTabbing, setIsKeyboardNavOn],
	);

	const moveSelectedCell = useCallback(
		(r: number, c: number) => {
			if (isCellNavEnabled.current) {
				// we do not need to set focus because we are changing state
				setIsKeyboardNavOn(true);
				isCellNavEnabled.current = false;
				disableTabbing();
			}

			setSelectedCell(r, c);
		},
		[disableTabbing, setIsKeyboardNavOn, setSelectedCell],
	);

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			switch (e.code) {
				// Goes from grid to cell navigation
				case 'Enter':
					if (e.target === cellRef?.current) {
						const elements = cellRef.current?.querySelectorAll(TabbableSelector);

						if (elements) {
							// if this is readonly, pressing Enter does nothing
							if (elements.length === 0) return;

							e.preventDefault();

							// if we have 1 tabbable link element, programmatically click that element
							if (elements.length === 1 && elements[0] instanceof HTMLElement) {
								elements[0].click();

								// if the element has no popups, stay in grid navigation
								if (elements[0].getAttribute('aria-haspopup') !== 'true') {
									return;
								}
							}

							toggleCellNavigation(true);
						}
					}
					break;

				// Goes from cell to grid navigation
				case 'Escape':
					if (isCellNavEnabled.current) {
						toggleCellNavigation(false);
						cellRef.current?.focus();
					}

					// this cell could have moved row/cell, so we set selected cell to follow it
					setSelectedCell(row, col);

					break;

				// Trap tab keys in the cell
				case 'Tab':
					if (
						isCellNavEnabled.current &&
						e.target instanceof Element &&
						cellRef?.current?.contains(e.target)
					) {
						const elements = cellRef.current?.querySelectorAll(TabbableSelector);

						const firstElement = elements[0];
						const lastElement = elements[elements.length - 1];

						let nextElement;
						if (e.shiftKey && firstElement === e.target) {
							nextElement = lastElement;
						} else if (!e.shiftKey && lastElement === e.target) {
							nextElement = firstElement;
						}

						if (nextElement) {
							e.preventDefault();
							nextElement instanceof HTMLElement && nextElement.focus();
						}
					}
					break;
				default:
				// do nothing
			}
		},
		[col, row, setSelectedCell, toggleCellNavigation],
	);

	const onBlur = useCallback(
		(e: FocusEvent) => {
			if (e.relatedTarget == null) {
				// if user has tabbed out of the page, disable tabs in cell again
				disableTabbing();
			}
		},
		[disableTabbing],
	);

	const onClick = useCallback(() => {
		if (!isCellNavEnabled.current) {
			setSelectedCell(row, col);
			const elements = cellRef.current?.querySelectorAll(TabbableSelector);
			if (elements && elements.length > 0) {
				// handle when there is only 1 tabbable element in the cell and it has no pop-up, stay in grid nav
				if (elements.length === 1 && elements[0].getAttribute('aria-haspopup') !== 'true') return;

				toggleCellNavigation(true);
			}
		}
	}, [col, row, setSelectedCell, toggleCellNavigation]);

	useEffect(() => {
		// attach necessary event handlers here
		const cellRefCurrent = cellRef.current;

		cellRefCurrent?.addEventListener('keydown', onKeyDown);
		cellRefCurrent?.addEventListener('blur', onBlur);
		cellRefCurrent?.addEventListener('click', onClick);

		return () => {
			cellRefCurrent?.removeEventListener('keydown', onKeyDown);
			cellRefCurrent?.removeEventListener('blur', onBlur);
			cellRefCurrent?.removeEventListener('click', onClick);
		};
	}, [onBlur, onClick, onKeyDown]);

	useEffect(() => {
		if (isSelectedCell) {
			cellRef.current?.setAttribute('tabindex', '0');
			cellRef.current?.focus();
		} else {
			cellRef.current?.setAttribute('tabindex', '-1');
		}
	}, [isSelectedCell]);

	useEffect(() => {
		// programatically disabling tabbing in all tabbable elements of the cell
		disableTabbing();
	}, [disableTabbing]);

	return {
		cellRef,
		isSelected: isSelectedCell,
		disableTabbing,
		toggleCellNavigation,
		setSelectedCell,
		moveSelectedCell,
	};
};
