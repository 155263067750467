/**
 * @generated SignedSource<<bda41d384640311154c7f8d58b578860>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type richText_nativeIssueTable_RichTextCell_fieldRef$data = {
  readonly fieldId: string;
  readonly richText: {
    readonly adfValue: {
      readonly convertedPlainText?: {
        readonly plainText: string | null | undefined;
      } | null | undefined;
      readonly json?: AGG$JSON | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "richText_nativeIssueTable_RichTextCell_fieldRef";
};
export type richText_nativeIssueTable_RichTextCell_fieldRef$key = {
  readonly " $data"?: richText_nativeIssueTable_RichTextCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"richText_nativeIssueTable_RichTextCell_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    }
  ],
  "kind": "Fragment",
  "name": "richText_nativeIssueTable_RichTextCell_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "condition": "isDensityFull",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "concreteType": "JiraAdfToConvertedPlainText",
                  "kind": "LinkedField",
                  "name": "convertedPlainText",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "plainText"
                    }
                  ]
                }
              ]
            },
            {
              "condition": "isDensityFull",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "json"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
};

(node as any).hash = "9735b308958216a9c39073bc5e73008a";

export default node;
