import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { ParentReadView } from '@atlassian/jira-issue-field-parent-readview-full/src/ui/parent/index.tsx';
import { ParentInlineEditViewIsEditable } from '@atlassian/jira-issue-field-parent-inline-edit-full/src/ui/parent/index.tsx';
import type { parent_nativeIssueTable_ParentCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/parent_nativeIssueTable_ParentCell_fieldRef.graphql';
import type { parent_nativeIssueTable_ParentCell_data$key } from '@atlassian/jira-relay/src/__generated__/parent_nativeIssueTable_ParentCell_data.graphql';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	useIsDensityFull,
	useIsInlineEditingExtendedFieldSupportEnabled,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { type Props as HoverPopoverProps, HoverPopover } from '../../hover-popover/index.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: parent_nativeIssueTable_ParentCell_fieldRef$key;
	fieldType: string;
};

export const ParentCell = memo<Props>(({ fieldRef, fieldType = '' }) => {
	const data = useFragment<parent_nativeIssueTable_ParentCell_fieldRef$key>(
		graphql`
			fragment parent_nativeIssueTable_ParentCell_fieldRef on JiraParentIssueField
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isJscInlineEditRefactorEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider"
				}
			) {
				id
				parentIssue {
					key
					summaryField {
						text
					}
				}
				...parent_issueFieldParentReadviewFull_ParentReadView
					@skip(if: $isJscInlineEditRefactorEnabled)
				...parent_nativeIssueTable_ParentCell_data
					@include(if: $isJscInlineEditRefactorEnabled)
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
			}
		`,
		fieldRef,
	);

	const isDensityFull = useIsDensityFull();

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const hoverPopoverRenderer = (props: HoverPopoverProps) => (
		<HoverPopover {...props}>{props.children}</HoverPopover>
	);

	/**
	 * As part of the initiative to remove "Epic Link" and "Parent Link" fields from Jira,
	 * we're rendering a "Replaced by Parent field" deprecation message in the "Parent Link" cell.
	 * At the time of writing this comment, "Parent Link" is still used in ~1% of issue tables, and
	 * we hope this message will help these users migrate and use the "Parent" field from now on.
	 */
	if (
		fieldType === 'com.atlassian.jpo:jpo-custom-field-parent' &&
		fg('replace-epic-parent-link-by-depreca-message-in-nin')
	) {
		return (
			<Text as="span" color="color.text.subtlest">
				<FormattedMessage
					{...messages.replacedByParentField}
					values={{
						strong: (chunks) => <Text as="strong">{chunks}</Text>,
					}}
				/>
			</Text>
		);
	}

	if (fg('jsc_inline_editing_field_refactor')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const inlineEditData = useFragment<parent_nativeIssueTable_ParentCell_data$key>(
			graphql`
				fragment parent_nativeIssueTable_ParentCell_data on JiraParentIssueField
				@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
					id
					lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
					...parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef
				}
			`,
			data,
		);

		// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
		// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
		const isEditable =
			'lazyIsEditableInIssueView' in inlineEditData && isInlineEditingExtendedFieldSupportEnabled
				? inlineEditData.lazyIsEditableInIssueView
				: false;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useLazyLoadEditability(inlineEditData.id, isEditable);

		return (
			<ParentInlineEditViewIsEditable
				attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
				spacing="default"
				editViewPopup
				editViewPopupAlignBlock="center"
				fragmentRef={inlineEditData}
				isTruncated={!isDensityFull}
				isEditable={isEditable ?? false}
				menuPosition="fixed"
				readViewFitContainerHeight
				// Render an empty string when there is no parent and experiment is off to mimic FG off state
				emptyText={isInlineEditingExtendedFieldSupportEnabled ? undefined : ''}
				isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
				renderHoverPopover={hoverPopoverRenderer}
			/>
		);
	}

	const { parentIssue } = data;

	if (!parentIssue) {
		return null;
	}

	if (parentIssue.key == null || parentIssue.summaryField?.text == null) {
		return <ErrorCell cellType="parent" reason="Missing required fields" />;
	}

	return (
		<ParentReadView
			fragmentRef={data}
			isTruncated={!isDensityFull}
			isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
			renderHoverPopover={hoverPopoverRenderer}
		/>
	);
});
