export const getSSRSafeFlatListWithGrouping = ({
	firstGroupId,
	firstGroupIssueIds = [],
	groupIds = [],
}: {
	firstGroupId?: string | null;
	firstGroupIssueIds?: string[];
	groupIds?: string[];
}) => {
	if (firstGroupId) {
		return [firstGroupId, ...firstGroupIssueIds, ...groupIds.filter((id) => id !== firstGroupId)];
	}

	return groupIds;
};
