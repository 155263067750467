/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss, Inline, Text, Flex } from '@atlaskit/primitives';
import {
	filterNonIssueItems,
	useHierarchyStore,
	useIsItemExpanded,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';

type DragPreviewProps = {
	summary?: string | null;
	issueTypeAvatarUrl?: string | null;
	childCount?: number;
	issueId: string;
	itemsConnectionId?: string;
};

const MAX_ISSUE_COUNT = 99;

export const DragPreview = ({
	summary,
	issueTypeAvatarUrl,
	issueId,
	itemsConnectionId,
}: DragPreviewProps) => {
	const [{ listMetadata, connectionMap, connectionItems }] = useHierarchyStore();
	const [isExpanded] = useIsItemExpanded({
		itemId: issueId,
		itemsConnectionId: itemsConnectionId || '',
	});

	const childCount: number = useMemo(() => {
		if (isExpanded) {
			const itemKey = listMetadata.find((element) => element.itemId === issueId)?.key || '';
			const keyConnection = connectionMap[itemKey];
			const itemConnectionItems = keyConnection ? connectionItems[keyConnection] || [] : [];
			return itemConnectionItems.length
				? filterNonIssueItems(itemConnectionItems.map((item) => item.itemId)).length || 0
				: 0;
		}
		return 0;
	}, [connectionItems, connectionMap, isExpanded, issueId, listMetadata]);

	const showChildIssues = childCount > 0;
	const hasMoreThanOneChild = childCount > 1;

	const countIncludingParent = childCount > 0 ? childCount + 1 : 0;
	const hasLessThanMaxIssueCount = countIncludingParent < MAX_ISSUE_COUNT;
	const roundedIssueCount = hasLessThanMaxIssueCount ? countIncludingParent : MAX_ISSUE_COUNT;

	return summary ? (
		<Box xcss={rootStyles}>
			{showChildIssues ? (
				<>
					{hasMoreThanOneChild ? <Box xcss={[listItemStyles, child2Styles]} /> : null}
					<Box xcss={[listItemStyles, child1Styles]} />
				</>
			) : null}
			<Box xcss={[listItemStyles, primaryItemStyles]}>
				{showChildIssues ? (
					<Flex
						xcss={hasLessThanMaxIssueCount ? badgeStyles : [badgeStyles, smallContentStyles]}
						alignItems="center"
						justifyContent="center"
					>
						{hasLessThanMaxIssueCount ? roundedIssueCount : `${roundedIssueCount}+`}
					</Flex>
				) : null}
				<Inline xcss={innerStyles} space="space.200" alignBlock="center">
					<Inline space="space.100" alignBlock="center">
						{issueTypeAvatarUrl && <img src={issueTypeAvatarUrl} css={imageStyles} alt="" />}
						<Box xcss={textStyle}>
							<Text maxLines={1}>{summary}</Text>
						</Box>
					</Inline>
				</Inline>
			</Box>
		</Box>
	) : null;
};

const MAX_WIDTH = 300;

const rootStyles = xcss({
	position: 'relative',
	width: `${MAX_WIDTH}px`,
});

const listItemStyles = xcss({
	backgroundColor: 'elevation.surface',
	boxShadow: 'elevation.shadow.overlay',
	position: 'absolute',
	height: 'size.400',
	width: `${MAX_WIDTH}px`,
});

const primaryItemStyles = xcss({
	paddingLeft: 'space.200',
	backgroundColor: 'color.background.input',
});

const innerStyles = xcss({
	height: '100%',
});

const textStyle = xcss({
	maxWidth: '220px',
});

const imageStyles = css({
	height: token('space.200'),
	width: token('space.200'),
});

const child1Styles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	left: 'space.075',
	top: 'space.075',
});

const child2Styles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
	left: 'space.150',
	top: 'space.150',
});

const badgeStyles = xcss({
	position: 'absolute',
	top: 'space.negative.150',
	right: 'space.negative.150',
	borderRadius: 'border.radius.circle',
	backgroundColor: 'color.background.accent.red.bolder',
	width: 'size.200',
	height: 'size.200',
	color: 'color.text.inverse',
});

const smallContentStyles = xcss({
	font: 'font.body.small',
});
