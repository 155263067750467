import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { graphql, useFragment, usePaginationFragment } from 'react-relay';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import type { FormPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { childRowsLoaderQuery } from '@atlassian/jira-relay/src/__generated__/childRowsLoaderQuery.graphql';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { childRowsPagination_issueTableHierarchy_query$key } from '@atlassian/jira-relay/src/__generated__/childRowsPagination_issueTableHierarchy_query.graphql';
import type { childRowsPagination_issueTableHierarchy_queryData$key } from '@atlassian/jira-relay/src/__generated__/childRowsPagination_issueTableHierarchy_queryData.graphql';
import { useIsSelected as useIsSelectedNew } from '../../controllers/hierarchy/index.tsx';
import {
	useRegisterConnection,
	type ConnectionMetadata,
} from '../../controllers/connections-list/index.tsx';
import {
	useUpdateHierarchyHasChildrenOnExpand,
	useUpdateGroupIssueCountOnExpand,
} from '../../common/utils/cache-utils/index.tsx';
import { ChildIssueCreateRow as ChildIssueCreateRowNew } from '../child-issue-create-row/index.tsx';
import type { RenderRows, OnCreateIssue, RenderCreateIssueController } from '../types.tsx';

const PACKAGE_LOCATION = 'jira-issue-table-hierarchy-child-rows-pagination';

export type BaseProps = {
	depth: number;
	parentItemId: string;
	itemsConnectionId: string;
	parentIssueAri?: string;
	// `parentItemConnectionId` actually refers to the id of the parent `JiraIssueEdge`, not the connection id
	// it will be renamed in JSC-1333 to match, and `parentIssueRelayConnectionId` will refer to the connection id
	parentItemConnectionId?: string;
	parentIssueRelayConnectionId?: string;
	isFormVisible?: boolean;
	formProps?: FormPropsType;
	connectionMetadata?: ConnectionMetadata;
	projectKey?: string;
	isGroupingSupported?: boolean;
	pageSize: number;
	childItemsExperience: string;
	childItemsLoadMoreExperience: string;
	analyticsSource: string;
	isIssueRankDescending: boolean;
	groupId?: string;
	width: number;
	renderRows: RenderRows;
	renderCreateIssueController: RenderCreateIssueController;
};

export interface RowLoaderProps extends BaseProps {
	issueKey: string;
}

export interface GroupLoaderProps extends BaseProps {
	groupJql: string;
}

export const isGroupLoader = (props: Props): props is GroupLoaderProps =>
	'groupJql' in props && Boolean(props.groupJql);

export type Props = BaseProps & (RowLoaderProps | GroupLoaderProps);

export const ChildRowsPagination = ({
	query,
	depth,
	parentItemId,
	itemsConnectionId,
	parentIssueAri,
	parentItemConnectionId,
	parentIssueRelayConnectionId,
	isFormVisible,
	formProps,
	connectionMetadata,
	projectKey,
	pageSize,
	childItemsExperience,
	childItemsLoadMoreExperience,
	analyticsSource,
	isIssueRankDescending,
	groupId,
	width,
	renderRows,
	renderCreateIssueController,
}: BaseProps & {
	query: childRowsPagination_issueTableHierarchy_query$key;
}) => {
	const {
		data: queryData,
		loadNext,
		isLoadingNext,
		hasNext,
	} = usePaginationFragment<
		childRowsLoaderQuery,
		childRowsPagination_issueTableHierarchy_query$key
	>(
		graphql`
			fragment childRowsPagination_issueTableHierarchy_query on Query
			@refetchable(queryName: "tableBody_issueTableHierarchy_childIssuesPaginationQuery")
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean", defaultValue: true }
				isReparentingEnabled: { type: "Boolean", defaultValue: false }
				isDensityFull: { type: "Boolean", defaultValue: false }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				jira @required(action: THROW) {
					issueSearch(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
						fieldSetsInput: $fieldSetsInput
						viewConfigInput: $viewConfigInput
					)
						@required(action: THROW)
						@connection(key: "hierarchy_nativeIssueTable__issueSearch")
						@optIn(to: "JiraSpreadsheetComponent-M1") {
						__id
						# eslint-disable-next-line @atlassian/relay/unused-fields
						edges {
							__typename # only interested in edge presence to handle empty state
						}
						...childRowsPagination_issueTableHierarchy_queryData
							@arguments(
								isInlineEditingEnabled: $isInlineEditingEnabled
								isReparentingEnabled: $isReparentingEnabled
								isDensityFull: $isDensityFull
								projectKeys: $projectKeys
								projectKey: $projectKey
								shouldQueryHasChildren: $shouldQueryHasChildren
							)
					}
				}
			}
		`,
		query,
	);

	const issueResultsData = useFragment<childRowsPagination_issueTableHierarchy_queryData$key>(
		graphql`
			fragment childRowsPagination_issueTableHierarchy_queryData on JiraIssueConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				__id
				totalIssueSearchResultCount
				isCappingIssueSearchResult
				edges {
					node {
						id
					}
				}
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...rows_nativeIssueTable
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
			}
		`,
		queryData?.jira?.issueSearch,
	);

	const { type } = connectionMetadata || {};
	const isGroupChildren = type === 'GROUP_CHILDREN';

	const onCreateIssueRef = useRef<OnCreateIssue>();
	const setOnCreateIssue = useCallback((onCreateIssue: OnCreateIssue) => {
		onCreateIssueRef.current = onCreateIssue;
	}, []);

	// Set the issue count on the group record if needed
	useUpdateGroupIssueCountOnExpand({
		groupId,
		isGroupChildren,
		totalIssueSearchResultCount: issueResultsData.totalIssueSearchResultCount || 0,
		isCappingIssueSearchResult: issueResultsData.isCappingIssueSearchResult ?? false,
	});

	const onExperienceLoadMoreStart = useExperienceStart({
		experience: childItemsLoadMoreExperience,
		analyticsSource,
	});

	const onExperienceLoadMoreFail = useExperienceFail({
		experience: childItemsLoadMoreExperience,
		attributes: {},
	});

	const onExperienceLoadMoreSuccess = useExperienceSuccess({
		experience: childItemsLoadMoreExperience,
		attributes: {},
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience: childItemsExperience,
		attributes: {},
	});

	useEffect(() => {
		onExperienceSuccess();
	}, [onExperienceSuccess]);

	const childConnectionId = issueResultsData.__id;

	const [isSelected] = useIsSelectedNew({
		itemsConnectionId,
		groupId,
		itemId: parentItemId,
	});
	const siblingIssueId = issueResultsData.edges?.[0]?.node?.id;

	const isChildIssueCreateRowVisible =
		isSelected && !!parentIssueAri && !!parentItemConnectionId && !!formProps && isFormVisible;

	if (
		expValEquals('jira_spreadsheet_component_m1', 'cohort', 'experiment') &&
		expVal('jira_list_realtime_updates', 'isEnabled', false)
	) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useUpdateHierarchyHasChildrenOnExpand({
			connectionMetadata,
			projectKey,
			childCount: issueResultsData.edges?.length ?? 0,
		});
	}

	if (fg('decouple_connection_register_hooks_from_realtime')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const connectionDetails = useMemo(() => {
			if (
				issueResultsData?.edges?.length === undefined ||
				(issueResultsData.edges.length === 0 && !isChildIssueCreateRowVisible) ||
				!connectionMetadata
			) {
				return undefined;
			}

			return {
				connectionId: childConnectionId,
				// This is the value the backend uses to fetch the issue list for realtime updates. It should be the connection length + 1
				// to ensure a potentially newly created issue is also fetched and included in the results.
				first: issueResultsData.edges.length + 1,
				...connectionMetadata,
			};
		}, [
			childConnectionId,
			connectionMetadata,
			isChildIssueCreateRowVisible,
			issueResultsData.edges?.length,
		]);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useRegisterConnection(connectionDetails);
	}

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onCompleteLoadNextItems = useCallback(
		(error: Error | null) => {
			if (!error) {
				onExperienceLoadMoreSuccess();
			} else {
				onExperienceLoadMoreFail(PACKAGE_LOCATION, error);
			}
		},
		[onExperienceLoadMoreFail, onExperienceLoadMoreSuccess],
	);

	const loadNextItems = useCallback(() => {
		onExperienceLoadMoreStart();
		const actionSubjectId = isGroupChildren ? 'groupingLoadMore' : 'hierarchyLoadMore';
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', actionSubjectId);
		loadNext(pageSize, { onComplete: onCompleteLoadNextItems });
	}, [
		pageSize,
		onExperienceLoadMoreStart,
		isGroupChildren,
		createAnalyticsEvent,
		loadNext,
		onCompleteLoadNextItems,
	]);

	const renderChildIssueCreateRow = useCallback(
		() =>
			isChildIssueCreateRowVisible && onCreateIssueRef.current ? (
				<ChildIssueCreateRowNew
					depth={depth}
					parentIssueAri={parentIssueAri}
					siblingIssueId={siblingIssueId}
					isFormVisible={isFormVisible ?? false}
					{...formProps}
					width={width}
					isIssueRankDescending={isIssueRankDescending}
					onCreateIssue={onCreateIssueRef.current}
				/>
			) : null,
		[
			isIssueRankDescending,
			depth,
			formProps,
			isChildIssueCreateRowVisible,
			isFormVisible,
			parentIssueAri,
			siblingIssueId,
			width,
		],
	);

	return (
		<>
			{renderCreateIssueController({
				childConnectionId: issueResultsData.__id,
				parentItemConnectionId,
				setOnCreateIssue,
			})}
			{renderRows({
				issueResults: issueResultsData,
				depth: depth + 1,
				parentItemId,
				itemsConnectionId,
				parentIssueAri,
				parentItemConnectionId,
				isLoadingNext,
				hasNext,
				onLoadNextItems: loadNextItems,
				renderChildIssueCreateRow,
				isChildIssueCreateRowVisible,
				parentIssueRelayConnectionId,
				...(fg('jsc_list_reparenting') ? { projectKey } : {}),
			})}
		</>
	);
};
