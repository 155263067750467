import type { Actions, RowIndex } from './types.tsx';

const actions: Actions = {
	setSelectedRow:
		(rowIndex) =>
		({ getState, setState }, { onRowChange }) => {
			const { selectedRowIndex, amountOfRows } = getState();
			if (selectedRowIndex !== rowIndex && amountOfRows > 0) {
				setState({ selectedRowIndex: rowIndex, focusedRowIndex: rowIndex });
				onRowChange && onRowChange(rowIndex);
			}
		},
	resetFocus:
		() =>
		({ getState, setState }) => {
			const { focusedRowIndex } = getState();
			focusedRowIndex !== null && setState({ focusedRowIndex: null });
		},
	setAmountOfRows:
		(newAmountOfRows) =>
		({ setState, getState }) => {
			const { amountOfRows } = getState();
			amountOfRows !== newAmountOfRows && setState({ amountOfRows: newAmountOfRows });
		},
	selectNextRow:
		() =>
		({ getState, dispatch }, { onNextPage }) => {
			const { amountOfRows, selectedRowIndex } = getState();
			if (amountOfRows === 0) {
				return;
			}
			// edge case: we have only blank issues in the list, we want to make it key accessible
			if (selectedRowIndex === null) {
				dispatch(actions.setSelectedRow(0));
			} else if (selectedRowIndex < amountOfRows - 1) {
				dispatch(actions.setSelectedRow(selectedRowIndex + 1));
			} else if (selectedRowIndex === amountOfRows - 1 && onNextPage) {
				onNextPage();
			}
		},
	selectPrevRow:
		() =>
		({ getState, dispatch }, { onPreviousPage }) => {
			const { selectedRowIndex, amountOfRows } = getState();
			if (amountOfRows === 0) {
				return;
			}
			// edge case: we have only one blank issues in the list, we want to make it key accessible
			if (selectedRowIndex === null) {
				dispatch(actions.setSelectedRow(0));
			} else if (selectedRowIndex > 0) {
				dispatch(actions.setSelectedRow(selectedRowIndex - 1));
			} else if (selectedRowIndex === 0 && onPreviousPage) {
				onPreviousPage();
			}
		},
	checkRow:
		(rowIndex: RowIndex, isChecked: boolean, isShiftPressed: boolean) =>
		(_, { onCheckRow }) => {
			onCheckRow && onCheckRow(rowIndex, isChecked, isShiftPressed);
		},
};

export default actions;
