import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	checkboxAriaLabel: {
		id: 'native-issue-table.common.issue-cells.checkbox.checkbox-aria-label',
		defaultMessage: 'Issue checkbox',
		description: 'Aria label for the issue row checkbox',
	},
	checkboxAriaLabelIssueTermRefresh: {
		id: 'native-issue-table.common.issue-cells.checkbox.checkbox-aria-label-issue-term-refresh',
		defaultMessage: 'Work item checkbox',
		description: 'Aria label for the issue row checkbox',
	},
	headerCheckboxAriaLabel: {
		id: 'native-issue-table.common.issue-cells.checkbox.header-checkbox-aria-label',
		defaultMessage: 'Select all checkbox',
		description: 'Aria label for the header row checkbox used to select all issues on the page',
	},
});
