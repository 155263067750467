import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { TagColor } from '@atlaskit/tag';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	DEFAULT_EPIC_COLOR,
	MAP_COLOR_TO_AK_COLOR,
} from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type { Color, ColorSchema } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import {
	asColor,
	transformColorToTheme,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { epicLink_nativeIssueTable_EpicLinkCell$key } from '@atlassian/jira-relay/src/__generated__/epicLink_nativeIssueTable_EpicLinkCell.graphql';
import ErrorCell from '../../error-cell/index.tsx';
import messages from './messages.tsx';

type LozengeThemes = {
	backgroundColor: string;
	color: string;
};

const getLozengeThemes: Record<Color, LozengeThemes> = transformColorToTheme(
	(colorSchema: ColorSchema) => ({
		color: colorSchema.text,
		backgroundColor: colorSchema.textBackground,
	}),
);

const getLozengeThemeByColor = (color: Color = DEFAULT_EPIC_COLOR): LozengeThemes =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getLozengeThemes[color] ?? (getLozengeThemes[DEFAULT_EPIC_COLOR] as LozengeThemes);

// type assertion is fine as the record key is of type Color
const findColor = (color: TagColor) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(Object.keys(MAP_COLOR_TO_AK_COLOR) as Array<Color>).find(
		(key) => MAP_COLOR_TO_AK_COLOR[key] === color,
	);

export type Props = {
	fieldRef: epicLink_nativeIssueTable_EpicLinkCell$key;
};
export const EpicLinkCell = memo<Props>(({ fieldRef }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { epic } = useFragment<epicLink_nativeIssueTable_EpicLinkCell$key>(
		graphql`
			fragment epicLink_nativeIssueTable_EpicLinkCell on JiraEpicLinkField {
				epic {
					key
					summary
					color
				}
			}
		`,
		fieldRef,
	);

	/**
	 * As part of the initiative to remove "Epic Link" and "Parent Link" fields from Jira,
	 * we're rendering a "Replaced by Parent field" deprecation message in the "Epic Link" cell.
	 * At the time of writing this comment, "Epic Link" is still used in ~6% of issue tables, and
	 * we hope this message will help these users migrate and use the "Parent" field from now on.
	 */
	if (fg('replace-epic-parent-link-by-depreca-message-in-nin')) {
		return (
			<Text as="span" color="color.text.subtlest">
				<FormattedMessage
					{...messages.replacedByParentField}
					values={{
						strong: (chunks) => <Text as="strong">{chunks}</Text>,
					}}
				/>
			</Text>
		);
	}

	if (!epic) {
		return null;
	}

	if (epic.key == null || epic.summary == null) {
		return <ErrorCell cellType="epicLink" reason="Missing required fields" />;
	}

	const epicColor = asColor(epic.color);
	const tagColor = MAP_COLOR_TO_AK_COLOR[epicColor];

	return (
		<TagWrapper
			href={`/browse/${epic.key}`}
			aria-label={`${formatMessage(messages.epicTitle)} ${epic.summary}`}
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={getLozengeThemeByColor(findColor(tagColor))}
			onClick={() => {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'link',
					}),
					'tableIssueLink',
				);
			}}
		>
			{epic.summary}
		</TagWrapper>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagWrapper = styled.a({
	// inline-block is needed to truncate with ellipses
	display: 'inline-block',
	// needed because of display: inline-block
	verticalAlign: 'middle',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	maxWidth: '100%',
	boxSizing: 'border-box',
	'&:hover': {
		textDecoration: 'underline',
	},
});
