import React, { Fragment, memo, useMemo, type ReactNode } from 'react';
import castArray from 'lodash/castArray';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss, type XCSS } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { TriggerPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import type { issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld$key } from '@atlassian/jira-relay/src/__generated__/issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld.graphql';
import type { issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets$key } from '@atlassian/jira-relay/src/__generated__/issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets.graphql';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge$key } from '@atlassian/jira-relay/src/__generated__/issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge.graphql';
import type { issueRow_nativeIssueTable_IssueRowWithFragments_issue$key } from '@atlassian/jira-relay/src/__generated__/issueRow_nativeIssueTable_IssueRowWithFragments_issue.graphql';
import {
	COLUMN_ID_MEATBALL_MENU,
	COLUMN_ID_EXPAND_BUTTON,
	KNOWN_COLUMN_TYPES,
	COLUMN_ID_CHECKBOX,
} from '../../../common/constants.tsx';
import type { Column } from '../../../common/types.tsx';
import { AffectedServicesCell } from '../../../common/ui/issue-cells/affected-services/index.tsx';
import { LoadingCell } from '../../../common/ui/issue-cells/loading-cell/index.tsx';
import { CheckboxCell } from '../../../common/ui/issue-cells/checkbox/index.tsx';
import { CheckboxSelectCell } from '../../../common/ui/issue-cells/checkbox-select/index.tsx';
import { CmdbObjectsCell } from '../../../common/ui/issue-cells/cmdb-objects/index.tsx';
import {
	ComponentsCell,
	ComponentsInlineEditCell,
} from '../../../common/ui/issue-cells/components/index.tsx';
import { CascadingSelectCell } from '../../../common/ui/issue-cells/cascading-select/index.tsx';
import { DateCell, DateInlineEditCell } from '../../../common/ui/issue-cells/date/index.tsx';
import { DateTimeCell } from '../../../common/ui/issue-cells/date-time/index.tsx';
import { EpicLinkCell } from '../../../common/ui/issue-cells/epic-link/index.tsx';
import { FallbackCell } from '../../../common/ui/issue-cells/fallback/index.tsx';
import { IssueKeyCell } from '../../../common/ui/issue-cells/issue-key/index.tsx';
import { IssueLinksCell } from '../../../common/ui/issue-cells/issue-links/index.tsx';
import { IssueOperations } from '../../../common/ui/issue-cells/issue-operations/index.tsx';
import { IssueStatusCategoryCell } from '../../../common/ui/issue-cells/issue-status-category/index.tsx';
import { IssueStatusCell } from '../../../common/ui/issue-cells/issue-status/index.tsx';
import { IssueSummaryCell } from '../../../common/ui/issue-cells/issue-summary/index.tsx';
import { IssueTypeCell } from '../../../common/ui/issue-cells/issue-type/index.tsx';
import { LabelsCell } from '../../../common/ui/issue-cells/labels/index.tsx';
import { MultiSelectCell } from '../../../common/ui/issue-cells/multi-select/index.tsx';
import { MultiUserPickerCell } from '../../../common/ui/issue-cells/multi-user-picker/index.tsx';
import { NumberCell } from '../../../common/ui/issue-cells/number/index.tsx';
import { OriginalEstimateCell } from '../../../common/ui/issue-cells/original-estimate/index.tsx';
import { ParentCell } from '../../../common/ui/issue-cells/parent/index.tsx';
import {
	PlainTextCell,
	PlainTextCellOld,
} from '../../../common/ui/issue-cells/plain-text/index.tsx';
import { PriorityCell } from '../../../common/ui/issue-cells/priority/index.tsx';
import { ProjectCell } from '../../../common/ui/issue-cells/project/index.tsx';
import { RadioSelectCell } from '../../../common/ui/issue-cells/radio-select/index.tsx';
import { RequestTypeCell } from '../../../common/ui/issue-cells/request-type/index.tsx';
import { ResolutionCell } from '../../../common/ui/issue-cells/resolution/index.tsx';
import { RichTextCell } from '../../../common/ui/issue-cells/rich-text/index.tsx';
import { SingleSelectCell } from '../../../common/ui/issue-cells/single-select/index.tsx';
import { SprintCell } from '../../../common/ui/issue-cells/sprint/index.tsx';
import { TeamCell } from '../../../common/ui/issue-cells/team/index.tsx';
import { UserCell } from '../../../common/ui/issue-cells/user/index.tsx';
import { VersionsCell } from '../../../common/ui/issue-cells/versions/index.tsx';
import { TableCell, type Props as TableCellProps } from '../../../common/ui/table-cell/index.tsx';
import { getProjectKeyFromIssueKey, isNonNullish } from '../../../common/utils/index.tsx';
import {
	useIsFixedTableLayoutEnabled,
	useIsInlineEditingExtendedFieldSupportEnabled,
	useIsIssueHierarchyEnabled,
	useIsIssueOperationMeatballMenuEnabled,
} from '../../../controllers/features/selectors.tsx';
import { useRowContext } from '../../rows/row-context/index.tsx';
import { CommentSummaryCell } from '../../../common/ui/issue-cells/comment-summary/index.tsx';
import { UrlCell } from '../../../common/ui/issue-cells/url/index.tsx';

import { MultiVersionPickerCell } from '../../../common/ui/issue-cells/multi-version-picker/index.tsx';
import IssueRowCell from './cell/index.tsx';
import FirstColumnCell from './first-column-cell/index.tsx';

export type Props = {
	issue: issueRow_nativeIssueTable_IssueRowWithFragments_issue$key;
	// remove null case when cleaning up jsc_m2_hierarchy_fe_changes
	issueEdge: issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge$key | null;
	fieldSets: issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets$key | null;
	cellBorder?: TableCellProps['border'];
	/**
	 * Element to render within the first `td` of the issue row. This is to enable rendering of absolute/fixed position
	 * elements relative to the start of the table row while retaining semantically valid HTML.
	 */
	beforeFirstCell?: ReactNode;
	itemsConnectionId: string;
	depth: number;
	isRowHoveredOrSelected?: boolean;
	rowIndex?: number; // TODO Remove optional when cleaning bulk_operations_in_nin
	triggerProps?: TriggerPropsType;
	isFormVisible?: boolean;
};

export const IssueRowWithFragments = ({
	issue,
	issueEdge,
	fieldSets: fieldSetsFragment,
	cellBorder,
	beforeFirstCell,
	itemsConnectionId,
	depth,
	isRowHoveredOrSelected,
	rowIndex,
	triggerProps,
	isFormVisible,
}: Props) => {
	const issueDataOld = useFragment<issueRow_nativeIssueTable_IssueRowWithFragments_issue$key>(
		graphql`
			fragment issueRow_nativeIssueTable_IssueRowWithFragments_issue on JiraIssue
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isJscInfiniteScrollEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider"
				}
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
				isDensityFull: { type: "Boolean!" }
			) {
				key
				issueId
				issueTypeField @include(if: $isJscM2Enabled) {
					issueType {
						hierarchy {
							level
						}
					}
				}
				...issueSummary_nativeIssueTable_IssueSummaryCell_issueRef
				...issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef
				...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld
					@skip(if: $isJscInfiniteScrollEnabled)
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled, isDensityFull: $isDensityFull)
				...richText_nativeIssueTable_RichTextCell_issueRef @arguments(isDensityFull: $isDensityFull)
			}
		`,
		issue,
	);

	const issueEdgeData = useFragment<issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge$key>(
		graphql`
			fragment issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge on JiraIssueEdge
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isJscInfiniteScrollEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider"
				}
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...firstColumnCell_nativeIssueTable
					@arguments(
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				node @required(action: THROW) {
					key
					issueId
					issueTypeField @include(if: $isJscM2Enabled) {
						issueType {
							hierarchy {
								level
							}
						}
					}
					...issueSummary_nativeIssueTable_IssueSummaryCell_issueRef
					...issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef
					...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld
						@skip(if: $isJscInfiniteScrollEnabled)
						@arguments(
							isInlineEditingEnabled: $isInlineEditingEnabled
							isDensityFull: $isDensityFull
						)
					...richText_nativeIssueTable_RichTextCell_issueRef
						@arguments(isDensityFull: $isDensityFull)
				}
			}
		`,
		issueEdge,
	);
	const issueData = fg('jsc_m2_hierarchy_fe_changes')
		? // Clean up issueDataOld on experiment cleanup, this will be safe as we the edge data will be made required
			issueEdgeData?.node ?? issueDataOld
		: issueDataOld;

	const fieldSetConnectionOld =
		useFragment<issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld$key>(
			graphql`
				fragment issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld on JiraIssue
				@argumentDefinitions(
					isInlineEditingEnabled: { type: "Boolean!" }
					isDensityFull: { type: "Boolean!" }
				) {
					fieldSetsById(fieldSetIds: $fieldSetIds, first: $amountOfColumns)
						@required(action: THROW)
						@include(if: $shouldQueryFieldSetsById) {
						...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
							@arguments(
								isInlineEditingEnabled: $isInlineEditingEnabled
								isDensityFull: $isDensityFull
							)
					}
					fieldSetsForIssueSearchView(
						namespace: $namespace
						viewId: $viewId
						first: $amountOfColumns
						filterId: $filterId
						context: $fieldSetsContext
					) @required(action: THROW) @skip(if: $shouldQueryFieldSetsById) {
						...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
							@arguments(
								isInlineEditingEnabled: $isInlineEditingEnabled
								isDensityFull: $isDensityFull
							)
					}
				}
			`,
			expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
				? null
				: issueData ?? null,
		);

	const fieldSets = expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? fieldSetsFragment
		: fieldSetConnectionOld?.fieldSetsById ??
			fieldSetConnectionOld?.fieldSetsForIssueSearchView ??
			null;

	const fieldSetsData = useFragment<issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets$key>(
		graphql`
			fragment issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets on JiraIssueFieldSetConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				isNikeChinStoryPointsInlineEditEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider"
				}
				isJscInlineEditRefactorEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider"
				}
			) {
				edges {
					node {
						type
						fieldSetId
						fields {
							... on JiraIssueFieldConnection {
								edges {
									node {
										__typename
										fieldId
										type
										...affectedServices_nativeIssueTable_AffectedServicesCell
										...cascadingSelect_nativeIssueTable_CascadingSelectCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...cmdbObjects_nativeIssueTable_CmdbObjectsCell
										...components_nativeIssueTable_ComponentsCell_fieldRef
											@skip(if: $isJscInlineEditRefactorEnabled)
										...components_nativeIssueTable_ComponentsInlineEditCell_fieldRef
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...date_nativeIssueTable_DateCell
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...date_nativeIssueTable_DateInlineEditCell_fieldRef
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...dateTime_nativeIssueTable_DateTimeCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...epicLink_nativeIssueTable_EpicLinkCell
										...fallback_nativeIssueTable_FallbackCell
										...issueKey_nativeIssueTable_IssueKeyCell_fieldRef
										...issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell
										...issueStatus_nativeIssueTable_IssueStatusCell
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...labels_nativeIssueTable_LabelsCell
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...issueType_nativeIssueTable_IssueTypeCell
										...parent_nativeIssueTable_ParentCell_fieldRef
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...priority_nativeIssueTable_PriorityCell
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...project_nativeIssueTable_ProjectCell
										...resolution_nativeIssueTable_ResolutionCell
										...sprint_nativeIssueTable_SprintCell
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...user_nativeIssueTable_UserCell_fieldRef
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...versions_nativeIssueTable_VersionsCell
										...plainText_nativeIssueTable_PlainTextCell_fieldRef
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...plainText_nativeIssueTable_PlainTextCellOld_fieldRef
											@skip(if: $isJscInlineEditRefactorEnabled)
										...richText_nativeIssueTable_RichTextCell_fieldRef
											@arguments(isDensityFull: $isDensityFull)
										...requestType_nativeIssueTable_RequestTypeCell
										...issueLinks_nativeIssueTable_IssueLinksCell
										...number_nativeIssueTable_NumberCell_fieldRef
											@include(if: $isNikeChinStoryPointsInlineEditEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...commentSummary_nativeIssueTable_CommentSummaryCell
											@include(if: $isJscInlineEditRefactorEnabled)
										...url_nativeIssueTable_UrlCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...singleSelect_nativeIssueTable_SingleSelectCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...multiSelect_nativeIssueTable_MultiSelectCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...checkboxSelect_nativeIssueTable_CheckboxSelectCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...multiUserPicker_nativeIssueTable_MultiUserPickerCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...team_nativeIssueTable_TeamCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...radioSelect_nativeIssueTable_RadioSelectCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
										...multiVersionPicker_nativeIssueTable_MultiVersionPickerCell
											@include(if: $isJscInlineEditRefactorEnabled)
											@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
									}
								}
							}
						}
					}
				}
			}
		`,
		fieldSets,
	);

	const isCreateSubTaskAllowed =
		issueData && issueData.issueTypeField?.issueType?.hierarchy?.level === 0;

	const { columns, tableInjectedProps } = useRowContext();

	const columnConfigByIdMap = useMemo(() => {
		const result = new Map<string | null, Column>();
		columns.forEach((columnConfig) => {
			result.set(columnConfig.id, columnConfig);
		});
		return result;
	}, [columns]);

	let isIssueHierarchyEnabled = false;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueHierarchyEnabled = useIsIssueHierarchyEnabled();
	}

	const isIssueOperationMeatballMenuEnabled = useIsIssueOperationMeatballMenuEnabled();
	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();

	const fieldElementByIdMap = useMemo(() => {
		// Map all issue fields into their respective cell type keyed by fieldSetId. This will be used as a lookup when
		// mapping table columns in the row.
		const result = new Map<
			string,
			{
				cellElement: ReactNode;
				spacing?: XCSS;
			}
		>();
		const projectKey = getProjectKeyFromIssueKey(issueData.key);

		if (isIssueHierarchyEnabled && issueEdgeData && fg('jsc_m2_hierarchy_fe_changes')) {
			const element = (
				<FirstColumnCell
					isRowHoveredOrSelected={Boolean(isRowHoveredOrSelected)}
					itemsConnectionId={itemsConnectionId}
					depth={depth}
					issueRef={issueEdgeData}
					triggerProps={triggerProps}
					isFormVisible={isFormVisible}
				/>
			);
			result.set(COLUMN_ID_EXPAND_BUTTON, {
				cellElement: element,
				spacing: noSpacingStyles,
			});
		}

		fieldSetsData?.edges
			?.map((edge) => edge?.node)
			.filter(isNonNullish)
			.forEach((fieldSet) => {
				// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string | null'.
				const columnConfig = columnConfigByIdMap.get(fieldSet.fieldSetId);
				if (!fieldSet.fieldSetId || !columnConfig) {
					return;
				}

				// Handle Collapsed Fields on a single issue (rare) by simply rendering a node for each field.
				const fieldElements: ReactNode[] = [];
				// Allow td spacing to be configured for the given field type
				let spacing: XCSS | undefined;
				fieldSet.fields?.edges?.forEach((fieldEdge) => {
					switch (fieldEdge?.node?.__typename) {
						case 'JiraAffectedServicesField':
							fieldElements.push(
								<AffectedServicesCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							break;
						case 'JiraCascadingSelectField':
							fieldElements.push(
								<CascadingSelectCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							spacing = noSpacingStyles;
							break;
						case 'JiraCMDBField':
							fieldElements.push(
								<CmdbObjectsCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);

							break;
						case 'JiraCheckboxesField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<CheckboxSelectCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraComponentsField':
							if (fg('jsc_inline_editing_field_refactor')) {
								spacing = noSpacingStyles;
								fieldElements.push(
									<ComponentsInlineEditCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										issueSearchBaseUrl={tableInjectedProps.issueSearchBaseUrl}
									/>,
								);
							} else {
								fieldElements.push(
									<ComponentsCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										issueSearchBaseUrl={tableInjectedProps.issueSearchBaseUrl}
										jqlTerm={columnConfig.jqlTerm}
										projectKey={projectKey}
									/>,
								);
							}
							break;
						case 'JiraDatePickerField':
							if (isInlineEditingExtendedFieldSupportEnabled) {
								fieldElements.push(
									<DateInlineEditCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							} else {
								fieldElements.push(
									<DateCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								if (fieldEdge.node.fieldId === KNOWN_COLUMN_TYPES.DUE_DATE) {
									spacing = noSpacingStyles;
								}
							}

							break;
						case 'JiraDateTimePickerField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<DateTimeCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							} else {
								fieldElements.push(
									<DateCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										isRequired={
											fieldEdge.node.type === KNOWN_COLUMN_TYPES.CREATED ||
											fieldEdge.node.type === KNOWN_COLUMN_TYPES.UPDATED
										}
									/>,
								);
							}
							break;
						case 'JiraEpicLinkField':
							fieldElements.push(
								<EpicLinkCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);

							break;
						case 'JiraFallbackField':
							fieldElements.push(
								<FallbackCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							spacing = noSpacingStyles;
							break;
						case 'JiraIssueTypeField':
							fieldElements.push(
								<IssueTypeCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							break;
						case 'JiraLabelsField':
							fieldElements.push(
								<LabelsCell
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									issueSearchBaseUrl={tableInjectedProps.issueSearchBaseUrl}
									jqlTerm={columnConfig.jqlTerm}
								/>,
							);
							if (
								fieldEdge.node.fieldId === KNOWN_COLUMN_TYPES.LABELS ||
								fg('jsc_inline_editing_field_refactor')
							) {
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraMultipleSelectField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<MultiSelectCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraMultipleSelectUserPickerField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<MultiUserPickerCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraNumberField':
							if (fg('nike_chin_story_points_inline_edit')) {
								fieldElements.push(
									<NumberCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);

								spacing = noSpacingStyles;
							}
							break;
						case 'JiraParentIssueField':
							fieldElements.push(
								<ParentCell
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									fieldType={fieldEdge.node.type}
								/>,
							);
							if (fg('jsc_inline_editing_field_refactor')) {
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraPriorityField':
							fieldElements.push(
								<PriorityCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);

							spacing = noSpacingStyles;
							break;
						case 'JiraProjectField':
							fieldElements.push(
								<ProjectCell
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									isRequired={fieldEdge.node.type === KNOWN_COLUMN_TYPES.PROJECT}
								/>,
							);
							break;
						case 'JiraResolutionField':
							fieldElements.push(
								<ResolutionCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							break;
						case 'JiraRichTextField':
							fieldElements.push(
								<RichTextCell
									issueKey={issueData.key}
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									issueRef={issueData}
								/>,
							);
							spacing = noSpacingStyles;
							break;
						case 'JiraSingleLineTextField':
							if (fieldEdge.node.type === KNOWN_COLUMN_TYPES.ISSUE_KEY) {
								fieldElements.push(
									<IssueKeyCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										issueRef={issueData}
										onIssueLinkSelected={tableInjectedProps.onIssueLinkSelected}
									/>,
								);
								spacing = noSpacingStyles;
							} else if (fieldEdge.node.type === KNOWN_COLUMN_TYPES.SUMMARY) {
								fieldElements.push(
									<IssueSummaryCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										issueRef={issueData}
										onIssueLinkSelected={tableInjectedProps.onIssueLinkSelected}
									/>,
								);
								spacing = noSpacingStyles;
							} else {
								if (fg('jsc_inline_editing_field_refactor')) {
									fieldElements.push(
										<PlainTextCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
									);
								} else {
									fieldElements.push(
										<PlainTextCellOld key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
									);
								}
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraSingleSelectUserPickerField':
							fieldElements.push(
								<UserCell
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									projectKey={projectKey}
								/>,
							);
							if (
								fieldEdge.node.fieldId === 'assignee' ||
								fieldEdge.node.fieldId === 'reporter' ||
								fg('jsc_inline_editing_field_refactor')
							) {
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraSingleSelectField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<SingleSelectCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraSprintField':
							fieldElements.push(
								<SprintCell
									key={fieldEdge.node.fieldId}
									projectKey={toProjectKey(projectKey)}
									fieldRef={fieldEdge.node}
									issueSearchBaseUrl={tableInjectedProps.issueSearchBaseUrl}
									jqlTerm={columnConfig.jqlTerm}
								/>,
							);
							spacing = noSpacingStyles;
							break;
						case 'JiraStatusCategoryField':
							fieldElements.push(
								<IssueStatusCategoryCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							break;
						case 'JiraStatusField':
							fieldElements.push(
								<IssueStatusCell
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									issueKey={issueData.key}
									issueId={issueData.issueId}
									onUpdateCache={tableInjectedProps.onUpdateCache}
								/>,
							);
							break;
						case 'JiraServiceManagementRequestTypeField':
							fieldElements.push(
								<RequestTypeCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
							);
							break;
						case 'JiraTeamViewField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<TeamCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraIssueLinkField':
							fieldElements.push(
								<IssueLinksCell
									key={fieldEdge.node.fieldId}
									fieldRef={fieldEdge.node}
									issueSearchBaseUrl={tableInjectedProps.issueSearchBaseUrl}
									issueKey={issueData.key}
								/>,
							);
							break;
						case 'JiraCommentSummaryField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<CommentSummaryCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										issueKey={issueData.key}
										onIssueLinkSelected={tableInjectedProps.onIssueLinkSelected}
									/>,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraUrlField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<UrlCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraOriginalTimeEstimateField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<OriginalEstimateCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraRadioSelectField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<RadioSelectCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							}
							break;
						case 'JiraMultipleVersionPickerField':
							if (fg('jsc_inline_editing_field_refactor')) {
								fieldElements.push(
									<MultiVersionPickerCell key={fieldEdge.node.fieldId} fieldRef={fieldEdge.node} />,
								);
								spacing = noSpacingStyles;
							} else {
								fieldElements.push(
									<VersionsCell
										key={fieldEdge.node.fieldId}
										fieldRef={fieldEdge.node}
										issueSearchBaseUrl={tableInjectedProps.issueSearchBaseUrl}
										jqlTerm={columnConfig.jqlTerm}
										projectKey={projectKey}
									/>,
								);
							}
							break;
						default:
							// Unrecognised field type encountered. Render empty cell.
							break;
					}
				});
				if (fieldElements.length) {
					result.set(fieldSet.fieldSetId, {
						cellElement: fieldElements,
						spacing,
					});
				}
			});

		// Always include the Issue Operations meatball menu cell.
		// But show it empty if isIssueOperationMeatballMenuEnabled is false
		result.set(COLUMN_ID_MEATBALL_MENU, {
			cellElement: isIssueOperationMeatballMenuEnabled && (
				<IssueOperations
					key={COLUMN_ID_MEATBALL_MENU}
					issueId={Number(issueData.issueId)}
					issueKey={issueData.key}
					onIssueRefetch={tableInjectedProps.onIssueRefetch}
					onIssueDelete={tableInjectedProps.onIssueDelete}
					isCreateSubTaskAllowed={isCreateSubTaskAllowed}
				/>
			),
		});

		// TODO Remove rowIndex !== undefined when cleaning up bulk_operations_in_nin
		if (rowIndex !== undefined && expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)) {
			result.set(COLUMN_ID_CHECKBOX, {
				cellElement: <CheckboxCell issueId={issueData.issueId} rowIndex={rowIndex} />,
			});
		}

		return result;
	}, [
		issueData,
		isIssueHierarchyEnabled,
		issueEdgeData,
		fieldSetsData?.edges,
		isIssueOperationMeatballMenuEnabled,
		tableInjectedProps.onIssueRefetch,
		tableInjectedProps.onIssueDelete,
		tableInjectedProps.issueSearchBaseUrl,
		tableInjectedProps.onUpdateCache,
		tableInjectedProps.onIssueLinkSelected,
		isCreateSubTaskAllowed,
		rowIndex,
		isRowHoveredOrSelected,
		itemsConnectionId,
		depth,
		triggerProps,
		isFormVisible,
		columnConfigByIdMap,
		isInlineEditingExtendedFieldSupportEnabled,
	]);

	const cellBorderWithNoInlineBordersStyles = useMemo(() => {
		if (cellBorder === undefined) {
			return noInlineBordersStyles;
		}
		return [...castArray(cellBorder), noInlineBordersStyles];
	}, [cellBorder]);

	const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();

	return (
		<>
			{columns.map(({ id, type, isPlaceHolderField }, index) => {
				const field = fieldElementByIdMap.get(id);
				return (
					<Fragment key={id}>
						{
							// TODO: EM-7165 – Update the extra filler cell logic when refactoring the column picker column
							// Empty cell to fill remainig space if configured columns are not wide enough to fill the table
							isFixedTableLayoutEnabled &&
								id === COLUMN_ID_MEATBALL_MENU &&
								!fg('jira_list_grid_pattern_keyboard_nav') && (
									<TableCell border={cellBorderWithNoInlineBordersStyles} aria-hidden />
								)
						}

						{
							// TODO: EM-7165 – Update the extra filler cell logic when refactoring the column picker column
							// Empty cell to fill remainig space if configured columns are not wide enough to fill the table
							isFixedTableLayoutEnabled &&
								id === COLUMN_ID_MEATBALL_MENU &&
								fg('jira_list_grid_pattern_keyboard_nav') && (
									// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
									<Box as="td" xcss={cellBorderWithNoInlineBordersStyles} aria-hidden />
								)
						}
						{isPlaceHolderField && fg('jira_inline_field_config_gate') ? (
							<LoadingCell />
						) : (
							<IssueRowCell
								type={type}
								beforeElement={index === 0 ? beforeFirstCell : undefined}
								cellElement={field?.cellElement}
								spacing={field?.spacing}
								border={cellBorder}
								{...(fg('jira_list_grid_pattern_keyboard_nav') && {
									row: rowIndex,
									column: index,
								})}
							/>
						)}
					</Fragment>
				);
			})}
		</>
	);
};

const noSpacingStyles = xcss({
	padding: 'space.0',
});

const noInlineBordersStyles = xcss({
	borderLeftWidth: '0',
	borderRightWidth: '0',
});

export const IssueRow = memo(IssueRowWithFragments);
