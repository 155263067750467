import isNumber from 'lodash/isNumber';
import {
	MAKE_CHILD,
	REORDER_ABOVE,
	REORDER_BELOW,
	SUBTASK_HIERARCHY_LEVEL,
} from '../../common/constants.tsx';
import type { BlockedInstructions, DraggableRowProps, DraggableRowData } from './types.tsx';

export const getBlockedInstructions = ({
	dropTarget,
	draggedItem,
	isRowReorderingEnabled,
}: {
	dropTarget?: DraggableRowProps;
	draggedItem?: DraggableRowData;
	isRowReorderingEnabled: Boolean;
}): BlockedInstructions => {
	// We don't support reordering and reparenting
	if (isRowReorderingEnabled) {
		return [MAKE_CHILD];
	}

	const draggedItemLevel = draggedItem?.hierarchyLevel;
	const dropTargetLevel = dropTarget?.hierarchyLevel;
	const dragItemHasParent = !!draggedItem?.parentIssueAri;
	const dropTargetHasParent = !!dropTarget?.parentIssueAri;

	if (!isNumber(dropTargetLevel) || !isNumber(draggedItemLevel)) {
		return [];
	}

	const dragItemParentIsDropTarget =
		String(dropTarget?.issueId) === String(draggedItem?.parentIssueAri);

	const isSiblingOfDropTarget =
		String(dropTarget?.parentIssueAri) === String(draggedItem?.parentIssueAri);

	const droppingToSameParent =
		// Do not take into account when both drag item and drop target do not have parent, in which case
		// they have an equal parent value but is not reparenting to same parent
		draggedItem?.parentIssueAri !== '' &&
		draggedItem?.parentIssueAri != null &&
		(isSiblingOfDropTarget || dragItemParentIsDropTarget);

	if (droppingToSameParent) {
		return [MAKE_CHILD, REORDER_BELOW, REORDER_ABOVE];
	}

	// if the difference is not of one level up, we could be reparenting by dragging
	if (dropTargetLevel !== draggedItemLevel + 1) {
		// If drop target is of same level as drag item and drop target has parent,
		// do not block reorder as we are reordering to reparent
		if (dropTargetLevel === draggedItemLevel && dropTargetHasParent) {
			return [MAKE_CHILD];
		}

		// If we are dropping to a top level issue (unparenting), we should not block reordering
		if (
			dropTarget?.depth === 0 &&
			draggedItemLevel > SUBTASK_HIERARCHY_LEVEL &&
			dragItemHasParent
		) {
			return [MAKE_CHILD];
		}

		// Any other actions are invalid
		return [MAKE_CHILD, REORDER_BELOW, REORDER_ABOVE];
	}

	// we cannot unparent a subtask nor an item that already does not have a parent
	if (draggedItemLevel === SUBTASK_HIERARCHY_LEVEL || !dragItemHasParent) {
		return [REORDER_BELOW, REORDER_ABOVE];
	}

	// Unparenting any non-subtask issue type
	if (dropTarget?.depth === 0 && draggedItemLevel > SUBTASK_HIERARCHY_LEVEL && dragItemHasParent) {
		return [];
	}

	// we are dropping to a valid parent
	return [REORDER_BELOW, REORDER_ABOVE];
};
