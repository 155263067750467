import React, {
	useMemo,
	type PropsWithChildren,
	type ReactNode,
	createContext,
	useContext,
} from 'react';
import type { FormPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import type { Column, TableInjectedCellProps } from '../../../common/types.tsx';

export type RenderChildRows = (
	args: {
		parentItemId: string;
		itemsConnectionId: string;
		depth: number;
		parentIssueAri?: string;
		parentItemConnectionId?: string;
		parentIssueRelayConnectionId?: string;
		isFormVisible?: boolean;
		formProps?: FormPropsType;
		hasChildren?: boolean;
		groupId?: string;
	} & (
		| { type: 'PARENT_CHILDREN'; issueKey: string }
		| { type: 'GROUP_CHILDREN'; groupJql: string; groupFieldValue: string | null }
	),
) => ReactNode;

export type Props = {
	columns: Column[];
	tableInjectedProps: TableInjectedCellProps;
	renderChildRows?: RenderChildRows;
	groupId?: string;
};

const RowContext = createContext<Props | null>(null);

export const useRowContext = (): Props => {
	const context = useContext(RowContext);

	if (!context) {
		throw new Error('useRowContext must be used within RowContextProvider');
	}

	return context;
};

export const RowContextProvider = ({
	children,
	columns,
	tableInjectedProps,
	renderChildRows,
	groupId,
}: PropsWithChildren<Props>) => {
	const props = useMemo(
		() => ({
			columns,
			tableInjectedProps,
			renderChildRows,
			groupId,
		}),
		[columns, tableInjectedProps, renderChildRows, groupId],
	);

	return <RowContext.Provider value={props}>{children}</RowContext.Provider>;
};
