/**
 * @generated SignedSource<<d893e1926e658e75f27358525402e5cc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project$data = {
  readonly key: string;
  readonly projectId: string;
  readonly " $fragmentType": "realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project";
};
export type realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project$key = {
  readonly " $data"?: realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "6a41012bf4b24761aa42f292d786f39f";

export default node;
