// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStateHook } from '@atlassian/react-sweet-state';
import { DENSITY_COMPACT, DENSITY_FULL } from '../../common/constants.tsx';
import { FeaturesStore } from './store.tsx';

export const useIsDensityFull = createStateHook(FeaturesStore, {
	selector: (state) => state.density === DENSITY_FULL,
});

export const useIsVirtualizationEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.density === DENSITY_COMPACT && state.isInfiniteScrollEnabled,
});

export const useIsColumnResizingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isColumnResizingEnabled,
});

export const useIsFixedTableLayoutEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isFixedTableLayoutEnabled,
});

export const useIsInlineEditingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInlineEditingEnabled,
});

export const useIsInlineEditingExtendedFieldSupportEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInlineEditingExtendedFieldSupportEnabled,
});

export const useIsIssueCreateEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueCreateEnabled && state.isInfiniteScrollEnabled,
});

export const useIsInfiniteScrollEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInfiniteScrollEnabled,
});

export const useIsSiblingIssueCreateEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueCreateEnabled && state.isIssueRankingEnabled,
});

export const useIsChildIssueCreateEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueCreateEnabled && state.isIssueHierarchyEnabled,
});

export const useIsIssueRankDescending = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueRankDescending,
});

export const useIsIssueHierarchyEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueHierarchyEnabled && state.isInfiniteScrollEnabled,
});

export const useIsIssueOperationMeatballMenuEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueOperationMeatballMenuEnabled,
});

export const useIsRowReorderingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isRowReorderingEnabled,
});

export const useIsEntireRowDraggable = createStateHook(FeaturesStore, {
	selector: (state) => state.isRowReorderingEnabled && state.isEntireRowDraggable,
});

export const useIsReparentingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isReparentingEnabled,
});

export const useIsDragAndDropEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isRowReorderingEnabled || state.isReparentingEnabled,
});

export const useProjectContext = createStateHook(FeaturesStore, {
	selector: (state) => state.projectContext,
});

export const useSortField = createStateHook(FeaturesStore, {
	selector: (state) => state.sortField,
});

export const useSortDirection = createStateHook(FeaturesStore, {
	selector: (state) => state.sortDirection,
});

export const useJql = createStateHook(FeaturesStore, {
	selector: (state) => state.jql,
});

export const useIsColumnBordersEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isColumnBordersEnabled,
});

export const useIsInlineFieldConfigEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInlineFieldConfigEnabled,
});

export const useIsInBetweenColumnPickerEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInBetweenColumnPickerEnabled,
});

export const useIsSingleLineRowHeightEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isSingleLineRowHeightEnabled,
});

export const useIsHideDoneItemsEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isHideDoneItemsEnabled,
});
