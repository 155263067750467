import { useCallback } from 'react';
import { graphql, useMutation, useRelayEnvironment } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import type {
	IssueToCreate,
	MutationError,
	ContextualFields,
} from '@atlassian/jira-issue-table-inline-issue-create/src/common/types.tsx';
import { useIssueCreateOnError } from '@atlassian/jira-issue-table-inline-issue-create/src/controllers/use-issue-create-on-error/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	issueCreateMutation_useIssueCreateMutation,
	issueCreateMutation_useIssueCreateMutation$data as CreateIssueMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/issueCreateMutation_useIssueCreateMutation.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { ANALYTICS_SOURCE, INLINE_ISSUE_CREATE_EXPERIENCE } from '../../common/constants.tsx';
import {
	useIsDensityFull,
	useIsInlineEditingEnabled,
	useIsIssueHierarchyEnabled,
	useProjectContext,
} from '../../controllers/features/selectors.tsx';
import {
	issueConnectionUpdater,
	type IssueInsertionArgs,
	removeHighlightAfterDelay,
} from '../../controllers/issue-connection-updater/index.tsx';
import { useIssueFetchQueryVariables } from '../../controllers/issue-fetch-query-variables/index.tsx';
import { useIssueFetchAndAppend } from '../issue-fetch-and-append/index.tsx';
import { toIssueCreateInput } from './utils/to-issue-create-input.tsx';
import { toOptimisticResponse } from './utils/to-optimistic-response.tsx';
import { useOptimisticId } from './utils/use-optimistic-id.tsx';

const ACTION_SUBJECT_ID = 'issueTableIssueCreate';
const PACKAGE_LOCATION = 'jira-native-issue-table-inline-issue-create';

const issueConnectionMutationUpdater = (
	store: RecordSourceSelectorProxy<CreateIssueMutationResponse>,
	response: CreateIssueMutationResponse | null | undefined,
	issueConnectionId: string,
	insertionArgs?: IssueInsertionArgs,
	parentItemConnectionId?: string,
	projectKey?: string | null,
) => {
	const issue = response?.jira?.createIssue?.issue;
	if (!issue) {
		return;
	}

	issueConnectionUpdater(store, {
		...insertionArgs,
		issue,
		issueConnectionId,
		parentItemConnectionId,
		issueRecordId: issue.__id,
		fieldSetsConnectionId: issue.fieldSetsById?.__id ?? '',
		projectKey,
	});
};

/**
 * Function called during inline issue create to submit a newly created issue.
 *
 * @param issueToCreate Issue object provided during inline issue create.
 * @param additionalFields Additional field value data that can be provided for issue create.
 * @param insertionArgs Arguments to determine where the created issue should be inserted in the connection.
 */
type IssueCreateSubmitFunction = (
	issueToCreate: IssueToCreate,
	contextualFields: ContextualFields,
	insertionArgs?: IssueInsertionArgs,
) => void;

/**
 * Returns a function to create a new issue in the issue table with data provided during inline issue create.
 *
 * @param issueConnectionId ID of the Relay connection where the new issue should be appended.
 * @param parentItemConnectionId The ID of the parent issue in the Relay connection, used to update the parent issue in the Relay store after creating a child issue.
 */
export const useIssueCreateMutation = (
	issueConnectionId: string | null,
	parentItemConnectionId?: string,
): IssueCreateSubmitFunction => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const environment = useRelayEnvironment();
	const handleErrorIIC = useIssueCreateOnError();

	const { fieldSetIds } = useIssueFetchQueryVariables();
	const onIssueFetchAndAppend = useIssueFetchAndAppend(issueConnectionId);

	const [commit] = useMutation<issueCreateMutation_useIssueCreateMutation>(graphql`
		mutation issueCreateMutation_useIssueCreateMutation(
			$issueCreateInput: JiraIssueCreateInput!
			$fieldSetIds: [String!]!
			$isInlineEditingEnabled: Boolean!
			$isIssueHierarchyEnabled: Boolean!
			$isDensityFull: Boolean!
			$projectKey: String
		) @raw_response_type {
			jira {
				createIssue(input: $issueCreateInput) @optIn(to: "JiraIssueCreateMutation") {
					errors {
						extensions {
							statusCode
						}
					}
					issue {
						...highlightIssueRow_update_isHighlightedIssueRow
						__id
						id
						key
						issueId
						issueTypeField @include(if: $isIssueHierarchyEnabled) {
							id
							issueType {
								hierarchy {
									level
								}
							}
						}
						canHaveChildIssues(projectKey: $projectKey) @include(if: $isIssueHierarchyEnabled)
						fieldSetsById(fieldSetIds: $fieldSetIds, first: 500) {
							__id
							...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
								@arguments(
									isInlineEditingEnabled: $isInlineEditingEnabled
									isDensityFull: $isDensityFull
								)
						}
					}
				}
			}
		}
	`);

	let projectKey: string | undefined | null;

	const isDensityFull = useIsDensityFull();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	let isIssueHierarchyEnabled = false;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueHierarchyEnabled = useIsIssueHierarchyEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		projectKey = useProjectContext();
	}

	const { generateOptimisticId, getRealId } = useOptimisticId();

	const onExperienceStart = useExperienceStart({
		experience: INLINE_ISSUE_CREATE_EXPERIENCE,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceFail = useExperienceFail({
		experience: INLINE_ISSUE_CREATE_EXPERIENCE,
		attributes: {},
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience: INLINE_ISSUE_CREATE_EXPERIENCE,
	});

	return useCallback(
		(
			issueToCreate: IssueToCreate,
			contextualFields: ContextualFields,
			insertionArgs?: IssueInsertionArgs,
		) => {
			const issueCreateInput = toIssueCreateInput(issueToCreate, contextualFields);
			if (!issueCreateInput || issueConnectionId == null) {
				return;
			}
			const insertionArgsWithIdGetter =
				insertionArgs == null
					? insertionArgs
					: {
							...insertionArgs,
							getRealId,
						};

			const handleError = async (error: Error | ReadonlyArray<MutationError>) => {
				const response = await handleErrorIIC({
					issueToCreate,
					contextualFields,
					error,
					onFail: (e) => onExperienceFail(PACKAGE_LOCATION, e),
				});
				if (response) {
					onIssueFetchAndAppend(response, insertionArgsWithIdGetter);
					onExperienceSuccess();
				}
			};

			const { id: optimisticId, saveRealId } = generateOptimisticId();
			onExperienceStart();

			commit({
				variables: {
					issueCreateInput,
					fieldSetIds,
					isInlineEditingEnabled,
					isDensityFull,
					isIssueHierarchyEnabled,
					...(fg('jsc_m2_hierarchy_fe_changes') ? { projectKey } : {}),
				},
				optimisticResponse: toOptimisticResponse(
					issueToCreate,
					optimisticId,
					isIssueHierarchyEnabled,
				),
				optimisticUpdater: (store, response) =>
					issueConnectionMutationUpdater(
						store,
						response,
						issueConnectionId,
						insertionArgsWithIdGetter,
						parentItemConnectionId,
						projectKey,
					),
				updater: (store, response) => {
					if (response != null && response?.jira?.createIssue?.issue?.id != null) {
						saveRealId(response?.jira?.createIssue?.issue?.id);
					}
					return issueConnectionMutationUpdater(
						store,
						response,
						issueConnectionId,
						insertionArgsWithIdGetter,
						parentItemConnectionId,
						projectKey,
					);
				},
				onCompleted: (response) => {
					const { errors, issue } = response?.jira?.createIssue ?? {};
					if (!issue) {
						// Handle any GraphQL errors that occurred during issue create
						errors && handleError(errors);
						return;
					}

					// Event contributes towards Jira Monthly Core User, see https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition#%3Ajira%3A-Jira.
					fireTrackAnalytics(createAnalyticsEvent({}), 'issue created', ACTION_SUBJECT_ID);
					onExperienceSuccess();
					removeHighlightAfterDelay(environment, issue);
				},
				onError: (error) => {
					// Handle any network errors that occurred during issue create
					handleError(error);
				},
			});
		},
		[
			issueConnectionId,
			getRealId,
			generateOptimisticId,
			onExperienceStart,
			commit,
			fieldSetIds,
			isInlineEditingEnabled,
			isDensityFull,
			isIssueHierarchyEnabled,
			handleErrorIIC,
			onExperienceFail,
			onIssueFetchAndAppend,
			onExperienceSuccess,
			parentItemConnectionId,
			projectKey,
			createAnalyticsEvent,
			environment,
		],
	);
};

export const CreateIssueController = ({
	childConnectionId,
	parentItemConnectionId,
	setOnCreateIssue,
}: {
	childConnectionId: string;
	parentItemConnectionId?: string;
	setOnCreateIssue: (onCreateIssue: IssueCreateSubmitFunction) => void;
}) => {
	const onCreateIssue = useIssueCreateMutation(childConnectionId, parentItemConnectionId);
	setOnCreateIssue(onCreateIssue);

	return null;
};
