/**
 * @generated SignedSource<<038490e885f5ed59aebe309ed3d06370>>
 * @relayHash 88dc3fe18d2828d729fb2bf7929e7076
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 878b3b6282e98d324c50ccd49dbaf8a07c889063b5efed0881fe239f64e5160d

import type { ConcreteRequest, Query } from 'relay-runtime';
export type actions_LazyLoadMediaReadTokenQuery$variables = {
  cloudId: string;
  issueKeys: ReadonlyArray<string>;
  maxTokenLength: number;
};
export type actions_LazyLoadMediaReadTokenQuery$data = {
  readonly jira: {
    readonly issuesByKey: ReadonlyArray<{
      readonly key: string;
      readonly mediaReadToken: {
        readonly clientId: string | null | undefined;
        readonly endpointUrl: string | null | undefined;
        readonly tokenLifespanInSeconds: AGG$Long | null | undefined;
        readonly tokensWithFiles: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly token: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type actions_LazyLoadMediaReadTokenQuery = {
  response: actions_LazyLoadMediaReadTokenQuery$data;
  variables: actions_LazyLoadMediaReadTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKeys"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxTokenLength"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "keys",
    "variableName": "issueKeys"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "args": [
    {
      "kind": "Variable",
      "name": "maxTokenLength",
      "variableName": "maxTokenLength"
    }
  ],
  "concreteType": "JiraMediaReadToken",
  "kind": "LinkedField",
  "name": "mediaReadToken",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "clientId"
    },
    {
      "kind": "ScalarField",
      "name": "endpointUrl"
    },
    {
      "kind": "ScalarField",
      "name": "tokenLifespanInSeconds"
    },
    {
      "concreteType": "JiraMediaTokenWithFilesConnection",
      "kind": "LinkedField",
      "name": "tokensWithFiles",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraMediaTokenWithFilesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraMediaTokenWithFiles",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "token"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "actions_LazyLoadMediaReadTokenQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issuesByKey",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "actions_LazyLoadMediaReadTokenQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issuesByKey",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "878b3b6282e98d324c50ccd49dbaf8a07c889063b5efed0881fe239f64e5160d",
    "metadata": {},
    "name": "actions_LazyLoadMediaReadTokenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a1b3499bc5d237db13d819b5abf7ce41";

export default node;
