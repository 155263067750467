import React, { type ReactNode } from 'react';
import { ConnectionHandler, type RecordProxy, type RecordSourceSelectorProxy } from 'relay-runtime';
import { type useRelayEnvironment, commitLocalUpdate } from 'react-relay';
import { v4 as uuid } from 'uuid';
import { Text } from '@atlaskit/primitives';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	SelectOption,
	SelectValue,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import messages from './messages.tsx';

export const isOnlyUnselectableField = (fieldType: string, canEditColumnConfiguration: boolean) => {
	// Epic Link can only be unselected
	if (fieldType === 'com.pyxis.greenhopper.jira:gh-epic-link') {
		return canEditColumnConfiguration;
	}

	// Parent Link can only be unselected
	if (fieldType === 'com.atlassian.jpo:jpo-custom-field-parent') {
		return canEditColumnConfiguration;
	}

	return false;
};

export const getFieldDisabledMessage = (fieldType: string, formatMessage: FormatMessage) => {
	// Epic Link deprecation message
	if (fieldType === 'com.pyxis.greenhopper.jira:gh-epic-link') {
		return formatMessage(messages.deprecatedEpicMessage, {
			strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
		});
	}

	// Parent Link deprecation message
	if (fieldType === 'com.atlassian.jpo:jpo-custom-field-parent') {
		return formatMessage(messages.deprecatedParentLinkMessage, {
			strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
		});
	}

	return undefined;
};

export const findNewInsertFields = (options: SelectOption[], selectedColumns: SelectValue) => {
	return options.filter(
		(option) =>
			!selectedColumns.some((prevOption) => {
				if ('value' in prevOption && 'value' in option) {
					return prevOption.value === option.value;
				}
				return false;
			}),
	);
};

export const findRemovedFields = (options: SelectOption[], selectedColumns: SelectValue) => {
	const removedFields: Field[] = [];
	const existingValues = new Set(options.map((opt) => ('value' in opt ? opt.value : '')));

	selectedColumns.forEach((option) => {
		if ('value' in option && !existingValues.has(option.value)) {
			removedFields.push({
				name: option.label,
				fieldSetId: option.value,
			});
		}
	});
	return removedFields;
};

export const getUpdatedOptions = (
	options: SelectValue,
	insertFields: SelectOption[],
	insertIndex: number | undefined,
) => {
	if (insertIndex !== undefined) {
		const updatedOptions = [...options];
		updatedOptions.splice(insertIndex, 0, ...insertFields);
		return updatedOptions;
	}
	return [...options, ...insertFields];
};

export type Field = {
	name: string;
	type?: string;
	fieldSetId: string;
	jqlTerm?: string;
};

export type OptimisticFieldConfigArgs = {
	fieldConfigId: string | undefined;
	fields: Field[];
	environment: ReturnType<typeof useRelayEnvironment>;
	createIndex?: number;
};

export const createOptimisticFieldConfig = ({
	environment,
	fieldConfigId,
	fields: newFields,
	createIndex,
}: OptimisticFieldConfigArgs) => {
	if (fieldConfigId) {
		commitLocalUpdate(environment, (store: RecordSourceSelectorProxy) => {
			if (fieldConfigId) {
				const fields = store.get(fieldConfigId)?.getLinkedRecords('edges');
				const createdFields: RecordProxy<{}>[] = [];
				newFields.forEach((field: Field) => {
					const exists = fields?.find(
						(edge) => edge.getLinkedRecord('node')?.getValue('fieldSetId') === field.fieldSetId,
					);
					if (!exists) {
						createdFields.push(createFieldInStore(field, store));
					}
				});
				if (fields) {
					if (createIndex !== undefined && createIndex >= 0 && createIndex <= fields.length) {
						const updatedFields = [...fields];
						updatedFields.splice(createIndex, 0, ...createdFields);
						store.get(fieldConfigId)?.setLinkedRecords(updatedFields, 'edges');
					} else {
						store.get(fieldConfigId)?.setLinkedRecords([...fields, ...createdFields], 'edges');
					}
					store.get(fieldConfigId)?.setValue(fields.length + createdFields.length, 'totalCount');
				}
			}
		});
	}
};

export const deleteOptimisticFieldConfig = ({
	environment,
	fieldConfigId,
	fields: deletedFields,
}: OptimisticFieldConfigArgs) => {
	if (fieldConfigId) {
		commitLocalUpdate(environment, (store: RecordSourceSelectorProxy) => {
			const fieldSetsConnection = store.get(fieldConfigId);
			const deletedFieldsSetIds: Set<string> = new Set(
				deletedFields.map((field) => field.fieldSetId),
			);

			fieldSetsConnection?.getLinkedRecords('edges')?.forEach((edge) => {
				const node = edge.getLinkedRecord('node');
				const fieldSetId = node?.getValue('fieldSetId')?.toString();
				if (fieldSetId && deletedFieldsSetIds.has(fieldSetId)) {
					const nodeId = node?.getDataID();
					nodeId && ConnectionHandler.deleteNode(fieldSetsConnection, nodeId);
				}
			});

			const newTotalCount = fieldSetsConnection?.getLinkedRecords('edges')?.length;
			store.get(fieldConfigId)?.setValue(newTotalCount, 'totalCount');
		});
	}
};

const createFieldInStore = (newField: Field, store: RecordSourceSelectorProxy) => {
	const fieldJqlTerm = 'jql-placeholder-only';

	const edge = store.create(uuid(), 'JiraIssueSearchFieldSetEdge');
	const aField = store.create(uuid(), 'JiraIssueSearchFieldSet');

	aField.setValue(newField.fieldSetId, 'fieldSetId');
	aField.setValue(newField.jqlTerm ?? fieldJqlTerm, 'jqlTerm');
	aField.setValue(newField.type, 'type');
	aField.setValue(newField.name, 'displayName');
	if (!newField.jqlTerm) {
		aField.setValue(true, 'isPlaceHolderField');
	}

	edge.setLinkedRecord(aField, 'node');

	return edge;
};
