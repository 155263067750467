/**
 * @generated SignedSource<<b7cb36164b5bb4bdee088db8cbf6ff35>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type realtimeUpdater_nativeIssueTable_project$data = {
  readonly key: string;
  readonly projectId: string;
  readonly " $fragmentType": "realtimeUpdater_nativeIssueTable_project";
};
export type realtimeUpdater_nativeIssueTable_project$key = {
  readonly " $data"?: realtimeUpdater_nativeIssueTable_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"realtimeUpdater_nativeIssueTable_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "realtimeUpdater_nativeIssueTable_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "54f3e67da3b1a5a78dd3e50777d39b59";

export default node;
