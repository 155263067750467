/**
 * @generated SignedSource<<3a4730d872936c47da86755d7a3a7c4e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type footerRenderer_nativeIssueTable$data = {
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueCreateFooter_nativeIssueTable" | "main_nativeIssueTable_IssueCountRenderer" | "paginationControls_nativeIssueTable">;
  readonly " $fragmentType": "footerRenderer_nativeIssueTable";
};
export type footerRenderer_nativeIssueTable$key = {
  readonly " $data"?: footerRenderer_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"footerRenderer_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "name": "footerRenderer_nativeIssueTable",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_nativeIssueTable_IssueCountRenderer"
    },
    {
      "kind": "FragmentSpread",
      "name": "issueCreateFooter_nativeIssueTable"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        }
      ],
      "kind": "FragmentSpread",
      "name": "paginationControls_nativeIssueTable"
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "9d716ccc56cda13ca3b1ffc97af563b2";

export default node;
