import type { IssueTypeId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, type Action, createHook } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

const initialState: State = {
	projectConfigs: {},
};

const actions = {
	setDefaultIssueType:
		(projectKey: ProjectKey | null | undefined, issueType: IssueTypeId): Action<State> =>
		({ setState, getState }) => {
			if (projectKey == null) {
				return;
			}
			const currentState = getState();

			let projectConfig = currentState.projectConfigs[projectKey];
			if (projectConfig == null) {
				projectConfig = {
					defaultIssueType: null,
				};
			}

			projectConfig.defaultIssueType = issueType;
			setState({
				...currentState,
				projectConfigs: {
					...currentState.projectConfigs,
					[projectKey]: projectConfig,
				},
			});
		},
};

type Actions = typeof actions;

export const IICConfigStore = createStore<State, Actions>({
	initialState,
	actions,
});

export const useIICConfigStore = createHook(IICConfigStore);
