/**
 * @generated SignedSource<<8ea01358b1a136fbd5aaaddd170b218a>>
 * @relayHash 8d969d5c4138633fa2c8d684c8d49929
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a9727b3dde65ac24d78ecc1c5fbefd04f2f7ea21234ed28165e96f0478c25976

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateRadioSelectFieldInput = {
  id: string;
  operation: JiraRadioSelectFieldOperationInput;
};
export type JiraRadioSelectFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation$variables = {
  input: JiraUpdateRadioSelectFieldInput;
};
export type radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation$data = {
  readonly jira: {
    readonly updateRadioSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateRadioSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly selectedOption: {
          readonly id: string;
          readonly label: string | null | undefined;
          readonly value: string;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation = {
  rawResponse: radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation$rawResponse;
  response: radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation$data;
  variables: radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraRadioSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateRadioSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraRadioSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraRadioSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateRadioSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraRadioSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraOption",
                    "kind": "LinkedField",
                    "name": "selectedOption",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "label",
                        "kind": "ScalarField",
                        "name": "value"
                      },
                      (v4/*: any*/),
                      {
                        "alias": "value",
                        "kind": "ScalarField",
                        "name": "id"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a9727b3dde65ac24d78ecc1c5fbefd04f2f7ea21234ed28165e96f0478c25976",
    "metadata": {},
    "name": "radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c21145b09e3422aaf4c5cef7684d37b2";

export default node;
