/**
 * @generated SignedSource<<20ba60668b078076c74952de9fab8952>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueFetchQueryVariables_nativeIssueTable$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly fieldSetId: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "issueFetchQueryVariables_nativeIssueTable";
};
export type issueFetchQueryVariables_nativeIssueTable$key = {
  readonly " $data"?: issueFetchQueryVariables_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueFetchQueryVariables_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueFetchQueryVariables_nativeIssueTable",
  "selections": [
    {
      "concreteType": "JiraIssueSearchFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueSearchFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "fieldSetId"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueSearchFieldSetConnection"
};

(node as any).hash = "03290a1a84f19d99043c011485a36598";

export default node;
