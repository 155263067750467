/**
 * @generated SignedSource<<9c0f79ce5044190fc6da94cb6bd50afa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueStatus_nativeIssueTable_IssueStatusCell$data = {
  readonly id?: string;
  readonly status: {
    readonly name: string | null | undefined;
    readonly statusCategory: {
      readonly statusCategoryId: string;
    } | null | undefined;
    readonly statusId: string;
  };
  readonly " $fragmentType": "issueStatus_nativeIssueTable_IssueStatusCell";
};
export type issueStatus_nativeIssueTable_IssueStatusCell$key = {
  readonly " $data"?: issueStatus_nativeIssueTable_IssueStatusCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueStatus_nativeIssueTable_IssueStatusCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "issueStatus_nativeIssueTable_IssueStatusCell",
  "selections": [
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        }
      ]
    },
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        {
          "concreteType": "JiraStatusCategory",
          "kind": "LinkedField",
          "name": "statusCategory",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "statusCategoryId"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraStatusField"
};

(node as any).hash = "90e3d56baf9c61aa74ebf80af89d841c";

export default node;
