import React from 'react';
import Checkbox from '@atlaskit/checkbox';
import { Inline } from '@atlaskit/primitives';
import { abortAll } from '@atlaskit/react-ufo/interaction-metrics';
import { useIntl } from '@atlassian/jira-intl';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	CheckboxCell as CheckboxCellComponent,
	HeaderCheckboxCell as HeaderCheckboxCellComponent,
} from './main.tsx';
import { messages } from './messages.tsx';
import type { CheckboxCellProps, HeaderCheckboxCellProps } from './types.tsx';

export const CheckboxCell = ({ issueId, ...checkboxProps }: CheckboxCellProps) => {
	const { formatMessage } = useIntl();
	return __SERVER__ ? (
		<Inline alignInline="center">
			<Checkbox
				aria-label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.checkboxAriaLabelIssueTermRefresh
						: messages.checkboxAriaLabel,
				)}
				onClick={() => abortAll('new_interaction')}
				{...checkboxProps}
			/>
		</Inline>
	) : (
		<CheckboxCellComponent issueId={issueId} {...checkboxProps} />
	);
};

export const HeaderCheckboxCell = ({
	selectableIssueIds,
	...checkboxProps
}: HeaderCheckboxCellProps) => {
	const { formatMessage } = useIntl();
	return __SERVER__ ? (
		<Inline alignInline="center">
			<Checkbox
				aria-label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.checkboxAriaLabelIssueTermRefresh
						: messages.checkboxAriaLabel,
				)}
				onClick={() => abortAll('new_interaction')}
				{...checkboxProps}
			/>
		</Inline>
	) : (
		<HeaderCheckboxCellComponent selectableIssueIds={selectableIssueIds} {...checkboxProps} />
	);
};
