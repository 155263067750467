import React, {
	type ReactNode,
	createContext,
	useContext,
	useState,
	useCallback,
	useRef,
	useMemo,
	useEffect,
} from 'react';

export const CELL_HOVER_DELAY = 200;

type IsCellHovered = boolean | null;
type CellHoverProps = {
	children: ReactNode;
};
type CellHoverHandlers = {
	handleMouseEnter: () => void;
	handleMouseLeave: () => void;
};

const CellHoverHandlerContext = createContext<CellHoverHandlers | null>(null);

const CellHoverContext = createContext<IsCellHovered>(null);

export const CellHoverProvider = ({ children }: CellHoverProps) => {
	const [isHovered, setIsHovered] = useState(false);

	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const cleanUpTimeout = useCallback(() => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
	}, []);

	const handleMouseEnter = useCallback(() => {
		hoverTimeoutRef.current = setTimeout(() => {
			setIsHovered(true);
		}, CELL_HOVER_DELAY);
	}, []);

	const handleMouseLeave = useCallback(() => {
		cleanUpTimeout();
		setIsHovered(false);
	}, [cleanUpTimeout]);

	const handers = useMemo(
		() => ({
			handleMouseEnter,
			handleMouseLeave,
		}),
		[handleMouseEnter, handleMouseLeave],
	);

	useEffect(() => {
		return cleanUpTimeout;
	}, [cleanUpTimeout]);

	return (
		<CellHoverHandlerContext.Provider value={handers}>
			<CellHoverContext.Provider value={isHovered}>{children}</CellHoverContext.Provider>
		</CellHoverHandlerContext.Provider>
	);
};

export const useCellHoverHandlers = () => {
	const handlers = useContext(CellHoverHandlerContext);

	if (handlers === null) {
		throw new Error('useCellHoverHandlers must be used within a CellHoverProvider');
	}

	return handlers;
};

export const useCellHoverContext = () => {
	const isHovered = useContext(CellHoverContext);

	if (isHovered === null) {
		throw new Error(
			'useCellHoverContext must be used within a CellHoverProvider, isCellHovered cannot be null',
		);
	}

	return isHovered;
};
