import React, { useState, useCallback, useRef } from 'react';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import type { IssueToCreate } from '../common/types.tsx';

import InlineCreateForm, {
	type Props as InlineCreateFormProps,
} from './inline-create-form/index.tsx';
import { useInlineIssueCreateEntryPoint } from './inline-create-form/utils.tsx';
import InlineCreateTrigger from './inline-create-trigger/index.tsx';

type HandlerArgs = {
	projectKey: string | null;
	minHierarchyLevel?: number;
	maxHierarchyLevel?: number;
};

export type FormPropsType = ReturnType<typeof useInlineCreateHandlers>['formProps'];

export type TriggerPropsType = ReturnType<typeof useInlineCreateHandlers>['triggerProps'];

export type Props = Omit<InlineCreateFormProps, 'entryPointSubject'> & HandlerArgs;

export const useInlineCreateHandlers = ({
	projectKey,
	minHierarchyLevel,
	maxHierarchyLevel,
}: HandlerArgs) => {
	const [isFormVisible, setIsFormVisible] = useState(false);
	const formIssueDraftRef = useRef<Partial<IssueToCreate>>();

	const { entryPointActions, entryPointReferenceSubject } = useInlineIssueCreateEntryPoint(
		projectKey,
		minHierarchyLevel,
		maxHierarchyLevel,
	);
	const buttonRef = useEntryPointButtonTrigger(entryPointActions);

	const ref = useRef<HTMLElement>(null);

	const merged = useMergeRefs(ref, buttonRef);

	const handleTriggerClick = useCallback(() => {
		setIsFormVisible(true);
	}, []);

	const handleFormCancel = useCallback(() => {
		setIsFormVisible(false);
		ref.current?.focus();
	}, []);

	const onDraftUpdate = useCallback((issueDraft: Partial<IssueToCreate>) => {
		formIssueDraftRef.current = issueDraft;
	}, []);

	return {
		isFormVisible,
		entryPointActions,
		formProps: {
			initialIssueDraft: formIssueDraftRef.current,
			onDraftUpdate,
			onCancel: handleFormCancel,
			entryPointSubject: entryPointReferenceSubject,
		},
		triggerProps: {
			onClick: handleTriggerClick,
			ref: merged,
		},
	};
};

const InlineCreate = ({ projectKey, minHierarchyLevel, ...formProps }: Props) => {
	const {
		isFormVisible,
		formProps: formPropsAdditional,
		triggerProps,
	} = useInlineCreateHandlers({ projectKey, minHierarchyLevel });

	return isFormVisible ? (
		<InlineCreateForm {...formProps} {...formPropsAdditional} />
	) : (
		<InlineCreateTrigger {...triggerProps} />
	);
};

export default InlineCreate;
