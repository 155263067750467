import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	storyPoints: {
		id: 'native-issue-table.row.group-row.group-header.story-points-group-header.story-points',
		defaultMessage: '{points, plural, one {# point} other {# points}}',
		description: 'Story points count to show when grouping items by Story points',
	},
	noStoryPoints: {
		id: 'native-issue-table.row.group-row.group-header.story-points-group-header.no-story-points',
		defaultMessage: 'No points',
		description: 'Label for grouped items without Story points',
	},
});
