import React, {
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	type PropsWithChildren,
} from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment, type EntryPointProps } from 'react-relay';
import { SpotlightTarget, SpotlightManager } from '@atlaskit/onboarding';
import { Inline, Text } from '@atlaskit/primitives';
import { token, type CSSToken } from '@atlaskit/tokens';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ColumnPickerContainer } from '@atlassian/jira-issue-table-column-picker/src/controllers/column-picker-open-state/index.tsx';
import AsyncColumnPicker from '@atlassian/jira-issue-table-column-picker/src/ui/async.tsx';
import {
	ContextualAnalyticsData,
	FireUiAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { ui_nativeIssueTable_NativeIssueTable_columns$key as ColumnsFragment } from '@atlassian/jira-relay/src/__generated__/ui_nativeIssueTable_NativeIssueTable_columns.graphql';
import type { ui_nativeIssueTable_NativeIssueTable_groups$key as GroupsFragment } from '@atlassian/jira-relay/src/__generated__/ui_nativeIssueTable_NativeIssueTable_groups.graphql';
import type { ui_nativeIssueTable_NativeIssueTable_issues$key as IssuesFragment } from '@atlassian/jira-relay/src/__generated__/ui_nativeIssueTable_NativeIssueTable_issues.graphql';
import type { ui_nativeIssueTable_NativeIssueTable_project$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/ui_nativeIssueTable_NativeIssueTable_project.graphql';
import { HierarchyContainer } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { HierarchyOnboarding as HierarchyOnboardingNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx';
import { ConnectionsListContainer } from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	BORDER_WIDTH,
	COLUMN_ID_MEATBALL_MENU,
	COLUMN_ID_EXPAND_BUTTON,
	ACTION_COLUMNS,
	COLUMN_TYPE_PARENT_LINK,
	DEFAULT_COLUMN_WIDTH,
	DENSITY_COMPACT,
	KNOWN_COLUMN_TYPES,
	LAZY_EDITABILITY_LOADER_CONTAINER_SCOPE,
	LAZY_MEDIA_VIEW_CONTEXT_LOADER_CONTAINER_SCOPE,
	MODIFY_COLUMNS,
	REORDER_COLUMNS,
	SORT_ASC,
	SORT_NONE,
	DENSITY_FULL,
	COLUMN_ID_CHECKBOX,
	COLUMN_PICKER_CONFIGURATION_CONTAINER,
} from '../common/constants.tsx';
import type { Column, ReorderRow } from '../common/types.tsx';
import ExpandHeaderCell from '../common/ui/header-cell/expand/index.tsx';
import { HeaderCheckboxCell } from '../common/ui/issue-cells/checkbox/index.tsx';
import { IssueTableSkeleton } from '../common/ui/skeleton/index.tsx';
import { getColumnConfig } from '../common/utils/get-column-config/index.tsx';
import { isKnownColumnType, isNonNullish } from '../common/utils/index.tsx';
import { ElementSizeProvider } from '../controllers/element-size/index.tsx';
import { FeaturesContainer } from '../controllers/features/container.tsx';
import { SelectedRowContainer } from '../controllers/selected-row-state/index.tsx';
import { SiblingCreateActiveIndexContainer } from '../controllers/sibling-create-active-index/index.tsx';
import { InlineCreateActiveIndexContainer } from '../controllers/inline-create-active-index/index.tsx';
import { TableIntersectionObserverContainer } from '../controllers/table-items-intersection-observer/index.tsx';
import { LazyEditabilityLoaderContainer } from '../services/lazy-editability-loader/index.tsx';
import { LazyMediaViewContextLoaderContainer } from '../services/lazy-media-view-context-loader/index.tsx';
import { DraggableRowsContainer } from '../controllers/draggable-rows/context.tsx';
import { IFCFooter } from '../common/ui/inline-field-config/column-picker-footer/index.tsx';
import FooterRenderer from './footer-renderer/index.tsx';
import { HierarchyOnboarding as HierarchyOnboardingOld } from './hierarchy-onboarding/index.tsx';
import { IssueFetchQueryVariables } from './issue-fetch-query-variables/index.tsx';
import IssueTable from './issue-table/index.tsx';
import KeyboardShortcuts from './keyboard-shortcuts/index.tsx';
import messages from './messages.tsx';
import { RealtimeUpdater } from './realtime-updater/index.tsx';
import { RealtimeUpdater as RealtimeUpdaterOld } from './realtime-updater-old/index.tsx';
import { ScrollContainer } from './scroll-container/index.tsx';
import { IssueTableSelectedIssuesProvider } from './selected-issues-provider/index.tsx';
import type { Props } from './types.tsx';
import { CopyPasteContainer } from './copy-paste-container/index.tsx';
import { ConnectionsSubscriberContainer } from './connections-subscriber-container/index.tsx';
import { RootConnectionRegister } from './root-connection-register/index.tsx';

const MaybeHierarchyContainer = componentWithCondition(
	() => fg('jsc_m2_hierarchy_fe_changes'),
	HierarchyContainer,
	({
		children,
	}: PropsWithChildren<{
		isIssueHierarchyEnabled?: boolean;
		groupByFieldId?: string | null;
		firstGroupId?: string | null;
		itemsConnectionId?: string;
	}>) => children,
);
export const MaybeInlineCreateActiveIndexContainer = componentWithCondition(
	() => fg('jira_inline_field_config_gate'),
	InlineCreateActiveIndexContainer,
	({ children }: PropsWithChildren<{}>) => children,
);

const MaybeConnectionsListContainer = componentWithCondition(
	() => fg('decouple_connection_register_hooks_from_realtime'),
	ConnectionsListContainer,
	({
		children,
	}: PropsWithChildren<{
		isIssueHierarchyEnabled?: boolean;
		groupByFieldId?: string | null;
		firstGroupId?: string | null;
	}>) => children,
);

const MaybeDraggableRowsContainer = componentWithCondition(
	() => fg('jira_native_issue_table_row_drag_and_drop') || fg('jsc_list_reparenting'),
	DraggableRowsContainer,
	({
		children,
	}: PropsWithChildren<{
		onRowReorder?: ReorderRow;
		instanceId?: string;
	}>) => children,
);

const NativeIssueTable = ({
	issues,
	groups,
	columns,
	project,
	columnWidthOverrides,
	columnConfigOverrides,
	components,
	loading = false,
	selectedRow = null,
	eventHandlers,
	isInlineEditingEnabled = true,
	isInlineEditingExtendedFieldSupportEnabled = false,
	onSelectedRowChange,
	sortField,
	sortDirection = SORT_ASC,
	onReady,
	onSortChange,
	onColumnsChange,
	onColumnResize,
	onRestoreDefaultColumns,
	issueSearchBaseUrl = '/issues',
	useColumnLoader,
	isColumnPickerLoading,
	projectContext,
	density = DENSITY_COMPACT,
	isIssueHierarchyEnabled = false,
	isHideDoneItemsEnabled = false,
	areShortcutsEnabled = true,
	isInlineFieldConfigEnabled = false,
	isInBetweenColumnPickerEnabled = false,
	isIssueCreateEnabled = false,
	enableMultiSelect = false,
	tableBorderRadius,
	onIssueFlatListUpdatedOld,
	onIssueFlatListUpdated,
	jql,

	// Discrete pagination props
	onPageChange,

	// Infinite scroll pagination props
	onLoadNext,
	onLoadPrevious,
	isLoadingNext,
	isLoadingPrevious,
	hasNext,
	hasPrevious,
	activationThreshold,

	// Experiment value props
	isGroupingSupported = false,
	isIssueOperationMeatballMenuEnabled = true,
	isCompactColumnEnabled = false,
	isReparentingEnabled = false,
	isColumnBordersEnabled = true,
	isSingleLineRowHeightEnabled = false,

	// Row reordering props
	onRowReorder,
	isEntireRowDraggable,
	ifcConfig,
}: Props) => {
	const tableRef = useRef<HTMLTableElement>(null);
	const { formatMessage } = useIntl();

	const issuesData = useFragment<IssuesFragment>(
		graphql`
			fragment ui_nativeIssueTable_NativeIssueTable_issues on JiraIssueConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean", defaultValue: true }
				isReparentingEnabled: { type: "Boolean", defaultValue: false }
				pageSize: { type: "Int" }
				isDensityFull: { type: "Boolean", defaultValue: false }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean", defaultValue: false }
			) {
				__id
				edges {
					node {
						key
						issueId
					}
				}
				jql
				...issueTable_nativeIssueTable_issueResults
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				...footerRenderer_nativeIssueTable @arguments(pageSize: $pageSize)
				...selectedRowState_nativeIssueTable_SelectedRowContainer @arguments(pageSize: $pageSize)
				...realtimeUpdater_nativeIssueTable_issues
				...realtimeUpdaterOld_nativeIssueTable_issues
				...rootConnectionRegister_nativeIssueTable_issues
			}
		`,
		issues ?? null,
	);

	const groupData = useFragment<GroupsFragment>(
		graphql`
			fragment ui_nativeIssueTable_NativeIssueTable_groups on JiraSpreadsheetGroupConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean", defaultValue: true }
				isDensityFull: { type: "Boolean", defaultValue: false }
				isPaginating: { type: "Boolean", defaultValue: false }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
			) {
				__id
				groupByField
				jql
				firstGroup @skip(if: $isPaginating) {
					id
				}
				...issueTable_nativeIssueTable_groupResults
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isDensityFull: $isDensityFull
						isPaginating: $isPaginating
						projectKeys: $projectKeys
						projectKey: $projectKey
					)
				...realtimeUpdater_nativeIssueTable_groups
				...realtimeUpdaterOld_nativeIssueTable_groups
			}
		`,
		groups ?? null,
	);

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const columnsData = useFragment<ColumnsFragment>(
		graphql`
			fragment ui_nativeIssueTable_NativeIssueTable_columns on JiraIssueSearchFieldSetConnection {
				...issueFetchQueryVariables_nativeIssueTable
				totalCount @required(action: THROW)
				edges @required(action: THROW) {
					node @required(action: THROW) {
						fieldSetId @required(action: THROW)
						type @required(action: THROW)
						displayName @required(action: THROW)
						jqlTerm @required(action: THROW)
						isSortable
						fieldSetPreferences {
							width
						}
						isPlaceHolderField
					}
				}
				...main_issueTableColumnPicker_ColumnConfig
			}
		`,
		columns,
	);
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */

	const projectData = useFragment<ProjectFragment>(
		graphql`
			fragment ui_nativeIssueTable_NativeIssueTable_project on JiraProject
			@argumentDefinitions(
				isReparentingEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider"
				}
			) {
				editIssues: action(type: EDIT_ISSUES) @include(if: $isReparentingEnabled) {
					canPerform
				}
				...realtimeUpdater_nativeIssueTable_project
				...realtimeUpdaterOld_nativeIssueTable_RealtimeUpdater_project
				...issueTable_nativeIssueTable_project
			}
		`,
		project ?? null,
	);

	const isInitialDataLoaded = useRef(false);

	useEffect(() => {
		if (!loading && !isInitialDataLoaded.current) {
			isInitialDataLoaded.current = true;
		}
	}, [loading]);

	const analyticsAttributes = useMemo(() => {
		const issueTableNumberOfColumns = columnsData.totalCount;
		const issueTableFieldTypesSet = new Set<string>();
		columnsData.edges
			.map((edge) => edge?.node.type)
			.filter(isNonNullish)
			.forEach(issueTableFieldTypesSet.add, issueTableFieldTypesSet);
		const issueTableFieldTypes = Array.from(issueTableFieldTypesSet).sort();

		return { issueTableNumberOfColumns, issueTableFieldTypes };
	}, [columnsData.edges, columnsData.totalCount]);

	const canViewColumnConfiguration = useColumnLoader !== undefined;
	const canEditColumnConfiguration = onColumnsChange !== undefined;
	const isColumnResizingEnabled = onColumnResize != null;
	const isRowReorderingEnabled =
		onRowReorder != null && fg('jira_native_issue_table_row_drag_and_drop');
	const isInfiniteScrollEnabled = onLoadNext != null && onLoadPrevious != null;
	const userHasEditIssuePermission = projectData?.editIssues?.canPerform === true;

	if (isInfiniteScrollEnabled && density === DENSITY_FULL) {
		throw new Error(`Infinite scroll is not allowed when density=${density}`);
	}

	const onColumnPickerChange = useCallback(
		(columnIds: string[] | undefined, onSuccessCallback?: () => void) => {
			if (onColumnsChange && columnIds) {
				onColumnsChange(columnIds, MODIFY_COLUMNS, undefined, onSuccessCallback);
			}
		},
		[onColumnsChange],
	);

	const mappedColumnConfig = useMemo(() => {
		const columnConfig = getColumnConfig(formatMessage);

		const columnsWithConfig: Array<Column> = [];

		const types = fg('issue-jiv-20263-dynamic-column-width-on-landing')
			? columnsData.edges.filter(isNonNullish).map((edge) => edge.node.type)
			: undefined;

		if (isIssueHierarchyEnabled && fg('jsc_m2_hierarchy_fe_changes')) {
			columnsWithConfig.push({
				id: COLUMN_ID_EXPAND_BUTTON,
				type: KNOWN_COLUMN_TYPES.EXPAND_BUTTON,
				title: '',
				fieldTypeDisplayName: '',
				jqlTerm: '',
				customHeader: (
					<SpotlightManager>
						<SpotlightTarget name="expand-column-header">
							<ExpandHeaderCell />
						</SpotlightTarget>
						{fg('jira_list_hierarchy_extraction') ? (
							<HierarchyOnboardingNew />
						) : (
							<HierarchyOnboardingOld />
						)}
					</SpotlightManager>
				),
				...columnConfig[KNOWN_COLUMN_TYPES.EXPAND_BUTTON](),
				...columnConfigOverrides?.[KNOWN_COLUMN_TYPES.EXPAND_BUTTON](types),
			});
		}

		columnsData.edges
			.map((edge) => edge?.node)
			.filter(isNonNullish)
			.forEach((node) => {
				const {
					fieldSetId,
					type,
					displayName,
					jqlTerm,
					isSortable,
					fieldSetPreferences,
					isPlaceHolderField,
				} = node;
				const baseConfig = {
					id: fieldSetId,
					type,
					title: displayName ?? '',
					jqlTerm,
					fieldTypeDisplayName: '',
					isSortable: isSortable ?? undefined,
					currentSorting: jqlTerm === sortField ? sortDirection : SORT_NONE,
					onSortOrderChanged: onSortChange,
					customWidth:
						columnWidthOverrides?.[fieldSetId] ?? fieldSetPreferences?.width ?? undefined,
					...(type === COLUMN_TYPE_PARENT_LINK && {
						warningText: formatMessage(messages.deprecatedParentLinkMessage, {
							strong: (chunks: ReactNode) => (
								<Text color="color.text.inverse" as="strong">
									{chunks}
								</Text>
							),
						}),
					}),
				};

				if (isKnownColumnType(type)) {
					const getColumnTypeConfig = columnConfig[type];
					const knownColumnTypeConfig = getColumnTypeConfig();
					const getColumnConfigOverride = columnConfigOverrides?.[type];
					const columnConfigOverride = getColumnConfigOverride?.(types);
					columnsWithConfig.push({
						...baseConfig,
						...knownColumnTypeConfig,
						...columnConfigOverride,
						isSortable: knownColumnTypeConfig.isSortable ?? true,
						isPlaceHolderField: !!isPlaceHolderField,
					});
				} else {
					columnsWithConfig.push({
						...baseConfig,
						defaultWidth: DEFAULT_COLUMN_WIDTH,
						isPlaceHolderField: !!isPlaceHolderField,
					});
				}
			});

		if (canViewColumnConfiguration || isIssueOperationMeatballMenuEnabled) {
			columnsWithConfig.push({
				id: COLUMN_ID_MEATBALL_MENU,
				type: KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS,
				title: '',
				fieldTypeDisplayName: '',
				jqlTerm: '',
				customHeader: canViewColumnConfiguration ? (
					<Inline alignInline="end">
						<AsyncColumnPicker
							columns={columnsData}
							components={
								isInlineFieldConfigEnabled && fg('jira_inline_field_config_gate')
									? { ...components?.columnPicker, Footer: IFCFooter }
									: components?.columnPicker
							}
							onChange={canEditColumnConfiguration ? onColumnPickerChange : undefined}
							onRestoreDefaultColumns={onRestoreDefaultColumns}
							useColumnLoader={useColumnLoader}
							isInfiniteScrollEnabled={isInlineFieldConfigEnabled}
							isLoading={
								fg('issue-jiv-19990-disable-selection-during-loading')
									? isColumnPickerLoading || loading
									: isColumnPickerLoading
							}
						/>
					</Inline>
				) : (
					<ScreenReaderText>{formatMessage(messages.columnHeaderIssueActions)}</ScreenReaderText>
				),
				...columnConfig[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS](),
				...columnConfigOverrides?.[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS](types),
			});
		}

		return columnsWithConfig;
	}, [
		formatMessage,
		columnsData,
		isIssueHierarchyEnabled,
		canViewColumnConfiguration,
		isIssueOperationMeatballMenuEnabled,
		columnConfigOverrides,
		sortField,
		sortDirection,
		onSortChange,
		columnWidthOverrides,
		isInlineFieldConfigEnabled,
		components?.columnPicker,
		canEditColumnConfiguration,
		onColumnPickerChange,
		onRestoreDefaultColumns,
		useColumnLoader,
		isColumnPickerLoading,
		loading,
	]);

	const checkboxColumnConfig = expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => {
				const checkboxConfig = getColumnConfig(formatMessage)[KNOWN_COLUMN_TYPES.CHECKBOX]();

				return {
					id: COLUMN_ID_CHECKBOX,
					type: KNOWN_COLUMN_TYPES.CHECKBOX,
					title: '',
					fieldTypeDisplayName: '',
					jqlTerm: '',
					customHeader: (
						<HeaderCheckboxCell
							groupByFieldId={groupData?.groupByField ?? null}
							selectableIssueIds={issuesData?.edges?.map((edge) => edge?.node?.issueId ?? '') ?? []}
						/>
					),
					...checkboxConfig,
				};
			}, [formatMessage, issuesData?.edges, groupData?.groupByField])
		: undefined;

	const mappedColumns = expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => {
				if (!enableMultiSelect) {
					return mappedColumnConfig;
				}
				// Remove type assertion when cleaning up `bulk_operations_in_nin`
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return [checkboxColumnConfig, ...mappedColumnConfig] as Column[];
			}, [checkboxColumnConfig, enableMultiSelect, mappedColumnConfig])
		: mappedColumnConfig;

	const handleOnColumnsChange = useCallback(
		(newColumns: Column[]) => {
			if (onColumnsChange) {
				const columnsWithIssueData = newColumns.filter(
					({ id }) =>
						Boolean(id) &&
						(fg('jsc_m2_hierarchy_fe_changes') ||
						expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
							? !ACTION_COLUMNS.includes(id)
							: id !== COLUMN_ID_MEATBALL_MENU),
				);

				const columnIds = columnsWithIssueData.map(({ id }) => id);

				const edges = columnsWithIssueData.map(
					({ id, type, title, jqlTerm, isSortable, fieldTypeDisplayName, customWidth }) => ({
						node: {
							fieldSetId: id,
							type: type ?? null,
							displayName: title,
							jqlTerm: jqlTerm ?? null,
							isSortable: isSortable ?? null,
							fieldType: fieldTypeDisplayName
								? {
										displayName: fieldTypeDisplayName,
									}
								: null,
							fieldSetPreferences: customWidth
								? {
										width: customWidth,
									}
								: null,
						},
					}),
				);

				const optimisticFieldSets = {
					totalCount: columnsData.totalCount,
					edges,
				};

				onColumnsChange(columnIds, REORDER_COLUMNS, optimisticFieldSets);
			}
		},
		[columnsData.totalCount, onColumnsChange],
	);

	// TODO: HRZ-1643 Handle shortcuts with grouping
	const issueKeys = useMemo(
		() =>
			fg('jsc_m2_hierarchy_fe_changes')
				? undefined
				: issuesData?.edges?.map((edge) => edge?.node?.key ?? ''),
		[issuesData?.edges],
	);

	const tableInjectedProps = useMemo(
		() => ({
			...eventHandlers,
			issueSearchBaseUrl,
		}),
		[eventHandlers, issueSearchBaseUrl],
	);

	const columnPickerProps = useMemo(
		() =>
			isInBetweenColumnPickerEnabled && canViewColumnConfiguration
				? {
						columns: columnsData,
						components: components?.columnPicker,
						onChange: canEditColumnConfiguration ? onColumnPickerChange : undefined,
						onRestoreDefaultColumns,
						useColumnLoader,
						isLoading: fg('issue-jiv-19990-disable-selection-during-loading')
							? isColumnPickerLoading || loading
							: isColumnPickerLoading,
					}
				: undefined,
		[
			canEditColumnConfiguration,
			canViewColumnConfiguration,
			columnsData,
			components?.columnPicker,
			isColumnPickerLoading,
			isInBetweenColumnPickerEnabled,
			loading,
			onColumnPickerChange,
			onRestoreDefaultColumns,
			useColumnLoader,
		],
	);

	useEffect(() => {
		onReady && fg('endeavour_nin_filter_results_gadget') && onReady();
	}, [onReady]);

	const conditionalOnColumnsChange = canEditColumnConfiguration ? handleOnColumnsChange : undefined;

	// Show the skeleton if we are loading on initial render as we may only have partial row data
	if (loading && !isInitialDataLoaded.current) {
		return <IssueTableSkeleton />;
	}

	// TODO JSC-72 Use GraphQL API to source rank field aliases
	const isOrderedByRank = sortField?.toLowerCase() === 'rank';

	const footer = issuesData ? (
		<FooterRenderer
			Footer={components?.Footer}
			IssueCount={components?.IssueCount}
			issues={issuesData}
			onPageChange={onPageChange}
		/>
	) : null;

	const connectionsSubscriber =
		components?.ConnectionsSubscriber &&
		expVal('bulk_operations_in_nin_experiment', 'isEnabled', false) ? (
			<ConnectionsSubscriberContainer ConnectionsSubscriber={components.ConnectionsSubscriber} />
		) : null;

	const hierarchyRemountKey = `${isIssueHierarchyEnabled}-${isGroupingSupported ? groupData?.jql || issuesData?.jql : ''}-${groupData?.groupByField}`;

	return (
		<ContextualAnalyticsData
			sourceName="nativeIssueTable"
			sourceType={SCREEN}
			attributes={analyticsAttributes}
		>
			<FeaturesContainer
				density={density}
				isColumnResizingEnabled={isColumnResizingEnabled}
				isInfiniteScrollEnabled={isInfiniteScrollEnabled}
				isInlineEditingEnabled={isInlineEditingEnabled}
				isInlineEditingExtendedFieldSupportEnabled={isInlineEditingExtendedFieldSupportEnabled}
				isIssueCreateEnabled={isIssueCreateEnabled}
				projectContext={projectContext}
				isIssueHierarchyEnabled={isIssueHierarchyEnabled}
				// TODO JSC-72 Issue ranking (and related features, e.g. sibling issue create) has been removed from
				//  scope of M1. This condition should be updated when/if ranking is re-introduced.
				isOrderedByRank={isOrderedByRank && false}
				isIssueOperationMeatballMenuEnabled={isIssueOperationMeatballMenuEnabled}
				isRowReorderingEnabled={isRowReorderingEnabled}
				isEntireRowDraggable={isEntireRowDraggable}
				isReparentingEnabled={
					isReparentingEnabled &&
					isIssueHierarchyEnabled &&
					(!groupData || !groupData?.groupByField) &&
					userHasEditIssuePermission
				}
				sortDirection={sortDirection}
				sortField={sortField}
				jql={jql ?? ''}
				isColumnBordersEnabled={isColumnBordersEnabled}
				isInlineFieldConfigEnabled={isInlineFieldConfigEnabled}
				isInBetweenColumnPickerEnabled={isInBetweenColumnPickerEnabled}
				isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
				isHideDoneItemsEnabled={isHideDoneItemsEnabled}
			>
				<TableIntersectionObserverContainer>
					<IssueFetchQueryVariables columns={columnsData}>
						<MaybeInlineCreateActiveIndexContainer>
							<SiblingCreateActiveIndexContainer>
								<MaybeHierarchyContainer
									// Key used to reset the container store when the hierarchy settings change
									// Without it there may be flickering when Concurrent Mode is enabled
									key={fg('empanada_nin_concurrent_mode_fixes') ? hierarchyRemountKey : undefined}
									isIssueHierarchyEnabled={isIssueHierarchyEnabled}
									groupByFieldId={groupData?.groupByField}
									firstGroupId={groupData?.firstGroup?.id}
									itemsConnectionId={issuesData?.__id || groupData?.__id}
									jql={isGroupingSupported ? groupData?.jql || issuesData?.jql : null}
								>
									<MaybeDraggableRowsContainer
										onRowReorder={onRowReorder}
										instanceId={issuesData?.__id}
									>
										<MainContainer
											tableBorderRadius={tableBorderRadius}
											data-vc={`issue-table-main-container${__SERVER__ ? '-ssr' : ''}`}
											{...(__SERVER__ &&
												fg('add_ssr_placeholder_replacements_to_nin_and_nav') && {
													'data-ssr-placeholder': 'issue-table-main-container-placeholder',
												})}
											{...(!__SERVER__ &&
												fg('add_ssr_placeholder_replacements_to_nin_and_nav') && {
													'data-ssr-placeholder-replace': 'issue-table-main-container-placeholder',
												})}
										>
											<ElementSizeProvider elementRef={tableRef}>
												<MaybeConnectionsListContainer
													// Key used to reset the container store when the hierarchy settings change
													// Without it there may be flickering when Concurrent Mode is enabled
													key={
														fg('empanada_nin_concurrent_mode_fixes')
															? hierarchyRemountKey
															: undefined
													}
													isIssueHierarchyEnabled={isIssueHierarchyEnabled}
													groupByFieldId={groupData?.groupByField}
													firstGroupId={groupData?.firstGroup?.id}
													jql={isGroupingSupported ? groupData?.jql || issuesData?.jql : null}
												>
													{connectionsSubscriber}
													<ScrollContainer
														footer={footer}
														onLoadNext={onLoadNext}
														onLoadPrevious={onLoadPrevious}
														isLoadingNext={isLoadingNext}
														isLoadingPrevious={isLoadingPrevious}
														hasNext={hasNext}
														hasPrevious={hasPrevious}
														activationThreshold={activationThreshold}
														loading={loading}
														isGrouping={isGroupingSupported && !!groupData}
													>
														<LazyEditabilityLoaderContainer
															scope={LAZY_EDITABILITY_LOADER_CONTAINER_SCOPE}
														>
															<LazyMediaViewContextLoaderContainer
																scope={LAZY_MEDIA_VIEW_CONTEXT_LOADER_CONTAINER_SCOPE}
															>
																<SelectedRowContainer
																	selectedRowIndex={selectedRow}
																	amountOfRows={issuesData?.edges?.length ?? 0}
																	onRowChange={onSelectedRowChange}
																	onPageChange={onPageChange}
																	issues={issuesData}
																>
																	{areShortcutsEnabled && (
																		<KeyboardShortcuts
																			issueKeys={issueKeys}
																			onIssueLinkSelected={tableInjectedProps.onIssueLinkSelected}
																		/>
																	)}
																	<ColumnPickerContainer
																		{...(isInBetweenColumnPickerEnabled && {
																			scope: COLUMN_PICKER_CONFIGURATION_CONTAINER,
																		})}
																	>
																		<CopyPasteContainer>
																			<IssueTable
																				columnPickerProps={columnPickerProps}
																				issueResults={issuesData}
																				groupResults={groupData}
																				project={projectData}
																				columns={mappedColumns}
																				canViewColumnConfiguration={canViewColumnConfiguration}
																				canEditColumnConfiguration={canEditColumnConfiguration}
																				loading={loading}
																				tableInjectedProps={tableInjectedProps}
																				onColumnsChange={conditionalOnColumnsChange}
																				onResizeColumn={onColumnResize}
																				NoColumns={components?.NoColumns}
																				NoIssues={components?.NoIssues}
																				tableRef={tableRef}
																				isGroupingSupported={isGroupingSupported}
																				isCompactColumnEnabled={isCompactColumnEnabled}
																				tableBorderRadius={tableBorderRadius}
																				onIssueFlatListUpdatedOld={onIssueFlatListUpdatedOld}
																				onIssueFlatListUpdated={onIssueFlatListUpdated}
																				ifcConfig={ifcConfig}
																			/>
																		</CopyPasteContainer>
																	</ColumnPickerContainer>
																</SelectedRowContainer>
															</LazyMediaViewContextLoaderContainer>
														</LazyEditabilityLoaderContainer>
													</ScrollContainer>
													{fg('decouple_connection_register_hooks_from_realtime') && (
														<RootConnectionRegister issues={issuesData} />
													)}
													{projectData &&
														expValEquals('jira_spreadsheet_component_m1', 'cohort', 'experiment') &&
														expVal('jira_list_realtime_updates', 'isEnabled', false) &&
														(fg('jira_list_realtime_queue_refactor') ? (
															<RealtimeUpdater
																project={projectData}
																issues={issuesData}
																groups={groupData}
																isHierarchyEnabled={isIssueHierarchyEnabled}
															/>
														) : (
															<RealtimeUpdaterOld
																project={projectData}
																issues={issuesData}
																groups={groupData}
																isHierarchyEnabled={isIssueHierarchyEnabled}
															/>
														))}
												</MaybeConnectionsListContainer>
											</ElementSizeProvider>
										</MainContainer>
									</MaybeDraggableRowsContainer>
								</MaybeHierarchyContainer>
							</SiblingCreateActiveIndexContainer>
						</MaybeInlineCreateActiveIndexContainer>
					</IssueFetchQueryVariables>
				</TableIntersectionObserverContainer>
			</FeaturesContainer>
			<FireUiAnalytics actionSubject="screen" action="viewed" actionSubjectId="nativeIssueTable" />
		</ContextualAnalyticsData>
	);
};

const ConditionalNativeIssueTable = componentWithCondition(
	() => expVal('bulk_operations_in_nin_experiment', 'isEnabled', false),
	IssueTableSelectedIssuesProvider(NativeIssueTable),
	NativeIssueTable,
);

export default ConditionalNativeIssueTable;

export const NativeIssueTableEntryPoint = ({ props }: EntryPointProps<{}, {}, Props, {}>) => (
	<ConditionalNativeIssueTable {...props} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContainer = styled.div<{ tableBorderRadius?: CSSToken }>({
	display: 'flex',
	flexFlow: 'column nowrap',
	borderColor: token('color.border'),
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderWidth: BORDER_WIDTH,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderRadius: `${(props: { tableBorderRadius?: CSSToken }) => props.tableBorderRadius ?? token('border.radius.200')}`,
	overflow: 'hidden',
});
