import React, { type ComponentType, type ReactNode, memo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, TEAM_NAME } from '../../../../common/constants.tsx';
import type { ColumnType } from '../../../../common/types.tsx';
import ErrorCell from '../../../../common/ui/error-cell/index.tsx';
import {
	TableCell,
	type Props as TableCellProps,
} from '../../../../common/ui/table-cell/index.tsx';

const DefaultFallbackComponent = () => <></>;

type Props = {
	/**
	 * Type of the issue field.
	 */
	type: ColumnType;
	/**
	 * Element to render before the cell content of an issue field.
	 */
	beforeElement?: ReactNode;
	/**
	 * Table cell element to render for an issue field.
	 */
	cellElement: ReactNode;
	/**
	 * Optional border styles to apply to the `td` element. If not provided, no borders will be rendered.
	 */
	border?: TableCellProps['border'];
	/**
	 * Optional spacing styles to apply to the `td` element. If not provided, default spacing will apply.
	 */
	spacing?: TableCellProps['spacing'];
	/**
	 * Component to display if the `cellElement` is undefined. This will default to an empty table cell if not provided.
	 */
	FallbackComponent?: ComponentType;

	row?: number;
	column?: number;
};

const IssueRowCellErrorFallback = ({
	type,
	border,
}: {
	type: ColumnType;
	columnWidth?: number;
	isFlexWidth?: boolean;
	border?: TableCellProps['border'];
}) => (
	<TableCell border={border}>
		<ErrorCell cellType={type} reason="Unhandled error" />
	</TableCell>
);
const IssueRowCell = ({
	type,
	beforeElement,
	cellElement,
	border,
	spacing,
	FallbackComponent = DefaultFallbackComponent,
	row,
	column,
}: Props) => (
	<ContextualAnalyticsData attributes={{ fieldType: type }}>
		<JSErrorBoundary
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			id="issueRowCellUnhandled"
			sendToPrivacyUnsafeSplunk
			fallback={() => <IssueRowCellErrorFallback type={type} border={border} />}
		>
			<TableCell
				spacing={spacing}
				border={border}
				{...(fg('jira_list_grid_pattern_keyboard_nav') && {
					row,
					column,
				})}
			>
				<>
					{beforeElement}
					{cellElement ?? <FallbackComponent />}
				</>
			</TableCell>
		</JSErrorBoundary>
	</ContextualAnalyticsData>
);

export default memo<Props>(IssueRowCell);
