/**
 * @generated SignedSource<<43f92167e2187e5f16de8edc7c53ac14>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type rows_nativeIssueTable$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly __id: string;
    readonly fieldSets?: {
      readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_fieldSets">;
    } | null | undefined;
    readonly hasChildren?: boolean | null | undefined;
    readonly node: {
      readonly id: string;
      readonly issueId: string;
      readonly issueTypeField: {
        readonly issueType: {
          readonly hierarchy: {
            readonly level: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_issue">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_issueEdge">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "rows_nativeIssueTable";
};
export type rows_nativeIssueTable$key = {
  readonly " $data"?: rows_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"rows_nativeIssueTable">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "isDensityFull",
  "variableName": "isDensityFull"
},
v1 = {
  "kind": "Variable",
  "name": "isInlineEditingEnabled",
  "variableName": "isInlineEditingEnabled"
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "rows_nativeIssueTable",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "isReparentingEnabled",
              "variableName": "isReparentingEnabled"
            },
            {
              "kind": "Variable",
              "name": "projectKey",
              "variableName": "projectKey"
            },
            {
              "kind": "Variable",
              "name": "projectKeys",
              "variableName": "projectKeys"
            },
            {
              "kind": "Variable",
              "name": "shouldQueryHasChildren",
              "variableName": "shouldQueryHasChildren"
            }
          ],
          "kind": "FragmentSpread",
          "name": "row_nativeIssueTable_issueEdge"
        },
        {
          "condition": "isReparentingEnabled",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterByProjectKeys",
                  "variableName": "projectKeys"
                }
              ],
              "kind": "ScalarField",
              "name": "hasChildren"
            }
          ]
        },
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "ScalarField",
              "name": "key"
            },
            {
              "kind": "ScalarField",
              "name": "issueId"
            },
            {
              "concreteType": "JiraIssueTypeField",
              "kind": "LinkedField",
              "name": "issueTypeField",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueType",
                  "kind": "LinkedField",
                  "name": "issueType",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeHierarchyLevel",
                      "kind": "LinkedField",
                      "name": "hierarchy",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "level"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "args": (v2/*: any*/),
              "kind": "FragmentSpread",
              "name": "row_nativeIssueTable_issue"
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 500
                }
              ],
              "concreteType": "JiraIssueFieldSetConnection",
              "kind": "LinkedField",
              "name": "fieldSets",
              "plural": false,
              "selections": [
                {
                  "args": (v2/*: any*/),
                  "kind": "FragmentSpread",
                  "name": "row_nativeIssueTable_fieldSets"
                }
              ],
              "storageKey": "fieldSets(first:500)"
            }
          ]
        },
        (v3/*: any*/)
      ]
    },
    (v3/*: any*/)
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "047aaaeef241e6738462ed8cf6130ab2";

export default node;
