/**
 * @generated SignedSource<<d0ec6f9a42c69561a21d355d7d95393a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cmdbObjects_nativeIssueTable_CmdbObjectsCell$data = {
  readonly selectedCmdbObjectsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly avatar: {
          readonly mediaClientConfig: {
            readonly clientId: string | null | undefined;
            readonly fileId: string | null | undefined;
            readonly mediaBaseUrl: AGG$URL | null | undefined;
            readonly mediaJwtToken: string | null | undefined;
          } | null | undefined;
          readonly url48: string | null | undefined;
        } | null | undefined;
        readonly label: string | null | undefined;
        readonly objectId: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly message: string | null | undefined;
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "cmdbObjects_nativeIssueTable_CmdbObjectsCell";
};
export type cmdbObjects_nativeIssueTable_CmdbObjectsCell$key = {
  readonly " $data"?: cmdbObjects_nativeIssueTable_CmdbObjectsCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"cmdbObjects_nativeIssueTable_CmdbObjectsCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cmdbObjects_nativeIssueTable_CmdbObjectsCell",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraCmdbMediaClientConfig",
                      "kind": "LinkedField",
                      "name": "mediaClientConfig",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "clientId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "fileId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "mediaBaseUrl"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "mediaJwtToken"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "message"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
};

(node as any).hash = "b737019714db01f1a69458d583f66ece";

export default node;
