import parameters from '@atlassian/jira-relay/src/__generated__/ui_RichTextPopUpContentQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { issueKey: string; fieldId: string; cloudId: string };

export const richTextPopUpContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-rich-text-content" */ './ui'),
	),
	getPreloadProps: ({ issueKey, fieldId, cloudId }: EntryPointParams) => ({
		queries: {
			richTextContent: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					issueKey,
					fieldId,
					cloudId,
				},
			},
		},
		extraProps: {
			issueKey,
		},
	}),
});
