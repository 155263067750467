import React, {
	type ChangeEventHandler,
	type KeyboardEventHandler,
	type FormEventHandler,
	type MouseEventHandler,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type {
	IssueToCreate,
	IssueCreateIssueType,
	IssueCreateProject,
	ContextualFields,
} from '../../common/types.tsx';

import {
	IssueCreateContextualFieldsData,
	useIssueCreateContextualFields,
} from '../../controllers/issue-create-contextual-fields-data/index.tsx';
import type IssueTypeDropdownEntrypoint from './issue-type-dropdown/entrypoint.tsx';
import { IssueTypeDropdown } from './issue-type-dropdown/index.tsx';
import messages from './messages.tsx';
import ReturnIcon from './return-icon/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useInlineIssueCreateEntryPoint } from './utils';

const ICON_WIDTH = 24;
const PADDING = 8;

export const useIsReady = () => {
	const isReadyRef = useRef(false);
	useEffect(() => {
		setTimeout(() => {
			isReadyRef.current = true;
		}, 100);
	}, []);

	return isReadyRef;
};

const isComposing = (event: React.KeyboardEvent) => event.nativeEvent.isComposing;

export const IssueTypeDropdownFallback = () => (
	<IssueTypeDropdown project={null} onChange={noop} onProjectChange={noop} selectedType={null} />
);

type Appearance = 'row' | 'footer';

type InlineCreateFormProps = {
	initialIssueDraft?: Partial<IssueToCreate>;
	onDraftUpdate?: (issueDraft: Partial<IssueToCreate>) => void;
	onCreateIssue: (issue: IssueToCreate, contextualFields: ContextualFields) => void;
	onCancel?: () => void;
	appearance?: Appearance;
	entryPointSubject: EntryPointReferenceSubject<typeof IssueTypeDropdownEntrypoint>;
	depth: number;
};

const InlineCreateForm = ({
	initialIssueDraft,
	onDraftUpdate,
	onCreateIssue,
	onCancel,
	entryPointSubject,
	appearance = 'row',
	depth = 0,
}: InlineCreateFormProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [textContent, setTextContent] = useState(initialIssueDraft?.summary ?? '');
	const [selectedType, setSelectedType] = useState<IssueCreateIssueType | null>(
		initialIssueDraft?.issueType ?? null,
	);
	const [selectedProject, setSelectedProject] = useState<IssueCreateProject | null>(
		initialIssueDraft?.project ?? null,
	);

	useEffect(() => {
		onDraftUpdate?.({
			summary: textContent,
			project: selectedProject ?? undefined,
			issueType: selectedType ?? undefined,
		});
	}, [onDraftUpdate, selectedProject, selectedType, textContent]);

	const formRef = useRef<HTMLFormElement | null>(null);
	const isEmptyRef = useRef(true);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	isEmptyRef.current = textContent.trim().length === 0;
	const contextualFields = useIssueCreateContextualFields();

	// prevent blurring from occurring when the form is first rendered
	const isReadyRef = useIsReady();

	const handleCreateIssue = useCallback(() => {
		if (!isEmptyRef.current && selectedType && selectedProject) {
			onCreateIssue(
				{
					summary: textContent,
					project: selectedProject,
					issueType: selectedType,
				},
				contextualFields,
			);
			setTextContent('');
		}
	}, [contextualFields, onCreateIssue, selectedProject, selectedType, textContent]);

	const handleCreateIssueRef = useRef(handleCreateIssue);
	handleCreateIssueRef.current = handleCreateIssue;

	const handleBlur = useCallback(
		(event: MouseEvent) => {
			if (!isReadyRef.current) {
				return;
			}

			// make sure to make a local copy of the isEmptyRef value before it gets reset by the create handler
			const formEmpty = isEmptyRef.current;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const inForm = formRef.current?.contains(event.target as Node) ?? false;

			if (!inForm) {
				onCancel?.();
				fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
					formEmpty,
					escapeKeyPressed: false,
				});
			}
		},
		[isReadyRef, onCancel, createAnalyticsEvent],
	);

	const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((e) => {
		const content = e.target.value.replace(/[\n\r]+/g, ' ');
		setTextContent(content);
	}, []);

	const handleKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
		(e) => {
			if (e.key === 'Enter' && !isComposing(e)) {
				e.preventDefault();
				handleCreateIssue();
			}
		},
		[handleCreateIssue],
	);

	const handleFormKeyDown = useCallback<KeyboardEventHandler<HTMLFormElement>>(
		(e) => {
			e.stopPropagation();

			if (
				e.key === 'Escape' &&
				!isComposing(e) &&
				!expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false)
			) {
				onCancel?.();
				fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
					formEmpty: isEmptyRef.current,
					escapeKeyPressed: true,
				});
			}
		},
		[createAnalyticsEvent, onCancel],
	);

	const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(e) => {
			e.preventDefault();
			handleCreateIssue();
		},
		[handleCreateIssue],
	);

	const handleCreateButtonClick = useCallback<MouseEventHandler<HTMLElement>>(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineCreateSubmit');
	}, [createAnalyticsEvent]);

	useEffect(() => {
		// on mount, we want to move the cursor to the end of the input
		// TODO; Add test for this feature once we support pre-population of the summary
		textAreaRef.current?.setSelectionRange(
			textAreaRef.current.value.length,
			textAreaRef.current.value.length,
		);
		fireUIAnalytics(createAnalyticsEvent({}), 'form focused', 'inlineCreate');
	}, [createAnalyticsEvent]);

	const handleEscape = useCallback(
		(e: KeyboardEvent) => {
			e.stopPropagation();
			if (e.key === 'Escape') {
				onCancel?.();
				fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
					formEmpty: isEmptyRef.current,
					escapeKeyPressed: true,
				});
			}
			if (e.key === 'Enter') {
				e.preventDefault();
				handleCreateIssue();
			}
		},
		[createAnalyticsEvent, handleCreateIssue, onCancel],
	);

	useEffect(() => {
		if (!__SERVER__) {
			document.addEventListener('click', handleBlur, true);
			if (expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false)) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.addEventListener('keydown', handleEscape, true);
			}

			return () => {
				document.removeEventListener('click', handleBlur, true);
				if (expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false)) {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					document.removeEventListener('keydown', handleEscape, true);
				}
			};
		}
	}, [handleBlur, handleEscape]);

	return (
		// TODO: Check if we can catch the escape in a different level that removes below lint rule
		<FullContent
			ref={formRef}
			onKeyDown={handleFormKeyDown}
			onSubmit={handleSubmit}
			appearance={appearance}
			depth={depth}
		>
			{!expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false) &&
				selectedType?.avatar?.small != null &&
				selectedType.avatar && (
					<Box xcss={IssueTypeIconStyles} as="img" alt="" src={selectedType.avatar.small} />
				)}
			{expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false) && (
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointSubject}
					id="async-issue-create-issue-type-dropdown"
					packageName={PACKAGE_NAME}
					teamName={TEAM_NAME}
					fallback={<IssueTypeDropdownFallback />}
					runtimeProps={{
						onChange: setSelectedType,
						onProjectChange: setSelectedProject,
						selectedType,
					}}
				/>
			)}
			<Box
				xcss={TextAreaStyles}
				as="textarea"
				autoFocus
				aria-label={formatMessage(messages.placeholderText)}
				placeholder={formatMessage(messages.placeholderText)}
				value={textContent}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				ref={textAreaRef}
			/>
			<Flex gap="space.100" alignItems="center" justifyContent="end">
				{!expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false) && (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointSubject}
						id="async-issue-create-issue-type-dropdown"
						packageName={PACKAGE_NAME}
						teamName={TEAM_NAME}
						fallback={<IssueTypeDropdownFallback />}
						runtimeProps={{
							onChange: setSelectedType,
							onProjectChange: setSelectedProject,
							selectedType,
						}}
					/>
				)}
				<StyledCreateButton
					appearance="primary"
					iconAfter={<ReturnIcon />}
					isDisabled={isEmptyRef.current}
					spacing="compact"
					type="submit"
					onClick={handleCreateButtonClick}
				>
					{formatMessage(messages.createButton)}
				</StyledCreateButton>
			</Flex>
		</FullContent>
	);
};

export type Props = InlineCreateFormProps & {
	contextualFields?: ContextualFields;
};

const InlineCreateFormWithContainer = ({ contextualFields, ...props }: Props) => (
	<JSErrorBoundary fallback="flag" id="issue-table-inline-issue-create">
		<IssueCreateContextualFieldsData payload={contextualFields}>
			<InlineCreateForm {...props} />
		</IssueCreateContextualFieldsData>
	</JSErrorBoundary>
);

export default memo(InlineCreateFormWithContainer);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullContent = styled.form<Pick<InlineCreateFormProps, 'appearance' | 'depth'>>(
	{
		display: 'flex',
		gap: token('space.100'),

		backgroundColor: token('elevation.surface'),
		transition: 'box-shadow 200ms ease-in-out',
		boxShadow: `inset 0 0 0 ${token('border.width.outline')} ${token('color.border.focused')}`,
		boxSizing: 'border-box',
		outline: 'none',
		minHeight: '40px',
		flexDirection: 'row',
		alignItems: 'center',
		height: '40px',
		margin: '0px', // margin: '0px' is needed to override the default margin of the form element
		paddingTop: token('space.050'),
		paddingRight: token('space.100'),
		paddingBottom: token('space.050'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		paddingLeft: (props) =>
			`${props.depth > 1 ? (props.depth - 1) * ICON_WIDTH + PADDING : PADDING}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	(props) =>
		props.appearance === 'footer'
			? {
					borderBottomLeftRadius: token('border.radius.200'),
					borderBottomRightRadius: token('border.radius.200'),
				}
			: {
					borderRadius: token('border.radius.100'),
				},
);

const TextAreaStyles = xcss({
	flex: '1',
	border: 'none',
	outline: 'none',
	margin: '0',
	padding: 'space.050',
	height: '20px',
	resize: 'none',
	backgroundColor: 'elevation.surface',
	color: 'color.text',
	font: 'font.body',
	scrollbarWidth: 'none',
	whiteSpace: 'pre',
	boxSizing: 'content-box',
	fontFamily: 'inherit',
});

const IssueTypeIconStyles = xcss({
	height: '16px',
	width: '16px',
});

// TODO: Maybe we can rewrite to pressable....
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCreateButton = styled(Button)({
	flex: 'none',
});
