import React, { useRef, useCallback, useEffect, type ComponentType, type RefObject } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { CSSToken } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Props as AsyncColumnPickerProps } from '@atlassian/jira-issue-table-column-picker/src/ui/main.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import type { issueTable_nativeIssueTable_groupResults$key as GroupResultsFragment } from '@atlassian/jira-relay/src/__generated__/issueTable_nativeIssueTable_groupResults.graphql';
import type { issueTable_nativeIssueTable_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/issueTable_nativeIssueTable_issueResults.graphql';
import type { issueTable_nativeIssueTable_project$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/issueTable_nativeIssueTable_project.graphql.ts';
import { getFlatListKey } from '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-flat-list-key/index.tsx';
import {
	useFlatList,
	useFlatListIssueIds,
	useIssueIds,
	useFlatListIssueKeys,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { getSSRSafeFlatListWithGrouping } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/utils.tsx';
import {
	ACTION_COLUMNS,
	COLUMN_ID_MEATBALL_MENU,
	COLUMN_RESIZE_HANDLE_FULL_HEIGHT,
	COLUMN_RESIZE_HANDLE_HEADER_ONLY,
	ROW_HEIGHT,
	HEADER_HEIGHT,
} from '../../common/constants.tsx';
import type {
	NoColumnsProps,
	Column,
	ColumnId,
	TableInjectedCellProps,
	NoIssuesProps,
} from '../../common/types.tsx';
import { useTableGridNavigation } from '../../common/ui/cell-navigation/index.tsx';
import { Overlay, OverlayOld } from '../../common/ui/overlay/index.tsx';
import {
	useIsFixedTableLayoutEnabled,
	useIsVirtualizationEnabled,
} from '../../controllers/features/selectors.tsx';
import { VirtualizationContainer } from '../../controllers/virtualization/rss-store.tsx';
import type { IFCConfigType } from '../../common/ui/inline-field-config/types.tsx';
import { IFCConfigContextProviderComponent } from '../../common/ui/inline-field-config/context/index.tsx';
import { GroupTableBody } from './group-table-body/index.tsx';
import { Header } from './header/index.tsx';
import messages from './messages.tsx';
import NoColumnsRenderer from './no-columns-renderer/index.tsx';
import NoIssuesRenderer from './no-issues-renderer/index.tsx';
import TableBody from './table-body/index.tsx';
import { VirtualizedTable } from './virtualized-table/index.tsx';
import { InitializeVirtualizer } from './initialize-virtualizer/index.tsx';

const emptyArray: string[] = [];

// We use rowsCount=50 by default to set the minimum table height until virtualizer reads it from DOM
const getInitialTableHeight = (rowsCount = 50) => ROW_HEIGHT * rowsCount + HEADER_HEIGHT;

export type Props = {
	columns: Column[];
	NoIssues?: ComponentType<NoIssuesProps>;
	canViewColumnConfiguration: boolean;
	canEditColumnConfiguration: boolean;
	loading: boolean;
	issueResults: IssueResultsFragment | null;
	groupResults: GroupResultsFragment | null;
	project: ProjectFragment | null;
	NoColumns?: ComponentType<NoColumnsProps>;
	onColumnsChange?: (columns: Column[]) => void;
	onResizeColumn?: (columnId: ColumnId, width: number | undefined) => void;
	// @deprecated use onIssueFlatListUpdated instead. Remove when cleaning up bulk_operations_in_nin_experiment
	onIssueFlatListUpdatedOld?: (newFlatList: string[]) => void;
	onIssueFlatListUpdated?: (newFlatKeyList: string[], newFlatIdList: string[]) => void;
	tableInjectedProps: TableInjectedCellProps;
	tableRef: RefObject<HTMLTableElement>;
	isGroupingSupported?: boolean;
	isCompactColumnEnabled?: boolean;
	tableBorderRadius?: CSSToken;
	columnPickerProps?: AsyncColumnPickerProps;
	ifcConfig?: IFCConfigType;
};

const IssueTable = ({
	columnPickerProps,
	columns,
	NoIssues,
	canViewColumnConfiguration,
	canEditColumnConfiguration,
	issueResults,
	groupResults,
	project,
	loading,
	NoColumns,
	onColumnsChange,
	onResizeColumn,
	tableInjectedProps,
	tableRef,
	isGroupingSupported,
	isCompactColumnEnabled = false,
	onIssueFlatListUpdatedOld,
	onIssueFlatListUpdated,
	tableBorderRadius,
	ifcConfig,
}: Props) => {
	const { formatMessage } = useIntl();
	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment issueTable_nativeIssueTable_issueResults on JiraIssueConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				edges {
					node {
						issueId
						key
					}
					__typename # only interested in edge presence to handle empty state
				}
				...tableBody_nativeIssueTable_issueResults
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
			}
		`,
		issueResults,
	);

	const groupResultsData = useFragment<GroupResultsFragment>(
		graphql`
			fragment issueTable_nativeIssueTable_groupResults on JiraSpreadsheetGroupConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				isPaginating: { type: "Boolean", defaultValue: false }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
			) {
				firstGroup @skip(if: $isPaginating) {
					id
					issues(fieldSetsInput: $fieldSetsInput, first: 50) {
						edges {
							node {
								issueId
							}
						}
					}
				}
				edges {
					node {
						id
					}
				}
				...groupTableBody_nativeIssueTable_groupResults
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isDensityFull: $isDensityFull
						isPaginating: $isPaginating
						projectKeys: $projectKeys
						projectKey: $projectKey
					)
			}
		`,
		groupResults,
	);

	const projectData = useFragment<ProjectFragment>(
		graphql`
			fragment issueTable_nativeIssueTable_project on JiraProject {
				...groupTableBody_nativeIssueTable_project
				...tableBody_nativeIssueTable_project
			}
		`,
		project,
	);

	let flatList = emptyArray;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		if (__SERVER__ && !fg('empanada_nin_concurrent_mode_fixes')) {
			flatList =
				groupResultsData && isGroupingSupported
					? getSSRSafeFlatListWithGrouping({
							firstGroupId: getFlatListKey({
								itemId: groupResultsData.firstGroup?.id ?? '',
								groupId: groupResultsData.firstGroup?.id,
							}),
							firstGroupIssueIds: groupResultsData.firstGroup?.issues?.edges
								?.map((edge) => edge?.node?.issueId)
								.filter(Boolean)
								.map((issueId) =>
									getFlatListKey({
										itemId: issueId ?? '',
										groupId: groupResultsData.firstGroup?.id,
									}),
								),
							groupIds: groupResultsData.edges
								// groupId is the same as id
								?.map((edge) =>
									getFlatListKey({
										itemId: edge?.node?.id ?? '',
										groupId: edge?.node?.id,
									}),
								)
								.filter(Boolean),
						})
					: issueResultsData?.edges?.map((edge) => edge?.node?.issueId).filter(Boolean) ?? [];
		} else {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			flatList = useFlatList();
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const flatListIssueKeys = useFlatListIssueKeys();
		const flatListIssueIds = fg('jira_list_grouping_bulk_ops')
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useIssueIds()
			: // eslint-disable-next-line react-hooks/rules-of-hooks
				useFlatListIssueIds();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (
				onIssueFlatListUpdated &&
				expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
			) {
				onIssueFlatListUpdated(flatListIssueKeys.filter(Boolean), flatListIssueIds);
			} else if (onIssueFlatListUpdatedOld) {
				onIssueFlatListUpdatedOld(flatListIssueKeys.filter(Boolean));
			}
		}, [
			flatList,
			flatListIssueIds,
			flatListIssueKeys,
			onIssueFlatListUpdated,
			onIssueFlatListUpdatedOld,
		]);
	}

	const getItemKey = useCallback(
		(index: number) => {
			if (fg('jsc_m2_hierarchy_fe_changes')) {
				if (fg('concurrent-rendering-fix-unique-list-key')) {
					return flatList[index] ?? index;
				}
				return flatList[index];
			}

			return issueResultsData?.edges?.at(index)?.node?.key ?? index;
		},
		[flatList, issueResultsData?.edges],
	);

	const isGrouping = isGroupingSupported && !!groupResultsData;
	const totalSizeRef = useRef<number>(getInitialTableHeight(issueResultsData?.edges?.length));

	const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();

	const showNoIssues = issueResultsData && !issueResultsData.edges?.length;
	const showNoGroupingIssues =
		isGroupingSupported && groupResultsData && !groupResultsData.edges?.length;

	let showNoColumns =
		columns.length === 0 || (columns.length === 1 && columns[0].id === COLUMN_ID_MEATBALL_MENU);

	if (fg('jsc_m2_hierarchy_fe_changes')) {
		showNoColumns = columns.filter((col) => !ACTION_COLUMNS.includes(col.id)).length === 0;
	}

	const isVirtualizationEnabled = useIsVirtualizationEnabled();

	const { cellNavigationRef, onBlur, onFocus, tabIndex, onKeyDown } = fg(
		'jira_list_grid_pattern_keyboard_nav',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useTableGridNavigation({
				rowCount: issueResultsData?.edges?.length ?? 0,
				colCount: columns.length,
			})
		: {
				cellNavigationRef: null,
				onBlur: undefined,
				onFocus: undefined,
				tabIndex: undefined,
				onKeyDown: undefined,
			};

	const tableRefs = fg('jira_list_grid_pattern_keyboard_nav')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMergeRefs(tableRef, cellNavigationRef)
		: tableRef;

	const tableHeader = (
		<IFCConfigContextProviderComponent ifcConfig={ifcConfig}>
			<Header
				columnPickerProps={columnPickerProps}
				columns={columns}
				canViewColumnConfiguration={canViewColumnConfiguration}
				canEditColumnConfiguration={canEditColumnConfiguration}
				isCompactColumnEnabled={isCompactColumnEnabled}
				resizeHandleType={
					showNoIssues || showNoColumns
						? COLUMN_RESIZE_HANDLE_HEADER_ONLY
						: COLUMN_RESIZE_HANDLE_FULL_HEIGHT
				}
				onColumnsChange={onColumnsChange}
				onResizeColumn={onResizeColumn}
				tableRef={tableRef}
				tableBorderRadius={tableBorderRadius}
			/>
		</IFCConfigContextProviderComponent>
	);

	if (showNoIssues || showNoGroupingIssues) {
		return (
			<>
				<Table
					isFixedTableLayoutEnabled={isFixedTableLayoutEnabled}
					aria-label={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.issuesTableLabelIssueTermRefresh
							: messages.issuesTableLabel,
					)}
					ref={tableRefs}
					onBlur={onBlur}
					onFocus={onFocus}
					tabIndex={tabIndex}
					onKeyDown={onKeyDown}
				>
					{tableHeader}
				</Table>
				<NoIssuesRenderer NoIssues={NoIssues} isLoading={loading} />
			</>
		);
	}

	if (showNoColumns) {
		return (
			<>
				{canViewColumnConfiguration && (
					<Table
						isFixedTableLayoutEnabled={isFixedTableLayoutEnabled}
						aria-label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.issuesTableLabelIssueTermRefresh
								: messages.issuesTableLabel,
						)}
						ref={tableRef}
					>
						{tableHeader}
					</Table>
				)}
				<NoColumnsRenderer
					NoColumns={NoColumns}
					hasColumnConfiguration={canViewColumnConfiguration}
					isLoading={loading}
				/>
			</>
		);
	}
	const renderTableBody = () => {
		if (isGroupingSupported && groupResultsData && !issueResultsData) {
			return (
				<GroupTableBody
					groupResults={groupResultsData}
					columns={columns}
					tableInjectedProps={tableInjectedProps}
					project={projectData}
					loading={loading}
				/>
			);
		}

		if (issueResultsData == null) return null;
		const tableBody = (
			<TableBody
				issueResults={issueResultsData}
				columns={columns}
				tableInjectedProps={tableInjectedProps}
				project={projectData}
				isGroupingSupported={isGroupingSupported}
			/>
		);

		return tableBody;
	};

	const renderTable = (style?: React.CSSProperties) => {
		return (
			<Table
				{...(style ? { style } : {})}
				isFixedTableLayoutEnabled={isFixedTableLayoutEnabled}
				aria-label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.issuesTableLabelIssueTermRefresh
						: messages.issuesTableLabel,
				)}
				ref={tableRefs}
				onBlur={onBlur}
				onFocus={onFocus}
				tabIndex={tabIndex}
				onKeyDown={onKeyDown}
				data-vc={`issue-table${__SERVER__ ? '-ssr' : ''}`}
				{...(__SERVER__ &&
					fg('additional_nin_ssr_placeholders') && {
						'data-ssr-placeholder': 'issue-table-placeholder',
					})}
				{...(!__SERVER__ &&
					fg('additional_nin_ssr_placeholders') && {
						'data-ssr-placeholder-replace': 'issue-table-placeholder',
					})}
			>
				{tableHeader}
				{renderTableBody()}
				{loading ? (
					<TableFooter>
						<tr>
							<td>
								{fg('add_nin_press_interactions') ? (
									<Overlay interactionName="issue-table-renderer-overlay" />
								) : (
									<OverlayOld />
								)}
							</td>
						</tr>
					</TableFooter>
				) : null}
			</Table>
		);
	};

	const rowsCount =
		flatList?.length > 0 && fg('jsc_m2_hierarchy_fe_changes')
			? flatList?.length
			: issueResultsData?.edges?.length;

	if (isVirtualizationEnabled) {
		if (fg('jsc_virtualizer_rss_store')) {
			return (
				<VirtualizationContainer>
					{renderTable({
						...(!fg('jsc-1473-fix-first-row-too-much-padding') &&
							!isGrouping && {
								height: `${totalSizeRef.current}px`,
							}),
						width: '100%',
						position: 'relative',
					})}
					<InitializeVirtualizer
						rows={rowsCount ?? 0}
						getItemKey={getItemKey}
						totalSizeRef={totalSizeRef}
					/>
				</VirtualizationContainer>
			);
		}

		return (
			<VirtualizedTable rows={rowsCount ?? 0} getItemKey={getItemKey}>
				{(style) => renderTable(style)}
			</VirtualizedTable>
		);
	}

	return renderTable();
};

export default IssueTable;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Table = styled.table<{ isFixedTableLayoutEnabled: boolean }>(
	{
		// Cross-browser CSS hack to allow inline editable fields to fill the entire space of their `td`. Please do
		// extensive cross-browser testing before you consider updating this rule.
		height: '1px',
		position: 'relative',
		margin: 0,
		// Using separate borders with no border spacing so that sticky table header can maintain borders on scroll
		// Note: having separate borders should also facilitate adding a border radius to the table if desired
		borderCollapse: 'separate',
		borderSpacing: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isFixedTableLayoutEnabled }) =>
		isFixedTableLayoutEnabled && {
			tableLayout: 'fixed',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableFooter = styled.tfoot({
	border: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	td: {
		padding: 0,
	},
});
