/**
 * @generated SignedSource<<b41c32994c435331b250ce449fc44634>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_RowWithSiblingCreate_issueEdge$data = {
  readonly node: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge">;
  readonly " $fragmentType": "row_nativeIssueTable_RowWithSiblingCreate_issueEdge";
};
export type row_nativeIssueTable_RowWithSiblingCreate_issueEdge$key = {
  readonly " $data"?: row_nativeIssueTable_RowWithSiblingCreate_issueEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowWithSiblingCreate_issueEdge">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_RowWithSiblingCreate_issueEdge",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isReparentingEnabled",
          "variableName": "isReparentingEnabled"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_MaybeRowWithDragAndDrop_issueEdge"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        }
      ]
    }
  ],
  "type": "JiraIssueEdge"
};

(node as any).hash = "0d2dd8ff9005b8953147938127c7b8d3";

export default node;
