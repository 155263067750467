import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	errorMessage: {
		id: 'native-issue-table.common.issue-cells.rich-text.error-state.error-message',
		defaultMessage: 'Something went wrong. Close and re-open this modal to try again.',
		description: 'The text for when an error occurs when loading options',
	},
});

export default messages;
