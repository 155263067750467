import mergeWith from 'lodash/mergeWith';
import type {
	ContextualFields,
	IssueToCreate,
} from '@atlassian/jira-issue-table-inline-issue-create/src/common/types.tsx';
import type { JiraIssueCreateInput } from '@atlassian/jira-relay/src/__generated__/issueCreateMutation_useIssueCreateMutation.graphql';

const mapContextualFieldDataToInput = (
	payload: ContextualFields,
): Partial<JiraIssueCreateInput> => {
	let rank;
	if (payload.rankBeforeIssueId) {
		rank = {
			beforeIssueId: payload.rankBeforeIssueId,
		};
	} else if (payload.rankAfterIssueId) {
		rank = {
			afterIssueId: payload.rankAfterIssueId,
		};
	}

	return {
		rank,
	};
};

export const toIssueCreateInput = (
	{ project, issueType, summary }: IssueToCreate,
	contextualFields: ContextualFields,
): JiraIssueCreateInput | null => {
	if (!summary.trim().length) {
		return null;
	}

	const input = {
		projectId: project.id,
		issueTypeId: issueType.id,
		fields: {
			singleLineTextFields: [
				{
					fieldId: 'summary',
					text: summary,
				},
			],
			...(contextualFields.parentIssueAri !== undefined && {
				parentField: { issueId: contextualFields.parentIssueAri },
			}),
		},
	};

	return mergeWith(input, mapContextualFieldDataToInput(contextualFields), (objValue, srcValue) => {
		if (Array.isArray(objValue)) {
			return objValue.concat(srcValue);
		}
	});
};
