/**
 * @generated SignedSource<<b92cc8d61690b086c94cc5bd1949b44b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type checkboxSelect_nativeIssueTable_CheckboxSelectCell$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "checkboxSelect_nativeIssueTable_CheckboxSelectCell";
};
export type checkboxSelect_nativeIssueTable_CheckboxSelectCell$key = {
  readonly " $data"?: checkboxSelect_nativeIssueTable_CheckboxSelectCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxSelect_nativeIssueTable_CheckboxSelectCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "checkboxSelect_nativeIssueTable_CheckboxSelectCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraCheckboxesField"
};

(node as any).hash = "2c20809123ea14dfdd133e575ffd4309";

export default node;
