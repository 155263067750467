/**
 * @generated SignedSource<<c8908458561d572f3260215943998593>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type firstColumnCell_nativeIssueTable$data = {
  readonly canHaveChildIssues?: boolean | null | undefined;
  readonly hasChildren?: boolean | null | undefined;
  readonly node?: {
    readonly issueId: string;
  } | null | undefined;
  readonly " $fragmentType": "firstColumnCell_nativeIssueTable";
};
export type firstColumnCell_nativeIssueTable$key = {
  readonly " $data"?: firstColumnCell_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"firstColumnCell_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "firstColumnCell_nativeIssueTable",
  "selections": [
    {
      "condition": "shouldQueryHasChildren",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "filterByProjectKeys",
              "variableName": "projectKeys"
            }
          ],
          "kind": "ScalarField",
          "name": "hasChildren"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "projectKey",
              "variableName": "projectKey"
            }
          ],
          "kind": "ScalarField",
          "name": "canHaveChildIssues"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueEdge"
};

(node as any).hash = "2a1a86d094950853f5ed2066834bdfcd";

export default node;
