import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { team_issueFieldTeamReadviewFull_TeamReadView$key as TeamFragment } from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamReadviewFull_TeamReadView.graphql';
import AsyncTeamProfileCardNext from '@atlassian/jira-profilecard-next/src/ui/team-profilecard/async.tsx';
import type { TeamReadViewProps } from './types.tsx';

/**
 * The TeamReadView will show a read only view of Team field.
 * @param props [TeamReadViewProps](./types.tsx)
 */
export const TeamReadView = ({ fragmentRef, maxLines, triggerLinkType }: TeamReadViewProps) => {
	const { selectedTeam } = useFragment<TeamFragment>(
		graphql`
			fragment team_issueFieldTeamReadviewFull_TeamReadView on JiraTeamViewField {
				selectedTeam {
					jiraSuppliedId
					jiraSuppliedName
				}
			}
		`,
		fragmentRef,
	);

	if (!selectedTeam?.jiraSuppliedName) {
		return <EmptyFieldText />;
	}

	return (
		<AsyncTeamProfileCardNext
			teamId={selectedTeam.jiraSuppliedId}
			trigger="hover-click"
			position="left-start"
			triggerLinkType={triggerLinkType}
		>
			<Text maxLines={maxLines}>{selectedTeam.jiraSuppliedName}</Text>
		</AsyncTeamProfileCardNext>
	);
};
