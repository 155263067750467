import React, { type ComponentType } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { footerRenderer_nativeIssueTable$key as IssuesKey } from '@atlassian/jira-relay/src/__generated__/footerRenderer_nativeIssueTable.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { IssueCountProps, FooterProps } from '../../common/types.tsx';
import { Footer as DefaultFooter } from '../../common/ui/components/footer/index.tsx';
import {
	useIsInfiniteScrollEnabled,
	useIsIssueCreateEnabled,
} from '../../controllers/features/selectors.tsx';
import IssueCountRenderer from './issue-count-renderer/main.tsx';
import { IssueCreateFooter } from './issue-create-footer/index.tsx';
import { PaginationControls } from './pagination-controls/index.tsx';

export type Props = {
	/**
	 * Overridable Footer component to render.
	 */
	Footer?: ComponentType<FooterProps>;
	/**
	 * Overridable IssueCount component to render.
	 */
	IssueCount?: ComponentType<IssueCountProps>;
	/**
	 * Relay fragment key for a connection of issues.
	 */
	issues: IssuesKey;
	/**
	 * Callback invoked when a user action results in a need to navigate to a different page of issues. This callback
	 * will be invoked with the cursor to paginate to, and whether last issue in the result set should be selected (for
	 * example, if the user is trying to navigate from the first issue in a page to the last issue in the previous page).
	 */
	onPageChange?: (cursor: string, shouldSelectLastIssue: boolean) => void;
};

/**
 * Render the table footer, composing together the default IssueCount and Pagination UI elements.
 */
const FooterRenderer = ({ Footer, IssueCount, issues, onPageChange }: Props) => {
	const issueData = useFragment<IssuesKey>(
		graphql`
			fragment footerRenderer_nativeIssueTable on JiraIssueConnection
			@argumentDefinitions(pageSize: { type: "Int" }) {
				...main_nativeIssueTable_IssueCountRenderer
				...issueCreateFooter_nativeIssueTable
				...paginationControls_nativeIssueTable @arguments(pageSize: $pageSize)
				totalIssueSearchResultCount
			}
		`,
		issues,
	);

	const isIssueCreateEnabled = useIsIssueCreateEnabled();
	const isInfiniteScrollEnabled = useIsInfiniteScrollEnabled();

	// Use new Visual Refresh footer order if visual refresh or any m1 features are enabled to
	// simplfiy the number of cases we need to handle with the new features.
	// cleanup all isNewFooterOrderEnabled props with visual-refresh FG
	const isNewFooterOrderEnabled =
		isVisualRefreshEnabled() || isIssueCreateEnabled || isInfiniteScrollEnabled;

	let start;
	let center;
	let end;

	// Show pagination controls only when discrete pagination is enabled
	if (onPageChange && !isInfiniteScrollEnabled) {
		const paginationControls = (
			<PaginationControls
				issues={issueData}
				onPageChange={(after: string) => onPageChange(after, false)}
				isNewFooterOrderEnabled={isNewFooterOrderEnabled}
			/>
		);

		if (isNewFooterOrderEnabled) {
			center = paginationControls;
		} else {
			end = paginationControls;
		}
	}

	// Show issue count when either pagination or infinite scroll are enabled
	if (onPageChange || isInfiniteScrollEnabled) {
		const issueCount = (
			<IssueCountRenderer
				issues={issueData}
				IssueCount={IssueCount}
				isNewFooterOrderEnabled={isNewFooterOrderEnabled}
				isInfiniteScrollEnabled={isInfiniteScrollEnabled}
			/>
		);

		if (isNewFooterOrderEnabled) {
			end = issueCount;
		} else {
			center = issueCount;
		}
	}

	const hasIssues = (issueData.totalIssueSearchResultCount ?? 0) > 0;
	const hasIssueConnectionControls = hasIssues && (!!onPageChange || isInfiniteScrollEnabled);
	const hasControlsToDisplay = hasIssueConnectionControls || isIssueCreateEnabled;

	// Hide the footer if we have no issues UNLESS the consumer has provided a custom footer component, in which case
	// the consumer becomes responsible for when the footer should be shown/hidden.
	if (!hasControlsToDisplay && !Footer) {
		return null;
	}

	const FooterToRender = Footer ?? DefaultFooter;

	if (isIssueCreateEnabled) {
		return (
			<IssueCreateFooter
				Footer={FooterToRender}
				start={start}
				center={center}
				end={end}
				issues={issueData}
			/>
		);
	}
	return <FooterToRender start={start} center={center} end={end} />;
};

export default FooterRenderer;
