/**
 * @generated SignedSource<<eb04dccc9d3a61377b7ef3151dc78754>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueTable_nativeIssueTable_issueResults$data = {
  readonly edges: ReadonlyArray<{
    readonly __typename: "JiraIssueEdge";
    readonly node: {
      readonly issueId: string;
      readonly key: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"tableBody_nativeIssueTable_issueResults">;
  readonly " $fragmentType": "issueTable_nativeIssueTable_issueResults";
};
export type issueTable_nativeIssueTable_issueResults$key = {
  readonly " $data"?: issueTable_nativeIssueTable_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueTable_nativeIssueTable_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "issueTable_nativeIssueTable_issueResults",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            },
            {
              "kind": "ScalarField",
              "name": "key"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isReparentingEnabled",
          "variableName": "isReparentingEnabled"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "tableBody_nativeIssueTable_issueResults"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "a5536e4395ec2016021f2a2e61b503e6";

export default node;
