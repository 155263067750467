import React, { useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { graphql, usePreloadedQuery, useQueryLoader, type PreloadedQuery } from 'react-relay';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import Query, {
	type JiraIssueSearchInput,
	type childRowsLoaderQuery,
} from '@atlassian/jira-relay/src/__generated__/childRowsLoaderQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	getChildQueryVariablesForJql,
	getChildQueryVariablesForParentIssue,
	type Column,
} from '../../common/utils/get-child-query-variables/index.tsx';
import LoadingIssueRow, { type RenderTableCell } from '../loading-row/index.tsx';
import {
	ChildRowsPagination,
	isGroupLoader,
	type RowLoaderProps,
	type GroupLoaderProps,
	type BaseProps as ChildRowsPaginationBaseProps,
} from '../child-rows-pagination/index.tsx';

interface BaseProps<TColumn extends Column, TSortOrder> extends ChildRowsPaginationBaseProps {
	columns: TColumn[];
	isFixedTableLayoutEnabled: boolean;
	isReparentingEnabled: boolean;
	actionColumns: string[];
	amountOfColumns: number;
	sortDirection: TSortOrder;
	sortField?: string;
	jql?: string;
	groupJql?: string;
	issueKey?: string;
	isHideDoneItemsEnabled: boolean;
	renderTableCell: RenderTableCell;
}

type Props<TColumn extends Column, TSortOrder> = BaseProps<TColumn, TSortOrder> &
	(RowLoaderProps | GroupLoaderProps);

export const ChildRowsLoader = <TColumn extends Column, TSortOrder>(
	props: Props<TColumn, TSortOrder>,
) => {
	const [issueSearch, loadQuery, dispose] = useQueryLoader<childRowsLoaderQuery>(Query);

	const cloudId = useCloudId();

	const {
		sortField,
		sortDirection,
		jql,
		columns,
		isReparentingEnabled,
		isGroupingSupported,
		pageSize,
		amountOfColumns,
		actionColumns,
		isHideDoneItemsEnabled,
	} = props;

	const variables = useMemo(
		() =>
			isGroupLoader(props)
				? getChildQueryVariablesForJql<TColumn>({
						cloudId,
						columns,
						jql: props.groupJql,
						isReparentingEnabled: false,
						actionColumns,
						amountOfColumns,
						pageSize,
					})
				: getChildQueryVariablesForParentIssue<TColumn, TSortOrder>({
						cloudId,
						columns,
						parentIssueKey: props.issueKey,
						sortDirection,
						sortField,
						jql,
						isReparentingEnabled,
						actionColumns,
						amountOfColumns,
						pageSize,
						isHideDoneItemsEnabled,
					}),
		[
			props,
			cloudId,
			columns,
			actionColumns,
			amountOfColumns,
			pageSize,
			sortDirection,
			sortField,
			jql,
			isHideDoneItemsEnabled,
			isReparentingEnabled,
		],
	);

	const prevIssueSearchInput = useRef<JiraIssueSearchInput | null>(variables.issueSearchInput);

	useEffect(() => {
		// This check prevents performing another network request for child items when the issue query changes
		// (which is unused as we re-fetch the issues when this happens). This is due to a race condition where
		// this component has not yet been unmounted when the issue query changes.
		if (
			props.isGroupingSupported &&
			!isEqual(variables.issueSearchInput, prevIssueSearchInput.current)
		) {
			return;
		}

		loadQuery(variables);

		if (props.isGroupingSupported) {
			prevIssueSearchInput.current = variables.issueSearchInput;
		}
		return () => {
			dispose();
		};
	}, [variables, loadQuery, dispose, props.isGroupingSupported]);

	if (!issueSearch) {
		return null;
	}

	const {
		depth,
		parentItemId,
		itemsConnectionId,
		parentIssueAri,
		parentItemConnectionId,
		parentIssueRelayConnectionId,
		connectionMetadata,
		isFormVisible,
		formProps,
		projectKey,
		childItemsExperience,
		childItemsLoadMoreExperience,
		analyticsSource,
		width,
		isIssueRankDescending,
		renderRows,
		renderCreateIssueController,
	} = props;

	return (
		<Placeholder
			name="native-issue-table-child-rows-query"
			fallback={
				<LoadingIssueRow
					itemId={props.parentItemId}
					groupId={props.groupId}
					columnsCount={columns.length}
					isFixedTableLayoutEnabled={props.isFixedTableLayoutEnabled}
					renderTableCell={props.renderTableCell}
				/>
			}
		>
			<ChildRowsPreloadedQuery
				issueSearch={issueSearch}
				depth={depth}
				parentItemId={parentItemId}
				itemsConnectionId={itemsConnectionId}
				parentIssueAri={parentIssueAri}
				parentItemConnectionId={parentItemConnectionId}
				parentIssueRelayConnectionId={parentIssueRelayConnectionId}
				connectionMetadata={connectionMetadata}
				isFormVisible={isFormVisible}
				formProps={formProps}
				projectKey={projectKey}
				isGroupingSupported={isGroupingSupported}
				childItemsExperience={childItemsExperience}
				childItemsLoadMoreExperience={childItemsLoadMoreExperience}
				analyticsSource={analyticsSource}
				pageSize={pageSize}
				width={width}
				isIssueRankDescending={isIssueRankDescending}
				renderRows={renderRows}
				renderCreateIssueController={renderCreateIssueController}
			/>
		</Placeholder>
	);
};

export type ChildRowsPreloadedQueryProps = ChildRowsPaginationBaseProps & {
	issueSearch: PreloadedQuery<childRowsLoaderQuery>;
};

export const ChildRowsPreloadedQuery = ({ issueSearch, ...rest }: ChildRowsPreloadedQueryProps) => {
	const queryData = usePreloadedQuery<childRowsLoaderQuery>(
		graphql`
			query childRowsLoaderQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$first: Int
				$last: Int
				$before: String
				$after: String
				$namespace: String
				$viewId: String
				$options: JiraIssueSearchOptions
				$filterId: String
				$amountOfColumns: Int!
				$fieldSetsInput: JiraIssueSearchFieldSetsInput!
				$fieldSetIds: [String!]!
				$shouldQueryFieldSetsById: Boolean!
				$fieldSetsContext: JiraIssueSearchViewFieldSetsContext
				$projectKeys: [String!]
				$projectKey: String
				$viewConfigInput: JiraIssueSearchViewConfigInput
				$shouldQueryHasChildren: Boolean!
				$isReparentingEnabled: Boolean!
			) {
				...childRowsPagination_issueTableHierarchy_query
					@arguments(
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
						isReparentingEnabled: $isReparentingEnabled
					)
				jira {
					issueSearch(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
						fieldSetsInput: $fieldSetsInput
						viewConfigInput: $viewConfigInput
					) @optIn(to: "JiraSpreadsheetComponent-M1") {
						issueSearchError {
							__typename
							... on JiraServerError {
								message
							}
							... on JiraInvalidSyntaxError {
								message
							}
							... on JiraInvalidJqlError {
								messages
							}
						}
					}
				}
			}
		`,
		issueSearch,
	);

	if (rest.isGroupingSupported && queryData?.jira?.issueSearch?.issueSearchError?.__typename) {
		switch (queryData.jira.issueSearch.issueSearchError.__typename) {
			case 'JiraInvalidJqlError':
				throw new Error(queryData.jira.issueSearch.issueSearchError.messages?.[0] ?? 'Invalid JQL');
			case 'JiraInvalidSyntaxError':
			case 'JiraServerError':
				throw new Error(queryData.jira.issueSearch.issueSearchError.message ?? 'Unknown Error');
			default:
		}
	}

	return <ChildRowsPagination {...rest} query={queryData} />;
};
