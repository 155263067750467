import React, { memo } from 'react';
import type { DocNode } from '@atlaskit/adf-schema';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useLazyMediaViewContext } from '../../../../../services/lazy-media-view-context-loader/index.tsx';
import { AsyncRichTextRenderer } from '../rich-text-renderer/index.tsx';

export type Props = {
	richText: DocNode;
	issueKey: string;
	canBeExported?: boolean | null;
};

export const RichTextCellFull = memo<Props>(({ richText, issueKey, canBeExported }: Props) => {
	const viewContext = useLazyMediaViewContext(issueKey);

	const disableMediaDownload =
		canBeExported === false && fg('block_attachment_download_on_data_export_dsp_rule');

	return (
		<Box xcss={richTextCellWrapperStyles}>
			<AsyncRichTextRenderer
				mediaContext={{ viewContext, uploadContext: null, userAuth: null }} // Specify the media context as needed
				adfDocument={richText}
				disableMediaDownload={disableMediaDownload}
			/>
		</Box>
	);
});

const richTextCellWrapperStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
});
