import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createStore, type Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { ContainerProps, State, DropTarget } from './types.tsx';
// Exported for testing
export const initialState: State = {
	currentDropTarget: null,
	instanceId: 'pdnd-issue-table-rows',
	items: new Map(),
	onRowReorder: undefined,
};

const actions = {
	setDropTarget:
		(dropTarget: DropTarget | null): Action<State> =>
		({ setState, getState }) => {
			const currentDropTarget = getState().currentDropTarget;
			if (!isEqual(currentDropTarget, dropTarget)) {
				setState({
					currentDropTarget: dropTarget,
				});
			}
		},
	reorderRows:
		(sourceIndex: number, destinationIndex: number): Action<State> =>
		({ getState }) => {
			const { items, onRowReorder } = getState();

			onRowReorder?.({
				source: {
					issueIds: [items.get(sourceIndex) ?? ''],
					rowIndex: sourceIndex,
				},
				destination: {
					relativeToIssueId: items.get(destinationIndex) ?? '',
					rowIndex: destinationIndex,
				},
				edge: sourceIndex > destinationIndex ? 'top' : 'bottom',
			});
		},
	registerItem:
		(edgeIndex: number, issueId: string): Action<State> =>
		({ getState, setState }) => {
			const { items } = getState();
			setState({ items: items.set(edgeIndex, issueId) });
		},
	unregisterItem:
		(edgeIndex: number): Action<State> =>
		({ getState, setState }) => {
			const { items } = getState();

			if (items.delete(edgeIndex)) {
				setState({ items });
			}
		},
};

export const DraggableRowsContainer = createContainer<ContainerProps>({
	getIsConcurrentSafe: () => fg('jira-concurrent-issue-table-rss-fix'),
});

export const Store = createStore({
	initialState,
	actions,
	containedBy: DraggableRowsContainer,
	handlers: {
		onInit:
			() =>
			({ setState }, { instanceId, onRowReorder }) => {
				setState({ instanceId: instanceId ?? initialState.instanceId, onRowReorder });
			},
		onContainerUpdate:
			(props, prevProps) =>
			({ setState }, { instanceId, onRowReorder }) => {
				if (fg('jira-issue-table-fix-reordering')) {
					if (
						props.instanceId !== prevProps.instanceId ||
						props.onRowReorder !== prevProps.onRowReorder
					) {
						setState({ instanceId, onRowReorder });
					}
				} else if (props.instanceId !== prevProps.instanceId) {
					setState({ instanceId, onRowReorder });
				}
			},
		onDestroy:
			() =>
			({ getState }) => {
				const { items } = getState();
				items.clear();
			},
	},
});
