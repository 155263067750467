import parameters from '@atlassian/jira-relay/src/__generated__/issueTypeDropdownIssueType_projectDataQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	projectKey: string | undefined;
};

export const reparentModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-reparent-modal-dialog" */ './index'),
	),
	getPreloadProps: ({ cloudId, projectKey }: EntryPointParams) =>
		({
			queries: {
				projectData: {
					parameters,
					options: {
						fetchPolicy: 'store-or-network',
					},
					variables: {
						cloudId,
						projectKey: projectKey ?? '',
					},
				},
			},
		}) as const,
});
