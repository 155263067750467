import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMore: {
		id: 'native-issue-table.common.load-more-items-row.show-more',
		defaultMessage: 'Show more',
		description: 'Button text to load more issues.',
	},
	loadingMoreItems: {
		id: 'native-issue-table.common.load-more-items-row.loading-more-items',
		defaultMessage: 'Loading more issues',
		description: 'Occurs when user clicks button to load more issues.',
	},
	loadingMoreItemsIssueTermRefresh: {
		id: 'native-issue-table.common.load-more-items-row.loading-more-items-issue-term-refresh',
		defaultMessage: 'Loading more',
		description: 'Occurs when user clicks button to load more issues.',
	},
});
