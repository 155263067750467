/**
 * @generated SignedSource<<43f95445791158b53e4f62c754a7e121>>
 * @relayHash f3f5453a258ab6fd76ff8a41380a7ce3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2da8aad6f8f4b5efabc8cdc7b5e6df47535b423f8314048978d461b2553a816e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_RichTextPopUpContentQuery } from './ui_RichTextPopUpContentQuery.graphql';

const node: PreloadableConcreteRequest<ui_RichTextPopUpContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2da8aad6f8f4b5efabc8cdc7b5e6df47535b423f8314048978d461b2553a816e",
    "metadata": {},
    "name": "ui_RichTextPopUpContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
