/**
 * @generated SignedSource<<b1958999ab18d9f57257e6116583fd83>>
 * @relayHash 5c17b8fbf5a1e69531b46c6e762bc127
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 46a6916f10ae38e187e874e829f804706892021354f2ba5bad26efc50947a5ee

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_IssueLinksPopUpContentQuery } from './ui_IssueLinksPopUpContentQuery.graphql';

const node: PreloadableConcreteRequest<ui_IssueLinksPopUpContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "46a6916f10ae38e187e874e829f804706892021354f2ba5bad26efc50947a5ee",
    "metadata": {},
    "name": "ui_IssueLinksPopUpContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
