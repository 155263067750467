/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useCallback, useState } from 'react';
import { cssMap, jsx } from '@atlaskit/css';
import AddIcon from '@atlaskit/icon/core/migration/add';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { RowDropIndicator } from '../../../../common/ui/row-drop-indicator/index.tsx';
import { RowPopupButton } from '../../../../common/ui/row-popup-button/index.tsx';
import { useIsSiblingIssueCreateActive } from '../../../../controllers/sibling-create-active-index/index.tsx';
import { ISSUE } from '../../../../controllers/sibling-create-active-index/types.tsx';
import { useSiblingIssueCreateRow } from '../../../../controllers/sibling-issue-create-handler/index.tsx';
import messages from './messages.tsx';

const styles = cssMap({
	button: {
		height: '24px',
		width: '24px',
		position: 'relative',
		borderStyle: 'solid',
		borderWidth: token('border.width'),
		borderColor: token('color.border'),
		boxShadow: token('elevation.shadow.overlay'),
	},
});

type Props = {
	/** Index of the issue within a connection. */
	edgeIndex: number;
	/** Relay ID of the issue connection record. */
	issuesConnectionId: string;
};

// Negatively offset the popup button by 1px to account for the cell border
const offset: [number, number] = [-1, -1];

/**
 * Display a 'Create' popup button positioned relative to the nearest containing block that will appear when hovering
 * between issue rows.
 */
export const SiblingIssueCreateButton = ({ issuesConnectionId, edgeIndex }: Props) => {
	const { formatMessage } = useIntl();

	const [isInlineIssueCreateActive, { lockIndex, clearIndexAfterDelay }] =
		useIsSiblingIssueCreateActive([issuesConnectionId, edgeIndex]);
	const [isMouseOverSiblingCreate, setIsMouseOverSiblingCreate] = useState(false);

	const { triggerProps } = useSiblingIssueCreateRow();

	const handleMouseEnter = useCallback(() => {
		setIsMouseOverSiblingCreate(true);
		lockIndex(ISSUE);
	}, [lockIndex]);

	const handleMouseLeave = useCallback(() => {
		setIsMouseOverSiblingCreate(false);
		clearIndexAfterDelay(ISSUE);
	}, [clearIndexAfterDelay]);

	return (
		<>
			<RowPopupButton
				alignToYAxis
				icon={<AddIcon label="" LEGACY_size="small" />}
				isVisible={isInlineIssueCreateActive}
				label={formatMessage(messages.createLabel)}
				offset={offset}
				onClick={triggerProps?.onClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				ref={triggerProps?.ref}
				tooltipContent={formatMessage(messages.createLabel)}
				xcss={styles.button}
			/>
			{isMouseOverSiblingCreate && <RowDropIndicator edge="top" />}
		</>
	);
};
