import React, { useCallback, useMemo, useState, type ChangeEvent } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { OriginalEstimateReadView } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/index.tsx';
import OriginalEstimateEditViewWithFormattedValueEntryPoint from '@atlassian/jira-issue-field-original-estimate-editview-full/src/ui/original-estimate/original-estimate-with-formatted-value/entrypoint.tsx';
import type { OriginalEstimateEditViewWithFormattedValueProps } from '@atlassian/jira-issue-field-original-estimate-editview-full/src/ui/original-estimate/original-estimate-with-formatted-value/types.tsx';
import { useFormatInputValue } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/utils/formatting.tsx';
import { useParseTimeString } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/utils/parsing.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type {
	lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_Mutation as OriginalEstimateMutation,
	lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_Mutation$data as OriginalEstimateMutationData,
} from '@atlassian/jira-relay/src/__generated__/lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_Mutation.graphql';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef$key as OriginalEstimateInlineEditViewIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef.graphql';
import type { lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView_fragmentRef$key as OriginalEstimateFragment } from '@atlassian/jira-relay/src/__generated__/lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView_fragmentRef.graphql';
import type {
	OriginalEstimateInlineEditViewProps,
	OriginalEstimateInlineEditViewIsEditableProps,
} from '../types.tsx';
import { useOriginalEstimateValidator } from '../utils.tsx';

export const OriginalEstimateInlineEditViewIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	spacing = 'compact',
	readViewFitContainerHeight,
	isEditable,
	isEditing: startWithEditViewOpen,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
}: OriginalEstimateInlineEditViewIsEditableProps) => {
	// #region Relay
	const data = useFragment<OriginalEstimateInlineEditViewIsEditableFragment>(
		graphql`
			fragment lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef on JiraTimeTrackingField {
				...originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView
				id
				fieldId
				name
				type
				originalEstimate {
					timeInSeconds
				}
				timeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			}
		`,
		fragmentRef,
	);
	const {
		id: uniqueFieldId = '',
		name = '',
		fieldId = '',
		type: fieldType = '',
		originalEstimate,
		timeTrackingSettings,
	} = data || {};
	const { workingHoursPerDay, workingDaysPerWeek, defaultFormat, defaultUnit } =
		timeTrackingSettings ?? {};

	const [commit] = useMutation<OriginalEstimateMutation>(graphql`
		mutation lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_Mutation(
			$input: JiraUpdateTimeTrackingFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateTimeTrackingField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						originalEstimate {
							timeInSeconds
						}
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const settingsInput = {
		workingHoursPerDay,
		workingDaysPerWeek,
		defaultFormat,
		defaultUnit,
	};

	const formatInputValue = useFormatInputValue(settingsInput);
	const initialValue = useMemo(
		() => (originalEstimate ? formatInputValue(originalEstimate.timeInSeconds ?? 0) : ''),
		[formatInputValue, originalEstimate],
	);
	const [updatedValue, setUpdatedValue] = useState<string>(initialValue);

	const validator = useOriginalEstimateValidator(settingsInput);
	const parseTimeString = useParseTimeString(settingsInput);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(
		() => (fg('relay-migration-issue-fields-original-estimate') ? overrideLabel(name) : name),
		[name, overrideLabel],
	);

	const isFieldEditable = fg('relay-migration-issue-fields-original-estimate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => overrideIsEditable(isEditable), [isEditable, overrideIsEditable])
		: isEditable;

	// #region Action
	const handleSubmit = useCallback(
		(value: string, { onSuccess, onFail }: OnSubmitCallbacks) => {
			const timeInSeconds = parseTimeString(value) ?? 0;
			onSubmit?.(timeInSeconds);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						originalEstimate: {
							timeInSeconds,
						},
					},
				},
				onCompleted: (response: OriginalEstimateMutationData) => {
					if (response.jira?.updateTimeTrackingField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateTimeTrackingField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								originalEstimate: {
									timeInSeconds,
								},
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, parseTimeString, uniqueFieldId],
	);

	const handleSubmitSucceeded = useCallback(
		(value: string) => onSubmitSucceeded?.(parseTimeString(value) ?? 0),
		[onSubmitSucceeded, parseTimeString],
	);

	const actions = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		updatedValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded: handleSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		validator,
	});

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		invalidMessage,
		isEditing,
	} = actions;

	// #region View
	const renderReadView = useCallback(
		() => <OriginalEstimateReadView fragmentRef={data} enableHover={isFieldEditable} />,
		[data, isFieldEditable],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			handleChange(event.target.value);
		},
		[handleChange],
	);

	const getEditViewProps = (
		fieldProps: ValidationFieldProps,
	): OriginalEstimateEditViewWithFormattedValueProps => ({
		...fieldProps,
		autoFocus: true,
		value: updatedValue,
		spacing,
		onChange,
		isInvalid: Boolean(invalidMessage),
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={OriginalEstimateEditViewWithFormattedValueEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage ?? undefined}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
		/>
	);
};

export const OriginalEstimateInlineEditView = ({
	fragmentRef,
	...props
}: OriginalEstimateInlineEditViewProps) => {
	const data = useFragment<OriginalEstimateFragment>(
		graphql`
			fragment lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView_fragmentRef on JiraTimeTrackingField {
				...lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	return (
		<OriginalEstimateInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data?.fieldConfig?.isEditable ?? false}
		/>
	);
};
