import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unassigned: {
		id: 'native-issue-table.common.issue-cells.user.unassigned',
		defaultMessage: 'Unassigned',
		description: 'String to display when the user is not defined',
	},
	anonymous: {
		id: 'native-issue-table.common.issue-cells.user.anonymous',
		defaultMessage: 'Anonymous',
		description: 'String to display when a user is not defined',
	},
});
