import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnHeaderIssueType: {
		id: 'native-issue-table.column-header-issue-type',
		defaultMessage: 'Type',
		description: 'Column header for issue type',
	},
	columnHeaderIssueKey: {
		id: 'native-issue-table.column-header-issue-key',
		defaultMessage: 'Key',
		description: 'Column header for issue key',
	},
	columnHeaderIssueSummary: {
		id: 'native-issue-table.column-header-issue-summary',
		defaultMessage: 'Summary',
		description: 'Column header for issue summary',
	},
	columnHeaderIssueAssignee: {
		id: 'native-issue-table.column-header-issue-assignee',
		defaultMessage: 'Assignee',
		description: 'Column header for issue assignee',
	},
	columnHeaderIssueReporter: {
		id: 'native-issue-table.column-header-issue-reporter',
		defaultMessage: 'Reporter',
		description: 'Column header for issue reporter',
	},
	columnHeaderIssuePriority: {
		id: 'native-issue-table.column-header-issue-priority',
		defaultMessage: 'P',
		description: 'Short column header for issue priority',
	},
	columnHeaderIssueStatus: {
		id: 'native-issue-table.column-header-issue-status',
		defaultMessage: 'Status',
		description: 'Short column header for issue status',
	},
	columnHeaderIssueResolution: {
		id: 'native-issue-table.column-header-issue-resolution',
		defaultMessage: 'Resolution',
		description: 'Short column header for resolution',
	},
	columnHeaderIssueCreatedDate: {
		id: 'native-issue-table.column-header-issue-created-date',
		defaultMessage: 'Created',
		description: 'Short column header for issue created date',
	},
	columnHeaderIssueUpdatedDate: {
		id: 'native-issue-table.column-header-issue-updated-date',
		defaultMessage: 'Updated',
		description: 'Short column header for updated date',
	},
	columnHeaderIssueDueDate: {
		id: 'native-issue-table.column-header-issue-due-date',
		defaultMessage: 'Due',
		description: 'Short column header for issue due date',
	},
	columnHeaderIssueActions: {
		id: 'native-issue-table.column-header-issue-actions',
		defaultMessage: 'More actions',
		description: 'Label for header column of meatball menu',
	},
	aToZOrder: {
		id: 'native-issue-table.a-to-z-order',
		defaultMessage: 'A ➞ Z',
		description:
			'Ascending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	zToAOrder: {
		id: 'native-issue-table.z-to-a-order',
		defaultMessage: 'Z ➞ A',
		description:
			'Descending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	oldestToNewestOrder: {
		id: 'native-issue-table.oldest-to-newest-order',
		defaultMessage: 'oldest ➞ newest',
		description:
			'Ascending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	newestToOldestOrder: {
		id: 'native-issue-table.newest-to-oldest-order',
		defaultMessage: 'newest ➞ oldest',
		description:
			'Descending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	reorderColumnsErrorTitle: {
		id: 'native-issue-table.reorder-columns-error-title',
		defaultMessage: "We couldn't reorder your columns",
		description:
			"Displayed as error flag's title when an unexpected error happens while user is trying to reorder columns in a table.",
	},
	reorderColumnsErrorDescription: {
		id: 'native-issue-table.reorder-columns-error-description',
		defaultMessage: 'Refresh and try again.',
		description:
			"Displayed as error flag's description when an unexpected error happens while user is trying to reorder columns in a table.",
	},
	deprecatedParentLinkMessage: {
		id: 'native-issue-table.deprecated-parent-link-message',
		defaultMessage: '<strong>Parent Link</strong> is replaced by <strong>Parent</strong>.',
		description: 'Warning message for Parent Link deprecation',
	},
});
