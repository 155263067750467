import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { TriggerPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import type { firstColumnCell_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/firstColumnCell_nativeIssueTable.graphql';
import { ExpandButtonWithCreateChildButton as ExpandButtonWithCreateChildButtonNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/expand-button/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsChildIssueCreateEnabled } from '../../../../controllers/features/selectors.tsx';
import { ExpandButtonWithCreateChildButton as ExpandButtonWithCreateChildButtonOld } from '../../../expand-button/index.tsx';
import { useRowContext } from '../../../rows/row-context/index.tsx';

export type Props = {
	isRowHoveredOrSelected: boolean;
	depth: number;
	itemsConnectionId: string;
	issueRef: firstColumnCell_nativeIssueTable$key;
	triggerProps?: TriggerPropsType;
	isFormVisible?: boolean;
};

const FirstColumnCell = ({
	isRowHoveredOrSelected,
	itemsConnectionId,
	depth,
	issueRef,
	triggerProps,
	isFormVisible,
}: Props) => {
	const issueData = useFragment<firstColumnCell_nativeIssueTable$key>(
		graphql`
			fragment firstColumnCell_nativeIssueTable on JiraIssueEdge
			@argumentDefinitions(
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
			) {
				hasChildren(filterByProjectKeys: $projectKeys)
					@include(if: $shouldQueryHasChildren)
					@optIn(to: "JiraSpreadsheetComponent-M2")
				canHaveChildIssues(projectKey: $projectKey)
					@include(if: $shouldQueryHasChildren)
					@optIn(to: "JiraSpreadsheetComponent-M2")
				node @include(if: $isJscM2Enabled) {
					issueId
				}
			}
		`,
		issueRef,
	);

	const { hasChildren, canHaveChildIssues } = issueData;
	const { issueId } = issueData.node ?? {};

	let groupId;
	if (fg('jira_list_hierarchy_extraction')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ groupId } = useRowContext());
	}

	const isChildIssueCreateEnabled = useIsChildIssueCreateEnabled();
	const showCreateChildButton =
		isChildIssueCreateEnabled && isRowHoveredOrSelected && Boolean(canHaveChildIssues);

	const ExpandButtonWithCreateChildButton = fg('jira_list_hierarchy_extraction')
		? ExpandButtonWithCreateChildButtonNew
		: ExpandButtonWithCreateChildButtonOld;

	return (
		issueId && (
			<ExpandButtonWithCreateChildButton
				itemId={issueId}
				itemsConnectionId={itemsConnectionId}
				depth={depth}
				hasChildren={Boolean(hasChildren)}
				showCreateChildButton={showCreateChildButton}
				isRowHoveredOrSelected={isRowHoveredOrSelected}
				isCreateChildFormVisible={isFormVisible}
				createChildTriggerProps={triggerProps}
				groupId={groupId}
			/>
		)
	);
};

export default FirstColumnCell;
