import { useCallback, useEffect, useRef, type RefCallback } from 'react';
import noop from 'lodash/noop';
import {
	attachClosestEdge,
	extractClosestEdge,
	type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	attachInstruction,
	extractInstruction,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { JiraIssueAri, JiraIssuefieldvalueAri } from '@atlassian/ari/jira';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	MAKE_CHILD,
	REORDER_ABOVE,
	REORDER_BELOW,
	ROW_OPERATION_REORDER,
} from '../../common/constants.tsx';
import {
	useIsEntireRowDraggable,
	useIsRowReorderingEnabled,
	useIsReparentingEnabled,
} from '../features/selectors.tsx';
import { useUpdateParent } from '../../services/reparent-mutation/index.tsx';
import { Store } from './context.tsx';
import type { BlockedInstructions, DraggableRowData, DraggableRowProps } from './types.tsx';
import { getBlockedInstructions } from './utils.tsx';

const INDENT_PER_LEVEL = 42;
const EXTRA_SPACE_PER_LEVEL = 4;
const ATTACH_INSTRUCTION_MODE = 'standard';

const SUPPORTED_TYPES = new Set([MAKE_CHILD, REORDER_ABOVE, REORDER_BELOW]);

export const useDraggableRows = createHook(Store);

const isDraggableRowData = (data: unknown): data is DraggableRowData =>
	data != null &&
	typeof data === 'object' &&
	'operation' in data &&
	data.operation === ROW_OPERATION_REORDER;

type Props = DraggableRowProps & { depth?: number };

export const useDraggableRow = (props: Props) => {
	const {
		edgeIndex,
		issueId,
		hierarchyLevel,
		parentIssueAri,
		parentIssueEdgeRelayId,
		parentIssueConnectionId,
		issueEdgeRelayId,
	} = props;

	const isRowReorderingEnabled = useIsRowReorderingEnabled();
	const isEntireRowDraggable = useIsEntireRowDraggable();
	const isReParentingEnabled = useIsReparentingEnabled();

	const [{ instanceId, onRowReorder }, { registerItem, setDropTarget, unregisterItem }] =
		useDraggableRows();

	let reparent = null;
	if (fg('jsc_list_reparenting')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ reparent } = useUpdateParent());
	}

	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const cleanupFnRef = useRef<() => void>();

	const attachDraggableElement = useCallback(
		(rowElement: HTMLTableRowElement) =>
			isEntireRowDraggable
				? draggable({
						element: rowElement,
						getInitialData(): DraggableRowData {
							return {
								operation: ROW_OPERATION_REORDER,
								instanceId,
								issueId,
								hierarchyLevel,
								parentIssueAri,
								parentIssueEdgeRelayId,
								parentIssueConnectionId,
								issueEdgeRelayId,
								rowIndex: edgeIndex,
								itemsConnectionId: props.itemsConnectionId,
							};
						},
						onDragStart() {
							fireUIAnalytics(
								createAnalyticsEvent({
									action: 'dragged',
									actionSubject: 'useDraggableRow',
									actionSubjectId: 'jscDraggableIssue',
								}),
							);
						},
						onDrop() {
							fireUIAnalytics(
								createAnalyticsEvent({
									action: 'dropped',
									actionSubject: 'useDraggableRow',
									actionSubjectId: 'jscDraggableIssue',
								}),
							);
						},
					})
				: noop,
		[
			isEntireRowDraggable,
			instanceId,
			issueId,
			hierarchyLevel,
			parentIssueAri,
			parentIssueEdgeRelayId,
			parentIssueConnectionId,
			issueEdgeRelayId,
			edgeIndex,
			props.itemsConnectionId,
			createAnalyticsEvent,
		],
	);

	const attachDropTargetElement = useCallback(
		(rowElement: HTMLTableRowElement) =>
			dropTargetForElements({
				element: rowElement,
				canDrop({ source }) {
					return (
						source.data.operation === ROW_OPERATION_REORDER &&
						source.data.instanceId === instanceId &&
						source.data.issueId !== issueId
					);
				},
				getData({ input, element, source }) {
					const data: DraggableRowData = {
						operation: ROW_OPERATION_REORDER,
						instanceId,
						issueId,
						parentIssueAri,
						parentIssueConnectionId,
						issueEdgeRelayId,
						parentIssueEdgeRelayId,
						rowIndex: edgeIndex,
						itemsConnectionId: props.itemsConnectionId,
						hasChildren: Boolean(props.hasChildren),
					};

					if (fg('jsc_list_reparenting')) {
						const blockedInstructions: BlockedInstructions = isDraggableRowData(source.data)
							? getBlockedInstructions({
									dropTarget: { ...data, ...props },
									draggedItem: source.data,
									isRowReorderingEnabled,
								})
							: [MAKE_CHILD, REORDER_BELOW, REORDER_BELOW];

						return attachInstruction(data, {
							input,
							element,
							mode: ATTACH_INSTRUCTION_MODE,
							indentPerLevel:
								// adjust the drop indicator position based on the depth of the item
								!props.depth || props.depth < 2
									? INDENT_PER_LEVEL
									: INDENT_PER_LEVEL - props.depth * EXTRA_SPACE_PER_LEVEL,
							currentLevel: props.depth || 0,
							block: blockedInstructions,
						});
					}

					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDrag({ self, source }) {
					// Don't set drop edge if source target is the same as this row instance
					if (issueId === source.data.issueId) return;

					if (fg('jsc_list_reparenting')) {
						const extractedInstruction = extractInstruction(self.data);
						extractedInstruction &&
							setDropTarget({ issueId, dropInstruction: extractedInstruction });

						return;
					}

					const closestEdge = extractClosestEdge(self.data);
					closestEdge && setDropTarget({ issueId, dropEdge: closestEdge });
				},
				onDragLeave() {
					setDropTarget(null);
				},
				onDrop({ source, location }) {
					const [destination] = location.current.dropTargets;

					setDropTarget(null);

					if (!isDraggableRowData(source.data) || !isDraggableRowData(destination?.data)) {
						return;
					}

					let closestEdgeOfTarget: Edge | null;
					let extractedInstruction;
					if (fg('jsc_list_reparenting')) {
						extractedInstruction = extractInstruction(destination.data);
						closestEdgeOfTarget = extractedInstruction?.type === REORDER_ABOVE ? 'top' : 'bottom';
					} else {
						closestEdgeOfTarget = extractClosestEdge(destination.data);
					}

					if (isRowReorderingEnabled) {
						if (!closestEdgeOfTarget) return;

						const startIndex = source.data.rowIndex;
						const finishIndex = getReorderDestinationIndex({
							axis: 'vertical',
							closestEdgeOfTarget,
							startIndex,
							indexOfTarget: destination.data.rowIndex,
						});

						if (startIndex === finishIndex) return;

						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'updatedRanking',
								actionSubject: 'dragActionsButton',
								actionSubjectId: 'jscDraggableIssue',
							}),
						);

						onRowReorder?.({
							source: {
								issueIds: [source.data.issueId],
								rowIndex: startIndex,
							},
							destination: {
								relativeToIssueId: destination.data.issueId,
								rowIndex: finishIndex,
							},
							edge: closestEdgeOfTarget,
						});
					}

					if (isReParentingEnabled) {
						if (extractedInstruction && SUPPORTED_TYPES.has(extractedInstruction.type)) {
							const issueIdToReparent = JiraIssueAri.parse(source.data.issueId).issueId;
							const issueFieldAriToReparent = JiraIssuefieldvalueAri.create({
								siteId: cloudId,
								issueId: issueIdToReparent,
								fieldId: PARENT_TYPE,
							}).toString();

							const oldParentId =
								source.data.parentIssueAri &&
								JiraIssueAri.parse(source.data.parentIssueAri).issueId;

							const newParentAri =
								extractedInstruction.type === MAKE_CHILD
									? destination.data.issueId
									: destination.data.parentIssueAri;
							const newParentId = newParentAri
								? JiraIssueAri.parse(newParentAri).issueId
								: undefined;

							const newParentEdgeId =
								extractedInstruction?.type === MAKE_CHILD
									? destination.data.issueEdgeRelayId
									: destination.data.parentIssueEdgeRelayId;

							let dropIndex = 0;
							if (extractedInstruction?.type === REORDER_ABOVE) {
								dropIndex = destination.data.rowIndex;
							} else if (extractedInstruction?.type === REORDER_BELOW) {
								dropIndex = destination.data.rowIndex + 1;
							}

							fireUIAnalytics(
								createAnalyticsEvent({
									action: 'updatedParent',
									actionSubject: 'dragActionsButton',
									actionSubjectId: 'jscDraggableIssue',
								}),
								{
									reparentingMethod: extractedInstruction.type,
									isAddingParent: !oldParentId,
									isUnparenting: !newParentAri,
									droppedIssueHierarchyLevel: source.data.hierarchyLevel,
								},
							);

							reparent?.({
								issueId: source.data.issueId,
								issueAri: issueFieldAriToReparent,
								oldParentId,
								newParentId,
								newParentAri: newParentAri || null,
								newParentEdgeId,
								index: dropIndex,
								projectKey: props.projectKey,
							});
						}
					}
				},
			}),
		[
			instanceId,
			issueId,
			parentIssueAri,
			parentIssueConnectionId,
			issueEdgeRelayId,
			parentIssueEdgeRelayId,
			edgeIndex,
			props,
			isRowReorderingEnabled,
			setDropTarget,
			isReParentingEnabled,
			createAnalyticsEvent,
			onRowReorder,
			cloudId,
			reparent,
		],
	);

	const registerDraggableRow = useCallback(() => {
		registerItem(edgeIndex, issueId);
	}, [edgeIndex, issueId, registerItem]);

	const unregisterDraggableRow = useCallback(() => {
		unregisterItem(edgeIndex);
	}, [edgeIndex, unregisterItem]);

	const attachDraggableRow = useCallback<RefCallback<HTMLTableRowElement>>(
		(rowElement) => {
			// If there's an existing ref, clean up before attaching
			cleanupFnRef.current?.();

			if (!rowElement) return;

			cleanupFnRef.current = combine(
				attachDraggableElement(rowElement),
				attachDropTargetElement(rowElement),
			);
		},
		[attachDraggableElement, attachDropTargetElement],
	);

	useEffect(() => () => cleanupFnRef.current?.(), []);

	useEffect(() => {
		registerDraggableRow();

		return () => {
			unregisterDraggableRow();
		};
	}, [registerDraggableRow, unregisterDraggableRow]);

	return isRowReorderingEnabled || isReParentingEnabled ? attachDraggableRow : null;
};
