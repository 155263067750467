import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Text, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { priorityGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/priorityGroupHeader_nativeIssueTable.graphql';
import messages from './messages.tsx';

export type Props = {
	priorityGroupHeader: priorityGroupHeader_nativeIssueTable$key | null;
};

export const PriorityGroupHeader = ({ priorityGroupHeader }: Props) => {
	const data = useFragment<priorityGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment priorityGroupHeader_nativeIssueTable on JiraJqlPriorityFieldValue {
				displayName
				priority {
					iconUrl
				}
			}
		`,
		priorityGroupHeader,
	);

	const { formatMessage } = useIntl();

	return (
		<>
			{data?.priority.iconUrl && (
				<Box as="img" src={data.priority.iconUrl} xcss={iconStyles} alt="" />
			)}
			<Text>{data?.displayName ?? formatMessage(messages.noPriority)}</Text>
		</>
	);
};

const iconStyles = xcss({
	width: token('space.250'),
	height: token('space.250'),
});
