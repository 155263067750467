import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { TableCell, type Props as TableCellProps } from '../../../common/ui/table-cell/index.tsx';
import { useIsFixedTableLayoutEnabled } from '../../../controllers/features/selectors.tsx';
import { useRowContext } from '../../rows/row-context/index.tsx';
import messages from './messages.tsx';

export type PropsBlankIssue = {
	isSelected: boolean;
	cellBorder?: TableCellProps['border'];
};

const BlankIssueRow = ({ isSelected, cellBorder }: PropsBlankIssue) => {
	const { formatMessage } = useIntl();
	const { columns } = useRowContext();

	const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();

	// Extra column added to account for the blank spacer column added to the table
	return (
		<TableCell colSpan={columns.length + (isFixedTableLayoutEnabled ? 1 : 0)} border={cellBorder}>
			<Text isSelected={isSelected}>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.blankRowTextWithArchiveIssueIssueTermRefresh
						: messages.blankRowTextWithArchiveIssue,
				)}
			</Text>
			<FireUiAnalytics action="viewed" actionSubject="blankIssue" />
		</TableCell>
	);
};

export default BlankIssueRow;

const textColorActiveState = token('color.text.selected');
const textColor = token('color.text');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.div<{ isSelected: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isSelected }) => (isSelected ? textColorActiveState : textColor),
	paddingLeft: token('space.050'),
	cursor: 'default',
});
