import React, { type ComponentType } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { main_nativeIssueTable_IssueCountRenderer$key as IssueCountRendererKey } from '@atlassian/jira-relay/src/__generated__/main_nativeIssueTable_IssueCountRenderer.graphql';
import type { IssueCountProps } from '../../../common/types.tsx';
import { IssueCount as DefaultIssueCount } from './issue-count/index.tsx';

export type Props = {
	/**
	 * Relay fragment key for a connection of issues.
	 */
	issues: IssueCountRendererKey;
	/**
	 * Overridable IssueCount component to render.
	 */
	IssueCount?: ComponentType<IssueCountProps>;
	/**
	 * `true` if the new footer order is enabled, which moves the issue count to the end of the footer.
	 */
	isNewFooterOrderEnabled: boolean;
	/**
	 * `true` if infinite scroll is enabled.
	 */
	isInfiniteScrollEnabled: boolean;
};
const IssueCountRenderer = ({
	issues,
	IssueCount = DefaultIssueCount,
	isNewFooterOrderEnabled,
	isInfiniteScrollEnabled,
}: Props) => {
	const data = useFragment<IssueCountRendererKey>(
		graphql`
			fragment main_nativeIssueTable_IssueCountRenderer on JiraIssueConnection {
				edges {
					__typename # Only requested to count number of edges
				}
				totalIssueSearchResultCount
				isCappingIssueSearchResult
				issueNavigatorPageInfo {
					firstIssuePosition
					lastIssuePosition
				}
			}
		`,
		issues,
	);

	const startIssueRange = data.issueNavigatorPageInfo?.firstIssuePosition ?? 0;
	const endIssueRange = data.issueNavigatorPageInfo?.lastIssuePosition ?? 0;
	const loadedIssuesCount = data.edges?.length ?? 0;
	const total = data.totalIssueSearchResultCount ?? 0;
	const countExceedsMaxResults = !!data.isCappingIssueSearchResult;

	return (
		<IssueCount
			startIssueRange={startIssueRange}
			endIssueRange={endIssueRange}
			loadedIssuesCount={loadedIssuesCount}
			total={total}
			countExceedsMaxResults={countExceedsMaxResults}
			isNewFooterOrderEnabled={isNewFooterOrderEnabled}
			isInfiniteScrollEnabled={isInfiniteScrollEnabled}
		/>
	);
};

export default IssueCountRenderer;
