/**
 * @generated SignedSource<<f743ace1a8ccf813b56223b4605f0d49>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueRow_nativeIssueTable_IssueRowWithFragments_issue$data = {
  readonly issueId: string;
  readonly issueTypeField?: {
    readonly issueType: {
      readonly hierarchy: {
        readonly level: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly " $fragmentSpreads": FragmentRefs<"issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef" | "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld" | "issueSummary_nativeIssueTable_IssueSummaryCell_issueRef" | "richText_nativeIssueTable_RichTextCell_issueRef">;
  readonly " $fragmentType": "issueRow_nativeIssueTable_IssueRowWithFragments_issue";
};
export type issueRow_nativeIssueTable_IssueRowWithFragments_issue$key = {
  readonly " $data"?: issueRow_nativeIssueTable_IssueRowWithFragments_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_issue">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "isDensityFull",
  "variableName": "isDensityFull"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "issueRow_nativeIssueTable_IssueRowWithFragments_issue",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "ScalarField",
      "name": "issueId"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueTypeHierarchyLevel",
                  "kind": "LinkedField",
                  "name": "hierarchy",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "level"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "issueSummary_nativeIssueTable_IssueSummaryCell_issueRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "isInlineEditingEnabled",
              "variableName": "isInlineEditingEnabled"
            }
          ],
          "kind": "FragmentSpread",
          "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld"
        }
      ]
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "richText_nativeIssueTable_RichTextCell_issueRef"
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "293a9bb49c1a785a639ef8f34f6b6441";

export default node;
