/**
 * @generated SignedSource<<871b4d4d6f391f7645d8365104353e34>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dragActionsButton_nativeIssueTable$data = {
  readonly issueTypeField?: {
    readonly issueType: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly summary?: string | null | undefined;
  readonly " $fragmentType": "dragActionsButton_nativeIssueTable";
};
export type dragActionsButton_nativeIssueTable$key = {
  readonly " $data"?: dragActionsButton_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"dragActionsButton_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "dragActionsButton_nativeIssueTable",
  "selections": [
    {
      "condition": "isReparentingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "small"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "2c82d93dee43bfbb87413c0ffdd9f22b";

export default node;
