import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	onboardingHeading: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.reparenting-onboarding.onboarding-heading',
		defaultMessage: 'Re-parent work items',
		description: 'Onboarding title for the re-parent feature',
	},
	onboardingDescription: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.reparenting-onboarding.onboarding-description',
		defaultMessage: 'Select and move a work item to add, or change, the parent.',
		description: 'Onboarding message for the re-parent feature',
	},
	dismiss: {
		id: 'native-issue-table.ui.row.before-first-cell.drag-actions-button.reparenting-onboarding.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Cta label to dismiss the onboarding',
	},
});
