/**
 * @generated SignedSource<<17e7b3bb588145775d0b366cc52edffd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type number_nativeIssueTable_NumberCell_fieldRef$data = {
  readonly isStoryPointField: boolean | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberReadviewFull_NumberReadView" | "number_nativeIssueTable_NumberInlineEditCell_fieldRef" | "storyPointEstimate_nativeIssueTable_StoryPointEstimateCell">;
  readonly " $fragmentType": "number_nativeIssueTable_NumberCell_fieldRef";
};
export type number_nativeIssueTable_NumberCell_fieldRef$key = {
  readonly " $data"?: number_nativeIssueTable_NumberCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"number_nativeIssueTable_NumberCell_fieldRef">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "number_nativeIssueTable_NumberCell_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "isStoryPointField"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "number_issueFieldNumberReadviewFull_NumberReadView"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "number_nativeIssueTable_NumberInlineEditCell_fieldRef"
        }
      ]
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "storyPointEstimate_nativeIssueTable_StoryPointEstimateCell"
    }
  ],
  "type": "JiraNumberField"
};
})();

(node as any).hash = "a39feb75905be7bc84dc70d725916c5e";

export default node;
