import React, { memo, type MouseEvent, type KeyboardEvent, useCallback } from 'react';
import noop from 'lodash/noop';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { SingleLineTextInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-single-line-text-inline-edit-full/src/ui/single-line-text/index.tsx';
import type { issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef.graphql.ts';
import { fg } from '@atlassian/jira-feature-gating';
import type { issueSummary_nativeIssueTable_IssueSummaryCell_issueRef$key } from '@atlassian/jira-relay/src/__generated__/issueSummary_nativeIssueTable_IssueSummaryCell_issueRef.graphql.ts';
import {
	useIsDensityFull,
	useIsInlineEditingExtendedFieldSupportEnabled,
} from '../../../../controllers/features/selectors.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import TableCellLink from '../../table-cell-link/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useUpdateIssueSummary } from './utils.tsx';

export type Props = {
	fieldRef: issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef$key;
	issueRef: issueSummary_nativeIssueTable_IssueSummaryCell_issueRef$key;
	/**
	 * Dispatched when a table cell with a link to the issue is selected. This method should return `true` to signal
	 * that the event has been handled, which will prevent default event handling.
	 */
	onIssueLinkSelected?: (issueKey: string) => boolean;
};

export const IssueSummaryCell = memo<Props>(
	({ fieldRef, issueRef, onIssueLinkSelected }: Props) => {
		const data = useFragment<issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef$key>(
			graphql`
				fragment issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef on JiraSingleLineTextField
				@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
					id
					lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
					...singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef
				}
			`,

			fieldRef,
		);

		const issueData = useFragment<issueSummary_nativeIssueTable_IssueSummaryCell_issueRef$key>(
			graphql`
				fragment issueSummary_nativeIssueTable_IssueSummaryCell_issueRef on JiraIssue {
					__id
					key
				}
			`,
			issueRef,
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const updateIssueSummary = fg('jsc_list_reparenting') ? useUpdateIssueSummary() : noop;

		const onSubmitSucceeded = useCallback(
			(value: string) => {
				if (issueData?.__id && fg('jsc_list_reparenting')) {
					updateIssueSummary(issueData.__id, value);
				}
			},
			[issueData?.__id, updateIssueSummary],
		);

		// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
		// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
		const isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
		useLazyLoadEditability(data.id, isEditable);

		const isDensityCompact = !useIsDensityFull();
		const isInlineEditingExtendedFieldSupportEnabled =
			useIsInlineEditingExtendedFieldSupportEnabled();

		const renderCustomReadView = useCallback(
			(text: string | null) => {
				if (!text) {
					return <ErrorCell cellType="summary" reason="Missing required fields" />;
				}

				const content = (
					<TableCellLink
						innerFocusOutline
						href={`/browse/${issueData.key}`}
						isSubtle={false}
						onClick={(e: MouseEvent | KeyboardEvent) => {
							// Use default browser event handling when a modifier key is pressed during the click event
							const isModifierKeyUsed = e.ctrlKey || e.shiftKey || e.metaKey;
							const handled =
								!isModifierKeyUsed &&
								onIssueLinkSelected !== undefined &&
								onIssueLinkSelected(issueData.key);
							if (handled) {
								e.preventDefault();
							}
						}}
						data-testid="native-issue-table.common.ui.issue-cells.issue-summary.issue-summary-cell"
					>
						{text}
					</TableCellLink>
				);

				if (isInlineEditingExtendedFieldSupportEnabled) {
					return (
						<Text
							testId="native-issue-table.common.ui.issue-cells.issue-summary.issue-summary-cell"
							maxLines={isDensityCompact ? 2 : undefined}
						>
							{text}
						</Text>
					);
				}

				// Span is necessary here to ensure only link text is clickable for multiline summaries, rather than the
				// entire content box.
				return isDensityCompact ? (
					<Box as="span" xcss={textOverflowStyles}>
						{content}
					</Box>
				) : (
					<span>{content}</span>
				);
			},
			[
				isDensityCompact,
				isInlineEditingExtendedFieldSupportEnabled,
				issueData.key,
				onIssueLinkSelected,
			],
		);

		return (
			<SingleLineTextInlineEditViewWithIsEditable
				attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
				spacing="default"
				editViewPopup
				editViewPopupAlignBlock="center"
				fragmentRef={data}
				isEditable={isEditable ?? false}
				isRequired
				maxLength={255}
				readViewFitContainerHeight
				renderCustomReadView={renderCustomReadView}
				hideEditButton={isInlineEditingExtendedFieldSupportEnabled}
				onSubmitSucceeded={fg('jsc_list_reparenting') ? onSubmitSucceeded : undefined}
			/>
		);
	},
);

const textOverflowStyles = xcss({
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 2,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'break-word',
});
