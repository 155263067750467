import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnActions: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.column-actions',
		defaultMessage: 'More actions for {column}',
		description:
			'Accessible label for an icon-only button that (when clicked) displays a dropdown list of column actions for a table',
	},
	sortAsc: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.sort-asc',
		defaultMessage: 'Sort in ascending order',
		description:
			'Text for an action that allows the user to sort a list of items in ascending order',
	},
	sortDesc: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.sort-desc',
		defaultMessage: 'Sort in descending order',
		description:
			'Text for an action that allows the user to sort a list of items in descending order',
	},
	sortCustom: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.sort-custom',
		defaultMessage: 'Sort {sortDescription}',
		description:
			'Text for an action that allows the user to sort a list of items – sortDescription is like "A -> Z"',
	},
	moveFirst: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.move-first',
		defaultMessage: 'Move column to the first position',
		description:
			'Text for an action that allows the user to move a table column to the first position',
	},
	moveLeft: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.move-left',
		defaultMessage: 'Move column to the left',
		description: 'Text for an action that allows the user to move a table column to the left',
	},
	moveRight: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.move-right',
		defaultMessage: 'Move column to the right',
		description: 'Text for an action that allows the user to move a table column to the right',
	},
	moveLast: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.move-last',
		defaultMessage: 'Move column to the last position',
		description:
			'Text for an action that allows the user to move a table column to the last position',
	},
	removeColumn: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.remove-column',
		defaultMessage: 'Remove column',
		description: 'Text for an action that allows the user to remove a column from a table',
	},
	resizeColumn: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.resize-column',
		defaultMessage: 'Resize column',
		description: 'Text for an action that allows the user to resize the width of a table column',
	},
	resetColumnWidth: {
		id: 'native-issue-table.issue-table.header.header-cell.header-cell-actions.more-actions.reset-column-width',
		defaultMessage: 'Reset column width',
		description:
			'Text for an action that allows the user to reset a table column to its default width',
	},
});
