/**
 * @generated SignedSource<<3901018953637bca59dab12c8fc72e0d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type paginationControls_nativeIssueTable$data = {
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
    readonly first: {
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
    readonly last: {
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "paginationControls_nativeIssueTable";
};
export type paginationControls_nativeIssueTable$key = {
  readonly " $data"?: paginationControls_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"paginationControls_nativeIssueTable">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "cursor"
  },
  {
    "kind": "ScalarField",
    "name": "pageNumber"
  },
  {
    "kind": "ScalarField",
    "name": "isCurrent"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "name": "paginationControls_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        },
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "first",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "last",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "f6c43af42ef3986e51936f79243f5544";

export default node;
