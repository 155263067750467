import React, { type PropsWithChildren, useEffect } from 'react';
import DataLoader from 'dataloader';
import { useRelayEnvironment } from 'react-relay';
import { JiraIssuefieldvalueAri } from '@atlassian/ari/jira/issuefieldvalue';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer, createActionsHook } from '@atlassian/react-sweet-state';
import { createDataLoaderScheduler } from '../../common/utils/data-loader-scheduler/index.tsx';
import { actions, batchIsEditableInIssueViewFactory } from './actions.tsx';
import { FIELD_IDS_BATCH_SIZE } from './constants.tsx';
import type { State, Actions, Props } from './types.tsx';

const initialState: State = {
	loader: null,
	scheduler: null,
};

const Container = createContainer<Props>();

const Store = createStore<State, Actions, Props>({
	containedBy: Container,
	initialState,
	actions,
	handlers: {
		onInit:
			() =>
			({ setState }, { environment }) => {
				const scheduler = createDataLoaderScheduler(100);
				const loader = new DataLoader(batchIsEditableInIssueViewFactory(environment), {
					maxBatchSize: FIELD_IDS_BATCH_SIZE,
					batchScheduleFn: scheduler.schedule,
				});
				setState({
					loader,
					scheduler,
				});
			},
	},
});

export const LazyEditabilityLoaderContainer = ({
	children,
	scope,
}: PropsWithChildren<{ scope?: string }>) => {
	const environment = useRelayEnvironment();

	return (
		<Container scope={scope} environment={environment}>
			{children}
		</Container>
	);
};

// Exported for testing
export const useLazyEditabilityLoaderActions = createActionsHook(Store);

export const useLazyLoadEditability = (
	id: string | null | undefined,
	isEditable?: boolean | null,
) => {
	const { load } = useLazyEditabilityLoaderActions();

	useEffect(() => {
		const executeLoad = async () => {
			// If there is no editability information for the issue field then queue a request to lazily fetch this data
			// We need to explicitly check the ID is an ARI to ignore fields for optimistically created issues, which
			// are initialised as a UUID.
			if (id != null && isEditable === undefined && JiraIssuefieldvalueAri.check(id)) {
				load(id);
			}
		};
		executeLoad();
	}, [isEditable, load, id]);
};
