import React, { type ComponentType } from 'react';
import noop from 'lodash/noop';
import { useConnectionsList } from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ConnectionsSubscriberProps } from '../../common/types.tsx';
import { useUpdateConnectionDetails } from '../../controllers/issue-realtime-updater/utils.tsx';

type ConnectionsSubscriberContainerProps = {
	ConnectionsSubscriber: ComponentType<ConnectionsSubscriberProps>;
};

export const ConnectionsSubscriberContainer = ({
	ConnectionsSubscriber,
}: ConnectionsSubscriberContainerProps) => {
	const { connections } = fg('decouple_connection_register_hooks_from_realtime')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useConnectionsList()
		: { connections: {} };

	const updateConnectionDetails = fg('decouple_connection_register_hooks_from_realtime')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useUpdateConnectionDetails()
		: noop;

	return (
		<ConnectionsSubscriber
			connections={connections}
			updateConnectionDetails={updateConnectionDetails}
		/>
	);
};
