/**
 * @generated SignedSource<<87c2e0fc25d3c82a0a376ce7418f273f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly accountStatus: AccountStatus;
        readonly email?: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView">;
  readonly " $fragmentType": "multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef";
};
export type multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView"
    },
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                {
                  "kind": "ScalarField",
                  "name": "accountStatus"
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "picture"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleSelectUserPickerField"
};
})();

(node as any).hash = "b0466005c00a3d3242fa4c867100b929";

export default node;
