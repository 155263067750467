import React, { type ComponentType, useState } from 'react';
import type { IconProp } from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { ErrorState } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import PickerButton, { PickerButtonFallback } from './column-picker/picker-button/index.tsx';
import type { Props } from './main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ColumnConfig = lazyAfterPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-column-config" */ './main'),
	{ ssr: false },
);

const ErrorFallback = ({ onRetry, customIcon }: { onRetry: () => void; customIcon?: IconProp }) => (
	<DropdownMenu
		trigger={({ triggerRef, ...props }) => (
			<PickerButton {...props} ref={triggerRef} customIcon={customIcon} />
		)}
		placement="bottom-end"
		spacing="compact"
	>
		<ErrorState onRetry={onRetry} pageAction="columnPicker" />
	</DropdownMenu>
);

const AsyncColumnConfig = (props: Props) => {
	const [resetCaughtError, setResetCaughtError] = useState<Error>();

	return (
		<JSErrorBoundary
			packageName="jiraIssueTableColumnPicker"
			id="unhandled"
			sendToPrivacyUnsafeSplunk
			// Attempt to re-render children when retry is pressed in the fallback error state
			fallback={({ error }) => (
				<ErrorFallback
					onRetry={() => setResetCaughtError(error)}
					customIcon={props.components?.Icon}
				/>
			)}
			onError={() => setResetCaughtError(undefined)}
			resetCaughtError={resetCaughtError}
		>
			<Placeholder
				name="async-column-config"
				fallback={
					<PickerButtonFallback
						customIcon={props.components?.Icon}
						isInBetweenColumnPickerEnabled={props.isInBetweenColumnPickerEnabled}
					/>
				}
			>
				<ColumnConfig {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};

export default AsyncColumnConfig;
