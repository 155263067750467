export const ISSUE = 'issue';
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FIELD = 'field';

export type Key = typeof ISSUE | typeof FIELD;

// We require namespacing so we can use edge indexes relative to their connection, e.g. when rendering grouped issue rows
export type Index = [namespace: string, edgeIndex: number];

export type State = {
	[key in Key]: {
		index?: Index;
		isIndexLocked: boolean;
		timeoutId?: NodeJS.Timeout;
	};
};
