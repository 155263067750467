import React, { useMemo } from 'react';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { useSelectedIssueKey } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { useSelectedRow } from '../../controllers/hierarchy/index.tsx';

type IssueKey = string;

type Props = {
	issueKeys?: IssueKey[];
	onIssueLinkSelected?: (issueKey: string) => boolean;
};

const KeyboardShortcuts = ({ issueKeys = [], onIssueLinkSelected }: Props) => {
	const { push } = useRouterActions();
	const [selectedRowIndex, { selectNextRow, selectPrevRow }] = useSelectedRow();
	const [selectedIssueKey] = fg('jsc_m2_hierarchy_fe_changes')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueKey()
		: [null];

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const keyMap = useMemo(() => {
		const navigateToIssue = (issueKey: string) => {
			push(`/browse/${issueKey}`);
		};

		return {
			arrowup: {
				callback: (e?: KeyboardEvent) => {
					// Prevent native browser scrolling as we provide auto-scroll functionality via scrollIntoView
					e?.preventDefault();
					selectPrevRow();
				},
			},
			arrowdown: {
				callback: (e?: KeyboardEvent) => {
					// Prevent native browser scrolling as we provide auto-scroll functionality via scrollIntoView
					e?.preventDefault();
					selectNextRow();
				},
			},
			k: {
				callback: () => {
					fireUIAnalytics(createAnalyticsEvent({}), 'rowItem pressed', 'navigateRowUp', {
						rowIndex: selectedRowIndex,
						keyPressed: 'k',
					});
					selectPrevRow();
				},
			},
			j: {
				callback: () => {
					fireUIAnalytics(createAnalyticsEvent({}), 'rowItem pressed', 'navigateRowDown', {
						rowIndex: selectedRowIndex,
						keyPressed: 'j',
					});
					selectNextRow();
				},
			},
			o: {
				callback: () => {
					fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'tableIssueLink', {
						fieldType: 'unknown',
						keyPressed: 'o',
						tableRowNumber: selectedRowIndex,
						keyboardShortcut: true,
					});

					if (fg('jsc_m2_hierarchy_fe_changes')) {
						if (typeof selectedIssueKey === 'string' && selectedIssueKey.length > 0) {
							const handled =
								onIssueLinkSelected !== undefined && onIssueLinkSelected(selectedIssueKey);
							if (!handled) {
								navigateToIssue(selectedIssueKey);
							}
						}

						return;
					}

					if (typeof selectedRowIndex === 'number') {
						const issueKey = issueKeys[selectedRowIndex];
						if (typeof issueKey === 'string' && issueKey.length > 0) {
							const handled = onIssueLinkSelected !== undefined && onIssueLinkSelected(issueKey);
							if (!handled) {
								navigateToIssue(issueKey);
							}
						}
					}
				},
			},
			z: {
				callback: () => {
					fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'tableIssueLink', {
						fieldType: 'unknown',
						keyPressed: 'z',
						tableRowNumber: selectedRowIndex,
						keyboardShortcut: true,
					});

					if (fg('jsc_m2_hierarchy_fe_changes')) {
						if (typeof selectedIssueKey === 'string' && selectedIssueKey.length > 0) {
							const handled =
								onIssueLinkSelected !== undefined && onIssueLinkSelected(selectedIssueKey);
							if (!handled) {
								navigateToIssue(selectedIssueKey);
							}
						}

						return;
					}

					if (typeof selectedRowIndex === 'number') {
						const issueKey = issueKeys[selectedRowIndex];
						if (typeof issueKey === 'string' && issueKey.length > 0) {
							const handled = onIssueLinkSelected !== undefined && onIssueLinkSelected(issueKey);
							if (!handled) {
								navigateToIssue(issueKey);
							}
						}
					}
				},
			},
		};
	}, [
		push,
		selectPrevRow,
		selectNextRow,
		createAnalyticsEvent,
		selectedRowIndex,
		selectedIssueKey,
		onIssueLinkSelected,
		issueKeys,
	]);

	return <Shortcuts keyMap={keyMap} />;
};

export default KeyboardShortcuts;
