/**
 * @generated SignedSource<<2fb7462fc58470e5f932ba5f158f8a16>>
 * @relayHash 53b193ba86b71b4b505947ef99d7bce3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ddf0f50c9d8d9f30aa48671b2fc60b8934c2cf6d1edc9897ed54f296df319cd4

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraFieldOptionIdsFilterOperation = "ALLOW" | "EXCLUDE" | "%future added value";
export type JiraFieldOptionIdsFilterInput = {
  operation: JiraFieldOptionIdsFilterOperation;
  optionIds: ReadonlyArray<string>;
};
export type issueType_SearchRefetchQuery$variables = {
  after?: string | null | undefined;
  filterById?: JiraFieldOptionIdsFilterInput | null | undefined;
  first?: number | null | undefined;
  id: string;
  searchBy?: string | null | undefined;
};
export type issueType_SearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef">;
};
export type issueType_SearchRefetchQuery = {
  response: issueType_SearchRefetchQuery$data;
  variables: issueType_SearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterById"
  },
  {
    "defaultValue": 1000,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "filterById",
  "variableName": "filterById"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v5 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueType_SearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueType_SearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      },
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": "value",
                            "kind": "ScalarField",
                            "name": "id"
                          },
                          {
                            "alias": "label",
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "small"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v7/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "issueType_issueFieldIssueTypeEditviewFull_issueTypes",
                "kind": "LinkedHandle",
                "name": "issueTypes"
              }
            ],
            "type": "JiraIssueTypeField"
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "ddf0f50c9d8d9f30aa48671b2fc60b8934c2cf6d1edc9897ed54f296df319cd4",
    "metadata": {},
    "name": "issueType_SearchRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "54c04d4f507b644a10ef1cc8b3c0fc09";

export default node;
