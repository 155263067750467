import React, {
	useCallback,
	type KeyboardEvent,
	type MouseEvent,
	type DragEvent,
	type TouchEvent,
} from 'react';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import Form, { RangeField } from '@atlaskit/form';
import type { PopupProps, ContentProps } from '@atlaskit/popup';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Range from '@atlaskit/range';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import {
	MINIMUM_COLUMN_WIDTH,
	MAXIMUM_COLUMN_WIDTH,
	MINIMUM_COLUMN_WIDTH_COMPACT,
} from '../../../../../common/constants.tsx';
import { ensureWidthWithinLimits } from '../utils.tsx';
import messages from './messages.tsx';

type ResizePopupContentProps = {
	columnTitle: string;
	initialWidth: number;
	isCompactColumnEnabled: boolean;
	minimumWidth?: number;
	onChange: (width: number) => void;
	onCancel: (event: KeyboardEvent | MouseEvent, analyticsEvent?: UIAnalyticsEvent) => void;
	onSubmit: (width: number) => void;
	setInitialFocusRef: ContentProps['setInitialFocusRef'];
};

const ResizePopupContent = ({
	columnTitle,
	initialWidth,
	isCompactColumnEnabled = false,
	minimumWidth,
	onChange,
	onCancel,
	onSubmit,
	setInitialFocusRef,
}: ResizePopupContentProps) => {
	const { formatMessage } = useIntl();
	const handleDragOrTouchStart = useCallback((event: DragEvent | TouchEvent) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const defaultMinWidth = isCompactColumnEnabled
		? MINIMUM_COLUMN_WIDTH_COMPACT
		: MINIMUM_COLUMN_WIDTH;
	const minWidth = minimumWidth || defaultMinWidth;

	return (
		<Box paddingInline="space.200" xcss={PopupContentStyles}>
			<Form<{ width: number }>
				onSubmit={({ width }) => onSubmit(ensureWidthWithinLimits(width, isCompactColumnEnabled))}
			>
				{({ formProps }) => (
					<form {...formProps}>
						<RangeField
							label={formatMessage(messages.columnWidthLabel, { columnTitle })}
							name="width"
							defaultValue={initialWidth}
						>
							{({ fieldProps }) => (
								<Range
									{...fieldProps}
									ref={setInitialFocusRef}
									min={minWidth}
									max={MAXIMUM_COLUMN_WIDTH}
									step={5}
									onChange={(value) => {
										onChange(value);
										fieldProps.onChange(value);
									}}
									{...(fg('jfp_a11y_issue_list_view_inaccurate_reading_order')
										? {
												draggable: true,
												onDragStart: handleDragOrTouchStart,
												onTouchStart: handleDragOrTouchStart,
											}
										: {})}
								/>
							)}
						</RangeField>
						<Inline space="space.100" alignInline="end" xcss={FormFooterStyles}>
							<Button appearance="subtle" onClick={onCancel}>
								{formatMessage(messages.cancel)}
							</Button>
							<Button type="submit" appearance="primary">
								{formatMessage(messages.save)}
							</Button>
						</Inline>
					</form>
				)}
			</Form>
			<ShortcutScope />
		</Box>
	);
};

export type Props = {
	columnTitle: string;
	initialWidth: number;
	placement: PopupProps['placement'];
	isOpen: boolean;
	isCompactColumnEnabled?: boolean;
	minimumWidth?: number;
	trigger: PopupProps['trigger'];
	onChange: (width: number) => void;
	onCancel: (event: KeyboardEvent | MouseEvent) => void;
	onSubmit: (width: number) => void;
};

export const ResizePopup = ({
	columnTitle,
	initialWidth,
	placement,
	isOpen,
	isCompactColumnEnabled = false,
	minimumWidth,
	trigger,
	onChange,
	onCancel: onCancelProp,
	onSubmit,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onCancel: ResizePopupContentProps['onCancel'] = useCallback(
		(event, analyticsEventParam) => {
			const analyticsEvent =
				analyticsEventParam ??
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				});

			fireUIAnalytics(analyticsEvent, 'cancelColumnResize');
			onCancelProp(event);
		},
		[onCancelProp, createAnalyticsEvent],
	);

	const PopupContent: PopupProps['content'] = useCallback(
		({ setInitialFocusRef }) => (
			<ResizePopupContent
				columnTitle={columnTitle}
				initialWidth={initialWidth}
				isCompactColumnEnabled={isCompactColumnEnabled}
				minimumWidth={minimumWidth}
				onChange={onChange}
				onCancel={onCancel}
				onSubmit={onSubmit}
				setInitialFocusRef={setInitialFocusRef}
			/>
		),
		[columnTitle, initialWidth, isCompactColumnEnabled, minimumWidth, onChange, onCancel, onSubmit],
	);

	return (
		<Popup
			testId="native-issue-table.ui.issue-table.header.header-cell.resize-popup.resize-popup"
			placement={placement}
			isOpen={isOpen}
			onClose={onCancel}
			trigger={trigger}
			content={PopupContent}
			{...(fg('jfp_a11y_issue_list_view_inaccurate_reading_order')
				? { shouldRenderToParent: true }
				: {})}
			messageId="native-issue-table.ui.issue-table.header.header-cell.resize-popup.popup"
			messageType="transactional"
		/>
	);
};

const PopupContentStyles = xcss({
	fontWeight: token('font.weight.regular'),
	marginBlock: 'space.200',
	whiteSpace: 'normal',
});

const FormFooterStyles = xcss({
	marginTop: 'space.100',
});
