import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const RadioSelectEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-radio-select-edit-view" */ './ui/radio-select'),
	),
	getPreloadProps: () => ({}),
});

export default RadioSelectEditViewEntryPoint;
