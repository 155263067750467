import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { MultiVersionPickerInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-multi-version-picker-inline-edit-full/src/ui/multi-version-picker/index.tsx';
import type { multiVersionPicker_nativeIssueTable_MultiVersionPickerCell$key } from '@atlassian/jira-relay/src/__generated__/multiVersionPicker_nativeIssueTable_MultiVersionPickerCell.graphql';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: multiVersionPicker_nativeIssueTable_MultiVersionPickerCell$key;
};

export const MultiVersionPickerCell = ({ fieldRef }: Props) => {
	const data = useFragment<multiVersionPicker_nativeIssueTable_MultiVersionPickerCell$key>(
		graphql`
			fragment multiVersionPicker_nativeIssueTable_MultiVersionPickerCell on JiraMultipleVersionPickerField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<MultiVersionPickerInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
			isTruncated
		/>
	);
};
