import React, { type PropsWithChildren, type ComponentPropsWithoutRef } from 'react';
import castArray from 'lodash/castArray';
import { Box, xcss, type XCSS } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { CellHoverProvider, useCellHoverHandlers } from '../hover-popover/hover-wrapper/index.tsx';
import { useCellWrapper } from '../cell-navigation/cell-wrapper/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../controllers/features/selectors.tsx';

export type Props = Omit<
	PropsWithChildren<{
		/**
		 * Optional border styles to apply to the `td` element. If not provided, no borders will be rendered.
		 */
		border?: XCSS | Array<XCSS | false | undefined>;
		/**
		 * Optional spacing styles to apply to the `td` element. If not provided, default spacing will apply.
		 */
		spacing?: XCSS | Array<XCSS | false | undefined>;
		/**
		 * Optional row number used for keyboard grid navigation
		 */
		row?: number;
		/**
		 * Optional column number used for keyboard grid navigation
		 */
		column?: number;
	}> &
		ComponentPropsWithoutRef<'td'>,
	'onMouseEnter' | 'onMouseLeave' | 'onBlur'
>;

export const TableCell = (props: Props) => {
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	return isSingleLineRowHeightEnabled ? (
		<CellHoverProvider>
			<TableCellInner {...props} />
		</CellHoverProvider>
	) : (
		<TableCellInner {...props} />
	);
};

const TableCellInner = ({ children, border, spacing, row, column, ...htmlAttributes }: Props) => {
	const spacingStyles = spacing !== undefined ? castArray(spacing) : [defaultSpacingStyles];

	const borderStyles = border !== undefined ? castArray(border) : [];

	const { cellRef } = fg('jira_list_grid_pattern_keyboard_nav')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCellWrapper({
				row: row == null ? 0 : row + 1,
				col: column == null ? 0 : column,
			})
		: { cellRef: null };

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	let singleLineRowHeightProps = {};

	if (isSingleLineRowHeightEnabled) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { handleMouseEnter, handleMouseLeave } = useCellHoverHandlers();
		singleLineRowHeightProps = {
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			onBlur: handleMouseLeave,
		};
	}

	return (
		<Box
			as="td"
			xcss={[baseStyles, ...spacingStyles, ...borderStyles]}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...(fg('jira_list_grid_pattern_keyboard_nav') && {
				ref: cellRef,
			})}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...htmlAttributes}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...singleLineRowHeightProps}
		>
			{children}
		</Box>
	);
};

const baseStyles = xcss({
	background: 'inherit',
	// Cross-browser CSS hack to allow inline editable fields to fill the entire space of their `td`. The
	// preferred long-term solution is to adopt a flexbox layout, but until then, please do extensive
	// cross-browser testing before you update this rule.
	height: '100%',
	position: 'relative',
});

const defaultSpacingStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':first-of-type': {
		paddingLeft: 'space.100',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':last-of-type': {
		paddingRight: 'space.100',
	},
});
