import React, { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import AvatarGroup, { type AvatarProps } from '@atlaskit/avatar-group';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView$key as MultiUserPickerFragment } from '@atlassian/jira-relay/src/__generated__/multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView.graphql';
import type { MultiUserPickerReadViewProps, NonNullableSelectedUserEdge } from './types.tsx';

/**
 * The MultiUserPickerReadView will show a read only view of MultiUserPicker field.
 * @param props [MultiUserPickerReadViewProps](./types.tsx)
 */
export const MultiUserPickerReadView = ({ fragmentRef }: MultiUserPickerReadViewProps) => {
	const { selectedUsersConnection } = useFragment<MultiUserPickerFragment>(
		graphql`
			fragment multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView on JiraMultipleSelectUserPickerField {
				selectedUsersConnection {
					edges {
						node {
							accountId
							name
							picture
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const transformedData: AvatarProps[] = useMemo(() => {
		const filteredEdges =
			selectedUsersConnection?.edges?.filter((edge): edge is NonNullableSelectedUserEdge =>
				Boolean(edge?.node),
			) ?? [];

		return filteredEdges.map((edge) => ({
			name: edge.node.name,
			key: edge.node.accountId,
			src: edge.node.picture,
		}));
	}, [selectedUsersConnection?.edges]);

	if (transformedData.length === 0) {
		return <EmptyFieldText />;
	}

	return <AvatarGroup data={transformedData} size="small" />;
};
