import { useCallback } from 'react';
import { useRelayEnvironment, commitLocalUpdate, useMutation } from 'react-relay';
import { graphql, type RecordSourceProxy } from 'relay-runtime';
import RelayDataID from '@atlassian/relay-data-id';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	JiraUpdateParentFieldInput,
	parent_issueFieldParent_ParentField_Mutation,
} from '@atlassian/jira-relay/src/__generated__/parent_issueFieldParent_ParentField_Mutation.graphql';
import { useFlagsService } from '@atlassian/jira-flags/src/services/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import {
	HIGHLIGHT_LINGER_DURATION_MS,
	ANALYTICS_SOURCE,
	PACKAGE_NAME,
	TEAM_NAME,
} from '../../common/constants.tsx';

import {
	ACTION_SUBJECT_ID,
	REPARENTING_EXPERIENCE,
	UPDATE_ITEM_PARENT_FAIL_GENERIC_FLAG,
} from './constants.tsx';
import { getFlagDetails, useGetReparentUpdater, JIRA_ISSUE_TYPENAME } from './utils.tsx';

type ReparentProps = {
	issueId: string;
	issueAri: string;
	newParentAri: string | null;
	oldParentId: string | undefined;
	newParentId: string | undefined;
	newParentEdgeId: string | undefined;
	index: number | undefined;
	projectKey?: string;
};

export const useUpdateParent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();

	const onExperienceStart = useExperienceStart({
		experience: REPARENTING_EXPERIENCE,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceFail = useExperienceFail({
		experience: REPARENTING_EXPERIENCE,
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience: REPARENTING_EXPERIENCE,
	});

	const [commitMutation, loading] = useMutation<parent_issueFieldParent_ParentField_Mutation>(
		graphql`
			mutation reparentMutation($input: JiraUpdateParentFieldInput!) {
				jira {
					updateParentField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
						success
						errors {
							extensions {
								statusCode
							}
						}
						field {
							id
							type
							name
						}
					}
				}
			}
		`,
	);

	const handleError = useCallback(
		(error: MutationError) => {
			fireErrorAnalytics({
				meta: {
					id: 'issueTableReparentMutation',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			onExperienceFail(PACKAGE_NAME, error);

			showFlag(UPDATE_ITEM_PARENT_FAIL_GENERIC_FLAG);
		},
		[onExperienceFail, showFlag],
	);

	const updater = useGetReparentUpdater();

	const environment = useRelayEnvironment();
	const highlightIssue = useCallback(
		({ issueId, store }: { issueId: string; store: RecordSourceProxy }) => {
			// Highlight the row
			const issueRelayID = RelayDataID({ id: issueId }, JIRA_ISSUE_TYPENAME);
			if (issueRelayID) {
				const node = store.get(issueRelayID);
				if (node) {
					node?.setValue(true, 'isHighlightedIssueRow');
				}
			}
		},
		[],
	);

	const reparent = useCallback(
		({
			issueId,
			issueAri,
			oldParentId,
			newParentId,
			newParentAri,
			newParentEdgeId,
			index,
			projectKey,
		}: ReparentProps) => {
			const input: JiraUpdateParentFieldInput = {
				id: issueAri,
				operation: { operation: 'SET', id: newParentAri },
			};

			onExperienceStart();

			commitMutation({
				variables: {
					input,
				},
				optimisticUpdater: (store) => {
					updater({
						store,
						issueId,
						oldParentId,
						newParentId,
						newParentEdgeId,
						index,
						projectKey,
					});

					// Highlight the row
					highlightIssue({ store, issueId });
				},
				updater: (store, response) => {
					if (response?.jira?.updateParentField?.success) {
						updater({
							store,
							issueId,
							oldParentId,
							newParentId,
							newParentEdgeId,
							index,
							projectKey,
						});

						highlightIssue({ store, issueId });

						const issueRelayID = RelayDataID({ id: issueId }, JIRA_ISSUE_TYPENAME);
						if (issueRelayID) {
							setTimeout(() => {
								commitLocalUpdate(environment, (currentStore) => {
									const node = currentStore.get(issueRelayID);
									node?.setValue(false, 'isHighlightedIssueRow');
								});
							}, HIGHLIGHT_LINGER_DURATION_MS);
						}
					}
				},
				onCompleted: (response) => {
					const { errors } = response?.jira?.updateParentField ?? {};
					if (errors && errors.length > 0) {
						const error = new MutationError(errors[0]);
						const statusCode = error.statusCode;

						const clientSideErrorMessage = getFlagDetails(statusCode);

						if (clientSideErrorMessage) {
							showFlag(clientSideErrorMessage);
							onExperienceSuccess();
							return;
						}

						handleError(error);
						return;
					}

					fireTrackAnalytics(createAnalyticsEvent({}), 'issue reparented', ACTION_SUBJECT_ID);
					onExperienceSuccess();
				},
				onError: (error: Error) => {
					const err = new MutationError(error);
					handleError(err);
				},
			});
		},
		[
			commitMutation,
			createAnalyticsEvent,
			environment,
			handleError,
			highlightIssue,
			onExperienceStart,
			onExperienceSuccess,
			showFlag,
			updater,
		],
	);

	return { reparent, loading };
};
