import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { RadioSelectInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-radio-select-inline-edit-full/src/ui/radio-select/index.tsx';
import type { radioSelect_nativeIssueTable_RadioSelectCell$key } from '@atlassian/jira-relay/src/__generated__/radioSelect_nativeIssueTable_RadioSelectCell.graphql';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: radioSelect_nativeIssueTable_RadioSelectCell$key;
};

export const RadioSelectCell = ({ fieldRef }: Props) => {
	const data = useFragment<radioSelect_nativeIssueTable_RadioSelectCell$key>(
		graphql`
			fragment radioSelect_nativeIssueTable_RadioSelectCell on JiraRadioSelectField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<RadioSelectInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
			maxLines={1}
		/>
	);
};
