import React from 'react';
import { IssueTableSelectedIssuesContainer } from '../../controllers/selected-issues/index.tsx';
import type { Props } from '../types.tsx';

/**
 * When multi select is enabled we don't provide a container as this needs to be defined by the consumer for usage.
 * However when multi select is not enabled we are required to provide a container such that the component
 * will not throw any errors.
 * This ensures that errors are only properly thrown when a container is not provided when multi select is enabled.
 */
export const IssueTableSelectedIssuesProvider = (Component: React.ComponentType<Props>) => {
	const SelectedIssuesWrapperComponent = (props: Props) => {
		if (props.enableMultiSelect) {
			return <Component {...props} />;
		}

		return (
			<IssueTableSelectedIssuesContainer>
				<Component {...props} />
			</IssueTableSelectedIssuesContainer>
		);
	};

	return SelectedIssuesWrapperComponent;
};
