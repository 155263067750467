/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, {
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
	forwardRef,
	type ReactNode,
} from 'react';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	cssMap,
	cx,
	jsx,
	type StrictXCSSProp,
	type XCSSAllProperties,
	type XCSSAllPseudos,
} from '@atlaskit/css';
import { Manager, Reference } from '@atlaskit/popper';
import { Box } from '@atlaskit/primitives';
import { Pressable } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { JiraPopper as Popper } from '@atlassian/jira-popper/src/ui/jira-popper.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ConditionalWrapper } from '../conditional-wrapper/index.tsx';

const styles = cssMap({
	reference: {
		left: token('space.0'),
		top: token('space.0'),
	},
	visuallyHidden: {
		width: '1px',
		height: '1px',
		paddingTop: token('space.0'),
		paddingRight: token('space.0'),
		paddingBottom: token('space.0'),
		paddingLeft: token('space.0'),
		position: 'absolute',
		border: 'none',
		clipPath: 'rect(1px 1px 1px 1px)',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
});

const buttonStyles = cssMap({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 400,
	},
	default: {
		backgroundColor: token('elevation.surface.overlay'),
		borderRadius: token('border.radius'),
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: token('elevation.surface.overlay.hovered'),
		},
		'&:active': {
			backgroundColor: token('elevation.surface.overlay.pressed'),
		},
	},
});

const constantModifiers = [
	{
		name: 'flip',
		enabled: false,
	},
];

// Height/width of the popup button
const DEFAULT_BUTTON_SIZE = 24;
// Height/width of the reference element the popup button is anchored to
const REFERENCE_SIZE = 1;

export type Props = {
	/** Icon be rendered inside the Pressable */
	icon: ReactNode;
	/** `true` if the button should be visible in the UI. */
	isVisible: boolean;
	/** `true` if the button should be vertically aligned along the Y axis. */
	alignToYAxis?: boolean;
	/** Size of the button used to calculate the offset */
	buttonSize?: number;
	/** Label for the Pressable */
	label?: string;
	/** Whether to wrap the button with SpotlightTarget */
	withSpotlight?: boolean;
	/**
	 * Distance the popup should be offset from the reference in the format of [along, away] (units in px). `along`
	 * refers to the primary axis, i.e. Y axis when `alignToYAxis` is `true`, and X axis when `alignToYAxis` is `false`.
	 * See https://popper.js.org/docs/v2/modifiers/offset/.
	 */
	offset?: [number, number];
	/** Event called when the button is clicked. */
	onClick?: (e: React.MouseEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => void;
	/** Event called when the mouse enters the button. */
	onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	/** Event called when the mouse leaves the button. */
	onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	/** Renders the tooltip content if provided. */
	tooltipContent?: TooltipProps['content'];
	xcss?: StrictXCSSProp<XCSSAllProperties, XCSSAllPseudos>;
};

/**
 * Display a popup button positioned relative to the nearest containing block.
 */
export const RowPopupButton = forwardRef<HTMLElement, Props>((props, forwardedRef) => {
	const {
		icon,
		isVisible,
		label,
		onClick,
		onMouseEnter,
		onMouseLeave,
		tooltipContent,
		alignToYAxis = true,
		buttonSize = DEFAULT_BUTTON_SIZE,
		offset = [0, 0],
		xcss,
		withSpotlight = false,
	} = props;

	const [isFocused, setIsFocused] = useState(false);
	const updateRef = useRef<() => void>();

	useLayoutEffect(() => {
		// Recompute popup position when the button is shown
		isVisible && updateRef.current?.();
	}, [isVisible]);

	useLayoutEffect(() => {
		// Recompute popup position when the button is focused
		isFocused && updateRef.current?.();
	}, [isFocused]);

	const [along, away] = offset;
	const finalOffset: [number, number] = useMemo(
		() =>
			// Negatively offset the popup by the button + reference size to center the button on the secondary axis
			[along, away - (buttonSize + REFERENCE_SIZE) / 2],
		[along, away, buttonSize],
	);

	const modifiers = useMemo(() => {
		// Adjust the overflow calculation for the primary axis so it is relative to the center of the button.
		const alongCenterOffset = -(buttonSize / 2);
		// Retain the provided offset for the secondary axis
		const padding = alignToYAxis
			? { left: finalOffset[1], top: alongCenterOffset, bottom: alongCenterOffset }
			: { top: finalOffset[1], left: alongCenterOffset, right: alongCenterOffset };
		return [
			...constantModifiers,
			{
				name: 'preventOverflow',
				options: {
					mainAxis: true,
					altAxis: true,
					padding,
					altBoundary: true,
					rootBoundary: 'viewport',
					tether: false,
				},
			},
			{
				name: 'eventListeners',
				options: {
					// Only recompute position on scroll/resize events while the button is focused to reduce CPU load
					scroll: isFocused,
					resize: isFocused,
				},
			},
		];
	}, [alignToYAxis, buttonSize, finalOffset, isFocused]);

	// Ensure a stable ref function is passed through to the trigger button
	const memoizedMergeRefs = useMemo(() => memoizeOne(mergeRefs), []);

	const isVisuallyHidden = !isVisible && !isFocused;

	return (
		<Manager>
			<Reference>
				{({ ref }) => <Box xcss={cx(styles.reference, styles.visuallyHidden)} ref={ref} />}
			</Reference>
			<Popper
				placement={alignToYAxis ? 'right' : 'bottom'}
				modifiers={modifiers}
				offset={finalOffset}
				messageId="native-issue-table.common.ui.row-popup-button.popper"
				messageType="transactional"
			>
				{({ ref, style, update }) => {
					updateRef.current = update;
					return (
						<Tooltip content={tooltipContent} canAppear={() => tooltipContent != null}>
							{(triggerProps) => (
								<ConditionalWrapper
									condition={withSpotlight && fg('jira_inline_field_config_gate')}
									renderWrapper={(children) => (
										<SpotlightTarget name="inline-field-create-onboarding-inline-column-create-button">
											{children}
										</SpotlightTarget>
									)}
								>
									<Pressable
										{...triggerProps}
										aria-label={label}
										testId={`native-issue-table.common.ui.row-popup-button.pressable${isVisuallyHidden ? '-hidden' : ''}`}
										xcss={cx(
											buttonStyles.root,
											buttonStyles.default,
											// See https://atlassian.design/components/css/overview#building-reusable-components
											// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
											xcss,
											isVisuallyHidden && styles.visuallyHidden,
										)}
										onClick={(e, analyticEvent) => {
											onClick?.(e, analyticEvent);
											triggerProps.onClick(e);
										}}
										onFocus={(e) => {
											setIsFocused(true);
											triggerProps.onFocus(e);
										}}
										onBlur={(e) => {
											setIsFocused(false);
											triggerProps.onBlur(e);
										}}
										onMouseMove={(e) => {
											// Stop propagation so the button isn't inadvertently hidden when hovering
											e.stopPropagation();
											triggerProps.onMouseMove?.(e);
										}}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										ref={memoizedMergeRefs(ref, triggerProps.ref, forwardedRef)}
										// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
										style={style}
									>
										{icon}
									</Pressable>
								</ConditionalWrapper>
							)}
						</Tooltip>
					);
				}}
			</Popper>
		</Manager>
	);
});
