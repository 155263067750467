// Adapted from from '@atlassian/jira-issue-table/src/common/fields/date';
import React, { memo } from 'react';
import { parseISO } from 'date-fns';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { FormattedDate } from '@atlassian/jira-intl';
import { DateInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-date-inline-edit-full/src/ui/date/index.tsx';
import type { date_nativeIssueTable_DateCell$key } from '@atlassian/jira-relay/src/__generated__/date_nativeIssueTable_DateCell.graphql';
import type { date_nativeIssueTable_DateInlineEditCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/date_nativeIssueTable_DateInlineEditCell_fieldRef.graphql';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { KNOWN_COLUMN_TYPES } from '../../../constants.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';

const parseDate = (dateString: string | null): Date | null => {
	if (dateString !== null && dateString !== '') {
		const date = parseISO(dateString);
		if (date.toString() !== 'Invalid Date') {
			return date;
		}
	}
	return null;
};

const formattedDate = (dateString: string | null) => {
	const date = parseDate(dateString);
	if (!date) {
		return null;
	}
	return <FormattedDate value={date} year="numeric" month="short" day="numeric" />;
};

export type Props = {
	fieldRef: date_nativeIssueTable_DateCell$key;
	isRequired?: boolean;
};

export const DateCell = memo<Props>(({ fieldRef, isRequired }: Props) => {
	const data = useFragment<date_nativeIssueTable_DateCell$key>(
		/* eslint-disable @atlassian/relay/graphql-naming */
		graphql`
			fragment date_nativeIssueTable_DateCell on JiraIssueField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				__typename
				fieldId
				... on JiraDatePickerField {
					date
					...date_nativeIssueTable_DateInlineEditCell_fieldRef
						@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
				}
				... on JiraDateTimePickerField {
					dateTime
				}
			}
		`,
		fieldRef,
	);

	if (data.__typename === 'JiraDatePickerField' && data.fieldId === KNOWN_COLUMN_TYPES.DUE_DATE) {
		return <DateInlineEditCell fieldRef={data} />;
	}

	let date: string | null = null;
	if (data.__typename === 'JiraDatePickerField') {
		date = data.date ?? null;
	} else if (data.__typename === 'JiraDateTimePickerField') {
		date = data.dateTime ?? null;
	}

	if (isRequired && date == null) {
		return <ErrorCell cellType="date" reason="Missing required fields" />;
	}

	return <Box xcss={wrapperStyles}>{formattedDate(date)}</Box>;
});

type DateInlineEditCellProps = {
	fieldRef: date_nativeIssueTable_DateInlineEditCell_fieldRef$key;
};

export const DateInlineEditCell = ({ fieldRef }: DateInlineEditCellProps) => {
	const data = useFragment<date_nativeIssueTable_DateInlineEditCell_fieldRef$key>(
		graphql`
			fragment date_nativeIssueTable_DateInlineEditCell_fieldRef on JiraDatePickerField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<DateInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
		/>
	);
};

const wrapperStyles = xcss({
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 2,
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
