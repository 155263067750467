/**
 * @generated SignedSource<<1c735683ae8c3dfc9825b7e9760388a3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type resolution_nativeIssueTable_ResolutionCell$data = {
  readonly resolution: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "resolution_nativeIssueTable_ResolutionCell";
};
export type resolution_nativeIssueTable_ResolutionCell$key = {
  readonly " $data"?: resolution_nativeIssueTable_ResolutionCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"resolution_nativeIssueTable_ResolutionCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "resolution_nativeIssueTable_ResolutionCell",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraResolutionField"
};

(node as any).hash = "f08df2df05baaf8922c9554fb39bd5f7";

export default node;
