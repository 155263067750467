import type { childRowsLoaderQuery$variables } from '@atlassian/jira-relay/src/__generated__/childRowsLoaderQuery.graphql';

export interface Column {
	id: string;
}

interface BaseProps<TColumn extends Column> {
	cloudId: string;
	columns: TColumn[];
	isReparentingEnabled: boolean;
	actionColumns: string[];
	amountOfColumns: number;
	pageSize: number;
}

interface ChildQueryForJqlProps<TColumn extends Column> extends BaseProps<TColumn> {
	jql: string;
}

const getProjectKeyFromIssueKey = (issueKey: string): string => {
	return issueKey.split('-')[0];
};

const getChildQueryVariablesBase = <TColumn extends Column>({
	cloudId,
	columns,
	isReparentingEnabled,
	actionColumns,
	amountOfColumns,
	pageSize,
}: BaseProps<TColumn>) => {
	const fieldSetIds = columns
		.filter((column: TColumn) => !!column.id && !actionColumns.includes(column.id))
		.map((column: TColumn) => column.id)
		.sort();

	return {
		cloudId,
		amountOfColumns,
		fieldSetsInput: {
			fieldSetIds,
		},
		fieldSetIds,
		first: pageSize,
		namespace: '',
		shouldQueryFieldSetsById: true,
		filterId: null,
		viewId: null,
		isReparentingEnabled,
	};
};

export const getChildQueryVariablesForJql = <TColumn extends Column>({
	cloudId,
	columns,
	jql,
	isReparentingEnabled,
	actionColumns,
	amountOfColumns,
	pageSize,
}: ChildQueryForJqlProps<TColumn>) => {
	return {
		...getChildQueryVariablesBase({
			cloudId,
			columns,
			isReparentingEnabled,
			actionColumns,
			amountOfColumns,
			pageSize,
		}),
		issueSearchInput: { jql },
		shouldQueryHasChildren: false,
	};
};

interface ChildQueryForParentIssueProps<TColumn extends Column, TSortOrder>
	extends BaseProps<TColumn> {
	parentIssueKey: string;
	sortField?: string;
	sortDirection?: TSortOrder;
	jql?: string;
	isReparentingEnabled: boolean;
	isHideDoneItemsEnabled: boolean;
}

export const getChildQueryVariablesForParentIssue = <TColumn extends Column, TSortOrder>({
	cloudId,
	columns,
	parentIssueKey,
	jql,
	isReparentingEnabled,
	isHideDoneItemsEnabled,
	actionColumns,
	amountOfColumns,
	pageSize,
}: ChildQueryForParentIssueProps<TColumn, TSortOrder>): childRowsLoaderQuery$variables => {
	const projectKey = getProjectKeyFromIssueKey(parentIssueKey);

	return {
		...getChildQueryVariablesBase({
			cloudId,
			columns,
			isReparentingEnabled,
			actionColumns,
			amountOfColumns,
			pageSize,
		}),
		issueSearchInput: {
			childIssuesInput: {
				parentIssueKey,
				filterByProjectKeys: [projectKey],
				...(jql
					? {
							jql,
						}
					: {}),
				...(isHideDoneItemsEnabled && { namespace: 'ISSUE_NAVIGATOR' }),
			},
		},
		projectKeys: [projectKey],
		projectKey,
		viewConfigInput: {
			staticViewInput: {
				isHierarchyEnabled: true,
				isGroupingEnabled: false,
				isHideDoneEnabled: isHideDoneItemsEnabled,
			},
		},
		shouldQueryHasChildren: true,
	};
};
