import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadingMessage: {
		id: 'native-issue-table.scroll-container.loading-manager.loading-indicator.loading-message',
		defaultMessage: 'Loading more issues',
		description: 'Text displayed in the loading indicator when more table rows are being loaded',
	},
	loadingMessageIssueTermRefresh: {
		id: 'native-issue-table.scroll-container.loading-manager.loading-indicator.loading-message-issue-term-refresh',
		defaultMessage: 'Loading more',
		description: 'Text displayed in the loading indicator when more table rows are being loaded',
	},
	loadingMessageWithGrouping: {
		id: 'native-issue-table.scroll-container.loading-manager.loading-indicator.loading-message-with-grouping',
		defaultMessage: 'Loading more groups',
		description: 'Text displayed in the loading indicator when more table groups are being loaded',
	},
});
