/**
 * @generated SignedSource<<d9871456a828734b8a317391b1436e2c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type statusGroupHeader_nativeIssueTable$data = {
  readonly displayName: string;
  readonly statusCategory: {
    readonly statusCategoryId: string;
  };
  readonly " $fragmentType": "statusGroupHeader_nativeIssueTable";
};
export type statusGroupHeader_nativeIssueTable$key = {
  readonly " $data"?: statusGroupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"statusGroupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "statusGroupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "displayName"
    },
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "statusCategoryId"
        }
      ]
    }
  ],
  "type": "JiraJqlStatusFieldValue"
};

(node as any).hash = "794bdfeefaa5bf1f9a0469c28c84d94d";

export default node;
