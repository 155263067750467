import React, { forwardRef } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { IconButton, type IconButtonProps, type IconProp } from '@atlaskit/button/new';

import AddIcon from '@atlaskit/icon/core/add';
import BoardIcon from '@atlaskit/icon/core/board';
import PreferencesIcon from '@atlaskit/icon/core/migration/customize--preferences';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type Props = {
	isLoading?: boolean;
	isInBetweenColumnPickerEnabled?: boolean;
	isSelected?: boolean;
	customIcon?: IconProp;
	onClick?: ButtonProps['onClick'];
};

const PickerButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			onClick,
			isLoading = false,
			isSelected = false,
			customIcon,
			isInBetweenColumnPickerEnabled,
		}: Props,
		ref,
	) => {
		const { formatMessage } = useIntl();

		const iconColor = isSelected ? token('color.icon.selected') : token('color.icon');

		const tooltipContent = formatMessage(
			isInBetweenColumnPickerEnabled && fg('jira_inline_field_config_gate')
				? messages.addColumnTooltipText
				: messages.tooltipText,
		);
		const tooltipOptions: IconButtonProps['tooltip'] = {
			position: 'bottom',
			content: tooltipContent,
			delay: 0,
		};

		const isDisabled = fg('issue-jiv-19990-disable-selection-during-loading')
			? !isSelected && isLoading
			: isLoading;
		const icon = customIcon ?? (fg('jira_inline_field_config_gate') ? BoardIcon : AddIcon);

		return isVisualRefreshEnabled() ? (
			<IconButton
				icon={icon}
				testId="issue-table-column-picker.ui.column-picker.picker-button.picker-button"
				label={tooltipContent}
				appearance="subtle"
				isDisabled={isDisabled}
				onClick={onClick}
				ref={ref}
				aria-haspopup="true"
				aria-expanded={isSelected}
				isSelected={isSelected}
				tooltip={tooltipOptions}
				isTooltipDisabled={false}
			/>
		) : (
			<Tooltip position="bottom" content={tooltipContent} delay={0}>
				<Button
					appearance="subtle"
					testId="issue-table-column-picker.ui.column-picker.picker-button.picker-button"
					aria-label={tooltipContent}
					iconBefore={
						<PreferencesIcon label="" spacing="spacious" LEGACY_size="medium" color={iconColor} />
					}
					isDisabled={isDisabled}
					onClick={onClick}
					ref={ref}
					aria-haspopup="true"
					aria-expanded={isSelected}
					isSelected={isSelected}
				/>
			</Tooltip>
		);
	},
);

export const PickerButtonFallback = ({
	customIcon,
	isInBetweenColumnPickerEnabled,
}: {
	customIcon?: IconProp;
	isInBetweenColumnPickerEnabled?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const tooltipContent = formatMessage(
		isInBetweenColumnPickerEnabled && fg('jira_inline_field_config_gate')
			? messages.addColumnTooltipText
			: messages.tooltipText,
	);
	const icon = customIcon ?? (fg('jira_inline_field_config_gate') ? BoardIcon : AddIcon);

	return isVisualRefreshEnabled() ? (
		<IconButton icon={icon} label={tooltipContent} appearance="subtle" isDisabled />
	) : (
		<Button
			appearance="subtle"
			aria-label={formatMessage(messages.tooltipText)}
			iconBefore={<PreferencesIcon color="currentColor" label="" LEGACY_size="medium" />}
			isDisabled
		/>
	);
};

export default PickerButton;
