import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useRegisterConnection } from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import type { rootConnectionRegister_nativeIssueTable_issues$key } from '@atlassian/jira-relay/src/__generated__/rootConnectionRegister_nativeIssueTable_issues.graphql.ts';

export const RootConnectionRegister = ({
	issues,
}: {
	issues?: rootConnectionRegister_nativeIssueTable_issues$key | null;
}) => {
	const issueConnection = useFragment(
		graphql`
			fragment rootConnectionRegister_nativeIssueTable_issues on JiraIssueConnection {
				__id
				edges {
					__typename
				}
			}
		`,
		issues ?? null,
	);

	const rootConnectionDetails = useMemo(() => {
		if (issueConnection && issueConnection.__id) {
			return {
				type: 'ROOT_ISSUES' as const,
				connectionId: issueConnection.__id,
				first: issueConnection?.edges?.length ?? 0,
			};
		}

		return undefined;
	}, [issueConnection]);

	useRegisterConnection(rootConnectionDetails);

	return null;
};
