import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnHeaderIssueType: {
		id: 'native-issue-table.common.utils.get-column-config.column-header-issue-type',
		defaultMessage: 'Type',
		description: 'Column header for issue type',
	},
	aToZOrderOld: {
		id: 'native-issue-table.common.utils.get-column-config.a-to-z-order-old',
		defaultMessage: 'A ➞ Z',
		description:
			'Ascending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	zToAOrderOld: {
		id: 'native-issue-table.common.utils.get-column-config.z-to-a-order-old',
		defaultMessage: 'Z ➞ A',
		description:
			'Descending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	oldestToNewestOrderOld: {
		id: 'native-issue-table.common.utils.get-column-config.oldest-to-newest-order-old',
		defaultMessage: 'oldest ➞ newest',
		description:
			'Ascending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	newestToOldestOrderOld: {
		id: 'native-issue-table.common.utils.get-column-config.newest-to-oldest-order-old',
		defaultMessage: 'newest ➞ oldest',
		description:
			'Descending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	deprecatedEpicLinkMessage: {
		id: 'native-issue-table.common.utils.get-column-config.deprecated-epic-link-message',
		defaultMessage: '<strong>Epic Link</strong> is replaced by <strong>Parent</strong>.',
		description: 'Warning message for Epic Link deprecation',
	},
	aToZOrder: {
		id: 'native-issue-table.common.utils.get-column-config.a-to-z-order',
		defaultMessage: 'A → Z',
		description:
			'Ascending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	zToAOrder: {
		id: 'native-issue-table.common.utils.get-column-config.z-to-a-order',
		defaultMessage: 'Z → A',
		description:
			'Descending order used by fields which contain text or names, e.g.: assignee, reporter, summary, issue type, etc.',
	},
	oldestToNewestOrder: {
		id: 'native-issue-table.common.utils.get-column-config.oldest-to-newest-order',
		defaultMessage: 'oldest → newest',
		description:
			'Ascending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
	newestToOldestOrder: {
		id: 'native-issue-table.common.utils.get-column-config.newest-to-oldest-order',
		defaultMessage: 'newest → oldest',
		description:
			'Descending order used by datetime fields which represent how recent a ticket is updated or created.',
	},
});
