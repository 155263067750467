import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { MultiUserPickerInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-multi-user-picker-inline-edit-full/src/ui/multi-user-picker/index.tsx';
import type { multiUserPicker_nativeIssueTable_MultiUserPickerCell$key } from '@atlassian/jira-relay/src/__generated__/multiUserPicker_nativeIssueTable_MultiUserPickerCell.graphql';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: multiUserPicker_nativeIssueTable_MultiUserPickerCell$key;
};

export const MultiUserPickerCell = ({ fieldRef }: Props) => {
	const data = useFragment<multiUserPicker_nativeIssueTable_MultiUserPickerCell$key>(
		graphql`
			fragment multiUserPicker_nativeIssueTable_MultiUserPickerCell on JiraMultipleSelectUserPickerField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<MultiUserPickerInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
		/>
	);
};
