import React, { useCallback, type ComponentType } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useInlineCreateHandlers } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import InlineCreateForm, {
	type Props as InlineCreateFormProps,
} from '@atlassian/jira-issue-table-inline-issue-create/src/ui/inline-create-form/index.tsx';
import InlineCreateTrigger from '@atlassian/jira-issue-table-inline-issue-create/src/ui/inline-create-trigger/index.tsx';
import type { issueCreateFooter_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/issueCreateFooter_nativeIssueTable.graphql';
import type { BorderWidth, FooterProps } from '../../../common/types.tsx';
import { Footer as DefaultFooter } from '../../../common/ui/components/footer/index.tsx';
import { useProjectContext } from '../../../controllers/features/selectors.tsx';
import { useCalculateIssueInsertionPoint } from '../../../controllers/table-items-intersection-observer/index.tsx';
import { useIssueCreateMutation } from '../../../services/issue-create-mutation/index.tsx';

export type Props = FooterProps & {
	Footer?: ComponentType<FooterProps>;
	issues: issueCreateFooter_nativeIssueTable$key;
};

type FormWithInsertionPointProps = Omit<InlineCreateFormProps, 'onCreateIssue'> & {
	connectionId: string;
};

const FormWithInsertionPoint = ({ connectionId, ...props }: FormWithInsertionPointProps) => {
	const insertionPoint = useCalculateIssueInsertionPoint();

	const handleIssueCreate = useIssueCreateMutation(connectionId);

	const handleIssueCreateWithInsertionPoint = useCallback<InlineCreateFormProps['onCreateIssue']>(
		(issueToCreate, contextualFields) => {
			if (insertionPoint == null) {
				return handleIssueCreate(issueToCreate, contextualFields);
			}

			return handleIssueCreate(issueToCreate, contextualFields, {
				insertAfter: insertionPoint.position === 'below',
				insertNodeId: insertionPoint.issueId,
			});
		},
		[handleIssueCreate, insertionPoint],
	);
	return (
		<Box xcss={formContainerStyles}>
			<InlineCreateForm onCreateIssue={handleIssueCreateWithInsertionPoint} {...props} />
		</Box>
	);
};

export const IssueCreateFooter = ({ Footer = DefaultFooter, issues, ...footerProps }: Props) => {
	const issueData = useFragment<issueCreateFooter_nativeIssueTable$key>(
		graphql`
			fragment issueCreateFooter_nativeIssueTable on JiraIssueConnection {
				__id
			}
		`,
		issues,
	);

	const projectContext = useProjectContext();
	const { isFormVisible, formProps, triggerProps } = useInlineCreateHandlers({
		projectKey: projectContext,
		minHierarchyLevel: 0,
	});

	if (isFormVisible) {
		return (
			<FormWithInsertionPoint
				connectionId={issueData?.__id}
				{...formProps}
				appearance="footer"
				depth={0}
			/>
		);
	}

	return (
		<Footer
			{...footerProps}
			start={
				<Box xcss={triggerContainerStyles}>
					<InlineCreateTrigger {...triggerProps} />
				</Box>
			}
		/>
	);
};

const BORDER_WIDTH: BorderWidth = '1px';
const formContainerStyles = xcss({
	backgroundColor: 'elevation.surface',
	boxShadow: 'elevation.shadow.overflow',
	borderTopWidth: BORDER_WIDTH,
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
});

const triggerContainerStyles = xcss({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	minHeight: '32px',
});
