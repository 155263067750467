/**
 * @generated SignedSource<<296609b60b4a4daf51b5572d7c0ce9ff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_RowInner_fieldSets$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
  readonly " $fragmentType": "row_nativeIssueTable_RowInner_fieldSets";
};
export type row_nativeIssueTable_RowInner_fieldSets$key = {
  readonly " $data"?: row_nativeIssueTable_RowInner_fieldSets$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_fieldSets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_RowInner_fieldSets",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets"
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};

(node as any).hash = "2d2a16ac06342f4e00560f32f837ce90";

export default node;
