import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import messages from './messages.tsx';

export const UPDATE_ITEM_PARENT_FAIL_GENERIC_FLAG: FlagConfiguration = {
	type: 'error',
	title: messages.updateIssueParentErrorTitle,
	description: messages.updateIssueParentGenericErrorDescription,
	messageId:
		'native-issue-table.services.reparent-mutation.update-item-parent-fail-generic-flag.error.flag.update-item-parent-fail-generic',
	messageType: 'transactional',
};

export const UPDATE_ITEM_PARENT_FAIL_400_FLAG: FlagConfiguration = {
	type: 'error',
	title: messages.updateIssueParentErrorTitle,
	description: messages.updateIssueParent400ErrorDescription,
	messageId:
		'native-issue-table.services.reparent-mutation.update-item-parent-fail-400-flag.error.flag.update-issue-parent400error',
	messageType: 'transactional',
};

export const UPDATE_ITEM_PARENT_FAIL_400_FLAG_WITH_ISSUE_TERM: FlagConfiguration = {
	type: 'error',
	title: messages.updateIssueParentErrorTitle,
	description: messages.updateIssueParent400ErrorDescriptionIssueTermRefresh,
	messageId:
		'native-issue-table.services.reparent-mutation.update-item-parent-fail-400-flag-with-issue-term.error.flag.update-issue-parent400error-issue-term-refresh',
	messageType: 'transactional',
};

export const UPDATE_ITEM_PARENT_FAIL_404_FLAG: FlagConfiguration = {
	type: 'error',
	title: messages.updateIssueParentErrorTitle,
	description: messages.updateIssueParent404ErrorDescription,
	messageId:
		'native-issue-table.services.reparent-mutation.update-item-parent-fail-404-flag.error.flag.update-issue-parent404error',
	messageType: 'transactional',
};

export const UPDATE_ITEM_PARENT_FAIL_404_FLAG_WITH_ISSUE_TERM: FlagConfiguration = {
	type: 'error',
	title: messages.updateIssueParentErrorTitle,
	description: messages.updateIssueParent404ErrorDescriptionIssueTermRefresh,
	messageId:
		'native-issue-table.services.reparent-mutation.update-item-parent-fail-404-flag-with-issue-term.error.flag.update-issue-parent404error-issue-term-refresh',
	messageType: 'transactional',
};

export const REPARENTING_EXPERIENCE = 'updateParent';
export const SUCCEEDED = 'SUCCEEDED';
export const FAILED = 'FAILED';
export const ERROR_ID = 'useUpdateParent';
export const ACTION_SUBJECT_ID = 'issueTableUpdateIssueParent';
