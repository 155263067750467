/**
 * @generated SignedSource<<ab557e702f4181aa0c25ff386cecf1ff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView$data = {
  readonly selectedOption: {
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView";
};
export type radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView$key = {
  readonly " $data"?: radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView",
  "selections": [
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "selectedOption",
      "plural": false,
      "selections": [
        {
          "alias": "label",
          "kind": "ScalarField",
          "name": "value"
        }
      ]
    }
  ],
  "type": "JiraRadioSelectField"
};

(node as any).hash = "b7b019c3c9e9a0bdd2c7ccf008f19f38";

export default node;
