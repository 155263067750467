import { useCallback } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import { TRIGGER_POINT_KEY_NOT_DEFINED } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useOpenIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { ContextualFields, IssueToCreate } from '../../common/types.tsx';

type OnFallbackToGIC = (args: {
	issueToCreate: IssueToCreate;
	contextualFields: ContextualFields;
}) => Promise<string | null>;

type GICCustomFieldValue = {
	[key: string]: string;
};
type GICAdditionalFields = {
	[key: string]: GICCustomFieldValue;
};

const toGICAdditionalFields = ({
	rankFieldId,
	rankBeforeIssueId,
	rankAfterIssueId,
}: ContextualFields): GICAdditionalFields => {
	const additionalFields: GICAdditionalFields = {};
	if (rankFieldId && (rankBeforeIssueId || rankAfterIssueId)) {
		additionalFields[rankFieldId] = {
			...(rankBeforeIssueId ? { rankBeforeIssue: rankBeforeIssueId } : {}),
			...(rankAfterIssueId ? { rankAfterIssue: rankAfterIssueId } : {}),
		};
	}
	return additionalFields;
};

const issueIdFromAri = (ari: string): string => JiraIssueAri.parse(ari).issueId;

/**
 * Open the global issue create modal prefilled with field values provided during inline issue create. This returns a
 * promise that will resolve with the issue key of the newly created issue, or `null` if the modal was dismissed.
 */

export const useFallbackToGIC = (): OnFallbackToGIC => {
	const openIssueCreateModal = useOpenIssueCreateModal();
	const { subscribe } = useGICLifeCycleEvents();
	return useCallback<OnFallbackToGIC>(
		({ issueToCreate, contextualFields }) => {
			const {
				project: { projectId },
				issueType: { issueTypeId },
				summary,
			} = issueToCreate;
			const payload = {
				project: {
					projectId,
				},
				issueType: {
					issueTypeId,
				},

				// eslint-disable-next-line jira/ff/no-preconditioning
				...(contextualFields.parentIssueAri &&
				expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
				fg('jsc_m2_hierarchy_fe_changes')
					? {
							parentId: {
								parentId: issueIdFromAri(contextualFields.parentIssueAri),
							},
						}
					: {}),
				defaultValues: {
					singleLineTextFields: [
						{
							fieldId: 'summary',
							text: summary,
						},
					],

					// eslint-disable-next-line jira/ff/no-preconditioning
					...(contextualFields.parentIssueAri &&
					expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
					fg('jsc_m2_hierarchy_fe_changes')
						? {
								parentField: {
									issueId: issueIdFromAri(contextualFields.parentIssueAri),
								},
							}
						: {}),
				},
			};

			return new Promise((resolve) => {
				const unsubscribe = subscribe(
					GIC_LIFECYCLE_EVENTS.MODAL_KEEP_EDITING_EXISTING_ISSUE,
					() => {
						// the user cancelled editing
						onClose(null);
					},
				);

				// resolve the promise once the GIC has been closed or cancelled
				const onClose = (data: CreatedIssueWithIssueData[] | null | undefined) => {
					const result = data?.[0]?.issueKey ?? null;
					resolve(result);
					unsubscribe();
				};

				const isSubTaskCreationOpen = !!(
					issueToCreate.issueType?.hierarchy?.level === -1 && fg('jsc_m2_hierarchy_fe_changes')
				);

				openIssueCreateModal({
					payload,
					context: {
						projectId,
						submitApiData: {
							additionalFields: toGICAdditionalFields(contextualFields),
						},
					},
					onClose,
					isSubTaskCreationOpen,
					triggerPointKey: TRIGGER_POINT_KEY_NOT_DEFINED, // TODO Define appropriate trigger point
					disableProjectDropdown: true,
					disableIssueTypeDropdown: false,
					disableCreationSuccessFlag: true,
					minimizableModalView: 'mini',
				});
			});
		},
		[openIssueCreateModal, subscribe],
	);
};
