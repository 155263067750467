/**
 * @generated SignedSource<<8342c4e836b7ec8b7c2b0ddfa6134252>>
 * @relayHash d19489d06d268f2aa2afd983864117f6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 221728ae5b3c6aa07ca8d90d92a49ae0c66a238ebcf155f78b4ee1fc1d21e007

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateMultipleVersionPickerFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraMultipleVersionPickerFieldOperationInput>;
};
export type JiraMultipleVersionPickerFieldOperationInput = {
  ids: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation$variables = {
  input: JiraUpdateMultipleVersionPickerFieldInput;
};
export type multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation$data = {
  readonly jira: {
    readonly updateMultipleVersionPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateMultipleVersionPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly selectedVersionsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly label: string | null | undefined;
              readonly name: string | null | undefined;
              readonly value: string;
              readonly versionId: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation = {
  rawResponse: multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation$rawResponse;
  response: multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation$data;
  variables: multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraMultipleVersionPickerFieldPayload",
            "kind": "LinkedField",
            "name": "updateMultipleVersionPickerField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraMultipleVersionPickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraMultipleVersionPickerFieldPayload",
            "kind": "LinkedField",
            "name": "updateMultipleVersionPickerField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraMultipleVersionPickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1000
                      }
                    ],
                    "concreteType": "JiraVersionConnection",
                    "kind": "LinkedField",
                    "name": "selectedVersionsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": "value",
                                "kind": "ScalarField",
                                "name": "id"
                              },
                              {
                                "alias": "label",
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "versionId"
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "storageKey": "selectedVersionsConnection(first:1000)"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "221728ae5b3c6aa07ca8d90d92a49ae0c66a238ebcf155f78b4ee1fc1d21e007",
    "metadata": {},
    "name": "multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c18481704920c57572e95641cfc0676b";

export default node;
