import React, { useCallback, useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

import type {
	ContextualFields,
	IssueToCreate,
} from '@atlassian/jira-issue-table-inline-issue-create/src/common/types.tsx';
import InlineCreateForm, {
	type Props as InlineCreateFormProps,
} from '@atlassian/jira-issue-table-inline-issue-create/src/ui/inline-create-form/index.tsx';
import { useIsIssueRankDescending } from '../../../../controllers/features/selectors.tsx';
import { useScrollStateSelector } from '../../../../controllers/scroll-state/index.tsx';
import { useIssueCreateMutation } from '../../../../services/issue-create-mutation/index.tsx';

export type Props = Omit<InlineCreateFormProps, 'onCreateIssue'> & {
	itemsConnectionId: string;
	parentIssueAri: string;
	siblingIssueId?: string;
	parentItemConnectionId: string;
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/child-issue-create-row/issue-create-cell/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const IssueCreateCell = ({
	itemsConnectionId,
	parentIssueAri,
	siblingIssueId,
	parentItemConnectionId,
	depth,
	...props
}: Props) => {
	const width = useScrollStateSelector((scrollState) => scrollState.width);
	const isIssueRankDescending = useIsIssueRankDescending();

	const formContextualFields = useMemo(
		() =>
			isIssueRankDescending
				? { rankAfterIssueId: siblingIssueId }
				: { rankBeforeIssueId: siblingIssueId },
		[isIssueRankDescending, siblingIssueId],
	);

	const onCreateIssue = useIssueCreateMutation(itemsConnectionId, parentItemConnectionId);
	const handleIssueCreate = useCallback(
		(issueToCreate: IssueToCreate, contextualFields: ContextualFields) => {
			onCreateIssue(
				issueToCreate,
				{
					...contextualFields,
					parentIssueAri,
				},
				{
					insertAfter: false,
					insertNodeId: siblingIssueId,
				},
			);
		},
		[onCreateIssue, parentIssueAri, siblingIssueId],
	);

	return (
		<Box as="td" xcss={cellStyles}>
			{/* eslint-disable-next-line jira/react/no-style-attribute */}
			<Box xcss={boxStyles} style={{ width: `${width}px` }}>
				<InlineCreateForm
					contextualFields={formContextualFields}
					onCreateIssue={handleIssueCreate}
					depth={depth + 1}
					{...props}
				/>
			</Box>
		</Box>
	);
};

const cellStyles = xcss({
	height: '40px',
	left: 'space.0',
	position: 'sticky',
	zIndex: 'card',
});

const boxStyles = xcss({
	left: 'space.0',
	position: 'absolute',
	top: 'space.0',
});
