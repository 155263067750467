import { useCallback } from 'react';
import { commitLocalUpdate, fetchQuery, graphql } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	utils_realtimeUpdater_issueListDetails_Query as IssuesListDetails,
	utils_realtimeUpdater_issueListDetails_Query$data as IssuesListDetailsResponse,
	JiraIssueExpandedGroup,
	JiraIssueExpandedParent,
} from '@atlassian/jira-relay/src/__generated__/utils_realtimeUpdater_issueListDetails_Query.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useConnectionsList,
	type ConnectionDetails,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import {
	useIsDensityFull,
	useIsInlineEditingEnabled,
} from '../../controllers/features/selectors.tsx';
import { useIssueFetchQueryVariables } from '../../controllers/issue-fetch-query-variables/index.tsx';
import { useIssueRealtimeUpdater } from '../../controllers/issue-realtime-updater/index.tsx';
import { useGetGroupsForIssues } from '../../services/groups-for-issues/index.tsx';

type SearchViewContextInputType = IssuesListDetails['variables']['searchViewContextInput'];
type IssueSearchInputType = IssuesListDetails['variables']['issueSearchInput'];

export const generateSearchViewContextInput = ({
	connections,
	isGroupingEnabled,
	groupedByFieldId,
}: {
	connections: Record<string, ConnectionDetails>;
	isGroupingEnabled: boolean;
	groupedByFieldId?: string | null;
}): SearchViewContextInputType => {
	const expandedParents: JiraIssueExpandedParent[] = [];
	const groups: JiraIssueExpandedGroup[] = [];

	Object.values(connections).forEach((connection) => {
		if (connection.type === 'PARENT_CHILDREN') {
			expandedParents.push({ parentIssueId: connection.parentId, first: connection.first });
		} else if (connection.type === 'GROUP_CHILDREN') {
			groups.push({
				jql: connection.jql,
				fieldValue: connection.fieldValue,
				first: connection.first,
			});
		}
	});

	if (isGroupingEnabled && groupedByFieldId) {
		return { expandedGroups: { groupedByFieldId, groups } };
	}

	return { expandedParents };
};

export const fetchIssueListDetails = async ({
	keys,
	cloudId,
	fieldSetIds,
	isInlineEditingEnabled,
	isDensityFull,
	isHierarchyEnabled,
	isGroupingEnabled,
	issueSearchInput,
	searchViewContextInput,
	projectKey,
}: {
	keys: string[];
	cloudId: string;
	fieldSetIds: string[];
	isInlineEditingEnabled: boolean;
	isDensityFull: boolean;
	isHierarchyEnabled: boolean | undefined;
	isGroupingEnabled: boolean;
	issueSearchInput: IssueSearchInputType;
	searchViewContextInput: SearchViewContextInputType;
	projectKey: string;
}): Promise<IssuesListDetailsResponse | undefined> => {
	const environment = getRelayEnvironment();

	try {
		// if the issue is in a loaded context, the issue is visible so we need to fetch the fields for display
		return await fetchQuery<IssuesListDetails>(
			environment,
			graphql`
				query utils_realtimeUpdater_issueListDetails_Query(
					$cloudId: ID!
					$keys: [String!]!
					$fieldSetIds: [String!]!
					$isInlineEditingEnabled: Boolean!
					$isDensityFull: Boolean!
					$isHierarchyEnabled: Boolean!
					$isGroupingEnabled: Boolean
					$issueSearchInput: JiraIssueSearchInput!
					$searchViewContextInput: JiraIssueSearchViewContextInput!
					$projectKey: String!
				) {
					jira {
						# This is a workaround for the subscription not currently being ready for use. In the future this query will be copied
						# as is there, so all the extra fields being requested here are used in the controller but not necessarily this file
						# eslint-disable-next-line @atlassian/relay/unused-fields
						issuesByKey(cloudId: $cloudId, keys: $keys) {
							__id
							issueId
							key
							parentIssueField {
								parentIssue {
									__id
								}
							}
							issueTypeField @include(if: $isHierarchyEnabled) {
								id
								issueType {
									hierarchy {
										level
									}
								}
							}
							fieldSetsById(fieldSetIds: $fieldSetIds, first: 500) {
								__id
								# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
								...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
									@arguments(
										isInlineEditingEnabled: $isInlineEditingEnabled
										isDensityFull: $isDensityFull
									)
							}
							canHaveChildIssues(projectKey: $projectKey) @include(if: $isHierarchyEnabled)
							searchViewContext(
								isHierarchyEnabled: $isHierarchyEnabled
								isGroupingEnabled: $isGroupingEnabled
								issueSearchInput: $issueSearchInput
								searchViewContextInput: $searchViewContextInput
							) @optIn(to: "JiraListComponent-M1.2") {
								contexts {
									afterIssueId
									beforeIssueId
									position
									... on JiraIssueSearchViewContextMappingByParent {
										parentIssueId
									}
									... on JiraIssueSearchViewContextMappingByGroup {
										jql
									}
								}
							}
						}
					}
				}
			`,
			{
				keys,
				cloudId,
				fieldSetIds,
				isInlineEditingEnabled,
				isDensityFull,
				issueSearchInput,
				searchViewContextInput,
				isHierarchyEnabled: isHierarchyEnabled ?? false,
				isGroupingEnabled,
				projectKey,
			},
			{ fetchPolicy: 'network-only' },
		).toPromise();
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'realTimeFetchIssueFieldSetConnection',
				packageName: PACKAGE_NAME,
				teamName: TEAM_NAME,
			},
			error: error instanceof Error ? error : undefined,
			sendToPrivacyUnsafeSplunk: true,
		});

		return Promise.resolve(undefined);
	}
};

export const useRealtimeUpdatesForIssues = () => {
	const environment = getRelayEnvironment();
	const cloudId = useCloudId();
	const { fieldSetIds } = useIssueFetchQueryVariables();
	const isDensityFull = useIsDensityFull();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	const { connections } = useConnectionsList();
	const getGroupsForIssues = useGetGroupsForIssues();

	const { issuePositionUpdater, groupsForIssueUpdater } = useIssueRealtimeUpdater();

	return useCallback(
		async ({
			keys,
			jql,
			groupByFieldId,
			numGroupsLoaded,
			groupConnectionId,
			isHierarchyEnabled,
			projectKey,
		}: {
			keys: string[];
			jql: string;
			groupByFieldId: string | undefined;
			groupConnectionId: string | undefined;
			numGroupsLoaded: number;
			isHierarchyEnabled: boolean;
			projectKey: string;
		}) => {
			const isGroupingEnabled = !!groupByFieldId;
			const searchViewContextInput = generateSearchViewContextInput({
				connections,
				isGroupingEnabled,
				groupedByFieldId: groupByFieldId,
			});

			const issueDetails = await fetchIssueListDetails({
				keys,
				cloudId,
				fieldSetIds,
				isInlineEditingEnabled,
				isDensityFull,
				isHierarchyEnabled,
				isGroupingEnabled,
				issueSearchInput: {
					jql,
				},
				searchViewContextInput,
				projectKey,
			});

			issueDetails?.jira?.issuesByKey?.filter(Boolean).forEach(async (issue) => {
				const searchViewContext = issue.searchViewContext;
				const id = issue.issueId;
				const issueRecordId = issue.__id;
				const parentIssueNodeRecordId = issue.parentIssueField?.parentIssue?.__id;

				const fieldSetConnectionId = issue?.fieldSetsById?.__id;
				if (id && issueRecordId) {
					commitLocalUpdate(environment, (store) => {
						issuePositionUpdater({
							store,
							searchViewContext,
							fieldSetConnectionId,
							issueId: id,
							issueRecordId,
							parentIssueNodeRecordId,
							groupConnectionId,
							projectKey,
						});
					});
				}
			});

			if (!isGroupingEnabled || !groupByFieldId) {
				return;
			}

			const issueKeysWithoutContext =
				issueDetails?.jira?.issuesByKey
					?.map((issue) =>
						!issue?.searchViewContext?.contexts?.length && !!issue?.key ? issue.key : null,
					)
					.filter(Boolean) || [];

			const groupsToInsert = await getGroupsForIssues({
				issueKeys: issueKeysWithoutContext,
				fieldId: groupByFieldId,
				jql,
				numGroupsLoaded,
			});

			if (groupsToInsert.length > 0) {
				commitLocalUpdate(environment, (store) => {
					groupsForIssueUpdater({
						store,
						groupConnectionId,
						groupsForIssue: groupsToInsert,
					});
				});
			}
		},
		[
			connections,
			cloudId,
			fieldSetIds,
			isInlineEditingEnabled,
			isDensityFull,
			environment,
			issuePositionUpdater,
			getGroupsForIssues,
			groupsForIssueUpdater,
		],
	);
};
