import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Text } from '@atlaskit/primitives';
import { AvatarLite } from '@atlassian/jira-business-avatar-lite/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { assigneeGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/assigneeGroupHeader_nativeIssueTable.graphql';
import messages from './messages.tsx';

export type Props = {
	assigneeGroupHeader: assigneeGroupHeader_nativeIssueTable$key | null;
};

export const AssigneeGroupHeader = ({ assigneeGroupHeader }: Props) => {
	const data = useFragment<assigneeGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment assigneeGroupHeader_nativeIssueTable on JiraJqlUserFieldValue {
				displayName
				user {
					picture
				}
			}
		`,
		assigneeGroupHeader,
	);

	const { formatMessage } = useIntl();

	const displayName = data?.displayName ?? formatMessage(messages.unassignedDisplayName);
	return (
		<>
			{data ? (
				<AvatarLite avatarUrl={data.user?.picture} />
			) : (
				<Avatar appearance="circle" src="" size="small" />
			)}
			<Text>{displayName}</Text>
		</>
	);
};
