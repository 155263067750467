import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { UrlInlineEditViewIsEditable } from '@atlassian/jira-issue-field-url-inline-edit-full/src/ui/url/index.tsx';
import type { url_nativeIssueTable_UrlCell$key } from '@atlassian/jira-relay/src/__generated__/url_nativeIssueTable_UrlCell.graphql';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: url_nativeIssueTable_UrlCell$key;
};

export const UrlCell = ({ fieldRef }: Props) => {
	const data = useFragment<url_nativeIssueTable_UrlCell$key>(
		graphql`
			fragment url_nativeIssueTable_UrlCell on JiraUrlField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...url_issueFieldUrlInlineEditFull_UrlInlineEditViewIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<UrlInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
		/>
	);
};
