/**
 * @generated SignedSource<<2a8b83aea95593d4c929eb090311a48a>>
 * @relayHash 198bef2c87d84a6ff2948bdb85602ae9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ec289666bbf9923ae2489d7fe3eb2bbbad9c0e7b0e2b6a5806a4df8c3615ece0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type groupsForIssues_realtimeUpdater_Query$variables = {
  cloudId: string;
  fieldId: string;
  firstNGroupsToSearch: number;
  issueKeys: ReadonlyArray<string>;
  issueSearchInput: JiraIssueSearchInput;
};
export type groupsForIssues_realtimeUpdater_Query$data = {
  readonly jira: {
    readonly issuesByKey: ReadonlyArray<{
      readonly groupsByFieldId: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __id: string;
            readonly afterGroupId: string | null | undefined;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"groupRow_nativeIssueTable_groupRow">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type groupsForIssues_realtimeUpdater_Query = {
  response: groupsForIssues_realtimeUpdater_Query$data;
  variables: groupsForIssues_realtimeUpdater_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstNGroupsToSearch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKeys"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v5 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "keys",
    "variableName": "issueKeys"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "fieldId",
    "variableName": "fieldId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "firstNGroupsToSearch",
    "variableName": "firstNGroupsToSearch"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "afterGroupId"
},
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = {
  "kind": "ScalarField",
  "name": "displayName"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "groupsForIssues_realtimeUpdater_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issuesByKey",
            "plural": true,
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraSpreadsheetGroupConnection",
                "kind": "LinkedField",
                "name": "groupsByFieldId",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSpreadsheetGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraSpreadsheetGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "groupRow_nativeIssueTable_groupRow"
                          },
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "groupsForIssues_realtimeUpdater_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issuesByKey",
            "plural": true,
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraSpreadsheetGroupConnection",
                "kind": "LinkedField",
                "name": "groupsByFieldId",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSpreadsheetGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraSpreadsheetGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "jql"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "fieldValue",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraPriority",
                                    "kind": "LinkedField",
                                    "name": "priority",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "priorityId"
                                      },
                                      (v7/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "iconUrl"
                                      }
                                    ]
                                  },
                                  (v11/*: any*/)
                                ],
                                "type": "JiraJqlPriorityFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "accountId"
                                      },
                                      (v7/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "picture"
                                      }
                                    ]
                                  },
                                  (v11/*: any*/)
                                ],
                                "type": "JiraJqlUserFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/)
                                    ]
                                  },
                                  (v11/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "statusCategoryId"
                                      },
                                      (v7/*: any*/)
                                    ]
                                  }
                                ],
                                "type": "JiraJqlStatusFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "number"
                                  }
                                ],
                                "type": "JiraJqlNumberFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "option",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "optionId"
                                      },
                                      (v7/*: any*/),
                                      {
                                        "concreteType": "JiraColor",
                                        "kind": "LinkedField",
                                        "name": "color",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "colorKey"
                                          },
                                          (v7/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v11/*: any*/)
                                ],
                                "type": "JiraJqlOptionFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraSprint",
                                    "kind": "LinkedField",
                                    "name": "sprint",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "sprintId"
                                      },
                                      (v7/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "state"
                                      }
                                    ]
                                  },
                                  (v11/*: any*/)
                                ],
                                "type": "JiraJqlSprintFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraGoal",
                                    "kind": "LinkedField",
                                    "name": "goal",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "status"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "score"
                                      }
                                    ]
                                  },
                                  (v11/*: any*/)
                                ],
                                "type": "JiraJqlGoalsFieldValue"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "fieldType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "issueCount"
                          },
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ec289666bbf9923ae2489d7fe3eb2bbbad9c0e7b0e2b6a5806a4df8c3615ece0",
    "metadata": {},
    "name": "groupsForIssues_realtimeUpdater_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "144f2828b0c3ea20da3fb2a4589ee105";

export default node;
