import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import type { requestType_nativeIssueTable_RequestTypeCell$key } from '@atlassian/jira-relay/src/__generated__/requestType_nativeIssueTable_RequestTypeCell.graphql';
import ErrorCell from '../../error-cell/index.tsx';

export type Props = {
	fieldRef: requestType_nativeIssueTable_RequestTypeCell$key;
};

const REQUEST_TYPE_IMAGE_SIZE = 16;

export const RequestTypeCell = memo<Props>(({ fieldRef }) => {
	const { requestType } = useFragment<requestType_nativeIssueTable_RequestTypeCell$key>(
		graphql`
			fragment requestType_nativeIssueTable_RequestTypeCell on JiraServiceManagementRequestTypeField {
				requestType {
					name
					avatar {
						xsmall
					}
				}
			}
		`,
		fieldRef,
	);

	if (!requestType) {
		return null;
	}

	if (!requestType.name) {
		return <ErrorCell cellType="requestType" reason="Missing required fields" />;
	}

	return (
		<Inline alignBlock="center" space="space.100">
			{requestType?.avatar?.xsmall && (
				<Box xcss={imageContainerStyles}>
					<img src={requestType.avatar.xsmall} width={REQUEST_TYPE_IMAGE_SIZE} alt="" />
				</Box>
			)}
			<Box as="span" xcss={textOverflowStyles}>
				{requestType.name}
			</Box>
		</Inline>
	);
});

const imageContainerStyles = xcss({
	display: 'inline',
	height: `${REQUEST_TYPE_IMAGE_SIZE}px`,
});

const textOverflowStyles = xcss({
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 2,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'break-word',
});
