// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createSelector } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

const getIssueIdSelector = createSelector(
	(state: State) => state.itemsObserved,
	(_: State, element: Element) => element,
	(items, element) => {
		const issueId = items.get(element);
		if (issueId === undefined) {
			return null;
		}
		return issueId;
	},
);

export const getInsertionPointSelector = (state: State) => {
	const { insertionPoint } = state;

	if (insertionPoint == null) {
		return null;
	}

	const { element, position } = insertionPoint;

	const issueId = getIssueIdSelector(state, element);

	if (issueId == null) {
		return null;
	}

	return {
		issueId,
		position,
	};
};
