/**
 * @generated SignedSource<<7a674bc11003d5a4f6042bd041448c62>>
 * @relayHash 2f2935fb2f2e43d33d4c8ed1e1b2cd67
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8540386bf8ddcde1b4f569318aad611087a8b356d205fa2274ec5675ba438f8e

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateCheckboxesFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraCheckboxesFieldOperationInput>;
};
export type JiraCheckboxesFieldOperationInput = {
  ids: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation$variables = {
  input: JiraUpdateCheckboxesFieldInput;
};
export type checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation$data = {
  readonly jira: {
    readonly updateCheckboxesField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateCheckboxesField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly selectedOptions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly value: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation = {
  rawResponse: checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation$rawResponse;
  response: checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation$data;
  variables: checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCheckboxesFieldPayload",
            "kind": "LinkedField",
            "name": "updateCheckboxesField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCheckboxesField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCheckboxesFieldPayload",
            "kind": "LinkedField",
            "name": "updateCheckboxesField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCheckboxesField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraOptionConnection",
                    "kind": "LinkedField",
                    "name": "selectedOptions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraOptionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraOption",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "8540386bf8ddcde1b4f569318aad611087a8b356d205fa2274ec5675ba438f8e",
    "metadata": {},
    "name": "checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8471c6b898ae5a82094ee733778542d4";

export default node;
