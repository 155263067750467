import {
	getSharedRestrictSprintsValueFromLocalStorage,
	setSharedRestrictSprintsValueInLocalStorage,
} from '@atlassian/jira-issue-field-sprint-editview-full/src/common/utils.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';

type State = {
	restrictSprints: boolean;
};

type Actions = {
	onRestrictSprints: (restrictSprints: boolean) => Action<State>;
};

const initialState: State = {
	restrictSprints: getSharedRestrictSprintsValueFromLocalStorage(),
};

const actions: Actions = {
	onRestrictSprints:
		(restrictSprints: boolean): Action<State> =>
		({ setState }) => {
			setSharedRestrictSprintsValueInLocalStorage(restrictSprints);
			setState({ restrictSprints });
		},
};

const RestrictSprintsStore = createStore<State, Actions>({
	initialState,
	actions,
});

export const useRestrictSprints = createHook<State, Actions, boolean>(RestrictSprintsStore, {
	selector: (state: State) => state.restrictSprints,
});
