import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';

import EmptyStateIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/generic-empty-state/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { NoColumnsProps } from '../../../common/types.tsx';
import { NoColumns as DefaultNoColumns } from '../../../common/ui/components/no-columns/index.tsx';
import { Overlay, OverlayOld } from '../../../common/ui/overlay/index.tsx';

export type Props = {
	NoColumns?: ComponentType<NoColumnsProps>;
	hasColumnConfiguration: boolean;
	isLoading?: boolean;
};

const NoColumnsRenderer = ({
	NoColumns = DefaultNoColumns,
	hasColumnConfiguration,
	isLoading = false,
}: Props) => (
	<NoColumnsContainer>
		<NoColumns
			defaultNoColumnsGlyph={
				<Box xcss={ImageWrapperStyles}>
					<EmptyStateIllustration alt="" />
				</Box>
			}
			hasColumnConfiguration={hasColumnConfiguration}
		/>
		{isLoading &&
			(fg('add_nin_press_interactions') ? (
				<Overlay interactionName="issue-table-no-columns-renderer-overlay" />
			) : (
				<OverlayOld />
			))}
		<FireUiAnalytics
			actionSubject="screen"
			action="viewed"
			actionSubjectId="nativeIssueTableNoColumns"
		/>
	</NoColumnsContainer>
);

export default NoColumnsRenderer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoColumnsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	flex: 1,
	position: 'sticky',
	left: 0,
});

const ImageWrapperStyles = xcss({
	maxHeight: '146px',
	maxWidth: '160px',
	margin: 'auto',
	marginBottom: 'space.300',
});
