import React, { type ReactNode, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

// If you add a new action type, please update the `actionType` enum in Data Portal, so we can monitor usage
// https://data-portal.internal.atlassian.com/analytics/registry/66149?tabIndex=Attributes
type ActionType =
	| 'sortAsc'
	| 'sortDesc'
	| 'moveColumnFirst'
	| 'moveColumnLeft'
	| 'moveColumnRight'
	| 'moveColumnLast'
	| 'removeColumn'
	| 'resizeColumn'
	| 'resetColumnWidth';

type Props = {
	actionType: ActionType;
	children?: ReactNode;
	onClick: () => void;
};

export const ActionItem = ({ actionType, children, onClick }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'issueTableHeaderActions',
			{ actionType },
		);

		onClick();
	}, [actionType, createAnalyticsEvent, onClick]);

	return <DropdownItem onClick={handleOnClick}>{children}</DropdownItem>;
};
