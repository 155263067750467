import React, { memo, useRef } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { ProjectReadView } from '@atlassian/jira-issue-field-project/src/ui/view/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { project_nativeIssueTable_ProjectCell$key } from '@atlassian/jira-relay/src/__generated__/project_nativeIssueTable_ProjectCell.graphql';
import {
	useIsDensityFull,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { HoverPopover } from '../../hover-popover/index.tsx';

export type Props = {
	fieldRef: project_nativeIssueTable_ProjectCell$key;
	isRequired?: boolean;
};

export const ProjectCell = memo<Props>(({ fieldRef, isRequired }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { project } = useFragment<project_nativeIssueTable_ProjectCell$key>(
		graphql`
			fragment project_nativeIssueTable_ProjectCell on JiraProjectField {
				project {
					name
					key
					avatar {
						large
					}
				}
			}
		`,
		fieldRef,
	);

	const isDensityFull = useIsDensityFull();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const contentRef = useRef(null);

	if (!isRequired && project == null) {
		return null;
	}

	if (project == null || project.name == null || project.key == null) {
		return <ErrorCell cellType="project" reason="Missing required fields" />;
	}

	if (!isSingleLineRowHeightEnabled)
		return (
			<AlignmentFix hasAvatar={Boolean(project.avatar?.large)}>
				<ProjectReadView
					value={{
						key: project.key,
						name: project.name,
						avatarUrls: { '48x48': project.avatar?.large ?? '' },
					}}
					onClick={() => {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'clicked',
								actionSubject: 'link',
							}),
							'tableIssueLink',
						);
					}}
					isTruncated={!isDensityFull}
				/>
			</AlignmentFix>
		);

	return (
		<HoverPopover content={project.name} contentRef={contentRef}>
			<AlignmentFix hasAvatar={Boolean(project.avatar?.large)}>
				<ProjectReadView
					value={{
						key: project.key,
						name: project.name,
						avatarUrls: { '48x48': project.avatar?.large ?? '' },
					}}
					onClick={() => {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'clicked',
								actionSubject: 'link',
							}),
							'tableIssueLink',
						);
					}}
					isTruncated={!isDensityFull}
					contentRef={contentRef}
				/>
			</AlignmentFix>
		</HoverPopover>
	);
});

// The Project component has a 4px padding plus the avatar inside has a 2px invisible border that we have to account for
// Refer to: src/packages/issue/fields/project/src/ui/view/main.tsx
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignmentFix = styled.div<{ hasAvatar?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ hasAvatar }) => (hasAvatar ? token('space.negative.025') : token('space.0')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		paddingTop: token('space.0'),
		paddingRight: token('space.0'),
		paddingBottom: token('space.0'),
		paddingLeft: token('space.0'),
	},
});
