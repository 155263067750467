import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import type { radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView$key as RadioSelectFragment } from '@atlassian/jira-relay/src/__generated__/radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView.graphql';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { RadioSelectReadViewProps } from './types.tsx';

/**
 * The RadioSelectReadView will show a read only view of RadioSelect field.
 * @param props [RadioSelectReadViewProps](./types.tsx)
 */
export const RadioSelectReadView = ({ fragmentRef, maxLines }: RadioSelectReadViewProps) => {
	const data = useFragment<RadioSelectFragment>(
		graphql`
			fragment radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView on JiraRadioSelectField {
				selectedOption {
					label: value
				}
			}
		`,
		fragmentRef,
	);

	const { selectedOption } = data;

	return selectedOption?.label ? (
		<Text maxLines={maxLines}>{selectedOption?.label}</Text>
	) : (
		<EmptyFieldText />
	);
};
