import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadedIssuesTotalKnown: {
		id: 'native-issue-table.footer-renderer.issue-count-renderer.issue-count.loaded-issues-total-known',
		defaultMessage: '{loadedIssuesCount} of {totalNumberIssues}',
		description: 'Text indicating the loaded range out of the total number of issues.',
	},
	issueRangeKnown: {
		id: 'native-issue-table.footer-renderer.issue-count-renderer.issue-count.issue-range-known',
		defaultMessage: '{startIssueRange}-{endIssueRange} of {totalNumberIssues}',
		description: 'Text indicating the shown range out of the total number of issues.',
	},
	issueRangeUnknown: {
		id: 'native-issue-table.footer-renderer.issue-count-renderer.issue-count.issue-range-unknown',
		defaultMessage: '{startIssueRange}-{endIssueRange}',
		description: 'Text indicating the shown range only.',
	},
});
