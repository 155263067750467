import React, { useCallback, type MouseEvent, type KeyboardEvent, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Link, type LinkProps } from '@atlassian/react-resource-router';
import { useIsSingleLineRowHeightEnabled } from '../../../controllers/features/selectors.tsx';

type Props = LinkProps & {
	innerFocusOutline?: boolean;
	shouldDisplayStrikethrough?: boolean;
	isSubtle?: boolean;
	'aria-label'?: string;
	ref?: React.Ref<HTMLAnchorElement>;
};

const TableCellLink = forwardRef<HTMLAnchorElement, Props>(({ onClick, ...rest }, ref) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const onClickWithAnalytics = useCallback(
		(e: MouseEvent | KeyboardEvent) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'link',
				}),
				'tableIssueLink',
			);
			onClick && onClick(e);
		},
		[createAnalyticsEvent, onClick],
	);

	return (
		<StyledLink
			{...rest}
			onClick={onClickWithAnalytics}
			ref={ref}
			isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
		/>
	);
});

// Fix for Compiled bug where invalid props are passed on to the underlying DOM element when using inheritance.
const PropFilteredLink = forwardRef<HTMLAnchorElement, Props>(
	({ innerFocusOutline, shouldDisplayStrikethrough, isSubtle, ...rest }, ref) => (
		<Link {...rest} ref={ref} />
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLinkOld = styled(PropFilteredLink)<{
	innerFocusOutline?: boolean;
	shouldDisplayStrikethrough?: boolean;
	isSubtle?: boolean;
	isSingleLineRowHeightEnabled?: boolean;
}>(
	{
		wordBreak: 'break-word',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ innerFocusOutline }) =>
		innerFocusOutline && {
			'&:focus-visible': {
				outlineOffset: token('space.negative.025'),
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-1876
	({ shouldDisplayStrikethrough }) =>
		shouldDisplayStrikethrough && {
			'text-decoration': 'line-through',
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-1876
	({ isSingleLineRowHeightEnabled }) =>
		isSingleLineRowHeightEnabled && {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			display: 'block',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLinkNew = styled(PropFilteredLink)<{
	innerFocusOutline?: boolean;
	shouldDisplayStrikethrough?: boolean;
	isSubtle?: boolean;
	isSingleLineRowHeightEnabled?: boolean;
}>(
	{
		wordBreak: 'break-word',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		color: ({ isSubtle }) => (isSubtle ? token('color.text.subtle') : token('color.text')),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ innerFocusOutline }) =>
		innerFocusOutline && {
			'&:focus-visible': {
				outlineOffset: token('space.negative.025'),
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-1876
	({ shouldDisplayStrikethrough }) =>
		shouldDisplayStrikethrough && {
			'text-decoration': 'line-through',
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-1876
	({ isSingleLineRowHeightEnabled }) =>
		isSingleLineRowHeightEnabled && {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			display: 'block',
		},
);

const StyledLink = componentWithCondition(isVisualRefreshEnabled, StyledLinkNew, StyledLinkOld);

export default TableCellLink;
