import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issuesTableLabel: {
		id: 'native-issue-table.issue-table.issues-table-label',
		defaultMessage: 'Issues',
		description: 'Name of table for screen reader users',
	},
	issuesTableLabelIssueTermRefresh: {
		id: 'native-issue-table.issue-table.issues-table-label-issue-term-refresh',
		defaultMessage: 'Work',
		description: 'Name of table for screen reader users',
	},
});
