import React, { memo, useCallback, useMemo, type MouseEvent, type KeyboardEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import CommentIcon from '@atlaskit/icon/core/comment';
import type { commentSummary_nativeIssueTable_CommentSummaryCell$key } from '@atlassian/jira-relay/src/__generated__/commentSummary_nativeIssueTable_CommentSummaryCell.graphql';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: commentSummary_nativeIssueTable_CommentSummaryCell$key;
	issueKey: string;
	/**
	 * Dispatched when a table cell with a link to the issue is selected. This method should return `true` to signal
	 * that the event has been handled, which will prevent default event handling.
	 *
	 * @param issueKey Key of the selected issue.
	 * @param viewActivity Flag to instruct the consumer they should attempt to scroll to the activity feed section when
	 * navigating to the selected issue.
	 */
	onIssueLinkSelected?: (issueKey: string, viewActivity?: boolean) => boolean;
};
export const CommentSummaryCell = memo<Props>(
	({ fieldRef, issueKey, onIssueLinkSelected }: Props) => {
		const { formatMessage } = useIntl();
		const data = useFragment<commentSummary_nativeIssueTable_CommentSummaryCell$key>(
			graphql`
				fragment commentSummary_nativeIssueTable_CommentSummaryCell on JiraCommentSummaryField {
					commentSummary {
						canAddComment
						totalCount
					}
				}
			`,
			fieldRef,
		);
		const canAddComment = data?.commentSummary?.canAddComment;
		const totalCount = data?.commentSummary?.totalCount;

		const renderedCommentCount = useMemo(() => {
			// No comments, add comment
			if (!totalCount) {
				return formatMessage(messages.addComment);
			}
			// Less than 100 comments, show count
			if (totalCount < 100) {
				return formatMessage(messages.commentsCount, {
					commentCount: totalCount,
				});
			}
			// More than 99 comments, show 99+
			return formatMessage(messages.moreComments);
		}, [formatMessage, totalCount]);

		const handleClick = useCallback(
			(e: MouseEvent | KeyboardEvent) => {
				// Use default browser event handling when a modifier key is pressed during the click event
				const isModifierKeyUsed = e.ctrlKey || e.shiftKey || e.metaKey;
				const handled =
					!isModifierKeyUsed &&
					onIssueLinkSelected !== undefined &&
					onIssueLinkSelected(issueKey, true);
				if (handled) {
					e.preventDefault();
				}
			},
			[issueKey, onIssueLinkSelected],
		);

		if (!totalCount && !canAddComment) {
			return (
				<Box paddingInline="space.075">
					<EmptyFieldText />
				</Box>
			);
		}

		return (
			<Anchor
				href={`/browse/${issueKey}`}
				xcss={[buttonStyles, !totalCount && emptyStyles]}
				onClick={handleClick}
				backgroundColor="color.background.neutral.subtle"
				paddingInline="space.075"
			>
				<CommentIcon color="currentColor" label={formatMessage(messages.comments)} />
				{renderedCommentCount}
			</Anchor>
		);
	},
);

const buttonStyles = xcss({
	color: 'color.text',
	borderRadius: 'border.radius',
	borderWidth: '2px',
	borderStyle: 'solid',
	// borderColor doesn't allow transparent as a value so we use inline/block which has looser typing
	borderInlineColor: 'transparent',
	borderBlockColor: 'transparent',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	minHeight: '100%',
	maxWidth: '100%',
	textDecoration: 'none',
	':focus': {
		borderInlineColor: 'color.border.focused',
		borderBlockColor: 'color.border.focused',
		outline: 0,
		color: 'color.text',
		textDecoration: 'none',
	},
	':focus-visible': {
		borderInlineColor: 'color.border.focused',
		borderBlockColor: 'color.border.focused',
		outline: 0,
	},
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		color: 'color.text',
		textDecoration: 'none',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});
const emptyStyles = xcss({
	color: 'color.text.subtlest',
	':focus': {
		color: 'color.text.subtlest',
	},
	':hover': {
		color: 'color.text.subtlest',
	},
});
