import { fg } from '@atlassian/jira-feature-gating';
import {
	useIsSelected as useIsSelectedNew,
	useIsFocused as useIsFocusedNew,
	useSelectedRow as useSelectedRowNew,
	type ItemDetails,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import {
	useIsSelected as useIsSelectedOld,
	useIsFocused as useIsFocusedOld,
	useSelectedRow as useSelectedRowOld,
} from '../selected-row-state/index.tsx';

export const useSelectedRow = () => {
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useSelectedRowNew();
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useSelectedRowOld();
};

export const useIsSelected = (itemDetail: ItemDetails, rowDetail: { selectedRowIndex: number }) => {
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useIsSelectedNew(itemDetail);
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useIsSelectedOld(rowDetail);
};

export const useIsFocused = (itemDetail: ItemDetails, rowDetail: { selectedRowIndex: number }) => {
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useIsFocusedNew(itemDetail);
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useIsFocusedOld(rowDetail);
};
