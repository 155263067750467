/* eslint-disable @typescript-eslint/consistent-type-assertions */
/** @jsx jsx */
import React, {
	type ReactNode,
	type RefCallback,
	type MutableRefObject,
	useCallback,
	useRef,
} from 'react';
import { jsx, css } from '@compiled/react';
import { notUndefined } from '@tanstack/react-virtual';
import {
	useVirtualization,
	useVirtualItemsMap,
} from '../../../controllers/virtualization/rss-store.tsx';
import type { ServerVirtualizer } from '../../../controllers/virtualization/index.tsx';

export type Props = {
	hasSpacers?: boolean;
	columnsCount: number;
	children: (
		isItemVirtual?: (itemId: string) => boolean,
		measureElement?: RefCallback<HTMLTableRowElement>,
		isScrollingRef?: MutableRefObject<boolean>,
	) => ReactNode[] | ReactNode | undefined;
};

export const VirtualizedRows = ({ hasSpacers, columnsCount, children }: Props) => {
	const virtualizer = useVirtualization();

	if (virtualizer == null) {
		return children();
	}
	return (
		<VirtualizedRowsInner
			hasSpacers={hasSpacers}
			columnsCount={columnsCount}
			virtualizer={virtualizer}
		>
			{children}
		</VirtualizedRowsInner>
	);
};

const VirtualizedRowsInner = ({
	hasSpacers,
	columnsCount,
	virtualizer,
	children,
}: Props & { virtualizer: ServerVirtualizer }) => {
	const virtualItemsMap = useVirtualItemsMap();
	const virtualItemsMapRef = useRef(virtualItemsMap);
	virtualItemsMapRef.current = virtualItemsMap;
	const isItemVirtual = useCallback((key: string) => Boolean(virtualItemsMapRef.current[key]), []);
	const items = virtualizer.getVirtualItems();
	// Remove isScrollingRef usages with empanada_nin_concurrent_mode_fixes clean up
	const isScrollingRef = useRef(false);
	isScrollingRef.current = virtualizer.isScrolling;

	// Calculates the height (top and bottom) of the non rendered portion of table
	const [before, after] =
		items.length > 0
			? [
					notUndefined(items[0]).start - virtualizer.options.scrollMargin,
					virtualizer.getTotalSize() - notUndefined(items.at(-1)).end,
				]
			: [0, 0];

	return (
		<>
			{hasSpacers && before > 0 && (
				<tr css={virtualRowStyles}>
					<td
						css={virtualTopSpacerStyles}
						colSpan={columnsCount}
						// eslint-disable-next-line jira/react/no-style-attribute
						style={{ '--local-row-top-spacer-height': `${before}px` } as React.CSSProperties}
					/>
				</tr>
			)}
			{children(isItemVirtual, virtualizer.measureElement, isScrollingRef)}
			{hasSpacers && after > 0 && (
				<tr css={virtualRowStyles}>
					<td
						css={virtualBottomSpacerStyles}
						colSpan={columnsCount}
						// eslint-disable-next-line jira/react/no-style-attribute
						style={{ '--local-row-bottom-spacer-height': `${after}px` } as React.CSSProperties}
					/>
				</tr>
			)}
		</>
	);
};

const virtualRowStyles = css({
	padding: '0',
	margin: '0',
});

const virtualTopSpacerStyles = css({
	padding: '0',
	margin: '0',
	height: 'var(--local-row-top-spacer-height)',
});

const virtualBottomSpacerStyles = css({
	padding: '0',
	margin: '0',
	height: 'var(--local-row-bottom-spacer-height)',
});
