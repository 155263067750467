/**
 * @generated SignedSource<<cbbefe241979b3de7277fee2ab84498c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type epicLink_nativeIssueTable_EpicLinkCell$data = {
  readonly epic: {
    readonly color: string | null | undefined;
    readonly key: string | null | undefined;
    readonly summary: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "epicLink_nativeIssueTable_EpicLinkCell";
};
export type epicLink_nativeIssueTable_EpicLinkCell$key = {
  readonly " $data"?: epicLink_nativeIssueTable_EpicLinkCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"epicLink_nativeIssueTable_EpicLinkCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "epicLink_nativeIssueTable_EpicLinkCell",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        }
      ]
    }
  ],
  "type": "JiraEpicLinkField"
};

(node as any).hash = "0d48d484eeec312fafb57f96f453cf0f";

export default node;
