import React, { memo } from 'react';
import { useFragment, graphql } from 'react-relay';
import type { issueType_nativeIssueTable_IssueTypeCell$key } from '@atlassian/jira-relay/src/__generated__/issueType_nativeIssueTable_IssueTypeCell.graphql';
import { IconCell } from '../../icon-cell/index.tsx';

type Props = {
	fieldRef: issueType_nativeIssueTable_IssueTypeCell$key;
};

export const IssueTypeCell = memo(({ fieldRef }: Props) => {
	const { issueType } = useFragment<issueType_nativeIssueTable_IssueTypeCell$key>(
		graphql`
			fragment issueType_nativeIssueTable_IssueTypeCell on JiraIssueTypeField {
				issueType {
					avatar {
						small
					}
					name
				}
			}
		`,
		fieldRef,
	);

	return (
		<IconCell iconUrl={issueType?.avatar?.small} name={issueType?.name} showErrorIconWhenEmpty />
	);
});
