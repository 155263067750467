/**
 * @generated SignedSource<<04fe6cbd7f86be0b3c73e9e2564fcb5e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type fallback_nativeIssueTable_FallbackCell$data = {
  readonly renderedFieldHtml: string | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "fallback_nativeIssueTable_FallbackCell";
};
export type fallback_nativeIssueTable_FallbackCell$key = {
  readonly " $data"?: fallback_nativeIssueTable_FallbackCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"fallback_nativeIssueTable_FallbackCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "fallback_nativeIssueTable_FallbackCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    }
  ],
  "type": "JiraFallbackField"
};

(node as any).hash = "ae1cf9566abe8a4e7439b5ee75c25916";

export default node;
