/**
 * @generated SignedSource<<f40040f20e49a676e75cea1191e3dde3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dateTime_nativeIssueTable_DateTimeCell$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "dateTime_nativeIssueTable_DateTimeCell";
};
export type dateTime_nativeIssueTable_DateTimeCell$key = {
  readonly " $data"?: dateTime_nativeIssueTable_DateTimeCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_nativeIssueTable_DateTimeCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "dateTime_nativeIssueTable_DateTimeCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "b976a3c6a5f97459d63599a3152d13cd";

export default node;
