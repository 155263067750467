// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createSelector,
	createStore,
	createHook,
	createStateHook,
	type Action,
	createContainer,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { getFlatListKey } from '../../common/utils/get-flat-list-key/index.tsx';

type ItemKey = string;

type ExpandedItems = { [key: ItemKey]: boolean };

type FlatListMetadata = {
	itemId: string;
	parentId: string;
	connectionId: string;
	key: string;
	groupId?: string;
};

export type ItemDetails = { itemsConnectionId: string; itemId: string; groupId?: string } | null;

export type ConnectionItem = { key: string | null; itemId: string; groupId?: string };

type LoadingItems = {
	[itemId: string]: boolean;
};

type State = {
	selectedItem: ItemKey | null;
	focusedItem: ItemKey | null;
	expandedItems: ExpandedItems;
	rootConnectionId: string | null;
	connectionMap: {
		[key: string]: string | null;
	};
	connectionItems: {
		[key: string]: ConnectionItem[] | null;
	};
	visibleDepth: number;
	flatList: string[];
	issueKeys: Array<string | null>;
	listMetadata: FlatListMetadata[];
	amountOfRows: number;
	loadingItems: LoadingItems;
};

const initialState: State = {
	selectedItem: null,
	focusedItem: null,
	expandedItems: {},
	rootConnectionId: null,
	connectionMap: {},
	connectionItems: {},
	loadingItems: {},
	/**
	 * Indicates the maximum expanded hierarhcy level:
	 * - Starts with 0 on initial load (flat list)
	 * - Expanding each level increments the value
	 */
	visibleDepth: 0,
	flatList: [],
	issueKeys: [],
	listMetadata: [],
	amountOfRows: 0,
};

const CHILD_ISSUE_CREATE_PREFIX = 'CHILD_ISSUE_CREATE';
const SHOW_MORE_PREFIX = 'SHOW_MORE';
const LOADING_ROW_PREFIX = 'LOADING_ROW';

const isSpecialItem = (id: string) =>
	id?.startsWith(CHILD_ISSUE_CREATE_PREFIX) ||
	id?.startsWith(SHOW_MORE_PREFIX) ||
	id?.startsWith(LOADING_ROW_PREFIX);

export const filterNonIssueItems = (list: Array<string | null>): string[] =>
	list.filter(Boolean).filter((item) => !isSpecialItem(item));

export const getItemKey = (itemsConnectionId: string, itemId: string, groupId?: string): ItemKey =>
	`${itemsConnectionId}::${itemId}${groupId ? `::${groupId}` : ''}`;

export const getChildIssueCreateItemKey = (parentItemId: string): ItemKey =>
	`${CHILD_ISSUE_CREATE_PREFIX}_${parentItemId}`;

export const getShowMoreItemKey = (parentItemId: string): ItemKey =>
	`${SHOW_MORE_PREFIX}_${parentItemId}`;

export const getLoadingItemKey = (parentItemId: string): ItemKey =>
	`${LOADING_ROW_PREFIX}_${parentItemId}`;

export const getItemMetadata = (
	key?: ItemKey | null,
): { connectionId: string | null; itemId: string | null } => {
	if (typeof key !== 'string') {
		return { connectionId: null, itemId: null };
	}

	const [connectionId, itemId] = key.split('::');
	return { connectionId, itemId };
};

type PopulateConnectionProps = {
	parentItemId: string;
	itemsConnectionId: string;
	parentConnectionId: string;
	list: ConnectionItem[] | null;
	hasNext?: boolean;
	isChildIssueCreateRowVisible?: boolean;
	groupId?: string;
};

const actions = {
	populateConnection: ({
		parentItemId,
		itemsConnectionId,
		parentConnectionId,
		list,
		hasNext,
		isChildIssueCreateRowVisible,
		groupId,
	}: PopulateConnectionProps): Action<State> => {
		return ({ setState, getState, dispatch }) => {
			if (parentItemId === 'root') {
				setState({
					rootConnectionId: itemsConnectionId,
				});
			}
			const key = getItemKey(itemsConnectionId, parentItemId, groupId);
			const state = getState();
			let nextList = list?.slice() ?? null;

			// adding [Show more] button to flat list so that virtualiser recognizes it
			if (hasNext && nextList) {
				const showMoreRowKey = getShowMoreItemKey(parentItemId);
				nextList = nextList.concat({ key: showMoreRowKey, itemId: showMoreRowKey, groupId });
			}

			if (isChildIssueCreateRowVisible && nextList) {
				const childIssueCreateRowKey = getChildIssueCreateItemKey(parentItemId);
				nextList.unshift({
					key: childIssueCreateRowKey,
					itemId: childIssueCreateRowKey,
					groupId,
				});
			}

			const parentKey = getItemKey(
				parentConnectionId,
				parentItemId,
				// root doesn't have a groupId
				parentItemId === 'root' ? undefined : groupId,
			);

			setState({
				connectionMap: {
					...state.connectionMap,
					[parentKey]: key,
				},
				connectionItems: {
					...state.connectionItems,
					[key]: nextList,
				},
			});

			dispatch(actions.traverseTreeAndUpdateState());
			dispatch(actions.clearSelectionIfItemIsNotInList());
		};
	},

	setItemIsLoading:
		({
			itemId,
			groupId,
			isLoading,
		}: {
			itemId: string;
			groupId?: string;
			isLoading: boolean;
		}): Action<State> =>
		({ setState, getState, dispatch }) => {
			const { loadingItems, listMetadata, connectionItems } = getState();
			const loadingItemKey = getLoadingItemKey(itemId);
			const metadataItem = listMetadata.find((metadata) => metadata.itemId === itemId);
			const key = metadataItem
				? getItemKey(metadataItem.connectionId, metadataItem.parentId, metadataItem.groupId)
				: null;

			if (!key || !connectionItems) {
				return;
			}

			let nextList = (connectionItems?.[key] ?? []).slice();

			if (isLoading) {
				const itemIndex = nextList.findIndex((item) => item.itemId === itemId);

				if (itemIndex > -1) {
					// insert loading row right after the expanded item
					nextList.splice(itemIndex + 1, 0, {
						key: loadingItemKey,
						itemId: loadingItemKey,
						groupId,
					});
				}
			} else {
				nextList = nextList.filter((item) => item.itemId !== loadingItemKey);
			}

			setState({
				loadingItems: { ...loadingItems, [itemId]: isLoading },
				connectionItems: {
					...connectionItems,
					[key]: nextList,
				},
			});

			dispatch(actions.traverseTreeAndUpdateState());
			dispatch(actions.clearSelectionIfItemIsNotInList());
		},

	toggleIsItemExpanded:
		(itemDetails: ItemDetails, issueDepth: number): Action<State> =>
		({ getState, dispatch }) => {
			if (!itemDetails) {
				return;
			}
			const { itemsConnectionId, itemId, groupId } = itemDetails;
			const key = getItemKey(itemsConnectionId, itemId, groupId);
			const state = getState();

			const isExpanded = !state.expandedItems[key];
			dispatch(actions.setIsItemExpanded(itemDetails, isExpanded, issueDepth));
		},

	setIsItemExpanded:
		(itemDetails: ItemDetails, isItemExpanded: boolean, issueDepth = 0): Action<State> =>
		({ setState, getState, dispatch }) => {
			if (!itemDetails) {
				return;
			}
			const { itemsConnectionId, itemId, groupId } = itemDetails;
			const key = getItemKey(itemsConnectionId, itemId, groupId);
			const state = getState();

			/*
				Update visibleDepth with the new depth if is expanded an item
				traverseTreeAndUpdateState function would update the visibleDepth when collapse
			*/
			const optimisticVisibleDepth = issueDepth + 1;
			const currentVisibleDepth = state.visibleDepth;
			const newVisibleDepth =
				isItemExpanded && optimisticVisibleDepth > currentVisibleDepth
					? optimisticVisibleDepth
					: state.visibleDepth;

			setState({
				expandedItems: {
					...state.expandedItems,
					[key]: isItemExpanded,
				},
				...(isItemExpanded === false
					? {
							connectionItems: {
								...state.connectionItems,
								[key]: null,
							},
						}
					: {}),
				visibleDepth: newVisibleDepth,
			});

			dispatch(actions.traverseTreeAndUpdateState());
			dispatch(actions.clearSelectionIfItemIsNotInList());
		},

	traverseTreeAndUpdateState:
		(): Action<State> =>
		({ setState, getState, dispatch }) => {
			const result = hierarchyToFlatTreePreOrdered(getState());

			setState({
				flatList: result.flatlist,
				issueKeys: result.issueKeys,
				listMetadata: result.listMetadata,
				visibleDepth: result.hierarchyLevels[result.hierarchyLevels.length - 1],
			});

			dispatch(actions.setAmountOfRows(result.flatlist.length));
		},

	setSelectedRow:
		(itemDetails: ItemDetails): Action<State> =>
		({ setState }) => {
			if (!itemDetails) {
				setState({
					selectedItem: null,
					focusedItem: null,
				});
				return;
			}

			const { itemsConnectionId, itemId, groupId } = itemDetails;
			const key = getItemKey(itemsConnectionId, itemId, groupId);

			setState({
				selectedItem: key,
				focusedItem: key,
			});
		},

	selectNextRow:
		(): Action<State> =>
		({ getState, dispatch }) => {
			const state = getState();
			const { listMetadata, selectedItem } = state;
			if (!selectedItem) {
				return;
			}
			const currentIndex = listMetadata.findIndex((item) => item.key === selectedItem);
			const nextIndex = currentIndex + 1;
			if (nextIndex < listMetadata.length) {
				dispatch(
					actions.setSelectedRow({
						itemsConnectionId: listMetadata[nextIndex].connectionId,
						itemId: listMetadata[nextIndex].itemId,
						groupId: listMetadata[nextIndex].groupId,
					}),
				);
			}
		},

	selectPrevRow:
		(): Action<State> =>
		({ getState, dispatch }) => {
			const state = getState();
			const { listMetadata, selectedItem } = state;
			if (!selectedItem) {
				return;
			}
			const currentIndex = listMetadata.findIndex((item) => item.key === selectedItem);
			const previousIndex = currentIndex - 1;
			if (previousIndex >= 0) {
				dispatch(
					actions.setSelectedRow({
						itemsConnectionId: listMetadata[previousIndex].connectionId,
						itemId: listMetadata[previousIndex].itemId,
						groupId: listMetadata[previousIndex].groupId,
					}),
				);
			}
		},

	clearSelectionIfItemIsNotInList:
		(): Action<State> =>
		({ getState, dispatch }) => {
			const state = getState();
			const { selectedItem } = state;

			if (!selectedItem || state.listMetadata.find((item) => item.key === selectedItem)) {
				return;
			}

			dispatch(actions.setSelectedRow(null));
		},

	setAmountOfRows:
		(newAmountOfRows: number): Action<State> =>
		({ getState, setState }) => {
			const { amountOfRows } = getState();
			amountOfRows !== newAmountOfRows && setState({ amountOfRows: newAmountOfRows });
		},

	resetFocus:
		(): Action<State> =>
		({ getState, setState }) => {
			const { focusedItem } = getState();
			focusedItem !== null && setState({ focusedItem: null });
		},

	resetHierarchyKeepingFirstGroupExpanded:
		({
			firstGroupId,
			itemsConnectionId,
		}: {
			firstGroupId?: string | null;
			itemsConnectionId?: string;
		}): Action<State> =>
		({ setState, dispatch }) => {
			if (firstGroupId && itemsConnectionId) {
				const firstGroupKey = getItemKey(itemsConnectionId, firstGroupId, firstGroupId);

				// First group is always expanded by default
				setState({ expandedItems: { [firstGroupKey]: true } });
			} else {
				setState({ expandedItems: {} });
			}

			dispatch(actions.traverseTreeAndUpdateState());
		},
};

type Actions = typeof actions;

// cleanup props with empanada_nin_concurrent_mode_fixes
export type Props = {
	isIssueHierarchyEnabled?: boolean;
	groupByFieldId?: string | null;
	firstGroupId?: string | null;
	itemsConnectionId?: string;
	jql?: string | null;
};

export const HierarchyContainer = createContainer<Props>();

const Store = createStore<State, Actions, Props>({
	initialState,
	actions,
	name: 'hierarchy',
	containedBy: HierarchyContainer,
	handlers: {
		// cleanup with empanada_nin_concurrent_mode_fixes
		onContainerUpdate:
			(props, prevProps) =>
			({ dispatch }) => {
				if (
					((!props.isIssueHierarchyEnabled && prevProps.isIssueHierarchyEnabled) ||
						props.groupByFieldId !== prevProps.groupByFieldId ||
						props.jql !== prevProps.jql) &&
					!fg('empanada_nin_concurrent_mode_fixes')
				) {
					dispatch(
						Store.actions.resetHierarchyKeepingFirstGroupExpanded({
							firstGroupId: props.firstGroupId,
							itemsConnectionId: props.itemsConnectionId,
						}),
					);
				}
			},
	},
});

export const useHierarchyStore = createHook(Store);

export const useHierarchyActions = createActionsHook(Store);

export const useIsItemExpanded = createHook<State, Actions, boolean, ItemDetails>(Store, {
	selector: (state: State, itemDetails: ItemDetails) => {
		if (!itemDetails) {
			return false;
		}

		const { itemsConnectionId, itemId, groupId } = itemDetails;
		const key = getItemKey(itemsConnectionId, itemId, groupId);
		return state.expandedItems[key] ?? false;
	},
});

export const useIsSelected = createHook<State, Actions, boolean, ItemDetails>(Store, {
	selector: (state: State, itemDetails: ItemDetails) => {
		if (!itemDetails) {
			return false;
		}

		const { itemsConnectionId, itemId, groupId } = itemDetails;
		return state.selectedItem === getItemKey(itemsConnectionId, itemId, groupId);
	},
});

export const useIsFocused = createHook<State, Actions, boolean, ItemDetails>(Store, {
	selector: (state: State, itemDetails: ItemDetails) => {
		if (!itemDetails) {
			return false;
		}

		const { itemsConnectionId, itemId, groupId } = itemDetails;
		return state.focusedItem === getItemKey(itemsConnectionId, itemId, groupId);
	},
});

const getSelectedIndexSelector = (state: State) => {
	const index = state.listMetadata.findIndex((item) => item.key === state.selectedItem);

	if (index === -1) {
		return null;
	}

	return index;
};

export const useIsItemLoading = createHook<State, Actions, boolean, string | null>(Store, {
	selector: (state: State, itemId: string | null) => Boolean(itemId && state.loadingItems[itemId]),
});

export const useSelectedRow = createHook<State, Actions, number | null>(Store, {
	selector: getSelectedIndexSelector,
});

type TraverseType = (props: {
	itemsConnectionId: string;
	parentId: string;
	flatlist: string[];
	issueKeys: Array<string | null>;
	listMetadata: FlatListMetadata[];
	hierarchyLevels: number[];
	depth: number;
	groupId?: string;
}) => {
	flatlist: string[];
	issueKeys: Array<string | null>;
	listMetadata: FlatListMetadata[];
	hierarchyLevels: number[];
};

export const hierarchyToFlatTreePreOrdered = (state: State) => {
	const traverse: TraverseType = ({
		itemsConnectionId,
		parentId,
		flatlist,
		issueKeys,
		listMetadata,
		hierarchyLevels,
		depth,
		groupId,
	}) => {
		const key = getItemKey(itemsConnectionId, parentId, groupId);
		const connectionItem = state.connectionItems[key];
		if (!connectionItem) {
			return { flatlist, issueKeys, listMetadata, hierarchyLevels };
		}

		// itemGroupId is different from groupId at the root level
		connectionItem.forEach(({ itemId: id, key: issueKey, groupId: itemGroupId }) => {
			// groupId is needed in flatlist to make the issueId unique across multiple groups
			flatlist.push(getFlatListKey({ itemId: id, groupId: itemGroupId }));
			issueKeys.push(issueKey);

			const childConnectionKey = getItemKey(itemsConnectionId, id, itemGroupId);
			listMetadata.push({
				itemId: id,
				parentId,
				key: childConnectionKey,
				connectionId: itemsConnectionId,
				groupId: itemGroupId,
			});
			const isExpanded = state.expandedItems[childConnectionKey];

			if (isExpanded) {
				const currentDepth = depth + 1;

				if (!hierarchyLevels.includes(currentDepth)) {
					hierarchyLevels.push(currentDepth);
				}

				const childrenConnection = state.connectionMap[childConnectionKey];
				const { connectionId: childrenConnectionId } = getItemMetadata(childrenConnection);

				if (childrenConnectionId) {
					traverse({
						itemsConnectionId: childrenConnectionId,
						parentId: id,
						flatlist,
						issueKeys,
						listMetadata,
						hierarchyLevels,
						depth: currentDepth,
						groupId: itemGroupId,
					});
				}
			}
		});

		return { flatlist, issueKeys, listMetadata, hierarchyLevels };
	};
	const depth = 0;
	const flatlist: string[] = [];
	const issueKeys: string[] = [];
	const listMetadata: FlatListMetadata[] = [];
	const hierarchyLevels: number[] = [depth];

	if (!state.rootConnectionId) {
		return { flatlist, issueKeys, listMetadata, hierarchyLevels };
	}

	return traverse({
		itemsConnectionId: state.rootConnectionId,
		parentId: 'root',
		flatlist,
		issueKeys,
		listMetadata,
		hierarchyLevels,
		depth,
		// no groupId for root
	});
};

export const useVisibleDepth = createStateHook(Store, {
	selector: (state) => state.visibleDepth,
});

export const useFlatList = createStateHook(Store, {
	selector: (state) => state.flatList,
});

const getFlatListIssueIdsSelector = createSelector(
	(state: State) => state.flatList,
	(list) => filterNonIssueItems(list),
);

/**
 * @deprecated  Use useIssueIds instead.
 * 'useFlatListIssueIds' needs to be removed when cleaning up jira_list_grouping_bulk_ops
 */
export const useFlatListIssueIds = createStateHook(Store, {
	selector: getFlatListIssueIdsSelector,
});

const getIssueIdsSelector = createSelector(
	(state: State) => state.listMetadata,
	(listMetadata) =>
		listMetadata
			// filtering group headers out
			.filter(({ itemId, groupId }) => itemId !== groupId && !isSpecialItem(itemId))
			.map(({ itemId }) => itemId),
);

export const useIssueIds = createStateHook(Store, {
	selector: getIssueIdsSelector,
});

const getIssueKeysSelector = (state: State) => state.issueKeys;

const getFlatListIssueKeysSelector = createSelector(getIssueKeysSelector, (issueKeys) =>
	filterNonIssueItems(issueKeys),
);
export const useFlatListIssueKeys = createStateHook(Store, {
	selector: getFlatListIssueKeysSelector,
});

export const useIssueKeys = createHook(Store, {
	selector: getIssueKeysSelector,
});

const getSelectedIssueKeySelector = createSelector(
	getSelectedIndexSelector,
	getIssueKeysSelector,
	(selectedIndex, issueKeys) => {
		if (selectedIndex === null) {
			return null;
		}

		return issueKeys[selectedIndex];
	},
);

export const useHasExpandedItems = createStateHook(Store, {
	selector: (state) => Object.values(state.expandedItems).some((isExpanded) => isExpanded),
});

export const useSelectedIssueKey = createHook(Store, {
	selector: getSelectedIssueKeySelector,
});
