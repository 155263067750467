import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const AVATAR_SIZE = 24;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Avatar = styled.span<{ avatarUrl: string; isHighlighted: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundImage: ({ avatarUrl }) => `url(${avatarUrl})`,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundColor: 'transparent',
	borderRadius: '50%',
	boxSizing: 'content-box',
	height: `${AVATAR_SIZE}px`,
	width: `${AVATAR_SIZE}px`,
	minWidth: `${AVATAR_SIZE}px`,
	minHeight: `${AVATAR_SIZE}px`,
	display: 'inline-block',
	position: 'relative',
	outline: '0px',
	cursor: 'inherit',
	marginTop: token('space.025'),
	marginRight: token('space.025'),
	marginBottom: token('space.025'),
	marginLeft: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isHighlighted ? `0px 0px 0px 3px ${token('color.border.focused')}` : 'none',
});

export type Props = {
	name?: string;
	avatarUrl?: string;
	highlight?: string[];
	testId?: string;
};

const hasKeywords = (name: string) => (keyword: string) =>
	name && keyword && name.toLowerCase().includes(keyword.toLowerCase());

export const AvatarLite = memo<Props>(({ avatarUrl, name = '', highlight, testId }: Props) => {
	const isHighlighted = highlight && highlight.some(hasKeywords(name));

	return (
		<Avatar
			// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
			avatarUrl={avatarUrl}
			title={name}
			// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
			isHighlighted={isHighlighted}
			data-testid={testId}
		/>
	);
});
