/**
 * @generated SignedSource<<62e3116c435d80a798da8c375c0bf69d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labels_nativeIssueTable_LabelsInlineEditCell_fieldRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "labels_nativeIssueTable_LabelsInlineEditCell_fieldRef";
};
export type labels_nativeIssueTable_LabelsInlineEditCell_fieldRef$key = {
  readonly " $data"?: labels_nativeIssueTable_LabelsInlineEditCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"labels_nativeIssueTable_LabelsInlineEditCell_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "labels_nativeIssueTable_LabelsInlineEditCell_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraLabelsField"
};

(node as any).hash = "f76e2dc26627b6d7258218de93f192ad";

export default node;
