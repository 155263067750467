import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTextComponent = styled.div({
	marginTop: token('space.100'),
});

export const StyledText = (props: ComponentPropsWithoutRef<typeof StyledTextComponent>) => (
	<Heading size="xsmall">
		<StyledTextComponent {...props} />
	</Heading>
);
