import React, { useMemo, useCallback, useRef } from 'react';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import AddIcon from '@atlaskit/icon/core/add';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ColumnPickerContainer,
	useColumnPickerOpenState,
} from '@atlassian/jira-issue-table-column-picker/src/controllers/column-picker-open-state/index.tsx';
import { useVisibleDepth } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import AsyncColumnPicker from '@atlassian/jira-issue-table-column-picker/src/ui/async.tsx';
import type { Props as AsyncColumnPickerProps } from '@atlassian/jira-issue-table-column-picker/src/ui/main.tsx';
import { useInlineFieldCreateOnboardingContext } from '@atlassian/jira-native-issue-table/src/ui/inline-field-create-onboarding/index.tsx';
import { RowPopupButton } from '../../../common/ui/row-popup-button/index.tsx';
import { getExpandColumnWidth } from '../utils.tsx';
import {
	useInlineCreateActiveIndexActions,
	useInlineFieldCreateActiveIndex,
} from '../../../controllers/inline-create-active-index/index.tsx';
import { FIELD } from '../../../controllers/inline-create-active-index/types.tsx';
import {
	COLUMN_ID_EXPAND_BUTTON,
	COLUMN_ID_CHECKBOX,
	INSERT_COLUMN_BUTTON_Y_OFFSET,
	COLUMN_PICKER_ADD_COLUMN_CONTAINER,
	COLUMN_ID_INTERNAL_COLUMN,
} from '../../../common/constants.tsx';
import type { Column } from '../../../common/types.tsx';
import { useIsInlineFieldConfigEnabled } from '../../../controllers/features/selectors.tsx';
import { IFCFooter } from '../../../common/ui/inline-field-config/column-picker-footer/index.tsx';

export type InsertColumnButtonProps = {
	columns: Column[];
	columnPickerProps?: AsyncColumnPickerProps;
};

export const InsertColumnButton = ({ columns, columnPickerProps }: InsertColumnButtonProps) => {
	const [{ isOpen }] = useColumnPickerOpenState();
	const [{ index: columnIndex }] = useInlineFieldCreateActiveIndex();
	const { lockIndex, clearIndex, clearIndexAfterDelay, setIndex } =
		useInlineCreateActiveIndexActions();
	const { showInlineFieldCreateOnboarding } = useInlineFieldCreateOnboardingContext();
	const isInlineFieldConfigEnabled = useIsInlineFieldConfigEnabled();
	let visibleDepth = 0;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		visibleDepth = useVisibleDepth();
	}

	const firstFieldColumnIndex = useMemo(() => {
		return columns.findIndex((column) => !column.id.startsWith(COLUMN_ID_INTERNAL_COLUMN));
	}, [columns]);

	const hasShownOnboarding = useRef(false);

	const shouldShowOnboarding = showInlineFieldCreateOnboarding && !hasShownOnboarding.current;

	if (shouldShowOnboarding) {
		setIndex(FIELD, firstFieldColumnIndex);
		lockIndex(FIELD);
		hasShownOnboarding.current = true;
	}

	const columnsWidth = columns.map((column) => {
		if (column.id === COLUMN_ID_EXPAND_BUTTON) {
			return getExpandColumnWidth(visibleDepth);
		}
		return column.customWidth ?? column.defaultWidth;
	});

	const fixedColumnCount = columns.filter(
		(column) => column.id === COLUMN_ID_EXPAND_BUTTON || column.id === COLUMN_ID_CHECKBOX,
	).length;

	const isEmptyColumnExist = columnsWidth.length - columns.length === 1;

	const position = useMemo(() => {
		if (columnIndex != null && columnsWidth.length > 0) {
			return columnsWidth.slice(0, columnIndex + 1).reduce((acc, width) => acc + width, 0);
		}
		return 0;
	}, [columnIndex, columnsWidth]);

	const handleMouseEnter = useCallback(() => {
		lockIndex(FIELD);
	}, [lockIndex]);

	const handleMouseLeave = useCallback(() => {
		!isOpen && clearIndexAfterDelay(FIELD);
	}, [clearIndexAfterDelay, isOpen]);

	if (
		columnIndex == null ||
		(isEmptyColumnExist && columnIndex >= columnsWidth.length - 3) || // If an empty column exists, prevent inserting in the last two columns.
		columnIndex >= columnsWidth.length - 2 || // Prevent inserting on the left or right of the last column.
		columnIndex < fixedColumnCount - 1 // Prevent inserting between fixed columns.
	) {
		if (!shouldShowOnboarding) {
			clearIndex(FIELD);
		}
		return null;
	}

	if (columnPickerProps) {
		const { components, ...props } = columnPickerProps;
		return (
			<RowPopupButton
				alignToYAxis={false}
				icon={
					<ColumnPickerContainer scope={COLUMN_PICKER_ADD_COLUMN_CONTAINER}>
						<AsyncColumnPicker
							{...props}
							components={{
								...components,
								Icon: AddIcon,
								...(isInlineFieldConfigEnabled ? { Footer: IFCFooter } : {}),
							}}
							columnInsertIndex={columnIndex - fixedColumnCount + 1}
							isInBetweenColumnPickerEnabled
							showInlineFieldCreateOnboarding={showInlineFieldCreateOnboarding}
							isInfiniteScrollEnabled
						/>
					</ColumnPickerContainer>
				}
				isVisible={columnIndex !== undefined}
				offset={[position, INSERT_COLUMN_BUTTON_Y_OFFSET]}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				xcss={styles.button}
				withSpotlight={columnIndex === firstFieldColumnIndex && shouldShowOnboarding}
			/>
		);
	}
	return null;
};

const styles = cssMap({
	button: {
		height: '24px',
		width: '24px',
		position: 'relative',
		borderStyle: 'solid',
		borderWidth: token('border.width'),
		borderColor: token('color.border'),
		boxShadow: token('elevation.shadow.overlay'),
	},
});
