/**
 * @generated SignedSource<<2eecf37369c6c8c0d95dae02e8db59b1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type team_issueFieldTeamReadviewFull_TeamReadView$data = {
  readonly selectedTeam: {
    readonly jiraSuppliedId: string;
    readonly jiraSuppliedName: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "team_issueFieldTeamReadviewFull_TeamReadView";
};
export type team_issueFieldTeamReadviewFull_TeamReadView$key = {
  readonly " $data"?: team_issueFieldTeamReadviewFull_TeamReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"team_issueFieldTeamReadviewFull_TeamReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "team_issueFieldTeamReadviewFull_TeamReadView",
  "selections": [
    {
      "concreteType": "JiraTeamView",
      "kind": "LinkedField",
      "name": "selectedTeam",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedId"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedName"
        }
      ]
    }
  ],
  "type": "JiraTeamViewField"
};

(node as any).hash = "69af0ce99580e256b8a8c95839ad6bd2";

export default node;
