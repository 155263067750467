/**
 * @generated SignedSource<<d9d7de21d373ab34aa3f2bef43850869>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type sprintGroupHeader_nativeIssueTable$data = {
  readonly displayName: string;
  readonly sprint: {
    readonly state: JiraSprintState | null | undefined;
  };
  readonly " $fragmentType": "sprintGroupHeader_nativeIssueTable";
};
export type sprintGroupHeader_nativeIssueTable$key = {
  readonly " $data"?: sprintGroupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"sprintGroupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "sprintGroupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "displayName"
    },
    {
      "concreteType": "JiraSprint",
      "kind": "LinkedField",
      "name": "sprint",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "state"
        }
      ]
    }
  ],
  "type": "JiraJqlSprintFieldValue"
};

(node as any).hash = "cf66e29052fee44caad52adffe8afb1a";

export default node;
