/**
 * @generated SignedSource<<14a01d21f77762b8bf6dc1773a908d06>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labels_nativeIssueTable_LabelsCell$data = {
  readonly fieldId: string;
  readonly selectedLabelsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"labels_nativeIssueTable_LabelsInlineEditCell_fieldRef">;
  readonly " $fragmentType": "labels_nativeIssueTable_LabelsCell";
};
export type labels_nativeIssueTable_LabelsCell$key = {
  readonly " $data"?: labels_nativeIssueTable_LabelsCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"labels_nativeIssueTable_LabelsCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "labels_nativeIssueTable_LabelsCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "name"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "labels_nativeIssueTable_LabelsInlineEditCell_fieldRef"
    }
  ],
  "type": "JiraLabelsField"
};

(node as any).hash = "4d3abd4e5894c74a98fcfca725bffcab";

export default node;
