import React from 'react';
import { graphql, useFragment } from 'react-relay';
import SimpleTag from '@atlaskit/tag/simple-tag';
import { useIntl } from '@atlassian/jira-intl';
import type { sprintGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/sprintGroupHeader_nativeIssueTable.graphql';
import messages from './messages.tsx';

export type Props = {
	sprintGroupHeader: sprintGroupHeader_nativeIssueTable$key | null;
};

export const SprintGroupHeader = ({ sprintGroupHeader }: Props) => {
	const data = useFragment<sprintGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment sprintGroupHeader_nativeIssueTable on JiraJqlSprintFieldValue {
				displayName
				sprint {
					state
				}
			}
		`,
		sprintGroupHeader,
	);

	const { formatMessage } = useIntl();

	let text;
	if (!data) {
		text = formatMessage(messages.backlog);
	} else if (data.sprint?.state === 'CLOSED') {
		text = formatMessage(messages.completedSprint);
	} else {
		text = data.displayName;
	}

	return <SimpleTag text={text} />;
};
