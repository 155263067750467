import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type {
	NullableMultiUserPickerOption,
	NullableAggMultiUserPickerOption,
	MultiUserPickerEditViewProps,
} from '@atlassian/jira-issue-field-multi-user-picker-editview-full/src/ui/multi-user-picker/types.tsx';
import { transformAggToUserOption } from '@atlassian/jira-issue-user-picker-edit-view/src/utils/transform-agg-to-user-option/index.tsx';
import MultiUserPickerEditViewEntryPoint from '@atlassian/jira-issue-field-multi-user-picker-editview-full/src/entrypoint.tsx';
import { MultiUserPickerReadView } from '@atlassian/jira-issue-field-multi-user-picker-readview-full/src/ui/multi-user-picker/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { multiUserPicker_issueFieldMultiUserPicker_MultiUserPickerField_Mutation as MultiUserPickerMutation } from '@atlassian/jira-relay/src/__generated__/multiUserPicker_issueFieldMultiUserPicker_MultiUserPickerField_Mutation.graphql';
import type { multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditView_fragmentRef$key as MultiUserPickerFragment } from '@atlassian/jira-relay/src/__generated__/multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditView_fragmentRef.graphql';
import type { multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef$key as MultiUserPickerWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef.graphql';
import type {
	MultiUserPickerInlineEditViewProps,
	MultiUserPickerInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (
	a: NullableAggMultiUserPickerOption,
	b: NullableAggMultiUserPickerOption,
) => {
	if (a === null || b === null) return a === b;
	if (a.length !== b.length) return false;

	const aIdSet = new Set(a.map((option) => option.id));
	return b.every((option) => aIdSet.has(option.id));
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `MultiUserPickerInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [MultiUserPickerInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const MultiUserPickerInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
}: MultiUserPickerInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<MultiUserPickerWithIsEditableFragment>(
		graphql`
			fragment multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef on JiraMultipleSelectUserPickerField {
				id
				name
				fieldId
				type
				...multiUserPicker_issueFieldMultiUserPickerReadviewFull_MultiUserPickerReadView
				selectedUsersConnection {
					edges {
						node {
							id
							# eslint-disable-next-line @atlassian/relay/unused-fields
							accountId
							# eslint-disable-next-line @atlassian/relay/unused-fields
							accountStatus
							name
							# eslint-disable-next-line @atlassian/relay/unused-fields
							picture
							... on AtlassianAccountUser {
								# eslint-disable-next-line @atlassian/relay/unused-fields
								email
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<MultiUserPickerMutation>(graphql`
		mutation multiUserPicker_issueFieldMultiUserPicker_MultiUserPickerField_Mutation(
			$input: JiraUpdateMultipleSelectUserPickerFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateMultipleSelectUserPickerField(input: $input) {
					success
					errors {
						message
					}
					field {
						...multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { id: uniqueFieldId, name, selectedUsersConnection, fieldId, type: fieldType } = data;

	const initialValue: NullableAggMultiUserPickerOption = useMemo(() => {
		if (!selectedUsersConnection?.edges) {
			return null;
		}

		return selectedUsersConnection?.edges?.map((edge) => edge?.node).filter(Boolean) ?? [];
	}, [selectedUsersConnection?.edges]);

	const [updatedValue, setUpdatedValue] = useState<NullableAggMultiUserPickerOption>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);
	// #endregion Common state

	// #region Actions
	const handleSubmit = useCallback(
		(users: NullableAggMultiUserPickerOption, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(users);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								operation: 'SET',
								ids: users?.map((user) => user.id) ?? [],
							},
						],
					},
				},
				onCompleted(response) {
					if (response.jira?.updateMultipleSelectUserPickerField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateMultipleSelectUserPickerField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedUsersConnection: {
									edges:
										users?.map((user) => ({
											node: {
												__typename: 'AtlassianAccountUser',
												...user,
											},
										})) ?? [],
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		handleCopy,
		handleCut,
		handlePaste,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
		clipboardComponentType: 'multiUserPicker',
	});
	// #endregion Actions

	// #region Read view
	const renderReadView = useCallback(() => <MultiUserPickerReadView fragmentRef={data} />, [data]);
	// #endregion

	// #region Edit view
	const transformedEditViewValue = useMemo<NullableMultiUserPickerOption>(() => {
		if (!updatedValue) return null;
		return updatedValue.map(transformAggToUserOption);
	}, [updatedValue]);

	const getEditViewProps = (fieldProps: ValidationFieldProps): MultiUserPickerEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		value: transformedEditViewValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChange,
		spacing,
	});
	// #endregion

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={MultiUserPickerEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [MultiUserPickerInlineEditViewProps](./types.tsx)
 */
export const MultiUserPickerInlineEditView = ({
	fragmentRef,
	...props
}: MultiUserPickerInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<MultiUserPickerFragment>(
		graphql`
			fragment multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditView_fragmentRef on JiraMultipleSelectUserPickerField {
				...multiUserPicker_issueFieldMultiUserPickerInlineEditFull_MultiUserPickerInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	// #endregion

	return (
		<MultiUserPickerInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
