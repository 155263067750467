import React, { memo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { StoryPointEstimateInlineEditViewIsEditable } from '@atlassian/jira-issue-field-story-point-estimate-inline-edit-full/src/ui/story-point-estimate/index.tsx';
import type { storyPointEstimate_nativeIssueTable_StoryPointEstimateCell$key } from '@atlassian/jira-relay/src/__generated__/storyPointEstimate_nativeIssueTable_StoryPointEstimateCell.graphql';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';

export type Props = {
	fieldRef: storyPointEstimate_nativeIssueTable_StoryPointEstimateCell$key;
};

export const StoryPointEstimateCell = memo(({ fieldRef }: Props) => {
	const data = useFragment<storyPointEstimate_nativeIssueTable_StoryPointEstimateCell$key>(
		graphql`
			fragment storyPointEstimate_nativeIssueTable_StoryPointEstimateCell on JiraNumberField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<StoryPointEstimateInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
		/>
	);
});
