import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadStartA11YMessageTop: {
		id: 'native-issue-table.scroll-container.loading-manager.load-start-a-11-y-message-top',
		defaultMessage: 'Loading more issues at the top of the table.',
		description: 'Screen reader announcement for when loading starts at the top of the table',
	},
	loadEndA11YMessageTop: {
		id: 'native-issue-table.scroll-container.loading-manager.load-end-a-11-y-message-top',
		defaultMessage: 'Finished loading more issues at the top of the table.',
		description: 'Screen reader announcement for when loading has finished at the top of the table',
	},
	loadStartA11YMessageBottom: {
		id: 'native-issue-table.scroll-container.loading-manager.load-start-a-11-y-message-bottom',
		defaultMessage: 'Loading more issues at the bottom of the table.',
		description: 'Screen reader announcement for when loading starts at the bottom of the table',
	},
	loadEndA11YMessageBottom: {
		id: 'native-issue-table.scroll-container.loading-manager.load-end-a-11-y-message-bottom',
		defaultMessage: 'Finished loading more issues at the bottom of the table.',
		description:
			'Screen reader announcement for when loading has finished at the bottom of the table',
	},
	loadStartA11YMessageTopIssueTermRefresh: {
		id: 'native-issue-table.scroll-container.loading-manager.load-start-a-11-y-message-top-issue-term-refresh',
		defaultMessage: 'Loading more work items at the top of the table.',
		description: 'Screen reader announcement for when loading starts at the top of the table',
	},
	loadEndA11YMessageTopIssueTermRefresh: {
		id: 'native-issue-table.scroll-container.loading-manager.load-end-a-11-y-message-top-issue-term-refresh',
		defaultMessage: 'Finished loading more work items at the top of the table.',
		description: 'Screen reader announcement for when loading has finished at the top of the table',
	},
	loadStartA11YMessageBottomIssueTermRefresh: {
		id: 'native-issue-table.scroll-container.loading-manager.load-start-a-11-y-message-bottom-issue-term-refresh',
		defaultMessage: 'Loading more work items at the bottom of the table.',
		description: 'Screen reader announcement for when loading starts at the bottom of the table',
	},
	loadEndA11YMessageBottomIssueTermRefresh: {
		id: 'native-issue-table.scroll-container.loading-manager.load-end-a-11-y-message-bottom-issue-term-refresh',
		defaultMessage: 'Finished loading more work items at the bottom of the table.',
		description:
			'Screen reader announcement for when loading has finished at the bottom of the table',
	},
	loadStartA11YMessageTopWithGrouping: {
		id: 'native-issue-table.scroll-container.loading-manager.load-start-a-11-y-message-top-with-grouping',
		defaultMessage: 'Loading more groups at the top of the table.',
		description:
			'Screen reader announcement for when loading of groups starts at the top of the table',
	},
	loadEndA11YMessageTopWithGrouping: {
		id: 'native-issue-table.scroll-container.loading-manager.load-end-a-11-y-message-top-with-grouping',
		defaultMessage: 'Finished loading more groups at the top of the table.',
		description:
			'Screen reader announcement for when loading of groups has finished at the top of the table',
	},
	loadStartA11YMessageBottomWithGrouping: {
		id: 'native-issue-table.scroll-container.loading-manager.load-start-a-11-y-message-bottom-with-grouping',
		defaultMessage: 'Loading more groups at the bottom of the table.',
		description:
			'Screen reader announcement for when loading of groups starts at the bottom of the table',
	},
	loadEndA11YMessageBottomWithGrouping: {
		id: 'native-issue-table.scroll-container.loading-manager.load-end-a-11-y-message-bottom-with-grouping',
		defaultMessage: 'Finished loading more groups at the bottom of the table.',
		description:
			'Screen reader announcement for when loading of groups has finished at the bottom of the table',
	},
});
