import { useCallback } from 'react';
import { applyOptimisticMutation, commitMutation } from 'react-relay';
import type { Disposable, IEnvironment, MutationConfig, MutationParameters } from 'relay-runtime';

type OnCompletedParameters<TOperation extends MutationParameters> = Parameters<
	NonNullable<Required<MutationConfig<TOperation>>['onCompleted']>
>;
type RetryProps<TOperation extends MutationParameters> = {
	retries: number;
	isValidOnCompleted: (...args: OnCompletedParameters<TOperation>) => boolean;
};

/**
 * Returns a commit function to be provided to the `useMutation` hook that retries the mutation requests based
 * on the props passed.
 *
 * Example usage:
 * ```
 * const retryCommit = useMutationWithRetriesCommit({
 *		retries: 1,
 *		isValidOnComplete: .... // false means retry
 * });
 * const [commit] = useMutation(
 * 		graphql`
 * 			# Your GraphQL mutation
 * 		`,
 * 		retryCommit,
 * 	);
 * ```
 */
export const useMutationWithRetriesCommit = <TMutation extends MutationParameters>({
	retries,
	isValidOnCompleted,
}: RetryProps<TMutation>) => {
	return useCallback(
		(environment: IEnvironment, config: MutationConfig<TMutation>): Disposable => {
			let disposable: Disposable | null;
			let optimisticDisposable: Disposable | null = null;

			const handleCommitWithRetries = ({ retriesLeft }: { retriesLeft: number }) => {
				disposable = commitMutation(environment, {
					...config,
					onCompleted: (response, errors) => {
						if (isValidOnCompleted(response, errors)) {
							config.onCompleted?.(response, errors);
							return;
						}

						if (retriesLeft > 0) {
							optimisticDisposable = applyOptimisticMutation(environment, config);
							// Wait 100ms before retrying
							const timeout = setTimeout(() => {
								optimisticDisposable?.dispose();
								handleCommitWithRetries({ retriesLeft: retriesLeft - 1 });
							}, 100);

							disposable = {
								dispose: () => {
									optimisticDisposable?.dispose();
									clearTimeout(timeout);
								},
							};
							return;
						}

						// Last retry needs to call onComplete
						config.onCompleted?.(response, errors);
					},
				});
			};

			handleCommitWithRetries({ retriesLeft: retries });

			// Return a new disposable that cleans up the optimistic AND committed operations
			return {
				dispose: () => {
					disposable?.dispose();
				},
			};
		},
		[isValidOnCompleted, retries],
	);
};
