import { useMemo, type MutableRefObject } from 'react';
import isEqual from 'lodash/isEqual';
import type { VirtualItem } from '@tanstack/virtual-core';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ROW_HEIGHT } from '../../../common/constants.tsx';
import { useScrollElement } from '../../../controllers/scroll-state/index.tsx';
import { useSSRSafeVirtualizer } from '../../../controllers/virtualization/index.tsx';
import {
	useVirtualizationActions,
	type VirtualItemsMap,
} from '../../../controllers/virtualization/rss-store.tsx';

export type Props = {
	rows: number;
	// make setTableHeight required and remove totalSizeRef when cleaning up jira_list_virtualizer_table_height
	totalSizeRef: MutableRefObject<number>;
	getItemKey: (index: number) => number | string | bigint;
};

const estimateSize = () => ROW_HEIGHT + 1; // include border

export const InitializeVirtualizer = ({ rows = 0, getItemKey, totalSizeRef }: Props) => {
	const scrollElement = useScrollElement();
	const { setVirtualizer, setVirtualItemsMap } = useVirtualizationActions();

	const options = useMemo(
		() => ({
			count: rows,
			getScrollElement: () => scrollElement,
			estimateSize,
			getItemKey,
			overscan: 10,
		}),
		[getItemKey, rows, scrollElement],
	);

	const virtualizer = useSSRSafeVirtualizer(options);
	const totalSize = virtualizer?.getTotalSize();
	const items = virtualizer?.getVirtualItems();

	// eslint-disable-next-line no-param-reassign
	totalSizeRef.current = totalSize ?? 0;

	const prevVirtualizer = usePrevious(virtualizer);
	const prevItems = usePrevious(items);

	if (fg('empanada_nin_concurrent_mode_fixes')) {
		if (prevVirtualizer !== virtualizer) {
			setVirtualizer(virtualizer, {});
		}

		if (!isEqual(prevItems, items)) {
			const virtualItemsMap = items?.reduce((acc: VirtualItemsMap, item: VirtualItem) => {
				acc[String(item.key)] = true;
				return acc;
			}, {});

			setVirtualItemsMap(virtualItemsMap);
		}
	} else {
		const virtualItemsMap = items?.reduce((acc: VirtualItemsMap, item: VirtualItem) => {
			acc[String(item.key)] = true;
			return acc;
		}, {});

		setVirtualizer(virtualizer, virtualItemsMap);
	}

	return null;
};
