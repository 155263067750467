/**
 * @generated SignedSource<<f9532ce6b28757b3b3005918e052f046>>
 * @relayHash 971fe8b1ad10658582f7679d784d64e8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c7f9afa80a2b652f54ea8f4e61b3378c65523f1bd8088be68a74b4d590adbf79

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchViewContextInput = {
  expandedGroups?: JiraIssueExpandedGroups | null | undefined;
  expandedParents?: ReadonlyArray<JiraIssueExpandedParent> | null | undefined;
};
export type JiraIssueExpandedGroups = {
  groupedByFieldId: string;
  groups?: ReadonlyArray<JiraIssueExpandedGroup> | null | undefined;
};
export type JiraIssueExpandedGroup = {
  fieldValue?: string | null | undefined;
  first?: number | null | undefined;
  jql: string;
};
export type JiraIssueExpandedParent = {
  first?: number | null | undefined;
  parentIssueId: string;
};
export type realtimeUpdaterOld_issueCreated_Subscription$variables = {
  cloudId: string;
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  projectId: string;
  searchViewContextInput: JiraIssueSearchViewContextInput;
};
export type realtimeUpdaterOld_issueCreated_Subscription$data = {
  readonly jira: {
    readonly onIssueCreatedByProject: {
      readonly __id: string;
      readonly id: string;
      readonly parentIssueField: {
        readonly parentIssue: {
          readonly __id: string;
        } | null | undefined;
      } | null | undefined;
      readonly searchViewContext: {
        readonly contexts: ReadonlyArray<{
          readonly afterIssueId: string | null | undefined;
          readonly beforeIssueId: string | null | undefined;
          readonly jql?: string | null | undefined;
          readonly parentIssueId?: string | null | undefined;
          readonly position: number | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type realtimeUpdaterOld_issueCreated_Subscription = {
  response: realtimeUpdaterOld_issueCreated_Subscription$data;
  variables: realtimeUpdaterOld_issueCreated_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isGroupingEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHierarchyEnabled"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchViewContextInput"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "projectId",
    "variableName": "projectId"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = [
  {
    "kind": "Variable",
    "name": "isGroupingEnabled",
    "variableName": "isGroupingEnabled"
  },
  {
    "kind": "Variable",
    "name": "isHierarchyEnabled",
    "variableName": "isHierarchyEnabled"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "searchViewContextInput",
    "variableName": "searchViewContextInput"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "afterIssueId"
},
v10 = {
  "kind": "ScalarField",
  "name": "beforeIssueId"
},
v11 = {
  "kind": "ScalarField",
  "name": "position"
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "parentIssueId"
    }
  ],
  "type": "JiraIssueSearchViewContextMappingByParent"
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jql"
    }
  ],
  "type": "JiraIssueSearchViewContextMappingByGroup"
},
v14 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "realtimeUpdaterOld_issueCreated_Subscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "onIssueCreatedByProject",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "args": (v8/*: any*/),
                "concreteType": "JiraIssueSearchViewContexts",
                "kind": "LinkedField",
                "name": "searchViewContext",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "contexts",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraParentIssueField",
                "kind": "LinkedField",
                "name": "parentIssueField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/)
                    ]
                  }
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "realtimeUpdaterOld_issueCreated_Subscription",
    "selections": [
      {
        "concreteType": "JiraSubscription",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "onIssueCreatedByProject",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "args": (v8/*: any*/),
                "concreteType": "JiraIssueSearchViewContexts",
                "kind": "LinkedField",
                "name": "searchViewContext",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "contexts",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraParentIssueField",
                "kind": "LinkedField",
                "name": "parentIssueField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "parentIssue",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v14/*: any*/)
                    ]
                  },
                  (v7/*: any*/)
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c7f9afa80a2b652f54ea8f4e61b3378c65523f1bd8088be68a74b4d590adbf79",
    "metadata": {},
    "name": "realtimeUpdaterOld_issueCreated_Subscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "f5bc25641acf171db78dc54685ab02e1";

export default node;
