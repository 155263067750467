/**
 * @generated SignedSource<<2d57f69fb6ad9a352f21f1cb6aee4989>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleSelect_nativeIssueTable_SingleSelectCell$data = {
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef">;
  readonly " $fragmentType": "singleSelect_nativeIssueTable_SingleSelectCell";
};
export type singleSelect_nativeIssueTable_SingleSelectCell$key = {
  readonly " $data"?: singleSelect_nativeIssueTable_SingleSelectCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_nativeIssueTable_SingleSelectCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "singleSelect_nativeIssueTable_SingleSelectCell",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectField"
};

(node as any).hash = "aa28f66296203077a00025e9c2e0ba1b";

export default node;
