import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	menuButton: {
		id: 'issue-navigator-issue-operations.menu-button',
		defaultMessage: 'Issue operations menu',
		description: 'Label for Menu Button',
	},
	menuButtonLabel: {
		id: 'issue-navigator-issue-operations.menu-button-label',
		defaultMessage: 'More actions for {issueKey}',
		description: 'Label for issue operations menu',
	},
	disabledMenuButtonLabel: {
		id: 'issue-navigator-issue-operations.disabled-menu-button-label',
		defaultMessage: 'Disabled more actions button for {issueKey}',
		description: 'Label for disabled issue operations menu fallback',
	},
});
