import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completedSprint: {
		id: 'native-issue-table.row.group-row.group-header.sprint-group-header.completed-sprint',
		defaultMessage: 'Completed sprint',
		description: 'Message to show that the sprint is completed',
	},
	backlog: {
		id: 'native-issue-table.row.group-row.group-header.sprint-group-header.backlog',
		defaultMessage: 'Backlog',
		description: 'Label for grouped items without sprint and therefore in the backlog',
	},
});
