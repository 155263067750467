import React, { type ReactNode, memo, type RefCallback } from 'react';
import { styled } from '@compiled/react';
import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	type UIAnalyticsEvent,
	ContextualAnalyticsData,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics-component';
import { MAX_LINE_WRAP_OLD } from '../../constants.tsx';
import { PopupContainer } from './popup-container/index.tsx';

export type Props = {
	triggerText: string | null;
	content: ReactNode;
	triggerRefCallback?: RefCallback<HTMLElement>;
	onSetIsOpen: (isOpen: boolean) => void;
	isOpen: boolean;
	maxLength?: number;
};

export const TruncatedTextWithPopup = memo<Props>(
	({
		triggerText,
		content,
		triggerRefCallback,
		onSetIsOpen,
		isOpen,
		maxLength = MAX_LINE_WRAP_OLD,
	}: Props) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		if (!triggerText) {
			return null;
		}

		const onClick = () => {
			onSetIsOpen(!isOpen);
			const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, 'truncatedTextWithPopupButton');
		};

		const trigger = (triggerProps: TriggerProps) => (
			<TruncatedTextContainer
				onClick={onClick}
				{...triggerProps}
				ref={(element) => {
					triggerRefCallback && triggerRefCallback(element);
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(triggerProps.ref as RefCallback<HTMLElement>)(element);
				}}
			>
				<TruncatedText maxLength={maxLength}>{triggerText}</TruncatedText>
			</TruncatedTextContainer>
		);

		return (
			<Popup
				isOpen={isOpen}
				onClose={() => onSetIsOpen(false)}
				messageId="native-issue-table.common.ui.truncated-text-cell-with-popup.popup"
				messageType="transactional"
				content={() => (
					<ContextualAnalyticsData sourceName="TruncatedTextPopUpContent" sourceType={SCREEN}>
						{content}
						<FireScreenAnalytics />
						<ShortcutScope />
					</ContextualAnalyticsData>
				)}
				trigger={trigger}
				placement="bottom"
				popupComponent={PopupContainer}
			/>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TruncatedTextContainer = styled.button({
	cursor: 'pointer',
	display: 'block',
	background: 'none',
	border: 'none',
	boxSizing: 'border-box',
	font: token('font.body'),

	color: token('color.text'),
	height: '100%',
	width: '100%',
	paddingBlock: token('space.050'),
	paddingInline: token('space.100'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
	'&:focus': {
		outline: 'none',

		boxShadow: `inset 0 0 0 2px ${token('color.border.focused')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[aria-expanded="true"]': {
		boxShadow: 'none',

		backgroundColor: token('color.background.accent.blue.subtler'),
	},
});

type SpanProps = {
	maxLength: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TruncatedText = styled.span<SpanProps>({
	textAlign: 'left',
	display: '-webkit-box',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	WebkitBoxOrient: 'vertical',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	WebkitLineClamp: ({ maxLength }) => maxLength,
	wordBreak: 'break-word',
});
