/**
 * @generated SignedSource<<6c164702c40075e34ab623520c824742>>
 * @relayHash 051016dfcf9e401f884ca67365f0371e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e98d56de3e93ca9040d141a36a8093249f9b3e74a8ae608f2911ed4fd5be5b2f

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type realtimeUpdaterOld_groupsForIssue_Query$variables = {
  fieldId: string;
  firstNGroupsToSearch: number;
  issueId: string;
  issueSearchInput: JiraIssueSearchInput;
};
export type realtimeUpdaterOld_groupsForIssue_Query$data = {
  readonly jira: {
    readonly issueById: {
      readonly groupsByFieldId: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __id: string;
            readonly afterGroupId: string | null | undefined;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"groupRow_nativeIssueTable_groupRow">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type realtimeUpdaterOld_groupsForIssue_Query = {
  response: realtimeUpdaterOld_groupsForIssue_Query$data;
  variables: realtimeUpdaterOld_groupsForIssue_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstNGroupsToSearch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "issueId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "fieldId",
    "variableName": "fieldId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "firstNGroupsToSearch",
    "variableName": "firstNGroupsToSearch"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "afterGroupId"
},
v8 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "displayName"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "realtimeUpdaterOld_groupsForIssue_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueById",
            "plural": false,
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraSpreadsheetGroupConnection",
                "kind": "LinkedField",
                "name": "groupsByFieldId",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSpreadsheetGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraSpreadsheetGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "groupRow_nativeIssueTable_groupRow"
                          },
                          (v8/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "realtimeUpdaterOld_groupsForIssue_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueById",
            "plural": false,
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraSpreadsheetGroupConnection",
                "kind": "LinkedField",
                "name": "groupsByFieldId",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSpreadsheetGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraSpreadsheetGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "jql"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "fieldValue",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraPriority",
                                    "kind": "LinkedField",
                                    "name": "priority",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "priorityId"
                                      },
                                      (v6/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "iconUrl"
                                      }
                                    ]
                                  },
                                  (v10/*: any*/)
                                ],
                                "type": "JiraJqlPriorityFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "accountId"
                                      },
                                      (v6/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "picture"
                                      }
                                    ]
                                  },
                                  (v10/*: any*/)
                                ],
                                "type": "JiraJqlUserFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/)
                                    ]
                                  },
                                  (v10/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "statusCategoryId"
                                      },
                                      (v6/*: any*/)
                                    ]
                                  }
                                ],
                                "type": "JiraJqlStatusFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "number"
                                  }
                                ],
                                "type": "JiraJqlNumberFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "option",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "optionId"
                                      },
                                      (v6/*: any*/),
                                      {
                                        "concreteType": "JiraColor",
                                        "kind": "LinkedField",
                                        "name": "color",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "colorKey"
                                          },
                                          (v6/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v10/*: any*/)
                                ],
                                "type": "JiraJqlOptionFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraSprint",
                                    "kind": "LinkedField",
                                    "name": "sprint",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "sprintId"
                                      },
                                      (v6/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "state"
                                      }
                                    ]
                                  },
                                  (v10/*: any*/)
                                ],
                                "type": "JiraJqlSprintFieldValue"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JiraGoal",
                                    "kind": "LinkedField",
                                    "name": "goal",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "status"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "score"
                                      }
                                    ]
                                  },
                                  (v10/*: any*/)
                                ],
                                "type": "JiraJqlGoalsFieldValue"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "fieldType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "issueCount"
                          },
                          (v8/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e98d56de3e93ca9040d141a36a8093249f9b3e74a8ae608f2911ed4fd5be5b2f",
    "metadata": {},
    "name": "realtimeUpdaterOld_groupsForIssue_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "90a9a11a485a511548dcf4b805f0f200";

export default node;
