import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createFieldBody: {
		id: 'native-issue-table.ui.inline-field-create-onboarding.create-field-body',
		defaultMessage: 'Create and configure fields without leaving your view.',
		description:
			'Onboarding content body, allowing users to create and configure fields within the table',
	},
	createFieldDismiss: {
		id: 'native-issue-table.ui.inline-field-create-onboarding.create-field-dismiss',
		defaultMessage: 'Dismiss',
		description: 'Dismiss label to dismiss the onboarding',
	},
	inlineColumnCreateBody: {
		id: 'native-issue-table.ui.inline-field-create-onboarding.inline-column-create-body',
		defaultMessage: 'Select <b>+</b> to quickly add columns exactly where you want them.',
		description:
			'Onboarding content body, allowing users to create columns and insert columns between existing columns in the table',
	},
	nextButtonText: {
		id: 'native-issue-table.ui.inline-field-create-onboarding.next-button-text',
		defaultMessage: 'Next',
		description: 'Next button text to navigate to the next step of the onboarding',
	},
});
