import React, { useMemo } from 'react';
import { ROW_HEIGHT } from '../../../common/constants.tsx';
import { useScrollElement } from '../../../controllers/scroll-state/index.tsx';
import {
	VirtualizationProvider,
	useSSRSafeVirtualizer,
} from '../../../controllers/virtualization/index.tsx';

export type Props = {
	rows: number;
	getItemKey: (index: number) => number | string | bigint;
	children: (style?: React.CSSProperties) => React.ReactElement;
};

const estimateSize = () => ROW_HEIGHT + 1; // include border

export const VirtualizedTable = ({ children, rows = 0, getItemKey }: Props) => {
	const scrollElement = useScrollElement();
	const options = useMemo(
		() => ({
			count: rows,
			getScrollElement: () => scrollElement,
			estimateSize,
			getItemKey,
			overscan: 10,
		}),
		[getItemKey, rows, scrollElement],
	);
	const virtualizer = useSSRSafeVirtualizer(options);
	const totalSize = virtualizer?.getTotalSize();

	return (
		<VirtualizationProvider value={virtualizer}>
			{children({
				height: `${totalSize}px`,
				width: '100%',
				position: 'relative',
			})}
		</VirtualizationProvider>
	);
};
