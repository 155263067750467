import React, { memo, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { OPERATOR_EQUALS, creators, print } from '@atlaskit/jql-ast';
import { SprintInlineEditViewIsEditable } from '@atlassian/jira-issue-field-sprint-inline-edit-full/src/ui/sprint/index.tsx';
import type { sprint_nativeIssueTable_SprintCell$key } from '@atlassian/jira-relay/src/__generated__/sprint_nativeIssueTable_SprintCell.graphql';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useRestrictSprints } from '../../../../controllers/restrict-sprints/index.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { escapeBackslashes } from '../../../utils/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';

export type Props = {
	fieldRef: sprint_nativeIssueTable_SprintCell$key;
	issueSearchBaseUrl: string;
	jqlTerm: string;
	projectKey: ProjectKey;
};

/**
 * Expected JQL Output: `${jqlTerm} = ${sprintId}`
 */
const getSprintJql = (jqlTerm: string, sprintId: number) => {
	const jast = creators.jast(
		creators.query(
			creators.terminalClause(
				creators.field(escapeBackslashes(jqlTerm)),
				creators.operator(OPERATOR_EQUALS),
				creators.valueOperand(String(sprintId)),
			),
		),
	);

	return print(jast, {
		operatorCase: 'upper',
	});
};

export const SprintCell = memo<Props>(({ fieldRef, projectKey, issueSearchBaseUrl, jqlTerm }) => {
	const data = useFragment<sprint_nativeIssueTable_SprintCell$key>(
		graphql`
			fragment sprint_nativeIssueTable_SprintCell on JiraSprintField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
	useLazyLoadEditability(data.id, isEditable);

	const [restrictSprints, { onRestrictSprints }] = useRestrictSprints();

	const getUrl = useCallback(
		(sprintId: number): string => {
			const jql = getSprintJql(jqlTerm, sprintId);
			return `${issueSearchBaseUrl}?jql=${encodeURIComponent(jql)}`;
		},
		[issueSearchBaseUrl, jqlTerm],
	);

	return (
		<SprintInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			getUrl={getUrl}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			onRestrictSprints={onRestrictSprints}
			projectKey={projectKey}
			readViewFitContainerHeight
			restrictSprints={restrictSprints}
		/>
	);
});
