import React, { useCallback, useState, useEffect, memo, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import type {
	StatusTransition,
	StatusValue,
} from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { AsyncIssueStatusField } from '@atlassian/jira-issue-field-status/src/ui/async.tsx';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { issueStatus_nativeIssueTable_IssueStatusCell$key } from '@atlassian/jira-relay/src/__generated__/issueStatus_nativeIssueTable_IssueStatusCell.graphql';
import { toIssueKey, toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../../controllers/features/selectors.tsx';
import { HoverPopover } from '../../hover-popover/index.tsx';
import { toStatusValue, toJiraStatusField, type JiraStatusField } from './utils.tsx';

export type Props = {
	fieldRef: issueStatus_nativeIssueTable_IssueStatusCell$key;
	issueKey: string;
	issueId?: string;
	onUpdateCache?: (issueKey: string, fieldName: string, field: JiraStatusField) => void;
};

export const IssueStatusCell = memo<Props>(({ fieldRef, issueKey, onUpdateCache, issueId }) => {
	const data = useFragment<issueStatus_nativeIssueTable_IssueStatusCell$key>(
		graphql`
			fragment issueStatus_nativeIssueTable_IssueStatusCell on JiraStatusField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id @include(if: $isInlineEditingEnabled)
				status {
					name
					statusId
					statusCategory {
						statusCategoryId
					}
				}
			}
		`,

		fieldRef,
	);

	const { status } = data;
	const [value, setValue] = useState<JiraStatusField>(status);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const contentRef = useRef(null);

	useEffect(() => {
		setValue(status);
	}, [status]);

	const onSubmit = useCallback(
		(
			oldValue: StatusValue,
			newValue: StatusValue,
			meta: {
				transition: StatusTransition;
			},
			event: UIAnalyticsEvent,
		) => {
			// Mirrors the updated event fired from the issue view (with the addition of `isInlineEditing: true`) to
			// be used for core action tracking as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
			fireTrackAnalytics(event, 'issueStatus updated', {
				oldValId: oldValue.id,
				oldStatusCategoryId: oldValue.statusCategory.id,
				newValId: newValue.id,
				newStatusCategoryId: newValue.statusCategory.id,
				hasScreen: meta.transition.hasScreen,
				isConditional: meta.transition.isConditional,
				isInlineEditing: true,
			});
		},
		[],
	);

	if (
		value?.statusCategory?.statusCategoryId === undefined ||
		!value?.name?.length ||
		!value?.statusId?.length
	) {
		return <ErrorCell cellType="status" reason="Missing required fields" />;
	}

	const onSuccess = (newStatus: StatusValue) => {
		const transformedStatus = toJiraStatusField(newStatus);
		setValue(transformedStatus);
		onUpdateCache && onUpdateCache(issueKey, 'status', transformedStatus);
	};

	// If the property does not exist in fragment data then we know it has been conditionally excluded because
	// inline editing is disabled. In future this should follow the same design/deferred loading strategy of
	// other inline editable fields, however this would require a larger effort to decompose the status field.
	const isEditable = data && typeof data.id === 'string';

	if (!isSingleLineRowHeightEnabled)
		return (
			<Box xcss={alignmentFixStyles} data-vc="native-issue-table-ui-issue-status-box">
				{isEditable ? (
					<AsyncIssueStatusField
						issueKey={toIssueKey(issueKey)}
						issueId={issueId && toIssueId(issueId)}
						appearance="lozenge"
						value={toStatusValue(value)}
						onSubmit={onSubmit}
						onSuccess={onSuccess}
					/>
				) : (
					<IssueStatusFieldReadonly value={toStatusValue(value)} />
				)}
			</Box>
		);

	return (
		<HoverPopover content={toStatusValue(value).name} contentRef={contentRef}>
			<Box xcss={alignmentFixStyles} data-vc="native-issue-table-ui-issue-status-box">
				{isEditable ? (
					<AsyncIssueStatusField
						issueKey={toIssueKey(issueKey)}
						issueId={issueId && toIssueId(issueId)}
						appearance="lozenge"
						value={toStatusValue(value)}
						onSubmit={onSubmit}
						onSuccess={onSuccess}
						statusButtonRef={contentRef}
					/>
				) : (
					<IssueStatusFieldReadonly value={toStatusValue(value)} />
				)}
			</Box>
		</HoverPopover>
	);
});

// Status lozenges have a 2px invisible border around them to keep them in the same place if they enter an "error" state (where a border colour is added).
// Refer to: src/packages/issue/fields/status/src/common/ui/status-lozenge/main.tsx

const alignmentFixStyles = xcss({
	marginLeft: 'space.negative.025',
});
