import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { OriginalEstimateInlineEditViewIsEditable } from '@atlassian/jira-issue-field-original-estimate-inline-edit-full/src/ui/original-estimate/lite/index.tsx';
import type { originalEstimate_nativeIssueTable_OriginalEstimateTimeTrackingFieldCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/originalEstimate_nativeIssueTable_OriginalEstimateTimeTrackingFieldCell_fieldRef.graphql';
import type { originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef.graphql';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef$key;
};

export const OriginalEstimateCell = ({ fieldRef }: Props) => {
	// NOTE: original estimate type is used to get timeTrackingField data, which is used in underlying original estimate decomposed field.
	const data = useFragment<originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef$key>(
		graphql`
			fragment originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef on JiraOriginalTimeEstimateField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				issue {
					timeTrackingField {
						...originalEstimate_nativeIssueTable_OriginalEstimateTimeTrackingFieldCell_fieldRef
							@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
					}
				}
			}
		`,
		fieldRef,
	);

	return <OriginalEstimateTimeTrackingFieldCell fieldRef={data.issue?.timeTrackingField ?? null} />;
};

const OriginalEstimateTimeTrackingFieldCell = ({
	fieldRef,
}: {
	fieldRef: originalEstimate_nativeIssueTable_OriginalEstimateTimeTrackingFieldCell_fieldRef$key | null;
}) => {
	const data =
		useFragment<originalEstimate_nativeIssueTable_OriginalEstimateTimeTrackingFieldCell_fieldRef$key>(
			graphql`
				fragment originalEstimate_nativeIssueTable_OriginalEstimateTimeTrackingFieldCell_fieldRef on JiraTimeTrackingField
				@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
					id
					lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
					...lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef
				}
			`,
			fieldRef,
		);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		data && 'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data?.id, isEditable);

	return (
		<OriginalEstimateInlineEditViewIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
		/>
	);
};
