import React, { memo, type MouseEvent, type KeyboardEvent, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { shouldDisplayStrikethrough as calculateShouldDisplayStrikethrough } from '@atlassian/jira-issue-key-with-resolution/src/common/utils.tsx';
import type { issueKey_nativeIssueTable_IssueKeyCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/issueKey_nativeIssueTable_IssueKeyCell_fieldRef.graphql';
import type { issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef$key } from '@atlassian/jira-relay/src/__generated__/issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	useIsDensityFull,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import TableCellLink from '../../table-cell-link/index.tsx';
import { HoverPopover } from '../../hover-popover/index.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: issueKey_nativeIssueTable_IssueKeyCell_fieldRef$key;
	issueRef?: issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef$key | null;
	/**
	 * Dispatched when a table cell with a link to the issue is selected. This method should return `true` to signal
	 * that the event has been handled, which will prevent default event handling.
	 */
	onIssueLinkSelected?: (issueKey: string) => boolean;
};

type IssueKeyStrikeThroughProps = {
	'aria-label'?: string;
	shouldDisplayStrikethrough?: boolean;
};

export const IssueKeyCell = memo<Props>(({ fieldRef, issueRef, onIssueLinkSelected }: Props) => {
	const intl = useIntl();
	const { text: issueKey } = useFragment<issueKey_nativeIssueTable_IssueKeyCell_fieldRef$key>(
		graphql`
			fragment issueKey_nativeIssueTable_IssueKeyCell_fieldRef on JiraSingleLineTextField {
				text
			}
		`,
		fieldRef,
	);

	const fieldsByIdFragment = useFragment<issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef$key>(
		graphql`
			fragment issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef on JiraIssue {
				isResolved
				fieldsById(ids: ["status"]) {
					edges {
						node {
							... on JiraStatusField {
								fieldId
								status {
									statusCategory {
										statusCategoryId
									}
								}
							}
						}
					}
				}
			}
		`,
		issueRef || null,
	);

	const isDensityFull = useIsDensityFull();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const tableCellLinkRef = useRef<HTMLAnchorElement>(null);

	if (!issueKey) {
		return <ErrorCell cellType="issueKey" reason="Missing required fields" />;
	}

	const issueStrikeThroughProps: IssueKeyStrikeThroughProps = {};
	const statusEdge = fieldsByIdFragment?.fieldsById?.edges?.find(
		(edge) => edge?.node?.fieldId === 'status',
	);

	const statusCategoryId = statusEdge?.node?.status?.statusCategory?.statusCategoryId;
	const isResolved = fieldsByIdFragment?.isResolved ?? undefined;
	const isIssueResolved = calculateShouldDisplayStrikethrough(
		isResolved,
		statusCategoryForId(statusCategoryId),
	);
	issueStrikeThroughProps.shouldDisplayStrikethrough = isIssueResolved;
	issueStrikeThroughProps['aria-label'] = intl.formatMessage(messages.issueKeyWithResolution, {
		issueKey,
		isResolved: isIssueResolved,
	});

	const getWrapperStyles = () => {
		if (isDensityFull) {
			return isVisualRefreshEnabled() ? wrapperStylesFull : wrapperStylesFullOld;
		}
		return isVisualRefreshEnabled() ? wrapperStylesCompact : wrapperStylesCompactOld;
	};

	const issueKeyLink = (
		<TableCellLink
			href={`/browse/${issueKey}`}
			data-testid="native-issue-table.common.ui.issue-cells.issue-key.issue-key-cell"
			{...issueStrikeThroughProps}
			isSubtle
			ref={tableCellLinkRef}
			onClick={(e: MouseEvent | KeyboardEvent) => {
				// Use default browser event handling when a modifier key is pressed during the click event
				const isModifierKeyUsed = e.ctrlKey || e.shiftKey || e.metaKey;
				const handled =
					!isModifierKeyUsed && onIssueLinkSelected !== undefined && onIssueLinkSelected(issueKey);
				if (handled) {
					e.preventDefault();
				}
			}}
		>
			{issueKey}
		</TableCellLink>
	);

	return (
		<Box
			paddingBlock="space.050"
			paddingInline="space.100"
			xcss={
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				[getWrapperStyles(), isSingleLineRowHeightEnabled && singleLineRowHeightStyles]
			}
			data-vc="native-issue-table-ui-issue-key-cell"
		>
			{isSingleLineRowHeightEnabled ? (
				<HoverPopover content={issueKey} contentRef={tableCellLinkRef}>
					{issueKeyLink}
				</HoverPopover>
			) : (
				<Tooltip content={issueKey}>{issueKeyLink}</Tooltip>
			)}
		</Box>
	);
});

const singleLineRowHeightStyles = xcss({
	textOverflow: 'ellipsis',
});

const wrapperStylesCompactOld = xcss({
	font: token('font.heading.xxsmall'),
	maxWidth: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const wrapperStylesCompact = xcss({
	font: token('font.body'),
	maxWidth: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const wrapperStylesFullOld = xcss({
	font: token('font.heading.xxsmall'),
	maxWidth: '100%',
});

const wrapperStylesFull = xcss({
	font: token('font.body'),
	maxWidth: '100%',
});
