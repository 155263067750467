/**
 * @generated SignedSource<<cf35f6bfca3e21a79e68e7f14b7d8f17>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_RowWithDragAndDrop_fieldSets$data = {
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_fieldSets">;
  readonly " $fragmentType": "row_nativeIssueTable_RowWithDragAndDrop_fieldSets";
};
export type row_nativeIssueTable_RowWithDragAndDrop_fieldSets$key = {
  readonly " $data"?: row_nativeIssueTable_RowWithDragAndDrop_fieldSets$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowWithDragAndDrop_fieldSets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_RowWithDragAndDrop_fieldSets",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "row_nativeIssueTable_RowInner_fieldSets"
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};

(node as any).hash = "e4a29721eef57f256f5fd661e73e9d0c";

export default node;
