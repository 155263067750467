import React, { type PropsWithChildren } from 'react';
import { CopyPasteContextProvider } from '@atlassian/jira-issue-field-copy-paste/src/ui/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	useIsInlineEditingEnabled,
	useIsInlineEditingExtendedFieldSupportEnabled,
} from '../../controllers/features/selectors.tsx';

const CopyPasteContainerInternal = ({ children }: PropsWithChildren<{}>) => {
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	return isInlineEditingEnabled && isInlineEditingExtendedFieldSupportEnabled ? (
		<CopyPasteContextProvider>{children}</CopyPasteContextProvider>
	) : (
		children
	);
};

export const CopyPasteContainer = componentWithFG(
	'jsc_inline_editing_field_refactor',
	CopyPasteContainerInternal,
	({ children }: PropsWithChildren<{}>) => children,
);
