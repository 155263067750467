import { useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

/**
 * It returns a set of utils that allows to keep track of the optimistic ids generated,
 * and the respective real id from the server.
 */
export const useOptimisticId = () => {
	const optimisticIDtoReal = useRef(new Map<string, string | undefined>());

	const generateOptimisticId = useCallback(() => {
		const id = uuid();
		optimisticIDtoReal.current.set(id, undefined);

		return {
			id,
			saveRealId: (realId: string) => {
				optimisticIDtoReal.current.set(id, realId);
			},
		};
	}, []);

	const getRealId = useCallback((optimisticId: string) => {
		return optimisticIDtoReal.current.get(optimisticId);
	}, []);

	return {
		generateOptimisticId,
		getRealId,
	};
};
