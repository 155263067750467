/**
 * @generated SignedSource<<9cb685025347abb0a5b07424eaaf6a04>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueLinks_nativeIssueTable_IssueLinksCell$data = {
  readonly issueLinkConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly issue: {
          readonly fieldsById: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly statusCategory?: {
                  readonly statusCategoryId: string;
                };
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly key: string;
          readonly webUrl: AGG$URL | null | undefined;
        } | null | undefined;
        readonly relationName: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "issueLinks_nativeIssueTable_IssueLinksCell";
};
export type issueLinks_nativeIssueTable_IssueLinksCell$key = {
  readonly " $data"?: issueLinks_nativeIssueTable_IssueLinksCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueLinks_nativeIssueTable_IssueLinksCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueLinks_nativeIssueTable_IssueLinksCell",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "totalCount"
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "key"
                    },
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "concreteType": "JiraStatusCategory",
                                      "kind": "LinkedField",
                                      "name": "statusCategory",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "ScalarField",
                                          "name": "statusCategoryId"
                                        }
                                      ]
                                    }
                                  ],
                                  "type": "JiraStatusCategoryField"
                                }
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "issueLinkConnection(first:1)"
    }
  ],
  "type": "JiraIssueLinkField"
};

(node as any).hash = "1e9888fcf84cc8f53dcabd49810063d2";

export default node;
