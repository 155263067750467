/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useHierarchyActions } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { TableCell } from '../../../common/ui/table-cell/index.tsx';
import { useIsFixedTableLayoutEnabled } from '../../../controllers/features/selectors.tsx';
import { useRowContext } from '../../rows/row-context/index.tsx';

/**
 *
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
const LoadingIssueRow = ({ itemId, groupId }: { itemId: string; groupId?: string }) => {
	const { columns: tableColumns } = useRowContext();
	const { setItemIsLoading } = useHierarchyActions();

	useEffect(() => {
		setItemIsLoading({
			itemId,
			groupId,
			isLoading: true,
		});

		return () => {
			setItemIsLoading({
				itemId,
				groupId,
				isLoading: false,
			});
		};
	}, [itemId, groupId, setItemIsLoading]);

	const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();
	const columns = Array.from({ length: tableColumns.length });

	const colSpans = columns.map((_, index) => {
		const isLastColumn = index === columns.length - 1;
		return 1 + (isLastColumn && isFixedTableLayoutEnabled ? 1 : 0);
	});

	return (
		<Box as="tr" xcss={wrapperStyles}>
			{columns.map((_, i) => (
				<TableCell
					key={i}
					colSpan={colSpans[i]}
					border={[cellBorderStyles, i === tableColumns.length - 1 && lastCellBorderStyles]}
				>
					<Box xcss={loadingCellStyles} />
				</TableCell>
			))}
		</Box>
	);
};

export default LoadingIssueRow;

const wrapperStyles = xcss({ height: '40px' });

const cellBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: 'border.width',
	borderBottomWidth: '0',
	borderLeftWidth: '0',
	borderRightWidth: 'border.width',
});

const lastCellBorderStyles = xcss({
	borderRightWidth: '0',
});

const loadingCellStyles = xcss({
	backgroundColor: 'color.background.neutral',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	padding: 'space.150',
	width: '100%',
});
