import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitleNonFinal: {
		id: 'issue-table-inline-issue-create.controllers.use-issue-create-on-error.error-flag-title-non-final',
		defaultMessage: "We weren't able to create an issue",
		description: 'Title for the flag displayed when creating an inline issue fails',
	},
	errorFlagDescriptionNonFinal: {
		id: 'issue-table-inline-issue-create.controllers.use-issue-create-on-error.error-flag-description-non-final',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description for the flag displayed when creating an inline issue fails',
	},
});
