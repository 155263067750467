import React, { memo, useState, useMemo } from 'react';
import Spinner from '@atlaskit/spinner';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME } from '../../../../constants.tsx';
import { TruncatedTextWithPopup } from '../../../truncated-text-cell-with-popup/index.tsx';
import { ErrorState } from '../error-state/index.tsx';
import { richTextPopUpContentEntryPoint } from '../rich-text-popup-content/entrypoint.tsx';

export type Props = {
	issueKey: string;
	fieldId: string;
	plainText: string;
	/**
	 * Should only be needed For VR testing, to render a storybook example with
	 * the popup content immediately visible
	 */
	shouldOpenOnFirstRender?: boolean;
};

const runtimeProps = {};
export const RichTextCellCompact = memo<Props>(
	({ issueKey, fieldId, plainText, shouldOpenOnFirstRender = false }: Props) => {
		const cloudId = useCloudId();

		const entryPointParams = useMemo(
			() => ({ fieldId, issueKey, cloudId }),
			[fieldId, cloudId, issueKey],
		);
		const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
			richTextPopUpContentEntryPoint,
			entryPointParams,
		);
		const [isOpen, setIsOpen] = useState(shouldOpenOnFirstRender);
		const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

		return (
			<TruncatedTextWithPopup
				triggerText={plainText}
				triggerRefCallback={triggerRef}
				isOpen={isOpen}
				onSetIsOpen={setIsOpen}
				content={
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="rich-text-popup-content"
						packageName={PACKAGE_NAME}
						errorFallback={() => <ErrorState />}
						fallback={<Spinner size="medium" />}
						runtimeProps={runtimeProps}
					/>
				}
			/>
		);
	},
);
