import { useCallback, useEffect, useRef, type RefCallback } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	type Action,
	createActionsHook,
	createHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { ROW_HEIGHT } from '../../common/constants.tsx';
import { getInsertionPointSelector } from './selectors.tsx';
import type { State, Props, InsertionPoint } from './types.tsx';

export const initialState: State = {
	intersectionObserver: null,
	itemsObserved: new Map(),
	insertionPoint: null,
	isObserving: false,
};

/** Container to store the intersection observer on the scrollable body of the table. */
export const TableIntersectionObserverContainer = createContainer<Props>();

const actions = {
	/**
	 * Starts the intersection observer to observer any stored item.
	 */
	observeItems:
		(): Action<State> =>
		({ getState, setState }) => {
			const { intersectionObserver, itemsObserved, isObserving } = getState();
			if (isObserving) {
				return;
			}

			if (intersectionObserver != null) {
				itemsObserved.forEach((_, element) => {
					intersectionObserver.observe(element);
				});
			}

			setState({
				isObserving: true,
			});
		},
	/**
	 * Stops the intersection observer.
	 */
	unobserveItems:
		(): Action<State> =>
		({ getState, setState }) => {
			const { intersectionObserver, isObserving } = getState();
			if (!isObserving) {
				return;
			}

			if (intersectionObserver != null) {
				intersectionObserver.disconnect();
			}

			setState({
				isObserving: false,
			});
		},

	/**
	 * Disconnect any previous intersection observer stored, if the stored is set to automatically observe,
	 * we start observing any stored item, finally we updated the stored with the new observer.
	 * @param {Object} config
	 * @param {IntersectionObserver} config.intersectionObserver - New IntersectionObserver to be added it
	 */
	setIntersectionObserver:
		({
			intersectionObserver: newIntersectionObserver,
		}: {
			intersectionObserver: IntersectionObserver;
		}): Action<State> =>
		({ setState, getState }) => {
			const { intersectionObserver, isObserving, itemsObserved } = getState();

			if (intersectionObserver != null) {
				intersectionObserver.disconnect();
			}

			if (isObserving) {
				itemsObserved.forEach((_, element) => {
					newIntersectionObserver.observe(element);
				});
			}

			setState({
				intersectionObserver: newIntersectionObserver,
			});
		},
	/**
	 * Add a HTML element ot the list of observed elements. It start's observing automatically
	 * if the stored is observing.
	 * @param {Object} config
	 * @param {HTMLElement} element - HTML Element to be observed
	 * @param {string} issueId - Id of the issue that is associated to the HTML Element that is been observed
	 */
	observeItem:
		({ element, issueId }: { element: HTMLElement; issueId: string }): Action<State, Props> =>
		({ getState, setState }) => {
			const { intersectionObserver, itemsObserved, isObserving } = getState();

			setState({ itemsObserved: new Map([...itemsObserved.entries(), [element, issueId]]) });

			if (intersectionObserver == null || !isObserving) {
				return;
			}

			intersectionObserver.observe(element);
		},
	/**
	 * Removes an element from been observed, I stop's observing the element automatically
	 * if the stored is observing
	 * @param {Object} config
	 * @param {HTMLElement} element - Element to be unobserved
	 */
	unobserveItem:
		({ element }: { element: HTMLElement }): Action<State, Props> =>
		({ getState, setState }) => {
			const { intersectionObserver, itemsObserved, isObserving } = getState();

			if (itemsObserved.has(element)) {
				const newItemsObserved = new Map(itemsObserved.entries());
				newItemsObserved.delete(element);
				setState({
					itemsObserved: newItemsObserved,
				});
			}

			if (intersectionObserver == null || !isObserving) {
				return;
			}

			intersectionObserver.unobserve(element);
		},
	/**
	 * It stored the insertion point in the stored
	 * @param {InsertionPoint} insertionPoint - Insertion point to be stored
	 */
	setInsertionPoint:
		(insertionPoint: InsertionPoint): Action<State, Props> =>
		({ setState }) => {
			setState({
				insertionPoint,
			});
		},
};

type Actions = typeof actions;

export const TableIntersectionObserverStore = createStore<State, Actions, Props>({
	initialState,
	actions,
	containedBy: TableIntersectionObserverContainer,
});

export const useTableItemsIntersectionObserver = createHook(TableIntersectionObserverStore);

export const useTableItemsIntersectionObserverActions = createActionsHook(
	TableIntersectionObserverStore,
);

const ROW_HEIGHT_WITH_BORDER = ROW_HEIGHT + 1;

type EntryWithPosition = {
	position: 'below' | 'above';
	entry: IntersectionObserverEntry;
};

/**
 * It creates an intersection observer on the element that is called; This observer will
 * calculate the insertion point closer to the footer, where an issue should be inserted.
 * @returns {CallbackRef} - CallbackRef to be used in the scrollable element of the table
 */
export const useIntersectionObserver = () => {
	const { setIntersectionObserver: setObserver, setInsertionPoint } =
		useTableItemsIntersectionObserverActions();

	return useCallback<RefCallback<HTMLElement>>(
		(element) => {
			if (element != null) {
				const observer = new IntersectionObserver(
					(entries) => {
						const isOverflow = element.scrollHeight > element.clientHeight;

						const mostRecentEntryCloseToFooter = entries
							.map<EntryWithPosition | undefined>((entry) => {
								if (entry.rootBounds == null) {
									return;
								}
								const { top } = entry.boundingClientRect;
								const { bottom: interceptLine } = entry.rootBounds;

								/**
								 * When scrolling upward (mac), the top line of the item rect is
								 * passing from below to above the intercept line. We identify this
								 * because the entry rectangle will look like:
								 *
								 *  --------  -> Top of root
								 * |        |
								 * |        |
								 * =========  -> Top of item
								 * ---------  -> Bottom of root (aka intercept line)
								 * =========  -> Bottom of item (aka top + height )
								 */
								const isBelow =
									top <= interceptLine && top + ROW_HEIGHT_WITH_BORDER >= interceptLine;

								/**
								 * When scrolling downward (mac), the top line of the item rect is
								 * passing from above to below the intercept line. We identify this
								 * because the entry rectangle will look like:
								 *
								 *  --------  -> Top of root
								 * |        |
								 * |        |
								 * ---------  -> Bottom of root (aka intercept line)
								 * =========  -> Top of item
								 * |        |
								 * =========  -> Bottom of item (aka top + height )
								 * If top minus height is below that the intercept line, means that is
								 * rectangle fits
								 */
								const isAbove =
									top >= interceptLine && top - ROW_HEIGHT_WITH_BORDER <= interceptLine;

								// We prioritize below vs above when is possible.
								if (isBelow) {
									return {
										position: 'below',
										entry,
									};
								}

								if (isAbove) {
									return {
										position: 'above',
										entry,
									};
								}

								// Otherwise; Is pass the intercept line ignored
								return undefined;
							})
							.filter((a): a is EntryWithPosition => a !== undefined)
							// put most recent entries on top, is possible that multiple
							// entries pass the intercept line
							.sort((a, b) => {
								if (b.entry.time !== a.entry.time) {
									return b.entry.time - a.entry.time;
								}

								// Returns the closest to the bottom when the time is the same
								if (b.entry.boundingClientRect.y > a.entry.boundingClientRect.y) {
									return 1;
								}
								if (b.entry.boundingClientRect.y < a.entry.boundingClientRect.y) {
									return -1;
								}
								return 0;
							})
							// get the most recent one
							.shift();

						if (mostRecentEntryCloseToFooter) {
							setInsertionPoint({
								element: mostRecentEntryCloseToFooter.entry.target,
								// Always insert below when is not overflowing
								position: isOverflow ? mostRecentEntryCloseToFooter.position : 'below',
							});
						}
					},
					{
						root: element,
						// We are placing the interceptor just below the header
						// and a row above the footer, as we want to insert the icon
						// at least a row above the footer to be visible
						rootMargin: `-40px 0px -${2 * ROW_HEIGHT_WITH_BORDER}px 0px`,
					},
				);
				setObserver({ intersectionObserver: observer });
			}
		},
		[setInsertionPoint, setObserver],
	);
};

/**
 * It observed an element when called it; when the component gets unmount stop observing.
 * @param {string} issueId - Issue Id hat belongs to the element called in the callbackRef
 * @returns {CallbackRef} - CallbackRef to be used in item that wants to be observed (Row)
 */
export const useObserveItem = <T extends HTMLElement>(issueId?: string) => {
	const { observeItem, unobserveItem } = useTableItemsIntersectionObserverActions();
	const itemRef = useRef<T>();
	const wasObserved = useRef<boolean>(false);

	useEffect(
		() => () => {
			itemRef.current != null && unobserveItem({ element: itemRef.current });
		},
		[unobserveItem],
	);

	return useCallback<RefCallback<T>>(
		(element) => {
			if (element != null) {
				itemRef.current = element;
				if (issueId != null) {
					observeItem({ element, issueId });
					wasObserved.current = true;
				}
			}
		},
		[issueId, observeItem],
	);
};

/**
 * @returns {InsertionPoint} - calculated insertion point to create the issue
 */
export const useInsertionPoint = createStateHook(TableIntersectionObserverStore, {
	selector: getInsertionPointSelector,
});

/**
 * It start observing/unobserving the items base if the component is mount or no
 * @returns {InsertionPoint} - calculated insertion point to create the issue
 */
export const useCalculateIssueInsertionPoint = () => {
	const { observeItems, unobserveItems } = useTableItemsIntersectionObserverActions();

	useEffect(() => {
		observeItems();

		return () => {
			unobserveItems();
		};
	}, [observeItems, unobserveItems]);

	return useInsertionPoint();
};
