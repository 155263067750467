/**
 * @generated SignedSource<<2a44ca5ffb48c8562c2b3a2b60023329>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type row_nativeIssueTable_RowInner_issue$data = {
  readonly id: string;
  readonly isHighlightedIssueRow: boolean | null | undefined;
  readonly issueId: string;
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_issue">;
  readonly " $fragmentType": "row_nativeIssueTable_RowInner_issue";
};
export type row_nativeIssueTable_RowInner_issue$key = {
  readonly " $data"?: row_nativeIssueTable_RowInner_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"row_nativeIssueTable_RowInner_issue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "row_nativeIssueTable_RowInner_issue",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        }
      ],
      "kind": "FragmentSpread",
      "name": "issueRow_nativeIssueTable_IssueRowWithFragments_issue"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "issueId"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isHighlightedIssueRow"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "a1246f8e77527118a6cf551f87d4b343";

export default node;
