import React, { type PropsWithChildren } from 'react';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	useIsDensityFull,
	useIsVirtualizationEnabled,
	useIsColumnResizingEnabled,
	useIsFixedTableLayoutEnabled,
	useIsInlineEditingEnabled,
	useIsIssueCreateEnabled,
	useIsInfiniteScrollEnabled,
	useIsSiblingIssueCreateEnabled,
	useIsChildIssueCreateEnabled,
	useIsIssueRankDescending,
	useIsIssueHierarchyEnabled,
	useIsIssueOperationMeatballMenuEnabled,
	useIsColumnBordersEnabled,
	useIsRowReorderingEnabled,
	useIsEntireRowDraggable,
	useIsInlineFieldConfigEnabled,
	useIsInBetweenColumnPickerEnabled,
	useIsInlineEditingExtendedFieldSupportEnabled,
	useIsSingleLineRowHeightEnabled,
	useIsHideDoneItemsEnabled,
} from './selectors.tsx';
import { FeaturesContainerInternal } from './store.tsx';
import type { Props } from './types.tsx';

const ContextualFeatureAnalytics = ({ children }: PropsWithChildren<{}>) => {
	const isDensityFull = useIsDensityFull();
	const isVirtualizationEnabled = useIsVirtualizationEnabled();
	const isColumnResizingEnabled = useIsColumnResizingEnabled();
	const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	const isIssueCreateEnabled = useIsIssueCreateEnabled();
	const isInfiniteScrollEnabled = useIsInfiniteScrollEnabled();
	const isSiblingIssueCreateEnabled = useIsSiblingIssueCreateEnabled();
	const isChildIssueCreateEnabled = useIsChildIssueCreateEnabled();
	const isIssueRankDescending = useIsIssueRankDescending();
	const isIssueHierarchyEnabled = useIsIssueHierarchyEnabled();
	const isIssueOperationMeatballMenuEnabled = useIsIssueOperationMeatballMenuEnabled();
	const isColumnBordersEnabled = useIsColumnBordersEnabled();
	const isRowReorderingEnabled = useIsRowReorderingEnabled();
	const isEntireRowDraggable = useIsEntireRowDraggable();
	const isInlineFieldConfigEnabled = useIsInlineFieldConfigEnabled();
	const isInBetweenColumnPickerEnabled = useIsInBetweenColumnPickerEnabled();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const isHideDoneItemsEnabled = useIsHideDoneItemsEnabled();

	return (
		<ContextualAnalyticsData
			attributes={{
				issueTableFeatures: {
					isDensityFull,
					isVirtualizationEnabled,
					isColumnResizingEnabled,
					isFixedTableLayoutEnabled,
					isInlineEditingEnabled,
					isInlineEditingExtendedFieldSupportEnabled,
					isIssueCreateEnabled,
					isInfiniteScrollEnabled,
					isSiblingIssueCreateEnabled,
					isChildIssueCreateEnabled,
					isIssueRankDescending,
					isIssueHierarchyEnabled,
					isIssueOperationMeatballMenuEnabled,
					isColumnBordersEnabled,
					isRowReorderingEnabled,
					isEntireRowDraggable,
					isInlineFieldConfigEnabled,
					isInBetweenColumnPickerEnabled,
					isSingleLineRowHeightEnabled,
					isHideDoneItemsEnabled,
				},
			}}
		>
			{children}
		</ContextualAnalyticsData>
	);
};

export const FeaturesContainer = ({ children, ...props }: PropsWithChildren<Props>) => {
	return (
		<FeaturesContainerInternal {...props}>
			<ContextualFeatureAnalytics>{children}</ContextualFeatureAnalytics>
		</FeaturesContainerInternal>
	);
};
