/**
 * @generated SignedSource<<84e38266018a2d0ee5fecb7a6ccbbea1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef$data = {
  readonly fieldsById: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldId?: string;
        readonly status?: {
          readonly statusCategory: {
            readonly statusCategoryId: string;
          } | null | undefined;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly isResolved: boolean | null | undefined;
  readonly " $fragmentType": "issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef";
};
export type issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef$key = {
  readonly " $data"?: issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isResolved"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "status"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "fieldId"
                    },
                    {
                      "concreteType": "JiraStatus",
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraStatusCategory",
                          "kind": "LinkedField",
                          "name": "statusCategory",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "statusCategoryId"
                            }
                          ]
                        }
                      ]
                    }
                  ],
                  "type": "JiraStatusField"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "fieldsById(ids:[\"status\"])"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "3f8a2e871d3d14cf9fc0af5923a7fcf4";

export default node;
