/**
 * @generated SignedSource<<0b5715b77ca55e802bdba8cdecf1bde1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_nativeIssueTable_NativeIssueTable_issues$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly issueId: string;
      readonly key: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly jql: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"footerRenderer_nativeIssueTable" | "issueTable_nativeIssueTable_issueResults" | "realtimeUpdaterOld_nativeIssueTable_issues" | "realtimeUpdater_nativeIssueTable_issues" | "rootConnectionRegister_nativeIssueTable_issues" | "selectedRowState_nativeIssueTable_SelectedRowContainer">;
  readonly " $fragmentType": "ui_nativeIssueTable_NativeIssueTable_issues";
};
export type ui_nativeIssueTable_NativeIssueTable_issues$key = {
  readonly " $data"?: ui_nativeIssueTable_NativeIssueTable_issues$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_issues">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isReparentingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "ui_nativeIssueTable_NativeIssueTable_issues",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            },
            {
              "kind": "ScalarField",
              "name": "issueId"
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "jql"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isReparentingEnabled",
          "variableName": "isReparentingEnabled"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "issueTable_nativeIssueTable_issueResults"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "footerRenderer_nativeIssueTable"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "selectedRowState_nativeIssueTable_SelectedRowContainer"
    },
    {
      "kind": "FragmentSpread",
      "name": "realtimeUpdater_nativeIssueTable_issues"
    },
    {
      "kind": "FragmentSpread",
      "name": "realtimeUpdaterOld_nativeIssueTable_issues"
    },
    {
      "kind": "FragmentSpread",
      "name": "rootConnectionRegister_nativeIssueTable_issues"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};
})();

(node as any).hash = "ce5101375c657f9a94a281e6b281bc1c";

export default node;
