import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type KeyboardEvent,
	type MouseEvent,
	type MouseEventHandler,
	type Ref,
	type RefObject,
} from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import noop from 'lodash/noop';
import {
	attachClosestEdge,
	type Edge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { Inline, xcss } from '@atlaskit/primitives';
import { token, type CSSToken } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { FIELD } from '../../../../controllers/inline-create-active-index/types.tsx';
import { useInlineCreateActiveIndexActions } from '../../../../controllers/inline-create-active-index/index.tsx';
import {
	COLUMN_OPERATION_REORDER,
	COLUMN_OPERATION_RESIZE,
	COLUMN_RESIZE_HANDLE_FULL_HEIGHT,
} from '../../../../common/constants.tsx';

import type {
	Column,
	ColumnId,
	TargetColumnPosition,
	ColumnResizeHandleType,
	BorderWidth,
	HeaderHeight,
} from '../../../../common/types.tsx';
import { useCellWrapper } from '../../../../common/ui/cell-navigation/cell-wrapper/index.tsx';
import WarningIndicator from '../../../../common/ui/warning-indicator/index.tsx';
import {
	useIsColumnResizingEnabled,
	useIsInBetweenColumnPickerEnabled,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import { useScrollStateSelector } from '../../../../controllers/scroll-state/index.tsx';
import { HeaderCellActions } from './header-cell-actions/index.tsx';
import { ResizePopup } from './resize-popup/index.tsx';
import {
	clearCssResizingWidth,
	disableCssPointerEvents,
	enableCssPointerEvents,
	getMinimumColumnProposedWidth,
	getMinimumColumnWidth,
	getProposedWidth,
	isMinimumWidth,
	setCssResizingWidth,
} from './utils.tsx';

const DRAG_PREVIEW_MAX_WIDTH = 260;
const BORDER_WIDTH: BorderWidth = '1px';
const HEADER_HEIGHT: HeaderHeight = 40;

// ensure that the resize handle displays above subsequent header cells, but that a sticky cell will display above the
// resize handle. Improper z-index may cause the resize handle to render _above_ the column picker.
const RESIZE_HANDLE_Z_INDEX = 1;
const STICKY_CELL_Z_INDEX = RESIZE_HANDLE_Z_INDEX + 1;
const BOUNDARY = 70;
const MOUSE_MOVE_THROTTLE = 16;

type Props = {
	column: Column;
	hasColumnConfiguration: boolean;
	instanceId: symbol;
	isCompactColumnEnabled?: boolean;
	isFirst: boolean;
	isLast: boolean;
	resizeHandleType?: ColumnResizeHandleType;
	tableRef?: RefObject<HTMLTableElement>;
	onMoveColumn: (position: TargetColumnPosition) => void;
	onRemoveColumn: () => void;
	onResizeColumn?: (columnId: ColumnId, width: number | undefined) => void;
	index: number;
	tableBorderRadius?: CSSToken;
};

type State =
	| { type: 'idle' }
	| { type: 'preview'; container: HTMLElement; previewWidth: number }
	| { type: 'reordering' }
	| { type: 'resizing' };

export const HeaderCell = ({
	column,
	hasColumnConfiguration,
	instanceId,
	isCompactColumnEnabled = false,
	isFirst,
	isLast,
	resizeHandleType = COLUMN_RESIZE_HANDLE_FULL_HEIGHT,
	tableRef,
	onMoveColumn: onMoveColumnProp,
	onRemoveColumn,
	onResizeColumn: onResizeColumnProp,
	index,
	tableBorderRadius,
}: Props) => {
	const headerCellContainerRef = useRef<HTMLTableCellElement>(null);
	const headerCellActionsTriggerRef = useRef<HTMLButtonElement>(null);
	const resizeHandleRef = useRef<HTMLDivElement>(null);

	const isColumnResizingEnabled = useIsColumnResizingEnabled();
	const isInBetweenColumnPickerEnabled = useIsInBetweenColumnPickerEnabled();
	const [isHovered, setIsHovered] = useState(false);

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const [state, setState] = useState<State>({ type: 'idle' });

	const [dropEdge, setDropEdge] = useState<Edge | null>(null);

	let clearIndex = noop;
	let setIndex = noop;
	if (isInBetweenColumnPickerEnabled) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ clearIndex, setIndex } = useInlineCreateActiveIndexActions());
	}

	const height = useScrollStateSelector((scrollState) => scrollState.height);

	const isSingleRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	const analyticsAttributes = useMemo(
		() => ({
			fieldType: column.type,
		}),
		[column.type],
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const throttledHandler = useMemo(
		() =>
			throttle((event: MouseEvent) => {
				if (!event.currentTarget) {
					return;
				}

				const rect = event.currentTarget.getBoundingClientRect();
				const x = event.clientX - rect.left;
				const width = rect.right - rect.left;
				if (x < width / 2 && x <= BOUNDARY) {
					setIndex(FIELD, index - 1);
				} else if (x >= width / 2 && x >= width - BOUNDARY) {
					setIndex(FIELD, index);
				} else {
					clearIndex(FIELD);
				}
			}, MOUSE_MOVE_THROTTLE),
		[index, setIndex, clearIndex],
	);

	const calculateColumnIndex = useCallback(throttledHandler, [throttledHandler]);

	const onMouseEnter = useCallback((event: MouseEvent) => {
		if (event.target === resizeHandleRef.current) {
			// prevent showing "hovered" state when only the resize handle is hovered
			return;
		}
		setIsHovered(true);
	}, []);

	const onMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	const handleMouseMove = useCallback(
		(event: MouseEvent) => {
			event.persist();
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (headerCellContainerRef.current?.contains(event.target as HTMLElement)) {
				calculateColumnIndex(event);
			} else {
				clearIndex(FIELD);
			}
		},
		[calculateColumnIndex, clearIndex],
	);

	const { cellRef, isSelected } = fg('jira_list_grid_pattern_keyboard_nav')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCellWrapper({
				row: 0,
				col: index,
			})
		: { cellRef: null, isSelected: false };

	const cellRefs = fg('jira_list_grid_pattern_keyboard_nav')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMergeRefs(headerCellContainerRef, cellRef)
		: headerCellContainerRef;

	// #region Column reordering
	const onMoveColumn = useCallback(
		(position: TargetColumnPosition) => {
			onMoveColumnProp(position);
			setIsHovered(false);
		},
		[onMoveColumnProp],
	);

	useEffect(() => {
		const headerCellContainer = headerCellContainerRef.current;

		if (!hasColumnConfiguration || !headerCellContainer) {
			return;
		}

		return combine(
			draggable({
				element: headerCellContainer,
				getInitialData() {
					return { operation: COLUMN_OPERATION_REORDER, id: column.id, instanceId };
				},
				onDragStart() {
					setState({ type: 'reordering' });
				},
				onDrop() {
					setState({ type: 'idle' });
				},
				onGenerateDragPreview({ nativeSetDragImage }) {
					const headerCellWidth = headerCellContainer.getBoundingClientRect().width;
					setCustomNativeDragPreview({
						getOffset() {
							return { x: 20, y: 20 };
						},
						render({ container }) {
							// Cause a React re-render to create portal synchronously
							setState({
								type: 'preview',
								container,
								previewWidth: Math.min(headerCellWidth, DRAG_PREVIEW_MAX_WIDTH),
							});
							// Cause a React re-render to remove portal on cleanup
							return () => setState({ type: 'reordering' });
						},
						nativeSetDragImage,
					});
				},
			}),
			dropTargetForElements({
				element: headerCellContainer,
				canDrop({ source }) {
					// Only allow drops from the same instance id
					// (i.e. prevent dropping entities that are not headers, or headers from another issue table)
					return source.data.instanceId === instanceId;
				},
				getData({ input, element }) {
					const data = { id: column.id, instanceId };
					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['left', 'right'],
					});
				},
				onDrag({ self }) {
					const closestEdge = extractClosestEdge(self.data);

					if (!closestEdge) {
						return;
					}

					setDropEdge(closestEdge);
				},
				onDragLeave() {
					setDropEdge(null);
				},
				onDrop() {
					setDropEdge(null);
				},
			}),
			monitorForElements({
				canMonitor({ source }) {
					// Only monitor this particular instance id
					// (i.e. prevent monitoring entities that are not headers, or headers from another issue table)
					return source.data.instanceId === instanceId;
				},
				onDrag() {
					// Close the dropdown menu when a drag starts on any column
					setIsDropdownOpen(false);
				},
			}),
		);
	}, [column.id, hasColumnConfiguration, instanceId]);
	// #endregion

	// #region Column resizing
	const [componentColumnWidth, setComponentColumnWidth] = useState(
		column.customWidth ?? column.defaultWidth,
	);
	const prevColumnConfigCustomWidth = usePreviousWithInitial(column.customWidth);
	const [isResizePopupOpen, setIsResizePopupOpen] = useState(false);

	const onResizeColumn = useCallback(
		(width: number, resizeMethod: 'drag-and-drop' | 'popup' | 'reset') => {
			if (width === componentColumnWidth) {
				// Skip state updates if column width has not changed
				return;
			}

			let proposedWidth = width;

			if (isSingleRowHeightEnabled) {
				proposedWidth = getMinimumColumnProposedWidth(column.type, width);
			}

			setComponentColumnWidth(proposedWidth);

			onResizeColumnProp &&
				onResizeColumnProp(
					column.id,
					proposedWidth === column.defaultWidth ? undefined : proposedWidth,
				);

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'resized',
					actionSubject: 'column',
				}),
				'issueTable',
				{
					...analyticsAttributes,
					resizeMethod,
					columnWidth: proposedWidth,
				},
			);
		},
		[
			componentColumnWidth,
			isSingleRowHeightEnabled,
			onResizeColumnProp,
			column.id,
			column.defaultWidth,
			column.type,
			createAnalyticsEvent,
			analyticsAttributes,
		],
	);

	// Header actions callbacks
	const onResizeColumnAction = useCallback(() => {
		setIsResizePopupOpen(true);
	}, []);

	const onResetColumnWidthAction = useCallback(() => {
		onResizeColumn(column.defaultWidth, 'reset');
	}, [onResizeColumn, column.defaultWidth]);

	// Resize popup callbacks
	const onChangeResizePopup = useCallback((width: number) => {
		setCssResizingWidth(headerCellContainerRef.current, width);
	}, []);

	const onCloseResizePopup = useCallback(
		(event?: KeyboardEvent | MouseEvent, overrideKeyboardCheck?: boolean) => {
			setIsResizePopupOpen(false);
			clearCssResizingWidth(headerCellContainerRef.current);

			// Focus header cell actions trigger if the resize popup was closed by keyboard
			// navigation or an override was requested
			//
			// `event.detail` docs: https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
			// (click count is 0 when using keyboard to activate a button)
			if (overrideKeyboardCheck === true || (event && event.detail === 0)) {
				requestAnimationFrame(() => headerCellActionsTriggerRef.current?.focus());
			}
		},
		[],
	);

	const onSubmitResizePopup = useCallback(
		(width: number) => {
			onResizeColumn(width, 'popup');
			// No event available from form submission. We will re-focus the actions
			// trigger because submit can't be triggerd by clicking outside of popup,
			// so don't have to worry about stealing focus from what the user clicked
			onCloseResizePopup(undefined, true);
		},
		[onResizeColumn, onCloseResizePopup],
	);

	// Resize handle callbacks
	const onMouseEnterResizeHandle: MouseEventHandler = useCallback((event) => {
		// prevent showing the drag & drop/reorder hover effect when resizing
		setIsHovered(false);
		event.stopPropagation();
	}, []);

	const onMouseLeaveResizeHandle: MouseEventHandler = useCallback(
		(event) => {
			// restore header hover state if mouse exits into the header element
			if (
				state.type !== 'resizing' &&
				(event.relatedTarget === headerCellContainerRef.current ||
					(event.relatedTarget instanceof Node &&
						headerCellContainerRef.current?.contains(event.relatedTarget)))
			) {
				setIsHovered(true);
			}
		},
		[state.type],
	);

	// Update column width state if a change is made in the column config
	useEffect(() => {
		if (fg('restore-default-width-in-issue-table')) {
			const nextColumnWidth = column.customWidth ?? column.defaultWidth;
			if (
				prevColumnConfigCustomWidth !== nextColumnWidth &&
				componentColumnWidth !== nextColumnWidth &&
				nextColumnWidth != null
			) {
				setComponentColumnWidth(nextColumnWidth);
			}
		} else if (
			prevColumnConfigCustomWidth !== column.customWidth &&
			componentColumnWidth !== column.customWidth &&
			column.customWidth != null
		) {
			setComponentColumnWidth(column.customWidth);
		}
	}, [column.customWidth, column.defaultWidth, componentColumnWidth, prevColumnConfigCustomWidth]);

	// Creates draggable resize handle
	useEffect(() => {
		const resizeHandle = resizeHandleRef.current;
		if (!resizeHandle || isResizePopupOpen) {
			return;
		}

		return draggable({
			element: resizeHandle,
			getInitialData() {
				return { operation: COLUMN_OPERATION_RESIZE, id: column.id };
			},
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				// we will be moving the line to indicate a drag
				// we can disable the native drag preview
				disableNativeDragPreview({ nativeSetDragImage });
				// we don't want any native drop animation for when the user
				// does not drop on a drop target. we want the drag to finish immediately
				preventUnhandled.start();
			},
			onDragStart() {
				setState({ type: 'resizing' });
				// Disable pointer events on the table while dragging in order to prevent buggy hover states in chrome
				// https://issues.chromium.org/issues/41129937#comment43
				disableCssPointerEvents(tableRef?.current);
			},
			onDrag({ location }) {
				const proposedWidth = getProposedWidth({
					initialWidth: componentColumnWidth,
					location,
					isCompactColumnEnabled,
				});

				if (isSingleRowHeightEnabled) {
					if (isMinimumWidth(column.type, proposedWidth)) return;
				}

				setCssResizingWidth(headerCellContainerRef.current, proposedWidth);
			},
			onDrop({ location }) {
				preventUnhandled.stop();
				setState({ type: 'idle' });
				onResizeColumn(
					getProposedWidth({
						initialWidth: componentColumnWidth,
						location,
						isCompactColumnEnabled,
					}),
					'drag-and-drop',
				);

				// Re-enable pointer events to restore normal behaviour
				// https://issues.chromium.org/issues/41129937#comment43
				enableCssPointerEvents(tableRef?.current);
				clearCssResizingWidth(headerCellContainerRef.current);
			},
		});
	}, [
		isResizePopupOpen,
		column.id,
		componentColumnWidth,
		tableRef,
		onResizeColumn,
		isCompactColumnEnabled,
		column.type,
		isSingleRowHeightEnabled,
	]);

	const columnMinimumWidth = isSingleRowHeightEnabled
		? getMinimumColumnWidth(column.type)
		: undefined;
	// #endregion

	// #region Render
	return (
		<ContextualAnalyticsData attributes={analyticsAttributes}>
			<HeaderCellContainer
				ref={cellRefs}
				scope="col"
				columnWidth={componentColumnWidth}
				minColumnWidth={!isColumnResizingEnabled ? column.customWidth : undefined}
				isDraggable={hasColumnConfiguration}
				isHovered={isHovered || isSelected}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				{...(isInBetweenColumnPickerEnabled && {
					onMouseMove: handleMouseMove,
				})}
				// aria-label is added to prevent the extra accessibility hints in the header cell from being
				// read as the column title when a user is navigating between columns in the table content.
				aria-label={column.title}
				visualRefresh={isVisualRefreshEnabled()}
				isCompactColumnEnabled={isCompactColumnEnabled}
				tableBorderRadius={tableBorderRadius}
			>
				<Inline
					alignBlock="center"
					spread="space-between"
					xcss={state.type === 'reordering' && reorderingStyles}
					testId="native-issue-table.ui.issue-table.header.header-cell.header-cell-container-inline"
				>
					<Inline alignBlock="center" space="space.050" xcss={overflowHiddenStyles}>
						{column.warningText && <WarningIndicator tooltip={column.warningText} />}
						<Title>{column.title}</Title>
					</Inline>
					<HeaderCellActions
						column={column}
						currentColumnWidth={componentColumnWidth}
						hasColumnConfiguration={hasColumnConfiguration}
						isColumnResizingEnabled={isColumnResizingEnabled}
						isCompactColumnEnabled={isCompactColumnEnabled}
						isHeaderCellHovered={isHovered || isSelected}
						isFirst={isFirst}
						isLast={isLast}
						isOpen={isDropdownOpen}
						triggerRef={headerCellActionsTriggerRef}
						onMoveColumn={onMoveColumn}
						onOpenChange={setIsDropdownOpen}
						onRemoveColumn={onRemoveColumn}
						onResizeColumn={onResizeColumnAction}
						onResetColumnWidth={onResetColumnWidthAction}
					/>
				</Inline>
				{isColumnResizingEnabled && (
					<>
						<ResizePopup
							columnTitle={column.title}
							initialWidth={componentColumnWidth}
							placement="bottom-start"
							isOpen={isResizePopupOpen}
							isCompactColumnEnabled={isCompactColumnEnabled}
							minimumWidth={columnMinimumWidth}
							trigger={resizePopupTarget}
							onChange={onChangeResizePopup}
							onCancel={onCloseResizePopup}
							onSubmit={onSubmitResizePopup}
						/>
						{!isResizePopupOpen && (
							<ResizeHandle
								ref={resizeHandleRef}
								height={resizeHandleType === COLUMN_RESIZE_HANDLE_FULL_HEIGHT ? height : undefined}
								isResizing={state.type === 'resizing'}
								onMouseEnter={onMouseEnterResizeHandle}
								onMouseLeave={onMouseLeaveResizeHandle}
								onDoubleClick={onResetColumnWidthAction}
								data-testid="native-issue-table.ui.issue-table.header.header-cell.resize-handle"
								data-vc="native-issue-table-ui-resize-handle"
							/>
						)}
					</>
				)}
				{dropEdge && (
					<DropTarget
						data-testid="native-issue-table.ui.issue-table.header.header-cell.drop-target"
						height={height}
					>
						<DropIndicator edge={dropEdge} gap={BORDER_WIDTH} type="no-terminal" />
					</DropTarget>
				)}
				{state.type === 'preview' &&
					createPortal(
						<DragPreview
							data-testid="native-issue-table.ui.issue-table.header.header-cell.drag-preview"
							height={height}
							width={state.previewWidth}
						/>,
						state.container,
					)}
			</HeaderCellContainer>
		</ContextualAnalyticsData>
	);
	// #endregion
};

// #region Styles

/**
 * Invisible element to attach resizing popup to - the intermediate component is required to allow
 * passing the ref while blocking the other props from being passed to the underlying div.
 *
 * This is because the other props are intended for a trigger button, but we are only using this
 * for positioning pourposes.
 */
const resizePopupTarget = ({ ref }: { ref: Ref<HTMLDivElement> }) => (
	<ResizePopupTargetDiv ref={ref} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ResizePopupTargetDiv = styled.div({
	width: 0,
	height: 0,
	position: 'absolute',
	left: 0,
	bottom: `calc(${token('space.100')} - 1px)`, // extra 1px adjustment to account for cell border
});

const reorderingStyles = xcss({
	opacity: 0.4,
});

// Add overflow: hidden to the inline div so that the title can collapse its width if needed
// and give space to the right-hand side icons (sort/actions)
const overflowHiddenStyles = xcss({
	overflow: 'hidden',
});

const baseHeaderContainerCellStyles = {
	backgroundColor: token('elevation.surface.sunken'),
	borderColor: token('color.border'),
	borderStyle: 'solid',
	borderTopWidth: 0,
	borderBottomWidth: BORDER_WIDTH,
	borderLeftWidth: 0,
	borderRightWidth: BORDER_WIDTH,
	'&:first-of-type': {
		borderTopLeftRadius: token('border.radius.200'),
	},
	'&:last-of-type': {
		borderTopRightRadius: token('border.radius.200'),
		borderRightWidth: 0,
	},
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const EmptyHeaderCell = styled.th(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Fix for compiled prop bug with inheritance
	baseHeaderContainerCellStyles,
	{
		borderLeftWidth: 0,
		borderRightWidth: 0,
	},
);

// TODO: some stopgaps have been introduced to facilitate landing column config which should be addressed in EM-7165
// – remove export from HeaderCellContainer
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderCellContainer = styled.th<{
	columnWidth?: number;
	minColumnWidth?: number;
	isDraggable?: boolean;
	isHovered?: boolean;
	isSticky?: boolean;
	hasShadow?: boolean;
	visualRefresh: boolean;
	isCompactColumnEnabled: boolean;
	tableBorderRadius?: CSSToken;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Fix for compiled prop bug with inheritance
	baseHeaderContainerCellStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ tableBorderRadius }) => ({
		position: 'relative',
		boxSizing: 'border-box',
		height: `${HEADER_HEIGHT}px`,
		maxHeight: `${HEADER_HEIGHT}px`,
		minHeight: `${HEADER_HEIGHT}px`,
		paddingBlock: token('space.0'),
		paddingInline: token('space.100'),
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			borderTopLeftRadius: `${tableBorderRadius ?? token('space.100')}`,
			// Overrides @atlaskit/css-reset styles
			paddingLeft: token('space.100'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:last-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			borderTopRightRadius: `${tableBorderRadius ?? token('space.100')}`,
			// Overrides @atlaskit/css-reset styles
			paddingRight: token('space.100'),
		},
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ columnWidth, minColumnWidth, isCompactColumnEnabled }) => ({
		// Width allows overrides when table is in fixed layout mode
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'--local-column-width': columnWidth != null ? `${columnWidth}px` : 'auto',

		width: 'var(--local-resizing-width, var(--local-column-width))',
		// Min-width allows overrides when table is in auto layout mode
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'--local-column-min-width': minColumnWidth != null ? `${minColumnWidth}px` : 'auto',
		minWidth: 'var(--local-resizing-width, var(--local-column-min-width))',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:first-of-type': {
			paddingLeft:
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
				isCompactColumnEnabled && columnWidth && columnWidth < 40 ? '0px' : token('space.100'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:last-of-type': {
			paddingRight:
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
				isCompactColumnEnabled && columnWidth && columnWidth < 40 ? '0px' : token('space.100'),
		},
	}),
	// Programmatic `isHovered` state is used here so hover state isn't displayed when hovering the resize handle
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDraggable, isHovered }) =>
		isDraggable &&
		isHovered && {
			':hover': {
				cursor: 'grab',
				backgroundColor: token('elevation.surface.hovered'),
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSticky }) =>
		isSticky && {
			position: 'sticky',
			right: 0,

			zIndex: STICKY_CELL_Z_INDEX,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasShadow }) =>
		hasShadow && {
			boxShadow: `
                -8px 0 8px -8px ${token('elevation.shadow.overflow.spread')},
                -1px 0 1px -1px ${token('elevation.shadow.overflow.perimeter')}
            `,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			borderLeftWidth: `${BORDER_WIDTH} !important`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh
			? { color: token('color.text.subtle'), font: token('font.heading.xxsmall') }
			: {
					color: token('color.text.accent.gray'),
					fontWeight: token('font.weight.medium'),
				},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	paddingRight: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandle = styled.div<{ height?: number; isResizing?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isResizing }) =>
		isResizing ? token('color.border.selected') : 'transparent',
	backgroundClip: 'content-box',
	width: '2px',
	paddingInline: token('space.050'),
	position: 'absolute',
	top: token('space.0'),
	right: `calc(${token('space.negative.050')} - 1px)`, // extra 1px adjustment to account for normal cell borders
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => (height != null ? `${height}px` : '100%'),
	cursor: 'ew-resize',
	// ensure it renders above the following header cell, but not any dialogs/modals/etc
	zIndex: RESIZE_HANDLE_Z_INDEX,
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isResizing }) => (isResizing ? undefined : token('color.border.bold')),
	},
});

// Invisible box surrounding the full column for the `DropIndicator` to attach to
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropTarget = styled.div<{ height: number }>({
	position: 'absolute',
	top: token('space.0'),
	left: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	width: '100%',
});

// Custom drag preview that looks like a ghost blue column
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragPreview = styled.div<{ height: number; width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	maxHeight: '100vh',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	borderColor: token('color.border.focused'),
	borderStyle: 'solid',
	borderRadius: token('border.radius.100'),
	borderWidth: token('border.width.outline'),
	backgroundColor: token('color.blanket.selected'),
});
