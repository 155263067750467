/**
 * @generated SignedSource<<c58da285cd1605ef51b4105866eae3ae>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type groupHeader_nativeIssueTable$data = {
  readonly fieldType: string | null | undefined;
  readonly fieldValue: {
    readonly " $fragmentSpreads": FragmentRefs<"assigneeGroupHeader_nativeIssueTable" | "categoryGroupHeader_nativeIssueTable" | "goalGroupHeader_nativeIssueTable" | "priorityGroupHeader_nativeIssueTable" | "sprintGroupHeader_nativeIssueTable" | "statusGroupHeader_nativeIssueTable" | "storyPointsGroupHeader_nativeIssueTable">;
  } | null | undefined;
  readonly id: string;
  readonly issueCount: number | null | undefined;
  readonly " $fragmentType": "groupHeader_nativeIssueTable";
};
export type groupHeader_nativeIssueTable$key = {
  readonly " $data"?: groupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "groupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldType"
    },
    {
      "kind": "ScalarField",
      "name": "issueCount"
    },
    {
      "kind": "LinkedField",
      "name": "fieldValue",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "priorityGroupHeader_nativeIssueTable"
        },
        {
          "kind": "FragmentSpread",
          "name": "assigneeGroupHeader_nativeIssueTable"
        },
        {
          "kind": "FragmentSpread",
          "name": "statusGroupHeader_nativeIssueTable"
        },
        {
          "kind": "FragmentSpread",
          "name": "categoryGroupHeader_nativeIssueTable"
        },
        {
          "kind": "FragmentSpread",
          "name": "sprintGroupHeader_nativeIssueTable"
        },
        {
          "kind": "FragmentSpread",
          "name": "storyPointsGroupHeader_nativeIssueTable"
        },
        {
          "kind": "FragmentSpread",
          "name": "goalGroupHeader_nativeIssueTable"
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetGroup"
};

(node as any).hash = "baa0e64c6dcc0ab449db99273da35823";

export default node;
