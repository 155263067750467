/**
 * @generated SignedSource<<9815ea3c7a55b07ebc8b0704650cd8b5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assigneeGroupHeader_nativeIssueTable$data = {
  readonly displayName: string;
  readonly user: {
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentType": "assigneeGroupHeader_nativeIssueTable";
};
export type assigneeGroupHeader_nativeIssueTable$key = {
  readonly " $data"?: assigneeGroupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"assigneeGroupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assigneeGroupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "displayName"
    },
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "picture"
        }
      ]
    }
  ],
  "type": "JiraJqlUserFieldValue"
};

(node as any).hash = "e9d182e8223ecc02797da0c973655152";

export default node;
