import React from 'react';
import { xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import { TableCell } from '../../table-cell/index.tsx';

export const LoadingCell = () => {
	return (
		<TableCell border={cellBorderStyles}>
			<Skeleton
				testId="native-issue-table.common.ui.issue-cells.loading-cell"
				height="16px"
				width="100%"
				borderRadius="3px"
			/>
		</TableCell>
	);
};

const cellBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: '0',
	borderBottomWidth: 'border.width',
	borderLeftWidth: '0',
	borderRightWidth: 'border.width',
});
