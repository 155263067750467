import React, { memo, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, Stack, Text } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import ItemWithPopup from '@atlassian/jira-item-with-popup/src/ui/item-with-popup/index.tsx';
import { ItemWithEntryPointPopup } from '@atlassian/jira-item-with-popup/src/ui/item-with-entrypoint-popup/index.tsx';
import { TagItem } from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import type { issueLinks_nativeIssueTable_IssueLinksCell$key } from '@atlassian/jira-relay/src/__generated__/issueLinks_nativeIssueTable_IssueLinksCell.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME } from '../../../constants.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { issueLinksPopUpContentEntryPoint } from './issue-links-popup-content/entrypoint.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: issueLinks_nativeIssueTable_IssueLinksCell$key;
	issueSearchBaseUrl: string;
	issueKey: string;
	/**
	 * Whether the accordions should be open by default when opening the ListCellWithPopup
	 * Default = false
	 */
	accordionInitialIsOpen?: boolean;
};

export const useIsOpen = (initialIsOpen: boolean) => useState(initialIsOpen);

export type IssueLink = {
	key: string;
	name: string;
	href: string;
	relationName?: string | null;
	shouldDisplayStrikethrough: boolean;
};

export const IssueLinksCell = memo<Props>(
	({ fieldRef, issueSearchBaseUrl, issueKey, accordionInitialIsOpen = false }: Props) => {
		const [isOpen, setIsOpen] = useIsOpen(false);

		const { formatMessage } = useIntl();

		const data = useFragment<issueLinks_nativeIssueTable_IssueLinksCell$key>(
			graphql`
				fragment issueLinks_nativeIssueTable_IssueLinksCell on JiraIssueLinkField {
					issueLinkConnection(first: 1) {
						totalCount
						edges {
							node {
								issue {
									webUrl
									key
									fieldsById(ids: ["statusCategory"]) {
										edges {
											node {
												... on JiraStatusCategoryField {
													statusCategory {
														statusCategoryId
													}
												}
											}
										}
									}
								}
								relationName
							}
						}
					}
				}
			`,
			fieldRef,
		);

		const cloudId = useCloudId();
		const entryPointParams = useMemo(() => ({ issueKey, cloudId }), [issueKey, cloudId]);

		const issueLinks = data?.issueLinkConnection?.edges;
		const totalCount = data?.issueLinkConnection?.totalCount;

		const filteredIssueLinks: IssueLink[] = useMemo(
			() =>
				issueLinks
					?.map((edge) =>
						edge?.node?.issue?.key != null
							? {
									key: edge.node.issue?.key || 'missingId',
									name: edge.node.issue?.key,
									href: edge.node.issue?.webUrl || '',
									relationName: edge.node.relationName,
									shouldDisplayStrikethrough:
										edge.node.issue?.fieldsById?.edges?.[0]?.node?.statusCategory
											?.statusCategoryId === '3' || false,
								}
							: null,
					)
					.filter(Boolean) ?? [],
			[issueLinks],
		);

		if (fg('jsc_inline_editing_field_refactor')) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const runtimeProps = useMemo(
				() => ({ issueKey, issueSearchBaseUrl, accordionInitialIsOpen }),
				[issueKey, issueSearchBaseUrl, accordionInitialIsOpen],
			);

			if (!issueLinks) {
				return <ErrorCell cellType="issueLinks" reason="Missing issue links field" />;
			}
			if (issueLinks.length !== filteredIssueLinks.length) {
				return <ErrorCell cellType="issueLinks" reason="Missing fields on issue links" />;
			}
			if (totalCount === undefined || totalCount === null) {
				return <ErrorCell cellType="issueLinks" reason="Missing total count" />;
			}
			if (totalCount < 0) {
				return <ErrorCell cellType="issueLinks" reason="Invalid total count" />;
			}
			if (totalCount > 0 && filteredIssueLinks.length === 0) {
				return (
					<ErrorCell cellType="issueLinks" reason="Total count valid but no issue links given" />
				);
			}

			return (
				<Box>
					<ItemWithEntryPointPopup
						entryPoint={issueLinksPopUpContentEntryPoint}
						entryPointParams={entryPointParams}
						entryPointRuntimeProps={runtimeProps}
						totalItems={Math.min(totalCount, 1000)}
						ItemComponent={
							totalCount ? (
								<TagItem
									name={filteredIssueLinks[0].name}
									href={filteredIssueLinks[0].href}
									shouldDisplayStrikethrough={filteredIssueLinks[0].shouldDisplayStrikethrough}
									key={filteredIssueLinks[0].key}
								/>
							) : undefined
						}
						showMoreTooltip={formatMessage(messages.triggerButton)}
						actionSubjectIdPrefix="issueLinks"
					/>
				</Box>
			);
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
			issueLinksPopUpContentEntryPoint,
			entryPointParams,
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [retryKey, setRetryKey] = useState(0);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

		if (!issueLinks) {
			return <ErrorCell cellType="issueLinks" reason="Missing issue links field" />;
		}
		if (issueLinks.length !== filteredIssueLinks.length) {
			return <ErrorCell cellType="issueLinks" reason="Missing fields on issue links" />;
		}
		if (totalCount === undefined || totalCount === null) {
			return <ErrorCell cellType="issueLinks" reason="Missing total count" />;
		}
		if (totalCount < 0) {
			return <ErrorCell cellType="issueLinks" reason="Invalid total count" />;
		}
		if (totalCount > 0 && filteredIssueLinks.length === 0) {
			return (
				<ErrorCell cellType="issueLinks" reason="Total count valid but no issue links given" />
			);
		}

		return (
			<Box>
				<ItemWithPopup
					totalItems={Math.min(totalCount, 1000)} // Equal to the `first` field in the query used in the entry point
					isOpen={isOpen}
					onSetIsOpen={setIsOpen}
					triggerRefCallback={triggerRef}
					ItemComponent={
						totalCount ? (
							<TagItem
								name={filteredIssueLinks[0].name}
								href={filteredIssueLinks[0].href}
								shouldDisplayStrikethrough={filteredIssueLinks[0].shouldDisplayStrikethrough}
								key={filteredIssueLinks[0].key}
							/>
						) : undefined
					}
					showMoreTooltip={formatMessage(messages.triggerButton)}
					PopupComponent={
						<JiraEntryPointContainer
							key={retryKey}
							entryPointReferenceSubject={entryPointReferenceSubject}
							id="issue-links-popup-content"
							packageName={PACKAGE_NAME}
							errorFallback={() => (
								<PopupErrorComponent onClick={() => setRetryKey((prev) => prev + 1)} />
							)}
							fallback={
								<Box padding="space.050">
									<Spinner size="medium" />
								</Box>
							}
							runtimeProps={{ issueKey, issueSearchBaseUrl, accordionInitialIsOpen }}
						/>
					}
					actionSubjectIdPrefix="issueLinks"
				/>
			</Box>
		);
	},
);

export const PopupErrorComponent = ({ onClick }: { onClick: () => void }) => {
	const { formatMessage } = useIntl();

	return (
		<Stack alignInline="center" space="space.050">
			<ErrorIcon color={token('color.icon')} LEGACY_primaryColor={token('color.icon')} label="" />
			<Text as="strong">
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.errorMessageIssueTermRefresh
						: messages.errorMessage,
				)}
			</Text>
			<Button
				onClick={onClick}
				appearance="link"
				spacing="compact"
				testId="native-issue-table.common.ui.issue-cells.issue-links.button"
			>
				{formatMessage(messages.retryMessage)}
			</Button>
		</Stack>
	);
};
