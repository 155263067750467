import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	nextButton: {
		id: 'native-issue-table.footer-renderer.pagination-controls.next-button',
		defaultMessage: 'Next page',
		description: 'Label shown for the "Next page" button',
	},
	prevButton: {
		id: 'native-issue-table.footer-renderer.pagination-controls.prev-button',
		defaultMessage: 'Previous page',
		description: 'Label shown for the "Previous page" button',
	},
	pageButtonPrefix: {
		id: 'native-issue-table.footer-renderer.pagination-controls.page-button-prefix',
		defaultMessage: 'Page {page}',
		description: 'Label shown for the "page number" button',
	},
	disabledButtonTooltip: {
		id: 'native-issue-table.footer-renderer.pagination-controls.disabled-button-tooltip',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more issues.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
	disabledButtonTooltipIssueTermRefresh: {
		id: 'native-issue-table.footer-renderer.pagination-controls.disabled-button-tooltip-issue-term-refresh',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more work.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
});
