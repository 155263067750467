/**
 * @generated SignedSource<<f7957e8652fedd915d40b24c6f0b7454>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type categoryGroupHeader_nativeIssueTable$data = {
  readonly displayName: string;
  readonly option: {
    readonly color: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "categoryGroupHeader_nativeIssueTable";
};
export type categoryGroupHeader_nativeIssueTable$key = {
  readonly " $data"?: categoryGroupHeader_nativeIssueTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"categoryGroupHeader_nativeIssueTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "categoryGroupHeader_nativeIssueTable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "displayName"
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "option",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraColor",
          "kind": "LinkedField",
          "name": "color",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "colorKey"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraJqlOptionFieldValue"
};

(node as any).hash = "c77041abe849edaf5c1cf362ca5e152f";

export default node;
