/**
 * @generated SignedSource<<062fdfba47135a99742296f1356235f7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type selectedRowState_nativeIssueTable_SelectedRowContainer$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly issueId: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly cursor: string | null | undefined;
      readonly isCurrent: boolean | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "selectedRowState_nativeIssueTable_SelectedRowContainer";
};
export type selectedRowState_nativeIssueTable_SelectedRowContainer$key = {
  readonly " $data"?: selectedRowState_nativeIssueTable_SelectedRowContainer$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectedRowState_nativeIssueTable_SelectedRowContainer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "name": "selectedRowState_nativeIssueTable_SelectedRowContainer",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        },
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "cursor"
            },
            {
              "kind": "ScalarField",
              "name": "isCurrent"
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "558a18d92170dc40c5bb420aa3fa3826";

export default node;
