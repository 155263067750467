/**
 * @generated SignedSource<<51281952c53ea8a9049c9fb9163738de>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type user_nativeIssueTable_UserCellInternal_fieldRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly lazyIsEditableInIssueView?: boolean | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef" | "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef" | "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "user_nativeIssueTable_UserCellInternal_fieldRef";
};
export type user_nativeIssueTable_UserCellInternal_fieldRef$key = {
  readonly " $data"?: user_nativeIssueTable_UserCellInternal_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"user_nativeIssueTable_UserCellInternal_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "user_nativeIssueTable_UserCellInternal_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "condition": "isInlineEditingEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "lazyIsEditableInIssueView"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "70c9b5cc4f6637fc8553932c9c90dc8f";

export default node;
