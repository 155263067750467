/**
 * @generated SignedSource<<176cf950f3063d94accb5a37ad63e7ce>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedComponentsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly label: string | null | undefined;
        readonly value: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"components_issueFieldComponentsReadviewFull_ComponentsReadView">;
  readonly " $fragmentType": "components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef";
};
export type components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "components_issueFieldComponentsReadviewFull_ComponentsReadView"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          }
        ],
        "concreteType": "JiraComponentConnection",
        "kind": "LinkedField",
        "name": "selectedComponentsConnection",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraComponentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "JiraComponent",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "value",
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "alias": "label",
                    "kind": "ScalarField",
                    "name": "name"
                  }
                ]
              }
            ]
          }
        ],
        "storageKey": "selectedComponentsConnection(first:1000)"
      },
      "action": "THROW",
      "path": "selectedComponentsConnection"
    }
  ],
  "type": "JiraComponentsField"
};

(node as any).hash = "7d58e8d795b9134c31920723b434efe7";

export default node;
