import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipText: {
		id: 'issue-table-column-picker.column-picker.picker-button.tooltip-text',
		defaultMessage: 'Configure columns',
		description: 'Tooltip over button that opens the column configuration menu',
	},
	addColumnTooltipText: {
		id: 'issue-table-column-picker.column-picker.picker-button.add-column-tooltip-text',
		defaultMessage: 'Add column',
		description: 'Tooltip over button that opens the column configuration menu',
	},
	columnPickerButtonText: {
		id: 'issue-table-column-picker.column-picker.picker-button.column-picker-button-text',
		defaultMessage: 'Column picker menu',
		description: 'Label for Column Picker Button',
	},
	chevronDownIconText: {
		id: 'issue-table-column-picker.column-picker.picker-button.chevron-down-icon-text',
		defaultMessage: 'Chevron down icon',
		description: 'Label for chevron down icon in Column Picker Button',
	},
	boardIconText: {
		id: 'issue-table-column-picker.column-picker.picker-button.board-icon-text',
		defaultMessage: 'board icon',
		description: 'Label for board icon in Column Picker Button',
	},
});
