import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import SearchNoResultsImageOld from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { NoIssuesProps } from '../../../common/types.tsx';
import { Overlay, OverlayOld } from '../../../common/ui/overlay/index.tsx';
import { NoIssues as DefaultNoIssues } from './no-issues/index.tsx';

type Props = {
	NoIssues?: ComponentType<NoIssuesProps>;
	isLoading?: boolean;
};

const NoIssuesRenderer = ({ NoIssues = DefaultNoIssues, isLoading = false }: Props) => (
	<EmptyIssuesContainer>
		<NoIssues
			defaultNoIssuesGlyph={
				isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? (
					<Box xcss={ImageWrapperStyles}>
						<SearchNoResultsImage alt="" />
					</Box>
				) : (
					<Box xcss={ImageWrapperStylesOld}>
						<SearchNoResultsImageOld alt="" />
					</Box>
				)
			}
		/>
		{isLoading &&
			(fg('add_nin_press_interactions') ? (
				<Overlay interactionName="issue-table-no-issues-renderer-overlay" />
			) : (
				<OverlayOld />
			))}
		<FireUiAnalytics
			actionSubject="screen"
			action="viewed"
			actionSubjectId="nativeIssueTableNoResults"
		/>
	</EmptyIssuesContainer>
);

export default NoIssuesRenderer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyIssuesContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	flex: 1,
	position: 'sticky',
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		width: '228px',
	},
});

const ImageWrapperStylesOld = xcss({
	maxHeight: '146px',
	maxWidth: '160px',
	margin: 'auto',
	marginBottom: 'space.300',
});

const ImageWrapperStyles = xcss({
	maxHeight: '165px',
	maxWidth: '150px',
	margin: 'auto',
	marginBottom: 'space.300',
});
