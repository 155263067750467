import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import AsyncIssueOperations from '@atlassian/jira-issue-navigator-issue-operations/src/ui/async.tsx';

type Props = {
	issueKey: string;
	issueId: number;
	isCreateSubTaskAllowed?: boolean;
	onIssueRefetch?: (issueKey: string) => void;
	onIssueDelete?: (issueKey: string) => void;
};

export const IssueOperations = memo<Props>(
	({ issueKey, issueId, onIssueRefetch, onIssueDelete, isCreateSubTaskAllowed }: Props) => (
		<ButtonWrapper>
			<AsyncIssueOperations
				issueId={issueId}
				issueKey={issueKey}
				isCreateSubTaskAllowed={isCreateSubTaskAllowed}
				onIssueRefetch={onIssueRefetch}
				onIssueDelete={onIssueDelete}
			/>
		</ButtonWrapper>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	backgroundColor: token('color.background.neutral.subtle'),
	borderRadius: '4px',
	width: 'fit-content',
	marginLeft: 'auto',
});
