/**
 * @generated SignedSource<<373e632fc369142429d535218f0816a2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type components_nativeIssueTable_ComponentsCell_fieldRef$data = {
  readonly componentsOld: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "components_nativeIssueTable_ComponentsCell_fieldRef";
};
export type components_nativeIssueTable_ComponentsCell_fieldRef$key = {
  readonly " $data"?: components_nativeIssueTable_ComponentsCell_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"components_nativeIssueTable_ComponentsCell_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "components_nativeIssueTable_ComponentsCell_fieldRef",
  "selections": [
    {
      "alias": "componentsOld",
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "name"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
};

(node as any).hash = "0c7ed7763b84f4a5f5fdf18a8ff99f37";

export default node;
