// Adapted from '@atlassian/jira-issue-table/src/common/fields/issue-key';

import React, { memo, useRef } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { resolution_nativeIssueTable_ResolutionCell$key } from '@atlassian/jira-relay/src/__generated__/resolution_nativeIssueTable_ResolutionCell.graphql';
import { useIsSingleLineRowHeightEnabled } from '../../../../controllers/features/selectors.tsx';
import { HoverPopover } from '../../hover-popover/index.tsx';
import messages from './messages.tsx';

type Props = {
	fieldRef: resolution_nativeIssueTable_ResolutionCell$key;
};

export const ResolutionCell = memo<Props>(({ fieldRef }: Props) => {
	const { formatMessage } = useIntl();
	const { resolution } = useFragment<resolution_nativeIssueTable_ResolutionCell$key>(
		graphql`
			fragment resolution_nativeIssueTable_ResolutionCell on JiraResolutionField {
				resolution {
					name
				}
			}
		`,
		fieldRef,
	);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	const contentRef = useRef<HTMLDivElement>(null);

	if (!isSingleLineRowHeightEnabled)
		return (
			<Cell data-vc="native-issue-table-ui-resolution-cell">
				{resolution?.name ?? formatMessage(messages.unresolved)}
			</Cell>
		);

	return (
		<HoverPopover
			content={resolution?.name ?? formatMessage(messages.unresolved)}
			contentRef={contentRef}
		>
			<Cell data-vc="native-issue-table-ui-resolution-cell" ref={contentRef}>
				{resolution?.name ?? formatMessage(messages.unresolved)}
			</Cell>
		</HoverPopover>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Cell = styled.div({
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
