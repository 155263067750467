/**
 * @generated SignedSource<<e82052d0b1365d9ea76e4cdcc375b79a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type plainText_nativeIssueTable_PlainTextCellOld_fieldRef$data = {
  readonly text: string | null | undefined;
  readonly " $fragmentType": "plainText_nativeIssueTable_PlainTextCellOld_fieldRef";
};
export type plainText_nativeIssueTable_PlainTextCellOld_fieldRef$key = {
  readonly " $data"?: plainText_nativeIssueTable_PlainTextCellOld_fieldRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"plainText_nativeIssueTable_PlainTextCellOld_fieldRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "plainText_nativeIssueTable_PlainTextCellOld_fieldRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "1a8cd11903dcf3b1dee4235893fe7cd3";

export default node;
