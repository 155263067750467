import type { hierarchy_childRowsPreloadedQuery$variables } from '@atlassian/jira-relay/src/__generated__/hierarchy_childRowsPreloadedQuery.graphql';
import { ACTION_COLUMNS, MAX_COLUMNS, CHILD_QUERY_PAGE_SIZE } from '../../common/constants.tsx';
import type { Column, SortOrder } from '../../common/types.tsx';
import { getProjectKeyFromIssueKey } from '../../common/utils/index.tsx';

type BaseProps = {
	cloudId: string;
	columns: Column[];
	isReparentingEnabled: boolean;
};

type ChildQueryForJqlProps = BaseProps & {
	jql: string;
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-child-query-variables/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const getChildQueryVariablesBase = ({
	cloudId,
	columns,
	isReparentingEnabled,
}: BaseProps) => {
	const fieldSetIds = columns
		.filter(({ id }) => !!id && !ACTION_COLUMNS.includes(id))
		.map(({ id }) => id)
		.sort();

	return {
		cloudId,
		amountOfColumns: MAX_COLUMNS,
		fieldSetsInput: {
			fieldSetIds,
		},
		fieldSetIds,
		first: CHILD_QUERY_PAGE_SIZE,
		namespace: '',
		shouldQueryFieldSetsById: true,
		filterId: null,
		viewId: null,
		isReparentingEnabled,
	};
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-child-query-variables/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const getChildQueryVariablesForJql = ({
	cloudId,
	columns,
	jql,
	isReparentingEnabled,
}: ChildQueryForJqlProps) => {
	return {
		...getChildQueryVariablesBase({ cloudId, columns, isReparentingEnabled }),
		issueSearchInput: { jql },
		shouldQueryHasChildren: false,
	};
};

type ChildQueryForParentIssueProps = BaseProps & {
	parentIssueKey: string;
	sortField?: string;
	sortDirection?: SortOrder;
	jql?: string;
	isReparentingEnabled: boolean;
	isHideDoneItemsEnabled: boolean;
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-child-query-variables/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const getChildQueryVariablesForParentIssue = ({
	cloudId,
	columns,
	parentIssueKey,
	jql,
	isReparentingEnabled,
	isHideDoneItemsEnabled,
}: ChildQueryForParentIssueProps): hierarchy_childRowsPreloadedQuery$variables => {
	const projectKey = getProjectKeyFromIssueKey(parentIssueKey);

	return {
		...getChildQueryVariablesBase({ cloudId, columns, isReparentingEnabled }),
		issueSearchInput: {
			childIssuesInput: {
				parentIssueKey,
				filterByProjectKeys: [projectKey],
				...(jql
					? {
							jql,
						}
					: {}),
				...(isHideDoneItemsEnabled && { namespace: 'ISSUE_NAVIGATOR' }),
			},
		},
		projectKeys: [projectKey],
		projectKey,
		viewConfigInput: {
			staticViewInput: {
				isHierarchyEnabled: true,
				isGroupingEnabled: false,
				isHideDoneEnabled: isHideDoneItemsEnabled,
			},
		},
		shouldQueryHasChildren: true,
	};
};
