// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	type Action,
	createHook,
	createStateHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';

type State = {
	selectedCell: [number, number];
	isKeyboardNavOn: boolean;
};

const initialState: State = {
	// holds the current selected cell in the table [row, col]
	selectedCell: [0, 0],
	// if this is on, it means user has tabbed into the table and the arrow keys etc can be used to navigate the table
	isKeyboardNavOn: false,
};

const actions = {
	setIsKeyboardNavOn:
		(value: boolean): Action<State> =>
		({ setState }) => {
			setState({
				isKeyboardNavOn: value,
			});
		},
	setSelectedCell:
		(row: number, col: number): Action<State> =>
		({ setState }) => {
			setState({
				selectedCell: [row, col],
			});
		},
	moveRowDown:
		(by: number, max: number): Action<State> =>
		({ setState, getState }) => {
			const {
				selectedCell: [row, col],
			} = getState();

			if (row < max) {
				setState({
					selectedCell: [row + by, col],
				});
			}
		},
	moveRowUp:
		(by: number, max: number): Action<State> =>
		({ setState, getState }) => {
			const {
				selectedCell: [row, col],
			} = getState();

			if (row > max) {
				setState({
					selectedCell: [row - by, col],
				});
			}
		},
	moveColumnRight:
		(by: number, max: number): Action<State> =>
		({ setState, getState }) => {
			const {
				selectedCell: [row, col],
			} = getState();

			if (col < max) {
				setState({
					selectedCell: [row, col + by],
				});
			}
		},
	moveColumnLeft:
		(by: number, max: number): Action<State> =>
		({ setState, getState }) => {
			const {
				selectedCell: [row, col],
			} = getState();

			if (col > max) {
				setState({
					selectedCell: [row, col - by],
				});
			}
		},
};

const Store = createStore({
	initialState,
	actions,
});

export const useIsKeyBoardNavOn = createStateHook(Store, {
	selector: (state) => state.isKeyboardNavOn,
});

export const useCellNavigationActions = createActionsHook(Store);

// we expose this function to the children of this provider so they can check if they are selected and react to it
export const useIsSelectedCell = createHook(Store, {
	selector: ({ selectedCell, isKeyboardNavOn }, [row, col]: [number, number]) =>
		isKeyboardNavOn && selectedCell[0] === row && selectedCell[1] === col,
});
