import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { commitLocalUpdate } from 'relay-runtime';

export const useUpdateIssueSummary = () => {
	const environment = useRelayEnvironment();
	return useCallback(
		(issueRelayId: string, summary: string) => {
			commitLocalUpdate(environment, (store) => {
				const issueEntry = store.get(issueRelayId);
				if (issueEntry) {
					issueEntry.setValue(summary, 'summary');
				}
			});
		},
		[environment],
	);
};
