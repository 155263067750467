/** @jsx jsx */
import React, { useCallback, useMemo, useRef, useLayoutEffect, type RefCallback } from 'react';
import { css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import { graphql, useFragment } from 'react-relay';
import { Text, Inline, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import {
	GOALS_CF_TYPE,
	STATUS_TYPE,
	NUMBER_CF_TYPE,
	PRIORITY_TYPE,
	CATEGORY_TYPE,
	ASSIGNEE_TYPE,
	SPRINT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { groupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/groupHeader_nativeIssueTable.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIsItemExpanded,
	useIsItemLoading,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import {
	ExpandButtonWithCreateChildButton as ExpandButtonWithCreateChildButtonNew,
	ExpandButton,
} from '@atlassian/jira-issue-table-hierarchy/src/ui/expand-button/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import CreateChildButton from '@atlassian/jira-issue-table-hierarchy/src/ui/create-child-button/index.tsx';
import { useIsSelected } from '../../../../controllers/hierarchy/index.tsx';
import { ExpandButtonWithCreateChildButton as ExpandButtonWithCreateChildButtonOld } from '../../../expand-button/index.tsx';

import { useRowContext } from '../../../rows/row-context/index.tsx';
import { AssigneeGroupHeader } from './assignee-group-header/index.tsx';
import { CategoryGroupHeader } from './category-group-header/index.tsx';
import messages from './messages.tsx';
import { PriorityGroupHeader } from './priority-group-header/index.tsx';
import { SprintGroupHeader } from './sprint-group-header/index.tsx';
import { StatusGroupHeader } from './status-group-header/index.tsx';
import { StoryPointsGroupHeader } from './story-points-group-header/index.tsx';
import { GoalGroupHeader } from './goal-group-header/index.tsx';
import { isCreateIssueSupportedForFieldType } from './utils.tsx';

export type Props = {
	groupHeader: groupHeader_nativeIssueTable$key;
	groupConnectionId: string;
	isGroupAtTop?: boolean;
	createChildTriggerProps?: {
		onClick: () => void;
		ref: RefCallback<HTMLElement>;
	};
};

export const GroupHeader = ({
	groupHeader,
	groupConnectionId,
	createChildTriggerProps,
	isGroupAtTop = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const tableRowRef = useRef<HTMLTableRowElement>(null);

	const data = useFragment<groupHeader_nativeIssueTable$key>(
		graphql`
			fragment groupHeader_nativeIssueTable on JiraSpreadsheetGroup {
				id
				fieldType
				issueCount
				fieldValue {
					...priorityGroupHeader_nativeIssueTable
					...assigneeGroupHeader_nativeIssueTable
					...statusGroupHeader_nativeIssueTable
					...categoryGroupHeader_nativeIssueTable
					...sprintGroupHeader_nativeIssueTable
					...storyPointsGroupHeader_nativeIssueTable
					...goalGroupHeader_nativeIssueTable
				}
			}
		`,
		groupHeader,
	);

	const { columns, groupId } = useRowContext();

	const itemDetails = useMemo(
		() => (data ? { itemsConnectionId: groupConnectionId, itemId: data.id, groupId } : null),
		[data, groupConnectionId, groupId],
	);

	const [isSelected, { setSelectedRow }] = useIsSelected(itemDetails, {
		selectedRowIndex: -1,
	});

	useLayoutEffect(() => {
		// Scroll the table row into view (if needed - block: nearest) when it becomes selected
		if (isSelected) {
			tableRowRef.current?.scrollIntoView({ block: 'nearest' });
		}
	}, [isSelected]);

	const focusCurrentRow = useCallback(() => {
		// jsc_m2_hierarchy_fe_changes cleanup reference - The method below has a different signature compared to the old implementation which leads to this type error which is not trivial to solve
		// @ts-expect-error - TS2345: Argument of type '[number | { issuesConnectionId: string; issueId: string; }]' is not assignable to parameter of type '{ issuesConnectionId: string; issueId: string; } & number'
		return setSelectedRow(itemDetails);
	}, [setSelectedRow, itemDetails]);

	const groupHeaderView = useMemo(() => {
		switch (data.fieldType) {
			case PRIORITY_TYPE:
				return <PriorityGroupHeader priorityGroupHeader={data.fieldValue ?? null} />;
			case ASSIGNEE_TYPE:
				return <AssigneeGroupHeader assigneeGroupHeader={data.fieldValue ?? null} />;
			case STATUS_TYPE:
				return <StatusGroupHeader statusGroupHeader={data.fieldValue ?? null} />;
			case NUMBER_CF_TYPE:
			case STORY_POINT_ESTIMATE_CF_TYPE:
				return <StoryPointsGroupHeader storyPointsGroupHeader={data.fieldValue ?? null} />;
			case GOALS_CF_TYPE:
				return <GoalGroupHeader goalGroupHeader={data.fieldValue ?? null} />;
			case CATEGORY_TYPE:
				return <CategoryGroupHeader categoryGroupHeader={data.fieldValue ?? null} />;
			case SPRINT_TYPE:
				return <SprintGroupHeader sprintGroupHeader={data.fieldValue ?? null} />;
			default:
				// should never happen
				return null;
		}
	}, [data]);

	const groupMetadata = useMemo(() => {
		return (
			<>
				{groupHeaderView}
				{!isNil(data?.issueCount) && (
					<Text color="color.text.subtlest">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.issuesIssueTermRefresh
								: messages.issues,
							{ count: data.issueCount },
						)}
					</Text>
				)}
			</>
		);
	}, [data.issueCount, formatMessage, groupHeaderView]);

	const [isExpanded, { toggleIsItemExpanded }] = useIsItemExpanded(itemDetails);
	const [isLoading] = useIsItemLoading(data?.id ?? null);

	const ExpandButtonWithCreateChildButton = fg('jira_list_hierarchy_extraction')
		? ExpandButtonWithCreateChildButtonNew
		: ExpandButtonWithCreateChildButtonOld;

	const onCreateChildClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'groupCreateChildButton');
		if (!isExpanded) {
			toggleIsItemExpanded(itemDetails, 0);
		}
		createChildTriggerProps?.onClick();
	}, [
		createAnalyticsEvent,
		isExpanded,
		toggleIsItemExpanded,
		itemDetails,
		createChildTriggerProps,
	]);

	const headerContent = useMemo(() => {
		if (expVal('jira_spreadsheet_issue_create_in_group', 'isEnabled', false)) {
			return (
				<>
					{data.issueCount ? (
						<ExpandButton
							itemId={data.id}
							itemsConnectionId={groupConnectionId}
							depth={0}
							isGroupButton
							groupId={groupId}
						/>
					) : null}
					{groupMetadata}
					{isCreateIssueSupportedForFieldType(data.fieldType) && (
						<CreateChildButton onClick={onCreateChildClick} ref={createChildTriggerProps?.ref} />
					)}
				</>
			);
		}

		return (
			<>
				{data.issueCount ? (
					<ExpandButtonWithCreateChildButton
						isGroupButton
						itemId={data.id}
						itemsConnectionId={groupConnectionId}
						depth={0}
						groupId={groupId}
					/>
				) : null}
				{groupMetadata}
			</>
		);
	}, [
		data.issueCount,
		data.id,
		data.fieldType,
		ExpandButtonWithCreateChildButton,
		groupConnectionId,
		groupId,
		groupMetadata,
		onCreateChildClick,
		createChildTriggerProps?.ref,
	]);

	return (
		<tr
			role="row"
			tabIndex={-1}
			css={[headerRowStyles, isSelected && activeRowStyles]}
			onFocus={focusCurrentRow}
			data-testid="native-issue-table.ui.row.group-row.group-header.tr"
			aria-selected={isSelected}
			ref={tableRowRef}
		>
			<Box
				as="td"
				// Additional column for configure columns menu & issue actions
				colSpan={columns.length + 1}
				xcss={[
					borderStyles,
					isGroupAtTop && removeTopBorder,
					(!isExpanded || isLoading || !data.issueCount) && removeBottomBorder,
				]}
			>
				<Inline xcss={[inlineStyles, !data.issueCount && placeholderStyles]} space="space.100">
					{headerContent}
				</Inline>
			</Box>
		</tr>
	);
};

const inlineStyles = xcss({
	paddingInline: 'space.150',
	alignItems: 'center',
	gap: 'space.100',
});

const placeholderStyles = xcss({
	marginLeft: 'space.400',
});

const borderStyles = xcss({
	border: 0,
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderTopWidth: 'border.width',
	borderBottomWidth: 'border.width',
});

const removeTopBorder = xcss({
	borderTopWidth: '0',
});

const removeBottomBorder = xcss({
	borderBottomWidth: '0',
});

const headerRowStyles = css({
	'&:hover': {
		backgroundColor: token('elevation.surface.hovered'),
	},
	'&:focus': {
		outline: 'none',
	},
});

const activeRowStyles = css({
	backgroundColor: token('color.background.selected'),
	'&:hover': {
		backgroundColor: token('color.background.selected.hovered'),
	},
});
