import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { tableBody_nativeIssueTable_issueResults$key } from '@atlassian/jira-relay/src/__generated__/tableBody_nativeIssueTable_issueResults.graphql.ts';
import type { tableBody_nativeIssueTable_project$key } from '@atlassian/jira-relay/src/__generated__/tableBody_nativeIssueTable_project.graphql.ts';
import { fg } from '@atlassian/jira-feature-gating';
import { ChildRows as ChildRowsNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows/index.tsx';
import { ChildRowsLoader } from '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows-loader/index.tsx';
import type { Column, TableInjectedCellProps, SortOrder } from '../../../common/types.tsx';
import { ChildRows as ChildRowsOld } from '../../rows/child-rows/index.tsx';
import { Rows } from '../../rows/index.tsx';
import { RowContextProvider, type RenderChildRows } from '../../rows/row-context/index.tsx';
import {
	CHILD_ITEMS_EXPERIENCE,
	CHILD_ITEMS_LOAD_MORE_EXPERIENCE,
	ANALYTICS_SOURCE,
	MAX_COLUMNS,
	ACTION_COLUMNS,
	CHILD_QUERY_PAGE_SIZE,
} from '../../../common/constants.tsx';
import {
	useIsReparentingEnabled,
	useSortDirection,
	useSortField,
	useJql,
	useIsFixedTableLayoutEnabled,
	useIsIssueRankDescending,
	useIsHideDoneItemsEnabled,
} from '../../../controllers/features/selectors.tsx';
import { TableCell } from '../../../common/ui/table-cell/index.tsx';
import { useScrollStateSelector } from '../../../controllers/scroll-state/index.tsx';
import { CreateIssueController } from '../../../services/issue-create-mutation/index.tsx';

export type Props = {
	columns: Column[];
	tableInjectedProps: TableInjectedCellProps;
	issueResults: tableBody_nativeIssueTable_issueResults$key;
	project: tableBody_nativeIssueTable_project$key | null;
	isGroupingSupported?: boolean;
};

const TableBody = ({ issueResults, project, isGroupingSupported, ...tableBodyProps }: Props) => {
	const issueResultsData = useFragment<tableBody_nativeIssueTable_issueResults$key>(
		graphql`
			fragment tableBody_nativeIssueTable_issueResults on JiraIssueConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				__id
				...rows_nativeIssueTable
					@arguments(
						isInlineEditingEnabled: $isInlineEditingEnabled
						isReparentingEnabled: $isReparentingEnabled
						isDensityFull: $isDensityFull
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
			}
		`,
		issueResults,
	);

	const projectData = useFragment<tableBody_nativeIssueTable_project$key>(
		graphql`
			fragment tableBody_nativeIssueTable_project on JiraProject {
				key
			}
		`,
		project,
	);

	/**
	 * Passing those values down to ChildRowsLoader as props to avoid direct imports
	 */
	let jql: string | undefined;
	let sortField: string | undefined;
	let sortDirection: SortOrder | undefined;
	let isReparentingEnabled = false;
	let isHideDoneItemsEnabled = false;
	let isFixedTableLayoutEnabled = false;
	let width = 0;
	let isIssueRankDescending = false;
	if (fg('jira_list_hierarchy_extraction')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		jql = useJql();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		sortField = useSortField();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		sortDirection = useSortDirection();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isReparentingEnabled = useIsReparentingEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isHideDoneItemsEnabled = useIsHideDoneItemsEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		width = useScrollStateSelector((scrollState) => scrollState.width);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueRankDescending = useIsIssueRankDescending();
	}

	const renderChildRows: RenderChildRows = useCallback(
		(props) =>
			fg('jira_list_hierarchy_extraction') ? (
				<ChildRowsNew
					projectKey={projectData?.key}
					isGroupingSupported={isGroupingSupported}
					childItemsExperience={CHILD_ITEMS_EXPERIENCE}
					childItemsLoadMoreExperience={CHILD_ITEMS_LOAD_MORE_EXPERIENCE}
					analyticsSource={ANALYTICS_SOURCE}
					renderChildRowsLoader={(childRowsLoaderProps) => (
						<ChildRowsLoader
							{...childRowsLoaderProps}
							jql={jql}
							width={width}
							sortField={sortField}
							sortDirection={sortDirection}
							columns={tableBodyProps.columns}
							amountOfColumns={MAX_COLUMNS}
							actionColumns={ACTION_COLUMNS}
							pageSize={CHILD_QUERY_PAGE_SIZE}
							isReparentingEnabled={isReparentingEnabled}
							isIssueRankDescending={isIssueRankDescending}
							isHideDoneItemsEnabled={isHideDoneItemsEnabled}
							isFixedTableLayoutEnabled={isFixedTableLayoutEnabled}
							renderRows={(rowProps) => <Rows {...rowProps} />}
							renderTableCell={(tableCellProps) => <TableCell {...tableCellProps} />}
							renderCreateIssueController={(args) => <CreateIssueController {...args} />}
						/>
					)}
					{...props}
				/>
			) : (
				<ChildRowsOld
					projectKey={projectData?.key}
					isGroupingSupported={isGroupingSupported}
					{...props}
				/>
			),
		[
			projectData?.key,
			isGroupingSupported,
			tableBodyProps.columns,
			sortField,
			sortDirection,
			jql,
			isReparentingEnabled,
			isHideDoneItemsEnabled,
			isFixedTableLayoutEnabled,
			width,
			isIssueRankDescending,
		],
	);

	return (
		<StyledTableBody>
			<RowContextProvider {...tableBodyProps} renderChildRows={renderChildRows}>
				<Rows
					issueResults={issueResultsData}
					parentItemId="root"
					itemsConnectionId={issueResultsData.__id}
					{...(fg('jsc_list_reparenting') ? { projectKey: projectData?.key } : {})}
				/>
			</RowContextProvider>
		</StyledTableBody>
	);
};

export default TableBody;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTableBody = styled.tbody({
	borderBottom: 'none',
	// Ensure position is correctly calculated when an inline edit popup is opened that overflows the table body
	overflowX: 'hidden',
});
