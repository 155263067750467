/**
 * @generated SignedSource<<ad0f42d6a1044635396f00707db2d928>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueTable_nativeIssueTable_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"groupTableBody_nativeIssueTable_project" | "tableBody_nativeIssueTable_project">;
  readonly " $fragmentType": "issueTable_nativeIssueTable_project";
};
export type issueTable_nativeIssueTable_project$key = {
  readonly " $data"?: issueTable_nativeIssueTable_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueTable_nativeIssueTable_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueTable_nativeIssueTable_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "groupTableBody_nativeIssueTable_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "tableBody_nativeIssueTable_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "7c431cd8484564a62351c9a48932aff4";

export default node;
