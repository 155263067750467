import React, { forwardRef, type Ref } from 'react';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/utility/add';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	onClick?: () => void;
};

const CreateChildButton = forwardRef(({ onClick }: Props, ref: Ref<HTMLButtonElement>) => {
	const { formatMessage } = useIntl();
	return (
		<IconButton
			spacing="compact"
			appearance="subtle"
			label={formatMessage(messages.create)}
			testId="issue-table-hierarchy.ui.create-child-button.create"
			icon={AddIcon}
			onClick={onClick}
			isTooltipDisabled={false}
			ref={ref}
		/>
	);
});

export default CreateChildButton;
