import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	onboardingDescription: {
		id: 'issue-table-inline-issue-create.inline-create-trigger.onboarding.onboarding-description',
		defaultMessage: 'Quickly create issues on this page by selecting + Create below',
		description: 'Description on how to create issues on the jira spreadsheet component.',
	},
	onboardingHeader: {
		id: 'issue-table-inline-issue-create.inline-create-trigger.onboarding.onboarding-header',
		defaultMessage: 'Create issues in context',
		description: 'Header on how to create issues on the jira spreadsheet component.',
	},
	onboardingDismiss: {
		id: 'issue-table-inline-issue-create.inline-create-trigger.onboarding.onboarding-dismiss',
		defaultMessage: 'OK',
		description: 'Label for a button that dismisses a Spotlight.',
	},
});
