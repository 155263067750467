import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueKeyWithResolution: {
		id: 'native-issue-table.common.issue-cells.issue-key.issue-key-with-resolution',
		defaultMessage: `{issueKey} is {isResolved, select, 
            true {resolved}
            false {not resolved}
            other {}
        }`,
		description: 'Text description of issue link that composite with issueKey and resolution',
	},
});
