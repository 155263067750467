import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const MultiVersionPickerEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-multi-version-picker-edit-view" */ './ui/multi-version-picker'
			),
	),
	getPreloadProps: () => ({}),
});

export default MultiVersionPickerEditViewEntryPoint;
