import React from 'react';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { HeaderHeight } from '../../../../common/types.tsx';
import messages from './messages.tsx';

type Props = {
	position: 'top' | 'bottom';
	isGrouping?: boolean;
};

export const LoadingIndicator = ({ position, isGrouping }: Props) => {
	const { formatMessage } = useIntl();

	const getLoadingMessage = () => {
		if (isGrouping) {
			return messages.loadingMessageWithGrouping;
		}
		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.loadingMessageIssueTermRefresh
			: messages.loadingMessage;
	};

	return (
		<Inline
			alignInline="center"
			xcss={[containerStyles, position === 'top' ? positionTopStyles : positionBottomStyles]}
		>
			<Inline space="space.100" alignBlock="center" grow="hug" xcss={loadingIndicatorStyles}>
				<Spinner
					appearance="invert"
					size="small"
					interactionName={
						fg('add_nin_press_interactions') ? 'issue-table-infinite-scroll-loading' : undefined
					}
				/>
				<Text color="color.text.inverse">{formatMessage(getLoadingMessage())}</Text>
			</Inline>
		</Inline>
	);
};

// Account for the sticky header row height
const HEADER_HEIGHT: HeaderHeight = 40;
const positionTopStyles = xcss({ top: `${HEADER_HEIGHT}px` });

const positionBottomStyles = xcss({ bottom: 'space.0' });

const loadingIndicatorStyles = xcss({
	backgroundColor: 'color.background.neutral.bold',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.overlay',
	color: 'color.text.inverse',
	marginBlock: 'space.200',
	padding: 'space.100',
});

const containerStyles = xcss({
	position: 'absolute',
	width: '100%',
});
