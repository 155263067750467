/** @jsx jsx */
import React, { type ReactNode, type RefCallback } from 'react';
import { jsx, css } from '@compiled/react';
import { notUndefined } from '@tanstack/react-virtual';
import { fg } from '@atlassian/jira-feature-gating';
import { useVirtualization as useVirtualizationOld } from '../../../controllers/virtualization/index.tsx';
import {
	useVirtualization,
	useVirtualItemsMap,
	type VirtualItemsMap,
} from '../../../controllers/virtualization/rss-store.tsx';
import { useRowContext } from '../row-context/index.tsx';

type VirtualizedRowsProps = {
	children: () => ReactNode | undefined;
	renderRow: (
		index: number,
		measureElement?: RefCallback<HTMLTableRowElement>,
		virtualItemsMap?: VirtualItemsMap,
	) => React.ReactElement;
};

export function VirtualizedRows({ children, renderRow }: VirtualizedRowsProps) {
	let virtualizer = useVirtualizationOld();
	let virtualItemsMap: VirtualItemsMap;
	if (fg('jsc_virtualizer_rss_store')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		virtualItemsMap = useVirtualItemsMap();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		virtualizer = useVirtualization();
	}

	const { columns } = useRowContext();

	if (virtualizer == null) {
		return children();
	}

	const items = virtualizer.getVirtualItems();
	// Calculates the height (top and bottom) of the non rendered portion of table
	const [before, after] =
		items.length > 0
			? [
					notUndefined(items[0]).start - virtualizer.options.scrollMargin,
					virtualizer.getTotalSize() - notUndefined(items.at(-1)).end,
				]
			: [0, 0];

	const bottomStyle = { height: after };

	return (
		<>
			{before > 0 && (
				<tr css={virtualRowStyles}>
					{/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop */}
					<td colSpan={columns.length} style={{ height: before }} />
				</tr>
			)}
			{/*
				NOTE: The [key](https://tanstack.com/virtual/latest/docs/api/virtual-item#key) here is
			    by default the item index; if we would like that to be something else we need to:

				a) Have access to the non virtualized list in this component, or
				b) Utilize the [getItemKey](https://tanstack.com/virtual/latest/docs/api/virtualizer#getitemkey)
				   on the `useVirtualizer` options.
			*/}
			{items.map((virtualRow) => (
				<React.Fragment key={virtualRow.key}>
					{renderRow(virtualRow.index, virtualizer.measureElement, virtualItemsMap)}
				</React.Fragment>
			))}
			{after > 0 && (
				<tr css={virtualRowStyles}>
					{/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop */}
					<td colSpan={columns.length} style={bottomStyle} />
				</tr>
			)}
		</>
	);
}

const virtualRowStyles = css({
	padding: '0',
	margin: '0',
});
