import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import type { richText_nativeIssueTable_RichTextCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/richText_nativeIssueTable_RichTextCell_fieldRef.graphql';
import type { richText_nativeIssueTable_RichTextCell_issueRef$key } from '@atlassian/jira-relay/src/__generated__/richText_nativeIssueTable_RichTextCell_issueRef.graphql';
import {
	useIsDensityFull,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import { RichTextCellCompact } from './rich-text-compact/index.tsx';
import { RichTextCellFull } from './rich-text-full/index.tsx';

export type Props = {
	issueKey: string;
	fieldRef: richText_nativeIssueTable_RichTextCell_fieldRef$key;
	/**
	 * Should only be needed For VR testing, to render a storybook example with
	 * the popup content immediately visible
	 */
	shouldOpenOnFirstRender?: boolean;
	issueRef?: richText_nativeIssueTable_RichTextCell_issueRef$key | null;
};

export const RichTextCell = memo<Props>(
	({ issueKey, fieldRef, issueRef, shouldOpenOnFirstRender = false }: Props) => {
		const { richText, fieldId } = useFragment<richText_nativeIssueTable_RichTextCell_fieldRef$key>(
			graphql`
				fragment richText_nativeIssueTable_RichTextCell_fieldRef on JiraRichTextField
				@argumentDefinitions(isDensityFull: { type: "Boolean!" }) {
					fieldId
					richText {
						adfValue {
							convertedPlainText @skip(if: $isDensityFull) {
								plainText
							}
							json @include(if: $isDensityFull)
						}
					}
				}
			`,
			fieldRef,
		);

		const issueData = useFragment<richText_nativeIssueTable_RichTextCell_issueRef$key>(
			graphql`
				fragment richText_nativeIssueTable_RichTextCell_issueRef on JiraIssue
				@argumentDefinitions(isDensityFull: { type: "Boolean!" }) {
					canBeExported @include(if: $isDensityFull)
				}
			`,
			issueRef || null,
		);

		const isDensityFull = useIsDensityFull();

		const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

		if (isSingleLineRowHeightEnabled) {
			return (
				<Box xcss={richTextCellWrapperStyles}>
					{richText?.adfValue?.convertedPlainText?.plainText}
				</Box>
			);
		}

		if (isDensityFull) {
			return (
				<RichTextCellFull
					issueKey={issueKey}
					richText={richText?.adfValue?.json || ''}
					canBeExported={issueData?.canBeExported}
				/>
			);
		}

		return (
			<RichTextCellCompact
				fieldId={fieldId}
				plainText={richText?.adfValue?.convertedPlainText?.plainText || ''}
				issueKey={issueKey}
				shouldOpenOnFirstRender={shouldOpenOnFirstRender}
			/>
		);
	},
);

const richTextCellWrapperStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
