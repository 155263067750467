import React, { type PropsWithChildren, useMemo } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createStateHook,
	type Action,
} from '@atlassian/react-sweet-state';
import type { Actions, Props, State } from './types.tsx';

const initialState: State = {};

const Store = createStore<State, Actions>({
	initialState,
	actions: {},
});

const updateStore =
	(): Action<State, Props> =>
	({ setState }, { payload }) =>
		setState(payload);

const Container = createContainer<State, Actions, Props>(Store, {
	onInit: updateStore,
	onUpdate: updateStore,
});

/**
 * Define contextual field value data that should be set during issue create. This will aggregate any context data that
 * has been set in an ancestor context.
 *
 * This allows consumers to define appropriate default values for fields for use cases such as grouping or sibling issue
 * create.
 */
export const IssueCreateContextualFieldsData = ({
	children,
	payload,
}: PropsWithChildren<Partial<Props>>) => {
	const ancestorPayload = useIssueCreateContextualFields();

	const nextPayload = useMemo(
		() => ({ ...ancestorPayload, ...payload }),
		[ancestorPayload, payload],
	);

	return <Container payload={nextPayload}>{children}</Container>;
};

export const useIssueCreateContextualFields = createStateHook(Store);
