import React, { createContext, useContext, type PropsWithChildren } from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { IFCConfigType } from '../types.tsx';

export const IFCConfigContext = createContext<IFCConfigType>(undefined);

export const IFCConfigContextProvider = ({
	children,
	ifcConfig,
}: PropsWithChildren<{ ifcConfig: IFCConfigType }>) => {
	return <IFCConfigContext.Provider value={ifcConfig}>{children}</IFCConfigContext.Provider>;
};

export const useIFCConfigContext = () => {
	return useContext(IFCConfigContext);
};

export const IFCConfigContextProviderComponent = componentWithCondition(
	() => fg('jira_inline_field_config_gate'),
	IFCConfigContextProvider,
	({ children }: PropsWithChildren<{ ifcConfig: IFCConfigType }>) => children,
);
