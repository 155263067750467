/**
 * @generated SignedSource<<8d787d89f2e4dcb871bde716130621fb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge$data = {
  readonly node: {
    readonly issueId: string;
    readonly issueTypeField?: {
      readonly issueType: {
        readonly hierarchy: {
          readonly level: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly key: string;
    readonly " $fragmentSpreads": FragmentRefs<"issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef" | "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld" | "issueSummary_nativeIssueTable_IssueSummaryCell_issueRef" | "richText_nativeIssueTable_RichTextCell_issueRef">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"firstColumnCell_nativeIssueTable">;
  readonly " $fragmentType": "issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge";
};
export type issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge$key = {
  readonly " $data"?: issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "isDensityFull",
  "variableName": "isDensityFull"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "issueRow_nativeIssueTable_IssueRowWithFragments_issueEdge",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "firstColumnCell_nativeIssueTable"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssue",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "key"
          },
          {
            "kind": "ScalarField",
            "name": "issueId"
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "issueTypeField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueTypeHierarchyLevel",
                        "kind": "LinkedField",
                        "name": "hierarchy",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "level"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "issueSummary_nativeIssueTable_IssueSummaryCell_issueRef"
          },
          {
            "kind": "FragmentSpread",
            "name": "issueKey_nativeIssueTable_IssueKeyCell_fieldsByIdRef"
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": [
                  (v0/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "isInlineEditingEnabled",
                    "variableName": "isInlineEditingEnabled"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetConnectionOld"
              }
            ]
          },
          {
            "args": [
              (v0/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "richText_nativeIssueTable_RichTextCell_issueRef"
          }
        ]
      },
      "action": "THROW",
      "path": "node"
    }
  ],
  "type": "JiraIssueEdge"
};
})();

(node as any).hash = "8291c7ff2f59c8a480bb96305a719d2d";

export default node;
