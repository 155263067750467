import React, { type ReactNode } from 'react';
import memoizeOne from 'memoize-one';
import { Text } from '@atlaskit/primitives';
import type { IntlFormatV2 as IntlFormat } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	DEFAULT_COLUMN_WIDTH,
	COLUMN_WIDTH_TEXT_LONG,
	COLUMN_WIDTH_TEXT_SHORT,
	COLUMN_WIDTH_TEXT_SUMMARY,
	KNOWN_COLUMN_TYPES,
} from '../../constants.tsx';
import type { ColumnConfig, KnownColumnType } from '../../types.tsx';
import messages from './messages.tsx';

type ColumnConfigGetter = () => ColumnConfig;
type ColumnConfigMap = Record<KnownColumnType, ColumnConfigGetter>;

export const getColumnConfigOld = memoizeOne(
	(formatMessage: IntlFormat['formatMessage']): ColumnConfigMap => ({
		[KNOWN_COLUMN_TYPES.ASSIGNEE]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.CHECKBOX]: () => ({
			defaultWidth: 48,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.CHECKBOX_SELECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.CREATED]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : 126,
			ascText: formatMessage(messages.oldestToNewestOrderOld),
			descText: formatMessage(messages.newestToOldestOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.UPDATED]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : 130,
			ascText: formatMessage(messages.oldestToNewestOrderOld),
			descText: formatMessage(messages.newestToOldestOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.DUE_DATE]: () => ({
			defaultWidth: 140,
			ascText: formatMessage(messages.oldestToNewestOrderOld),
			descText: formatMessage(messages.newestToOldestOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.RESOLUTION_DATE]: () => ({
			defaultWidth: 106,
			ascText: formatMessage(messages.oldestToNewestOrderOld),
			descText: formatMessage(messages.newestToOldestOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.PRIORITY]: () => ({
			defaultWidth: 140,
		}),
		[KNOWN_COLUMN_TYPES.ISSUE_KEY]: () => ({
			defaultWidth: 96,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.EXPAND_BUTTON]: () => ({
			defaultWidth: 48,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS]: () => ({
			title: '',
			defaultWidth: 48,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.STATUS]: () => ({
			defaultWidth: 140,
		}),
		[KNOWN_COLUMN_TYPES.SUMMARY]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SUMMARY,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.ISSUE_TYPE]: () => ({
			// Overrides title from the server's displayName from "Issue Type" to "Type" as per designs
			title: formatMessage(messages.columnHeaderIssueType),
			defaultWidth: 101,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.RESOLUTION]: () => ({
			defaultWidth: 106,
		}),
		[KNOWN_COLUMN_TYPES.REPORTER]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.CREATOR]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.SPRINT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.PARENT]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SUMMARY,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		// Labels renders using an Atlaskit Tag which doesn't respect its container, and there's no prop to override it...
		// <Tag/> has a maxWidth of 180px with 4px of padding either side
		// And we need to accound for 5px & 8px for left and right padding on our cell respectively
		[KNOWN_COLUMN_TYPES.LABELS]: () => ({
			defaultWidth: 201,
		}),
		[KNOWN_COLUMN_TYPES.COMPONENTS]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.FIX_VERSIONS]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.STATUS_CATEGORY]: () => ({
			defaultWidth: 140,
		}),
		[KNOWN_COLUMN_TYPES.PROJECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.EPIC_LINK]: () => ({
			// EpicLink renders using an Atlaskit Tag which doesn't respect its container, and there's no prop to override it...
			// <Tag/> has a maxWidth of 180px with 4px of padding either side
			// And we need to account for 5px & 8px for left and right padding on our cell respectively
			defaultWidth: 201,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
			warningText: formatMessage(messages.deprecatedEpicLinkMessage, {
				strong: (chunks: ReactNode) => (
					<Text color="color.text.inverse" as="strong">
						{chunks}
					</Text>
				),
			}),
		}),
		[KNOWN_COLUMN_TYPES.DESCRIPTION]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_LONG,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.ENVIRONMENT]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_LONG,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.TEXT_AREA]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_LONG,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.TEXT]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SHORT,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.READ_ONLY]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SHORT,
			ascText: formatMessage(messages.aToZOrderOld),
			descText: formatMessage(messages.zToAOrderOld),
		}),
		[KNOWN_COLUMN_TYPES.AFFECTS_VERSIONS]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.REQUEST_TYPE]: () => ({
			defaultWidth: 251,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.NUMBER]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.USER_PICKER]: () =>
			fg('jsc_inline_editing_field_refactor')
				? {
						defaultWidth: DEFAULT_COLUMN_WIDTH,
						ascText: formatMessage(messages.aToZOrderOld),
						descText: formatMessage(messages.zToAOrderOld),
					}
				: {
						defaultWidth: DEFAULT_COLUMN_WIDTH,
					},
		[KNOWN_COLUMN_TYPES.CUSTOM_LABELS]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.DATE_TIME]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.URL]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			...(fg('jsc_inline_editing_field_refactor') && {
				ascText: formatMessage(messages.aToZOrderOld),
				descText: formatMessage(messages.zToAOrderOld),
			}),
		}),
		[KNOWN_COLUMN_TYPES.SINGLE_SELECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.MULTI_SELECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.MULTI_USER_PICKER]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.TEAM]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.ORIGINAL_ESTIMATE]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
	}),
);

export const getColumnConfigNew = memoizeOne(
	(formatMessage: IntlFormat['formatMessage']): ColumnConfigMap => ({
		[KNOWN_COLUMN_TYPES.ASSIGNEE]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.CHECKBOX]: () => ({
			defaultWidth: 48,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.CHECKBOX_SELECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.CREATED]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : 126,
			ascText: formatMessage(messages.oldestToNewestOrder),
			descText: formatMessage(messages.newestToOldestOrder),
		}),
		[KNOWN_COLUMN_TYPES.UPDATED]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : 130,
			ascText: formatMessage(messages.oldestToNewestOrder),
			descText: formatMessage(messages.newestToOldestOrder),
		}),
		[KNOWN_COLUMN_TYPES.DUE_DATE]: () => ({
			defaultWidth: 140,
			ascText: formatMessage(messages.oldestToNewestOrder),
			descText: formatMessage(messages.newestToOldestOrder),
		}),
		[KNOWN_COLUMN_TYPES.RESOLUTION_DATE]: () => ({
			defaultWidth: 106,
			ascText: formatMessage(messages.oldestToNewestOrder),
			descText: formatMessage(messages.newestToOldestOrder),
		}),
		[KNOWN_COLUMN_TYPES.PRIORITY]: () => ({
			defaultWidth: 140,
		}),
		[KNOWN_COLUMN_TYPES.ISSUE_KEY]: () => ({
			defaultWidth: 96,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.EXPAND_BUTTON]: () => ({
			defaultWidth: 48,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.ISSUE_OPERATIONS]: () => ({
			title: '',
			defaultWidth: 48,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.STATUS]: () => ({
			defaultWidth: 140,
		}),
		[KNOWN_COLUMN_TYPES.SUMMARY]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SUMMARY,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.ISSUE_TYPE]: () => ({
			// Overrides title from the server's displayName from "Issue Type" to "Type" as per designs
			title: formatMessage(messages.columnHeaderIssueType),
			defaultWidth: 101,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.RESOLUTION]: () => ({
			defaultWidth: 106,
		}),
		[KNOWN_COLUMN_TYPES.REPORTER]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.CREATOR]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.SPRINT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.PARENT]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SUMMARY,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		// Labels renders using an Atlaskit Tag which doesn't respect its container, and there's no prop to override it...
		// <Tag/> has a maxWidth of 180px with 4px of padding either side
		// And we need to accound for 5px & 8px for left and right padding on our cell respectively
		[KNOWN_COLUMN_TYPES.LABELS]: () => ({
			defaultWidth: 201,
		}),
		[KNOWN_COLUMN_TYPES.COMPONENTS]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.FIX_VERSIONS]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.STATUS_CATEGORY]: () => ({
			defaultWidth: 140,
		}),
		[KNOWN_COLUMN_TYPES.PROJECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.EPIC_LINK]: () => ({
			// EpicLink renders using an Atlaskit Tag which doesn't respect its container, and there's no prop to override it...
			// <Tag/> has a maxWidth of 180px with 4px of padding either side
			// And we need to account for 5px & 8px for left and right padding on our cell respectively
			defaultWidth: 201,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
			warningText: formatMessage(messages.deprecatedEpicLinkMessage, {
				strong: (chunks: ReactNode) => (
					<Text color="color.text.inverse" as="strong">
						{chunks}
					</Text>
				),
			}),
		}),
		[KNOWN_COLUMN_TYPES.DESCRIPTION]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_LONG,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.ENVIRONMENT]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_LONG,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.TEXT_AREA]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_LONG,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.TEXT]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SHORT,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.READ_ONLY]: () => ({
			defaultWidth: COLUMN_WIDTH_TEXT_SHORT,
			ascText: formatMessage(messages.aToZOrder),
			descText: formatMessage(messages.zToAOrder),
		}),
		[KNOWN_COLUMN_TYPES.AFFECTS_VERSIONS]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.REQUEST_TYPE]: () => ({
			defaultWidth: 251,
			isSortable: false,
		}),
		[KNOWN_COLUMN_TYPES.NUMBER]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.USER_PICKER]: () =>
			fg('jsc_inline_editing_field_refactor')
				? {
						defaultWidth: DEFAULT_COLUMN_WIDTH,
						ascText: formatMessage(messages.aToZOrder),
						descText: formatMessage(messages.zToAOrder),
					}
				: {
						defaultWidth: DEFAULT_COLUMN_WIDTH,
					},
		[KNOWN_COLUMN_TYPES.CUSTOM_LABELS]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.DATE_TIME]: () => ({
			defaultWidth: fg('jsc_inline_editing_field_refactor') ? 201 : DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.URL]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
			...(fg('jsc_inline_editing_field_refactor') && {
				ascText: formatMessage(messages.aToZOrder),
				descText: formatMessage(messages.zToAOrder),
			}),
		}),
		[KNOWN_COLUMN_TYPES.SINGLE_SELECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.MULTI_SELECT]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.MULTI_USER_PICKER]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.TEAM]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
		[KNOWN_COLUMN_TYPES.ORIGINAL_ESTIMATE]: () => ({
			defaultWidth: DEFAULT_COLUMN_WIDTH,
		}),
	}),
);

export const getColumnConfig = (formatMessage: IntlFormat['formatMessage']): ColumnConfigMap => {
	return isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
		? getColumnConfigNew(formatMessage)
		: getColumnConfigOld(formatMessage);
};
