/**
 * @generated SignedSource<<3588ea039c9febd14e0e88de5d01bbc2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type affectedServices_nativeIssueTable_AffectedServicesCell$data = {
  readonly selectedAffectedServicesConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null | undefined;
        readonly serviceId: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "affectedServices_nativeIssueTable_AffectedServicesCell";
};
export type affectedServices_nativeIssueTable_AffectedServicesCell$key = {
  readonly " $data"?: affectedServices_nativeIssueTable_AffectedServicesCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"affectedServices_nativeIssueTable_AffectedServicesCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "affectedServices_nativeIssueTable_AffectedServicesCell",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
};

(node as any).hash = "ce0f961052b6620f6333f7806e16524e";

export default node;
