import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noGoalsDisplayName: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.no-goals-display-name',
		defaultMessage: 'Issues without goal',
		description:
			'Text used as a group name for issues with no goals when grouping items by goal is enabled.',
	},
	noGoalsDisplayNameIssueTermRefresh: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.no-goals-display-name-issue-term-refresh',
		defaultMessage: 'Work items without goal',
		description:
			'Text used as a group name for issues with no goals when grouping items by goal is enabled.',
	},
	statusLabelArchived: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-archived',
		defaultMessage: 'Archived',
		description: 'Label for the archived goal status.',
	},
	statusLabelPending: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-pending',
		defaultMessage: 'Pending',
		description: 'Label for the pending goal status.',
	},
	statusLabelInProgress: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-in-progress',
		defaultMessage: 'In progress',
		description: 'Label for the in progress goal status.',
	},
	statusLabelCompleted: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-completed',
		defaultMessage: 'Completed',
		description: 'Label for the completed goal status.',
	},
	statusLabelOnTrack: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-on-track',
		defaultMessage: 'On track',
		description: 'Label for the on track goal status.',
	},
	statusLabelPaused: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-paused',
		defaultMessage: 'Paused',
		description: 'Label for the paused goal status.',
	},
	statusLabelAtRisk: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-at-risk',
		defaultMessage: 'At risk',
		description: 'Label for the at risk goal status.',
	},
	statusLabelOffTrack: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-off-track',
		defaultMessage: 'Off track',
		description: 'Label for the off track goal status.',
	},
	statusLabelCancelled: {
		id: 'native-issue-table.row.group-row.group-header.goal-group-header.status-label-cancelled',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Cancelled',
		description: 'Label for the cancelled goal status.',
	},
});
