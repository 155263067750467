/**
 * @generated SignedSource<<cdf4eac0d7108a760c04d4abe4f1ad1c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type realtimeUpdater_nativeIssueTable_groups$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly __typename: "JiraSpreadsheetGroupEdge";
  } | null | undefined> | null | undefined;
  readonly groupByField: string | null | undefined;
  readonly jql: string | null | undefined;
  readonly " $fragmentType": "realtimeUpdater_nativeIssueTable_groups";
};
export type realtimeUpdater_nativeIssueTable_groups$key = {
  readonly " $data"?: realtimeUpdater_nativeIssueTable_groups$data;
  readonly " $fragmentSpreads": FragmentRefs<"realtimeUpdater_nativeIssueTable_groups">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "realtimeUpdater_nativeIssueTable_groups",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jql"
    },
    {
      "kind": "ScalarField",
      "name": "groupByField"
    },
    {
      "concreteType": "JiraSpreadsheetGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "ef119ccb02ba7801b3514b38181f129c";

export default node;
