import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	replacedByParentField: {
		id: 'native-issue-table.common.issue-cells.parent.replaced-by-parent-field',
		defaultMessage: 'Replaced by <strong>Parent</strong> field',
		description:
			'Text displayed in the "Parent Link" field cell to indicate that the "Parent Link" field is deprecated and that users should use the "Parent" field instead',
	},
});
